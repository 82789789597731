
import { useMutation } from '@apollo/react-hooks'
import useReactRouter from "use-react-router";
import { CREATE_STAFF, DELETE_STAFF, UPDATE_STAFF,CREATE_CLIMATE } from '../apollo/user'
import { successAdd, errorAdd } from '../helpers/sweetalert'
import Routers from "../consts";

export default function CreateCategory() {
    const { history } = useReactRouter();

    // ====== updateCategory ======>
    const [updateStaff] = useMutation(UPDATE_STAFF)
    const _updateStaff = async (item, DataAction,rout) => {
        try {
            let _updateData = await updateStaff({
                variables: {
                    data: item,
                    where: {
                        id: DataAction
                    }
                }
            })
            if (_updateData?.data?.updateUser) {
                await successAdd("UPDATE SUCCESS")
                history.replace(rout)
            }
        } catch (error) {
            errorAdd(error)
        }
    }
    // ====== createCategory ======>
    const [createStaff] = useMutation(CREATE_STAFF)
    const _createStaff = async (items, rout) => {
        try {
            let _createData = await createStaff({
                variables: {
                    data: items
                }
            })
            if (_createData?.data?.createUser) {
                await successAdd("CREATE SUCCESS")
                history.replace(rout)
            }
        } catch (error) {
            errorAdd(error)
        }
    }


    // ==============_createVolunteer =============================
    const _createVolunteer = async (items, handleShowAlertSuccess) => {
        try {
            let _createData = await createStaff({
                variables: {
                    data: items
                }
            })
            if (_createData?.data?.createUser) {
                // await successAdd("CREATE SUCCESS")
                await handleShowAlertSuccess()
                // history.replace(rout)
            }
        } catch (error) {
            errorAdd(error)
        }
    }


    // ====== createCategory ======>
    const [createClimate] = useMutation(CREATE_CLIMATE)
    const _createClimate = async (items, rout) => {
        try {
            let _createData = await createClimate({
                variables: {
                    data: items
                }
            })
            if (_createData?.data?.createClimate) {
                await successAdd("CREATE SUCCESS")
                history.replace(rout)
            }
        } catch (error) {
            errorAdd(error)
        }
    }
    // ====== deleteCategory ======>
    const [deleteStaff] = useMutation(DELETE_STAFF)
    const _deleteStaff = async (items, handleCloseDelete, rout) => {
        try {
            let _deleteData = await deleteStaff({
                variables: {
                    where: { id: items }
                }
            })
            if (_deleteData?.data?.deleteUser) {
                await successAdd("DELETE SUCCESS")
                await handleCloseDelete()
                history.replace(rout)
            }
        } catch (error) {
            errorAdd(error)
        }
    }
    return {
        _updateStaff,
        _deleteStaff,
        _createStaff,
        _createClimate,
        _createVolunteer,
    }
}