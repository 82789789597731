// import React from 'react'
// import {Navigate} from 'react-router-dom'
// import {isLogin} from '../helpers/Helper'

// const PrivateRoute = ({component:Component, ...rest}) =>{
//     if(isLogin){
//     return <Component {...rest} />;
//     }

//      return  <Navigate to="/home" />;
// }

// export default PrivateRoute;
import React, { useEffect, useState } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { USER_KEY } from '../consts'
function PrivateRoute({ component: Component, headerTitle, ...rest }) {
	// const [data, setData] = useState()
	// useEffect(() => {
	// 	const _resData = localStorage.getItem(USER_KEY);
	// 	const _localJson = JSON.parse(_resData)
	// 	setData(_localJson)
	// }, [])
	let isAuthenticated = true;
	return (
		<Route
			{...rest}
			render={props =>
				isAuthenticated ? (
					<div>
						<Component {...props} />
					</div>
				) : (
					<Component {...props} />
				)
			}
		/>
	)
}

export default PrivateRoute
