import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import useReactRouter from "use-react-router";
import { useLazyQuery } from '@apollo/react-hooks'


import Consts from "../../../consts"

import { POSTS } from "../../../apollo/post/Query"
import { dateTimeLao } from '../../../helpers/Helper';


import Blog1 from "../../../image/blog/blog1.png"
import Blog2 from "../../../image/blog/blog2.png"
import Blog3 from "../../../image/blog/blog3.png"
import Blog4 from "../../../image/blog/blog4.png"
import Blog5 from "../../../image/blog/blog5.png"
import Blog6 from "../../../image/blog/blog6.png"
import NewsCover from "../../../image/blog/NewsCover.png"
import LogoZero from "../../../image/logo/LogoZero.png"


import Developing from "../../../image/developing.gif"


import { FaAngleRight } from "react-icons/fa";
import { Carousel, Container, Row, Col, Button, ListGroup, Modal } from 'react-bootstrap'
import { FaSearch, FaTimes } from "react-icons/fa";

import Footer from "../../../components/footer"

import './Index.css'


function Blog() {
    const { history } = useReactRouter();

    const [showDeveloping, setShowDeveloping] = useState(false);

    const handleClose = () => setShowDeveloping(false);
    const handleShow = () => setShowDeveloping(true);

    const [loadPost, { data: postData }] = useLazyQuery(POSTS)

    // useEffect
    useEffect(() => {
        loadPost()
    }, [])

    

    return (
        <div style={{ zIndex: 0 }}>
            <div style={{ height: 150 }} className='hideHeight'></div>
            {/* <Container fluid className='imgTitle'>
                <h2 className='blogTitle'><span className="title-Active">OUR</span> LATEST NEWS</h2>
            </Container> */}
           <Container fluid className="m-0 p-0 bgImageNews">
                <Row className="m-0 p-0">
                    <Col md='12' xs={12} className="m-0 p-0 bannerNews">
                        <img className='imgNews' src={NewsCover} />
                        <div className="text-left-new">
                            {/* <center> */}
                            <p>News and Update</p>
                            {/* </center> */}
                        </div>
                    </Col>
                </Row>

            </Container>

            <div className='title'>
                <h2 style={{ fontSize: "18px", color: "#000000" }}><span className="title-Active">OUR</span> LATEST NEWS</h2>
            </div>

            <Container fluid className='mt-5'>
                <Row className=' card-blog'>
                    {postData?.posts?.data.map((post, index) => (
                        <Col xs='12' sm='6' md='6' lg='4' key={index} onClick={() => history.push(Consts.PAGE_BLOG_DETAIL + '/' + post?.id)} style={{ cursor: 'pointer' }}>
                            <div className='news-card'>
                                <div className="newsFlex">
                                    <div className='newsLetf'>ZWL news updates</div>
                                    <div className='newsRight'>
                                        <div style={{float: 'right'}}>
                                            <img src={LogoZero} style={{borderRadius:5}} />
                                        </div>
                                    </div>
                                </div>
                                <img
                                    className="news-img"
                                    src={Consts.URL_FOR_SHOW_PHOTO + post.imageProfile}
                                    alt="First slide"
                                />
                                <h5 style={{ fontSize: "16px", fontWeight: "800", marginTop: 10 }}>News description: {post?.topic}</h5>
                                <div style={{ display: "inline-block" }} dangerouslySetInnerHTML={{ __html: post?.contents?.length > 50 ? `${post?.contents?.substring(0, 50)}...` : (post?.contents ?? "-") }}></div>
                                <p style={{ fontSize: "14px", color: "#00A991" }}>{dateTimeLao(post?.createdAt)}</p>
                                <center>
                                <a onClick={() => history.push(Consts.PAGE_BLOG_DETAIL+ '/' + post?.id)} style={{ fontSize: "14px", color: "#00A991",textAlign: "center",fontWeight: "bold" }}> Read more </a>
                                </center>
                            </div>
                        </Col>

                    ))}



                    {/* <Col md={12} className="mt-5" >
                        <span style={{ float: 'right' }}>
                            <Link to='' className="button-news-next">More <FaAngleRight /></Link>
                        </span>
                    </Col> */}
                </Row>
            </Container>

            <Modal show={showDeveloping} onHide={handleClose} className="modal-form">
                <div style={{ float: 'right', padding: 20 }} className="formRegisterClose">
                    <FaTimes style={{ float: 'right', fontSize: 20, cursor: "pointer" }} onClick={handleClose} />
                </div>
                <Modal.Body>

                    <center>
                        <p style={{ fontSize: "16px", fontWeight: "bold" }}>Coming soon</p>
                        <p style={{ fontSize: "14px", }}>We will be celebrating the launch of our new site soon!</p>
                        <img src={Developing} style={{ width: "150px", height: "150px" }} />
                        
                    </center>

                </Modal.Body>
            </Modal>


            <Footer />

        </div>
    )
}
export default Blog