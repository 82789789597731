import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import useReactRouter from "use-react-router";


import Consts from "../../../consts"
import UploadPhoto from "../../../helpers/UploadPhoto";
import BgCoverPrograme from "./BgCoverPrograme"



import { FaAngleRight } from "react-icons/fa";
import { Modal, Container, Row, Col, Button, Form } from 'react-bootstrap'
import { FaTimes } from "react-icons/fa";

import Footer from "../../../components/footer"

import './Index.css'


function Programe3() {
    const { history } = useReactRouter();
    const {
        namePhoto,
        buttonUploadAndShowPhoto,
        setWidhtPhoto,
        setHeightPhoto,
    } = UploadPhoto();
    const [showFormRegister, setShowFormRegister] = useState(false);

    const handleCloseFormRegister = () => setShowFormRegister(false);
    const handleShowFormRegister = () => setShowFormRegister(true);



    return (
        <div style={{ zIndex: 0 }}>
            <div style={{ height: 150 }} className='hideHeight'></div>

            <Container fluid className="m-0 p-0 mb-5 bgImage-about-pro">
                <Row className="m-0 p-0">
                    <Col md='12' xs={12} className="m-0 p-0 banner-about-pro">
                        {/* <img className='imgBg-about-pro' src={coverImg} /> */}
                        <BgCoverPrograme />
                        {/* <div className="centered-about-pro">
                            <p>Our Program</p>
                        </div> */}

                        <div className="container-list">
                            <div className='list-items' onClick={() => history.push(Consts.PAGE_PROGRAME1)}>Campaign/ Workshop</div>
                            <div className='list-items ' onClick={() => history.push(Consts.PAGE_PROGRAME2)}> Research</div>
                            <div className='list-items action-programe' onClick={() => history.push(Consts.PAGE_PROGRAME3)}> Capacity Building</div>
                            <div className='list-items' onClick={() => history.push(Consts.PAGE_PROGRAME4)}> CSR Collaboration</div>
                        </div>
                    </Col>
                </Row>
            </Container>




            <Container className="">
                <Row className="proFix1">
                    <Col xs={12} className="p-5">
                        <h1 className="proTitle" style={{fontWeight: "bold"}}>Capacity Building</h1>
                        <p className="proDetailTitle">We provide opportunities for youth to improve their soft skills as well as social skills that are necessary for their future care such as leadership, teamwork, communication, system thinking, sustainability mindset, etc </p>

                    </Col>



                </Row>

            </Container>





            <Footer />
        </div>
    )
}
export default Programe3