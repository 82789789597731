import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import useReactRouter from "use-react-router";
import Avatar from 'react-avatar';

import { Formik } from 'formik'
import * as _ from "lodash";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import moment from "moment";

// import { ADDRESSES } from "../../../consts/newAddresses";
import { PROVINCE, DRISTRIC } from '../../../consts/addresses'

import Consts, { USER_KEY } from "../../../consts"

import { USER } from "../../../apollo/register/Query"
import { USER_UPDATE } from "../../../apollo/register/Mutation"

import { successAdd, errorAdd } from '../../../helpers/sweetalert'
import NewsCover from "../../../image/blog/NewsCover.png"


import { FaAngleRight } from "react-icons/fa";
import { Carousel, Container, Row, Col, Button, ListGroup, Form } from 'react-bootstrap'
import { FaSearch } from "react-icons/fa";

import Footer from "../../../components/footer"

import './Index.css'
function UserAccount() {
    const { history } = useReactRouter();

    const [updateUser] = useMutation(USER_UPDATE)

    const user = localStorage?.getItem(USER_KEY)
    const _userRole = JSON?.parse(user)

    const _userInfo = _userRole?.data

    const [dataDistrictBirth, setDataDistrictBirth] = useState([])
    const [occupationShow, setOccupationShow] = useState(true)
    const [genderData, setGenderData] = useState("MALE");
    const [dataDistrict, setDataDistrict] = useState([])

    const [plantingActivityData, setplantingActivityData] = useState("YES");
    const [intentionData, setIntentionData] = useState("TREE_DONATION");


    const [userData, { loading, data: userDataInfo }] = useLazyQuery(USER)

    useEffect(() => {
        userData({
            variables: {
                where: { id: _userInfo?.id }
            }
        })
    }, []);

    useEffect(() => {
        if (userDataInfo?.user) {
            setGenderData(userDataInfo?.user?.gender)
            if (userDataInfo?.user?.occupation === "Officer" || userDataInfo?.user?.occupation === "Student") setOccupationShow(true)
            else setOccupationShow(false)


            let _selectData = PROVINCE?.filter((item) => item?.pr_name === userDataInfo?.user?.provinceOfBirth)
            let _selectDistrict = DRISTRIC?.filter((item) => _selectData[0]?.pr_id === item?.pr_id)
            setDataDistrictBirth(_selectDistrict)

        }





    }, [userDataInfo])


    const onSelectOccupation = (e) => {
        let _occupation = e?.target?.value
        if (_occupation === "Other") setOccupationShow(false)
        else setOccupationShow(true)

    }

    // update account

    const updateAccount = async (data) => {
        try {

            let _updateAccount = await updateUser({
                variables: {
                    data: data,
                    where: { id: _userInfo?.id }
                }
            })

            if (_updateAccount) {
                successAdd("Save Done")
                history.replace(Consts.USER_APPROVED)

            }

        }
        catch (err) {
            errorAdd(err)

        }

    }



    const _selectProvinceBirth = (a) => {
        let _selectData = PROVINCE?.filter((item) => item?.pr_name === a)
        let _selectDistrict = DRISTRIC?.filter((item) => _selectData[0]?.pr_id === item?.pr_id)
        setDataDistrictBirth(_selectDistrict)
    }


    return (
        <div style={{ zIndex: 0 }}>
            <div style={{ height: 150 }} className='hideHeight'></div>
            {/* <Container fluid className='imgTitle'>
                <Row className="p-0 m-0">
                    <Col className="p-0 m-0">
                        <h2 className='blogTitle'> {_userInfo?.firstName} </h2>
                    </Col>
                </Row>
            </Container> */}
               <Container fluid className="m-0 p-0 bgImageNews">
                <Row className="m-0 p-0">
                    <Col md='12' xs={12} className="m-0 p-0 bannerNews">
                        <img className='imgNews' src={NewsCover} />
                        <div className="text-left-new">
                            {/* <center> */}
                            {/* <p>{_userInfo?.firstName}</p> */}
                            <Avatar name={_userInfo?.firstName} color="#00A991" />

                            {/* </center> */}
                        </div>
                    </Col>
                </Row>

            </Container>

            {/* <Container> */}
            <div className="containerStep">
                <ul className="progressbar">
                    <li className={userDataInfo?.user?.appover==="PENDING" || userDataInfo?.user?.appover==="WAITTING" || userDataInfo?.user?.appover==="APPROVED" || userDataInfo?.user?.appover==="NOT_APPROVED" ? "activeDone" : "active"}>
                        <span className="activeHidden">Create account</span>
                    </li>
                    <li className={userDataInfo?.user?.appover==="WAITTING" || userDataInfo?.user?.appover==="APPROVED" || userDataInfo?.user?.appover==="NOT_APPROVED" ? "activeDone" : "active"}>
                        <span className="activeHidden">Tell us about yourself</span>

                    </li>
                    <li className="active">
                        <span className="activeHidden">Wait for confirmation</span>

                    </li>
                    <li className="active">
                        <span className="activeHidden">get a certificate</span>
                    </li>
                </ul>

            </div>
            {/* </Container> */}

            {userDataInfo && (

                <Formik
                    initialValues={{

                        firstName: userDataInfo?.user?.firstName ?? "",
                        birthday: moment(userDataInfo?.user?.birthday ?? "").format("YYYY-MM-DD"),
                        phone: userDataInfo?.user?.phone ?? "",
                        whatsapp: userDataInfo?.user?.whatsapp ?? "",
                        ethnicity: userDataInfo?.user?.ethnicity ?? "",
                        nationality: userDataInfo?.user?.nationality ?? "",
                        workplace: userDataInfo?.user?.workplace ?? "",
                        occupation: userDataInfo?.user?.occupation === "Officer" || userDataInfo?.user?.occupation === "Student"  ? userDataInfo?.user?.occupation : "Other",
                        occupation1: userDataInfo?.user?.occupation ?? "" ,
                        company: userDataInfo?.user?.company ?? "",
                        provinceOfBirth: userDataInfo?.user?.provinceOfBirth ?? "",
                        districtOfBirth: userDataInfo?.user?.districtOfBirth ?? "",
                        villageOfBirth: userDataInfo?.user?.villageOfBirth ?? "",
                    }}
                    validate={values => {
                        const errors = {}
                        if (!values.firstName) {
                            errors.firstName = 'Required';
                        }
                        if (!values.birthday) {
                            errors.birthday = 'Required';
                        }
                        if (!values.phone) {
                            errors.phone = 'Required';
                        }
                        if (!values.whatsapp) {
                            errors.whatsapp = 'Required';
                        }
                        if (!values.ethnicity) {
                            errors.ethnicity = 'Required';
                        }
                        if (!values.nationality) {
                            errors.nationality = 'Required';
                        }
                        if (!values.occupation) {
                            errors.occupation = 'Required';
                        }
                        if (!values.company) {
                            errors.company = 'Required';
                        }
                        if (!values.provinceOfBirth) {
                            errors.provinceOfBirth = 'Required';
                        } else {
                            _selectProvinceBirth(values.provinceOfBirth)
                        }
                        if (!values.districtOfBirth) {
                            errors.districtOfBirth = 'Required';
                        }
                        if (!values.villageOfBirth) {
                            errors.villageOfBirth = 'Required';
                        }

                        return errors
                    }}
                    onSubmit={(values, { resetForm }) => {
                        
                        let occupations

                        if(values.occupation === "Other"){
                            occupations=values.occupation1
                            delete values.occupation
                            delete values.occupation1
                        }
                        else{
                            occupations=values.occupation
                            delete values.occupation
                            delete values.occupation1
                        }


                        let _data = {
                            ...values,
                            role: "USER",
                            appover: "WAITTING",
                            occupation:occupations,
                            gender: genderData,
                            plantingActivity: plantingActivityData,
                            intention: intentionData,
                            phone: values.phone.toString(),
                            whatsapp: values.whatsapp.toString(),
                        }

                        updateAccount(_data)

                        // resetForm({ values: '' })
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting
                        /* and other goodies */
                    }) => (
                        <div>
                            {/* // <Container>
                            <Row className="p-5"> */}
                            <Col xs={12} sm={12} md={12}>
                                <h1 className="text-center titleAccount">Tell us about yourself</h1>
                            </Col>

                            <Col xs={12} sm={12} md={12} style={{ textAlign: 'left' }}>
                                <br />
                                <p className='subTitleForm'>About Zero Waste Laos </p>
                                <hr></hr>
                            </Col>


                            <Col xs={12} sm={12} md={12} className='textLabel'>
                                <div style={{ backgroundColor: "#F6F6F6", color: "#000000", fontSize: '18px', fontWeight: "400", padding: 10, marginBottom: 10 }}>
                                    General information
                                </div>


                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Name and surname <span style={{ color: "red" }}>*</span></Form.Label>
                                    <Form.Control size='lg'
                                        type="text"
                                        name="firstName"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.firstName}
                                        className='input' placeholder="Please Name and surname" />
                                    <div style={{ color: 'red' }}>
                                        <p>{errors.firstName && touched.firstName && errors.firstName}</p>
                                    </div>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Gender <span style={{ color: "red" }}>*</span></Form.Label>
                                    <br />
                                    <div className="custom-control custom-radio custom-control-inline">

                                        <Form.Check
                                            type="radio"
                                            id="MALE"
                                            name="gender"
                                            defaultChecked
                                            onChange={() => setGenderData("MALE")}
                                            style={{ width: '40px !important', height: "40px !important" }}

                                        />
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <Form.Check.Label style={{marginTop:15}}>Male</Form.Check.Label>
                                    </div>
                                    <div className="custom-control custom-radio custom-control-inline">

                                        <Form.Check
                                            type="radio"
                                            id="FEMALE"
                                            name="gender"
                                            defaultChecked
                                            onChange={() => setGenderData("FEMALE")}
                                            style={{ width: '40px !important', height: "40px !important" }}

                                        />
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <Form.Check.Label style={{marginTop:15}}>FEMALE</Form.Check.Label>
                                    </div>

                                    <div className="custom-control custom-radio custom-control-inline">

                                        <Form.Check
                                            type="radio"
                                            id="LGBTQ+"
                                            name="gender"
                                            defaultChecked
                                            onChange={() => setGenderData("LGBTQ")}
                                            style={{ width: '40px !important', height: "40px !important" }}

                                        />
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <Form.Check.Label style={{marginTop:15}}>LGBTQ+</Form.Check.Label>

                                    </div>
                                </Form.Group>


                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Date of birth <span style={{ color: "red" }}>*</span></Form.Label>
                                    <Form.Control size='lg'
                                        className='input'
                                        type="date"
                                        name="birthday"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.birthday}
                                    />
                                    <div style={{ color: 'red' }}>
                                        <p>{errors.birthday && touched.birthday && errors.birthday}</p>
                                    </div>
                                </Form.Group>


                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Phone number <span style={{ color: "red" }}>*</span></Form.Label>
                                    <Form.Control size='lg'
                                        className='input'
                                        type="number"
                                        min='0'
                                        name="phone"
                                        style={{ fontSize: "16px" }}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.phone}
                                        placeholder="20xxxxxxx" />
                                    <div style={{ color: 'red' }}>
                                        <p>{errors.phone && touched.phone && errors.phone}</p>
                                    </div>
                                    <Form.Label>Whatsapp <span style={{ color: "red" }}>*</span></Form.Label>
                                    <Form.Control size='lg'
                                        className='input'
                                        type="number"
                                        min='0'
                                        name="whatsapp"
                                        style={{ fontSize: "16px" }}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.whatsapp}
                                        placeholder="20xxxxxxx" />
                                    <div style={{ color: 'red' }}>
                                        <p>{errors.whatsapp && touched.whatsapp && errors.whatsapp}</p>
                                    </div>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Ethnicity In case you are a foreigner, please type "0" <span style={{ color: "red" }}>*</span></Form.Label>
                                    <Form.Control size='lg'
                                        className='input'
                                        type="text"
                                        name="ethnicity"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.ethnicity}
                                        placeholder="Please input ethnicity" />
                                    <div style={{ color: 'red' }}>
                                        <p>{errors.ethnicity && touched.ethnicity && errors.ethnicity}</p>
                                    </div>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label> Nationality<span style={{ color: "red" }}>*</span></Form.Label>
                                    <Form.Control size='lg'
                                        className='input'
                                        type="text"
                                        name="nationality"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.nationality}
                                        style={{ fontSize: "16px" }} />
                                        {/* <option value='' selected disabled>Select the nationality</option>
                                        <option value='College'>College</option>
                                        <option value="Bachelor's degree">Bachelor's degree</option>
                                        <option value="Master's degree">Master's degree</option>
                                        <option value='PhD'>PhD</option>
                                        <option value='Other'>Other</option>
                                    </Form.Control> */}
                                    <div style={{ color: 'red' }}>
                                        <p>{errors.nationality && touched.nationality && errors.nationality}</p>
                                    </div>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Occupation <span style={{ color: "red" }}>*</span></Form.Label>
                                    <Form.Control size='lg'
                                        name="occupation"
                                        onChange={(e)=>{onSelectOccupation(e);handleChange(e)}}
                                        onBlur={handleBlur}
                                        value={values.occupation}
                                        className='input' as="select" style={{ fontSize: "16px" }} >
                                        <option value='' selected disabled>Select the Occupation</option>
                                        <option value='Student'>Student</option>
                                        <option value="Officer">Officer</option>
                                        <option value='Other'>Other</option>
                                    </Form.Control>
                                    
                                    <br></br>

                                    <Form.Control size='lg'
                                        className='input'
                                        type="text"
                                        hidden={occupationShow}
                                        value={values.occupation1}
                                        placeholder="Occupation etc..."
                                        name="occupation1"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        style={{ fontSize: "16px" }} />

                                    <div style={{ color: 'red' }}>
                                        <p>{errors.occupation && touched.occupation && errors.occupation}</p>
                                    </div>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Company/ Organization/ Institution <span style={{ color: "red" }}>*</span></Form.Label>
                                    <Form.Control size='lg'
                                        type="text"
                                        name="company"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.company}
                                        className='input'
                                        placeholder="Please input your Company/Organization/Institution" />
                                    <div style={{ color: 'red' }}>
                                        <p>{errors.company && touched.company && errors.company}</p>
                                    </div>
                                </Form.Group>


                                <div style={{ backgroundColor: "#F6F6F6", color: "#000000", fontSize: '18px', fontWeight: "400", padding: 10, marginBottom: 10 }}>
                                    Brith place information
                                </div>

                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Province of birth <span style={{ color: "red" }}>*</span></Form.Label>
                                    <Form.Control size='lg'
                                        name="provinceOfBirth"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.provinceOfBirth}
                                        className='input' as="select"
                                        style={{ fontSize: "16px" }}
                                    >
                                        <option value='' selected disabled >Select the Province</option>
                                        {PROVINCE?.map((item, index) =>
                                            <option value={item?.pr_name} key={index + 1} >{item?.pr_name}</option>
                                        )}
                                    </Form.Control>
                                    <div style={{ color: 'red' }}>
                                        <p>{errors.provinceOfBirth && touched.provinceOfBirth && errors.provinceOfBirth}</p>
                                    </div>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>District of birth <span style={{ color: "red" }}>*</span></Form.Label>
                                    <Form.Control size='lg'
                                        name="districtOfBirth"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.districtOfBirth}
                                        className='input'
                                        as="select"
                                        style={{ fontSize: "16px" }}>
                                        <option value='' selected disabled>Select the District</option>
                                        {dataDistrictBirth?.map((item, index) =>
                                            <option value={item?.dr_name} key={index + 1} >{item?.dr_name}</option>
                                        )}
                                    </Form.Control>
                                    <div style={{ color: 'red' }}>
                                        <p>{errors.districtOfBirth && touched.districtOfBirth && errors.districtOfBirth}</p>
                                    </div>
                                </Form.Group>


                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Village of birth <span style={{ color: "red" }}>*</span></Form.Label>
                                    <Form.Control size='lg'
                                        type="text"
                                        name="villageOfBirth"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.villageOfBirth}
                                        className='input'
                                        placeholder="Please input your Village of birth" />
                                    <div style={{ color: 'red' }}>
                                        <p>{errors.villageOfBirth && touched.villageOfBirth && errors.villageOfBirth}</p>
                                    </div>
                                </Form.Group>
                                <div style={{ backgroundColor: "#F6F6F6", color: "#000000", fontSize: '18px', fontWeight: "400", padding: 10, marginBottom: 10 }}>
                                    Plant donation
                                </div>


                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Would you join us in planting activity ? <span style={{ color: "red" }}>*</span></Form.Label>
                                    <br />
                                    <div className="custom-control custom-radio custom-control-inline">
                                        <Form.Check
                                            type="radio"
                                            id="YES"
                                            name="plantingActivity"
                                            defaultChecked
                                            onClick={() => setplantingActivityData("YES")}
                                            style={{ width: '40px !important', height: "40px !important" }}
                                        />
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <Form.Check.Label style={{marginTop:15}}>Yes</Form.Check.Label>
                                    </div>
                                    <div className="custom-control custom-radio custom-control-inline">
                                        <Form.Check
                                            type="radio"
                                            id="NO"
                                            name="plantingActivity"
                                            onClick={() => setplantingActivityData("NO")}
                                            style={{ width: '40px !important', height: "40px !important" }}
                                        />
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <Form.Check.Label style={{marginTop:15}}>No</Form.Check.Label>
                                    </div>
                                    <div className="custom-control custom-radio custom-control-inline">
                                        <Form.Check
                                            type="radio"
                                            id="MAYBE"
                                            name="plantingActivity"
                                            onClick={() => setplantingActivityData("MAYBE")}
                                            style={{ width: '40px !important', height: "40px !important" }}
                                        />
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <Form.Check.Label style={{marginTop:15}}>Maybe</Form.Check.Label>
                                    </div>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Your intention to join us ? <span style={{ color: "red" }}>*</span></Form.Label>
                                    <br />
                                    <div className="custom-control custom-radio custom-control-inline">
                                        <Form.Check
                                            type="radio"
                                            id="TREE_DONATION"
                                            name="intention"
                                            defaultChecked
                                            onClick={() => setIntentionData("TREE_DONATION")}
                                            style={{ width: '40px !important', height: "40px !important" }}
                                        />
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <Form.Check.Label style={{marginTop:15}}>Tree donation</Form.Check.Label>
                                    </div>
                                    <div className="custom-control custom-radio custom-control-inline">
                                        <Form.Check
                                            type="radio"
                                            id="PLANTING_TREE"
                                            name="intention"
                                            onClick={() => setIntentionData("PLANTING_TREE")}
                                            style={{ width: '40px !important', height: "40px !important" }}
                                        />
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <Form.Check.Label style={{marginTop:15}}>Planting tree</Form.Check.Label>
                                    </div>
                                    <div className="custom-control custom-radio custom-control-inline">
                                        <Form.Check
                                            type="radio"
                                            id="BOTH"
                                            name="intention"
                                            onClick={() => setIntentionData("BOTH")}
                                            style={{ width: '40px !important', height: "40px !important" }}
                                        />
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <Form.Check.Label style={{marginTop:15}}>Both</Form.Check.Label>
                                    </div>
                                </Form.Group>

                            </Col>







                            <Col xs={12} sm={12} md={12} className='textLabel mb-3'>
                                <Form.Group className="mt-4" controlId="exampleForm.ControlInput1">
                                    <button className="btn-login" onClick={handleSubmit}>Create account</button>

                                </Form.Group>
                            </Col>
                            {/* </Row> */}
                            {/* // </Container> */}
                        </div>
                    )}
                </Formik>
            )}


            <Footer />

        </div>
    )
}
export default UserAccount