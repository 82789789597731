import { gql } from "apollo-boost";

export const DASHBOARD = gql` 
query Dashboard($where: DashboardWhereInputId!) {
  dashboard(where: $where) {
    allUsers
    usersMale
    usersFemale
    usersLGBTQ
    allVolunteers
    volunteersMale
    volunteersFemale
    volunteersLGBTQ
    locationTree
    donatedTree
    blogs
    carbons
    donatedMoneyCount
    donatedMoneyAmount
  }
}
`;

export const USERS_DASHBOARD = gql`
query Query($where: UserWhereInput) {
  users(where: $where) {
    total
    data {
      id
      role
      firstName
      email
      appover
      birthday
      image
      phone
      createdAt
      updatedAt
      note
    }
  }
}
`;