import { gql } from "apollo-boost";


export const CERTIFICATE_CATEGORYS = gql` 
query CategoryCertificates {
  categoryCertificates {
    data {
      id
      name
      isDeleted
      detail
      createdAt
      updatedAt
      note
    }
  }
}
`

export const CERTIFICATE_CATEGORY = gql`
query CategoryCertificate($where: CategoryCertificateWhereInputId!) {
  categoryCertificate(where: $where) {
    id
    name
    isDeleted
    detail
    note
  }
}
`


