import React, { useState, useEffect } from 'react'
import useReactRouter from "use-react-router";
import { Formik } from 'formik'
import moment from "moment";



import { Breadcrumb, Row, Form, Col} from 'react-bootstrap'
import Consts from "../../../consts"

import { Title, CustomButton } from "../../../common"
import UploadPhoto from "../../../helpers/UploadPhoto";
import CUSD_STAFF from '../../../crud/user';
import { PROVINCE, DRISTRIC } from '../../../consts/addresses'

import './Index.css'

function VolunteerEdit() {
    const { history, location } = useReactRouter();

    //state
    const [dataDistrictBirth, setDataDistrictBirth] = useState([])
    const [dataDistrict, setDataDistrict] = useState([])
    const [genderData, setGenderData] = useState("MALE");
    const [volunteer, setVolunteerData] = useState(false);

    const {
        namePhoto,
        buttonUploadAndShowPhotoEdit
    } = UploadPhoto();
    const {
        _updateStaff,
    } = CUSD_STAFF();
    // function
    useEffect(() => {
        setGenderData(location?.state?.gender)
        setVolunteerData(location?.state?.volunteerBefore)
    }, [location?.state])

    // =====>
    const _selectProvinceBirth = (a) => {
        let _selectData = PROVINCE?.filter((item) => item?.pr_name === a)
        let _selectDistrict = DRISTRIC?.filter((item) => _selectData[0]?.pr_id === item?.pr_id)
        setDataDistrictBirth(_selectDistrict)
    }
    const _selectProvince = (a) => {
        let _selectData = PROVINCE?.filter((item) => item?.pr_name === a)
        let _selectDistrict = DRISTRIC?.filter((item) => _selectData[0]?.pr_id === item?.pr_id)
        setDataDistrict(_selectDistrict)
    }
    return (
        <div>
            <Breadcrumb style={{ marginTop: 60, fontSize: '12px' }}>
                <Breadcrumb.Item href="#" onClick={() => history.push(Consts.PAGE_VOLUNTEER_LIST)}>volunteer Managerment</Breadcrumb.Item>
                <Breadcrumb.Item active>Edit volunteer</Breadcrumb.Item>
            </Breadcrumb>

            <div style={Consts.MAIN_CARD} >
                <Title text='Edit volunteer' />
                <hr></hr>
                {location?.state &&
                    <Formik
                        initialValues={{
                            email: location?.state?.email,
                            firstName: location?.state?.firstName,
                            birthday: moment(location?.state?.birthday).format('YYYY-MM-DD'),
                            phone: location?.state?.phone,
                            email: location?.state?.email,
                            educationLevel: location?.state?.educationLevel,
                            englishProficiency: location?.state?.englishProficiency,
                            occupation: location?.state?.occupation,
                            workplace: location?.state?.workplace,
                            provinceOfBirth: location?.state?.provinceOfBirth,
                            districtOfBirth: location?.state?.districtOfBirth,
                            villageOfBirth: location?.state?.villageOfBirth,
                            province: location?.state?.province,
                            district: location?.state?.district,
                            village: location?.state?.village,
                            volunteerExplain: location?.state?.volunteerExplain,
                            knowAboutUs: location?.state?.knowAboutUs,
                            everJoinedZeroWaste: location?.state?.everJoinedZeroWaste,
                            tellUs: location?.state?.tellUs,
                            skillsAndProfession: location?.state?.skillsAndProfession,
                            improveYourself: location?.state?.improveYourself,
                            expectZeroWaste: location?.state?.expectZeroWaste,
                        }}
                        validate={values => {
                            const errors = {}
                            if (!values.firstName) {
                                errors.firstName = 'Required';
                            }
                            if (!values.birthday) {
                                errors.birthday = 'Required';
                            }
                            if (!values.phone) {
                                errors.phone = 'Required';
                            }
                            if (!values.email) {
                                errors.email = 'Required';
                            }
                            if (!values.educationLevel) {
                                errors.educationLevel = 'Required';
                            }
                            if (!values.englishProficiency) {
                                errors.englishProficiency = 'Required';
                            }
                            if (!values.occupation) {
                                errors.occupation = 'Required';
                            }
                            if (!values.workplace) {
                                errors.workplace = 'Required';
                            }
                            if (!values.provinceOfBirth) {
                                errors.provinceOfBirth = 'Required';
                            } else {
                                _selectProvinceBirth(values.provinceOfBirth)
                            }
                            if (!values.districtOfBirth) {
                                errors.districtOfBirth = 'Required';
                            }
                            if (!values.villageOfBirth) {
                                errors.villageOfBirth = 'Required';
                            }
                            if (!values.province) {
                                errors.province = 'Required';
                            } else {
                                _selectProvince(values.province)
                            }
                            if (!values.district) {
                                errors.district = 'Required';
                            }
                            if (!values.village) {
                                errors.village = 'Required';
                            }

                            return errors
                        }}
                        onSubmit={values => {
                            let _data = {
                                ...values,
                                role: "VOLUNTEER",
                                image: namePhoto,
                                gender: genderData,
                                volunteerBefore: volunteer,
                            }
                            // console.log("Update===>", _data)
                            _updateStaff(_data, location?.state?.id, Consts.PAGE_VOLUNTEER_LIST)
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting
                            /* and other goodies */
                        }) => (

                            <Row>
                                <Col md={3}>
                                    <Form.Label>Upload image <span style={{ color: "red" }}>*</span></Form.Label>
                                    {buttonUploadAndShowPhotoEdit(location?.state?.image)}
                                </Col>
                                <Col md={9}>
                                    <div style={{ backgroundColor: "#F6F6F6", color: "#000000", fontSize: '18px', fontWeight: "400", padding: 10, marginBottom: 10 }}>
                                        General information
                                    </div>

                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Name and surname <span style={{ color: "red" }}>*</span></Form.Label>
                                        <Form.Control size='lg'
                                            type="text"
                                            name="firstName"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.firstName}
                                            className='input' placeholder="Please Name and surname" />
                                        <div style={{ color: 'red' }}>
                                            <p>{errors.firstName && touched.firstName && errors.firstName}</p>
                                        </div>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Gender <span style={{ color: "red" }}>*</span></Form.Label>
                                        <br />
                                        <div className="custom-control custom-radio custom-control-inline">

                                            <Form.Check
                                                type="radio"
                                                id="MALE"
                                                name="gender"
                                                checked={genderData === "MALE"}
                                                onChange={() => setGenderData("MALE")}
                                                style={{ width: '20px !important', height: "20px !important" }}

                                            />
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <Form.Check.Label>ຊາຍ</Form.Check.Label>
                                        </div>
                                        <div className="custom-control custom-radio custom-control-inline">

                                            <Form.Check
                                                type="radio"
                                                id="FEMALE"
                                                name="gender"
                                                checked={genderData === "FEMALE"}
                                                onChange={() => setGenderData("FEMALE")}
                                                style={{ width: '20px !important', height: "20px !important" }}

                                            />
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <Form.Check.Label>ຍິງ</Form.Check.Label>
                                        </div>

                                        <div className="custom-control custom-radio custom-control-inline">

                                            <Form.Check
                                                type="radio"
                                                id="LGBTQ+"
                                                name="gender"
                                                defaultChecked
                                                onChange={() => setGenderData("LGBTQ")}
                                                style={{ width: '20px !important', height: "20px !important" }}

                                            />
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <Form.Check.Label>LGBTQ+</Form.Check.Label>

                                        </div>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Date of birth <span style={{ color: "red" }}>*</span></Form.Label>
                                        <Form.Control size='lg'
                                            className='input'
                                            type="date"
                                            name="birthday"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.birthday}
                                        />
                                        <div style={{ color: 'red' }}>
                                            <p>{errors.birthday && touched.birthday && errors.birthday}</p>
                                        </div>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Phone number <span style={{ color: "red" }}>*</span></Form.Label>
                                        <Form.Control size='lg'
                                            className='input'
                                            type="text"
                                            name="phone"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.phone}
                                            placeholder="20xxxxxxx" />
                                        <div style={{ color: 'red' }}>
                                            <p>{errors.phone && touched.phone && errors.phone}</p>
                                        </div>
                                    </Form.Group>


                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Email <span style={{ color: "red" }}>*</span></Form.Label>
                                        <Form.Control size='lg'
                                            className='input'
                                            type="email"
                                            name="email"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.email}
                                            placeholder="Please input Email" />
                                        <div style={{ color: 'red' }}>
                                            <p>{errors.email && touched.email && errors.email}</p>
                                        </div>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Education level <span style={{ color: "red" }}>*</span></Form.Label>
                                        <Form.Control size='lg'
                                            className='input'
                                            as="select"
                                            name="educationLevel"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.educationLevel}
                                            style={{ fontSize: "16px" }}>
                                            <option value='' selected disabled>Select the Education level</option>
                                            <option value='College'>College</option>
                                            <option value="Bachelor's degree">Bachelor's degree</option>
                                            <option value="Master's degree">Master's degree</option>
                                            <option value='PhD'>PhD</option>
                                            <option value='Other'>Other</option>
                                        </Form.Control>
                                        <div style={{ color: 'red' }}>
                                            <p>{errors.educationLevel && touched.educationLevel && errors.educationLevel}</p>
                                        </div>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>English proficiency <span style={{ color: "red" }}>*</span></Form.Label>
                                        <Form.Control size='lg' className='input'
                                            name="englishProficiency"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.englishProficiency}
                                            as="select" style={{ fontSize: "16px" }}>
                                            <option value='' selected disabled>Select the English proficiency</option>
                                            <option value='Beginner'>Beginner</option>
                                            <option value="Elementary">Elementary</option>
                                            <option value="Lower intermediate">Lower intermediate</option>
                                            <option value='Intermediate'>Intermediate</option>
                                            <option value='Upper intermediate'>Upper intermediate</option>
                                            <option value='Advance'>Advance</option>
                                        </Form.Control>
                                        <div style={{ color: 'red' }}>
                                            <p>{errors.englishProficiency && touched.englishProficiency && errors.englishProficiency}</p>
                                        </div>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Occupation <span style={{ color: "red" }}>*</span></Form.Label>
                                        <Form.Control size='lg'
                                            name="occupation"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.occupation}
                                            className='input' as="select" style={{ fontSize: "16px" }} >
                                            <option value='' selected disabled>Select the Occupation</option>
                                            <option value='Student'>Student</option>
                                            <option value="Officer">Officer</option>
                                            <option value='Other'>Other</option>
                                        </Form.Control>
                                        <div style={{ color: 'red' }}>
                                            <p>{errors.occupation && touched.occupation && errors.occupation}</p>
                                        </div>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>School/Institute/Workplace <span style={{ color: "red" }}>*</span></Form.Label>
                                        <Form.Control size='lg'
                                            type="text"
                                            name="workplace"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.workplace}
                                            className='input'
                                            placeholder="Please input your School/Institute/Workplace" />
                                        <div style={{ color: 'red' }}>
                                            <p>{errors.workplace && touched.workplace && errors.workplace}</p>
                                        </div>
                                    </Form.Group>

                                    <div style={{ backgroundColor: "#F6F6F6", color: "#000000", fontSize: '18px', fontWeight: "400", padding: 10, marginBottom: 10 }}>
                                        Brith place information
                                    </div>

                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Province of birth <span style={{ color: "red" }}>*</span></Form.Label>
                                        <Form.Control size='lg'
                                            name="provinceOfBirth"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.provinceOfBirth}
                                            className='input' as="select"
                                            style={{ fontSize: "16px" }}
                                        >
                                            <option value='' selected disabled >Select the Province</option>
                                            {PROVINCE?.map((item, index) =>
                                                <option value={item?.pr_name} key={index + 1} >{item?.pr_name}</option>
                                            )}
                                        </Form.Control>
                                        <div style={{ color: 'red' }}>
                                            <p>{errors.provinceOfBirth && touched.provinceOfBirth && errors.provinceOfBirth}</p>
                                        </div>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>District of birth <span style={{ color: "red" }}>*</span></Form.Label>
                                        <Form.Control size='lg'
                                            name="districtOfBirth"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.districtOfBirth}
                                            className='input'
                                            as="select"
                                            style={{ fontSize: "16px" }}>
                                            <option value='' selected disabled>Select the District</option>
                                            {dataDistrictBirth?.map((item, index) =>
                                                <option value={item?.dr_name} key={index + 1} >{item?.dr_name}</option>
                                            )}
                                        </Form.Control>
                                        <div style={{ color: 'red' }}>
                                            <p>{errors.districtOfBirth && touched.districtOfBirth && errors.districtOfBirth}</p>
                                        </div>
                                    </Form.Group>

                                    {/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Village of birth <span style={{ color: "red" }}>*</span></Form.Label>
                                    <Form.Control size='lg' className='input' as="select" style={{fontSize:"16px"}}>
                                        <option value=''>Select the Village</option>
                                      
                                    </Form.Control>
                                </Form.Group> */}
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Village of birth <span style={{ color: "red" }}>*</span></Form.Label>
                                        <Form.Control size='lg'
                                            type="text"
                                            name="villageOfBirth"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.villageOfBirth}
                                            className='input'
                                            placeholder="Please input your Village of birth" />
                                        <div style={{ color: 'red' }}>
                                            <p>{errors.villageOfBirth && touched.villageOfBirth && errors.villageOfBirth}</p>
                                        </div>
                                    </Form.Group>

                                    <div style={{ backgroundColor: "#F6F6F6", color: "#000000", fontSize: '18px', fontWeight: "400", padding: 10, marginBottom: 10 }}>
                                        Current address information
                                    </div>

                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Province <span style={{ color: "red" }}>*</span></Form.Label>
                                        <Form.Control size='lg'
                                            name="province"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.province}
                                            className='input' as="select" style={{ fontSize: "16px" }}>
                                            <option value='' selected disabled >Select the Province</option>
                                            {PROVINCE?.map((item, index) =>
                                                <option value={item?.pr_name} key={index + 1} >{item?.pr_name}</option>
                                            )}
                                        </Form.Control>
                                        <div style={{ color: 'red' }}>
                                            <p>{errors.province && touched.province && errors.province}</p>
                                        </div>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>District <span style={{ color: "red" }}>*</span></Form.Label>
                                        <Form.Control size='lg'
                                            name="district"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.district}
                                            className='input' as="select" style={{ fontSize: "16px" }}>
                                            <option value='' selected disabled>Select the District</option>
                                            {dataDistrict?.map((item, index) =>
                                                <option value={item?.dr_name} key={index + 1} >{item?.dr_name}</option>
                                            )}
                                        </Form.Control>
                                        <div style={{ color: 'red' }}>
                                            <p>{errors.district && touched.district && errors.district}</p>
                                        </div>
                                    </Form.Group>

                                    {/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Village <span style={{ color: "red" }}>*</span></Form.Label>
                                    <Form.Control size='lg' className='input' as="select" style={{fontSize:"16px"}}>
                                        <option value=''>Select the Village</option>
                                      
                                    </Form.Control>
                                </Form.Group> */}
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>village <span style={{ color: "red" }}>*</span></Form.Label>
                                        <Form.Control size='lg'
                                            type="text"
                                            name="village"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.village}
                                            className='input'
                                            placeholder="Please input your village" />
                                        <div style={{ color: 'red' }}>
                                            <p>{errors.village && touched.village && errors.village}</p>
                                        </div>
                                    </Form.Group>

                                    <div style={{ backgroundColor: "#F6F6F6", color: "#000000", fontSize: '18px', fontWeight: "400", padding: 10, marginBottom: 10 }}>
                                        About Zero Waste Laos
                                    </div>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Have you ever been a volunteer before? <span style={{ color: "red" }}>*</span></Form.Label>
                                        <br />

                                        <div className="custom-control custom-radio custom-control-inline">

                                            <Form.Check
                                                type="radio"
                                                id="YES"
                                                name="volunteer"
                                                checked={volunteer === true}
                                                onClick={() => setVolunteerData(true)}
                                                style={{ width: '20px !important', height: "20px !important" }}

                                            />
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <Form.Check.Label>Yes</Form.Check.Label>
                                        </div>

                                        <div className="custom-control custom-radio custom-control-inline">

                                            <Form.Check
                                                type="radio"
                                                id="NO"
                                                name="volunteer"
                                                checked={volunteer === false}
                                                onClick={() => setVolunteerData(false)}
                                                style={{ width: '20px !important', height: "20px !important" }}

                                            />
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <Form.Check.Label>No</Form.Check.Label>

                                        </div>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Where did you work as a volunteer? explain</Form.Label>
                                        <Form.Control size='lg'
                                            name="volunteerExplain"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.volunteerExplain}
                                            className='input' type="text" placeholder="Please explain" />
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>How do you know about us?</Form.Label>
                                        <Form.Control size='lg'
                                            name="knowAboutUs"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.knowAboutUs}
                                            className='input' as="select" style={{ fontSize: "16px" }}>
                                            <option value='' selected disabled>Please selecc</option>
                                            <option value='Facebook'>Facebook</option>
                                            <option value='Website'>Website</option>
                                            <option value='YouTube'>YouTube</option>
                                            <option value='Other'>Other</option>
                                        </Form.Control>
                                    </Form.Group>


                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Have you ever joined any event held by Zero Waste Laos?</Form.Label>
                                        <Form.Control as="textarea"
                                            name="everJoinedZeroWaste"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.everJoinedZeroWaste}
                                            placeholder="Please input .." style={{ height: '100px', fontSize: "16px" }} />
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Please tell us why do you want to be a part of our core team?</Form.Label>
                                        <Form.Control as="textarea"
                                            name="tellUs"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.tellUs}
                                            placeholder="Please input .." style={{ height: '100px', fontSize: "16px" }} />
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Please tell us about your skills and profession</Form.Label>
                                        <Form.Control as="textarea"
                                            name="skillsAndProfession"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.skillsAndProfession}
                                            placeholder="Please input .." style={{ height: '100px', fontSize: "16px" }} />
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>How do you want to improve yourself?</Form.Label>
                                        <Form.Control as="textarea"
                                            name="improveYourself"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.improveYourself}
                                            placeholder="Please input .." style={{ height: '100px', fontSize: "16px" }} />
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>What do you expect from Zero Waste Laos?</Form.Label>
                                        <Form.Control as="textarea"
                                            name="expectZeroWaste"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.expectZeroWaste}
                                            placeholder="Please input .." style={{ height: '100px', fontSize: "16px" }} />
                                    </Form.Group>

                                    <center>
                                        <CustomButton cancel title='Cancel' style={{ width: "199px", height: '44px', border: 0 }} onClick={() => history.goBack()} />
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <CustomButton addIcon title='Edit volunteer' style={{ width: "199px", height: '44px' }} onClick={() => handleSubmit()} />
                                    </center>

                                </Col>
                            </Row>

                        )}
                    </Formik>
                }
            </div>



        </div>
    )
}
export default VolunteerEdit

