import React, { useState,useEffect } from 'react'
import { Link } from 'react-router-dom';
import useReactRouter from "use-react-router";
import { useLazyQuery } from '@apollo/react-hooks'

import { COUNT_VISITER } from "../apollo/visiter/Query"


import Consts from '../consts/index'
import Logo from "../image/logo/LogoZero.png"

import './footer.css'
import { Navbar as NavbarMenu, Container, Row, Col } from 'react-bootstrap'
import { FaFacebookSquare, FaYoutubeSquare, FaInstagramSquare, FaAngleDown } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";


const Footer = () => {
    const { history } = useReactRouter();
    const _onLogout = () => {
        localStorage.clear()
        sessionStorage.clear()
        history.push(Consts.PAGE_HOME)
        window.location.reload();

    }
  
    const [loadCountVister, {data: dataVister}] = useLazyQuery(COUNT_VISITER)
    console.log("dataVister -->",dataVister)
    //useEffect

    useEffect(() => {
        loadCountVister()
    },[])


    return (
        <>
            <Container fluid style={{backgroundColor:"#334155"}} className="footerBox">
                <Row className='p-5 footer-tag' >
                    <Col xs={12} sm={4} md={4} lg={2}>
                        <center>
                            <img className="menu-logo-img" onClick={()=>history.push('/')} style={{width:100, height:100, borderRadius:"50%"}} src={Logo} />
                        </center>
                    </Col>
                    <Col xs={12} sm={4} md={4} lg={2}>
                        <p className='TitleName' style={{cursor: 'pointer', fontWeight: "bold"}} onClick={()=>history.push('/')}>Home</p>
                        <ul>
                            {/* <li><a href="#ourPrograme" style={{cursor: 'pointer', textDecoration:"none" }}>Our Program</a></li>
                            <li><a href="#ourPartner" style={{cursor: 'pointer', textDecoration:"none"  }}>Our Partners</a></li>
                            <li><a href="#ourLastestNews" style={{cursor: 'pointer', textDecoration:"none"  }}>Our Lastest news</a></li> */}

                             <li onClick={()=>history.push('/')} style={{cursor: 'pointer' }}><a>Our Program</a></li>
                            <li onClick={()=>history.push('/')} style={{cursor: 'pointer' }}>Our Partners</li>
                            <li onClick={()=>history.push('/')} style={{cursor: 'pointer' }}>Our Lastest news</li> 
                        </ul>
                    </Col>
                    <Col xs={12} sm={4} md={4} lg={2}>
                        <p className='TitleName' onClick={()=>history.push(Consts.PAGE_ABOUT)} style={{cursor: 'pointer', fontWeight: "bold" }}>About us</p>
                        <ul>
                        {/* <li><a href="#ourStory" style={{cursor: 'pointer', textDecoration:"none" }}>Story</a></li>
                        <li><a href="#ourMission" style={{cursor: 'pointer', textDecoration:"none" }}>Our Mission</a></li>
                        <li><a href="#ourTeam" style={{cursor: 'pointer', textDecoration:"none" }}>Our Team</a></li> */}

                            {/* <li><a hrefໍ="#ourStory" style={{cursor: 'pointer', textDecoration:"none"  }}>Story</a></li>
                            <li><a hrefໍ="#ourMission" style={{cursor: 'pointer', textDecoration:"none"  }}>Our Mission</a></li>
                            <li><a hrefໍ="#ourTeam" style={{cursor: 'pointer', textDecoration:"none"  }}>Our Team</a></li> */}
                            <li onClick={()=>history.push(Consts.PAGE_ABOUT)} style={{cursor: 'pointer' }}>Story</li>
                            <li onClick={()=>history.push(Consts.PAGE_ABOUT)} style={{cursor: 'pointer' }}>Our Mission</li>
                            <li onClick={()=>history.push(Consts.PAGE_ABOUT)} style={{cursor: 'pointer' }}>Our Team</li>
                        </ul>
                    </Col>
                 
                    <Col xs={12} sm={4} md={4} lg={2}>
                        <p className='TitleName' onClick={()=>history.push(Consts.PAGE_PROGRAME1)} style={{cursor: 'pointer', fontWeight: "bold" }}>Program</p>
                        <ul>
                            <li onClick={()=>history.push(Consts.PAGE_PROGRAME1)} style={{cursor: 'pointer' }}>Campaign/ Workshop</li>
                            <li onClick={()=>history.push(Consts.PAGE_PROGRAME2)} style={{cursor: 'pointer' }}>Research</li>
                            <li onClick={()=>history.push(Consts.PAGE_PROGRAME3)} style={{cursor: 'pointer' }}>Capacity Building</li>
                            <li  onClick={()=>history.push(Consts.PAGE_PROGRAME4)} style={{cursor: 'pointer' }}>CSR Collaboration </li>
                        </ul>
                    </Col>
                    
                    <Col xs={12} sm={4} md={4} lg={2}>
                        <p className='TitleName' onClick={()=>history.push(Consts.PAGE_VOLUNTEER)} style={{cursor: 'pointer', fontWeight: "bold" }}>Volunteer</p>
                        <ul>
                            {/* <li><a href="#AboutVolunteer" style={{cursor: 'pointer', textDecoration:"none"  }}>About Our Volunteering</a></li> */}
                            <li onClick={()=>history.push(Consts.PAGE_VOLUNTEER)} style={{cursor: 'pointer' }}>About Our Volunteering</li>
                            {/* <li onClick={()=>history.push(Consts.PAGE_VOLUNTEER)} style={{cursor: 'pointer' }}>Program's Benefit</li>
                            <li onClick={()=>history.push(Consts.PAGE_VOLUNTEER)} style={{cursor: 'pointer' }}>Our Volunteers Say</li> */}
                            {/* <li><a href="#register" style={{cursor: 'pointer', textDecoration:"none"  }}>Register to volunteer</a></li> */}
                            <li onClick={()=>history.push(Consts.PAGE_VOLUNTEER)} style={{cursor: 'pointer' }}>Register to volunteer</li>
                        </ul>
                    </Col>
                    <Col xs={12} sm={4} md={4} lg={2}>
                        <p onClick={()=>history.push(Consts.PAGE_CONTACT)} style={{cursor: 'pointer', fontWeight: "bold" }} className='TitleName'>Contact us</p>
                        <ul>
                            <li><a href="https://www.facebook.com/Zerowastelaos.Org/">Facebook</a></li>
                            <li><a href="https://www.clubhouse.com/@zwl_laos"> Clubhouse</a></li>
                            <li><a href="https://www.youtube.com/channel/UCALxH4u0bT9JZ64Lk2UaKgQ">Youtube</a></li>
                        </ul>
                    </Col>  
                    <Col xs={12} md={12} className='mt-3'>
                        <p className='footerText'>© Copyright 2022 zerowastelaos.org , Viewed {dataVister?.countSiter?.count=== null ? 0 :dataVister?.countSiter?.count} times</p>
                    </Col>
                </Row>
            </Container>


        </>
    )
}

export default Footer
