import React, { useState, useEffect } from 'react'
import useReactRouter from "use-react-router";
import { Formik } from 'formik'
import moment from "moment";

import { Breadcrumb, Row, Form, Col } from 'react-bootstrap'
import Consts from "../../../consts"
import { Title, CustomButton } from "../../../common"
import UploadPhoto from "../../../helpers/UploadPhoto";
import CUSD_STAFF from '../../../crud/user';
import { PROVINCE, DRISTRIC } from '../../../consts/addresses'
import MapContainer from "../../../consts/locationMap"

// import './Index.css'

function ClimateAdd() {
    const { history, location } = useReactRouter();

    //state
    const [dataDistrictBirth, setDataDistrictBirth] = useState([])
    const [genderData, setGenderData] = useState("MALE");
    const [plantingActivityData, setplantingActivityData] = useState("YES");
    const [intentionData, setIntentionData] = useState("TREE_DONATION");

    const {
        namePhoto,
        buttonUploadAndShowPhotoEdit,
    } = UploadPhoto();
    const {
        _updateStaff,
    } = CUSD_STAFF();
    // function
    useEffect(() => {
        setGenderData(location?.state?.gender)
        setplantingActivityData(location?.state?.plantingActivity)
        setIntentionData(location?.state?.intention)
    }, [location?.state])

    const _selectProvinceBirth = (a) => {
        let _selectData = PROVINCE?.filter((item) => item?.pr_name === a)
        let _selectDistrict = DRISTRIC?.filter((item) => _selectData[0]?.pr_id === item?.pr_id)
        setDataDistrictBirth(_selectDistrict)
    }

    return (
        <div>
            <Breadcrumb style={{ marginTop: 60, fontSize: '12px' }}>
                <Breadcrumb.Item href="#" onClick={() => history.goBack()}>Climate Managerment</Breadcrumb.Item>
                <Breadcrumb.Item active>Edit climate</Breadcrumb.Item>
            </Breadcrumb>
            <div style={Consts.MAIN_CARD} >
                <Title text='Edit climate' />
                <hr></hr>
                {location?.state &&
                    <Formik
                        initialValues={{
                            email: location?.state?.email,
                            firstName: location?.state?.firstName,
                            birthday: moment(location?.state?.birthday).format('YYYY-MM-DD'),
                            phone: location?.state?.phone,
                            whatsapp: location?.state?.whatsapp,
                            ethnicity: location?.state?.ethnicity,
                            nationality: location?.state?.nationality,
                            occupation: location?.state?.occupation,
                            company: location?.state?.company,
                            image: location?.state?.image,
                            provinceOfBirth: location?.state?.provinceOfBirth,
                            districtOfBirth: location?.state?.districtOfBirth,
                            villageOfBirth: location?.state?.villageOfBirth,
                        }}
                        validate={values => {
                            const errors = {}
                            if (!values.email) {
                                errors.email = 'Required';
                            }
                            if (!values.firstName) {
                                errors.firstName = 'Required';
                            }
                            if (values.password) {
                                if (values.password !== values.ConfirmPassword) {
                                    errors.password = 'Required';
                                    errors.ConfirmPassword = 'Required';
                                }
                            }
                            if (!values.birthday) {
                                errors.birthday = 'Required';
                            }
                            if (!values.phone) {
                                errors.phone = 'Required';
                            }
                            if (!values.whatsapp) {
                                errors.whatsapp = 'Required';
                            }
                            if (!values.ethnicity) {
                                errors.ethnicity = 'Required';
                            }
                            if (!values.nationality) {
                                errors.nationality = 'Required';
                            }
                            if (!values.occupation) {
                                errors.occupation = 'Required';
                            }
                            if (!values.company) {
                                errors.company = 'Required';
                            }
                            if (!values.provinceOfBirth) {
                                errors.provinceOfBirth = 'Required';
                            } else {
                                _selectProvinceBirth(values.provinceOfBirth)
                            }
                            if (!values.districtOfBirth) {
                                errors.districtOfBirth = 'Required';
                            }
                            if (!values.villageOfBirth) {
                                errors.villageOfBirth = 'Required';
                            }

                            return errors
                        }}
                        onSubmit={values => {
                            let _data = {
                                ...values,
                                role: "USER",
                                image: namePhoto,
                                gender: genderData,
                                plantingActivity: plantingActivityData,
                                intention: intentionData,
                            }
                            if (_data?.password === "") {
                                delete _data?.password
                            } else {
                                delete _data.ConfirmPassword
                            }
                            _updateStaff(_data, location?.state?.id, Consts.PAGE_CLIMATE_LIST)

                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting
                            /* and other goodies */
                        }) => (

                            <Row>
                                <Col md={3}>
                                    <Form.Label>Upload image <span style={{ color: "red" }}>*</span></Form.Label>
                                    {buttonUploadAndShowPhotoEdit(location?.state?.image)}
                                </Col>
                                <Col md={9}>
                                    <div style={{ backgroundColor: "#F6F6F6", color: "#000000", fontSize: '18px', fontWeight: "400", padding: 10, marginBottom: 10 }}>
                                        Create User and Password
                                    </div>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Email <span style={{ color: "red" }}>*</span></Form.Label>
                                        <Form.Control size='lg'
                                            type="text"
                                            name="email"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.email}
                                            className='input' placeholder="Please Name and surname" />
                                        <div style={{ color: 'red' }}>
                                            <p>{errors.email && touched.email && errors.email}</p>
                                        </div>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Password <span style={{ color: "red" }}>*</span></Form.Label>
                                        <Form.Control size='lg'
                                            type="text"
                                            name="password"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.password}
                                            className='input' placeholder="Please Name and Password" />
                                        <div style={{ color: 'red' }}>
                                            <p>{errors.password && touched.password && errors.password}</p>
                                        </div>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Confirm Password <span style={{ color: "red" }}>*</span></Form.Label>
                                        <Form.Control size='lg'
                                            type="text"
                                            name="ConfirmPassword"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.ConfirmPassword}
                                            className='input' placeholder="Please Name and surname" />
                                        <div style={{ color: 'red' }}>
                                            <p>{errors.ConfirmPassword && touched.ConfirmPassword && errors.ConfirmPassword}</p>
                                        </div>
                                    </Form.Group>
                                    <div style={{ backgroundColor: "#F6F6F6", color: "#000000", fontSize: '18px', fontWeight: "400", padding: 10, marginBottom: 10 }}>
                                        General information
                                    </div>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Name and surname <span style={{ color: "red" }}>*</span></Form.Label>
                                        <Form.Control size='lg'
                                            type="text"
                                            name="firstName"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.firstName}
                                            className='input' placeholder="Please Name and surname" />
                                        <div style={{ color: 'red' }}>
                                            <p>{errors.firstName && touched.firstName && errors.firstName}</p>
                                        </div>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Gender <span style={{ color: "red" }}>*</span></Form.Label>
                                        <br />
                                        <div className="custom-control custom-radio custom-control-inline">

                                            <Form.Check
                                                type="radio"
                                                id="MALE"
                                                name="gender"
                                                checked={genderData === "MALE"}
                                                onChange={() => setGenderData("MALE")}
                                                style={{ width: '20px !important', height: "20px !important" }}

                                            />
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <Form.Check.Label>Male</Form.Check.Label>
                                        </div>
                                        <div className="custom-control custom-radio custom-control-inline">

                                            <Form.Check
                                                type="radio"
                                                id="FEMALE"
                                                name="gender"
                                                checked={genderData === "FEMALE"}
                                                onChange={() => setGenderData("FEMALE")}
                                                style={{ width: '20px !important', height: "20px !important" }}

                                            />
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <Form.Check.Label>FEMALE</Form.Check.Label>
                                        </div>

                                        <div className="custom-control custom-radio custom-control-inline">

                                            <Form.Check
                                                type="radio"
                                                id="LGBTQ+"
                                                name="gender"
                                                checked={genderData === "LGBTQ"}
                                                onChange={() => setGenderData("LGBTQ")}
                                                style={{ width: '20px !important', height: "20px !important" }}

                                            />
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <Form.Check.Label>LGBTQ+</Form.Check.Label>

                                        </div>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Date of birth <span style={{ color: "red" }}>*</span></Form.Label>
                                        <Form.Control size='lg'
                                            className='input'
                                            type="date"
                                            name="birthday"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.birthday}
                                        />
                                        <div style={{ color: 'red' }}>
                                            <p>{errors.birthday && touched.birthday && errors.birthday}</p>
                                        </div>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Phone number <span style={{ color: "red" }}>*</span></Form.Label>
                                        <Form.Control size='lg'
                                            className='input'
                                            type="text"
                                            name="phone"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.phone}
                                            placeholder="20xxxxxxx" />
                                        <div style={{ color: 'red' }}>
                                            <p>{errors.phone && touched.phone && errors.phone}</p>
                                        </div>
                                        <Form.Label>Whatsapp <span style={{ color: "red" }}>*</span></Form.Label>
                                        <Form.Control size='lg'
                                            className='input'
                                            type="text"
                                            name="whatsapp"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.whatsapp}
                                            placeholder="20xxxxxxx" />
                                        <div style={{ color: 'red' }}>
                                            <p>{errors.whatsapp && touched.whatsapp && errors.whatsapp}</p>
                                        </div>
                                    </Form.Group>


                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Ethnicity In case you are a foreigner, please type "0" <span style={{ color: "red" }}>*</span></Form.Label>
                                        <Form.Control size='lg'
                                            className='input'
                                            type="text"
                                            name="ethnicity"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.ethnicity}
                                            placeholder="Please input ethnicity" />
                                        <div style={{ color: 'red' }}>
                                            <p>{errors.ethnicity && touched.ethnicity && errors.ethnicity}</p>
                                        </div>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label> Nationality<span style={{ color: "red" }}>*</span></Form.Label>
                                        <Form.Control size='lg'
                                            className='input'
                                            as="select"
                                            name="nationality"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.nationality}
                                            style={{ fontSize: "16px" }}>
                                            <option value='' selected disabled>Select the nationality</option>
                                            <option value='College'>College</option>
                                            <option value="Bachelor's degree">Bachelor's degree</option>
                                            <option value="Master's degree">Master's degree</option>
                                            <option value='PhD'>PhD</option>
                                            <option value='Other'>Other</option>
                                        </Form.Control>
                                        <div style={{ color: 'red' }}>
                                            <p>{errors.nationality && touched.nationality && errors.nationality}</p>
                                        </div>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Occupation <span style={{ color: "red" }}>*</span></Form.Label>
                                        <Form.Control size='lg'
                                            name="occupation"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.occupation}
                                            className='input' as="select" style={{ fontSize: "16px" }} >
                                            <option value='' selected disabled>Select the Occupation</option>
                                            <option value='Student'>Student</option>
                                            <option value="Officer">Officer</option>
                                            <option value='Other'>Other</option>
                                        </Form.Control>
                                        <div style={{ color: 'red' }}>
                                            <p>{errors.occupation && touched.occupation && errors.occupation}</p>
                                        </div>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Company/ Organization/ Institution <span style={{ color: "red" }}>*</span></Form.Label>
                                        <Form.Control size='lg'
                                            type="text"
                                            name="company"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.company}
                                            className='input'
                                            placeholder="Please input your Company/Organization/Institution" />
                                        <div style={{ color: 'red' }}>
                                            <p>{errors.company && touched.company && errors.company}</p>
                                        </div>
                                    </Form.Group>

                                    <div style={{ backgroundColor: "#F6F6F6", color: "#000000", fontSize: '18px', fontWeight: "400", padding: 10, marginBottom: 10 }}>
                                        Brith place information
                                    </div>

                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Province of birth <span style={{ color: "red" }}>*</span></Form.Label>
                                        <Form.Control size='lg'
                                            name="provinceOfBirth"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.provinceOfBirth}
                                            className='input' as="select"
                                            style={{ fontSize: "16px" }}
                                        >
                                            <option value='' selected disabled >Select the Province</option>
                                            {PROVINCE?.map((item, index) =>
                                                <option value={item?.pr_name} key={index + 1} >{item?.pr_name}</option>
                                            )}
                                        </Form.Control>
                                        <div style={{ color: 'red' }}>
                                            <p>{errors.provinceOfBirth && touched.provinceOfBirth && errors.provinceOfBirth}</p>
                                        </div>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>District of birth <span style={{ color: "red" }}>*</span></Form.Label>
                                        <Form.Control size='lg'
                                            name="districtOfBirth"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.districtOfBirth}
                                            className='input'
                                            as="select"
                                            style={{ fontSize: "16px" }}>
                                            <option value='' selected disabled>Select the District</option>
                                            {dataDistrictBirth?.map((item, index) =>
                                                <option value={item?.dr_name} key={index + 1} >{item?.dr_name}</option>
                                            )}
                                        </Form.Control>
                                        <div style={{ color: 'red' }}>
                                            <p>{errors.districtOfBirth && touched.districtOfBirth && errors.districtOfBirth}</p>
                                        </div>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Village of birth <span style={{ color: "red" }}>*</span></Form.Label>
                                        <Form.Control size='lg'
                                            type="text"
                                            name="villageOfBirth"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.villageOfBirth}
                                            className='input'
                                            placeholder="Please input your Village of birth" />
                                        <div style={{ color: 'red' }}>
                                            <p>{errors.villageOfBirth && touched.villageOfBirth && errors.villageOfBirth}</p>
                                        </div>
                                    </Form.Group>
                                    <div style={{ backgroundColor: "#F6F6F6", color: "#000000", fontSize: '18px', fontWeight: "400", padding: 10, marginBottom: 10 }}>
                                        Plant donation
                                    </div>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Would you join us in planting activity ? <span style={{ color: "red" }}>*</span></Form.Label>
                                        <br />
                                        <div className="custom-control custom-radio custom-control-inline">
                                            <Form.Check
                                                type="radio"
                                                id="YES"
                                                name="plantingActivity"
                                                checked={plantingActivityData === "YES"}
                                                onClick={() => setplantingActivityData("YES")}
                                                style={{ width: '20px !important', height: "20px !important" }}
                                            />
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <Form.Check.Label>Yes</Form.Check.Label>
                                        </div>
                                        <div className="custom-control custom-radio custom-control-inline">
                                            <Form.Check
                                                type="radio"
                                                id="NO"
                                                name="plantingActivity"
                                                checked={plantingActivityData === "NO"}
                                                onClick={() => setplantingActivityData("NO")}
                                                style={{ width: '20px !important', height: "20px !important" }}
                                            />
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <Form.Check.Label>No</Form.Check.Label>
                                        </div>
                                        <div className="custom-control custom-radio custom-control-inline">
                                            <Form.Check
                                                type="radio"
                                                id="MAYBE"
                                                name="plantingActivity"
                                                checked={plantingActivityData === "MAYBE"}
                                                onClick={() => setplantingActivityData("MAYBE")}
                                                style={{ width: '20px !important', height: "20px !important" }}
                                            />
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <Form.Check.Label>Maybe</Form.Check.Label>
                                        </div>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Your intention to join us ? <span style={{ color: "red" }}>*</span></Form.Label>
                                        <br />
                                        <div className="custom-control custom-radio custom-control-inline">
                                            <Form.Check
                                                type="radio"
                                                id="TREE_DONATION"
                                                name="intention"
                                                checked={intentionData === "TREE_DONATION"}
                                                onClick={() => setIntentionData("TREE_DONATION")}
                                                style={{ width: '20px !important', height: "20px !important" }}
                                            />
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <Form.Check.Label>Tree donation</Form.Check.Label>
                                        </div>
                                        <div className="custom-control custom-radio custom-control-inline">
                                            <Form.Check
                                                type="radio"
                                                id="PLANTING_TREE"
                                                name="intention"
                                                checked={intentionData === "PLANTING_TREE"}
                                                onClick={() => setIntentionData("PLANTING_TREE")}
                                                style={{ width: '20px !important', height: "20px !important" }}
                                            />
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <Form.Check.Label>Planting tree</Form.Check.Label>
                                        </div>
                                        <div className="custom-control custom-radio custom-control-inline">
                                            <Form.Check
                                                type="radio"
                                                id="BOTH"
                                                name="intention"
                                                checked={intentionData === "BOTH"}
                                                onClick={() => setIntentionData("BOTH")}
                                                style={{ width: '20px !important', height: "20px !important" }}
                                            />
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <Form.Check.Label>Both</Form.Check.Label>
                                        </div>
                                    </Form.Group>

                                    {/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Type of donated seedling. Ex: Mango seedling, tamarind seedling...</Form.Label>
                                    <Form.Control size='lg'
                                        name="typeOfDonated"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.typeOfDonated}
                                        className='input'
                                        type="text" placeholder="Please explain" />
                                </Form.Group> */}
                                    {/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Number of seedlings donated</Form.Label>
                                    <Form.Control size='lg'
                                        name="numberDonated"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.numberDonated}
                                        className='input' type="number" placeholder="Please explain" />
                                </Form.Group> */}
                                    <div style={{ height: 50 }}></div>
                                    <center>
                                        <CustomButton cancel title='Cancel' style={{ width: "199px", height: '44px', border: 0 }} onClick={() => history.goBack()} />
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <CustomButton addIcon title='Edit' style={{ width: "199px", height: '44px' }} onClick={() => handleSubmit()} />
                                    </center>
                                </Col>
                            </Row>
                        )}
                    </Formik>
                }
            </div>
        </div>
    )
}
export default ClimateAdd

