import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import useReactRouter from "use-react-router";
import { Formik } from 'formik'
import { useMutation } from '@apollo/react-hooks'

import Consts, { USER_KEY } from "../../../consts"
import { CREATE_REGISTER, USER_LOGIN } from "../../../apollo/register/Mutation"

import { successAdd, errorAdd } from '../../../helpers/sweetalert'

import payPal from "../../../image/donate/cc-paypal.png"
import onePay from "../../../image/donate/onePay.png"
import donateOne from "../../../image/donate/donateOne.png"
import donateTwo from "../../../image/donate/donateTwo.png"
import bgDonate from "../../../image/donate/bgDonate.png"
import newBg from "../../../image/donate/1.png"
import Developing from "../../../image/developing.gif"


import { Container, Row, Col, Button, ListGroup, Modal, Form } from 'react-bootstrap'
import { FaTimes } from "react-icons/fa";

import Footer from "../../../components/footer"

import './Index.css'


function Donate() {

    const [showDeveloping, setShowDeveloping] = useState(false);

    const handleClose = () => setShowDeveloping(false);
    const handleShow = () => setShowDeveloping(true);


    const { history, location } = useReactRouter();
    const [createRegistere] = useMutation(CREATE_REGISTER)
    const [userLogin] = useMutation(USER_LOGIN)
    const user = localStorage?.getItem(USER_KEY)
    const _userRole = JSON?.parse(user)
    const _userInfo = _userRole?.data

    const [checkUserData, setCheckUserData] = useState(false)

    // const [showDeveloping, setShowDeveloping] = useState(false);



    const [pathNames, setPathNames] = useState("/")

    const [userDataInfo, setUserDataInfo] = useState()

    const [showMediaIcons, setShowMediaIcons] = useState(false);
    const [showLogin, setShowLogin] = useState(false);
    const [showSignUp, setShowSignUp] = useState(false);

    const [active, setActive] = useState("nav__menu");
    const [icon, setIcon] = useState("nav__toggler");

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("ZEROWASTE"))

        const _resDataUser = localStorage?.getItem(USER_KEY)
        const _localUserJson = JSON?.parse(_resDataUser)


        //     let _pathNames=location?.pathname.split("/")[0];



        //     if (!_localUserJson?.accessToken) {
        //         if(_pathNames === "admin"){
        //             history.push(Consts.LOGIN_ADMIN)
        //         }
        //         else{
        //         history.push(`/`)
        // }

        // } else {
        setUserDataInfo(_localUserJson)
        // }
        setPathNames(location?.pathname.split("/")[1])

    }, [])

    useEffect(() => {
        if (!_userInfo) {
            setCheckUserData(true)
        }
    }, [_userInfo])


    const handleCloseLogin = () => setShowLogin(false);
    const handleShowLogin = () => {
        if (checkUserData) {
            setShowLogin(true)
            setShowSignUp(false)
        }
        else {
            history.push(Consts.USER_PLANT_DONATE)
        }
    };

    const handleCloseSignUp = () => setShowSignUp(false);
    const handleShowSignUp = () => {
        setShowSignUp(true)
        setShowLogin(false)
    }
    // oncreate

    const createAccount = async (data) => {
        try {
            let createAccount = await createRegistere({ variables: { data: data } })

            if (createAccount?.data?.registration) {
                await successAdd("Register Success")
                setShowSignUp(false)

                let _userLogin = await userLogin({
                    variables: {
                        data: {
                            email: data?.email,
                            password: data?.password
                        }
                    }
                })

                if (_userLogin?.data?.loginUser?.accessToken) {
                    await localStorage.setItem(Consts.USER_KEY, JSON.stringify(_userLogin?.data?.loginUser))
                    history.replace(Consts.USER_ACCOUNT)
                }
            }

        }
        catch (err) {
            errorAdd(err)
        }
    }


    // user login callback
    const loginUser = async (data) => {
        try {
            let _userLogin = await userLogin({
                variables: {
                    data: {
                        email: data?.email,
                        password: data?.password
                    }
                }
            })

            if (_userLogin?.data?.loginUser?.accessToken) {
                setShowLogin(false)
                let _approved = _userLogin?.data?.loginUser?.data?.appover

                if (_approved === "APPROVED") {
                    await localStorage.setItem(Consts.USER_KEY, JSON.stringify(_userLogin?.data?.loginUser))
                    history.replace(Consts.USER_PROFILE)
                    window.location.reload();
                }
                else if (_approved === "PENDING") {
                    await localStorage.setItem(Consts.USER_KEY, JSON.stringify(_userLogin?.data?.loginUser))
                    history.replace(Consts.USER_ACCOUNT)
                    window.location.reload();
                }
                else if (_approved === "WAITTING") {
                    await localStorage.setItem(Consts.USER_KEY, JSON.stringify(_userLogin?.data?.loginUser))
                    history.replace(Consts.USER_APPROVED)
                    window.location.reload();
                }
                else {
                    await localStorage.setItem(Consts.USER_KEY, JSON.stringify(_userLogin?.data?.loginUser))
                    history.replace(Consts.USER_APPROVED)
                    window.location.reload();
                }


            }

        }
        catch (err) {
            errorAdd(err)
        }
    }




    const newsImage = [

        {
            url: donateTwo,
            title: 'Money donation',
            detail: "Fundraising for youth development and climate action activities!"
        },

    ];

    return (
        <div style={{ zIndex: 0 }}>
            <div style={{ height: 150 }} className='hideHeight'></div>

            <Container fluid className='donate-img'>
                <Row className="m-0 p-0">
                    <Col md='12' xs={12} className="m-0 p-0 bannerDonate">
                        <img
                            className="bgImageDonate"
                            src={newBg}
                            alt="First slide"
                            style={{ width: "100%", height: "400px" }}
                        />
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row className='donateBox'>
                    <Col xs={12} sm={3} md={3} className="donateTitle">
                        <p>
                            Donate<br />
                            =<br />
                            Opportunity
                        </p>
                    </Col>
                    <Col xs={12} sm={9} md={9} className="donateDatail-box">
                        <p className='donateDatail-text'>Give to bring higher possibility to something bigger - see what you can do to help us on taking actions to protect the environment </p>
                    </Col>
                </Row>
            </Container>


            <Container className="mt-5 mb-5">

                <Row className="mt-2 mb-5 donateDesktop">
                    <Col xs={12} sm={6} md={6} lg={5}>

                        <img
                            className="news-img"
                            src={donateOne}
                            alt="First slide"
                            style={{ width: '100%', height: '300px', borderRadius: "10px" }}
                        />
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={7}>
                        <h1 style={{ fontSize: '18px', fontWeight: "bold", textAlign: "left" }} className="ListTitle">1 Tree donation</h1>

                        <p style={{ fontSize: '14px', textAlign: "left" }} className='detailList'>A tree can absorb 9-15 kg of carbon dioxide per year. Therefore, planting a tree is one of our solutions to help in generating fresh air in our community!</p>

                        <Row>
                            <Col xs={12} sm={6} md={5}>
                                <div className="btnDonateTree" style={{ cursor: 'pointer' }}
                                    onClick={() => handleShowLogin()}
                                >
                                    <span style={{ marginTop: 50 }}>
                                        Tree Donation
                                    </span>
                                </div>
                            </Col>

                        </Row>

                    </Col>
                </Row>

                <Row className="mt-2 mb-5 donateMobile">
                    <Col xs={12} sm={12} md={6} lg={5}>
                        <h1 style={{ fontSize: '18px', fontWeight: "bold", textAlign: "left" }} className="ListTitle">1 Tree donation</h1>

                        <img
                            className="news-img"
                            src={donateOne}
                            alt="First slide"
                            style={{ width: '100%', height: '300px', borderRadius: "10px" }}
                        />
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={7} className="mt-5">
                        <p style={{ fontSize: '14px', textAlign: "left" }} className='detailList'>A tree can absorb 9-15 kg of carbon dioxide per year. Therefore, planting a tree is one of our solutions to help in generating fresh air in our community!</p>

                        <Row>
                            <Col xs={12} sm={6} md={5} >
                                <div className="btnDonateTree" style={{ cursor: 'pointer' }}
                                    onClick={() => handleShowLogin()}
                                >
                                    <span style={{ marginTop: 50 }}>
                                        Tree Donation
                                    </span>
                                </div>
                            </Col>

                        </Row>

                    </Col>
                </Row>


                <div className="donateDesktop">
                    {newsImage.map((donateItem, index) => (
                        <Row key={index} className="mt-2 mb-5">
                            <Col xs={12} sm={6} md={6} lg={5}>
                                <img
                                    className="news-img"
                                    src={donateItem.url}
                                    alt="First slide"
                                    style={{ width: '100%', height: '300px', borderRadius: "10px" }}
                                />
                            </Col>
                            <Col xs={12} sm={6} md={6} lg={7}>
                                <h1 style={{ fontSize: '18px', fontWeight: "bold", textAlign: "left" }} className="ListTitle">{index + 2}. {donateItem?.title}</h1>
                                <p style={{ fontSize: '14px', textAlign: "left" }} className='detailList'>{donateItem?.detail}</p>

                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={6}>
                                        <div className="btnDonate" style={{ cursor: 'pointer' }}
                                        onClick={() => handleShow()}
                                        >
                                            <img src={payPal}
                                                style={{ width: "50px", height: "40px", marginLeft: -10 }}
                                            />
                                            &nbsp;&nbsp;&nbsp;
                                            Donate With Card

                                        </div>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={6}>
                                        <div className="btnDonate" style={{ cursor: 'pointer' }}
                                            // onClick={() => handleShow()}
                                            onClick={() => history.push(Consts.PAGE_DONATE_FORM, donateItem)}
                                        >
                                            <img src={onePay}
                                                style={{ width: "50px", height: "40px", marginLeft: -10 }}
                                            />
                                            &nbsp;&nbsp;&nbsp;

                                            Donate With One pay
                                        </div>

                                    </Col>
                                </Row>

                            </Col>
                        </Row>
                    ))}
                </div>

                <div className="donateMobile">
                    {newsImage.map((donateItem, index) => (
                        <Row key={index} className="mt-2 mb-5">
                            <Col xs={12} sm={6} md={6} lg={4}>
                                <h1 style={{ fontSize: '18px', fontWeight: "bold", textAlign: "left" }} className="ListTitle">{index + 2}. {donateItem?.title}</h1>

                                <img
                                    className="news-img"
                                    src={donateItem.url}
                                    alt="First slide"
                                    style={{ width: '100%', height: '300px', borderRadius: "10px" }}
                                />
                            </Col>
                            <Col xs={12} sm={6} md={6} lg={8} className="mt-5">
                                <p style={{ fontSize: '14px', textAlign: "left", textAlign: "justify", textJustify: "inter-word" }} className='detailList'>{donateItem?.detail}</p>

                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={6}>
                                        <div className="btnDonate" style={{ cursor: 'pointer' }}
                                        onClick={() => handleShow()}
                                        >
                                            <img src={payPal}
                                                style={{ width: "50px", height: "40px", marginLeft: -10 }}
                                            />
                                            &nbsp;&nbsp;&nbsp;
                                            Donate With Card

                                        </div>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={6}>
                                        <div className="btnDonate" style={{ cursor: 'pointer' }}
                                            // onClick={() => handleShow()}
                                            onClick={() => history.push(Consts.PAGE_DONATE_FORM, donateItem)}
                                        >
                                            <img src={onePay}
                                                style={{ width: "50px", height: "40px", marginLeft: -10 }}
                                            />
                                            &nbsp;&nbsp;&nbsp;

                                            Donate With One pay
                                        </div>

                                    </Col>
                                </Row>

                            </Col>
                        </Row>
                    ))}
                </div>



            </Container>

            <Formik
                initialValues={{
                    email: "",
                    password: "",
                }}
                validate={values => {
                    const errors = {}

                    if (!values.email) {
                        errors.email = 'Please input your email'
                    }
                    if (!values.password) {
                        errors.password = 'Please input your password (zero waste)'
                    }


                    return errors
                }}
                onSubmit={(values, { resetForm }) => {
                    let _data = {
                        email: values?.email,
                        password: values?.password
                    }
                    loginUser(_data)
                    resetForm({ values: '' })
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting
                    /* and other goodies */
                }) => (

                    <Modal show={showLogin} onHide={handleCloseLogin}>
                        <div style={{ float: 'right', padding: 20 }}>
                            <FaTimes style={{ float: 'right', fontSize: 20, cursor: "pointer" }} onClick={handleCloseLogin} />
                        </div>

                        <Modal.Body className='login-form'>
                            <h3 className='login-title'>Please log in</h3>
                            <br />

                            <Form.Group className="mb-3">
                                <Form.Control type="email" placeholder="Email..." name='email' value={values.email} onChange={handleChange} style={{ height: "40px", fontSize: "18px" }} />
                                <div style={{ color: 'red' }}>
                                    <p>{errors.email && touched.email && errors.email}</p>
                                </div>
                            </Form.Group>
                            <br />
                            <Form.Group className="mb-3">
                                <Form.Control type="password" placeholder="******" name='password' value={values.password} onChange={handleChange} style={{ height: "40px", fontSize: "18px" }} />
                                <div style={{ color: 'red' }}>
                                    <p>{errors.password && touched.password && errors.password}</p>
                                </div>
                            </Form.Group>
                            <br />

                            <button type="submit" className="btn-login" onClick={handleSubmit}>Log in</button>

                            <div style={{ textAlign: "center", padding: 20, marginTop: 10, fontSize: "14px" }}>
                                No account ? <span style={{ color: "#00A991", cursor: "pointer" }} onClick={handleShowSignUp}>Create account</span>
                            </div>
                        </Modal.Body>
                    </Modal>
                )}
            </Formik>



            <Formik
                initialValues={{
                    email: "",
                    password: "",
                    confirmPassword: "",
                    firstName: ""
                }}
                validate={values => {
                    const errors = {}
                    if (!values.firstName) {
                        errors.firstName = 'Please input your first name and last name'
                    }
                    if (!values.email) {
                        errors.email = 'Please input your email'
                    }
                    if (!values.password) {
                        errors.password = 'Please input your password (zero waste)'
                    }
                    if (!values.confirmPassword) {
                        errors.confirmPassword = 'Please input confirm your password (zero waste)'
                    }
                    if (values.password !== values.confirmPassword) {
                        errors.confirmPassword = "Your password does't match"
                    }

                    return errors
                }}
                onSubmit={(values, { resetForm }) => {
                    let _data = {
                        firstName: values?.firstName,
                        email: values?.email,
                        role: "USER",
                        appover: "PENDING",
                        password: values?.password
                    }
                    createAccount(_data)
                    resetForm({ values: '' })
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting
                    /* and other goodies */
                }) => (

                    <Modal show={showSignUp} onHide={handleCloseSignUp}>
                        <div style={{ float: 'right', padding: 20 }}>
                            <FaTimes style={{ float: 'right', fontSize: 20, cursor: "pointer" }} onClick={handleCloseSignUp} />
                        </div>
                        <Form>
                            <Modal.Body className='login-form'>
                                <h3 className='login-title'>Please Sign up</h3>
                                <br />

                                <Form.Group className="mb-3">
                                    <Form.Label>First Name and Last Name</Form.Label>
                                    <Form.Control type="text" placeholder="First Name and Last Name ..." name='firstName' value={values.firstName} onChange={handleChange} style={{ height: "40px", fontSize: "18px" }} />
                                    <div style={{ color: 'red' }}>
                                        <p>{errors.firstName && touched.firstName && errors.firstName}</p>
                                    </div>
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" placeholder="Email" name='email' value={values.email} onChange={handleChange} style={{ height: "40px", fontSize: "18px" }} />
                                    <div style={{ color: 'red' }}>
                                        <p>{errors.email && touched.email && errors.email}</p>
                                    </div>
                                </Form.Group>
                                <br />
                                <Form.Group className="mb-3">
                                    <Form.Label>password</Form.Label>
                                    <Form.Control type="password" placeholder="******" name='password' value={values.password} onChange={handleChange} style={{ height: "40px", fontSize: "18px" }} />
                                    <div style={{ color: 'red' }}>
                                        <p>{errors.password && touched.password && errors.password}</p>
                                    </div>
                                </Form.Group>
                                <br />
                                <Form.Group className="mb-3">
                                    <Form.Label>confirm password</Form.Label>
                                    <Form.Control type="password" placeholder="******" name='confirmPassword' value={values.confirmPassword} onChange={handleChange} style={{ height: "40px", fontSize: "18px" }} />
                                    <div style={{ color: 'red' }}>
                                        <p>{errors.confirmPassword && touched.confirmPassword && errors.confirmPassword}</p>
                                    </div>
                                </Form.Group>
                                <br />

                                <button type='submit' className="btn-login" onClick={handleSubmit}>Sign in</button>

                                <div style={{ textAlign: "center", padding: 20, marginTop: 10, fontSize: "14px" }}>
                                    Already have account? <span style={{ color: "#00A991", cursor: "pointer" }} onClick={handleShowLogin}>Log in</span>
                                </div>
                            </Modal.Body>
                        </Form>
                    </Modal>
                )}
            </Formik>





            <Modal show={showDeveloping} onHide={handleClose} className="modal-form">
                <div style={{ float: 'right', padding: 20 }} className="formRegisterClose">
                    <FaTimes style={{ float: 'right', fontSize: 20, cursor: "pointer" }} onClick={handleClose} />
                </div>
                <Modal.Body>

                    <center>
                        <p style={{ fontSize: "16px", fontWeight: "bold" }}>Coming soon</p>
                        <p style={{ fontSize: "14px", }}>We will be celebrating the launch of our new site soon!</p>
                        <img src={Developing} style={{ width: "150px", height: "150px" }} />
                        
                    </center>

                </Modal.Body>
            </Modal>

            <Footer />

        </div>
    )
}
export default Donate