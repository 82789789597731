import React, { useState, useEffect } from 'react'
import useReactRouter from "use-react-router";

import { Breadcrumb, Form, Table, Image } from 'react-bootstrap'
import { FaEye, FaEdit, FaTrash } from "react-icons/fa";
import Consts from "../../../../consts"
import { Title, CustomButton } from "../../../../common"
import { useLazyQuery } from '@apollo/react-hooks'

import { CERTIFICATE_CATEGORYS } from "../../../../apollo/certificateCategory/Query"
import { dateTimeLao } from "../../../../helpers/Helper"

function CertificateCategoryList() {
    const { history } = useReactRouter();
    const [fillterName, setFillterName] = useState('')
    const [getCertificateCategory, { data }] = useLazyQuery(CERTIFICATE_CATEGORYS, {
        fetchPolicy: "network-only",
        variables: {
            where: {
                isDeleted: false
            }
        }
    });
    //function
    const _AddCertificateCategory = () => { history.push(Consts.PAGE_CERTIFICATE_CATEGORY_ADD) }



    // function
    const onEdit = async (id) => {
        await history.push(Consts.PAGE_CERTIFICATE_CATEGORY_UPDATE + '/' + id)
    }


     const _settingMenu = () =>{history.push(Consts.PAGE_SETTING_MENU )}

    // ======>

    useEffect(() => {
        getCertificateCategory()
    }, [])

    return (
        <div>
            <Breadcrumb style={{ marginTop: 60, fontSize: '12px' }}>
                <Breadcrumb.Item href="#" onClick={() => _settingMenu()}>Settings</Breadcrumb.Item>
                <Breadcrumb.Item active>E-CertificateCategory</Breadcrumb.Item>
            </Breadcrumb>
            <div style={Consts.MAIN_CARD} >
                <Title text='E-CertificateCategory' />


                <div style={{ display: "flex", justifyContent: "space-between", marginTop: 20, marginBottom: 20 }}>
                    <h4>Show {data?.categoryCertificates?.data?.length} List</h4>
                    {/* <div>

                        <CustomButton addIcon title='Add' onClick={() => _AddCertificateCategory()} />

                    </div> */}
                </div>
                <Table striped>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Category name</th>
                            <th>detail</th>
                            <th>Created At</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    {data?.categoryCertificates?.data?.map((item, index) =>
                        <tbody key={index}>

                            <tr>
                                <td>{index + 1}</td>

                                <td>{item?.name}</td>
                                <td>{item?.detail}</td>

                                <td>{dateTimeLao(item?.createdAt)}</td>
                                <td>
                                    {/* <FaEye style={{ color: '#00A991',cursor:'pointer', fontSize: '14px' }}  />
                                &nbsp;&nbsp;&nbsp; */}
                                    <FaEdit style={{ color: '#00A991', cursor: 'pointer', fontSize: '14px' }} onClick={(e) => onEdit(item?.id)} />
                                    {/* &nbsp;&nbsp;&nbsp;
                                <FaTrash style={{ color: 'red',cursor:'pointer', fontSize: '14px' }} /> */}
                                </td>
                            </tr>
                        </tbody>

                    )}
                </Table>


            </div>



        </div>
    )
}
export default CertificateCategoryList