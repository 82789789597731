import { gql } from "apollo-boost";

export const USERS_DATA = gql`
query Query($where: UserWhereInput) {
    users(where: $where) {
        total
    data {
            id
            role
            userId
            firstName
            email
            educationLevel
            englishProficiency
            qccupation
            workplace
            provinceOfBirth
            districtOfBirth
            villageOfBirth
            volunteerBefore
            volunteerExplain
            knowAboutUs
            everJoinedZeroWaste
            tellUs
            skillsAndProfession
            improveYourself
            expectZeroWaste
            appover
            ethnicity
            nationality
            occupation
            company
            province
            village
            district
            gender
            birthday
            image
            phone
            whatsapp
            plantingActivity
            intention
            isDeleted
            detail
            createdAt
      createdBy {
                id
                role
                userId
                firstName
            }
            updatedAt
            note
      updatedBy {
                id
                role
                userId
                firstName
            }
        }
    }
}
`;


export const USER_INFO = gql`
query User($where: UserWhereInputId!) {
  user(where: $where) {
    id
    role
    userId
    firstName
    email
    educationLevel
    englishProficiency
    qccupation
    workplace
    provinceOfBirth
    districtOfBirth
    villageOfBirth
    volunteerBefore
    volunteerExplain
    knowAboutUs
    everJoinedZeroWaste
    tellUs
    skillsAndProfession
    improveYourself
    expectZeroWaste
    appover
    ethnicity
    nationality
    occupation
    company
    province
    district
    village
    gender
    birthday
    image
    phone
    whatsapp
    isDeleted
    detail
    note
    plantingActivity
    intention
    createdAt
    createdBy {
      id
      firstName
    }
    updatedAt
    updatedBy {
      id
      firstName
    }
  }
}

`





export const CREATE_STAFF = gql`
mutation Mutation($data: UserCreateWhereInput) {
  createUser(data: $data) {
    id
  }
}
`;
export const CREATE_CLIMATE = gql`
mutation Mutation($data: ClimateCreateWhereInput) {
  createClimate(data: $data) {
    id
  }
}
`;
export const UPDATE_STAFF = gql`
 mutation Mutation($where: UserWhereInputId!, $data: UserCreateWhereInput) {
  updateUser(where: $where, data: $data) {
    id
  }
}
`;
export const DELETE_STAFF = gql`
mutation Mutation($where: UserWhereInputId!) {
  deleteUser(where: $where) {
    id
  }
}
`;