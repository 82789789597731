import React, { useState } from 'react'
import useReactRouter from "use-react-router";
import { Formik } from 'formik'

import { Breadcrumb, Container, Row, Form, Col, Image, Button } from 'react-bootstrap'
import { FaEye, FaEdit, FaTrash, FaPlusCircle } from "react-icons/fa";
import Consts from "../../../../consts"

import { Title, CustomButton } from "../../../../common"
import CUSD_POST_CATEGORY from '../../../../crud/PostCategory';


function PostCategoryAdd() {
    const { history } = useReactRouter();

    //state

    const {
        _createPostCategory,
    } = CUSD_POST_CATEGORY();

    // function
    const _settingMenu = () => { history.push(Consts.PAGE_SETTING_MENU) }

    return (
        <div>
            <Breadcrumb style={{ marginTop: 60, fontSize: '12px' }}>
                <Breadcrumb.Item href="#" onClick={() => _settingMenu()}>Settings</Breadcrumb.Item>
                <Breadcrumb.Item active>Post Category Add</Breadcrumb.Item>
            </Breadcrumb>

            <div style={Consts.MAIN_CARD} >
                <Title text='Post Category Add' />
                <hr></hr>
                <Formik
                    initialValues={{
                        postCategoryName:"",
                        detail:"",
                    }}
                    validate={values => {
                        const errors = {}
                        if (!values.postCategoryName) {
                          errors.postCategoryName = 'Please input post category name ....'
                        }
                      
                        return errors
                    }}
                    onSubmit={values => {
                        let _data ={
                            name: values?.postCategoryName,
                            detail:values?.detail
                        }
                        _createPostCategory(_data)
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting
                        /* and other goodies */
                    }) => (

                        <Row>
                        
                            <Col md={12}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label style={{fontSize:14}}>certificate category name <span style={{ color: "red" }}>*</span></Form.Label>
                                    <Form.Control
                                        size='lg'
                                        type="text"
                                        placeholder="Please post category name"
                                        name="postCategoryName"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.postCategoryName}
                                        style={{ height:40,fontSize:14}}
                                    />
                                    <div style={{ color: 'red' }}>
                                        <p>{errors.postCategoryName && touched.postCategoryName && errors.postCategoryName}</p>
                                    </div>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label style={{fontSize:14}}>Detail</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        placeholder="if any detail..."
                                        name="detail"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.detail}
                                        style={{ height: '100px',fontSize:14 }}
                                    />
                                </Form.Group>
                                
                                <center>
                                    <CustomButton cancel title='Cancel' style={{ width: "199px", height: '44px', border: 0 }} onClick={() => history.goBack()}/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <CustomButton addIcon title='Save' style={{ width: "199px", height: '44px' }} onClick={()=>handleSubmit()}/>
                                </center>
                            </Col>
                        </Row>
                    )}
                </Formik>
            </div>
        </div>
    )
}
export default PostCategoryAdd