import { gql } from "apollo-boost";

export const CERTIFICATES= gql`
query Certificates($where: CertificateWhereInput) {
  certificates(where: $where) {
    data {
      id
      categoryCertificateId {
        id
        name
      }
      topic
      imageProfile
      images
      detail
      createdAt
      updatedAt
    }
  }
}
`

export const CERTIFICATE = gql`
query Certificate($where: CertificateWhereInputId!) {
  certificate(where: $where) {
    id
    categoryCertificateId {
      id
      name
    }
    topic
    imageProfile
    images
    detail
    createdAt
    createdBy {
      id
      firstName
    }
    updatedAt
    updatedBy {
      id
      firstName
    }
  }
}
`

