import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import useReactRouter from "use-react-router";

import { Formik } from 'formik'
import * as _ from "lodash";
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import MapContainer from "../../../consts/locationMap"
import { KEY_API_GOOGLEMAP } from '../../../consts'

import { PLANTING_DONATE } from "../../../apollo/plantDonate/Query"


import { ADDRESSES } from "../../../consts/newAddresses";
import Consts, { USER_KEY } from "../../../consts"

import { USER } from "../../../apollo/register/Query"
import { USER_UPDATE } from "../../../apollo/register/Mutation"

import imageWaiting from "../../../image/user/wating.png"

import UploadPhoto from "../../../helpers/UploadPhoto";

import { successAdd, errorAdd } from '../../../helpers/sweetalert'
import donteSuccess from "../../../image/donate/donteSuccess.png"

import CRUD_PLANTDONATE from "../../../crud/donateTree"

import { Carousel, Container, Row, Col, Button, ListGroup, Form ,Modal} from 'react-bootstrap'
import { FaSearch, FaAngleRight,FaTimes } from "react-icons/fa";
import { PROVINCE, DRISTRIC } from '../../../consts/addresses'

import Footer from "../../../components/footer"

import './Index.css'
function PlantDonateTree() {
    const { history } = useReactRouter();


    const [updateUser] = useMutation(USER_UPDATE)
    const user = localStorage?.getItem(USER_KEY)
    const _userRole = JSON?.parse(user)
    const _userInfo = _userRole?.data
    const [showAlertSuccess, setShowAlertSuccess] = useState(false);



    const [totalOf, setTotalOf] = useState(0)
    const [loadPlantDonate, { data: plantDonate }] = useLazyQuery(PLANTING_DONATE)


    const handleCloseAlertSuccess = () => {
        setShowAlertSuccess(false);
        history.replace(Consts.USER_PROFILE)
        window.location.reload();

    }
    const handleShowAlertSuccess = () => setShowAlertSuccess(true);


    useEffect(() => {
        loadPlantDonate()
    }, [])

    useEffect(() => {
        if (plantDonate?.plantDonations?.data) {
            let _tree = 0
            for (let i = 0; i < plantDonate?.plantDonations?.data?.length; i++) {
                _tree += plantDonate?.plantDonations?.data[i]?.numberDonated
            }
            setTotalOf(_tree)
        }

    }, [plantDonate?.plantDonations?.data])


    const [dataLatLong, setDataLatLong] = useState({
        lat: 17.9994624,
        lng: 102.547456,
    })

    const {
        namePhotoMany,
        buttonUploadAndShowPhotoMany,
        setWidhtPhoto,
        setHeightPhoto,
    } = UploadPhoto();

    const {
        _createPlantDonate,
    } = CRUD_PLANTDONATE();


    const [activity, setActivity] = useState("Yes")
    const [dataDistrict, setDataDistrict] = useState([])


    // onst [showAlertSuccess, setShowAlertSuccess] = useState(false);

    // const handleCloseAlertSuccess = () => {setShowAlertSuccess(false);
    // history.replace(Consts.USER_PROFILE)
    // window.location.reload();

    // }
    // const handleShowAlertSuccess = () => setShowAlertSuccess(true);



    const [userData, { loading, data: userDataInfo }] = useLazyQuery(USER)

    useEffect(() => {
        userData({
            variables: {
                where: { id: _userInfo?.id }
            }
        })

    }, []);



    /* ເລືອກແຂວງປະຈຸບັນ */
    const _selectProvince = (a) => {
        let _selectData = PROVINCE?.filter((item) => item?.pr_name === a)
        let _selectDistrict = DRISTRIC?.filter((item) => _selectData[0]?.pr_id === item?.pr_id)
        setDataDistrict(_selectDistrict)
    }


    const defaultCenter = {
        lat: dataLatLong?.lat, lng: dataLatLong?.lng
    }
    const mapStyles = {
        height: "50vh",
        width: "100%"
    };
    const onMarkerClick = (evt) => {
        setDataLatLong({
            lat: evt?.latLng?.lat(),
            lng: evt?.latLng?.lng(),
        })
    };


    // update account



    return (
        <div style={{ zIndex: 0 }}>
            
            <div className="bgPlantDonate">


                <Formik
                    initialValues={{
                        typeOfDonated: "",
                        numberDonated: "",
                        province: "",
                        district: "",
                        village: "",
                    }}
                    validate={values => {
                        const errors = {}
                        if (!values.typeOfDonated) {
                            errors.typeOfDonated = 'Required';
                        }
                        if (!values.numberDonated) {
                            errors.numberDonated = 'Required';
                        }
                        if (!values.province) {
                            errors.province = 'Required';
                        } else {
                            _selectProvince(values.province)
                        }

                        if (!values.district) {
                            errors.district = 'Required';
                        }
                        if (!values.village) {
                            errors.village = 'Required';
                        }

                        return errors
                    }}
                    onSubmit={(values, { resetForm }) => {



                        let _data = {
                            ...values,
                            images: namePhotoMany,
                            lat: dataLatLong?.lat,
                            long: dataLatLong?.lng,
                        }

                        _createPlantDonate(_data, _userInfo,handleShowAlertSuccess)


                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting
                    }) => (

                        <>
                            <Row className="" style={{ marginRight: 0, marginLeft: 0 }}>
                                <Col xs={12} sm={12} md={4} style={{ backgroundColor: "#ffffff", padding: 10, paddingBottom: 20,marginTop:170 }}>
                                    <p className="titleAccount">Climate action</p>
                                    <Col xs={12} sm={12} md={12} style={{ textAlign: 'left' }}>
                                        <br />
                                        <p className='subTitleForm'>Type of donated seedling. Ex: Mango seedling, tamarind seedling... </p>
                                        <hr></hr>
                                    </Col>

                                    <Col xs={12} sm={12} md={12} className='textLabel'>
                                        <Form.Group >
                                            <br />
                                            <Form.Control type="text" name="typeOfDonated" value={values.typeOfDonated} onChange={handleChange} placeholder="Your answer ...." className="formInput" />
                                            <div style={{ color: 'red' }}>
                                                <p>{errors.typeOfDonated && touched.typeOfDonated && errors.typeOfDonated}</p>
                                            </div>
                                        </Form.Group>
                                    </Col>

                                    <Col xs={12} sm={12} md={12} style={{ textAlign: 'left' }}>
                                        <br />
                                        <p className='subTitleForm'>Number of seedlings donated </p>
                                        <hr></hr>
                                    </Col>

                                    <Col xs={12} sm={12} md={12} className='textLabel'>
                                        <Form.Group >
                                            <br />
                                            <Form.Control type="number" name="numberDonated" value={values.numberDonated} onChange={handleChange} placeholder="0" style={{ fontSize: 16, height: 40 }} className="formInput" />

                                            <div style={{ color: 'red' }}>
                                                <p>{errors.numberDonated && touched.numberDonated && errors.numberDonated}</p>
                                            </div>
                                        </Form.Group>
                                    </Col>


                                    <Col xs={12} sm={12} md={12} style={{ textAlign: 'left' }}>
                                        <br />
                                        <p className='subTitleForm'>Upload a picture of yourself with the trees you plant.</p>
                                        <hr></hr>
                                    </Col>

                                    <Col xs={12} sm={12} md={12} className='textLabel'>
                                        <Form.Group className='mt-4' style={{ display: "flex", justifyContent: "center" }}>
                                            <center>
                                                <Form.Label >Upload a picture <span style={{ color: "red" }}>*</span></Form.Label>
                                                {buttonUploadAndShowPhotoMany()}
                                            </center>

                                        </Form.Group>

                                    </Col>
                                    <Col xs={12} sm={12} md={12} style={{ textAlign: 'left' }}>
                                        <br />
                                        <p className='subTitleForm'>Current Address </p>
                                        <hr></hr>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} className='textLabel'>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Province <span style={{ color: "red" }}>*</span></Form.Label>
                                            <Form.Control size='lg'
                                                name="province"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.province}
                                                className='input' as="select"
                                                style={{ fontSize: "16px" }}
                                            >
                                                <option value='' selected disabled >Select the Province</option>
                                                {PROVINCE?.map((item, index) =>
                                                    <option value={item?.pr_name} key={index + 1} >{item?.pr_name}</option>
                                                )}
                                            </Form.Control>
                                            <div style={{ color: 'red' }}>
                                                <p>{errors.province && touched.province && errors.province}</p>
                                            </div>
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>District<span style={{ color: "red" }}>*</span></Form.Label>
                                            <Form.Control size='lg'
                                                name="district"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.district}
                                                className='input'
                                                as="select"
                                                style={{ fontSize: "16px" }}>
                                                <option value='' selected disabled>Select the District</option>
                                                {dataDistrict?.map((item, index) =>
                                                    <option value={item?.dr_name} key={index + 1} >{item?.dr_name}</option>
                                                )}
                                            </Form.Control>
                                            <div style={{ color: 'red' }}>
                                                <p>{errors.district && touched.district && errors.district}</p>
                                            </div>
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Village <span style={{ color: "red" }}>*</span></Form.Label>
                                            <Form.Control size='lg'
                                                name="village"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.village}
                                                className='input' type="text" placeholder="Please village" />
                                            <div style={{ color: 'red' }}>
                                                <p>{errors.village && touched.village && errors.village}</p>
                                            </div>
                                        </Form.Group>

                                    </Col>

                                    <Col xs={12} sm={12} md={12} className='textLabel'>
                                        <Form.Group className='mt-4'>
                                            <LoadScript
                                                googleMapsApiKey={KEY_API_GOOGLEMAP}>
                                                <GoogleMap
                                                    mapContainerStyle={mapStyles}
                                                    zoom={12}
                                                    center={defaultCenter}
                                                    onClick={onMarkerClick}
                                                >
                                                    <Marker position={{ lat: dataLatLong?.lat, lng: dataLatLong?.lng }} />
                                                </GoogleMap>
                                            </LoadScript>

                                        </Form.Group>

                                    </Col>

                                    <Col xs={12} sm={12} md={12} className='textLabel'>
                                        <Form.Group className="mt-4" controlId="exampleForm.ControlInput1">
                                            <button type="submit" className="btn-login" onClick={handleSubmit}>Donate</button>

                                        </Form.Group>
                                    </Col>
                                </Col>
                                <Col xs={12} sm={12} md={6} style={{marginTop:170}}>
                                    <h2 style={{ fontSize: 50, textAlign: 'center', color: "#ffffff" }}>{totalOf ?? "0"}</h2>
                                    <p style={{ fontSize: 18, textAlign: 'center', color: "#ffffff" }}>Trees already planted by Zerowastelaos users </p>
                                </Col>

                            </Row>
                        </>

                    )}
                </Formik>


                <Modal show={showAlertSuccess}
                onHide={handleCloseAlertSuccess}
                centered
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
            >

                <Modal.Body>

                    <Row>

                        <Col xs={12} style={{ textAlign: 'right' }}>
                            <FaTimes style={{ marginTop: 15, marginRight: 15, fontSize: "20px" }} onClick={handleCloseAlertSuccess} />
                        </Col>

                        <Col xs={12}>
                            <img src={donteSuccess} style={{ width: "100%", height: "auto" }} />
                            <center>
                                <p style={{ fontSize: "18px", fontWeight: "bold" }}>
                                    Thanks we will consider soon
                                </p>
                                <p>
                                    We will notify you of the results via WhatsApp or Email ❤🌱️
                                </p>
                            </center>
                        </Col>
                    </Row>

                </Modal.Body>
            </Modal>




            </div>
            <Footer />

        </div>
    )
}
export default PlantDonateTree