import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import useReactRouter from "use-react-router";


import Consts from "../../../consts"
import UploadPhoto from "../../../helpers/UploadPhoto";
import BgCoverPrograme from "./BgCoverPrograme"

import Bgprograme from "../../../image/programe/bgPrograme.png"
import P2_1 from "../../../image/programe/programe2/p2-1.JPG"
import P2_2 from "../../../image/programe/programe2/p2-2.JPG"
import P2_3 from "../../../image/programe/programe2/p2-3.png"
import NewsCover from "../../../image/blog/NewsCover.png"



import { FaAngleRight } from "react-icons/fa";
import { Modal, Container, Row, Col, Button, Form } from 'react-bootstrap'
import { FaTimes } from "react-icons/fa";

import Footer from "../../../components/footer"

import './Index.css'


function Programe2() {
    const { history } = useReactRouter();
    const {
        namePhoto,
        buttonUploadAndShowPhoto,
        setWidhtPhoto,
        setHeightPhoto,
    } = UploadPhoto();
    const [showFormRegister, setShowFormRegister] = useState(false);

    const handleCloseFormRegister = () => setShowFormRegister(false);
    const handleShowFormRegister = () => setShowFormRegister(true);



    return (
        <div style={{ zIndex: 0 }}>
            <div style={{ height: 150 }} className='hideHeight'></div>




            <Container fluid className="m-0 p-0 mb-5 bgImage-about-pro">
                <Row className="m-0 p-0">
                    <Col md='12' xs={12} className="m-0 p-0 banner-about-pro">
                        <BgCoverPrograme />
                        {/* <img className='imgBg-about-pro' src={NewsCover} />

                        <div className="centered-about-pro">
                            <p>Our Program</p>
                        </div> */}

                        <div className="container-list">
                            <div className='list-items' onClick={() => history.push(Consts.PAGE_PROGRAME1)}>Campaign/ Workshop</div>
                            <div className='list-items action-programe' onClick={() => history.push(Consts.PAGE_PROGRAME2)}> Research</div>
                            <div className='list-items' onClick={() => history.push(Consts.PAGE_PROGRAME3)}> Capacity Building</div>
                            <div className='list-items' onClick={() => history.push(Consts.PAGE_PROGRAME4)}> CSR Collaboration</div>
                        </div>
                    </Col>
                </Row>

            </Container>




            <Container className="">
                <Row className="proFix1">

                <Col xs={12} className="p-5">
                         <p className="proTitle">Research</p>
                         <p className="proDetailTitle">Our project results will be converted to data, as we conduct research and also collaborate with research institutes to improve our understanding about various issues in the environment and human development process. </p>

                    </Col>


                </Row>
            </Container>





            <Footer />
        </div>
    )
}
export default Programe2