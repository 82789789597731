import React, { useState } from 'react'
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import styled from "styled-components";

import Home from '../pages/website/home'
import Blog from "../pages/website/blog/Blog"
import BlogDetail from "../pages/website/blog/BlogDetail"
import Contact from "../pages/website/contact/contact"
import Donate from "../pages/website/donate/donate"
import DonateForm from "../pages/website/donate/DonateForm"
import Volunteer from "../pages/website/volunteer/Volunteer"
import About from "../pages/website/about/About"
import Programe1 from "../pages/website/progame/Programe1"
import Programe2 from "../pages/website/progame/Programe2"
import Program3 from "../pages/website/progame/Program3"
import Program4 from "../pages/website/progame/Program4"
import AboutPro2022 from "../pages/website/progame/AboutPro2022"
import AboutPro2021 from "../pages/website/progame/AboutPro2021"
import AboutPro2020 from "../pages/website/progame/AboutPro2020"
import AboutPro2019 from "../pages/website/progame/AboutPro2019"

import UserAccount from "../pages/website/userMember/UserAccount"
import UserProfile from "../pages/website/userMember/UserProfile"
import UserWaitApproved from "../pages/website/userMember/UserWaitApproved"
import PlantDonateTree from "../pages/website/userMember/PlantDonateTree"

import Navbar from '../components/Navbar'
import Const from "../consts"


//----------- admin
import CustomSideNav from "../components/SideNav";
import NavbarAdmin from "../components/NavbarAdmin"
import Login from "../pages/webAdmin/login/Login"
import Deshboard from "../pages/webAdmin/deshboard"
//staff
import StaffList from "../pages/webAdmin/staff/staffList"
import StaffAdd from "../pages/webAdmin/staff/saffAdd"
import StaffEdit from "../pages/webAdmin/staff/StaffEdit"
import StaffDetail from '../pages/webAdmin/staff/StaffDetail'

//volunteer
import VolunteerList from "../pages/webAdmin/volunteer/VolunteerList"
import VolunteerAdd from "../pages/webAdmin/volunteer/VolunteerAdd"
import VolunteerEdit from "../pages/webAdmin/volunteer/VolunteerEdit"
import VolunteerfDetail from "../pages/webAdmin/volunteer/VolunteerDetail"

//ClimateList
import ClimateList from "../pages/webAdmin/climate/ClimateList"
import ClimateListAppover from "../pages/webAdmin/climate/ClimateListAppover"
import ClimateListReject from "../pages/webAdmin/climate/ClimateListReject"
import ClimateListDetail from "../pages/webAdmin/climate/ClimateListDetail"
import ClimateAdd from "../pages/webAdmin/climate/ClimateAdd"
import ClimateEdit from "../pages/webAdmin/climate/ClimateEdit"
import ClimateListPending from "../pages/webAdmin/climate/ClimateListPending"

//SettingMenu

import SettingMenu from "../pages/webAdmin/setting/SettingMenu"
import CertificateCategoryList from "../pages/webAdmin/setting/certificateCategory/CertificateCategoryList"
import CertificateCategoryAdd from "../pages/webAdmin/setting/certificateCategory/CertificateCateogyAdd"
import CertificateCategoryUpdate from "../pages/webAdmin/setting/certificateCategory/CertificateCategoryUpdate"

import PostCategryList from "../pages/webAdmin/setting/postCategory/PostCategryList"
import PostCategoryAdd from "../pages/webAdmin/setting/postCategory/PostCategoryAdd"
import PostCategoryUpdate from "../pages/webAdmin/setting/postCategory/PostCategoryUpdate"

import DonateList from "../pages/webAdmin/donate/DonateList"
import DonateDetail from "../pages/webAdmin/donate/DonateDetail"

// Certificate
import CertificateList from "../pages/webAdmin/certificate/CertificateList"
import CertificateAdd from "../pages/webAdmin/certificate/CertificateAdd"
import CertificateUpdate from "../pages/webAdmin/certificate/CertificateUpdate"
import CertitficateDetail from "../pages/webAdmin/certificate/CertificationDetail"

import PostList from "../pages/webAdmin/post"
import AddBlog from "../pages/webAdmin/post/addBlog"
import EditBlog from "../pages/webAdmin/post/editBlog"
import PostDetail from "../pages/webAdmin/post/detailBlog"

const Main = styled.main`
  /* position: relative; */
  overflow: hidden;
  transition: all 0.15s;
  padding: 0 20px;
  margin-left: ${(props) => (props.expanded ? 240 : 64)}px;
`;

function RouterLink() {
  const [detactRoute, setDetactRoute] = useState()
  const [expanded, setExpanded] = useState(false);
  const _onToggle = (exp) => {
    setExpanded(exp);
  };
  return (
    <>
      <Router>
        <Navbar />
        <Switch>

          <PublicRoute exact path="/" component={Home} />
          <PublicRoute exact path={Const.PAGE_HOME2} component={Home} />
          <PublicRoute exact path={Const.PAGE_LOGIN} component={Login} />
          <PublicRoute exact path={Const.PAGE_BLOG} component={Blog} />
          <PublicRoute exact path={Const.PAGE_BLOG_DETAIL + "/:id"} component={BlogDetail} />
          <PublicRoute exact path={Const.PAGE_CONTACT} component={Contact} />
          <PublicRoute exact path={Const.PAGE_DONATE} component={Donate} />
          <PublicRoute exact path={Const.PAGE_DONATE_FORM} component={DonateForm} />
          <PublicRoute exact path={Const.PAGE_VOLUNTEER} component={Volunteer} />
          <PublicRoute exact path={Const.PAGE_ABOUT} component={About} />
          <PublicRoute exact path={Const.PAGE_PROGRAME1} component={Programe1} />
          <PublicRoute exact path={Const.PAGE_PROGRAME2} component={Programe2} />
          <PublicRoute exact path={Const.PAGE_PROGRAME3} component={Program3} />
          <PublicRoute exact path={Const.PAGE_PROGRAME4} component={Program4} />
          <PublicRoute exact path={Const.PAGE_ABOUT_PRO2022} component={AboutPro2022} />
          <PublicRoute exact path={Const.PAGE_ABOUT_PRO2021} component={AboutPro2021} />
          <PublicRoute exact path={Const.PAGE_ABOUT_PRO2020} component={AboutPro2020} />
          <PublicRoute exact path={Const.PAGE_ABOUT_PRO2019} component={AboutPro2019} />


          <PublicRoute exact path={Const.USER_ACCOUNT} component={UserAccount} />
          <PublicRoute exact path={Const.USER_PROFILE} component={UserProfile} />
          <PublicRoute exact path={Const.USER_APPROVED} component={UserWaitApproved} />
          <PublicRoute exact path={Const.USER_PLANT_DONATE} component={PlantDonateTree} />

          

          <Route
            render={({ location, history }) => (
              <React.Fragment>
                {setDetactRoute(location?.pathname.split("-")[0].toString())}
                <CustomSideNav
                  location={location}
                  history={history}
                  onToggle={(exp) => _onToggle(exp)}
                />

                <NavbarAdmin />
                <Main expanded={expanded}>
                  <div
                    style={{
                      // marginTop: 80,
                      marginLeft: -20,
                      marginRight: -20,
                      backgroundColor: "#eee",
                      minHeight: "100vh",
                    }}
                  >
                    <PrivateRoute
                      path={Const.PAGE_DESHBOARD}
                      exact
                      component={(props) => <Deshboard />}
                    />

                    {/* staff managerment */}
                    <PrivateRoute
                      path={Const.PAGE_STAFF}
                      exact
                      component={(props) => <StaffList />}
                    />
                    <PrivateRoute
                      path={Const.PAGE_STAFF_ADD}
                      exact
                      component={(props) => <StaffAdd />}
                    />
                    <PrivateRoute
                      path={Const.PAGE_STAFF_EDIT}
                      exact
                      component={(props) => <StaffEdit />}
                    />
                    <PrivateRoute
                      path={Const.PAGE_STAFF_DETAIL}
                      exact
                      component={(props) => <StaffDetail />}
                    />

                    {/* Volunteer */}
                    <PrivateRoute
                      path={Const.PAGE_VOLUNTEER_LIST}
                      exact
                      component={(props) => <VolunteerList />}
                    />
                    <PrivateRoute
                      path={Const.PAGE_ADD_VOLUNTEER}
                      exact
                      component={(props) => <VolunteerAdd />}
                    />
                    <PrivateRoute
                      path={Const.PAGE_EDIT_VOLUNTEER}
                      exact
                      component={(props) => <VolunteerEdit />}
                    />

                    <PrivateRoute
                      path={Const.PAGE_VOLUNTEER_DETAIL}
                      exact
                      component={(props) => <VolunteerfDetail />}
                    />
                    {/* Climate */}
                    <PrivateRoute
                      path={Const.PAGE_CLIMATE_LIST}
                      exact
                      component={(props) => <ClimateList />}
                    />
                  <PrivateRoute
                      path={Const.PAGE_APPORVERD_LIST}
                      exact
                      component={(props) => <ClimateListAppover />}
                    /> 
                    <PrivateRoute
                      path={Const.PAGE_REJECT_LIST}
                      exact
                      component={(props) => <ClimateListReject />}
                    />

                    <PrivateRoute
                      path={Const.PAGE_PEDDING_LIST }
                      exact
                      component={(props) => <ClimateListPending />}
                    />

                    <PrivateRoute
                      path={Const.PAGE_CLIMATE_DETAIL}
                      exact
                      component={(props) => <ClimateListDetail />}
                    />
                    <PrivateRoute
                      path={Const.PAGE_CLIMATE_ADD}
                      exact
                      component={(props) => <ClimateAdd />}
                    />
                    <PrivateRoute
                      path={Const.PAGE_CLIMATE_EDIT}
                      exact
                      component={(props) => <ClimateEdit />}
                    />

                    {/* setting menu */}

                    <PrivateRoute
                      path={Const.PAGE_SETTING_MENU}
                      exact
                      component={(props) => <SettingMenu />}
                    />
                    <PrivateRoute
                      path={Const.PAGE_CERTIFICATE_CATEGORY_LIST}
                      exact
                      component={(props) => <CertificateCategoryList />}
                    />
                    <PrivateRoute
                      path={Const.PAGE_CERTIFICATE_CATEGORY_ADD}
                      exact
                      component={(props) => <CertificateCategoryAdd />}
                    />

                    <PrivateRoute
                      path={Const.PAGE_CERTIFICATE_CATEGORY_UPDATE + "/:id"}
                      exact
                      component={(props) => <CertificateCategoryUpdate />}
                    />


                    {/* Post */}
                    <PrivateRoute
                      path={Const.PAGE_POST_CATEGORY_LIST}
                      exact
                      component={(props) => <PostCategryList />}
                    />
                    <PrivateRoute
                      path={Const.PAGE_POST_CATEGORY_ADD}
                      exact
                      component={(props) => <PostCategoryAdd />}
                    />

                    <PrivateRoute
                      path={Const.PAGE_POST_CATEGORY_UPDATE + "/:id"}
                      exact
                      component={(props) => <PostCategoryUpdate />}
                    />

                    {/* ClimateAction */}
                    
                    <PrivateRoute
                      path={Const.PAGE_DONATE_LIST}
                      exact
                      component={(props) => <DonateList />}
                    />
                    <PrivateRoute
                      path={Const.PAGE_DONATE_DETAIL}
                      exact
                      component={(props) => <DonateDetail />}
                    />

                    {/* <PrivateRoute
                      path={Const.PAGE_CLIMATE_ACTION_APPROVED}
                      exact
                      component={(props) => <ClimateActionApprovedList />}
                    /> */}

                    {/* <PrivateRoute
                      path={Const.PAGE_CLIMATE_ACTION_REJECT}
                      exact
                      component={(props) => <ClimateActionRejected />}
                    />
                    <PrivateRoute
                      path={Const.PAGE_CLIMATE_ACTION_ADD}
                      exact
                      component={(props) => <ClimateActionAdd />}
                    />

                    <PrivateRoute
                      path={Const.PAGE_CLIMATE_ACTION_DETAIL + '/:id'}
                      exact
                      component={(props) => <ClimateActionDetail />}
                    />
                    <PrivateRoute
                      path={Const.PAGE_CLIMATE_ACTION_UPDATE + '/:id'}
                      exact
                      component={(props) => <ClimateActionUpdate />}
                    /> */}

                    {/* CertificateList  */}
                    <PrivateRoute
                      path={Const.PAGE_CERTIFICATE_LIST}
                      exact
                      component={(props) => <CertificateList />}
                    />
                    <PrivateRoute
                      path={Const.PAGE_CERTIFICATE_ADD}
                      exact
                      component={(props) => <CertificateAdd />}
                    />
                    <PrivateRoute
                      path={Const.PAGE_CERTIFICATE_UPADTE}
                      exact
                      component={(props) => <CertificateUpdate />}
                    />
                    <PrivateRoute
                    path={Const.PAGE_CERTIFICATE_DETAIL + "/:id"}
                    exact
                    component={(props) => <CertitficateDetail />}
                    />
                    
                    


                    {/* post */}
                    <PrivateRoute
                      path={Const.PAGE_POST_LIST}
                      exact
                      component={(props) => <PostList />}
                    />
                    <PrivateRoute
                      path={Const.PAGE_ADD_POST}
                      exact
                      component={(props) => <AddBlog />}
                    />
                    <PrivateRoute
                      path={Const.PAGE_POST_DETAIL + "/:id"}
                      exact
                      component={(props) => <PostDetail />}
                    />
                    <PrivateRoute
                      path={Const.PAGE_EDIT_POST + "/:id"}
                      exact
                      component={(props) => <EditBlog />}
                    />

                  </div>
                </Main>
              </React.Fragment>
            )}
          />

        </Switch>
      </Router>

    </>

  )
}

export default RouterLink;