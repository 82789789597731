import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import useReactRouter from "use-react-router";
import { Formik } from 'formik'
import { useMutation } from '@apollo/react-hooks';

import { CREATE_DONATE_WEBSITE } from "../../../apollo/donate/Mutation"

import Consts from "../../../consts"
import UploadPhoto from "../../../helpers/UploadPhoto";
import { successAdd, errorAdd } from '../../../helpers/sweetalert'

import { FaAngleRight } from "react-icons/fa";
import { Carousel, Container, Row, Col, Button, Modal, Form } from 'react-bootstrap'
import { FaSearch ,FaTimes} from "react-icons/fa";
import bgDonate from "../../../image/donate/bgDonate.png"
import newBg from "../../../image/donate/1.png"
import onePay from "../../../image/donate/onePay.jpeg"


import Footer from "../../../components/footer"

import './Index.css'


function DonateForm() {
    const { history, location } = useReactRouter();
    const [createDonate] = useMutation(CREATE_DONATE_WEBSITE)
    const [modalShow, setModalShow] = React.useState(false);
    const {
        namePhotoMany,
        buttonUploadAndShowPhotoMany,
        setWidhtPhoto,
        setHeightPhoto,
    } = UploadPhoto();

    const [checked, setChecked] = useState(false);
    const [errImage, setErrImage] = useState(false);

    let titleDetail = location?.state


    const onSave = async (data) => {
        try {

            if (namePhotoMany.length <= 0) {
                setErrImage(true)
            }
            else {

                let _data = {
                    amount: parseInt(data?.amount),
                    detail: data?.detail,
                    email: data?.email,
                    userName: data?.userName,
                    images: namePhotoMany,
                    consent: checked
                }

                let _create = await createDonate({ variables: { data: _data } })

                if (_create) {
                    successAdd("Donate Success")
                    setTimeout(() => {
                        history.push(Consts.PAGE_DONATE)
                    }, 2000);
                }
            }


        }
        catch (err) {
            console.log("error--->", err)
            errorAdd("Please try again")

        }
    }

    const _handleChange = () => {
        setChecked(!checked);
    };


    return (
        <div style={{ zIndex: 0 }}>
                        <div style={{ height: 150 }} className='hideHeight'></div>

            <Container fluid className='donate-img'>
                <Row className="m-0 p-0">
                    <Col md='12' xs={12} className="m-0 p-0 bannerDonate">
                        <img
                            className="bgImageDonate"
                            src={newBg}
                            alt="First slide"
                            style={{ width: "100%", height: "400px" }}
                        />
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row className='donateBox'>
                    <Col xs={12} sm={3} md={3} className="donateTitle">
                        <p style={{ marginTop: 40 }}>
                            {titleDetail?.title}
                        </p>
                    </Col>
                    <Col xs={12} sm={9} md={9} className="donateDatail-box">
                        <p className='donateDatail-text'>  {titleDetail?.detail} </p>
                    </Col>
                </Row>
            </Container>

            <Formik
                initialValues={{
                    amount: "",
                    detail: "",
                    email: "",
                    userName: "",
                }}
                validate={values => {
                    const errors = {}
                    if (!values.amount) {
                        errors.amount = 'Please input amount donate'
                    }
                    if (!values.detail) {
                        errors.detail = 'Please input detail'
                    }
                    if (!values.email) {
                        errors.email = 'Please input your email'
                    }
                    if (!values.userName) {
                        errors.userName = 'Please input your fullname'
                    }


                    return errors
                }}
                onSubmit={values => {
                    console.log("values--->", values)
                    onSave(values)

                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting
                    /* and other goodies */
                }) => (


                    <Container style={{ textAlign: "left" }}>
                        <Row>
                            <Col xs={12} sm={12} md={12}>
                                <h3 className="titleDetailTitle">Scan QR to pay</h3>
                            </Col>

                            <Col xs={12} sm={12} md={12} >
                                <center>
                                    <img style={{ width: 360, height: 350, padding: 20 }} src={onePay} className='imgQRCode' />
                                </center>
                            </Col>

                            <Col xs={12} sm={12} md={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label className="formTitles" style={{ textAlign: "left" }}>Name and Surname<span style={{ color: "red" }}>*</span></Form.Label>
                                    <Form.Control type="text" placeholder="Name and Surname....." name='userName' value={values.userName} onChange={handleChange} className="formInput" />
                                    <div style={{ color: 'red' }}>
                                        <p>{errors.userName && touched.userName && errors.userName}</p>
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label className="formTitles" style={{ textAlign: "left" }}>Email Address<span style={{ color: "red" }}>*</span></Form.Label>
                                    <Form.Control type="email" name='email' value={values.email} onChange={handleChange} style={{ height: "40px", fontSize: "14px", border: "1px solid #D1D5DB" }} placeholder="name@example.com...." className="formInput" />
                                    <div style={{ color: 'red' }}>
                                        <p>{errors.email && touched.email && errors.email}</p>
                                    </div>
                                </Form.Group>
                            </Col>

                            <Col xs={12} sm={12} md={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label className="formTitles" style={{ textAlign: "left" }}>Amount<span style={{ color: "red" }}>*</span></Form.Label>
                                    <Form.Control type="number"  placeholder="0" className="formInput" name='amount' value={values.amount} onChange={handleChange} style={{ height: "40px", fontSize: "14px", border: "1px solid #D1D5DB" }} />
                                    <div style={{ color: 'red' }}>
                                        <p>{errors.amount && touched.amount && errors.amount}</p>
                                    </div>
                                </Form.Group>
                            </Col>

                            <Col xs={12} sm={12} md={12} style={{ display: "flex", justifyContent: "center" }}>
                                <center>
                                    <p className="formTitle mt-2"> Upload receipt Image <span style={{ color: "red" }}>*</span></p>
                                    {buttonUploadAndShowPhotoMany()}

                                    <p style={{ color: "red" }}>
                                        {errImage ? "Please select your bill image" : ""}
                                    </p>


                                </center>
                            </Col>


                            <Col xs={12} sm={12} md={12}>
                                <Form.Group className="mb-3" style={{ textAlign: "left !important" }}>
                                    <Form.Label className="formTitles" style={{ textAlign: "left !important" }}>Message<span style={{ color: "red" }}>*</span></Form.Label>
                                    <Form.Control as="textarea" rows={4} placeholder="Masseger...." name='detail' value={values.detail} onChange={handleChange} style={{ fontSize: "18px" }} />
                                    <div style={{ color: 'red' }}>
                                        <p>{errors.detail && touched.detail && errors.detail}</p>
                                    </div>
                                </Form.Group>

                                <br />

                                <div className="form-check">
                                    <input className="form-check-input" style={{ width: 20, height: 20 }} type="checkbox" checked={checked}
                                        onChange={_handleChange} />
                                    <label className="form-check-label" htmlFor="defaultCheck1" style={{ marginLeft: "20px", color: "#000000" }}>
                                        I agree with the terms and conditions <span style={{ color: "#00A991", fontSize: "12px", fontWeight: "700" }} onClick={() => setModalShow(true)}>Here</span>
                                    </label>
                                    <p style={{ color: "#000000", padding: 20 }}>We strongly advise you to read the terms of service and privacy policies of any websites or services that you visit. By using services provided by us, you acknowledge that you have read these terms of service and agree to be bound by them.</p>
                                </div>
                            </Col>

                            <Col xs={12} sm={12} md={12} className='btnSubmit'>
                                <center>
                                    <button type='button' className='button-submit' style={{ backgroundColor: checked ? "" : "gray" }} disabled={!checked ? true : false} onClick={handleSubmit} >Donate</button>
                                </center>
                            </Col>


                        </Row>
                    </Container>
                )}
            </Formik>

            <Footer />
            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>

                    <Col xs={12} style={{ textAlign: 'right' }}>
                        <FaTimes style={{ marginTop: 15, marginRight: 15, fontSize: "20px",color:"#00A991" }} onClick={() => setModalShow(false)} />
                    </Col>
                    <p>
                        These Terms of Service (“Terms”, “Terms of Service”) govern your use of our website located at Zerowastelaos.org (together or individually “Service”) operated by Zero Waste Laos Organization.
                    </p>
                    <p>Our Privacy Policy also governs your use of our Service and explains how we collect, safeguard, and disclose information that results from your use of our web pages.</p>
                    <p>Your agreement with us includes these Terms and our Privacy Policy (“Agreements”). You acknowledge that you have read and understood Agreements and agree to be bound by them.</p>
                    <p>If you do not agree with (or cannot comply with) Agreements, then you may not use the Service, but please let us know by emailing at Email so we can try to find a solution. These Terms apply to all visitors, users and others who wish to access or use Service.</p>
                    <p>1. When you create an account with us, you guarantee that you are above the age of 15, and that the information you provide us is accurate, complete, and current always. Inaccurate, incomplete, or obsolete information may result in the immediate termination of your account on Service.</p>
                    <p>2. You shall make sure that you have a genuine intention to cooperate with us, not for the purpose of exploiting, harming, or attempting to exploit or harm minors, and other commercial or illegal purposes.</p>
                    <p>3. You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than you, without appropriate authorization. You may not use as a username any name that is offensive, vulgar, or obscene.</p>
                    <p>4. You may provide us either directly at Email or via third party sites and tools with information and feedback concerning errors, suggestions for improvements, ideas, problems, complaints, and other matters related to our Service (“Feedback”).</p>
                    <p>We strongly advise you to read the terms of service and privacy policies of any websites or services that you visit. By using services provided by us, you acknowledge that you have read these terms of service and agree to be bound by them.</p>
                </Modal.Body>
            </Modal>
        </div>
    )
}
export default DonateForm