import { gql } from "apollo-boost";

export const DONATE_DATA = gql`
query ExampleQuery($where: DonateWhereInput) {
  donates(where: $where) {
    total
    data {
      id
      userId {
        id
        firstName
        image
      }
      userName
      email
      amount
      phone
      images
      whatsapp
      consent
      detail
      createdAt
      createdBy {
        id
        firstName
      }
      updatedAt
      updatedBy {
        id
        firstName
      }
      note
      isDeleted
    }
  }
}

`;
