import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import useReactRouter from "use-react-router";

import Consts from "../../../consts"
import { dateTimeLao } from '../../../helpers/Helper';
import { useLazyQuery } from '@apollo/react-hooks'

import {POST} from "../../../apollo/post/Query"


import { FaAngleRight } from "react-icons/fa";
import { Carousel, Container, Row, Col, Button, ListGroup } from 'react-bootstrap'
import { FaSearch } from "react-icons/fa";
import NewsCover from "../../../image/blog/NewsCover.png"

import Footer from "../../../components/footer"

import './Index.css'
function BlogDetail() {
    const { history, location,match } = useReactRouter();

    const [postData, setPostData] = useState()


    const [loadPost, {data: postDetail}] = useLazyQuery(POST, { fetchPolicy: "network-only", variables:{where:{id:match?.params?.id}} })

    useEffect(() => {
        loadPost()
    },[])

    useEffect(() => {
        if (postDetail) {
            setPostData(postDetail?.post)
        }
    }, [postDetail])



    return (
        <div style={{ zIndex: 0 }}>
            <div style={{ height: 150 }} className='hideHeight'></div>
            {/* <Container fluid className='imgTitle'>
                <h2 className='blogTitle'>{postData?.topic ?? ""}</h2>
            </Container> */}
            <Container fluid className="m-0 p-0 bgImageNews">
                <Row className="m-0 p-0">
                    <Col md='12' xs={12} className="m-0 p-0 bannerNews">
                        <img className='imgNews' src={NewsCover} />
                        <div className="text-left-new">
                            {/* <center> */}
                            <p>{postData?.topic ?? ""}</p>
                            {/* </center> */}
                        </div>
                    </Col>
                </Row>

            </Container>

            <Container className='mt-5 mb-5'>
                <Row>
                    <Col xs={12} sm={{ span: 10, offset: 2 }} md={{ span: 8, offset: 2 }} >
                        <img
                            className="news-img"
                            src={Consts.URL_FOR_SHOW_PHOTO + postData?.imageProfile}
                            alt="First slide"
                            style={{ width: "100%", height: "auto" }}
                        />
                    </Col>
                    <Col xs={12} sm={{ span: 10, offset: 2 }} md={{ span: 8, offset: 2 }} className='mt-2'>
                        <h5 className='blog-detail-title'>{postData?.topic ?? ""}</h5>
                        <div className="blog-detail-text" style={{ display: "inline-block" }} dangerouslySetInnerHTML={{ __html: postData?.contents ?? "" }}></div>
                    </Col>


                    {postData?.images?.map((item, index) =>
                        <Col key={"imgs"+index} xs={12} sm={{ span: 10, offset: 2 }} md={{ span: 8, offset: 2 }} className='mt-5'>
                            <img
                                className="news-img"
                                src={Consts.URL_FOR_SHOW_PHOTO + item}
                                alt="First slide"
                                style={{ width: "100%", height: "auto" }}
                            />
                        </Col>
                    )}

                    <Col xs={12} sm={{ span: 10, offset: 2 }} md={{ span: 8, offset: 2 }} className='mt-5'>
                        <p className='blog-update'>{dateTimeLao(postData?.createdAt)}</p>
                    </Col>
                </Row>
            </Container>

            <Footer />

        </div>
    )
}
export default BlogDetail