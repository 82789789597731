import { gql } from "apollo-boost";

export const LOGIN_ADMIN = gql`
mutation Mutation($data: LoginAdminInput) {
  loginAdmin(data: $data) {
    accessToken
    data {
      id
      userId
      firstName
      role
      email
      
    }
  }
}
`;