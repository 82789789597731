import React, { useState, useEffect } from 'react'
import useReactRouter from "use-react-router";
import moment from "moment";
import { useLazyQuery } from '@apollo/react-hooks'
import { USERS_DATA } from "../../../apollo/user"



import { Breadcrumb, Container, Row, Form, Table, Image, Button, Col } from 'react-bootstrap'
import { FaEye, FaEdit, FaTrash, FaPlusCircle } from "react-icons/fa";
import Consts from "../../../consts"

import { Title, CustomButton } from "../../../common"
import { dateTimeLao } from "../../../helpers/Helper"


function VolunteerList() {
    const { history } = useReactRouter();
    const today = new Date();


    const [fillterName, setFillterName] = useState('')
    const [fillterStartDate, setFillterStartDate] = useState(moment(moment(today).add(-30, "days")).format('YYYY-MM-DD'))
    const [fillterEndDate, setFillterEndDate] = useState(moment(today).format('YYYY-MM-DD'))

    //function
    const [getUsers, { data }] = useLazyQuery(USERS_DATA, {
        fetchPolicy: "network-only",
        variables: {
            where: {
                role: "VOLUNTEER",
                firstName: fillterName === '' ? undefined : fillterName,
                startDate: fillterStartDate,
                endDate: fillterEndDate,
            }
        }
    });
    //function
    // ======>

    useEffect(() => {
        getUsers()
    }, [])
    return (
        <div>
            <Breadcrumb style={{ marginTop: 60, fontSize: '12px' }}>
                <Breadcrumb.Item active>Volunteer Management</Breadcrumb.Item>
            </Breadcrumb>

            <div style={Consts.MAIN_CARD} >
                <Title text='Volunteer Management' />

                <Row className="mt-2">
                    <Col md={6}>
                        <Form.Group >
                            <Form.Control size='lg' type="text" style={{ height: '40px', fontSize: '14px',marginTop: 30}} placeholder="Searching by name..." onChange={(e) => setFillterName(e?.target?.value)}/>
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group >
                            <Form.Label>From date</Form.Label>
                            <Form.Control size='lg' type="date" defaultValue={fillterStartDate} onChange={(e) => setFillterStartDate(e?.target?.value)}/>
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group >
                            <Form.Label>To date</Form.Label>
                            <Form.Control size='lg' type="date" defaultValue={fillterEndDate} onChange={(e) => setFillterEndDate(e?.target?.value)}/>
                        </Form.Group>
                    </Col>
                </Row>



                <div style={{ display: "flex", justifyContent: "space-between", marginTop: 20, marginBottom: 20 }}>
                    <h4>Show {data?.users?.data.length ?? "0"} List</h4>
                    <div>

                        <CustomButton addIcon title='Add Volunteer' onClick={() => history.push(Consts.PAGE_ADD_VOLUNTEER)} />

                    </div>
                </div>



                <Table striped>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Name and surname</th>
                            <th>Phone number</th>
                            <th>Email</th>
                            <th>Occupation</th>
                            <th>Created At</th>
                            {/* <th>Action</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {data?.users?.data?.map((item, index) =>
                            <tr key={index} onClick={() => history.push(Consts.PAGE_VOLUNTEER_DETAIL, item)}>
                                <td>{index + 1}</td>
                                <td>
                                    <Image src={item?.image ? Consts.URL_FOR_SHOW_PHOTO + item?.image : "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Circle-icons-profile.svg/1024px-Circle-icons-profile.svg.png"}
                                        style={{ width: 40, height: 40 }}
                                    />
                                    &nbsp;&nbsp;&nbsp;

                                    {item?.firstName}
                                </td>
                                <td>{item?.phone}</td>
                                <td>{item?.email}</td>
                                <td>{item?.Occupation}</td>
                                <td>{dateTimeLao(item?.createdAt)}</td>
                                {/* <td>
                                    <FaEye style={{ color: '#00A991', cursor: 'pointer', fontSize: '14px' }} onClick={() => history.push(Consts.PAGE_VOLUNTEER_DETAIL)} />
                                    &nbsp;&nbsp;&nbsp;
                                    <FaEdit style={{ color: '#00A991', cursor: 'pointer', fontSize: '14px' }} />
                                    &nbsp;&nbsp;&nbsp;
                                    <FaTrash style={{ color: 'red', cursor: 'pointer', fontSize: '14px' }} />
                                </td> */}
                            </tr>
                        )}


                    </tbody>
                </Table>


            </div>



        </div>
    )
}
export default VolunteerList