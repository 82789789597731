import React, { useState } from 'react'
import { PRESINGED_URL } from '../apollo/uploadFile'
import Swal from 'sweetalert2'
import { useMutation } from '@apollo/react-hooks'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImage } from '@fortawesome/free-solid-svg-icons'
import { v4 as uuidv4 } from 'uuid';
import Consts from "../consts/"
import { Image } from "react-bootstrap";
import { Receiver } from 'react-file-uploader';
import { Badge } from "@material-ui/core";



const UploadPhoto = () => {
  const [widhtPhoto, setWidhtPhoto] = useState(200)
  const [heightPhoto, setHeightPhoto] = useState(200)
  const [uploadPhoto] = useMutation(PRESINGED_URL)
  const [namePhoto, setNamePhoto] = useState()
  const [file, setFile] = useState('')
  const [imageLoading, setImageLoading] = useState()
  const handleUpload = async event => {
    setImageLoading('')
    try {
      setFile(event.target.files[0])
      let fileDataBoole = event?.target?.files[0]
      let fileDatatype = event?.target?.files[0]?.type
      let imageName = uuidv4() + '.' + fileDatatype.split('/')[1].toString()
      let checkFileUpload = fileDatatype.split('/')[0] !== 'application'
      if (checkFileUpload === false) {
        Swal.fire({
          icon: 'error',
          title: 'ກະລຸນາເລືອກຮູບພາບ',
          showConfirmButton: false,
          timer: 1500
        })
        return
      }

      console.log("imageName---->", imageName)
      console.log("fileDatatype.toString()---->", fileDatatype.toString())

      const resUploadPhoto = await uploadPhoto({
        variables: {
          fileName: imageName,
          mimeType: fileDatatype.toString()
        }
      })
      
      console.log("resUploadPhoto:::",resUploadPhoto?.data?.preSignedUrl?.url)

      await axios({
        method: 'put',
        url: resUploadPhoto.data.preSignedUrl.url,
        data: fileDataBoole,
        headers: {
          'Content-Type': ' file/*; image/*',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
          'Access-Control-Allow-Headers':
            'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With'
        },

        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          )
          setImageLoading(percentCompleted)
        }
      })
      setNamePhoto(imageName)
    } catch (error) { }
  }
  const ImageThumb = () => {
    return (
      <img
        src={Consts.URL_FOR_SHOW_PHOTO + namePhoto}
        alt={namePhoto}
        style={{
          borderRadius: '10px',
          height: heightPhoto,
          width: widhtPhoto
        }}
      />
    )
  }

  const buttonUploadAndShowPhoto = () => {
    return (
      <div className='form-row'>
        <div className='col-12'>
          <div className='form-group'>
            <div style={{ textAlign: 'center' }}>
              <input
                type='file'
                id='file-upload'
                onChange={handleUpload}
                hidden
                accept="image/png, image/gif, image/jpeg, image/jpg"
              />
              <label htmlFor='file-upload'>
                <div
                  style={{
                    height: heightPhoto,
                    width: widhtPhoto,
                    borderRadius: '10px',
                    cursor: 'pointer',
                    display: 'flex',
                    backgroundColor: '#DDDDDD'
                  }}
                >
                  {namePhoto ? (
                    <ImageThumb image={namePhoto} />
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        height: heightPhoto,
                        width: widhtPhoto,
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >

                      <FontAwesomeIcon icon={faImage} style={{
                        fontSize: 50,
                        color: '#ffffff'
                      }} />
                    </div>
                  )}
                </div>
              </label>
              {/* progass */}
              {imageLoading ? (
                <div className='progress' style={{ height: 20 }}>
                  <div
                    className='progress-bar'
                    role='progressbar'
                    style={{
                      width: `${imageLoading}%`,
                      backgroundColor: 'green'
                    }}
                    aria-valuenow={imageLoading}
                    aria-valuemin='0'
                    aria-valuemax='100'
                  >
                    {imageLoading}%
                  </div>
                </div>
              ) : (
                <div style={{ height: 20 }} />
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
  // ========> upload photo many ======>
  const [namePhotoMany, setNamePhotoMany] = useState([])
  const [fileMany, setFileMany] = useState('')
  const [imageLoadingMany, setImageLoadingMany] = useState()
  const handleUploadMany = async event => {
    setImageLoadingMany('')
    let _newPhotoMany = [...namePhotoMany]
    try {
      setFileMany(event.target.files[0])
      let fileDataBoole = event?.target?.files[0]
      let fileDatatype = event?.target?.files[0]?.type
      let imageName = uuidv4() + '.' + fileDatatype.split('/')[1].toString()
      let checkFileUpload = fileDatatype.split('/')[0] !== 'application'
      if (checkFileUpload === false) {
        Swal.fire({
          icon: 'error',
          title: 'ກະລຸນາເລືອກຮູບພາບ',
          showConfirmButton: false,
          timer: 1500
        })
        return
      }
      const resUploadPhoto = await uploadPhoto({
        variables: {
          fileName: imageName,
          mimeType: fileDatatype.toString()
        }
      })
      let afterUpload = await axios({
        method: 'put',
        url: resUploadPhoto.data.preSignedUrl.url,
        data: fileDataBoole,
        headers: {
          'Content-Type': ' file/*; image/*',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
          'Access-Control-Allow-Headers':
            'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With'
        },

        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          )
          setImageLoadingMany(percentCompleted)
        }
      })
      if (imageName) _newPhotoMany.push(imageName)
      setNamePhotoMany(_newPhotoMany)
    } catch (error) { }
  }


  const [widhtPhotoFamily, setWidhtPhotoFamily] = useState(200)
  const [heightPhotoFamily, setHeightPhotoFamily] = useState(200)

  const _onDeleteFile = (item) => {
    let filnameData = namePhotoMany?.filter((data) => data !== item)
    setNamePhotoMany(filnameData)
  }
  const buttonUploadAndShowPhotoMany = () => {
    return (
      <div className='form-row'>
        <div className='col-12'>
          <div className='form-group'>
            <div style={{ display: 'flex' }}>
              <div>
                {namePhotoMany?.map((namePhotoMany, index) => {
                  return (
                    <Badge badgeContent="X" color="secondary" style={{backgroundColor:"#ffffff"}} onClick={() => _onDeleteFile(namePhotoMany)}>
                        <div
                          style={{
                            padding: 0,
                            margin: 0,
                            height: heightPhotoFamily,
                            width: widhtPhotoFamily,
                            display: 'flex', flexDirection: 'row',
                            backgroundColor:"#ffffff"
                          }}
                          key={'photmany' + index}
                        >
                          <Image
                            src={Consts.URL_FOR_SHOW_PHOTO + namePhotoMany}
                            alt="Emart"
                            style={{
                              padding: 0,
                              margin: 0,
                              paddingLeft: 5,
                              height: heightPhotoFamily,
                              width: widhtPhotoFamily,
                              borderRadius: 5,
                              backgroundColor:"none"
                            }}
                          />
                        </div>
                    </Badge>
                  )
                })}
              </div>

              <div>
                <input
                  type='file'
                  id='file-upload-many'
                  onChange={handleUploadMany}
                  hidden
                />
                <label htmlFor='file-upload-many' style={{
                  padding: 0,
                  margin: 0,
                }}>
                  <div
                    style={{
                      height: heightPhotoFamily,
                      width: widhtPhotoFamily,
                      borderRadius: 5,
                      cursor: 'pointer',
                      display: 'flex',
                      backgroundColor: '#DDDDDD',
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        height: heightPhotoFamily,
                        width: widhtPhotoFamily,
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: 0,
                        margin: 0,
                        borderRadius: 5,

                      }}
                    >
                      <FontAwesomeIcon icon={faImage} style={{
                        fontSize: 50,
                        color: '#ffffff'
                      }} />
                    </div>
                  </div>
                </label>
              </div>




            </div>
            {/* progass */}
            {imageLoadingMany ? (
              <div className='progress' style={{ height: 20 }}>
                <div
                  className='progress-bar'
                  role='progressbar'
                  style={{
                    width: `${imageLoadingMany}%`,
                    backgroundColor: 'green'
                  }}
                  aria-valuenow={imageLoadingMany}
                  aria-valuemin='0'
                  aria-valuemax='100'
                >
                  {imageLoadingMany}%
                </div>
              </div>
            ) : (
              <div style={{ height: 20 }} />
            )}
            {/* </div> */}
          </div>
        </div>
      </div>
    )
  }


  const [widhtPhotoCard, setWidhtPhotoCard] = useState(200)
  const [heightPhotoCard, setHeightPhotoCard] = useState(200)
//   const [uploadPhotoCard] = useMutation(PRESINGED_URL)
  const [namePhotoCard, setNamePhotoCard] = useState()
  const [fileCard, setFileCard] = useState('')
  const [imageLoadingCard, setImageLoadingCard] = useState()
  const handleUploadCard = async event => {
    setImageLoadingCard('')
    try {
      setFileCard(event.target.files[0])
      let fileDataBoole = event?.target?.files[0]
      let fileDatatype = event?.target?.files[0]?.type
      let imageNameCard = uuidv4() + '.' + fileDatatype.split('/')[1].toString()
      let checkFileUploadCard = fileDatatype.split('/')[0] !== 'application'
      if (checkFileUploadCard === false) {
        Swal.fire({
          icon: 'error',
          title: 'ກະລຸນາເລືອກຮູບພາບ',
          showConfirmButton: false,
          timer: 1500
        })
        return
      }
    //   const resUploadPhotoCard = await uploadPhotoCard({
    //     variables: {
    //       fileName: imageNameCard,
    //       mimeType: fileDatatype.toString()
    //     }
    //   })
      let afterUploadCard = await axios({
        method: 'put',
        // url: resUploadPhotoCard.data.preSignedUrl.url,
        data: fileDataBoole,
        headers: {
          'Content-Type': ' file/*; image/*',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
          'Access-Control-Allow-Headers':
            'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With'
        },

        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          )
          setImageLoadingCard(percentCompleted)
        }
      })
      setNamePhotoCard(imageNameCard)
    } catch (error) { }
  }
  const ImageThumbCard = () => {
    return (
      <img
        src={Consts.URL_FOR_SHOW_PHOTO + namePhotoCard}
        alt={namePhotoCard}
        style={{
          height: heightPhotoCard,
          width: widhtPhotoCard
        }}
      />
    )
  }
  const buttonUploadAndShowPhotoCard = () => {
    return (
      <div className='form-row'>
        <div className='col-12'>
          <div className='form-group'>
            <div style={{ textAlign: 'center' }}>
              <input
                type='file'
                id='file-upload-card'
                onChange={handleUploadCard}
                hidden
              />
              <label htmlFor='file-upload-card'>
                <div
                  style={{
                    height: heightPhotoCard,
                    width: widhtPhotoCard,
                    cursor: 'pointer',
                    display: 'flex',
                    backgroundColor: '#DDDDDD'
                  }}
                >
                  {namePhotoCard ? (
                    <ImageThumbCard image={namePhotoCard} />
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        height: heightPhotoCard,
                        width: widhtPhotoCard,
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >

                      <FontAwesomeIcon icon={faImage} style={{
                        fontSize: 50,
                        color: '#ffffff'
                      }} />
                    </div>
                  )}
                </div>
              </label>
              {/* progass */}
              {imageLoadingCard ? (
                <div className='progress' style={{ height: 20 }}>
                  <div
                    className='progress-bar'
                    role='progressbar'
                    style={{
                      width: `${imageLoadingCard}%`,
                      backgroundColor: 'green'
                    }}
                    aria-valuenow={imageLoadingCard}
                    aria-valuemin='0'
                    aria-valuemax='100'
                  >
                    {imageLoadingCard}%
                  </div>
                </div>
              ) : (
                <div style={{ height: 20 }} />
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
  const buttonUploadAndShowPhotoEdit = (image) => {
    return (
      <div class="form-row">
        <dov class="col-12">
          <div class="form-group">
            <div style={{ textAlign: "center" }}>
              <input
                type="file"
                id="file-upload"
                onChange={handleUpload}
                hidden
              />
              <label for="file-upload">
                <div
                  style={{
                    height: 200,
                    width: 200,
                    borderRadius: "50%",
                    cursor: "pointer",
                    display: "flex",
                    backgroundColor: "#DDDDDD",
                  }}
                >
                  {file ? (
                    <ImageThumb image={file} />
                  ) : (
                      <img
                        src={Consts.URL_FOR_SHOW_PHOTO + image}
                        alt={namePhoto}
                        style={{
                          borderRadius: '10px',
                          height: heightPhoto,
                          width: widhtPhoto
                        }}
                      />
                  )}
                </div>
              </label>
              {/* progass */}
              {imageLoading ? (
                <div class="progress" style={{ height: 20 }}>
                  <div
                    class="progress-bar"
                    role="progressbar"
                    style={{
                      width: `${imageLoading}%`,
                      backgroundColor: "#476fbc",
                    }}
                    aria-valuenow={imageLoading}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    {imageLoading}%
                  </div>
                </div>
              ) : (
                <div style={{ height: 20 }} />
              )}
            </div>
          </div>
        </dov>
      </div>
    )
  }

  return {
    namePhoto,
    namePhotoMany,
    namePhotoCard,
    setWidhtPhoto,
    setHeightPhoto,
    buttonUploadAndShowPhoto,
    buttonUploadAndShowPhotoMany,
    buttonUploadAndShowPhotoEdit,
    setWidhtPhotoFamily,
    setHeightPhotoFamily,
    buttonUploadAndShowPhotoCard,
    setHeightPhotoCard,
    setWidhtPhotoCard,
    setNamePhotoMany,
    setNamePhotoCard,
    setNamePhoto
  }
}
export default UploadPhoto
