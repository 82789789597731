import moment from "moment";

const TOKEN_KEY = 'userToken';

export const isLogin = () => {
    if(localStorage.getItem(TOKEN_KEY)){
        return true;
    }
    return false;
}
export const moneyCurrency = (value) => {
    if (value) {
        let currencys = new Intl.NumberFormat("en-CA").format(value);
        return currencys;
    } else {
        return 0;
    }
};
export const dateTimeLao = (today) => {
    var todays = moment(today).format("DD-MM-YYYY");
    return todays === "Invalid date" ? "-" : todays;
};

export const errorCass = (item) => {
    return item == 'GraphQL error: NAME_IS_READY' ? "This name is ready to use" :
        item == 'GraphQL error: EMAIL_IS_NOT_READY' ? "ອີເມວຂອງທ່ານໄດ້ຖືກນຳໃຊ້ລົງທະບຽນແລ້ວ" :
            item == 'GraphQL error: INVALID_EMAIL_OR_PASSWORD' ? "Can not Access" :
                item == 'GraphQL error: USERID_IS_NOT_READY' ? "USERID_IS_READY" : "The system has a problem";
}