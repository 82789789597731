import React, { useState, useEffect } from 'react'
import useReactRouter from "use-react-router";
import moment from "moment";
import { useLazyQuery } from '@apollo/react-hooks'
import { USERS_DATA } from "../../../apollo/user"

import { downloadExcel } from "../../../helpers/exportToExcel";

import { Breadcrumb, Row, Form, Table, Image, Col, Modal, Button } from 'react-bootstrap'
import Consts from "../../../consts"
import CUSD_STAFF from '../../../crud/user';
import NavClimate from './NavClimate'
import { Title, CustomButton } from "../../../common"
import { dateTimeLao } from "../../../helpers/Helper"


function ClimateList() {
    const { history } = useReactRouter();
    const today = new Date();
  const [isExport, setIsExport] = useState(false);
const [userList,setUsersList] = useState([]);
    const {
        _updateStaff,
    } = CUSD_STAFF();

    const [fillterName, setFillterName] = useState('')
    const [fillterStartDate, setFillterStartDate] = useState(moment(moment(today).add(-30, "days")).format('YYYY-MM-DD'))
    const [fillterEndDate, setFillterEndDate] = useState(moment(today).format('YYYY-MM-DD'))

    const [selectData, setselectData] = useState({
        action: "",
        data:{}
    })
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (item,action) => {
        setselectData(
            {
                action: action,
                data: item
            }
        )
        setShow(true);
    }
    //function
    const [getUsers, { data }] = useLazyQuery(USERS_DATA, {
        fetchPolicy: "network-only",
        variables: {
            where: {
                role: "USER",
                appover: "WAITTING",
                firstName: fillterName === '' ? undefined : fillterName,
                startDate: fillterStartDate,
                endDate: fillterEndDate,
            }
        }
    });
    //function
    const _updateStatus =async (item) => {
        let _data = {
            appover: item
        }
        _updateStaff(_data, selectData?.data?.id, Consts.PAGE_CLIMATE_LIST)
    }
    // ======>
    useEffect(() => {
        getUsers()
    }, [])



  useEffect(() => {
    if (data?.users?.data && !isExport) {
      setUsersList(data?.users?.data);
    }
    if (data?.users?.data && data?.users?.data.length > 0 && isExport) {
      const rows = data?.users?.data?.map((user, index) => ({
        index: index + 1,
        firstName: user?.firstName,
        birth:dateTimeLao(user?.birthday),
        email: user?.email ?? "-",
        phone: user?.phone ?? "-",
        Created: dateTimeLao(user?.createdAt) ?? "-",
     
      }));

      const titles = [
        "No",
        "firstName",
        "birth",
        "Email",
        "Phone",
        "Created",
      ]
      const fileName = `Zero-Waste-Waiting-${moment().format("DDMMYYYYHHmmss")}.xlsx`
      downloadExcel(titles, rows, fileName)
      setIsExport(false)
    }
  }, [data?.users?.data, isExport]);
    

    const onDownloadExcel = async () => {
        try {
          await getUsers()
          setIsExport(true)
        } catch (error) {
          console.log("error: ", error);
        }
      }


    return (
        <div>
            <Breadcrumb style={{ marginTop: 60, fontSize: '12px' }}>
                <Breadcrumb.Item active>Climate Action</Breadcrumb.Item>
            </Breadcrumb>
            <NavClimate/>
            <div style={Consts.MAIN_CARD} >
                <Title text='Climate Action' />

                <Row className="mt-2">
                    <Col md={6}>
                        <Form.Group >
                            <Form.Control size='lg' type="text" style={{ marginTop: 30,height:36,fontSize:16 }} placeholder="Searching by name..." onChange={(e) => setFillterName(e?.target?.value)}/>
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group >
                            <Form.Label>From date</Form.Label>
                            <Form.Control size='lg' type="date" defaultValue={fillterStartDate} onChange={(e) => setFillterStartDate(e?.target?.value)}/>
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group >
                            <Form.Label>To date</Form.Label>
                            <Form.Control size='lg' type="date" defaultValue={fillterEndDate} onChange={(e) => setFillterEndDate(e?.target?.value)}/>
                        </Form.Group>
                    </Col>
                </Row>



                <div style={{ display: "flex", justifyContent: "space-between", marginTop: 20, marginBottom: 20 }}>
                    <h4>Show {data?.users?.data?.length} List</h4>
                    <div>
                        <CustomButton addIcon title='Download Excel'
                            style={{ marginRight: 10, backgroundColor:"#E5E5E5",color:"#000000",border:"solid 0px" }}
                            onClick={() => onDownloadExcel()}
                        />
                        <CustomButton addIcon title='Add'
                            onClick={() => history.push(Consts.PAGE_CLIMATE_ADD)}
                        />
                    </div>
                </div>
                <Table striped>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Name and surname</th>
                            <th>Email</th>
                            <th>Date of birth</th>
                            <th>Phone number</th>
                            <th>Created At</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {userList.map((item, index) =>
                            <tr key={index}
                            >
                                <td onClick={() => history.push(Consts.PAGE_CLIMATE_DETAIL, item)}>{index + 1}</td>
                                <td onClick={() => history.push(Consts.PAGE_CLIMATE_DETAIL, item)}>
                                    <Image src={item?.image ? Consts.URL_FOR_SHOW_PHOTO + item?.image : "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Circle-icons-profile.svg/1024px-Circle-icons-profile.svg.png"}
                                        style={{ width: 40, height: 40 }}
                                    />
                                    &nbsp;&nbsp;&nbsp;

                                    {item?.firstName}
                                </td>
                                <td onClick={() => history.push(Consts.PAGE_CLIMATE_DETAIL, item)}>{item?.email ?? "-"}</td>
                                <td onClick={() => history.push(Consts.PAGE_CLIMATE_DETAIL, item)}>{dateTimeLao(item?.birthday)}</td>
                                <td onClick={() => history.push(Consts.PAGE_CLIMATE_DETAIL, item)}>{item?.phone ?? "-"}</td>
                                <td onClick={() => history.push(Consts.PAGE_CLIMATE_DETAIL, item)}>{dateTimeLao(item?.createdAt)}</td>
                                <td>
                                    <button
                                        onClick={()=>handleShow(item,"Appover")}
                                        style={{ padding: 5, width: 80, backgroundColor: "#00A991", color: "#FFFFFF", borderRadius: 5, border: "solid 0px" }}>
                                        verify
                                    </button>
                                    <button
                                        onClick={() => handleShow(item,"Reject")}
                                        style={{ padding: 5, width: 80, backgroundColor: "#CBD5E1", color: "#FFFFFF", borderRadius: 5, border: "solid 0px", marginLeft: 5 }}>
                                        Reject
                                    </button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title style={{ color: selectData?.action === "Appover" ? "green":"red"}}>{selectData?.action}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{textAlignLast:"center"}}>
                        {selectData?.action === "Appover" ? "Do you want Appover " + selectData?.data?.firstName + " ? " : "Do you want Reject " + selectData?.data?.firstName + " ? "}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => _updateStatus(selectData?.action === "Appover" ? "APPROVED" :"NOT_APPROVED")}>
                        Appove
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>
    )
}
export default ClimateList