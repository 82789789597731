import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import useReactRouter from "use-react-router";
import { Formik } from 'formik'
import { useMutation } from '@apollo/react-hooks'
import { USER_KEY } from '../consts'

import LogoZero from "../image/logo/zerologo.png"
import newLogo from "../image/logo/newLogo.png"
import Usa from "../image/Usa.png"
import Lao from "../image/Lao.png"
import Developing from "../image/developing.gif"


import Consts from '../consts'
import { CREATE_REGISTER, USER_LOGIN } from "../apollo/register/Mutation"

import { successAdd, errorAdd } from '../helpers/sweetalert'



import './navbar.css'
import { Navbar as NavbarMenu, Container, Form, Button, FormControl, NavDropdown, Nav, Modal, Offcanvas } from 'react-bootstrap'
import { FaFacebookSquare, FaYoutubeSquare, FaInstagramSquare, FaAngleDown, FaTimes, FaHips } from "react-icons/fa";
import { GiCondorEmblem, GiHamburgerMenu } from "react-icons/gi";

const Navbar = () => {
    const { history, location } = useReactRouter();
    const [createRegistere] = useMutation(CREATE_REGISTER)
    const [userLogin] = useMutation(USER_LOGIN)
    const user = localStorage?.getItem(USER_KEY)
    const _userRole = JSON?.parse(user)
    const _userInfo = _userRole?.data

    console.log('user---->',_userInfo)

    const [checkUserData, setCheckUserData] = useState(false)

    const [showDeveloping, setShowDeveloping] = useState(false);

    const handleClose = () => setShowDeveloping(false);
    const handleShow = () => setShowDeveloping(true);

    const [pathNames, setPathNames] = useState("/")

    const [userDataInfo, setUserDataInfo] = useState()

    const [showLogin, setShowLogin] = useState(false);
    const [showSignUp, setShowSignUp] = useState(false);

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("ZEROWASTE"))
        const _resDataUser = localStorage?.getItem(USER_KEY)
        const _localUserJson = JSON?.parse(_resDataUser)
        setUserDataInfo(_localUserJson)
        setPathNames(location?.pathname.split("/")[1])
    }, [])

    useEffect(() => {
        if (!_userInfo) {
            setCheckUserData(true)
        }
    }, [_userInfo])


    const handleCloseLogin = () => setShowLogin(false);
    const handleShowLogin = () => {
        if (checkUserData) {
            setShowLogin(true)
            setShowSignUp(false)
        }
        else {
            history.push(Consts.USER_PLANT_DONATE)
        }
    };

    const handleCloseSignUp = () => setShowSignUp(false);
    const handleShowSignUp = () => {
        setShowSignUp(true)
        setShowLogin(false)
    }
    // oncreate

    const createAccount = async (data) => {
        try {
            let createAccount = await createRegistere({ variables: { data: data } })

            if (createAccount?.data?.registration) {
                await successAdd("Register Success")
                setShowSignUp(false)

                let _userLogin = await userLogin({
                    variables: {
                        data: {
                            email: data?.email,
                            password: data?.password
                        }
                    }
                })

                if (_userLogin?.data?.loginUser?.accessToken) {
                    await localStorage.setItem(Consts.USER_KEY, JSON.stringify(_userLogin?.data?.loginUser))
                    history.replace(Consts.USER_ACCOUNT)
                }
            }

        }
        catch (err) {
            errorAdd(err)
        }
    }
    // user login callback
    const loginUser = async (data) => {
        try {
            let _userLogin = await userLogin({
                variables: {
                    data: {
                        email: data?.email,
                        password: data?.password
                    }
                }
            })

            if (_userLogin?.data?.loginUser?.accessToken) {
                setShowLogin(false)
                let _approved = _userLogin?.data?.loginUser?.data?.appover

                if (_approved === "APPROVED") {
                    await localStorage.setItem(Consts.USER_KEY, JSON.stringify(_userLogin?.data?.loginUser))
                    history.replace(Consts.USER_PROFILE)
                    window.location.reload();
                }
                else if (_approved === "PENDING") {
                    await localStorage.setItem(Consts.USER_KEY, JSON.stringify(_userLogin?.data?.loginUser))
                    history.replace(Consts.USER_ACCOUNT)
                    window.location.reload();
                }
                else if (_approved === "WAITTING") {
                    await localStorage.setItem(Consts.USER_KEY, JSON.stringify(_userLogin?.data?.loginUser))
                    history.replace(Consts.USER_APPROVED)
                    window.location.reload();
                }
                else {
                    await localStorage.setItem(Consts.USER_KEY, JSON.stringify(_userLogin?.data?.loginUser))
                    history.replace(Consts.USER_APPROVED)
                    window.location.reload();
                }
            }
        }
        catch (err) {
            errorAdd(err)
        }
    }
    const _onLogout = () => {
        localStorage.clear()
        sessionStorage.clear()
        history.push('/')
        window.location.reload();
    }
    return (
        <div style={{
            position: "fixed",
            width: "100%",
            zIndex: 100,
        }}>
            <div className='menu-main'
                style={{
                    display: history?.location?.pathname.split("-")[0] === "/admin" ? "none" : history?.location?.pathname.split("-")[0] === "/" ? "" : ""
                }}>
                <div className="menu-head-main">
                    <div className='menu-head-main-left' onClick={() => history.push("/")}>
                        <img className="menu-logo-img" style={{width:"120px", height:"90px"}} src={newLogo} />

                    </div>
                    <div className='menu-head-main-right'>
                        <div className="dropdown" hidden={!userDataInfo?.accessToken ? true : false}>
                            <button className="dropbtn"> {userDataInfo?.data?.firstName} <FaAngleDown /></button>

                            <div className="dropdown-content">
                                <a href="#" hidden={_userInfo?.appover === "PENDING" || _userInfo?.appover === "WAITING" ? true : false} onClick={() => history.push(Consts.USER_PROFILE)} className='item-link'>Profile</a>
                                <a className='item-link' onClick={() => _onLogout()}>Log Out</a>
                            </div>
                        </div>
                        <button type='button' className='button-header button-donate' onClick={() => history.push(Consts.PAGE_DONATE)}>Donate</button>
                        <button type='button' className='button-header button-tree-donate' onClick={handleShowLogin}>Climate Action</button>

                        <a
                            href="#"
                            target="_thapa"
                            className="language-img"
                        >

                            <img src={Usa} />
                        </a>
                        <a
                            href="#"
                            target="_thapa"
                            className="language-img"
                            style={{ opacity: 0.4 }}
                        >
                            <img src={Lao} />
                        </a>

                    </div>
                </div>
                <div className='menu-list'>
                    <div className='menu-link'>
                        <Link to='/' className='item-link' style={{ color: location?.pathname === "/" ? "#00A991" : "" }}>Home</Link>
                    </div>
                    <div className='menu-link'>
                        <Link to={Consts.PAGE_ABOUT}
                            className='item-link'
                            style={{ color: location?.pathname === "/about" || location?.pathname === "/about-pro2022" || location?.pathname === "/about-pro2021" || location?.pathname === "/about-pro2020" || location?.pathname === "/about-pro2019"  ? "#00A991" : "" }}>About us</Link>

                    </div>
                    <div className='menu-link dropdown'>
                        <a className="dropbtn item-link" style={{ color: location?.pathname === "/program1" || location?.pathname === "/program2" || location?.pathname === "/program3" || location?.pathname === "/program4" ? "#00A991" : "" }} >Programs <FaAngleDown /></a>
                        <div className="dropdown-content">
                            <Link to={Consts.PAGE_PROGRAME1} className='item-link'>Campaign/ Workshop</Link>
                            <Link to={Consts.PAGE_PROGRAME2} className='item-link'>Research</Link>
                            <Link to={Consts.PAGE_PROGRAME3} className='item-link'>Capacity Building</Link>
                            <Link to={Consts.PAGE_PROGRAME4} className='item-link'>CSR Collaboration </Link>
                        </div>
                    </div>
                    <div className='menu-link'>
                        <Link to={Consts.PAGE_VOLUNTEER} className='item-link' style={{ color: location?.pathname === "/volunteer" ? "#00A991" : "" }}>Volunteer</Link>

                    </div>
                    <div className='menu-link'>
                        {/* <a href='#' className='item-link '>News and Update</a> */}
                        <Link to={Consts.PAGE_BLOG} className='item-link' style={{ color: location?.pathname === "/blog" || location?.pathname === "/blog-detail" ? "#00A991" : "" }}>News and Update</Link>

                    </div>
                    <div className='menu-link'>
                        <a href="#" onClick={() => history.push(Consts.PAGE_CONTACT, 'contact')} className='item-link' style={{ color: location?.pathname === "/contact" ? "#00A991" : "" }}>Contact us</a>

                        {/* <a href='#' className='item-link '> Contact us</a> */}
                    </div>
                </div>
                <div
                // onClick={navToggle} className={icon}
                >
                    <div className="line1"></div>
                    <div className="line2"></div>
                    <div className="line3"></div>
                </div>
            </div>






            <header className="header">
                <div className="logo">
                    <img onClick={() => _onLogout()} className="menu-logo-img" style={{width:100,height:80}} src={newLogo} />
                </div>
                <div className="dropdown" hidden={!userDataInfo?.accessToken ? true : false}>
                    {/* <button className="dropbtnProfile">{userDataInfo?.data?.firstName} <FaAngleDown /></button> */}
                    <a href="#" style={{ textDecoration: "none", color: "#5c5c5c5c", fontWeight: "900" }} className="">{userDataInfo?.data?.firstName} <FaAngleDown /></a>
                    <div className="dropdown-content">
                        <a href="#" hidden={_userInfo?.appover === "PENDING" || _userInfo?.appover === "WAITING" ? true : false} onClick={() => history.push(Consts.USER_PROFILE)} className='item-link'>Profile</a>
                        <a v className='item-link' onClick={() => _onLogout()}>Log Out</a>
                    </div>
                </div>
                <a href="#" className="nav_ico">
                    <label htmlFor="menu_trigger">
                    </label>
                    <span></span>
                    <span></span>
                    <span></span>
                </a>

            </header>
            <input type="radio" id="menu_trigger" name="menu_close" />
            <nav className="side_nav">
                <span className="close_icon">+
                    <input type="radio" name="menu_close" />
                </span>
                <ul>
                    <li>
                        <a href="#" onClick={() => _onLogout()} className='item-link active-link' style={{ fontWeight:"bold",color: location?.pathname === "/" ? "#00A991" : "" }}>Home</a>

                    </li>
                    <li>
                        <a href="#" onClick={() => history.push(Consts.PAGE_ABOUT)} className='item-link' style={{ fontWeight:"bold",color: location?.pathname === "/about" || location?.pathname === "/about-pro2022" || location?.pathname === "/about-pro2021" || location?.pathname === "/about-pro2020" || location?.pathname === "/about-pro2019" ? "#00A991" : "" }}>About us</a>

                    </li>
                    <li className='dropdown'>
                        {/* ddd */}
                        <a href="#" className="" style={{fontWeight:"bold", color: location?.pathname === "/program1" || location?.pathname === "/program2" || location?.pathname === "/program3" || location?.pathname === "/program4" ? "#00A991" : "" }}>Programs <FaAngleDown /></a>
                        <div className="dropdown-content">
                            <a href="#" onClick={() => history.push(Consts.PAGE_PROGRAME1)} className='item-link'>Campaign/ Workshop</a>
                            <a href="#" onClick={() => history.push(Consts.PAGE_PROGRAME2)} className='item-link'>Research</a>
                            <a href="#" onClick={() => history.push(Consts.PAGE_PROGRAME3)} className='item-link'>Capacity Building</a>
                            <a href="#" onClick={() => history.push(Consts.PAGE_PROGRAME4)} className='item-link'>CSR Collaboration</a>

                        </div>
                    </li>
                    <li>
                        <a href="#" onClick={() => history.push(Consts.PAGE_VOLUNTEER)} className='item-link' style={{ fontWeight:"bold",color: location?.pathname === "/volunteer" ? "#00A991" : "" }}>Volunteer</a>

                    </li>
                    <li>
                        <a href="#" onClick={() => history.push(Consts.PAGE_BLOG)} className='item-link' style={{ fontWeight:"bold",color: location?.pathname === "/blog" || location?.pathname === "/blog-detail" ? "#00A991" : "" }}>News and Update</a></li>
                    <li>
                        <a href="#" onClick={() => history.push(Consts.PAGE_CONTACT)} className='item-link' style={{ fontWeight:"bold",color: location?.pathname === "/contact" ? "#00A991" : ""}}>Contact us</a>
                    </li>
                </ul>
                <div className='btnDonateSideNav'>
                    <button type='button' className='button-header button-donate' onClick={() => history.push(Consts.PAGE_DONATE)}>Donate</button>
                    <button type='button' className='button-header button-tree-donate' onClick={handleShowLogin}>Climate Action</button>
                </div>
                <div className='langueage'>
                    <center>
                        <img src={Usa} />
                        &nbsp;&nbsp;&nbsp;
                        <img src={Lao} />
                    </center>
                </div>


            </nav>


            <Formik
                initialValues={{
                    email: "",
                    password: "",
                }}
                validate={values => {
                    const errors = {}


                    if (!values.email) {
                        errors.email = "Please input your email";
                      } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
                      )
                      {
                        errors.email = "Your email is not correct format";
                      }
                    if (!values.password) {
                        errors.password = 'Please input your password (zero waste)'
                    }

                    if(values.password.length < 6){
                        errors.password = 'Please input your password 6 alphabets'
                    }


                    return errors
                }}
                onSubmit={(values, { resetForm }) => {

                    let _data = {
                        email: values?.email,
                        password: values?.password
                    }
                    loginUser(_data)
                    resetForm({ values: '' })
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting
                    /* and other goodies */
                }) => (

                    <Modal show={showLogin} onHide={handleCloseLogin}>
                        <div style={{ float: 'right', padding: 20 }}>
                            <FaTimes style={{ float: 'right', fontSize: 20, cursor: "pointer" }} onClick={handleCloseLogin} />
                        </div>

                        <Modal.Body className='login-form'>
                            <h3 className='login-title'>Please log in</h3>
                            <br />

                            <Form.Group className="mb-3">
                                <Form.Control type="email" placeholder="Email..." name='email' value={values.email} onChange={handleChange} style={{ height: "40px", fontSize: "14px" }} />
                                <div style={{ color: 'red' }}>
                                    <p>{errors.email && touched.email && errors.email}</p>
                                </div>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Control type="password" placeholder="******" name='password' value={values.password} onChange={handleChange} style={{ height: "40px", fontSize: "14px" }} />
                                <div style={{ color: 'red' }}>
                                    <p>{errors.password && touched.password && errors.password}</p>
                                </div>
                            </Form.Group>
                            <br />

                            <button type="submit" className="btn-login" onClick={handleSubmit}>Log in</button>

                            <div style={{ textAlign: "center", padding: 20, marginTop: 10, fontSize: "14px" }}>
                                No account ? <span style={{ color: "#00A991", cursor: "pointer" }} onClick={handleShowSignUp}>Create account</span>
                            </div>
                        </Modal.Body>
                    </Modal>
                )}
            </Formik>



            <Formik
                initialValues={{
                    email: "",
                    password: "",
                    confirmPassword: "",
                    firstName: ""
                }}
                validate={values => {
                    const errors = {}
                    if (!values.firstName) {
                        errors.firstName = 'Please input your first name and last name'
                    }
                   
                    if (!values.email) {
                        errors.email = "Please input your email";
                      } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
                      )
                      {
                        errors.email = "Your email is not correct format";
                      }

                    if (values.password.length < 6) {
                        errors.password = 'Please input your password 6 alphabets'
                    }

                    if (!values.password) {
                        errors.password = 'Please input your password (zero waste)'
                    }
                    if (values.confirmPassword.length < 6) {
                        errors.confirmPassword = 'Please input confirm your password 6 alphabets'
                    }

                    if (!values.confirmPassword) {
                        errors.confirmPassword = 'Please input confirm your password (zero waste)'
                    }
                    if (values.password !== values.confirmPassword) {
                        errors.confirmPassword = "Your password does't match"
                    }

                    return errors
                }}
                onSubmit={(values, { resetForm }) => {
                    let _data = {
                        firstName: values?.firstName,
                        email: values?.email,
                        role: "USER",
                        appover: "PENDING",
                        password: values?.password
                    }
                    createAccount(_data)
                    resetForm({ values: '' })
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting
                    /* and other goodies */
                }) => (

                    <Modal show={showSignUp} onHide={handleCloseSignUp}>
                        <div style={{ float: 'right', padding: 20 }}>
                            <FaTimes style={{ float: 'right', fontSize: 20, cursor: "pointer" }} onClick={handleCloseSignUp} />
                        </div>
                        <Form>
                            <Modal.Body className='login-form'>
                                <h3 className='login-title'>Please Sign up</h3>
                                <br />

                                <Form.Group className="mb-3">
                                    <Form.Label>First Name and Last Name</Form.Label>
                                    <Form.Control type="text" placeholder="First Name and Last Name ..." name='firstName' value={values.firstName} onChange={handleChange} style={{ height: "40px", fontSize: "14px" }} />
                                    <div style={{ color: 'red' }}>
                                        <p>{errors.firstName && touched.firstName && errors.firstName}</p>
                                    </div>
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" placeholder="Email" name='email' value={values.email} onChange={handleChange} style={{ height: "40px", fontSize: "14px" }} />
                                    <div style={{ color: 'red' }}>
                                        <p>{errors.email && touched.email && errors.email}</p>
                                    </div>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control type="password" placeholder="******" name='password' value={values.password} onChange={handleChange} style={{ height: "40px", fontSize: "14px" }} />
                                    <div style={{ color: 'red' }}>
                                        <p>{errors.password && touched.password && errors.password}</p>
                                    </div>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Confirm password</Form.Label>
                                    <Form.Control type="password" placeholder="******" name='confirmPassword' value={values.confirmPassword} onChange={handleChange} style={{ height: "40px", fontSize: "14px" }} />
                                    <div style={{ color: 'red' }}>
                                        <p>{errors.confirmPassword && touched.confirmPassword && errors.confirmPassword}</p>
                                    </div>
                                </Form.Group>
                                <br />

                                <button type='submit' className="btn-login" onClick={handleSubmit}>Sign in</button>

                                <div style={{ textAlign: "center", padding: 20, marginTop: 10, fontSize: "14px" }}>
                                    Already have account? <span style={{ color: "#00A991", cursor: "pointer" }} onClick={handleShowLogin}>Log in</span>
                                </div>
                            </Modal.Body>
                        </Form>
                    </Modal>
                )}
            </Formik>



            <Modal show={showDeveloping} onHide={handleClose} className="modal-form">
                <div style={{ float: 'right', padding: 20 }} className="formRegisterClose">
                    <FaTimes style={{ float: 'right', fontSize: 20, cursor: "pointer" }} onClick={handleClose} />
                </div>
                <Modal.Body>

                    <center>
                        <p style={{ fontSize: "16px", fontWeight: "bold" }}>Coming soon</p>
                        <p style={{ fontSize: "14px", textAlign: "justify", textJustify: "inter-word" }}>We will be celebrating the launch of our new site soon!</p>
                        <img src={Developing} style={{ width: "150px", height: "150px" }} />

                    </center>

                </Modal.Body>
            </Modal>




        </div>
    )
}

export default Navbar
