import React, { useState } from 'react'
import useReactRouter from "use-react-router";
import { Formik } from 'formik'


import { Breadcrumb, Row, Modal, Col, Image, Button, ListGroup } from 'react-bootstrap'
import { FaEye, FaEdit, FaTrash, FaPlusCircle } from "react-icons/fa";
import Consts from "../../../consts"

import { Title } from "../../../common"
import { dateTimeLao, moneyCurrency } from "../../../helpers/Helper";


function DonateDetail() {
    const { history, location } = useReactRouter();

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [nextImage, setNextImage] = useState(0)
    const [dataImages, setDataImages] = useState([]);


    const handleShow = (item) => {
        setShow(true)
    };

    //state
    console.log("first===>", location?.state)
    return (
        <div>
            <Breadcrumb style={{ marginTop: 60, fontSize: '12px' }}>
                <Breadcrumb.Item href="#" onClick={() => history.push(Consts.PAGE_DONATE_LIST)}>Staff Managerment</Breadcrumb.Item>
                <Breadcrumb.Item active>Staff Information</Breadcrumb.Item>
            </Breadcrumb>

            <div style={Consts.MAIN_CARD} >
                <div style={{ display: "flex", justifyContent: "space-between", marginTop: 20, marginBottom: 20 }}>
                    <Title text='Managerment Information' />
                </div>
                <hr></hr>


                <Row>
                    <Col md={3} style={{ justifyContent: 'center', display: 'flex' }}>
                        <Image src={location?.state?.images?.length > 0 ? Consts.URL_FOR_SHOW_PHOTO + location?.state?.images[0] : "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Circle-icons-profile.svg/1024px-Circle-icons-profile.svg.png"}
                            style={{ width: 220, height: 220, borderRadius: 10 }}
                            onClick={() => handleShow()}
                        />
                    </Col>
                    <Col md={9}>
                        <ListGroup variant="flush">
                            <ListGroup.Item style={{ fontWeight: "700", padding: 15 }}>Name and surname <span style={{ float: 'right' }}>{location?.state?.userName ?? "-"}</span></ListGroup.Item>
                            <ListGroup.Item style={{ fontWeight: "700", padding: 15 }}>Eamil address <span style={{ float: 'right' }}>{location?.state?.email ?? "-"}</span></ListGroup.Item>
                            <ListGroup.Item style={{ fontWeight: "700", padding: 15 }}>Phone number / Whatsapp <span style={{ float: 'right' }}>{location?.state?.phone ?? "-"}</span></ListGroup.Item>
                            <ListGroup.Item style={{ fontWeight: "700", padding: 15 }}>Amount <span style={{ float: 'right' }}>{moneyCurrency(location?.state?.amount)}</span></ListGroup.Item>
                            <ListGroup.Item style={{ fontWeight: "700", padding: 15 }}>Message <span style={{ float: 'right' }}>{location?.state?.detail ?? "-"}</span></ListGroup.Item>
                        </ListGroup>
                    </Col>
                </Row>
            </div>
                <Modal show={show}
                    centered
                    size="lg"
                    onHide={handleClose}>
                    <Modal.Body className="text-center">
                        <div className='row' style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <div style={{ padding: 20, fontWeight: "bold" }} onClick={() => setNextImage(nextImage <= 0 ? 0 : nextImage - 1)} >Next</div>
                        <Image src={location?.state?.images?.length > 0 ? Consts.URL_FOR_SHOW_PHOTO + location?.state?.images[nextImage] :
                                "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Circle-icons-profile.svg/1024px-Circle-icons-profile.svg.png"}
                                style={{
                                    width: "80%",
                                    height: 450,
                                    objectFit: "cover",
                                }}
                            />
                            <div style={{ padding: 20, fontWeight: "bold" }} onClick={() => setNextImage(nextImage <= location?.state?.images?.length ? location?.state?.images?.length - 1 : nextImage + 1)} >Next</div>
                        </div>

                    </Modal.Body>
                </Modal>
        </div >
    )
}
export default DonateDetail