import React, { useState, useEffect } from 'react'

import { Breadcrumb, Button, Form, Image, Modal, Table } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEye, faTrash } from '@fortawesome/free-solid-svg-icons';
import useReactRouter from "use-react-router";
import moment from 'moment';

import consts, { URL_FOR_SHOW_PHOTO } from '../../../consts'
import { POSTS, POST_CATEGORYS } from '../../../apollo/post/Query';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { dateTimeLao } from '../../../helpers/Helper';
import { DELETE_POST } from '../../../apollo/post/Mutation';

function Post() {
    const { history } = useReactRouter();
    const [deleteData, setDeleteData] = useState();
    const [isOpenMadal, setIsOpenMadal] = useState(false);
    const [postCategories, setPostCategories] = useState([])
    const [posts, setPosts] = useState([])
    const [selectedCategory, setSelectedCategory] = useState('')
    const [startDate, setStartDate] = useState(moment(moment().add(-30, "days")).format("yyyy-MM-DD"))
    const [endDate, setEndDate] = useState(moment().format("yyyy-MM-DD"))

    const [getPostCategories, { data: postCategoriesData }] = useLazyQuery(POST_CATEGORYS, { fetchPolicy: "network-only" })
    const [getPosts, { data: postsData }] = useLazyQuery(POSTS, { fetchPolicy: "network-only" })
    const [deletePost] = useMutation(DELETE_POST)

    useEffect(() => {
        getPostCategories({
            variables: {
                limit: 1000,
                skip: 0,
                where: {
                    isDeleted: false
                }
            }
        })
        gueryPosts()
    }, [])

    useEffect(() => {
        if (postCategoriesData?.categoryPosts) {
            setPostCategories(postCategoriesData?.categoryPosts?.data ?? [])
        }
        if (postsData?.posts) {
            setPosts(postsData?.posts?.data ?? [])
        }
    }, [postCategoriesData, postsData])

    const gueryPosts = () => {
        try {
            getPosts({
                variables: {
                    skip: 0,
                    limit: 50
                }
            })
        } catch (error) {
            console.log("error: ", error)
        }
    }

    const onSelectedCategory = (e) => {
        try {
            setSelectedCategory(e.target.value)
            if (e.target.value && e.target.value != "") {
                getPosts({
                    variables: {
                        where: {
                            categoryPostId: e.target.value,
                            createdAt_gte: startDate,
                            createdAt_lt: endDate
                        },
                        skip: 0,
                        limit: 50
                    }
                })
            } else {
                getPosts({
                    variables: {
                        where: {
                            createdAt_gte: startDate,
                            createdAt_lt: endDate
                        },
                        skip: 0,
                        limit: 50
                    }
                })
            }
        } catch (error) {
            console.log("error: ", error);
        }
    }
    const onSelectedStartDate = (e) => {
        try {
            setStartDate(e.target.value)
            let _where = { createdAt_gte: e.target.value, createdAt_lt: endDate }
            if (selectedCategory) _where = { ..._where, categoryPostId: selectedCategory }
            getPosts({
                variables: {
                    where: _where,
                    skip: 0,
                    limit: 50
                }
            })
        } catch (error) {
            console.log("error: ", error);
        }
    }
    const onSelectedEndDate = (e) => {
        try {
            setEndDate(e.target.value)
            let _where = { createdAt_gte: startDate, createdAt_lt: moment(moment(e.target.value).add(1, "days")).format("yyyy-MM-DD") }
            if (selectedCategory) _where = { ..._where, categoryPostId: selectedCategory }
            getPosts({
                variables: {
                    where: _where,
                    skip: 0,
                    limit: 50
                }
            })
        } catch (error) {
            console.log("error: ", error);
        }
    }

    const onDeleteConfirm = (data) => {
        setDeleteData(data)
        setIsOpenMadal(true)
    }

    const onDeletePost = async () => {
        try {
            if (deleteData) {
                await deletePost({
                    variables: {
                        where: {
                            id: deleteData?.id
                        }
                    }
                })
                gueryPosts()
                setDeleteData()
            }
            setIsOpenMadal(false)
        } catch (error) {
            console.log("error: ", error);
            setIsOpenMadal(false)
        }
    }

    return (
        <div>
            <Breadcrumb style={{ marginTop: 60, paddingTop: 10, paddingLeft: 5 }}>
                <Breadcrumb.Item style={{ color: "#2E72D2" }} active>Post Management</Breadcrumb.Item>
                <div style={{ backgroundColor: "white", minHeight: "95vh", width: "100%", padding: 20, marginTop: 10 }}>
                    <h3 style={{ color: "#3F4952" }}>Blog Management</h3>
                    <div style={{ marginTop: 32 }}></div>
                    <div style={{ display: "flex", width: "100%", flexDirection: "row" }}>
                        <Form.Group className="col-6" controlId="exampleForm.ControlInput1">
                            <Form.Label>From date</Form.Label>
                            <Form.Control size='lg' className='input'
                                name="englishProficiency"
                                onChange={(e) => onSelectedCategory(e)}
                                value={selectedCategory}
                                as="select" style={{ fontSize: "16px" }}>
                                <option value='' selected>Select the blog category</option>
                                {postCategories?.map((category, index) =>
                                    <option key={index} value={category?.id}>{category?.name ?? "-"}</option>
                                )}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group className="col-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>From date</Form.Label>
                            <Form.Control size='lg'
                                className='input'
                                type="date"
                                name="birthday"
                                onChange={(e) => onSelectedStartDate(e)}
                                value={startDate}
                            />
                        </Form.Group>
                        <Form.Group className="col-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>To date</Form.Label>
                            <Form.Control size='lg'
                                className='input'
                                type="date"
                                name="birthday"
                                onChange={(e) => onSelectedEndDate(e)}
                                value={endDate}
                            />
                        </Form.Group>
                    </div>
                    <div style={{ marginTop: 20 }}>
                        <Button onClick={() => history.push(consts.PAGE_ADD_POST)} style={{ backgroundColor: '#00A991', color: "white", float: "right", marginBottom: 10 }} size="lg">Add blog</Button>
                    </div>
                    <div style={{ marginTop: 20 }}>
                        <p style={{ fontSize: 14, color: "grey" }}>Show 1-50 From {postsData?.posts?.total ?? 0} Blogs</p>
                        <Table striped>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Picture</th>
                                    <th>Blog category</th>
                                    <th>Topic</th>
                                    <th>Content</th>
                                    <th>Posted date</th>
                                    <th>Posted by</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {posts?.map((post, index) => <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>
                                        <Image src={`${URL_FOR_SHOW_PHOTO}${post?.imageProfile}`} style={{ width: 30, height: 30 }} />
                                    </td>
                                    <td>{post?.categoryPostName ?? "-"}</td>
                                    <td>{post?.topic ?? "-"}</td>
                                    <td><div style={{ display: "inline-block" }} dangerouslySetInnerHTML={{ __html: post?.contents?.length > 50 ? `${post?.contents?.substring(0, 50)}...` : (post?.contents ?? "-") }}></div></td>
                                    <td>{dateTimeLao(post?.createdAt)}</td>
                                    <td>{post?.createdBy?.firstName ?? "-"}</td>
                                    <td>
                                        <FontAwesomeIcon onClick={() => history.push(consts.PAGE_POST_DETAIL + "/" + post?.id, post)} icon={faEye} style={{ color: "#00A991", cursor: 'pointer' }} />
                                        &nbsp;&nbsp;&nbsp;
                                        <FontAwesomeIcon onClick={() => history.push(consts.PAGE_EDIT_POST + "/" + post?.id, post)} icon={faEdit} style={{ color: "#00A991", cursor: 'pointer' }} />
                                        &nbsp;&nbsp;&nbsp;
                                        <FontAwesomeIcon icon={faTrash} onClick={() => onDeleteConfirm(post)} style={{ color: "#D21C1C", cursor: 'pointer' }} />
                                    </td>
                                </tr>)}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </Breadcrumb>

            <Modal show={isOpenMadal} onHide={() => setIsOpenMadal(false)}>
                <Modal.Body>
                    <p style={{ fontSize: "18px", fontWeight: "bold" }}>Are you sure you want to delete?</p>
                    <p style={{ fontSize: "16px", color: "#4F5E71" }}>Please ensure and then confirm!</p>
                    <div style={{ float: "right", marginTop: 10 }}>
                        <Button variant="secondary" onClick={() => setIsOpenMadal(false)} style={{ width: 90 }}>
                            Cancel
                        </Button>
                        &nbsp;&nbsp;&nbsp;
                        <Button variant="danger" onClick={() => onDeletePost()} style={{ width: 90 }}>
                            Confirm
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>

        </div>
    )
}
export default Post