import { gql } from "apollo-boost";


export const POST_CATEGORYS = gql` 
query CategoryPosts($where: CategoryPostWhereInput) {
    categoryPosts(where: $where) {
      data {
        id
        name
        isDeleted
        detail
        createdAt
        updatedAt
        note
      }
    }
  }
`

export const POST_CATEGORY = gql` 
query CategoryPost($where: CategoryPostWhereInputId!) {
  categoryPost(where: $where) {
    name
    detail
    id
  }
}
`

export const POSTS = gql` 
query Posts($where: PostWhereInput, $skip: Int, $limit: Int) {
  posts(where: $where, skip: $skip, limit: $limit) {
    total
    data {
      id
      categoryPostName
      topic
      imageProfile
      images
      contents
      createdAt
      createdBy {
        firstName
      }
      updatedAt
      updatedBy {
        firstName
      }
      categoryPostId {
        id
      }
      note
    }
  }
}
`

export const POST = gql` 
query Post($where: PostWhereInputId!) {
  post(where: $where) {
    id
    categoryPostName
    topic
    imageProfile
    images
    contents
    createdAt
    createdBy {
      firstName
    }
    updatedAt
    updatedBy {
      firstName
    }
    categoryPostId {
      id
    }
    note
  }
}
`





