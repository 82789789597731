import React, { useState, useEffect}from 'react'
import useReactRouter from "use-react-router";

import { Breadcrumb, Form, Table, Image } from 'react-bootstrap'
import { FaEye, FaEdit, FaTrash } from "react-icons/fa";
import Consts from "../../../consts"
import {Title, CustomButton} from "../../../common"
import { useLazyQuery } from '@apollo/react-hooks'
import { USERS_DATA } from "../../../apollo/user"
import { dateTimeLao } from "../../../helpers/Helper"

function StaffList() {
    const { history } = useReactRouter();
    const [fillterName, setFillterName] = useState('')
    const [getUsers, { data }] = useLazyQuery(USERS_DATA, {
        fetchPolicy: "network-only",
        variables: {
            where: {
                role: "ADMIN",
                firstName: fillterName === '' ? undefined : fillterName
            }
        }
    });
    //function
// ======>
    
    useEffect(() => {
        getUsers()
    }, [])

    return (
        <div>
            <Breadcrumb style={{ marginTop: 60, fontSize: '12px' }}>
                <Breadcrumb.Item active>Staff Managerment</Breadcrumb.Item>
            </Breadcrumb>

            <div style={Consts.MAIN_CARD} >
                <Title text='Staff Managerment' />

                <Form.Group className="mt-5">
                    <Form.Control size='lg' type="text" style={{ height: '40px', fontSize: '14px',marginTop: 30}} placeholder="Searching by name..." onChange={(e) => setFillterName(e?.target?.value)}/>
                </Form.Group>

                <div style={{ display: "flex", justifyContent: "space-between", marginTop: 20, marginBottom: 20 }}>
                    <h4>Show {data?.users?.data?.length} List</h4>
                    <div>
                      
                        <CustomButton addIcon title='Add User' onClick={() => history.push(Consts.PAGE_STAFF_ADD)}/>

                    </div>
                </div>
                <Table striped>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Name and surname</th>
                            <th>Username</th>
                            <th>Role</th>
                            <th>Phone number</th>
                            <th>Created At</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.users?.data?.map((item,index)=>
                            <tr key={index} onClick={() => history.push(Consts.PAGE_STAFF_DETAIL, item) }>
                                <td>{index+1}</td>
                            <td>
                                    <Image src={item?.image ? Consts.URL_FOR_SHOW_PHOTO + item?.image :"https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Circle-icons-profile.svg/1024px-Circle-icons-profile.svg.png"}
                                    style={{ width: 40, height: 40 }}
                                />
                                &nbsp;&nbsp;&nbsp;

                                    {item?.firstName}
                            </td>
                                <td>{item?.userId}</td>
                                <td>{item?.role}</td>
                                <td>{item?.phone}</td>
                                <td>{dateTimeLao(item?.createdAt)}</td>
                            {/* <td>
                                <FaEye style={{ color: '#00A991',cursor:'pointer', fontSize: '14px' }} onClick={()=> viewDetail()} />
                                &nbsp;&nbsp;&nbsp;
                                <FaEdit style={{ color: '#00A991',cursor:'pointer', fontSize: '14px' }} />
                                &nbsp;&nbsp;&nbsp;
                                <FaTrash style={{ color: 'red',cursor:'pointer', fontSize: '14px' }} />
                            </td> */}
                        </tr>
                        
                        )}
                    </tbody>
                </Table>


            </div>



        </div>
    )
}
export default StaffList