import React from 'react'
import useReactRouter from "use-react-router";
import { Nav } from 'react-bootstrap'
import Consts from "../../../consts"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faUserCheck,
    faUserClock,
    faUserEdit,
    faUserLargeSlash,
} from "@fortawesome/free-solid-svg-icons";


export default function NavClimate() {
    const { history, location } = useReactRouter();

    const _historyPush = (rout) => {
        history.push(rout)
    }
    return (
        <div style={{ paddingLeft: 15 }}>
            <Nav variant="tabs" defaultActiveKey={location?.pathname}>
                <Nav.Item>
                    <Nav.Link
                        onClick={() => _historyPush(Consts.PAGE_CLIMATE_LIST)}
                        style={{ padding:10,display: "flex", color: location?.pathname === Consts.PAGE_CLIMATE_LIST ? "#00A991":"#000000" }}
                        eventKey={Consts.PAGE_CLIMATE_LIST}>
                        <FontAwesomeIcon
                            icon={faUserClock} />
                        <div style={{ marginLeft: 10 }}>
                            Waiting verification
                        </div>
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link
                        onClick={() => _historyPush(Consts.PAGE_APPORVERD_LIST)}
                        style={{ padding: 10, display: "flex", color: location?.pathname === Consts.PAGE_APPORVERD_LIST ? "#00A991" : "#000000" }}
                        eventKey={Consts.PAGE_APPORVERD_LIST}>
                        <FontAwesomeIcon
                            icon={faUserCheck} />
                        <div style={{ marginLeft: 10 }}>
                            Approved
                        </div>
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link
                        onClick={() => _historyPush(Consts.PAGE_REJECT_LIST)}
                        style={{ padding: 10, display: "flex", color: location?.pathname === Consts.PAGE_REJECT_LIST ? "#00A991" : "#000000" }}
                        eventKey={Consts.PAGE_REJECT_LIST}>
                        <FontAwesomeIcon
                            icon={faUserLargeSlash} />
                        <div style={{ marginLeft: 10 }}>
                            Reject history
                        </div>
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link
                        onClick={() => _historyPush(Consts.PAGE_PEDDING_LIST)}
                        style={{ padding: 10, display: "flex", color: location?.pathname === Consts.PAGE_PEDDING_LIST ? "#00A991" : "#000000" }}
                        eventKey={Consts.PAGE_PEDDING_LIST}>
                        <FontAwesomeIcon
                            icon={faUserEdit} />
                        <div style={{ marginLeft: 10 }}>
                            Pending
                        </div>
                    </Nav.Link>
                </Nav.Item>
            </Nav>
        </div>
    )
}