import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import useReactRouter from "use-react-router";

import { useLazyQuery,useMutation } from '@apollo/react-hooks'


import { FaAngleRight } from "react-icons/fa";
import { Carousel, Container, Row, Col, Button, Card, Modal } from 'react-bootstrap'
import { FaTimes } from "react-icons/fa";
import Developing from "../../../image/developing.gif"
import LogoZero from "../../../image/logo/LogoZero.png"

import Consts from "../../../consts"

// import {COUNT_VISITER} from "../../../apollo/visiter/Mutation"
import { POSTS } from "../../../apollo/post/Query"
import { CREATE_COUNT } from "../../../apollo/visiter/Mutation"
import { dateTimeLao } from '../../../helpers/Helper';

import Banner1 from "../../../image/banner/Silde1.jpg"
import Banner2 from "../../../image/banner/Silde2.jpg"
import Banner3 from "../../../image/banner/Silde3.jpg"

import bgHomePrograme from "../../../image/programe/bgPrograme.jpg"

import planUser from "../../../image/planUser.png"
import research from "../../../image/research.png"
import usersGroup from "../../../image/users.png"
import world from "../../../image/world.png"

import p1 from "../../../image/partner/p1.png"
import p2 from "../../../image/partner/p2.png"
import p3 from "../../../image/partner/p3.png"
import p4 from "../../../image/partner/p4.png"
import p5 from "../../../image/partner/p5.png"
import p6 from "../../../image/partner/p6.png"
import p7 from "../../../image/partner/p7.png"
import p8 from "../../../image/partner/p8.png"
import p9 from "../../../image/partner/p9.png"


import Blog1 from "../../../image/blog/blog1.png"
import Blog2 from "../../../image/blog/blog2.png"
import Blog3 from "../../../image/blog/blog3.png"
import Blog4 from "../../../image/blog/blog4.png"
import Blog5 from "../../../image/blog/blog5.png"
import Blog6 from "../../../image/blog/blog6.png"

import Footer from "../../../components/footer"

import './Index.css'

function Home() {
    const { history } = useReactRouter();
    // const [countVisiter] = useMutation(COUNT_VISITER)
    const [countVisiter] = useMutation(CREATE_COUNT)
    const [showDeveloping, setShowDeveloping] = useState(false);

    const handleClose = () => setShowDeveloping(false);
    const handleShow = () => setShowDeveloping(true);

    
    const [loadPost, { data: postData }] = useLazyQuery(POSTS)

    // useEffect
    useEffect(() => {
        loadPost()
        loadCountVisiter()
    }, [])

    // count visiter
    const loadCountVisiter = async () => { 
        await countVisiter()
    }

    const programeImage = [
        {
            url: planUser,
            programe: 'Awareness Campaign',
            link: Consts.PAGE_PROGRAME1,
            detail: "To raise people's awareness on waste issues, climate action, gender equality and SDG. We held workshops and seminars for youths to participate and take part in decision-making activities. There are more than 15 campaigns and workshops since 2019"

        },
        {
            url: research,
            programe: 'Research',
            link: Consts.PAGE_PROGRAME2,
            detail: 'Our project results will be converted to data, as we conduct research and also collaborate with research institutes to improve our understanding about various issues in the environment and human development process.'
        },
        {
            url: world,
            programe: 'Capacity Building',
            link: Consts.PAGE_PROGRAME3,

            detail: 'We provide opportunities for youth to improve their soft skills as well as social skills that are necessary for their future care such as leadership, teamwork, communication, system thinking, sustainability mindset, etc'
        },
        {
            url: usersGroup,
            programe: 'CSR Collaboration ',
            link: Consts.PAGE_PROGRAME4,

            detail: 'We collaborate with other organizations and companies to work on various projects including waste management, climate action, youth empowerment, and more.            '
        },

    ];

    

    const partnerImage1 = [
        {
            url: p1,
        },
        {
            url: p2,
        },
        {
            url: p3,
        },
        {
            url: p4,
        },
    ];
    const partnerImage2 = [
        {
            url: p5,
        },
        {
            url: p6,
        },
        {
            url: p7,
        },
        {
            url: p8,
        },
        {
            url: p9,
        },

    ];

    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    return (
        <div style={{ zIndex: 0 }}>

            <div style={{ height: 150 }} className='hideHeight'></div>







            <Carousel className="banner">
                <Carousel.Item interval={4000} className='bannerImg'>
                    <img
                        className="w-100 img-slide"
                        src={Banner1}
                        alt="First slide"
                    />
                    <Carousel.Caption className='img-caption'>
                        {/* <h3 className="caption-title" style={{ color: "#ffffff" }}>Advocate <br /> for Our <br /> Cause</h3> */}
                        <div className="btn-mobile-banner">
                            <button className="btn-register-home btn-register-home2" onClick={() => history.push(Consts.PAGE_VOLUNTEER)}>Volunteer</button>
                        </div>
                    </Carousel.Caption>
                </Carousel.Item>

                <Carousel.Item interval={4000} className='bannerImg'>
                    <img
                        className="w-100 img-slide"
                        src={Banner2}
                        alt="First slide"
                    />

                    <Carousel.Caption className='img-caption'>
                        {/* <h3 className="caption-title" style={{ color: "#000000" }}>We are nonprofit <br /> from indonesia focus on  <br /> merine debris issue</h3> */}
                        <div className="btn-mobile-banner">
                            <button className="btn-register-home btn-register-home2" onClick={() => history.push(Consts.PAGE_ABOUT)}>About us</button>
                        </div>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item interval={4000} className='bannerImg'>
                    <img
                        className="w-100 img-slide"
                        src={Banner3}
                        alt="First slide"
                    />

                    <Carousel.Caption className='img-caption'>
                        {/* <h3 className="caption-title" style={{ color: "#000000" }}>We are nonprofit <br /> from indonesia focus on  <br /> merine debris issue</h3> */}
                        <div className="btn-mobile-banner">
                            <button className="btn-register-home btn-register-home2" onClick={() => history.push(Consts.PAGE_ABOUT)}>About us</button>
                        </div>
                    </Carousel.Caption>
                </Carousel.Item>

            </Carousel>

            {/* 
        *
        *OUR Program
        */}



            <h2 className='titleBig' id='ourPrograme'><span className="title-Active">OUR</span> Program</h2>
            <div className='title'>
                <div>
                    <p className='middleTitle'>
                        Be part of the solution and join the movement #ZeroWasteLaos
                    </p>
                </div>
            </div>

            <Container fluid className="section-programe-desktop">
                <img className='bgProgrameImg' src={bgHomePrograme} />
                <Row className='mainCard m-0'>
                    {programeImage.map((programeImage, index) =>
                        <Col xs={12} sm={6} md={6} lg={3} key={"pro" + index} className='p-0'>
                            <Card className='programe-card' >
                                <Card.Img variant="top" src={programeImage?.url} className="imgProgrameIcon" />
                                <Card.Body>
                                    <Card.Title className='titlePrograme' style={{ fontWeight: "bold" }}>{programeImage.programe}</Card.Title>
                                    <Card.Text style={{ textAlign: "justify", textJustify: "inter-word" }}>
                                        {programeImage?.detail ?? ""}
                                    </Card.Text>
                                    <Button className="btnProgrmaeDetail" onClick={() => history.push(programeImage?.link)}>Detail</Button>
                                </Card.Body>
                            </Card>
                        </Col>
                    )}
                </Row>


                <Row className='count-text'>
                    <Col xs={12}>
                        <div className='title'>
                            <h2>Some Count that matters</h2>
                            <p style={{ color: "#ffffff" }}>Our achievement in the journey depicted in numbers</p>
                        </div>
                    </Col>

                    <Col xs={12} sm={3} md={3} lg={3} 	xl={3}	xxl={3} style={{ borderRight: "1px solid #ffffff" }}>
                        <h3>5200</h3>
                        <p style={{ fontSize: "14px", fontWeight: "400" }}>Trees planted in 260 schools</p>
                    </Col>
                    <Col xs={12} sm={3} md={3} lg={3} xl={3} xxl={3} style={{ borderRight: "1px solid #ffffff" }}>
                        <h3>10</h3>
                        <p style={{ fontSize: "14px", fontWeight: "400" }}>Schools involved in Waste Management Project </p>
                    </Col>
                    <Col xs={12} sm={3} md={3} lg={3} xl={3}	xxl={3} style={{ borderRight: "1px solid #ffffff" }}>
                        <h3>+2000</h3>
                        <p style={{ fontSize: "14px", fontWeight: "400" }}>Youths involved</p>
                    </Col>
                    <Col xs={12} sm={3} md={3} lg={3} xl={3}	xxl={3}>
                        <h3>300</h3>
                        <p style={{ fontSize: "14px", fontWeight: "400" }}>Of young volunteer</p>
                    </Col>
                </Row>

            </Container>



            <Container fluid className="section-programe-mobile">
                <Row className='mainCard'>
                    {programeImage.map((programeImage, index) => (
                        <Col xs='12' sm='6' md='6' lg='6' >
                            <div className='programe-card'>
                                <img src={programeImage?.url} className="imgProgrameIcon" />
                                <h5 className='titlePrograme'>{programeImage.programe}</h5>
                                <p style={{ textAlign: "justify", textJustify: "inter-word" }}>{programeImage?.detail ?? ""}</p>
                                <center>
                                    <Button className="btnProgrmaeDetail" onClick={() => history.push(programeImage?.link)}>Detail</Button>

                                    {/* <a href="#" onClick={() => history.push(programeImage?.link)}>Detail</a> */}
                                </center>
                            </div>
                        </Col>
                    ))}
                </Row>
                <Row className='bgPrograme'>
                    <Col xs='12'  >
                        <div className='title'>
                            <h2>Some Count that matters</h2>
                            <p style={{ color: "#ffffff" }}>Our achievement in the journey depicted in numbers</p>
                        </div>
                    </Col>
                    <Col xs={12} sm={6} md={6}>
                        <div className='cardTotal'>
                            <h3>5200</h3>
                            <p>Trees planted in 260 schools</p>
                        </div>
                    </Col>
                    <Col xs={12} sm={6} md={6}>
                        <div className='cardTotal'>

                            <h3>10</h3>
                            <p>Schools involved in Waste Management Project  </p>
                        </div>

                    </Col>
                    <Col xs={12} sm={6} md={6}>
                        <div className='cardTotal'>

                            <h3>+2000</h3>
                            <p>Youths involved</p>
                        </div>

                    </Col>
                    <Col xs={12} sm={6} md={6}>
                        <div className='cardTotal'>

                            <h3>150</h3>
                            <p>Of young volunteer </p>
                        </div>

                    </Col>
                </Row>
            </Container>



            {/* 
        *
        *OUR Program
        */}



            <div id='ourPartner'>
                <h2 className='titleBig' ><span className="title-Active">OUR</span> Partners</h2>
            </div>

            <Container>
                <Row className='partner-card p-5'>

                    {partnerImage1.map((partImg, index) => (
                        <Col className='' xs={12} sm={3} md={3}>
                            <center>
                                <img src={partImg.url} className="partner-img" />
                            </center>
                        </Col>
                    ))}

                    {partnerImage2.map((partImg, index) => (
                        <Col className='' xs={12} sm={3} md={3} lg='3'>
                            <center>
                                <img src={partImg.url} className="partner-img" />
                            </center>
                        </Col>
                    ))}

                </Row>
            </Container>


            <div className='titleBig' id='ourLastestNews'>
                <h2 className='titleBig' ><span className="title-Active">OUR</span> LASTEST NEWS</h2>
            </div>

            <Container fluid>
                <Row className='' style={{ marginTop: 50 }}>

                    {postData?.posts?.data.map((post, index) => (

                        <Col xs='12' sm='6' md='6' lg='4' key={index} onClick={() => history.push(Consts.PAGE_BLOG_DETAIL + "/" + post?.id)} style={{ cursor: 'pointer' }}>
                            <div className='news-card'>
                                <div className="newsFlex">
                                    <div className='newsLetf'>ZWL news updates</div>
                                    <div className='newsRight'>
                                        <div style={{ float: 'right' }}>
                                            <img src={LogoZero} style={{ borderRadius: 5 }} />
                                        </div>
                                    </div>
                                </div>
                                <img
                                    className="news-img"
                                    src={Consts.URL_FOR_SHOW_PHOTO + post.imageProfile}
                                    alt="First slide"
                                />
                                <h5 style={{ fontSize: "16px", fontWeight: "800", marginTop: 10 }}>News description: {post?.topic}</h5>
                                <div style={{ display: "inline-block" }} dangerouslySetInnerHTML={{ __html: post?.contents?.length > 50 ? `${post?.contents?.substring(0, 50)}...` : (post?.contents ?? "-") }}></div>
                                <p style={{ fontSize: "14px", color: "#00A991" }}>{dateTimeLao(post?.createdAt)}</p>
                                <center>
                                <a onClick={() => history.push(Consts.PAGE_BLOG_DETAIL+ "/" + post?.id)} style={{ fontSize: "14px", color: "#00A991",textAlign: "center",fontWeight: "bold" }}> Read more </a>
                                </center>
                            </div>
                        </Col>


                    ))}
                    <Col md={12} className="mt-5 mb-5" >
                        <span style={{ float: 'right' }}>
                            <a href="#" onClick={() => history.push(Consts.PAGE_BLOG)} className="button-news-next">More <FaAngleRight /></a>
                        </span>
                    </Col>
                </Row>
            </Container>

            <Footer />

        </div >
    )
}
export default Home