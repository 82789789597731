
import { useMutation } from '@apollo/react-hooks'
import useReactRouter from "use-react-router";
// import { CREATE_STAFF, DELETE_STAFF, UPDATE_STAFF } from '../apollo/user'
import {CREATE_PLANT_DONATE} from "../apollo/plantDonate/Mutation"
import { successAdd, errorAdd } from '../helpers/sweetalert'
import Routers from "../consts";

export default function CreateUserMember() {
    const { history } = useReactRouter();

    // ====== updateCategory ======>
    // const [updateUserMember] = useMutation(UPDATE_STAFF)
    // const _updateUserMember = async (item, DataAction) => {
    //     try {
    //         let _updateData = await updateUserMember({
    //             variables: {
    //                 data: item,
    //                 where: {
    //                     id: DataAction
    //                 }
    //             }
    //         })
    //         if (_updateData?.data?.updateUser) {
    //             await successAdd("UPDATE SUCCESS")
    //             history.push(Routers.PAGE_CLIMATE_ACTION_DETAIL + '/' + DataAction)
    //             window.location.reload();
    //         }
    //     } catch (error) {
    //         errorAdd(error)
    //     }
    // }
    // ====== createCategory ======>
    const [createPlantDonate] = useMutation(CREATE_PLANT_DONATE)
    const _createPlantDonate = async (userData, donateData , handleShowAlertSuccess) => {
        try {
            let _dataDonate = {
                ...userData,
                userId:donateData?.id
            }
            let _createPlantDonate = await createPlantDonate({variables: { data: _dataDonate}})
            
            if(_createPlantDonate?.data?.createPlantDonation){
                //  await successAdd("Donate Success")
                 await handleShowAlertSuccess()
                //  history.replace(Routers.USER_PROFILE)
            }


        } catch (error) {
            errorAdd(error)
        }
    }
    // ====== deleteCategory ======>
    // const [deleteUserMember] = useMutation(DELETE_STAFF)
    // const _deleteUserMember = async (items, handleCloseDelete, rout) => {
    //     try {
    //         let _deleteData = await deleteUserMember({
    //             variables: {
    //                 where: { id: items }
    //             }
    //         })
    //         if (_deleteData?.data?.deleteUser) {
    //             await successAdd("ລົບຂໍ້ມູນສຳເລັດ")
    //             await handleCloseDelete()
    //             history.replace(rout)
    //         }
    //     } catch (error) {
    //         errorAdd(error)
    //     }
    // }
    return {
        //  _updateUserMember,
        //  _deleteUserMember,
        _createPlantDonate
    }
}