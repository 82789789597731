import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Link } from 'react-router-dom';
import useReactRouter from "use-react-router";
import Avatar from 'react-avatar';
import { Formik } from 'formik'
import * as _ from "lodash";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import ReactToPrint from 'react-to-print';
import jsPDF from 'jspdf';
import { toPng, toJpeg } from 'html-to-image'
import Pdf from 'react-to-pdf';
import format from 'date-fns/format';
import html2canvas from "html2canvas";
// import Consts from "../../../consts"
import { PLANTING_DONATE } from "../../../apollo/plantDonate/Query"
import { CERTIFICATES } from "../../../apollo/certificate/Query"

// import { ADDRESSES } from "../../../consts/newAddresses";
import { PROVINCE, DRISTRIC } from '../../../consts/addresses'

import Consts, { USER_KEY } from "../../../consts"
import { CertificateComponentForm } from "./CertificateComponent"


import { USER } from "../../../apollo/register/Query"
import { USER_UPDATE } from "../../../apollo/register/Mutation"

import { dateTimeLao } from "../../../helpers/Helper"
import { successAdd, errorAdd } from '../../../helpers/sweetalert'

import eCer from "../../../image/user/eCer.png"

import { FaAngleRight } from "react-icons/fa";
import { Carousel, Container, Row, Col, Button, ListGroup, Form } from 'react-bootstrap'
import { FaTree } from "react-icons/fa";

import Footer from "../../../components/footer"
import NewsCover from "../../../image/blog/NewsCover.png"

import './Index.css'
import './TimeLine.css'
function UserProfile() {
    const { history } = useReactRouter();
    const user = localStorage?.getItem(USER_KEY)
    const _userRole = JSON?.parse(user)
    const _userInfo = _userRole?.data

    const componentPrint = useRef();


    const [totalOf, setTotalOf] = useState(0)
    const [certificate, SetCertificate] = useState(0)
    const [dataToSave, setDataToSave] = useState()


    //apollo
    const [loadCertificate, { data: certificateData }] = useLazyQuery(CERTIFICATES)
    const [loadPlantDonate, { data: plantDonate }] = useLazyQuery(PLANTING_DONATE, { variables: { where: { userId: _userInfo?.id } } })
    const [userData, { loading, data: userDataInfo }] = useLazyQuery(USER)


    // useEffect
    useEffect(() => {
        loadPlantDonate()
        loadCertificate()
        userData({
            variables: {
                where: { id: _userInfo?.id }
            }
        })
    }, [])

    useEffect(() => {
        if (plantDonate?.plantDonations?.data) {
            let _tree = 0
            for (let i = 0; i < plantDonate?.plantDonations?.data?.length; i++) {
                _tree += plantDonate?.plantDonations?.data[i]?.numberDonated
            }
            setTotalOf(_tree)
        }

        if (certificateData?.certificates?.data) {
            SetCertificate(certificateData?.certificates?.data[0])
        }

    }, [plantDonate?.plantDonations?.data, certificateData])


    useEffect(() => {

        if (certificate || totalOf || userDataInfo) {
            setDataToSave({
                total: totalOf,
                carImage: certificate,
                userInfo: userDataInfo
            })
        }

    }, [certificate, totalOf, userDataInfo])






    const ref = React.createRef();


    return (
        <div style={{ zIndex: 0 }}>
            <div style={{ height: 150 }} className='hideHeight'></div>
            {/* <Container fluid className='imgTitle'>
                <h2 className='blogTitle'> {_userInfo?.firstName} </h2>
            </Container> */}

            <Container fluid className="m-0 p-0 bgImageNews">
                <Row className="m-0 p-0">
                    <Col md='12' xs={12} className="m-0 p-0 bannerNews">
                        <img className='imgNews' src={NewsCover} />
                        <div className="text-left-new">
                            {/* <center> */}
                            {/* <p>{_userInfo?.firstName}</p> */}
                            <Avatar name={_userInfo?.firstName} color="#00A991" />
                            {/* </center> */}
                        </div>
                    </Col>
                </Row>

            </Container>
            {/* <Container> */}
            <div className="containerStep">
                <ul className="progressbar">
                    <li className={userDataInfo?.user?.appover === "APPROVED" ? "activeDone" : "active"}>
                        <span className="activeHidden">Create account</span>
                    </li>
                    <li className={userDataInfo?.user?.appover === "APPROVED" ? "activeDone" : "active"}>
                        <span className="activeHidden">Tell us about yourself</span>

                    </li>
                    <li className={userDataInfo?.user?.appover === "APPROVED" ? "activeDone" : "active"}>
                        <span className="activeHidden">Wait for confirmation</span>

                    </li>
                    <li className={userDataInfo?.user?.appover === "APPROVED" ? "activeDone" : "active"}>
                        <span className="activeHidden">get a certificate</span>
                    </li>



                </ul>
            </div>
            {/* </Container> */}

            <Container>

                <Row>
                    <Col xs={12} sm={4} md={4}>
                        <div className="cardProfile">
                            <p className="profileTitle">Locations of trees planted</p>
                            <h1 className="profileSubTitle">{plantDonate?.plantDonations?.total ?? "0"}</h1>
                        </div>
                    </Col>
                    <Col xs={12} sm={4} md={4}>
                        <div className="cardProfile">
                            <p className="profileTitle">Trees planted</p>
                            <h1 className="profileSubTitle">{totalOf}</h1>
                        </div>
                    </Col>
                    <Col xs={12} sm={4} md={4}>
                        <div className="cardProfile">
                            <p className="profileTitle">Dissolve carbon dioxide</p>
                            <h1 className="profileSubTitle">{totalOf * 15} kg</h1>
                        </div>
                    </Col>
                </Row>
            </Container>


            <Container>
                <Row>
                    <Col xs={12} >
                        <h4 className="certificateTitle">Congratulations on your certificate</h4>
                    </Col>
                    <Col xs={12} >
                        {/* <img src={Consts.URL_FOR_SHOW_PHOTO + certificate?.imageProfile} style={{ width: "100%", height: 600 }} /> */}
                        <img src={Consts.URL_FOR_SHOW_PHOTO + certificate?.imageProfile} style={{ width: "100%" }} className="cerImage" />
                        <div hidden={true}>
                            <CertificateComponentForm ref={componentPrint} data={dataToSave} />
                        </div>
                    </Col>
                    <Col xs={12} >

                        <ReactToPrint
                            trigger={() => (

                                <button 
                                disabled={plantDonate?.plantDonations?.data.length > 0 ? false : true}
                                style={{ backgroundColor: "#00A991", padding: 10, color: "#ffffff", width: "300px", margin: 20, border: 0 }}>
                                    Download
                                </button>

                            )}
                            content={() => componentPrint.current}

                        />
                        <p hidden={plantDonate?.plantDonations?.data.length > 0 ? true : false} style={{color: "gray"}}>
                            (Please donate tree for take certificate)
                        </p>
                                

                        {/* 
                        <div ref={ref}>
                            <h1>dddddd</h1>
                            <img src={Consts.URL_FOR_SHOW_PHOTO + certificate?.imageProfile} />
                        </div>
                        <Pdf targetRef={ref} filename="post.pdf">
                            {({ toPdf }) => <button onClick={toPdf}>Capture as PDF</button>}
                        </Pdf> */}




                       





                    </Col>

                </Row>
            </Container>





            <VerticalTimeline>
                {plantDonate?.plantDonations?.data.map((item, index) =>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: '#00A991', color: '#fff' }}
                        contentArrowStyle={{ borderRight: '7px solid  #00A991' }}
                        // date={dateTimeLao(item?.createdAt)}
                        // date="2020-2022"
                        iconStyle={{ background: '#00A991', color: '#fff' }}
                        icon={<FaTree />}
                        key={index}
                    >
                        <h3 className="vertical-timeline-element-title">Type Donate:{item?.typeOfDonated}</h3>
                        <h4 className="vertical-timeline-element-subtitle">Number: {item?.numberDonated} ຕົ້ນ</h4>
                        <h4 className="vertical-timeline-element-subtitle">Province: {item?.province}</h4>
                        <h4 className="vertical-timeline-element-subtitle">District: {item?.district}</h4>
                        <h4 className="vertical-timeline-element-subtitle">Village: {item?.village}</h4>
                        <h4 className="vertical-timeline-element-subtitle">date: {dateTimeLao(item?.createdAt)}</h4>


                        {item?.images.length > 0 ? (
                            <div>
                                <h4 className="vertical-timeline-element-subtitle mb-3">image:</h4>
                                {item?.images.map((image, index) =>
                                    // console.log("image--->",image)
                                    <img style={{ width: "100%", height: "auto" }} src={Consts.URL_FOR_SHOW_PHOTO + image} />

                                )}
                            </div>
                        ) : ""}
                        {/* <p>
                            Creative Direction, User Experience, Visual Design, Project Management, Team Leading
                        </p> */}
                    </VerticalTimelineElement>


                )}





                <VerticalTimelineElement
                    iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
                    icon={<FaTree />}
                />
            </VerticalTimeline>







            <Footer />

        </div>
    )
}
export default UserProfile

