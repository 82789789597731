import React, { useState } from 'react'
import useReactRouter from "use-react-router";
import { Formik } from 'formik'


import { Breadcrumb, Row, Modal, Col, Image, Button, ListGroup } from 'react-bootstrap'
import { FaEye, FaEdit, FaTrash, FaPlusCircle } from "react-icons/fa";
import Consts from "../../../consts"

import { Title, CustomButton } from "../../../common"
import { dateTimeLao } from "../../../helpers/Helper";
import CUSD_STAFF from '../../../crud/user';


function StaffDetail() {
    const { history, location } = useReactRouter();

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const {
        _deleteStaff
    } = CUSD_STAFF();
    //state
    return (
        <div>
            <Breadcrumb style={{ marginTop: 60, fontSize: '12px',fontWeight:"bold" }}>
                <Breadcrumb.Item href="#" onClick={() => history.push(Consts.PAGE_STAFF)}>Staff Managerment</Breadcrumb.Item>
                <Breadcrumb.Item active>Staff Information</Breadcrumb.Item>
            </Breadcrumb>

            <div style={Consts.MAIN_CARD} >
                <div style={{ display: "flex", justifyContent: "space-between", marginTop: 20, marginBottom: 20 }}>
                    <Title text='Managerment Information' />

                    <div>
                        <CustomButton editIcon title='Edit '
                            style={{ border: 0 }}
                            onClick={() => history.push(Consts.PAGE_STAFF_EDIT, location?.state)}
                        />
                        {' '}
                        <CustomButton addDelete title='Delete '
                            style={{ border: 0 }}
                            onClick={handleShow}
                        />

                    </div>
                </div>
                <hr></hr>


                <Row>
                    <Col md={3} style={{ justifyContent: 'center', display: 'flex' }}>
                        <Image src={location?.state?.image ? Consts.URL_FOR_SHOW_PHOTO + location?.state?.image : "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Circle-icons-profile.svg/1024px-Circle-icons-profile.svg.png"}
                            style={{ width: 220, height: 220, borderRadius: 10 }}
                        />
                    </Col>
                    <Col md={9}>
                        <ListGroup variant="flush">
                            <ListGroup.Item style={{ fontWeight: "700", padding: 15 }}>Name and surname <span style={{ float: 'right' }}>{location?.state?.firstName ?? "-"}</span></ListGroup.Item>
                            <ListGroup.Item style={{ fontWeight: "700", padding: 15 }}>Gender <span style={{ float: 'right' }}>{location?.state?.gender ?? "-"}</span></ListGroup.Item>
                            <ListGroup.Item style={{ fontWeight: "700", padding: 15 }}>Date of birth <span style={{ float: 'right' }}>{dateTimeLao(location?.state?.birthday) ?? "-"}</span></ListGroup.Item>
                            <ListGroup.Item style={{ fontWeight: "700", padding: 15 }}>Role <span style={{ float: 'right' }}>{location?.state?.role}</span></ListGroup.Item>
                            <ListGroup.Item style={{ fontWeight: "700", padding: 15 }}>Username <span style={{ float: 'right' }}>{location?.state?.userId ?? "-"}</span></ListGroup.Item>
                            <ListGroup.Item style={{ fontWeight: "700", padding: 15 }}>Password <span style={{ float: 'right' }}>*****</span></ListGroup.Item>
                            <ListGroup.Item style={{ fontWeight: "700", padding: 15 }}>Phone number <span style={{ float: 'right' }}>{location?.state?.phone ?? "-"}</span></ListGroup.Item>
                            <ListGroup.Item style={{ fontWeight: "700", padding: 15 }}>Note <span style={{ float: 'right' }}>{location?.state?.detail ?? "-"}</span></ListGroup.Item>

                        </ListGroup>
                    </Col>
                </Row>
            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Staff</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    Do you want delete {location?.state?.firstName ?? "-"} ?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={() => {
                        _deleteStaff(location?.state?.id, handleClose, Consts.PAGE_STAFF)
                    }
                    }>
                        Confirm delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </div >
    )
}
export default StaffDetail