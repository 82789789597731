// import React from 'react'
// import {Navigate} from 'react-router-dom'
// import {isLogin} from '../helpers/Helper'

// const PublicRoute = ({component:Component, restricted, ...rest}) =>{
//     // console.log("Component--->",Component)
//     if(isLogin){
//         return <Navigate to="/" />;
//     }

//     return <Component {...rest} />;
// }

// export default PublicRoute;

import React from 'react'
import { Route } from 'react-router-dom'
function PublicRoute({ component: Component, headerTitle, ...rest }) {


  return (
    <Route
      {...rest}
      render={props => {
        return (
          <div>
            <Component {...props} />
          </div>
        )
      }}
    />
  )
}

export default PublicRoute
