import { gql } from "apollo-boost";

export const CREATE_CERTIFICATE= gql`
mutation CreateCertificate($data: CertificateWhereInput) {
  createCertificate(data: $data) {
    id
  }
}

`

export const UPDATE_CERTIFICATE= gql`
mutation UpdateCertificate($where: CertificateWhereInputId!, $data: CertificateWhereInput) {
  updateCertificate(where: $where, data: $data) {
    id
  }
}
`

export const DELETE_CERTIFICATE= gql`
mutation DeleteCertificate($where: CertificateWhereInputId!) {
  deleteCertificate(where: $where) {
   id 
  }
}
`





