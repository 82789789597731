import React, { useState } from 'react'
import useReactRouter from "use-react-router";
import { Formik } from 'formik'


import { Breadcrumb, Container, Row, Modal, Col, Image, Button, ListGroup } from 'react-bootstrap'
import { FaEye, FaEdit, FaTrash, FaPlusCircle } from "react-icons/fa";
import Consts from "../../../consts"

import { Title, CustomButton } from "../../../common"
import { dateTimeLao } from "../../../helpers/Helper";
import CUSD_STAFF from '../../../crud/user';


function VolunteerfDetail() {
    const { history, location } = useReactRouter();

    //state
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const {
        _deleteStaff
    } = CUSD_STAFF();
    // function
    return (
        <div>
            <Breadcrumb style={{ marginTop: 60, fontSize: '12px' }}>
                <Breadcrumb.Item href="#" onClick={() => history.push(Consts.PAGE_VOLUNTEER_LIST)}>Volunteer Managerment</Breadcrumb.Item>
                <Breadcrumb.Item active>Volunteer Information</Breadcrumb.Item>
            </Breadcrumb>

            <div style={Consts.MAIN_CARD} >
                <div style={{ display: "flex", justifyContent: "space-between", marginTop: 20, marginBottom: 20 }}>
                    <Title text='Managerment Information' />

                    <div>
                        <CustomButton editIcon title='Edit '
                            style={{ border: 0 }}
                            onClick={() => history.push(Consts.PAGE_EDIT_VOLUNTEER, location?.state) }//PAGE_EDIT_VOLUNTEER
                        />
                        {' '}
                        <CustomButton addDelete title='Delete '
                            style={{ border: 0 }}
                            onClick={() => handleShow()}
                        />

                    </div>
                </div>
                <hr></hr>


                <Row>
                    <Col md={2} style={{ justifyContent: 'center', display: 'flex' }}>
                        <Image src={location?.state?.image ? Consts.URL_FOR_SHOW_PHOTO + location?.state?.image : "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Circle-icons-profile.svg/1024px-Circle-icons-profile.svg.png"}
                            style={{ width: 220, height: 220, borderRadius: 10 }}
                        />
                    </Col>
                    <Col md={5}>
                        <div style={{ backgroundColor: "#F6F6F6", color: "#000000", fontSize: '18px', fontWeight: "400", padding: 10, marginBottom: 10 }}>
                            General information
                        </div>
                        <ListGroup variant="flush">
                            <ListGroup.Item style={{ fontWeight: "400", padding: 15 }}>Name and surname <span style={{ float: 'right' }}>{location?.state?.firstName ?? "-"}</span></ListGroup.Item>
                            <ListGroup.Item style={{ fontWeight: "400", padding: 15 }}>Gender <span style={{ float: 'right' }}>{location?.state?.gender ?? "-"}</span></ListGroup.Item>
                            <ListGroup.Item style={{ fontWeight: "400", padding: 15 }}>Date of birth <span style={{ float: 'right' }}>{dateTimeLao(location?.state?.birthday) ?? "-"}</span></ListGroup.Item>
                            <ListGroup.Item style={{ fontWeight: "400", padding: 15 }}>Phone number <span style={{ float: 'right' }}>{location?.state?.phone ?? "-"}</span></ListGroup.Item>
                            <ListGroup.Item style={{ fontWeight: "400", padding: 15 }}>Email <span style={{ float: 'right' }}>{location?.state?.email ?? "-"}</span></ListGroup.Item>
                            <ListGroup.Item style={{ fontWeight: "400", padding: 15 }}>Education level <span style={{ float: 'right' }}>{location?.state?.educationLevel ?? "-"}</span></ListGroup.Item>
                            <ListGroup.Item style={{ fontWeight: "400", padding: 15 }}>English proficiency <span style={{ float: 'right' }}>{location?.state?.englishProficiency ?? "-"}</span></ListGroup.Item>
                            <ListGroup.Item style={{ fontWeight: "400", padding: 15 }}>Occupation <span style={{ float: 'right' }}>{location?.state?.occupation ?? "-"}</span></ListGroup.Item>
                            <ListGroup.Item style={{ fontWeight: "400", padding: 15 }}>School/Institute/Workplace <span style={{ float: 'right' }}>{location?.state?.workplace ?? "-"}</span></ListGroup.Item>
                        </ListGroup>
                    </Col>
                    <Col md={5}>
                        <div style={{ backgroundColor: "#F6F6F6", color: "#000000", fontSize: '18px', fontWeight: "400", padding: 10, marginBottom: 10 }}>
                            Brith place information
                        </div>

                        <ListGroup variant="flush">
                            <ListGroup.Item style={{ fontWeight: "400", padding: 15 }}>Province of birth <span style={{ float: 'right' }}>{location?.state?.provinceOfBirth ?? "-"}</span></ListGroup.Item>
                            <ListGroup.Item style={{ fontWeight: "400", padding: 15 }}>District of birth <span style={{ float: 'right' }}>{location?.state?.districtOfBirth ?? "-"}</span></ListGroup.Item>
                            <ListGroup.Item style={{ fontWeight: "400", padding: 15 }}>Village of birth <span style={{ float: 'right' }}>{location?.state?.villageOfBirth ?? "-"}</span></ListGroup.Item>

                        </ListGroup>
                        <div style={{ backgroundColor: "#F6F6F6", color: "#000000", fontSize: '18px', fontWeight: "400", padding: 10, marginBottom: 10 }}>
                            Current address information
                        </div>

                        <ListGroup variant="flush">
                            <ListGroup.Item style={{ fontWeight: "400", padding: 15 }}>Province <span style={{ float: 'right' }}>{location?.state?.province ?? "-"}</span></ListGroup.Item>
                            <ListGroup.Item style={{ fontWeight: "400", padding: 15 }}>District <span style={{ float: 'right' }}>{location?.state?.district ?? "-"}</span></ListGroup.Item>
                            <ListGroup.Item style={{ fontWeight: "400", padding: 15 }}>Village <span style={{ float: 'right' }}>{location?.state?.village ?? "-"}</span></ListGroup.Item>

                        </ListGroup>
                    </Col>

                    <Col md={{ span: 10, offset: 2 }}>
                        <div style={{ backgroundColor: "#F6F6F6", color: "#000000", fontSize: '18px', fontWeight: "400", padding: 10, marginBottom: 10 }}>
                            About Zero Waste Laos
                        </div>

                        <ListGroup variant="flush">
                            <ListGroup.Item style={{ fontWeight: "400", padding: 15 }}>Have you ever been a volunteer before? <span style={{ float: 'right' }}>{location?.state?.volunteerBefore ? "YES":"NO"}</span></ListGroup.Item>
                            <ListGroup.Item style={{ fontWeight: "400", padding: 15 }}>Where did you work as a volunteer? explain <span style={{ float: 'right', overflowWrap: "break-word", width: 400 }}>{location?.state?.volunteerExplain ?? "-"}</span></ListGroup.Item>
                            <ListGroup.Item style={{ fontWeight: "400", padding: 15 }}>How do you know about us? <span style={{ float: 'right' }}>{location?.state?.knowAboutUs ?? "-"}</span></ListGroup.Item>
                            <ListGroup.Item style={{ fontWeight: "400", padding: 15 }}>Have you ever joined any event held by Zero Waste Laos? <span style={{ float: 'right', overflowWrap: "break-word", width: 400 }}>{location?.state?.everJoinedZeroWaste ?? "-"}</span></ListGroup.Item>
                            <ListGroup.Item style={{ fontWeight: "400", padding: 15 }}>Please tell us why do you want to be a part of our core team? <span style={{ float: 'right', overflowWrap: "break-word", width: 400 }}>{location?.state?.tellUs ?? "-"}</span></ListGroup.Item>
                            <ListGroup.Item style={{ fontWeight: "400", padding: 15 }}>Please tell us about your skills and profession <span style={{ float: 'right', overflowWrap: "break-word", width: 400 }}>{location?.state?.skillsAndProfession ?? "-"}</span></ListGroup.Item>
                            <ListGroup.Item style={{ fontWeight: "400", padding: 15 }}>How do you want to improve yourself? <span style={{ float: 'right', overflowWrap: "break-word", width: 400 }}>{location?.state?.improveYourself ?? "-"}</span></ListGroup.Item>
                            <ListGroup.Item style={{ fontWeight: "400", padding: 15 }}>What do you expect from Zero Waste Laos? <span style={{ float: 'right', overflowWrap: "break-word", width: 400 }}>{location?.state?.expectZeroWaste ?? "-"}</span></ListGroup.Item>
                        </ListGroup>
                    </Col>
                    <Col md={4}>
                        <ListGroup variant="flush">
                            <ListGroup.Item style={{ fontWeight: "400", padding: 15 }}>Created by <span style={{ float: 'right' }}>{location?.state?.createdBy?.firstName ?? "-"}<br />
                                {dateTimeLao(location?.state?.createdAt)}</span></ListGroup.Item>
                            <ListGroup.Item style={{ fontWeight: "400", padding: 15 }}>Updated by <span style={{ float: 'right' }}>{location?.state?.updatedBy?.firstName ?? "-"}<br />
                                {dateTimeLao(location?.state?.updatedAt)}</span></ListGroup.Item>
                        </ListGroup>
                    </Col>

                </Row>
                




            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Staff</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    Do you want delete {location?.state?.firstName ?? "-"} ?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={() => {
                        _deleteStaff(location?.state?.id, handleClose, Consts.PAGE_VOLUNTEER_LIST)
                    }
                    }>
                        Confirm delete
                    </Button>
                </Modal.Footer>
            </Modal>

        </div >
    )
}
export default VolunteerfDetail