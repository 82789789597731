import { gql } from "apollo-boost";

export const CREATE_REGISTER = gql`
mutation Registration($data: UserCreateWhereInput) {
  registration(data: $data) {
    id
  }
}
`;


export const USER_LOGIN = gql`
mutation LoginUser($data: LoginUserInput) {
  loginUser(data: $data) {
    accessToken
    data {
      id
      role
      userId
      firstName
      email
      appover
    }
  }
}
`;



export const USER_UPDATE = gql`
mutation UpdateUser($where: UserWhereInputId!, $data: UserCreateWhereInput) {
  updateUser(where: $where, data: $data) {
    id
  }
}
`;
