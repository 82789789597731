import React, { useState, useEffect } from 'react'
import useReactRouter from "use-react-router";
import { Formik } from 'formik'
import { useLazyQuery } from '@apollo/react-hooks'


import { Breadcrumb, Container, Row, Form, Col, Image, Button } from 'react-bootstrap'
import Consts from "../../../consts"

import { CERTIFICATE_CATEGORYS } from "../../../apollo/certificateCategory/Query"

import { Title, CustomButton } from "../../../common"
import UploadPhoto from "../../../helpers/UploadPhoto";
import CUSD_CERTIFICATE from '../../../crud/certificate';


function CertificateAdd() {
    const { history } = useReactRouter();

    //state
    const [genderData, setGenderData] = useState("MALE");
    const {
        _createCertificate,
    } = CUSD_CERTIFICATE();
    const {
        namePhoto,
        buttonUploadAndShowPhoto,
        namePhotoMany,
        buttonUploadAndShowPhotoMany,
        setWidhtPhoto,
        setHeightPhoto,
    } = UploadPhoto();

    // apollo
    const [loadCategory, { data: categoryData }] = useLazyQuery(CERTIFICATE_CATEGORYS)
    console.log("categoryData--->", categoryData)
    // useEffect
    useEffect(() => {
        loadCategory({ variables: { where: { isDeleted: false } } })
    }, [])


    // function

    return (
        <div>
            <Breadcrumb style={{ marginTop: 60, fontSize: '12px' }}>
                <Breadcrumb.Item href="#" onClick={() => history.push(Consts.PAGE_CERTIFICATE_LIST)}>E-Cert Management</Breadcrumb.Item>
                <Breadcrumb.Item active>E-Certificate Add</Breadcrumb.Item>
            </Breadcrumb>

            <div style={Consts.MAIN_CARD} >
                <Title text='E-Certificate Add' />
                <hr></hr>
                <Formik
                    initialValues={{
                        categoryCertificateId: "",
                        topic: "",
                        detail: "",

                    }}
                    validate={values => {
                        const errors = {}
                        if (!values.categoryCertificateId) {
                            errors.categoryCertificateId = 'Please select category certificate'
                        }
                        if (!values.topic) {
                            errors.topic = 'please input topic'
                        }

                        return errors
                    }}
                    onSubmit={values => {
                        let _data = {
                            ...values,
                            imageProfile: namePhoto,
                            // images: namePhotoMany,
                        }

                        console.log("_data---->",_data)
                         _createCertificate(_data)
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting
                        /* and other goodies */
                    }) => (

                        <Row>
                            <Col md={3}>
                                <Form.Label>Upload image <span style={{ color: "red" }}>*</span></Form.Label>
                                {buttonUploadAndShowPhoto()}
                            </Col>
                            <Col md={9}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>E-Certificate <span style={{ color: "red" }}>*</span></Form.Label>
                                    <Form.Control
                                        size='lg'
                                        as="select"
                                        name="categoryCertificateId"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.categoryCertificateId}
                                        style={{ height: '40px', fontSize: '14px'}}

                                    >
                                        <option value="">Select the catagory</option>
                                        {categoryData?.categoryCertificates?.data.map((item, index) =>
                                            <option key={index} value={item?.id}>{item?.name}</option>
                                        )}


                                    </Form.Control>
                                    <div style={{ color: 'red' }}>
                                        <p>{errors.categoryCertificateId && touched.categoryCertificateId && errors.categoryCertificateId}</p>
                                    </div>
                                </Form.Group>


                                <Form.Group className="mb-3" >
                                    <Form.Label>Topic <span style={{ color: "red" }}>*</span></Form.Label>
                                    <Form.Control size='lg'
                                        type="text"
                                        id="topic"
                                        name="topic"
                                        placeholder="Please input the topic"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.topic}
                                        style={{ height: '40px', fontSize: '14px'}}
                                    />
                                    <div style={{ color: 'red' }}>
                                        <p>{errors.topic && touched.topic && errors.topic}</p>
                                    </div>


                                </Form.Group>
                                {/* <Form.Group className="mb-3" >
                                    <Form.Label>Add image (you can add only 1 image <span style={{ color: "red" }}>*</span></Form.Label>
                                    <p>Filename: JPEG, JPG, PNG. Size should be less than 5M</p>
                                    {buttonUploadAndShowPhotoMany()}
                                </Form.Group> */}

                                <Form.Group className="mb-3" >
                                    <Form.Label>detail</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        placeholder="if any detail..."
                                        name="detail"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.detail}
                                        style={{ height: '100px', fontSize: '14px'}}
                                    />
                                </Form.Group>

                                <center>
                                    <CustomButton cancel title='Cancel' style={{ width: "199px", height: '44px', border: 0 }} onClick={() => history.goBack()} />
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <CustomButton addIcon title='Add user' style={{ width: "199px", height: '44px' }} onClick={() => handleSubmit()} />
                                </center>
                            </Col>
                        </Row>
                    )}
                </Formik>
            </div>
        </div>
    )
}
export default CertificateAdd