import React from 'react'
import useReactRouter from "use-react-router";

import { Breadcrumb, Row,Col } from 'react-bootstrap'
import Consts from "../../../consts"

import { Title } from "../../../common"


//css
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBullhorn,
} from "@fortawesome/free-solid-svg-icons";
import { FaCertificate } from "react-icons/fa";
import './SettingStyle.css'


function SettingMenu() {
    const { history } = useReactRouter();

    //function
    const _pageCertificateCategory = () => { history.push(Consts.PAGE_CERTIFICATE_CATEGORY_LIST) }
    const _pagePostCategory = () => { history.push(Consts.PAGE_POST_CATEGORY_LIST) }
    const viewDetail = () => { history.push(Consts.PAGE_VOLUNTEER_DETAIL) }

    return (
        <div>
            <Breadcrumb style={{ marginTop: 60, fontSize: '12px' }}>
                <Breadcrumb.Item active>Settings</Breadcrumb.Item>
            </Breadcrumb>

            <div style={Consts.MAIN_CARD} >
                <Title text='Volunteer Management' />

                <Row className="mt-5">
                    <Col md={3}>
                        <div className="card-setting" onClick={()=>_pageCertificateCategory()}>
                            <div className="card-body">
                                <FaCertificate className="settingIcon" />
                                <p className='settingTitle'>E-Certificate Category</p>
                                <p className='settingSubTitle'>Mange your own category</p>
                            </div>
                        </div>
                    </Col>

                    <Col md={3}>
                        <div className="card-setting" onClick={()=>_pagePostCategory()}>
                            <div className="card-body">
                                <FontAwesomeIcon icon={faBullhorn} className="settingIcon" />
                                <p className='settingTitle'>Post Category</p>
                                <p className='settingSubTitle'>Mange your own category</p>
                            </div>
                        </div>
                    </Col>
                </Row>

            </div>
        </div>
    )
}
export default SettingMenu