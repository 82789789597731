import React, { useState } from 'react'
import coverImg from "../../../image/programe/2.png"
import './Index.css'
function BgCoverPrograme() {

    return (
        <div>
                <img className='imgBg-about-pro' src={coverImg} />
        </div>
    )
}
export default BgCoverPrograme