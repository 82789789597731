import React, { useState, useEffect } from 'react'
import useReactRouter from "use-react-router";
import moment from "moment";
import { useLazyQuery } from '@apollo/react-hooks'
import { USERS_DATA } from "../../../apollo/user"
import { downloadExcel } from "../../../helpers/exportToExcel";

import { Breadcrumb, Row, Form, Table, Image, Col } from 'react-bootstrap'
import Consts from "../../../consts"
import NavClimate from './NavClimate'
import { Title, CustomButton } from "../../../common"
import { dateTimeLao } from "../../../helpers/Helper"


function ClimateListPending() {
    const { history } = useReactRouter();
    const today = new Date();

    const [isExport, setIsExport] = useState(false);
    const [userList,setUsersList] = useState([]);

    const [fillterName, setFillterName] = useState('')
    const [fillterStartDate, setFillterStartDate] = useState(moment(moment(today).add(-30, "days")).format('YYYY-MM-DD'))
    const [fillterEndDate, setFillterEndDate] = useState(moment(today).format('YYYY-MM-DD'))

    //function
    const [getUsers, { data }] = useLazyQuery(USERS_DATA, {
        fetchPolicy: "network-only",
        variables: {
            where: {
                appover: "PENDING",
                role: "USER",
                firstName: fillterName === '' ? undefined : fillterName,
                startDate: fillterStartDate,
                endDate: fillterEndDate,
            }
        }
    });
    //function
    // ======>

    useEffect(() => {
        getUsers()
    }, [])


  useEffect(() => {
    if (data?.users?.data && !isExport) {
      setUsersList(data?.users?.data);
    }
    if (data?.users?.data && data?.users?.data.length > 0 && isExport) {
      const rows = data?.users?.data?.map((user, index) => ({
        index: index + 1,
        firstName: user?.firstName,
        birth:dateTimeLao(user?.birthday),
        email: user?.email ?? "-",
        phone: user?.phone ?? "-",
        Created: dateTimeLao(user?.createdAt) ?? "-",
     
      }));

      const titles = [
        "No",
        "firstName",
        "birth",
        "Email",
        "Phone",
        "Created",
      ]
      const fileName = `Zero-Waste-Reject-${moment().format("DDMMYYYYHHmmss")}.xlsx`
      downloadExcel(titles, rows, fileName)
      setIsExport(false)
    }
  }, [data?.users?.data, isExport]);
    

    const onDownloadExcel = async () => {
        try {
          await getUsers()
          setIsExport(true)
        } catch (error) {
          console.log("error: ", error);
        }
      }


    return (
        <div>
            <Breadcrumb style={{ marginTop: 60, fontSize: '12px' }}>
                <Breadcrumb.Item active>Climate Action</Breadcrumb.Item>
            </Breadcrumb>
            <NavClimate/>
            <div style={Consts.MAIN_CARD} >
                <Title text='Climate Action' />

                <Row className="mt-2">
                    <Col md={6}>
                        <Form.Group >
                            <Form.Control size='lg' type="text" style={{ marginTop: 30,height:36,fontSize:16 }} placeholder="Searching by name..." onChange={(e) => setFillterName(e?.target?.value)}/>
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group >
                            <Form.Label>From date</Form.Label>
                            <Form.Control size='lg' type="date" defaultValue={fillterStartDate} onChange={(e) => setFillterStartDate(e?.target?.value)}/>
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group >
                            <Form.Label>To date</Form.Label>
                            <Form.Control size='lg' type="date" defaultValue={fillterEndDate} onChange={(e) => setFillterEndDate(e?.target?.value)}/>
                        </Form.Group>
                    </Col>
                </Row>



                <div style={{ display: "flex", justifyContent: "space-between", marginTop: 20, marginBottom: 20 }}>
                    <h4>Show {data?.users?.data?.length} List</h4>
                    <div>

                    <CustomButton addIcon title='Download Excel'
                            style={{ marginRight: 10, backgroundColor:"#E5E5E5",color:"#000000",border:"solid 0px" }}
                            onClick={() => onDownloadExcel()}
                        />

                    </div>
                </div>



                <Table striped>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Name and surname</th>
                            <th>Email</th>
                            <th>Date of birth</th>
                            <th>Phone number</th>
                            <th>Created At</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.users?.data?.map((item, index) =>
                            <tr key={index}
                                onClick={() => history.push(Consts.PAGE_CLIMATE_DETAIL, item)}
                            >
                                <td>{index + 1}</td>
                                <td>
                                    <Image src={item?.image ? Consts.URL_FOR_SHOW_PHOTO + item?.image : "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Circle-icons-profile.svg/1024px-Circle-icons-profile.svg.png"}
                                        style={{ width: 40, height: 40 }}
                                    />
                                    &nbsp;&nbsp;&nbsp;

                                    {item?.firstName}
                                </td>
                                <td>{item?.email ?? "-"}</td>
                                <td>{dateTimeLao(item?.birthday)}</td>
                                <td>{item?.phone ?? "-"}</td>
                                <td>{dateTimeLao(item?.createdAt)}</td>
                            </tr>
                        )}
                    </tbody>
                </Table>


            </div>



        </div>
    )
}
export default ClimateListPending