import { gql } from "apollo-boost";


export const PLANTING_DONATE = gql`
query PlantDonations($where: PlantDonationWhereInput) {
  plantDonations(where: $where) {
    data {
      id
      userId {
        id
        firstName
      }
      typeOfDonated
      numberDonated
      images
      province
      district
      village
      lat
      long
      detail
      createdAt
      note
    }
    total
  }
}
`