
import { useMutation } from '@apollo/react-hooks'
import useReactRouter from "use-react-router";
import { CREATE_POST_CATEGORY,POST_POST_CATEGORY } from '../apollo/post/Mutation'
import { successAdd, errorAdd } from '../helpers/sweetalert'
import Routers from "../consts";

export default function CreatePostCategory() {
    const { history } = useReactRouter();

    // ====== updateCategory ======>
    const [updatePostCategory] = useMutation(POST_POST_CATEGORY)
    const _updatePostCategory = async (item, DataAction) => {
        try {

            console.log("item------->",item)
            console.log("DataAction------->",DataAction)

            if (item?.name === DataAction?.name) {
                delete item?.name
            }
            let _updateData = await updatePostCategory({
                variables: {
                    data: {
                        name: item?.name,
                        detail: item?.detail,
                    },
                    where: {
                        id: item?.id
                    }
                }
            })
            if (_updateData?.data?.updateCategoryPost) {
                await successAdd("Update Success")
                history.replace(Routers.PAGE_POST_CATEGORY_LIST)
            }
        } catch (error) {
            errorAdd(error)
        }
    }
    // ====== createCategory ======>
    const [createPostCategory] = useMutation(CREATE_POST_CATEGORY)
    const _createPostCategory = async (items) => {
        try {
            let _createData = await createPostCategory({
                variables: {
                    data: items
                }
            })
            if (_createData?.data?.createCategoryPost) {
                await successAdd("Success")
                history.replace(Routers.PAGE_POST_CATEGORY_LIST)
            }
        } catch (error) {
            errorAdd(error)
        }
    }
    // ====== deleteCategory ======>
    // const [deleteStaff] = useMutation(DELETE_STAFF)
    // const _deleteStaff = async (items, handleCloseDelete) => {
    //     try {
    //         let _deleteData = await deleteStaff({
    //             variables: {
    //                 where: { id: items }
    //             }
    //         })
    //         if (_deleteData?.data?.deleteUser) {
    //             await successAdd("ລົບຂໍ້ມູນສຳເລັດ")
    //             await handleCloseDelete()
    //             history.replace(Routers.PAGE_STAFF)
    //         }
    //     } catch (error) {
    //         errorAdd(error)
    //     }
    // }


    return {
        _updatePostCategory,
        // _deleteStaff,
        _createPostCategory
    }
}