import { gql } from "apollo-boost";

export const CREATE_POST_CATEGORY = gql`
mutation CreateCategoryPost($data: CategoryPostWhereCreateInput) {
  createCategoryPost(data: $data) {
    id
  }
}
`

export const POST_POST_CATEGORY = gql`
mutation UpdateCategoryPost($where: CategoryPostWhereInputId!, $data: CategoryPostWhereInput) {
  updateCategoryPost(where: $where, data: $data) {
    id
  }
}
`

export const CREATE_POST = gql`
mutation CreatePost($data: PostInput) {
  createPost(data: $data) {
    id
  }
}
`

export const UPDATE_POST = gql`
mutation UpdatePost($where: PostWhereInputId!, $data: PostInput) {
  updatePost(where: $where, data: $data) {
    id
  }
}
`

export const DELETE_POST = gql`
mutation DeletePost($where: PostWhereInputId!) {
  deletePost(where: $where) {
    id
  }
}
`





