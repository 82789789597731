import React, { useState, useEffect } from 'react'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Breadcrumb, Button, Col, Form, Image, Modal, Row, Spinner } from 'react-bootstrap'
import useReactRouter from "use-react-router";
import { useLazyQuery, useMutation } from '@apollo/react-hooks';

import { Title } from "../../../common"
import consts, { URL_FOR_SHOW_PHOTO } from '../../../consts'
import { POST } from '../../../apollo/post/Query';
import { dateTimeLao } from '../../../helpers/Helper';
import { DELETE_POST } from '../../../apollo/post/Mutation';

export default function DetailBlog() {
    const { history, match, location } = useReactRouter();
    const POST_ID = match.params.id
    const [isOpenMadal, setIsOpenMadal] = useState(false);
    const [post, setPost] = useState({});

    const [getPost, { loading: postLoading, data: postData }] = useLazyQuery(POST, { fetchPolicy: "network-only" })
    const [deletePost] = useMutation(DELETE_POST)

    useEffect(() => {
        queryPost()
    }, [])

    useEffect(() => {
        if (postData || location?.state) {
            setPost(postData?.post ?? (location?.state ?? {}))
        } else {
            queryPost()
        }
    }, [postData])

    const queryPost = () => {
        try {
            getPost({
                variables: {
                    where: {
                        id: POST_ID
                    }
                },
                fetchPolicy: "network-only"
            })
        } catch (error) {
            console.log(error);
        }
    }
    const onDeleteConfirm = () => {
        setIsOpenMadal(true)
    }

    const onDeletePost = async () => {
        try {
            if (POST_ID) {
                await deletePost({
                    variables: {
                        where: {
                            id: POST_ID
                        }
                    }
                })
            }
            setIsOpenMadal(false)
            history.push(consts.PAGE_POST_LIST)
        } catch (error) {
            console.log("error: ", error);
            setIsOpenMadal(false)
        }
    }

    if (postLoading) return <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '85vh', justifyContent: 'center', alignItems: 'center' }}>
        <div style={{ display: 'flex', backgroundColor: 'rgba(0, 169, 145, 0.3)', borderRadius: 8, flexDirection: 'column', width: 100, height: 100, justifyContent: 'center', alignItems: 'center' }}>
            <Spinner animation="border" variant="success" />
            <p style={{ fontSize: 12, marginTop: 8 }}>Loading...</p>
        </div>
    </div >;

    return (
        <div>
            <Breadcrumb style={{ marginTop: 60, fontSize: '12px' }}>
                <Breadcrumb.Item onClick={() => history.push(consts.PAGE_POST_LIST)}><span style={{ color: "#2E72D2" }}>Blog Management</span></Breadcrumb.Item>
                <Breadcrumb.Item active>Blog Detail</Breadcrumb.Item>
            </Breadcrumb>
            <div style={consts.MAIN_CARD} >
                <div style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between" }}>
                    <div>
                        <Title text='Blog Detail' />
                    </div>
                    <div style={{ display: 'flex', flexDirection: "row", width: 300, alignItems: 'center' }}>
                        <Button variant="secondary" size="lg" onClick={() => history.push(consts.PAGE_EDIT_POST + "/" + post?.id, post)} style={{ height: 40, width: 100 }}>
                            <FontAwesomeIcon icon={faEdit} /> &nbsp;&nbsp; Edit
                        </Button>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <Button variant="danger" size="lg" onClick={() => onDeleteConfirm()} style={{ height: 40, width: 100 }}>
                            <FontAwesomeIcon icon={faTrash} /> &nbsp;&nbsp; Delete
                        </Button>
                    </div>
                </div>
                <Col md={9}>
                    <hr />
                </Col>
                <Row>
                    <Col md={3}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <Form.Label>Feature image</Form.Label>
                            {post?.imageProfile && <Image src={`${URL_FOR_SHOW_PHOTO}${post?.imageProfile}`} style={{ width: 200, height: 200, marginRight: 10 }} rounded />}
                        </div>
                    </Col>
                    <Col md={8}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between", alignItems: 'center' }}>
                                <Form.Label style={{ marginRight: 80 }}>Blog category</Form.Label>
                                <Form.Label>{post?.categoryPostName ?? "-"}</Form.Label>
                            </div>
                        </Form.Group>
                        <hr />
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between", alignItems: 'center' }}>
                                <Form.Label style={{ marginRight: 80 }}>Topic</Form.Label>
                                <Form.Label>{post?.topic ?? "-"}</Form.Label>
                            </div>
                        </Form.Group>
                        <hr />
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between", alignItems: 'center' }}>
                                <Form.Label style={{ marginRight: 80 }}>Contents</Form.Label>
                                <Form.Label><div style={{ textAlign: "justify" }} dangerouslySetInnerHTML={{ __html: post?.contents ?? "-" }} /></Form.Label>
                            </div>
                        </Form.Group>
                        <hr />
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between", alignItems: 'center' }}>
                                <Form.Label style={{ marginRight: 80 }}>images</Form.Label>
                                <div>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginTop: 10 }}>
                                        {post?.images?.map((image, index) => <Image key={index} src={`${URL_FOR_SHOW_PHOTO}${image}`} style={{ width: 80, height: 80, marginRight: 10 }} />)}
                                    </div>
                                </div>
                            </div>
                        </Form.Group>
                        <hr />
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between", alignItems: 'center' }}>
                                <Form.Label style={{ marginRight: 80 }}>Note</Form.Label>
                                <Form.Label>{post?.note ?? "-"}</Form.Label>
                            </div>
                        </Form.Group>
                    </Col>
                </Row>
                <div style={{ height: 70 }} />
                <Col md={3}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between", alignItems: 'center' }}>
                                <Form.Label style={{ color: "#3F4952", fontSize: 14 }}>Created by</Form.Label>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Form.Label style={{ color: "#3F4952", fontSize: 14 }}>{post?.createdBy?.firstName ?? "-"}</Form.Label>
                                    <Form.Label style={{ color: "#3F4952", fontSize: 14 }}>{dateTimeLao(post?.createdAt)}</Form.Label>
                                </div>
                            </div>
                        </Form.Group>
                        <hr />
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between", alignItems: 'center' }}>
                                <Form.Label style={{ color: "#3F4952", fontSize: 14 }}>Updated by</Form.Label>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Form.Label style={{ color: "#3F4952", fontSize: 14 }}>{post?.updatedBy?.firstName ?? "-"}</Form.Label>
                                    <Form.Label style={{ color: "#3F4952", fontSize: 14 }}>{post?.updatedBy ? dateTimeLao(post?.updatedAt) : "-"}</Form.Label>
                                </div>
                            </div>
                        </Form.Group>
                        <hr />
                    </div>
                </Col>
            </div>

            <Modal show={isOpenMadal} onHide={() => setIsOpenMadal(false)}>
                <Modal.Body>
                    <p style={{ fontSize: "18px", fontWeight: "bold" }}>Are you sure you want to delete?</p>
                    <p style={{ fontSize: "16px", color: "#4F5E71" }}>Please ensure and then confirm!</p>
                    <div style={{ float: "right", marginTop: 10 }}>
                        <Button variant="secondary" onClick={() => setIsOpenMadal(false)} style={{ width: 90 }}>
                            Cancel
                        </Button>
                        &nbsp;&nbsp;&nbsp;
                        <Button variant="danger" onClick={() => onDeletePost()} style={{ width: 90 }}>
                            Confirm
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>

        </div>
    )
}
