import { gql } from "apollo-boost";

export const CLIMATE_DATA = gql`
query Query($where: PlantDonationWhereInput) {
  plantDonations(where: $where) {
    total
    data {
      id
      userName
      typeOfDonated
      numberDonated
      images
      province
      district
      village
      lat
      long
      detail
      userId {
        id
        userId
        role
        firstName
        email
      }
      createdAt
      createdBy {
        id
        firstName
      }
      updatedAt
      updatedBy {
        id
        firstName
      }
      note
    }
  }
}
`;
export const CREATE_STAFF = gql`
mutation Mutation($data: UserCreateWhereInput) {
  createUser(data: $data) {
    id
  }
}
`;
export const UPDATE_STAFF = gql`
 mutation Mutation($where: UserWhereInputId!, $data: UserCreateWhereInput) {
  updateUser(where: $where, data: $data) {
    id
  }
}
`;
export const DELETE_STAFF = gql`
mutation Mutation($where: UserWhereInputId!) {
  deleteUser(where: $where) {
    id
  }
}
`;