
import { useMutation } from '@apollo/react-hooks'
import useReactRouter from "use-react-router";
import { CREATE_CERTIFICATE,UPDATE_CERTIFICATE,DELETE_CERTIFICATE } from '../apollo/certificate/Mutation'
import { successAdd, errorAdd } from '../helpers/sweetalert'
import Routers from "../consts";

export default function CreateCertificateCategory() {
    const { history } = useReactRouter();

    // ====== updateCategory ======>
    const [updateCertificate] = useMutation(UPDATE_CERTIFICATE)
    const _updateCertificate = async (item, DataAction) => {
        try {
            
            // if (DataAction?.topic === item?.topic) {
            //     delete item?.topic
            // }
            console.log("item----->",item)

            let data={...item}

            let _updateData = await updateCertificate({
                variables: {
                    data: data,
                    where: {
                        id: DataAction?.id
                    }
                }
            })
            if (_updateData?.data?.updateCertificate) {
                await successAdd("Update Success")
                history.replace(Routers.PAGE_CERTIFICATE_LIST)
            }
        } catch (error) {
            errorAdd(error)
        }
    }
    // ====== createCategory ======>
    const [createCertificate] = useMutation(CREATE_CERTIFICATE)
    const _createCertificate = async (items) => {
        try {
            let _createData = await createCertificate({
                variables: {
                    data: items
                }
            })
            if (_createData?.data?.createCertificate) {
                await successAdd("Success")
                history.replace(Routers.PAGE_CERTIFICATE_LIST)
            }
        } catch (error) {
            errorAdd(error)
        }
    }
    // ====== deleteCategory ======>
    const [deleteCertificate] = useMutation(DELETE_CERTIFICATE)
    const _deleteCertificate = async (items, handleCloseDelete) => {
        try {
            console.log("items--->",items)
            let _deleteData = await deleteCertificate({
                variables: {
                    where: { id: items?.id }
                }
            })
            if (_deleteData?.data?.deleteCertificate) {
                await successAdd("ລົບຂໍ້ມູນສຳເລັດ")
                await handleCloseDelete()
                history.replace(Routers.PAGE_CERTIFICATE_LIST)
            }
        } catch (error) {
            errorAdd(error)
        }
    }


    return {
         _updateCertificate,
         _deleteCertificate,
        _createCertificate
    }
}