import React from "react";
import ApolloClient from "apollo-boost";
import { ApolloProvider } from "@apollo/react-hooks";
import { InMemoryCache } from "@apollo/client";
import { USER_KEY } from "./consts";
import './App.css';

import RouterLink from './routes'

const App = () => {
  const client = new ApolloClient({

    // uri: "https://4vld4ho53b.execute-api.ap-southeast-1.amazonaws.com",
    // uri: "http://localhost:2022",
    uri: "https://zero.api.vixayexpress.la/", //new endpoint
    
    
    // cache: new InMemoryCache({
    //   addTypename: false,
    // }),
    request: (operation) => {
      const _resData = localStorage.getItem(USER_KEY);
      const _localJson = JSON.parse(_resData)
      if (_localJson) {
        operation.setContext({
          headers: {
            authorization: _localJson?.accessToken,
          },
        });
        return;
      }
    },
  });
  return (
      <ApolloProvider client={client}>
      <RouterLink />
    </ApolloProvider>
  );
}
export default App;
