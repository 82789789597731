import React, { useState, useEffect } from 'react'
import useReactRouter from "use-react-router";

import { Breadcrumb, Button, Image, Table } from 'react-bootstrap'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn, faMap, faTree } from '@fortawesome/free-solid-svg-icons';
import { DASHBOARD, USERS_DASHBOARD } from '../../../apollo/dashboard/Query';
import { UPDATE_STAFF } from '../../../apollo/user'
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { URL_FOR_SHOW_PHOTO } from '../../../consts';
import { dateTimeLao } from '../../../helpers/Helper';
import { successAdd } from '../../../helpers/sweetalert'
import { moneyCurrency } from "../../../helpers/Helper"

import Consts from "../../../consts"


ChartJS.register(ArcElement, Tooltip, Legend);

function Deshboard() {
    const { history } = useReactRouter();

    const [dashboard, setDashboard] = useState({})
    const [userData, setUserData] = useState({
        labels: ["Male", "Female", "LGBTQ+"],
        datasets: [
            {
                data: [0, 0, 0],
                backgroundColor: ['#00A991', "#e9c46a", '#FB8832'],
                borderColor: ['#00A991', "#e9c46a", '#FB8832'],
                borderWidth: 1,
            },
        ],
    })
    const [volunteerData, setVolunteerData] = useState({
        labels: ["Male", "Female", "LGBTQ+"],
        datasets: [
            {
                data: [0, 0, 0],
                backgroundColor: ['#33475B', '#a8dadc', '#999999'],
                borderColor: ['#33475B', '#a8dadc', '#999999'],
                borderWidth: 1,
            },
        ],
    })
    const [usersDashboard, setUsersDashboard] = useState([])

    const [getDashboard, { data: dashboardData }] = useLazyQuery(DASHBOARD, { fetchPolicy: "network-only" })
    const [getDashboardUsers, { data: dashboardUsersData }] = useLazyQuery(USERS_DASHBOARD, {
        fetchPolicy: "network-only",
        variables: {
            where: {
                role: "USER",
                appover: "WAITTING",
            }
        }
    })
    const [updateStaff] = useMutation(UPDATE_STAFF)

    useEffect(() => {
        getDashboard({
            variables: {
                where: {
                    id: "62a61e10160b6e624884dc24"
                }
            }
        })
        _getDashboardUsers()
    }, [])

    useEffect(() => {
        if (dashboardData?.dashboard) {
            setDashboard(dashboardData?.dashboard ?? {})
            setUserData({
                labels: ["Male", "Female", "LGBTQ+"],
                datasets: [
                    {
                        data: [dashboardData?.dashboard?.usersMale, dashboardData?.dashboard?.usersFemale, dashboardData?.dashboard?.usersLGBTQ],
                        backgroundColor: ['#00A991', "#e9c46a", '#FB8832'],
                        borderColor: ['#00A991', "#e9c46a", '#FB8832'],
                        borderWidth: 1,
                    },
                ],
            })
            setVolunteerData({
                labels: ["Male", "Female", "LGBTQ+"],
                datasets: [
                    {
                        data: [dashboardData?.dashboard?.volunteersMale, dashboardData?.dashboard?.volunteersFemale, dashboardData?.dashboard?.volunteersLGBTQ],
                        backgroundColor: ['#33475B', '#a8dadc', '#999999'],
                        borderColor: ['#33475B', '#a8dadc', '#999999'],
                        borderWidth: 1,
                    },
                ],
            })
        }
    }, [dashboardData])

    useEffect(() => {
        if (dashboardUsersData?.users?.data) {
            setUsersDashboard(dashboardUsersData?.users?.data ?? [])
        }
    }, [dashboardUsersData])

    const _getDashboardUsers = () => {
        try {
            getDashboardUsers({
                variables: {
                    where: {
                        role: "USER",
                        appover: "WAITTING",
                        isDeleted: false
                    },
                    skip: 0,
                    limit: 500,
                }
            })
        } catch (error) {
            console.log("error: ", error)
        }
    }

    const updateUserDashboard = async (data, type) => {
        try {
            const _updateStaff = await updateStaff({
                variables: {
                    data: { appover: type },
                    where: { id: data.id }
                }
            })
            if (_updateStaff?.data?.updateUser) {
                successAdd("UPDATE SUCCESS")
                _getDashboardUsers()
            }
        } catch (error) {
            console.log("error: ", error)
        }
    }

    const options = {
        plugins: {
            legend: {
                display: false,
            },
        },
    };

    return (
        <div>
            <Breadcrumb style={{ marginTop: 60, paddingTop: 10, paddingLeft: 5 }}>
                <Breadcrumb.Item style={{ color: "#2E72D2" }} active>Dashboard</Breadcrumb.Item>
                <div style={{ backgroundColor: "white", minHeight: "95vh", width: "100%", padding: 20, marginTop: 10 }}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{ width: "18vw", border: "1px solid #E5E5E5", borderRadius: 4, padding: 16 }}>
                            <p style={{ fontSize: 16, fontWeight: "bold" }}>All users: {dashboard?.allUsers ?? 0}</p>
                            <Pie data={userData} options={options} />
                            <div style={{ display: "flex", flexDirection: "row", width: "100%", marginTop: 10, height: 20, justifyContent: "center", alignItems: "center" }}>
                                <div style={{ width: 8, height: 8, borderRadius: 8, backgroundColor: "#00A991" }} />
                                <div style={{ marginLeft: 8, color: "grey", fontSize: 12 }}>Male</div>
                                <div style={{ width: 8, height: 8, borderRadius: 8, backgroundColor: "#e9c46a", marginLeft: 10 }} />
                                <div style={{ marginLeft: 5, color: "grey", fontSize: 12 }}>Female</div>
                                <div style={{ width: 8, height: 8, borderRadius: 8, backgroundColor: "#FB8832", marginLeft: 10 }} />
                                <div style={{ marginLeft: 5, color: "grey", fontSize: 12 }}>LGBTQ+</div>
                            </div>
                        </div>
                        <div style={{ width: "18vw", border: "1px solid #E5E5E5", borderRadius: 4, padding: 16, marginLeft: 20 }}>
                            <p style={{ fontSize: 16, fontWeight: "bold" }}>All volunteers: {dashboard?.allVolunteers ?? 0}</p>
                            <Pie data={volunteerData} options={options} />
                            <div style={{ display: "flex", flexDirection: "row", width: "100%", marginTop: 10, height: 20, justifyContent: "center", alignItems: "center" }}>
                                <div style={{ width: 8, height: 8, borderRadius: 8, backgroundColor: "#33475B" }} />
                                <div style={{ marginLeft: 8, color: "grey", fontSize: 12 }}>Male</div>
                                <div style={{ width: 8, height: 8, borderRadius: 8, backgroundColor: "#a8dadc", marginLeft: 10 }} />
                                <div style={{ marginLeft: 5, color: "grey", fontSize: 12 }}>Female</div>
                                <div style={{ width: 8, height: 8, borderRadius: 8, backgroundColor: "#999999", marginLeft: 10 }} />
                                <div style={{ marginLeft: 5, color: "grey", fontSize: 12 }}>LGBTQ+</div>
                            </div>
                        </div>
                        <div style={{ width: "37vw", borderRadius: 4, marginLeft: 20 }}>
                            <div style={{ width: "100%", border: "1px solid #E5E5E5", borderRadius: 4, padding: 16, display: "flex", flexDirection: "row" }}>
                                <div style={{ width: "8vw", height: "8vw", backgroundColor: "#00A991", borderRadius: 8, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", boxShadow: '0.5px 5px 14px rgba(0, 169, 145, .7)' }}>
                                    <FontAwesomeIcon icon={faMap} style={{ color: "white", fontSize: "3vw" }} />
                                </div>
                                <div style={{ marginLeft: 32, height: "8vw", width: "25vw", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                    <div style={{ fontSize: 16, fontWeight: "bold" }}>Locations of trees planted</div>
                                    <div style={{ fontSize: 40, color: "#3ECD7B", fontWeight: "bold" }}>{dashboard?.locationTree ?? 0}</div>
                                </div>
                            </div>
                            <div style={{ height: 24 }} />
                            <div style={{ width: "100%", border: "1px solid #E5E5E5", borderRadius: 4, padding: 16, display: "flex", flexDirection: "row" }}>
                                <div style={{ width: "8vw", height: "8vw", backgroundColor: "#00A991", borderRadius: 8, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", boxShadow: '0.5px 5px 14px rgba(0, 169, 145, .7)' }}>
                                    <FontAwesomeIcon icon={faTree} style={{ color: "white", fontSize: "3vw" }} />
                                </div>
                                <div style={{ marginLeft: 32, height: "8vw", width: "25vw", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                    <div style={{ fontSize: 16, fontWeight: "bold",paddingBottom:10 }}>Number of seedlings donated</div>
                                    <div style={{ fontSize: 25, color: "#3ECD7B", fontWeight: "bold" }}>{moneyCurrency(dashboard?.donatedTree ?? 0)} / all carbon : {moneyCurrency(dashboard?.donatedTree*15 ?? 0)} kg</div>
                                </div>
                            </div>
                        </div>
                        <div style={{ width: "15vw", marginLeft: 20, border: "1px solid #E5E5E5", borderRadius: 4, padding: 16, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                            <div style={{ width: "8vw", height: "8vw", backgroundColor: "#00A991", borderRadius: 8, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", boxShadow: '0.5px 5px 14px rgba(0, 169, 145, .7)' }}>
                                <FontAwesomeIcon icon={faBullhorn} style={{ color: "white", fontSize: "3vw" }} />
                            </div>
                            <div style={{ height: 24 }} />
                            <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <div style={{ fontSize: 16, fontWeight: "bold" }}>Blog total</div>
                                <div style={{ fontSize: 40, color: "#3ECD7B", fontWeight: "bold" }}>{dashboard?.blogs ?? 0}</div>
                            </div>
                        </div>
                    </div>
                    <div style={{ marginTop: 32 }}>
                        <p style={{ fontSize: 16, fontWeight: "bold" }}>Newest Climate Action</p>
                    </div>
                    <Table striped>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Full name</th>
                                <th>User account</th>
                                <th>Birth of date</th>
                                <th>Phone number</th>
                                <th>Register date</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {usersDashboard?.map((item, index) => <tr key={index}>
                                <td onClick={() => history.push(Consts.PAGE_CLIMATE_DETAIL, item)}>{index + 1}</td>
                                <td onClick={() => history.push(Consts.PAGE_CLIMATE_DETAIL, item)}>
                                    {item?.image && <Image src={`${URL_FOR_SHOW_PHOTO}${item?.image}`} style={{ width: 30, height: 30 }} />}
                                    &nbsp;&nbsp;&nbsp;
                                    {item?.firstName ?? "-"}
                                </td>
                                <td onClick={() => history.push(Consts.PAGE_CLIMATE_DETAIL, item)}>{item?.email ?? "-"}</td>
                                <td onClick={() => history.push(Consts.PAGE_CLIMATE_DETAIL, item)}>{dateTimeLao(item?.birthday)}</td>
                                <td onClick={() => history.push(Consts.PAGE_CLIMATE_DETAIL, item)}>{item?.firstName ?? "-"}</td>
                                <td onClick={() => history.push(Consts.PAGE_CLIMATE_DETAIL, item)}>{dateTimeLao(item?.createdAt)}</td>
                                <td>
                                    <Button onClick={() => updateUserDashboard(item, "APPROVED")} variant="primary" size="lg" style={{fontSize: 16}}>verify</Button>
                                    &nbsp;&nbsp;&nbsp;
                                    <Button onClick={() => updateUserDashboard(item, "NOT_APPROVED")} variant="secondary" size="lg" style={{fontSize: 16}}>reject</Button>
                                </td>
                            </tr>)}
                        </tbody>
                    </Table>
                </div>
            </Breadcrumb>
        </div>
    )
}
export default Deshboard