
import { gql } from "apollo-boost";

export const CREATE_PLANT_DONATE= gql`
mutation CreatePlantDonation($data: PlantDonationWhereInput) {
    createPlantDonation(data: $data) {
      id
    }
  }
`;


