import React, { useState, useEffect } from 'react'
import moment from "moment";
import useReactRouter from "use-react-router";
import { Formik } from 'formik'


import { Breadcrumb, Container, Row, Form, Col, Image, Button } from 'react-bootstrap'
import { FaEye, FaEdit, FaTrash, FaPlusCircle } from "react-icons/fa";
import Consts from "../../../consts"

import { Title, CustomButton } from "../../../common"
import UploadPhoto from "../../../helpers/UploadPhoto";
import CUSD_STAFF from '../../../crud/user';


function StaffEdit() {
    const { history, location } = useReactRouter();

    //state
    const [genderData, setGenderData] = useState("MALE");
    const {
        _updateStaff,
    } = CUSD_STAFF();
    const {
        namePhoto,
        buttonUploadAndShowPhoto,
        buttonUploadAndShowPhotoEdit,
        setWidhtPhoto,
        setHeightPhoto,
      } = UploadPhoto();

    useEffect(() => {
        setGenderData(location?.state?.gender)
    }, [location?.state])
    
    // function
    const _staffList = () => { history.push(Consts.PAGE_STAFF) }


    return (
        <div>
            <Breadcrumb style={{ marginTop: 60, fontSize: '12px' }}>
                <Breadcrumb.Item href="#" onClick={() => _staffList()}>Staff Managerment</Breadcrumb.Item>
                <Breadcrumb.Item active>Edit Staff</Breadcrumb.Item>
            </Breadcrumb>

            <div style={Consts.MAIN_CARD} >
                <Title text='Staff Managerment' />
                <hr></hr>
                {location?.state &&
                    <Formik
                        initialValues={{
                            firstName: location?.state?.firstName,
                            userId: location?.state?.userId,
                            password: "",
                            appover: "APPROVED",
                            birthday: moment(location?.state?.birthday).format('YYYY-MM-DD'),
                            phone: location?.state?.phone,
                            image: location?.state?.image,
                            detail: location?.state?.detail,
                        }}
                        validate={values => {
                            const errors = {}
                            if (!values.firstName) {
                                errors.firstName = 'ກະລຸນາປ້ອນຂໍ້ມູນ'
                            }
                            if (!values.userId) {
                                errors.userId = 'ກະລຸນາປ້ອນຂໍ້ມູນ'
                            }
                            if (!values.phone) {
                                errors.phone = 'ກະລຸນາປ້ອນຂໍ້ມູນ'
                            }
                            return errors
                        }}
                        onSubmit={values => {
                            let _data = {
                                ...values,
                                role: "ADMIN",
                                image: namePhoto ?? location?.state?.image,
                                gender: genderData,
                            }
                            if (location?.state?.userId === _data?.userId) {
                                delete _data?.userId
                            }
                            if (_data?.password === "") {
                                delete _data?.password
                            }
                            _updateStaff(_data, location?.state?.id, Consts.PAGE_STAFF)
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting
                            /* and other goodies */
                        }) => (

                            <Row>
                                <Col md={3}>
                                    <Form.Label>Upload image <span style={{ color: "red" }}>*</span></Form.Label>
                                    {buttonUploadAndShowPhotoEdit(location?.state?.image)}
                                </Col>
                                <Col md={9}>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Name and surname <span style={{ color: "red" }}>*</span></Form.Label>
                                        <Form.Control
                                            size='lg'
                                            type="text"
                                            placeholder="Please Name and surname"
                                            name="firstName"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.firstName}
                                        />
                                        <div style={{ color: 'red' }}>
                                            <p>{errors.firstName && touched.firstName && errors.firstName}</p>
                                        </div>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Gender</Form.Label>
                                        <br />
                                        <div className="custom-control custom-radio custom-control-inline">
                                    
                                            <Form.Check
                                                type="radio"
                                                id="MALE"
                                                name="gender"
                                                checked={genderData === "MALE"}
                                                onChange={() => setGenderData("MALE")}
                                                style={{ width: '20px !important', height: "20px !important" }}
                                            />
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <Form.Check.Label>ຊາຍ</Form.Check.Label>
                                        </div>
                                        <div className="custom-control custom-radio custom-control-inline">
                                       
                                            <Form.Check
                                                type="radio"
                                                id="FEMALE"
                                                name="gender"
                                                checked={genderData === "FEMALE"}
                                                onChange={() => setGenderData("FEMALE")}
                                                style={{ width: '20px !important', height: "20px !important" }}
                                            
                                            />
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <Form.Check.Label>ຍິງ</Form.Check.Label>
                                        </div>

                                        <div className="custom-control custom-radio custom-control-inline">

                                            <Form.Check
                                                type="radio"
                                                id="LGBTQ"
                                                name="gender"
                                                checked={genderData === "LGBTQ"}
                                                onChange={() => setGenderData("LGBTQ")}
                                                style={{ width: '20px !important', height: "20px !important" }}
                                            
                                            />
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <Form.Check.Label>LGBTQ+</Form.Check.Label>
                                      
                                        </div>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Date of birth <span style={{ color: "red" }}>*</span></Form.Label>
                                        <Form.Control size='lg'
                                            type="date"
                                            id="birthday"
                                            name="birthday"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.birthday}
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Username <span style={{ color: "red" }}>*</span></Form.Label>
                                        <Form.Control
                                            size='lg'
                                            type="text"
                                            placeholder="Please username"
                                            name="userId"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.userId}
                                        />
                                        <div style={{ color: 'red' }}>
                                            <p>{errors.userId && touched.userId && errors.userId}</p>
                                        </div>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control
                                            size='lg'
                                            type="text"
                                            placeholder="*******"
                                            name="password"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.password}
                                        />
                                        {/* <div style={{ color: 'red' }}>
                                            <p>{errors.password && touched.password && errors.password}</p>
                                        </div> */}
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Phone number <span style={{ color: "red" }}>*</span></Form.Label>
                                        <Form.Control
                                            size='lg'
                                            type="text"
                                            placeholder="20xxxxxxx"
                                            name="phone"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.phone}
                                        />
                                        <div style={{ color: 'red' }}>
                                            <p>{errors.phone && touched.phone && errors.phone}</p>
                                        </div>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Note</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            placeholder="if any note..."
                                            name="detail"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.detail}
                                            style={{ height: '100px' }}
                                        />
                                    </Form.Group>
                                
                                    <center>
                                        <CustomButton cancel title='Cancel' style={{ width: "199px", height: '44px', border: 0 }} onClick={() => history.goBack()} />
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <CustomButton addIcon title='Edit Staff' style={{ width: "199px", height: '44px' }} onClick={() => handleSubmit()} />
                                    </center>
                                </Col>
                            </Row>
                        )}
                    </Formik>
                }
            </div>
        </div>
    )
}
export default StaffEdit