import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import useReactRouter from "use-react-router";


import Consts from "../../../consts"
import UploadPhoto from "../../../helpers/UploadPhoto";


import { FaAngleRight } from "react-icons/fa";
import { Modal, Container, Row, Col, Button, Form } from 'react-bootstrap'
import { FaTimes } from "react-icons/fa";


import P1 from "../../../image/programe/programe1/p1.jpg"
import P2 from "../../../image/programe/programe1/p2.jpg"
import P3 from "../../../image/programe/programe1/p3.jpg"
import P4 from "../../../image/programe/programe1/p4.jpg"
import P5 from "../../../image/programe/programe1/p5.png"

import Bgprograme from "../../../image/programe/bgPrograme.png"
import NewsCover from "../../../image/blog/NewsCover.png"


import Footer from "../../../components/footer"

import './Index.css'


function AboutPro2022() {
    const { history } = useReactRouter();
    const {
        namePhoto,
        buttonUploadAndShowPhoto,
        setWidhtPhoto,
        setHeightPhoto,
    } = UploadPhoto();
    const [showFormRegister, setShowFormRegister] = useState(false);

    const handleCloseFormRegister = () => setShowFormRegister(false);
    const handleShowFormRegister = () => setShowFormRegister(true);



    return (
        <div style={{ zIndex: 0 }}>
            <div style={{ height: 150 }} className='hideHeight'></div>




            <Container fluid className="m-0 p-0 mb-5 bgImage-about-pro">
                <Row className="m-0 p-0">
                    <Col md='12' xs={12} className="m-0 p-0 banner-about-pro">
                        <img className='imgBg-about-pro' src={NewsCover} />

                        <div className="centered-about-pro">
                            <p className="proMainTitle">About us</p>
                        </div>

                        <div className="container-list">
                            <div className='list-items ' onClick={() => history.push(Consts.PAGE_ABOUT)}> Story</div>
                            <div className='list-items action-programe' onClick={() => history.push(Consts.PAGE_ABOUT_PRO2022)}> <span onClick={() => history.push(Consts.PAGE_ABOUT_PRO2022)}>ZWL 2022</span></div>
                            <div className='list-items' onClick={() => history.push(Consts.PAGE_ABOUT_PRO2021)}><span onClick={() => history.push(Consts.PAGE_ABOUT_PRO2021)}> ZWL 2021</span></div>
                            <div className='list-items' onClick={() => history.push(Consts.PAGE_ABOUT_PRO2020)}><span onClick={() => history.push(Consts.PAGE_ABOUT_PRO2020)} > ZWL 2020</span></div>
                            <div className='list-items' onClick={() => history.push(Consts.PAGE_ABOUT_PRO2019)}><span onClick={() => history.push(Consts.PAGE_ABOUT_PRO2019)}> ZWL 2019</span></div>
                        </div>
                    </Col>
                </Row>
            </Container>

            <Container className="mt-5 ">
                <Row className="proFix1">

                    <Col xs={12} sm={6} md={6}>
                        <center>
                            <p className="proTitle">The Biofertilizer composting project  in Kongkham village (01/2022)</p>

                            <img className="proImage" src={P1} />
                        </center>

                    </Col>
                    <Col xs={12} sm={6} md={6}>

                        <p className="proDetail">&nbsp;&nbsp;&nbsp;&nbsp; On January 15 2022, Zero Waste Laos had cooperated with “Volunteer For Community ອາສາສະໝັກເພື່ອຊຸມຊົນ”, agricultural department officer, and village authority to hold a workshop for biofertilizer in Kongkham Village, Xiengkhor District, Houaphanh Province. The workshop aims to introduce eco-friendly fertilizer to the local community. More than 15 representatives from different households had successfully participated with us. At the end of the workshop, we also had a Q&A session to share and exchange our ideas together.
                        </p>
                    </Col>

                </Row>
                <hr />

                <Row className='mt-4' >
                    <Col xs={12} sm={6} md={6}>
                        <center>
                            <p className="proTitle proTitleFix">The Biofertilizer composting project  in Kongkham village (01/2022)</p>

                            <img className="proImage" src={P2} />
                        </center>

                    </Col>
                    <Col xs={12} sm={6} md={6}>

                        <p className="proDetail">&nbsp;&nbsp;&nbsp;&nbsp;On 2/2/2022, the ZWL & TETRAKTYS team organized a joint event on environmental awareness, waste management and composting at a natural tourist site (Dongling), Dongmuang Village, Champhone District, Savannakhet Province. There were participants from many departments: Department of Natural Resources and Environment, Office of Information, Culture and Tourism, Office of Politics, Office of Agriculture and Forestry, Office of Resources and Environment, District Women's Union, Village Council, people and students, a total of 50 people.</p>
                        <p className="proDetail">&nbsp;&nbsp;&nbsp;&nbsp;During the workshop, participants had a lively exchange, discussion, Q&A session, and at the end of the day, the ZWL team demonstrated how to make compost so that participants could try it out in their own households.</p>
                        <p className="proDetail">&nbsp;&nbsp;&nbsp;&nbsp;Apart from this workshop, we also held our workshops in other three villages in Champhone District such as: Nonglamchanh Village, Donedaeng village, and Taleo Village.
                        </p>
                    </Col>
                </Row>

                <hr />

                <Row className='mt-4' >
                    <Col xs={12} sm={6} md={6}>
                        <center>
                            <p className="proTitle proTitleFix">Youth and Environment in Laos (05/2022)</p>

                            <img className="proImage" src={P3} />
                        </center>
                    </Col>
                    <Col xs={12} sm={6} md={6}>

                        <p className="proDetail ">&nbsp;&nbsp;&nbsp;&nbsp; On May 7-8 2022 “Youth and Environment in Laos”  at Le Padaek restaurant on May 7-8 2022. This occasion aims to raise people’s awareness by focusing on youth’s initiative through sharing activities based on a variety of environmental issues namely waste management, climate change, biodiversity, and water pollution. Furthermore, there are also an exhibition, environmental-friendly product showcase, gaming and activity session, and award session for Illustration contest, Song contest, Video contest, and Essay writing contest with honoring judges from various sectors. The event was attended by more than 150 people from various sectors, including the government, the private sector, international organizations, embassies, and youth volunteers.
                        </p>
                    </Col>
                </Row>
                <hr />

                <Row>

                    <Col xs={12} sm={6} md={6}>
                        <center>
                            <p className="proTitle proTitleFix">Seedling Workshop and Launching Youth Climate Action (05/2022)
                            </p>
                            <img className="proImage" src={P4} />
                        </center>
                    </Col>
                    <Col xs={12} sm={6} md={6}>


                        <p className="proDetail ">&nbsp;&nbsp;&nbsp;&nbsp; On May 14, 2022 , Zero Waste Laos held a seedling workshop and tree planting initiative at Sombounsathit Primary School in Vientiane, which was supported by the European Union in Laos and the NPA. The event aims to raise awareness on the importance of trees in climate change mitigation, learning about seedlings, tree care, and the participants also experimented with tree seedlings including soil compaction to plant under the phase 1 of Youth “Climate Action Project” with targeted 260 schools for tree planting across three provinces namely Vientiane Capital, Vientiane, and Bolikhamxay province.

                        </p>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col xs={12} sm={6} md={6}>
                        <center>
                            <p className="proTitle proTitleFix">Seedling Workshop and Launching Youth Climate Action (05/2022)
                            </p>
                            <img className="proImage" src={P5} />
                        </center>
                    </Col>
                    <Col xs={12} sm={6} md={6}>


                        <p className="proDetail">&nbsp;&nbsp;&nbsp;&nbsp; On May 14, 2022 , Zero Waste Laos held a seedling workshop and tree planting initiative at Sombounsathit Primary School in Vientiane, which was supported by the European Union in Laos and the NPA. The event aims to raise awareness on the importance of trees in climate change mitigation, learning about seedlings, tree care, and the participants also experimented with tree seedlings including soil compaction to plant under the phase 1 of Youth “Climate Action Project” with targeted 260 schools for tree planting across three provinces namely Vientiane Capital, Vientiane, and Bolikhamxay province.

                        </p>
                    </Col>

                </Row>



            </Container>





            <Footer />
        </div>
    )
}
export default AboutPro2022