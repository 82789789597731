import { gql } from "apollo-boost";

export const CREATE_CERTIFICATE_CATEGORY= gql`
mutation CreateCategoryCertificate($data: CategoryCertificateWhereCreateInput) {
  createCategoryCertificate(data: $data) {
   id 
  }
}
`


export const UPDATE_CERTIFICATE_CATEGORY= gql`
mutation UpdateCategoryCertificate($where: CategoryCertificateWhereInputId!, $data: CategoryCertificateWhereInput) {
  updateCategoryCertificate(where: $where, data: $data) {
    id
  }
}
`





