import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import useReactRouter from "use-react-router";


import Consts from "../../../consts"
import UploadPhoto from "../../../helpers/UploadPhoto";


import { FaAngleRight } from "react-icons/fa";
import { Modal, Container, Row, Col, Button, Form } from 'react-bootstrap'
import { FaTimes } from "react-icons/fa";
import BgCoverPrograme from "./BgCoverPrograme"



import Footer from "../../../components/footer"

import './Index.css'


function Programe1() {
    const { history } = useReactRouter();
    const {
        namePhoto,
        buttonUploadAndShowPhoto,
        setWidhtPhoto,
        setHeightPhoto,
    } = UploadPhoto();
    const [showFormRegister, setShowFormRegister] = useState(false);

    const handleCloseFormRegister = () => setShowFormRegister(false);
    const handleShowFormRegister = () => setShowFormRegister(true);



    return (
        <div style={{ zIndex: 0 }}>
            <div style={{ height: 150 }} className='hideHeight'></div>




            <Container fluid className="m-0 p-0 mb-5 bgImage-about-pro">
                <Row className="m-0 p-0">
                    <Col md='12' xs={12} className="m-0 p-0 banner-about-pro">
                        {/* <img className='imgBg-about-pro' src={NewsCover} /> */}
                        <BgCoverPrograme />


                        {/* <div className="centered-about-pro">
                            <p className="proMainTitle">Our Program</p>
                        </div> */}

                        <div className="container-list">
                            <div className='list-items action-programe' onClick={() => history.push(Consts.PAGE_PROGRAME1)}>Campaign/ Workshop</div>
                            <div className='list-items' onClick={() => history.push(Consts.PAGE_PROGRAME2)}> Research</div>
                            <div className='list-items' onClick={() => history.push(Consts.PAGE_PROGRAME3)}> Capacity Building</div>
                            <div className='list-items' onClick={() => history.push(Consts.PAGE_PROGRAME4)}> CSR Collaboration </div>
                        </div>
                    </Col>
                </Row>
            </Container>

            <Container className="mt-5 ">
                <Row className="proFix1">

                    <Col xs={12} className="p-5">
                         <p className="proTitle">Awareness Campaign/ Workshop</p>
                         <p className="proDetailTitle">To raise people's awareness on waste issues, climate action, gender equality and SDG. We held workshops and seminars for youths to participate and take part in decision-making activities. There are more than 15 campaigns and workshops since 2019. </p>

                    </Col>
                </Row>
          
            </Container>


            <Footer />
        </div>
    )
}
export default Programe1