import React, { useState } from 'react'
import useReactRouter from "use-react-router";
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { KEY_API_GOOGLEMAP } from '../../../consts'
import { Formik } from 'formik'

import { Breadcrumb, Row, Form, Col } from 'react-bootstrap'
import Consts from "../../../consts"

import { Title, CustomButton } from "../../../common"
import UploadPhoto from "../../../helpers/UploadPhoto";
import CUSD_STAFF from '../../../crud/user';
import { PROVINCE, DRISTRIC } from '../../../consts/addresses'

// import './Index.css'

function ClimateAdd() {
    const { history } = useReactRouter();

    //state
    const [dataDistrictBirth, setDataDistrictBirth] = useState([])
    const [dataDistrict, setDataDistrict] = useState([])
    const [genderData, setGenderData] = useState("MALE");
    const [plantingActivityData, setplantingActivityData] = useState("YES");
    const [intentionData, setIntentionData] = useState("TREE_DONATION");

    const {
        namePhoto,
        namePhotoMany,
        buttonUploadAndShowPhoto,
        buttonUploadAndShowPhotoMany
    } = UploadPhoto();
    const {
        _createClimate,
    } = CUSD_STAFF();
    // function

    const _selectProvinceBirth = (a) => {
        let _selectData = PROVINCE?.filter((item) => item?.pr_name === a)
        let _selectDistrict = DRISTRIC?.filter((item) => _selectData[0]?.pr_id === item?.pr_id)
        setDataDistrictBirth(_selectDistrict)
    }
    const _selectProvince = (a) => {
        let _selectData = PROVINCE?.filter((item) => item?.pr_name === a)
        let _selectDistrict = DRISTRIC?.filter((item) => _selectData[0]?.pr_id === item?.pr_id)
        setDataDistrict(_selectDistrict)
    }

    const [dataLatLong, setDataLatLong] = useState({
        lat: 17.9994624,
        lng: 102.547456,
    })
    const defaultCenter = {
        lat: dataLatLong?.lat, lng: dataLatLong?.lng
    }
    const mapStyles = {
        height: "50vh",
        width: "100%"
    };
    const onMarkerClick = (evt) => {
        setDataLatLong({
            lat: evt?.latLng?.lat(),
            lng: evt?.latLng?.lng(),
        })
    };
    return (
        <div>
            <Breadcrumb style={{ marginTop: 60, fontSize: '12px' }}>
                <Breadcrumb.Item href="#" onClick={() => history.goBack()}>Climate Managerment</Breadcrumb.Item>
                <Breadcrumb.Item active>Add climate</Breadcrumb.Item>
            </Breadcrumb>
            <div style={Consts.MAIN_CARD} >
                <Title text='climate Managerment' />
                <hr></hr>
                <Formik
                    initialValues={{
                        email: "",
                        password: "",
                        ConfirmPassword: "",
                        firstName: "",
                        birthday: "",
                        phone: "",
                        whatsapp: "",
                        ethnicity: "",
                        nationality: "",
                        typeOfDonated: "",
                        occupation: "",
                        company: "",
                        provinceOfBirth: "",
                        districtOfBirth: "",
                        villageOfBirth: "",
                        province: "",
                        district: "",
                        village: "",
                    }}
                    validate={values => {
                        const errors = {}
                        if (!values.email) {
                            errors.email = 'Required';
                        }
                        if (!values.password || !values.ConfirmPassword || values.password !== values.ConfirmPassword) {
                            errors.password = 'Required';
                            errors.ConfirmPassword = 'Required';
                        }
                        if (!values.firstName) {
                            errors.firstName = 'Required';
                        }
                        if (!values.birthday) {
                            errors.birthday = 'Required';
                        }
                        if (!values.phone) {
                            errors.phone = 'Required';
                        }
                        if (!values.whatsapp) {
                            errors.whatsapp = 'Required';
                        }
                        if (!values.ethnicity) {
                            errors.ethnicity = 'Required';
                        }
                        if (!values.nationality) {
                            errors.nationality = 'Required';
                        }
                        if (!values.occupation) {
                            errors.occupation = 'Required';
                        }
                        if (!values.company) {
                            errors.company = 'Required';
                        }
                        if (!values.provinceOfBirth) {
                            errors.provinceOfBirth = 'Required';
                        } else {
                            _selectProvinceBirth(values.provinceOfBirth)
                        }
                        if (!values.districtOfBirth) {
                            errors.districtOfBirth = 'Required';
                        }
                        if (!values.villageOfBirth) {
                            errors.villageOfBirth = 'Required';
                        }
                        if (!values.province) {
                            errors.province = 'Required';
                        } else {
                            _selectProvince(values.province)
                        }
                        if (!values.district) {
                            errors.district = 'Required';
                        }
                        if (!values.village) {
                            errors.village = 'Required';
                        }

                        return errors
                    }}
                    onSubmit={values => {
                        delete values.ConfirmPassword
                        let _data = {
                            ...values,
                            role: "USER",
                            image: namePhoto,
                            images: namePhotoMany,
                            gender: genderData,
                            plantingActivity: plantingActivityData,
                            intention: intentionData,
                            lat: dataLatLong?.lat,
                            long: dataLatLong?.lng,
                        }
                        _createClimate(_data, Consts.PAGE_CLIMATE_LIST)
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting
                        /* and other goodies */
                    }) => (

                        <Row>
                            <Col md={3}>
                                <Form.Label>Upload image <span style={{ color: "red" }}>*</span></Form.Label>
                                {buttonUploadAndShowPhoto()}
                            </Col>
                            <Col md={9}>
                                <div style={{ backgroundColor: "#F6F6F6", color: "#000000", fontSize: '18px', fontWeight: "400", padding: 10, marginBottom: 10 }}>
                                    Create User and Password
                                </div>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Email <span style={{ color: "red" }}>*</span></Form.Label>
                                    <Form.Control size='lg'
                                        type="text"
                                        name="email"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.email}
                                        className='input' placeholder="Please Name and surname" />
                                    <div style={{ color: 'red' }}>
                                        <p>{errors.email && touched.email && errors.email}</p>
                                    </div>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Password <span style={{ color: "red" }}>*</span></Form.Label>
                                    <Form.Control size='lg'
                                        type="text"
                                        name="password"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.password}
                                        className='input' placeholder="Please Name and Password" />
                                    <div style={{ color: 'red' }}>
                                        <p>{errors.password && touched.password && errors.password}</p>
                                    </div>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Confirm Password <span style={{ color: "red" }}>*</span></Form.Label>
                                    <Form.Control size='lg'
                                        type="text"
                                        name="ConfirmPassword"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.ConfirmPassword}
                                        className='input' placeholder="Please Name and surname" />
                                    <div style={{ color: 'red' }}>
                                        <p>{errors.ConfirmPassword && touched.ConfirmPassword && errors.ConfirmPassword}</p>
                                    </div>
                                </Form.Group>
                                <div style={{ backgroundColor: "#F6F6F6", color: "#000000", fontSize: '18px', fontWeight: "400", padding: 10, marginBottom: 10 }}>
                                    General information
                                </div>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Name and surname <span style={{ color: "red" }}>*</span></Form.Label>
                                    <Form.Control size='lg'
                                        type="text"
                                        name="firstName"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.firstName}
                                        className='input' placeholder="Please Name and surname" />
                                    <div style={{ color: 'red' }}>
                                        <p>{errors.firstName && touched.firstName && errors.firstName}</p>
                                    </div>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Gender <span style={{ color: "red" }}>*</span></Form.Label>
                                    <br />
                                    <div className="custom-control custom-radio custom-control-inline">

                                        <Form.Check
                                            type="radio"
                                            id="MALE"
                                            name="gender"
                                            defaultChecked
                                            onChange={() => setGenderData("MALE")}
                                            style={{ width: '20px !important', height: "20px !important" }}

                                        />
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <Form.Check.Label>Male</Form.Check.Label>
                                    </div>
                                    <div className="custom-control custom-radio custom-control-inline">

                                        <Form.Check
                                            type="radio"
                                            id="FEMALE"
                                            name="gender"
                                            defaultChecked
                                            onChange={() => setGenderData("FEMALE")}
                                            style={{ width: '20px !important', height: "20px !important" }}

                                        />
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <Form.Check.Label>FEMALE</Form.Check.Label>
                                    </div>

                                    <div className="custom-control custom-radio custom-control-inline">

                                        <Form.Check
                                            type="radio"
                                            id="LGBTQ+"
                                            name="gender"
                                            defaultChecked
                                            onChange={() => setGenderData("LGBTQ")}
                                            style={{ width: '20px !important', height: "20px !important" }}

                                        />
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <Form.Check.Label>LGBTQ+</Form.Check.Label>

                                    </div>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Date of birth <span style={{ color: "red" }}>*</span></Form.Label>
                                    <Form.Control size='lg'
                                        className='input'
                                        type="date"
                                        name="birthday"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.birthday}
                                    />
                                    <div style={{ color: 'red' }}>
                                        <p>{errors.birthday && touched.birthday && errors.birthday}</p>
                                    </div>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Phone number <span style={{ color: "red" }}>*</span></Form.Label>
                                    <Form.Control size='lg'
                                        className='input'
                                        type="text"
                                        name="phone"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.phone}
                                        placeholder="20xxxxxxx" />
                                    <div style={{ color: 'red' }}>
                                        <p>{errors.phone && touched.phone && errors.phone}</p>
                                    </div>
                                    <Form.Label>Whatsapp <span style={{ color: "red" }}>*</span></Form.Label>
                                    <Form.Control size='lg'
                                        className='input'
                                        type="text"
                                        name="whatsapp"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.whatsapp}
                                        placeholder="20xxxxxxx" />
                                    <div style={{ color: 'red' }}>
                                        <p>{errors.whatsapp && touched.whatsapp && errors.whatsapp}</p>
                                    </div>
                                </Form.Group>


                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Ethnicity In case you are a foreigner, please type "0" <span style={{ color: "red" }}>*</span></Form.Label>
                                    <Form.Control size='lg'
                                        className='input'
                                        type="text"
                                        name="ethnicity"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.ethnicity}
                                        placeholder="Please input ethnicity" />
                                    <div style={{ color: 'red' }}>
                                        <p>{errors.ethnicity && touched.ethnicity && errors.ethnicity}</p>
                                    </div>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label> Nationality<span style={{ color: "red" }}>*</span></Form.Label>
                                    <Form.Control size='lg'
                                        className='input'
                                        as="select"
                                        name="nationality"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.nationality}
                                        style={{ fontSize: "16px" }}>
                                        <option value='' selected disabled>Select the nationality</option>
                                        <option value='College'>College</option>
                                        <option value="Bachelor's degree">Bachelor's degree</option>
                                        <option value="Master's degree">Master's degree</option>
                                        <option value='PhD'>PhD</option>
                                        <option value='Other'>Other</option>
                                    </Form.Control>
                                    <div style={{ color: 'red' }}>
                                        <p>{errors.nationality && touched.nationality && errors.nationality}</p>
                                    </div>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Occupation <span style={{ color: "red" }}>*</span></Form.Label>
                                    <Form.Control size='lg'
                                        name="occupation"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.occupation}
                                        className='input' as="select" style={{ fontSize: "16px" }} >
                                        <option value='' selected disabled>Select the Occupation</option>
                                        <option value='Student'>Student</option>
                                        <option value="Officer">Officer</option>
                                        <option value='Other'>Other</option>
                                    </Form.Control>
                                    <div style={{ color: 'red' }}>
                                        <p>{errors.occupation && touched.occupation && errors.occupation}</p>
                                    </div>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Company/ Organization/ Institution <span style={{ color: "red" }}>*</span></Form.Label>
                                    <Form.Control size='lg'
                                        type="text"
                                        name="company"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.company}
                                        className='input'
                                        placeholder="Please input your Company/Organization/Institution" />
                                    <div style={{ color: 'red' }}>
                                        <p>{errors.company && touched.company && errors.company}</p>
                                    </div>
                                </Form.Group>

                                <div style={{ backgroundColor: "#F6F6F6", color: "#000000", fontSize: '18px', fontWeight: "400", padding: 10, marginBottom: 10 }}>
                                    Brith place information
                                </div>

                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Province of birth <span style={{ color: "red" }}>*</span></Form.Label>
                                    <Form.Control size='lg'
                                        name="provinceOfBirth"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.provinceOfBirth}
                                        className='input' as="select"
                                        style={{ fontSize: "16px" }}
                                    >
                                        <option value='' selected disabled >Select the Province</option>
                                        {PROVINCE?.map((item, index) =>
                                            <option value={item?.pr_name} key={index + 1} >{item?.pr_name}</option>
                                        )}
                                    </Form.Control>
                                    <div style={{ color: 'red' }}>
                                        <p>{errors.provinceOfBirth && touched.provinceOfBirth && errors.provinceOfBirth}</p>
                                    </div>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>District of birth <span style={{ color: "red" }}>*</span></Form.Label>
                                    <Form.Control size='lg'
                                        name="districtOfBirth"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.districtOfBirth}
                                        className='input'
                                        as="select"
                                        style={{ fontSize: "16px" }}>
                                        <option value='' selected disabled>Select the District</option>
                                        {dataDistrictBirth?.map((item, index) =>
                                            <option value={item?.dr_name} key={index + 1} >{item?.dr_name}</option>
                                        )}
                                    </Form.Control>
                                    <div style={{ color: 'red' }}>
                                        <p>{errors.districtOfBirth && touched.districtOfBirth && errors.districtOfBirth}</p>
                                    </div>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Village of birth <span style={{ color: "red" }}>*</span></Form.Label>
                                    <Form.Control size='lg'
                                        type="text"
                                        name="villageOfBirth"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.villageOfBirth}
                                        className='input'
                                        placeholder="Please input your Village of birth" />
                                    <div style={{ color: 'red' }}>
                                        <p>{errors.villageOfBirth && touched.villageOfBirth && errors.villageOfBirth}</p>
                                    </div>
                                </Form.Group>
                                <div style={{ backgroundColor: "#F6F6F6", color: "#000000", fontSize: '18px', fontWeight: "400", padding: 10, marginBottom: 10 }}>
                                    Plant donation
                                </div>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Would you join us in planting activity ? <span style={{ color: "red" }}>*</span></Form.Label>
                                    <br />
                                    <div className="custom-control custom-radio custom-control-inline">
                                        <Form.Check
                                            type="radio"
                                            id="YES"
                                            name="plantingActivity"
                                            defaultChecked
                                            onClick={() => setplantingActivityData("YES")}
                                            style={{ width: '20px !important', height: "20px !important" }}
                                        />
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <Form.Check.Label>Yes</Form.Check.Label>
                                    </div>
                                    <div className="custom-control custom-radio custom-control-inline">
                                        <Form.Check
                                            type="radio"
                                            id="NO"
                                            name="plantingActivity"
                                            onClick={() => setplantingActivityData("NO")}
                                            style={{ width: '20px !important', height: "20px !important" }}
                                        />
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <Form.Check.Label>No</Form.Check.Label>
                                    </div>
                                    <div className="custom-control custom-radio custom-control-inline">
                                        <Form.Check
                                            type="radio"
                                            id="MAYBE"
                                            name="plantingActivity"
                                            onClick={() => setplantingActivityData("MAYBE")}
                                            style={{ width: '20px !important', height: "20px !important" }}
                                        />
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <Form.Check.Label>Maybe</Form.Check.Label>
                                    </div>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Your intention to join us ? <span style={{ color: "red" }}>*</span></Form.Label>
                                    <br />
                                    <div className="custom-control custom-radio custom-control-inline">
                                        <Form.Check
                                            type="radio"
                                            id="TREE_DONATION"
                                            name="intention"
                                            defaultChecked
                                            onClick={() => setIntentionData("TREE_DONATION")}
                                            style={{ width: '20px !important', height: "20px !important" }}
                                        />
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <Form.Check.Label>Tree donation</Form.Check.Label>
                                    </div>
                                    <div className="custom-control custom-radio custom-control-inline">
                                        <Form.Check
                                            type="radio"
                                            id="PLANTING_TREE"
                                            name="intention"
                                            onClick={() => setIntentionData("PLANTING_TREE")}
                                            style={{ width: '20px !important', height: "20px !important" }}
                                        />
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <Form.Check.Label>Planting tree</Form.Check.Label>
                                    </div>
                                    <div className="custom-control custom-radio custom-control-inline">
                                        <Form.Check
                                            type="radio"
                                            id="BOTH"
                                            name="intention"
                                            onClick={() => setIntentionData("BOTH")}
                                            style={{ width: '20px !important', height: "20px !important" }}
                                        />
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <Form.Check.Label>Both</Form.Check.Label>
                                    </div>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Type of donated seedling. Ex: Mango seedling, tamarind seedling...</Form.Label>
                                    <Form.Control size='lg'
                                        name="typeOfDonated"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.typeOfDonated}
                                        className='input'
                                        type="text" placeholder="Please explain" />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Number of seedlings donated</Form.Label>
                                    <Form.Control size='lg'
                                        name="numberDonated"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.numberDonated}
                                        className='input' type="number" placeholder="Please explain" />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Upload a picture of yourself with the trees you plant</Form.Label>
                                    {/* <Form.Control size='lg'
                                        name="images"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.images}
                                        className='input'
                                        type="file"
                                        placeholder="Please explain" /> */}
                                    {buttonUploadAndShowPhotoMany()}
                                </Form.Group>
                                <div className='col-12' style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div className='col-4'>
                                        <div style={{ backgroundColor: "#F6F6F6", color: "#000000", fontSize: '18px', fontWeight: "400", padding: 10, marginBottom: 10 }}>
                                            The location of planting
                                        </div>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Province <span style={{ color: "red" }}>*</span></Form.Label>
                                            <Form.Control size='lg'
                                                name="province"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.province}
                                                className='input' as="select"
                                                style={{ fontSize: "16px" }}
                                            >
                                                <option value='' selected disabled >Select the Province</option>
                                                {PROVINCE?.map((item, index) =>
                                                    <option value={item?.pr_name} key={index + 1} >{item?.pr_name}</option>
                                                )}
                                            </Form.Control>
                                            <div style={{ color: 'red' }}>
                                                <p>{errors.province && touched.province && errors.province}</p>
                                            </div>
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>District<span style={{ color: "red" }}>*</span></Form.Label>
                                            <Form.Control size='lg'
                                                name="district"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.district}
                                                className='input'
                                                as="select"
                                                style={{ fontSize: "16px" }}>
                                                <option value='' selected disabled>Select the District</option>
                                                {dataDistrict?.map((item, index) =>
                                                    <option value={item?.dr_name} key={index + 1} >{item?.dr_name}</option>
                                                )}
                                            </Form.Control>
                                            <div style={{ color: 'red' }}>
                                                <p>{errors.district && touched.district && errors.district}</p>
                                            </div>
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Village <span style={{ color: "red" }}>*</span></Form.Label>
                                            <Form.Control size='lg'
                                                name="village"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.village}
                                                className='input' type="text" placeholder="Please explain" />
                                        </Form.Group>
                                    </div>
                                    <div className='col-8'>
                                        <LoadScript
                                            googleMapsApiKey={KEY_API_GOOGLEMAP}>
                                            <GoogleMap
                                                mapContainerStyle={mapStyles}
                                                zoom={12}
                                                center={defaultCenter}
                                                onClick={onMarkerClick}
                                            >
                                                <Marker position={{ lat: dataLatLong?.lat, lng: dataLatLong?.lng }} />
                                            </GoogleMap>
                                        </LoadScript>
                                    </div>
                                </div>
                                <div style={{ height: 50 }}></div>
                                <center>
                                    <CustomButton cancel title='Cancel' style={{ width: "199px", height: '44px', border: 0 }} onClick={() => history.goBack()} />
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <CustomButton addIcon title='Add' style={{ width: "199px", height: '44px' }} onClick={() => handleSubmit()} />
                                </center>
                            </Col>
                        </Row>
                    )}
                </Formik>
            </div>
        </div>
    )
}
export default ClimateAdd

