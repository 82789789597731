import React from 'react';
import { Card, Image } from "react-bootstrap";
import Consts, { USER_KEY } from "../../../consts"



export class CertificateComponentForm extends React.PureComponent {
    render() {
        let _data = this?.props?.data;


        return (
            <div
                style={{
                    // color: "#5C5C5C",
                    width: "100%",
                    fontSize: 14,
                    padding: "40px",
                }}
            >
                <div style={{position: "relative"}}>
                     
                        <p style={{color:"#000",fontSize:30,position: "absolute",top: "53%",left: "35%"}}>
                            {_data?.userInfo?.user?.firstName}
                        </p>

                        <p style={{color:"#000",fontSize:20,position: "absolute",top: "67%",left: "39%"}}>
                            {_data?.total}
                        </p>
                        <p style={{color:"#000",fontSize:20,position: "absolute",top: "76%",left: "35%"}}>
                            {_data?.total*15}
                        </p>
                   
                     <img src={Consts.URL_FOR_SHOW_PHOTO + _data?.carImage?.imageProfile} style={{width: "100%"}}/>

                </div>


               
            </div>
        );
    }
}