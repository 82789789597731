import React, { useState, useEffect}from 'react'
import useReactRouter from "use-react-router";

import { Breadcrumb, Form, Table, Image,Button,Modal } from 'react-bootstrap'
import { FaEye, FaEdit, FaTrash } from "react-icons/fa";
import Consts from "../../../consts"
import {Title, CustomButton} from "../../../common"
import { useLazyQuery } from '@apollo/react-hooks'
import { CERTIFICATES } from "../../../apollo/certificate/Query"
import { dateTimeLao } from "../../../helpers/Helper"

import CUSD_CERTIFICATE from '../../../crud/certificate';


function CertificateList() {
    const { history } = useReactRouter();
    const [toppicName, setTopicName] = useState('')
    const [isOpenMadal, setIsOpenMadal] = useState(false);
    const [deleteData, setDeleteData] = useState();
    const [getCertificate, { data }] = useLazyQuery(CERTIFICATES, {
        fetchPolicy: "network-only",
                variables: {
                    where: {
                        topic: toppicName === '' ? undefined : toppicName,
                       
                    }
                }
        }
    );

    const {
        _deleteCertificate,
    } = CUSD_CERTIFICATE();

    //function
// ======>
    
    useEffect(() => {
        getCertificate()
    }, [])


//function function
const onDeleteConfirm = (data) => {
    setDeleteData(data)
    setIsOpenMadal(true)
}

const handleCloseDelete = () => {setIsOpenMadal(false)}





    return (
        <div>
            <Breadcrumb style={{ marginTop: 60, fontSize: '12px' }}>
                <Breadcrumb.Item active>E-Cert Management</Breadcrumb.Item>
            </Breadcrumb>

            <div style={Consts.MAIN_CARD} >
                <Title text='E-Cert Management' />

                <Form.Group className="mt-5">
                    <Form.Control size='lg' type="text" placeholder="Searching by topic..." onChange={(e) => setTopicName(e?.target?.value)} 
                    style={{ height: '40px', fontSize: '14px'}} />
                </Form.Group>

                <div style={{ display: "flex", justifyContent: "space-between", marginTop: 20, marginBottom: 20 }}>
                    <h4>Show {data?.certificates?.data.length} List</h4>
                    {/* <div>
                      
                        <CustomButton addIcon title='Add Certificate' onClick={() => history.push(Consts.PAGE_CERTIFICATE_ADD)}/>

                    </div> */}
                </div>
                <Table striped>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Picture</th>
                            <th>Certificatie category</th>
                            <th>Topic</th>
                            <th>Created At</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.certificates?.data?.map((item,index)=>
                            <tr key={index}>
                                <td>{index+1}</td>
                            <td>
                                    <Image src={item?.imageProfile ? Consts.URL_FOR_SHOW_PHOTO + item?.imageProfile :"https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Circle-icons-profile.svg/1024px-Circle-icons-profile.svg.png"}
                                    style={{ width: 40, height: 40 }}
                                />
                            </td>
                                <td>{item?.categoryCertificateId?.name}</td>
                                <td>{item?.topic}</td>
                                <td>{dateTimeLao(item?.createdAt)}</td>
                            <td>
                                <FaEye style={{ color: '#00A991',cursor:'pointer', fontSize: '14px'}} onClick={()=> history.push(Consts.PAGE_CERTIFICATE_DETAIL + '/' + item?.id,item)} />
                                &nbsp;&nbsp;&nbsp;
                                <FaEdit style={{ color: '#00A991',cursor:'pointer', fontSize: '14px' }} onClick={()=> history.push(Consts.PAGE_CERTIFICATE_UPADTE, item)} />
                                {/* &nbsp;&nbsp;&nbsp; */}
                                {/* <FaTrash style={{ color: 'red',cursor:'pointer', fontSize: '14px' }} onClick={() => onDeleteConfirm(item)} /> */}
                            </td>
                        </tr>
                        
                        )}
                    </tbody>
                </Table>


            </div>

            <Modal show={isOpenMadal} onHide={() => setIsOpenMadal(false)}>
                <Modal.Body>
                    <p style={{ fontSize: "18px", fontWeight: "bold" }}>Are you sure you want to delete?</p>
                    <p style={{ fontSize: "16px", color: "#4F5E71" }}>Please ensure and then confirm!</p>
                    <div style={{ float: "right", marginTop: 10 }}>
                        <Button variant="secondary" onClick={() => setIsOpenMadal(false)} style={{ width: 90 }}>
                            Cancel
                        </Button>
                        &nbsp;&nbsp;&nbsp;
                        <Button variant="danger" 
                        onClick={() => _deleteCertificate(deleteData ,handleCloseDelete)}
                         style={{ width: 90 }}>
                            Confirm
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>


        </div>
    )
}
export default CertificateList