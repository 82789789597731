import { gql } from "apollo-boost";


export const USER = gql`
query User($where: UserWhereInputId!) {
  user(where: $where) {
    id
    firstName
    email
    educationLevel
    userId
    role
    englishProficiency
    qccupation
    workplace
    districtOfBirth
    provinceOfBirth
    villageOfBirth
    volunteerBefore
    volunteerExplain
    knowAboutUs
    everJoinedZeroWaste
    tellUs
    skillsAndProfession
    improveYourself
    expectZeroWaste
    appover
    ethnicity
    nationality
    occupation
    company
    province
    district
    village
    gender
    birthday
    image
    phone
    whatsapp
    isDeleted
    detail
    note
  }
  }

`