import React, { useState } from 'react'
import useReactRouter from "use-react-router";


import Consts from "../../../consts"


import { Container, Row, Col, Button, ListGroup } from 'react-bootstrap'
import { FaCircle } from "react-icons/fa";

import M2 from "../../../image/mission/m2.png"
import M1 from "../../../image/mission/m1.png"
import M4 from "../../../image/mission/m4.png"
import M5 from "../../../image/mission/m5.png"
import M3 from "../../../image/mission/m3.png"
import Bgprograme from "../../../image/programe/bgPrograme.png"
import NewsCover from "../../../image/blog/NewsCover.png"


import AboutImg1 from "../../../image/about/aboutImg1.png"
import AboutImg2 from "../../../image/about/aboutImg2.png"
import AboutImg3 from "../../../image/about/aboutImg3.png"
import AboutImg4 from "../../../image/about/aboutImg4.png"
import AboutImg5 from "../../../image/about/aboutImg5.png"
import AboutImg6 from "../../../image/about/aboutImg6.png"
import AboutImg7 from "../../../image/about/aboutImg7.png"

import Climatechange from "../../../image/about/Climatechange.JPG"
import IT from "../../../image/about/IT.png"
import management from "../../../image/about/management.png"
import SDGS from "../../../image/about/SDGS.png"
import Youth from "../../../image/about/Youth.JPG"
import Solidwastemanagement from "../../../image/about/Solidwastemanagement.JPG"



import Footer from "../../../components/footer"

import './Index.css'


function About() {
    const { history } = useReactRouter();

    const pro1 = () => {
        console.log("ddd")
    }

    return (
        <div style={{ zIndex: 0 }}>
            <div className='hideHeight'></div>
            <Container fluid className="m-0 p-0 mb-5 bgImage-about-pro">
                <Row className="m-0 p-0">
                    <Col md='12' xs={12} className="m-0 p-0 banner-about-pro">
                        <img className='imgBg-about-pro' src={NewsCover} />

                        <div className="centered-about-pro">
                            <p className="proMainTitle">About Us</p>
                        </div>
                        <div className="container-list">
                            <div className='list-items action-programe' onClick={() => history.push(Consts.PAGE_ABOUT)}> <span onClick={() => history.push(Consts.PAGE_ABOUT)} >Story</span></div>
                            <div className='list-items ' onClick={() => history.push(Consts.PAGE_ABOUT_PRO2022)}> <span onClick={() => history.push(Consts.PAGE_ABOUT_PRO2022)}>ZWL 2022</span></div>
                            <div className='list-items' onClick={() => history.push(Consts.PAGE_ABOUT_PRO2021)}><span onClick={() => history.push(Consts.PAGE_ABOUT_PRO2021)}> ZWL 2021</span></div>
                            <div className='list-items' onClick={() => history.push(Consts.PAGE_ABOUT_PRO2020)}><span onClick={() => history.push(Consts.PAGE_ABOUT_PRO2020)} > ZWL 2020</span></div>
                            <div className='list-items' onClick={() => history.push(Consts.PAGE_ABOUT_PRO2019)}><span onClick={() => history.push(Consts.PAGE_ABOUT_PRO2019)}> ZWL 2019</span></div>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container style={{ zIndex: 0 }}>
                <Row className='aboutTop'>
                    <Col xs={12} md={12}>
                        <div className='title' >
                            <h2 id="bigTitle" ><span className="title-Active">OUR</span> Story</h2>
                            <p className="titleDetail" style={{ textAlign: "justify", textJustify: "inter-word" }}>&nbsp;&nbsp;&nbsp;&nbsp; ZWL was initially established in 2019 as a volunteering group for promoting sustainable waste management through awareness campaigns via social media platforms in Vientiane, Lao PDR. Our first event was carried out in partnership with UNV on Mekong River Volunteering Action with more than 300 participants. ZWL organized the event with support from many development partners. We are recognized as a youth-led organization on environmental issues. Therefore, various sectors reach out to us for collaboration, mainly development partners, INGOs, NGOs, government, and private sectors, which are more than 20 partners who have cooperated with us.  ZWL assists many parts of the country to advance their environment and sustainable development goals through the provision of capacity-building, knowledge sharing, policy advice, and research in four thematic areas: Waste Management, Climate Change, Sustainability, Youth Development and gender.  Our competitive advantages include, but not limited to:</p>
                            <div className='storyUl' style={{
                                listStyleType: "disc",
                                marginLeft: "20px",
                                textAlign: "left",
                                fontWeight: "400",
                                fontSize: "16px",
                                color: "#000000"
                            }}>
                                <p style={{ color: "#000000" }}>- Nearly three years of experience in operating landmark environment initiatives in Lao PDR.</p>
                                <p style={{ color: "#000000" }}>- One-stop Organization for various Environmental Issues.</p>
                                <p style={{ color: "#000000" }}>- Lao PDR presence - supporting various schools and communities on waste management in Laos, and adaptation to climate change, leading green and sustainability initiatives.</p>
                                <p style={{ color: "#000000" }}>- Rich Partnership, Resources, and Networking.</p>
                            </div>
                        </div>
                    </Col>

                    <Col xs={12} md={12} className="p-5">
                        <center>
                            <iframe width="853" height="500" src="https://www.youtube.com/embed/L0ibWJOcjDM" title="YouTube video player" className="videoAbout" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </center>
                    </Col>
                    <Col xs={12} md={12} className="">
                        <div className='title HeadMission'>
                            <h2 className='bigTitle' id='ourMission'><span className="title-Active">OUR</span> Mission</h2>
                        </div>
                    </Col>
                </Row>
                <div style={{ height: 50 }} ></div>

                <Row className="">
                    <Col xs={12} sm={6} md={6}>
                        <p className="titleMission mission1" >Mission 1</p>
                        <p className="MissionDetail">Contribute towards the achievement of sustainable waste management by promoting reducing, reusing, recycling, composting at communities’ level through 3Rs and circular economy waste innovations. </p>
                    </Col>
                    <Col xs={12} sm={6} md={6}>
                        <img src={M2} className="imgMission" />
                    </Col>
                </Row>


                <Row className="mt-5">
                    <Col xs={12} sm={6} md={6} lg={6} xl={6} className='missionDesktop'>
                        <center>
                            <img src={M1} className="imgMission2" />
                        </center>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={6} xl={6} className='missionDesktop'>
                        <p className="titleMission smallTitle">Mission 2 </p>
                        <p className="MissionDetail">Enhance the capacity of young people and supporting them to be sustainability leader by engaging them in environmental program of ZWL  </p>
                    </Col>
                </Row>


                {/* <Row className="mt-5 missionDesktop">
                    <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                        <center>
                            <img src={M1} className="imgMission2" />
                        </center>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                        <p className="titleMission">Mission 2 </p>
                        <p className="MissionDetail">Enhance the capacity of young people and supporting them to be sustainability leader by engaging them in environmental program of ZWL  </p>
                    </Col>
                </Row> */}

                <Row className="mt-5 missionMobile">
                    <Col xs={12} sm={6} md={6} lg={6}>
                        <p className="titleMission smallTitle">Mission 2</p>
                        <p className="MissionDetail">Enhance the capacity of young people and supporting them to be sustainability leader by engaging them in environmental program of ZWL  </p>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={6}>
                        <center>
                            <img src={M1} className="imgMission2" />
                        </center>
                    </Col>

                </Row>

                <Row className="mt-5">
                    <Col xs={12} sm={6} md={6}>
                        <p className="titleMission">Mission 3</p>
                        <p className="MissionDetail">Support young girl or women for gender equality and provide them opportunity for skill development </p>
                    </Col>
                    <Col xs={12} sm={6} md={6}>
                        <center>
                            <img src={M3} className="imgMission3" />
                        </center>
                    </Col>
                </Row>

                <Row className="mt-5 mb-5 ">
                    <Col xs={12} sm={6} md={6} className='missionDesktop'>
                        <center>
                            <img src={M5} className="imgMission4" />
                        </center>
                    </Col>
                    <Col xs={12} sm={6} md={6} className='missionDesktop'>
                        <p className="titleMission smallTitle">Mission 4</p>
                        <p className="MissionDetail">Set up role model school and build sound knowledge and capacity base in schools on sustainable waste management in every province of Laos PDR.  </p>
                    </Col>
                </Row>

                <Row className="mt-5 mb-5 missionMobile">
                    <Col xs={12} sm={6} md={6}>
                        <p className="titleMission smallTitle">Mission 4</p>
                        <p className="MissionDetail">Set up role model school and build sound knowledge and capacity base in schools on sustainable waste management in every province of Laos PDR.  </p>
                    </Col>
                    <Col xs={12} sm={6} md={6}>
                        <center>
                            <img src={M5} className="imgMission4" />
                        </center>
                    </Col>

                </Row>


                <Row className="mt-5 mb-5">

                    <Col xs={12} sm={6} md={6}>
                        <p className="titleMission smallTitle">Mission 5</p>
                        <p className="MissionDetail">Support communities where there is vulnerable area of climate change and build the capacity of adaptation and system for readiness.  </p>
                    </Col>
                    <Col xs={12} sm={6} md={6}>
                        <center>
                            <img src={M4} className="imgMission4" />
                        </center>
                    </Col>
                </Row>
            </Container>

            <Container fluid className='missionDesktop'>
                <Row>
                    <Col xs={12} md={6} className="p-5">
                        <img src={AboutImg1} className="volunteerImg" style={{ borderRadius: "16px" }} />
                    </Col>

                    <Col xs={12} md={6} className="p-5">
                        <h1 className='bigTitle' id='ourTeam'><span className="title-Active">OUR</span> Teams</h1>
                        <p className='teamDetail'>
                            ZWL has 45 core team members and 100 supporting members. It divided into 2 team and 4 programs following:
                        </p>

                        <div className="form-check">
                            <label className="form-check-label" htmlFor="defaultCheck1">
                                <FaCircle style={{ color: "#00A991" }} /> &nbsp;&nbsp;&nbsp; IT and Communication
                            </label>
                        </div>

                        <div className="form-check">
                            <label className="form-check-label" htmlFor="defaultCheck1">
                                <FaCircle style={{ color: "#00A991" }} /> &nbsp;&nbsp;&nbsp; Management
                            </label>
                        </div>

                        <div className="form-check">
                            <label className="form-check-label" htmlFor="defaultCheck1">
                                <FaCircle style={{ color: "#00A991" }} /> &nbsp;&nbsp;&nbsp; Climate Change
                            </label>
                        </div>


                        <div className="form-check">
                            <label className="form-check-label" htmlFor="defaultCheck1">
                                <FaCircle style={{ color: "#00A991" }} /> &nbsp;&nbsp;&nbsp; Waste Management
                            </label>
                        </div>

                        <div className="form-check">
                            <label className="form-check-label" htmlFor="defaultCheck1">
                                <FaCircle style={{ color: "#00A991" }} /> &nbsp;&nbsp;&nbsp; Sustainability
                            </label>
                        </div>

                        <div className="form-check">
                            <label className="form-check-label" htmlFor="defaultCheck1">
                                <FaCircle style={{ color: "#00A991" }} /> &nbsp;&nbsp;&nbsp;  Youth Development and Gender
                            </label>
                        </div>

                    </Col>
                </Row>
            </Container>

            <Container fluid className='missionMobile'>
                <Row>

                    <Col xs={12} md={6} className="p-5">
                        <h1 style={{ fontSize: "20px" }} id='team'><span className="title-Active">OUR</span> Teams</h1>
                        <p className='teamDetail'>
                            ZWL has 45 core team members and 100 supporting members. It divided into 2 team and 4 programs following:
                        </p>

                        <div className="form-check">
                            <label className="form-check-label listTeam" htmlFor="defaultCheck1">
                                <FaCircle style={{ color: "#00A991" }} /> &nbsp;&nbsp;&nbsp; IT and Communication
                            </label>
                        </div>

                        <div className="form-check">
                            <label className="form-check-label listTeam" htmlFor="defaultCheck1">
                                <FaCircle style={{ color: "#00A991" }} /> &nbsp;&nbsp;&nbsp; Management
                            </label>
                        </div>

                        <div className="form-check">
                            <label className="form-check-label listTeam" htmlFor="defaultCheck1">
                                <FaCircle style={{ color: "#00A991" }} /> &nbsp;&nbsp;&nbsp; Climate Change
                            </label>
                        </div>


                        <div className="form-check">
                            <label className="form-check-label listTeam" htmlFor="defaultCheck1">
                                <FaCircle style={{ color: "#00A991" }} /> &nbsp;&nbsp;&nbsp; Waste Management
                            </label>
                        </div>

                        <div className="form-check">
                            <label className="form-check-label listTeam" htmlFor="defaultCheck1">
                                <FaCircle style={{ color: "#00A991" }} /> &nbsp;&nbsp;&nbsp; Sustainability
                            </label>
                        </div>

                        <div className="form-check">
                            <label className="form-check-label listTeam" htmlFor="defaultCheck1">
                                <FaCircle style={{ color: "#00A991" }} /> &nbsp;&nbsp;&nbsp;  Youth Development and Gender
                            </label>
                        </div>

                    </Col>
                    <Col xs={12} md={6} className="p-5">
                        <img src={AboutImg1} className="volunteerImg" style={{ borderRadius: "16px" }} />
                    </Col>
                </Row>
            </Container>


            <Container>
                <Row>
                    <Col xs={12} sm={12} md={12}>
                        <h1 style={{ color: "#000000", fontSize: "18px" }}>Climate Change Team</h1>
                    </Col>
                    <Col xs={12} sm={12} md={12}>
                        <img src={Climatechange} className="volunteerImg" style={{ borderRadius: "16px", height: "auto" }} />
                    </Col>
                    <Col xs={12} sm={12} md={12} className='mt-5' style={{ textAlign: 'left', color: "#000000" }}>

                        <p className='nornalText'>
                            &nbsp;&nbsp;&nbsp;&nbsp;Nowadays, climate change does not seem to be improving at all and we still find behaviors that are degrading the environment every day, such as deforestation, forest fires, burning garbage, wasteful energy use, and many other things that we do in our daily lives that are detrimental to the world.
                        </p>
                        <p className='nornalText'>
                            As an environmental volunteer under Zero Waste Laos, we want to be a communicator and be a voice for local people to learn about Climate Change and change their behavior for the world.
                        </p>
                        <p className='nornalText'>
                            There are 6 people core team in Climate Change team:
                        </p>

                        <ol className='nornalText'>
                            <li>1. Miss Chindaphone PHALICHAN</li>
                            <li>2. Mr Chhunheng RIT</li>
                            <li>3. Mr Phetchamphone KETTAVONG</li>
                            <li>4. Miss Nalinthone SETTHACHAK</li>
                            <li>5. Ms Soudalath KHAMSINGSAVATH</li>
                            <li>6. Miss Salackchay VOLASAY</li>
                            <li>7. Mr Deth IENLISAK</li>
                            <li>8. Miss Palamee XAIYAVONGKHAMDY</li>
                            <li>9. Miss Soudalath PHOSALATH</li>
                            <li>10. Miss Souphaphone LATHSAVONG</li>
                            <li>11. Mr Tounar PHETLAVANH</li>
                            <li>12. Miss Bovilay XAIYALATH</li>
                            <li>13. Miss Palisa PHAENGNUANTAN</li>
                            <li>14. Miss Ninar KIETTAVONG</li>
                            <li>15. Miss Phonevilai SIHALATH</li>





                        </ol>
                    </Col>
                </Row>
            </Container>


            <Container>
                <Row className="mt-5">
                    <Col xs={12} sm={12} md={12}>
                        <h1 style={{ color: "#000000", fontSize: "18px" }}>Solid Waste Management</h1>
                    </Col>
                    <Col xs={12} sm={12} md={12}>
                        <img src={Solidwastemanagement} className="volunteerImg" style={{ borderRadius: "16px", height: "auto" }} />
                    </Col>
                    <Col xs={12} sm={12} md={12} className='mt-5' style={{ textAlign: 'left', color: "#000000" }}>

                        <p className='nornalText'>
                            &nbsp;&nbsp;&nbsp;&nbsp;   Our team is a team that provides knowledge on waste separation and environmental protection and waste management, such as disseminating knowledge on correct waste management and proper waste separation, and raising awareness among children.  And the new generation to teach them how to manage household waste and reduce the amount of plastic waste each day to become another voice for society and the environment.  Recycle too, and tell the kids that some waste can be used again.  No need to wear once  In the future, we hope that people in our community will take responsibility and work together to keep the country clean for better living.
                        </p>

                        <p className='nornalText'>
                            Core team member of Solid Waste Management Team:
                        </p>

                        <ol className='nornalText'>
                            <li> 1. Ms Chanthanom KEOOUNKHAM</li>
                            <li> 2. Miss Jintana XONETHAMMAVONG</li>
                            <li> 3. Ms Ngernphanit  ARINGABANDITH</li>
                            <li> 4. Miss Viengsavanh VILAVANGSONE</li>
                            <li> 5. Miss Nalinda DUANGDALA</li>
                            <li> 6. Ms Inthaseang VONGPHAKDY</li>
                            <li> 7. Ms Xailao YASOTOUKEE</li>
                            <li> 8. Miss Padaphone AVALY</li>
                            <li> 9. Miss Soudalath SIVILAI</li>
                            <li> 10. Miss Palamy PHONETHICHAK</li>
                            <li> 11. Miss Xaiyaphone PHONETHICKAK</li>
                            <li> 12. Miss Phonesavanh SOUTTHAVONG</li>
                            <li> 13. Miss Souphonesili KHAMMANY</li>
                            <li> 14. Mr Suntisouk MITHIYAPHONE</li>
                            <li> 15. Mr Soukthavone DUANGDALA</li>
                            <li> 16. Miss Chanthamalee PHONGSAVATH</li>
                            <li> 17. Miss Jammy SONELATH</li>
                        </ol>
                    </Col>
                </Row>
            </Container>

            <Container>
                <Row className="mt-5">
                    <Col xs={12} sm={12} md={12}>
                        <h1 style={{ color: "#000000", fontSize: "18px" }}>SDGs Team</h1>
                    </Col>
                    <Col xs={12} sm={12} md={12}>
                        <img src={SDGS} className="volunteerImg" style={{ borderRadius: "16px", height: "auto" }} />
                    </Col>
                    <Col xs={12} sm={12} md={12} className='mt-5' style={{ textAlign: 'left', color: "#000000" }}>

                        <p className='nornalText'>
                            &nbsp;&nbsp;&nbsp;&nbsp;  Sustainable Development Goals (SDGs) team is the one of Zero Waste Laos, has a responsibility to encourage everyone to participate in sustainable development, but the main goal is to focus on the development of youth to be the most involved in order to encourage them  to have skills, knowledge and abilities in many areas because youth is an important force in the development of the nation in the future.
                        </p>

                        <p className='nornalText'>
                            Members of SDGs core team:
                        </p>

                        <ol className='nornalText'>
                            <li> 1. Ms Moukdalaxay KHAMPHASOUK</li>
                            <li> 2. Miss Phetsamai LORANOUPHAP</li>
                            <li> 3. Mr Soukkhamsai KEOVONGSA</li>
                            <li>4. Miss Lathsamee XAIVOUT</li>
                            <li>5. Miss Alisa KHENNAVONG</li>
                            <li>6. Miss Linna KHIENAPHONE</li>
                            <li> 7. Miss Soulaphy PHANGSOUVANH</li>
                            <li> 8. Miss Noumvina SEANKHAMTEE</li>
                            <li> 9. Mr Xaipaseuth SOUNTHAVONG</li>
                            <li> 10. Miss Soukthida SOMSEE</li>
                            <li> 11. Miss Chansamai LUANGLIDTHIDETH</li>
                            <li> 12. Miss Chindavanh SEANGCHAN</li>
                            <li> 13. Miss Chindanun PHETKEOVONGSA</li>
                            <li> 14. Miss Ketmany SEEAKKHASONE</li>
                            <li> 15. Miss Pamy NOUPHAENGDY</li>
                            <li> 16. Mr Xaixana DUANGDALA</li>
                        </ol>
                    </Col>
                </Row>
            </Container>

            <Container>
                <Row className="mt-5">
                    <Col xs={12} sm={12} md={12}>
                        <h1 style={{ color: "#000000", fontSize: "18px" }}>Youth and Gender development Team</h1>
                    </Col>
                    <Col xs={12} sm={12} md={12}>
                        <img src={Youth} className="volunteerImg" style={{ borderRadius: "16px", height: "auto" }} />
                    </Col>
                    <Col xs={12} sm={12} md={12} className='mt-5' style={{ textAlign: 'left', color: "#000000" }}>

                        <p className='nornalText'>
                            &nbsp;&nbsp;&nbsp;&nbsp;  We were established to encourage and survey about youth and gender issues that happened in our society, especially the youth age of 15-25 years old to target them to see the environment problem. Besides that, we also support human fairness in our generation to see what point we have to focus on. For example, career, education, human beings, environment, and salary.
                        </p>
                        <p className='nornalText'>
                            &nbsp;&nbsp;&nbsp;&nbsp;  To sum up, we are a group of the youth that represent to solve the issue with another team to achieve more youth for improving our environment better together.
                        </p>

                        <ol className='nornalText'>
                            <li> 1. Ms Alanya DELEUTH</li>
                            <li>  2. Miss Saliphone LEUANGVANXAY</li>
                            <li> 3. Miss Noukayang ZAWA</li>
                            <li> 4. Mr Phaisavath INTHABOUNPHAY</li>
                            <li> 5. Miss Sounisa SORSISOMCHAI</li>
                            <li>6. Miss Thipmany SOUTTHAVONG</li>
                            <li>7. Miss Xoudalath PHONTHIEN</li>
                            <li>8. Miss Minavanh CHEARTCHENG</li>
                            <li>9. Miss Siamphai BOUNDEE</li>
                            <li>10. Miss Souphaphone CHANTHAPHONE</li>
                            <li>11. Miss Lathsamy NAMMASA</li>
                            <li>12. Mr Boling PATHAMMAVONG</li>
                            <li>13. Miss Ketsavanh MALAITHONG</li>
                            <li>14. Mr Paphakone PHOMMACHAN</li>
                            <li>15. Miss Khamthavy NOIVONG</li>
                            <li>16. Mr Jonhny SOMMIXAI</li>
                            <li>17. Miss Sataphone MOUNIVONG</li>
                        </ol>
                    </Col>
                </Row>
            </Container>

            <Container>
                <Row className="mt-5">
                    <Col xs={12} sm={12} md={12}>
                        <h1 style={{ color: "#000000", fontSize: "18px" }}>IT and Communication Team</h1>
                    </Col>
                    <Col xs={12} sm={12} md={12}>
                        <img src={IT} className="volunteerImg" style={{ borderRadius: "16px", height: "auto" }} />
                    </Col>
                    <Col xs={12} sm={12} md={12} className='mt-5' style={{ textAlign: 'left', color: "#000000" }}>

                        <p className='nornalText'>
                            &nbsp;&nbsp;&nbsp;&nbsp;  In the movement of various activities, our team is the key team and behind the scenes in facilitating various things, whether it's zoom for joining meetings and online events, distributing various content via Facebook for everyone to access lesson information.  And the activities of our team are easier and we also keep various member information. We also collect the activity information of every event in order to get a summary for the annual summary and we have a clear division of duties.
                        </p>

                        <p className='nornalText'>
                            Team IT and communications:
                        </p>

                        <ol className='nornalText'>

                            <li>1. Mr Xaysana PHUNPHAENGDY</li>
                            <li>2. Mr Saksith SIDAVONG</li>
                            <li> 3. Mr Athit XAIYASENH</li>
                            <li>  4. Mr David PHANTHAVONG</li>
                            <li> 5. Mr Vonevue CHIABLONG</li>
                            <li>  6. Ms Souklada THAVIKHAM</li>
                            <li>  7. Miss Linsy</li>
                            <li>  8. Miss Mina SOUNAKHEN</li>
                            <li>  9. Mr Phoutthavong PHOUMIVONG</li>
                            <li>  10. Mr Sakthavath PHOUTTHAVONG</li>
                            <li>  11. Mr Phetphachan</li>
                        </ol>
                    </Col>
                </Row>
            </Container>

            <Container>
                <Row className="mt-5">
                    <Col xs={12} sm={12} md={12}>
                        <h1 style={{ color: "#000000", fontSize: "18px" }}>Management Team</h1>
                    </Col>
                    <Col xs={12} sm={12} md={12}>
                        <img src={management} className="volunteerImg" style={{ borderRadius: "16px", height: "auto" }} />
                    </Col>
                    <Col xs={12} sm={12} md={12} className='mt-5' style={{ textAlign: 'left', color: "#000000" }}>

                        <p className='nornalText'>
                            &nbsp;&nbsp;&nbsp;&nbsp;  Our team is the one team that is essential to all Zero Waste activities. We are the team working on both on-site and online registration.  A certificate is also made available to the organization at each event held.  Manage snacks and lunches during meetings or events.  In addition, during the event, we are especially responsible for the Zero Waste booth.  At the same time, there will also be bringing each person who comes into the Booth to play activities that convey how to separate waste properly is our goal, we want everyone to pay attention and act accordingly.

                        </p>

                        <p className='nornalText'>
                            Management team:
                        </p>

                        <ol className='nornalText'>
                            <li>1. Ms Sounita SOMPHONEXAY</li>
                            <li>2. Ms Khounkham LUANGLATH</li>
                            <li>3. Ms Khoungeun LUANGLATH</li>
                            <li>4. Ms Danychang XAIBEENOU</li>
                            <li>5. Mr Khamlar HER</li>
                            <li>6. Mr Xaiyo CHANG </li>
                            <li>7. Ms Pouy VIVONGXAI</li>
                        </ol>
                    </Col>
                </Row>
            </Container>

            <Footer />

        </div>
    )
}
export default About