import React, { useState, useEffect } from 'react'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Breadcrumb, Button, Col, Form, Image, Modal, Row, Spinner } from 'react-bootstrap'
import useReactRouter from "use-react-router";
import { useLazyQuery, useMutation } from '@apollo/react-hooks';

import { Title } from "../../../common"
import consts, { URL_FOR_SHOW_PHOTO } from '../../../consts'
import { CERTIFICATE } from '../../../apollo/certificate/Query';
import { dateTimeLao } from '../../../helpers/Helper';
import { DELETE_POST } from '../../../apollo/post/Mutation';

import CUSD_CERTIFICATE from '../../../crud/certificate';


export default function CertitficateDetail() {
    const { history, match, location } = useReactRouter();
    const CER_ID = match.params.id



    const [isOpenMadal, setIsOpenMadal] = useState(false);
    const [certificate, setCertificate] = useState({});
    const [deleteData, setDeleteData] = useState();

    const [getCertificate, { loading: cerLoading, data: certificateData }] = useLazyQuery(CERTIFICATE, { fetchPolicy: "network-only" })
    const {
        _deleteCertificate,
    } = CUSD_CERTIFICATE();

    useEffect(() => {
        queryCertificate()
    }, [])

    useEffect(() => {
        if (certificateData || location?.state) {
            setCertificate(certificateData?.certificate ?? (location?.state ?? {}))
        } else {
            queryCertificate()
        }
    }, [certificateData])

    const queryCertificate = () => {
        try {
            getCertificate({
                variables: {
                    where: {
                        id: CER_ID
                    }
                },
                fetchPolicy: "network-only"
            })
        } catch (error) {
            console.log(error);
        }
    }
    const onDeleteConfirm = (data) => {
        setDeleteData(data)
        setIsOpenMadal(true)
    }

    const handleCloseDelete = () => {setIsOpenMadal(false)}


    if (cerLoading) return <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '85vh', justifyContent: 'center', alignItems: 'center' }}>
        <div style={{ display: 'flex', backgroundColor: 'rgba(0, 169, 145, 0.3)', borderRadius: 8, flexDirection: 'column', width: 100, height: 100, justifyContent: 'center', alignItems: 'center' }}>
            <Spinner animation="border" variant="success" />
            <p style={{ fontSize: 12, marginTop: 8 }}>Loading...</p>
        </div>
    </div >;

    return (
        <div>
            <Breadcrumb style={{ marginTop: 60, fontSize: '12px' }}>
                <Breadcrumb.Item onClick={() => history.push(consts.PAGE_CERTIFICATE_LIST)}><span style={{ color: "#2E72D2" }}>E-Certificate</span></Breadcrumb.Item>
                <Breadcrumb.Item active>E-Certificate View</Breadcrumb.Item>
            </Breadcrumb>
            <div style={consts.MAIN_CARD} >
                <div style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between" }}>
                    <div>
                        <Title text='E-Certificate View' />
                    </div>
                    <div style={{ display: 'flex', flexDirection: "row", width: 300, alignItems: 'center' }}>
                        <Button variant="secondary" size="lg" onClick={() => history.push(consts.PAGE_CERTIFICATE_UPADTE, certificate)} style={{ height: 40, width: 100 }}>
                            <FontAwesomeIcon icon={faEdit} /> &nbsp;&nbsp; Edit
                        </Button>
                        {/* &nbsp;&nbsp;&nbsp;&nbsp;
                        <Button variant="danger" size="lg" onClick={() => onDeleteConfirm(certificate)} style={{ height: 40, width: 100 }}>
                            <FontAwesomeIcon icon={faTrash} /> &nbsp;&nbsp; Delete
                        </Button> */}
                    </div>
                </div>
                <Col md={9}>
                    <hr />
                </Col>
                <Row>
                    <Col md={3}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <Form.Label>Feature image</Form.Label>
                            {certificate?.imageProfile && <Image src={`${URL_FOR_SHOW_PHOTO}${certificate?.imageProfile}`} style={{ width: 200, height: 200, marginRight: 10 }} rounded />}
                        </div>
                    </Col>
                    <Col md={8}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between", alignItems: 'center' }}>
                                <Form.Label style={{ marginRight: 80 }}>Certificate category</Form.Label>
                                <Form.Label>{certificate?.categoryCertificateId?.name ?? "-"}</Form.Label>
                            </div>
                        </Form.Group>
                        <hr />
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between", alignItems: 'center' }}>
                                <Form.Label style={{ marginRight: 80 }}>Topic</Form.Label>
                                <Form.Label>{certificate?.topic ?? "-"}</Form.Label>
                            </div>
                        </Form.Group>
                        <hr />
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between", alignItems: 'center' }}>
                                <Form.Label style={{ marginRight: 80 }}>Detail</Form.Label>
                                <Form.Label><div style={{ textAlign: "justify" }} />{certificate?.detail ?? "-"}</Form.Label>
                            </div>
                        </Form.Group>
                        {/* <hr />
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between", alignItems: 'center' }}>
                                <Form.Label style={{ marginRight: 80 }}>images</Form.Label>
                                <div>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginTop: 10 }}>
                                        {certificate?.images?.map((image, index) => <Image key={index} src={`${URL_FOR_SHOW_PHOTO}${image}`} style={{ width: 80, height: 80, marginRight: 10 }} />)}
                                    </div>
                                </div>
                            </div>
                        </Form.Group> */}
                       
                    </Col>
                </Row>
                <div style={{ height: 70 }} />
                <Col md={3}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between", alignItems: 'center' }}>
                                <Form.Label style={{ color: "#3F4952", fontSize: 14 }}>Created by</Form.Label>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Form.Label style={{ color: "#3F4952", fontSize: 14 }}>{certificate?.createdBy?.firstName ?? "-"}</Form.Label>
                                    <Form.Label style={{ color: "#3F4952", fontSize: 14 }}>{dateTimeLao(certificate?.createdAt)}</Form.Label>
                                </div>
                            </div>
                        </Form.Group>
                        <hr />
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between", alignItems: 'center' }}>
                                <Form.Label style={{ color: "#3F4952", fontSize: 14 }}>Updated by</Form.Label>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Form.Label style={{ color: "#3F4952", fontSize: 14 }}>{certificate?.updatedBy?.firstName ?? "-"}</Form.Label>
                                    <Form.Label style={{ color: "#3F4952", fontSize: 14 }}>{dateTimeLao(certificate?.updatedAt)}</Form.Label>
                                </div>
                            </div>
                        </Form.Group>
                        <hr />
                    </div>
                </Col>
            </div>

            <Modal show={isOpenMadal} onHide={() => setIsOpenMadal(false)}>
                <Modal.Body>
                    <p style={{ fontSize: "18px", fontWeight: "bold" }}>Are you sure you want to delete?</p>
                    <p style={{ fontSize: "16px", color: "#4F5E71" }}>Please ensure and then confirm!</p>
                    <div style={{ float: "right", marginTop: 10 }}>
                        <Button variant="secondary" onClick={() => setIsOpenMadal(false)} style={{ width: 90 }}>
                            Cancel
                        </Button>
                        &nbsp;&nbsp;&nbsp;
                        <Button variant="danger" 
                        onClick={() => _deleteCertificate(deleteData ,handleCloseDelete)}
                         style={{ width: 90 }}>
                            Confirm
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>

        </div>
    )
}
