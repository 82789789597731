import React, { useState, useRef, useEffect } from 'react'
import { Breadcrumb, Col, Form, Image, Row } from 'react-bootstrap'
import useReactRouter from "use-react-router";
import { Formik } from 'formik'
import JoditEditor from "jodit-react";
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import Swal from 'sweetalert2';
import { v4 as uuidv4 } from 'uuid';
import { faFolder, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';

import { Title, CustomButton } from "../../../common"
import consts, { URL_FOR_SHOW_PHOTO } from '../../../consts'
import UploadPhoto from "../../../helpers/UploadPhoto";
import { PRESINGED_URL } from '../../../apollo/uploadFile';
import { POST, POST_CATEGORYS } from '../../../apollo/post/Query';
import { UPDATE_POST } from '../../../apollo/post/Mutation';

export default function EditBlog() {
    const { history, match, location } = useReactRouter();
    const POST_ID = match.params.id
    const { namePhoto, buttonUploadAndShowPhotoEdit } = UploadPhoto();
    const editor = useRef(null)
    const [content, setContent] = useState('')
    const [file, setFile] = useState('')
    const [imageLoading, setImageLoading] = useState(0)
    const [namePhotos, setNamePhotos] = useState([])
    const [post, setPost] = useState({});
    const [postCategories, setPostCategories] = useState([])

    const [getPostCategories, { data: postCategoriesData }] = useLazyQuery(POST_CATEGORYS, { fetchPolicy: "network-only" })
    const [getPost, { loading: postLoading, data: postData }] = useLazyQuery(POST, { fetchPolicy: "network-only" })
    const [uploadPhoto] = useMutation(PRESINGED_URL)
    const [updatePost] = useMutation(UPDATE_POST)

    useEffect(() => {
        getPostCategories({
            variables: {
                limit: 1000,
                skip: 0,
                where: {
                    isDeleted: false
                }
            }
        })
        queryPost()
    }, [])

    useEffect(() => {
        if (postCategoriesData?.categoryPosts) {
            setPostCategories(postCategoriesData?.categoryPosts?.data ?? [])
        }
    }, [postCategoriesData])

    useEffect(() => {
        if (postData || location?.state) {
            setPost(postData?.post ?? (location?.state ?? {}))
            setNamePhotos(postData?.post?.images ?? (location?.state?.images ?? []))
            setContent(postData?.post?.contents ?? (location?.state?.contents ?? ""))
        } else {
            queryPost()
        }
    }, [postData])

    const queryPost = () => {
        try {
            getPost({
                variables: {
                    where: {
                        id: POST_ID
                    }
                },
                fetchPolicy: "network-only"
            })
        } catch (error) {
            console.log(error);
        }
    }

    const handleUpload = async event => {
        setImageLoading('')
        try {
            setFile(event.target.files[0])
            let fileDataBoole = event?.target?.files[0]
            let fileDatatype = event?.target?.files[0]?.type
            let imageName = uuidv4() + '.' + fileDatatype.split('/')[1].toString()
            let checkFileUpload = fileDatatype.split('/')[0] !== 'application'
            if (checkFileUpload === false) {
                Swal.fire({
                    icon: 'error',
                    title: 'ກະລຸນາເລືອກຮູບພາບ',
                    showConfirmButton: false,
                    timer: 1500
                })
                return
            }
            const resUploadPhoto = await uploadPhoto({
                variables: {
                    fileName: imageName,
                    mimeType: fileDatatype.toString()
                }
            })
            await axios({
                method: 'put',
                url: resUploadPhoto.data.preSignedUrl.url,
                data: fileDataBoole,
                headers: {
                    'Content-Type': ' file/*; image/*',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
                    'Access-Control-Allow-Headers':
                        'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With'
                },

                onUploadProgress: function (progressEvent) {
                    var percentCompleted = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                    )
                    setImageLoading(percentCompleted)
                }
            })
            const _namePhotos = [...namePhotos, imageName]
            setNamePhotos(_namePhotos)
            setImageLoading(0)
        } catch (error) {
            console.log("ERROR: ", error)
        }
    }

    const removeImageFromArray = (image) => {
        const newImages = namePhotos.filter(_image => _image != image)
        setNamePhotos(newImages)
    }

    return (
        <div>
            <Breadcrumb style={{ marginTop: 60, fontSize: '12px' }}>
                <Breadcrumb.Item onClick={() => history.push(consts.PAGE_POST_LIST)}><span style={{ color: "#2E72D2" }}>Blog Management</span></Breadcrumb.Item>
                <Breadcrumb.Item active>Edit Blog</Breadcrumb.Item>
            </Breadcrumb>
            <div style={consts.MAIN_CARD} >
                <Title text='Edit Blog' />
                <hr></hr>
                <Formik
                    initialValues={{
                        topic: post?.topic ?? (location?.state?.topic ?? ""),
                        category: post?.categoryPostId?.id ?? (location?.state?.categoryPostId?.id ?? ""),
                        note: post?.note ?? (location?.state?.note ?? "")
                    }}
                    validate={values => {
                        const errors = {}
                        if (!values.topic) {
                            errors.topic = 'Required';
                        }
                        if (!values.category) {
                            errors.category = 'Required';
                        }
                        return errors
                    }}
                    onSubmit={async values => {
                        if (location?.state?.topic === values.topic) {
                            delete values.topic
                        }
                        const selectedCategory = postCategories.filter(category => category.id == values.category)
                        await updatePost({
                            variables: {
                                data: {
                                    categoryPostId: selectedCategory[0].id,
                                    categoryPostName: selectedCategory[0].name,
                                    topic: values.topic,
                                    imageProfile: namePhoto,
                                    images: namePhotos,
                                    contents: content.toString(),
                                    note: values.note
                                },
                                where: {
                                    id: POST_ID
                                }
                            }
                        })
                        history.push(consts.PAGE_POST_LIST)
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting
                        /* and other goodies */
                    }) => (
                        <Row>
                            <Col md={3}>
                                <Form.Label>Feature image <span style={{ color: "red" }}>*</span></Form.Label>
                                {buttonUploadAndShowPhotoEdit(postData?.post?.imageProfile ?? location?.state?.imageProfile)}
                            </Col>
                            <Col md={9}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Blog category <span style={{ color: "red" }}>*</span></Form.Label>
                                    <Form.Control size='lg'
                                        className='input'
                                        as="select"
                                        name="category"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.category}
                                        style={{ fontSize: "16px" }}>
                                        <option value='' selected disabled>Select the category</option>
                                        {postCategories?.map((category, index) =>
                                            <option key={index} value={category?.id}>{category?.name ?? "-"}</option>
                                        )}
                                    </Form.Control>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Topic <span style={{ color: "red" }}>*</span></Form.Label>
                                    <Form.Control size='lg'
                                        type="text"
                                        name="topic"
                                        onChange={handleChange}
                                        value={values.topic}
                                        className='input' placeholder="Please input the topic" />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Contents <span style={{ color: "red" }}>*</span></Form.Label>
                                    <JoditEditor
                                        ref={editor}
                                        value={content}
                                        tabIndex={1}
                                        onBlur={newContent => setContent(newContent)}
                                        onChange={newContent => { }}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Add image (you can add only 2 images)</Form.Label>
                                    <div style={{ border: "1px dashed gray", borderRadius: 8, padding: 16, display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        {imageLoading == 0 && <input
                                            type='file'
                                            id='files-upload'
                                            onChange={handleUpload}
                                            disabled={namePhotos?.length >= 2 ? true : false}
                                            hidden
                                            accept="image/png, image/gif, image/jpeg, image/jpg"
                                        />}
                                        {imageLoading == 0 && <label htmlFor='files-upload' disabled={namePhotos?.length >= 2 ? true : false}>
                                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                <FontAwesomeIcon icon={faFolder} style={{ cursor: "pointer" }} />
                                                &nbsp;&nbsp;
                                                <span style={{ color: namePhotos?.length >= 2 ? "#bebebe" : "blue", textDecorationLine: 'underline', cursor: "pointer" }}>Add file</span>
                                            </div>
                                        </label>}
                                        {imageLoading > 0 && <div className='progress' style={{ height: 20, width: 150 }}>
                                            <div
                                                className='progress-bar'
                                                role='progressbar'
                                                style={{
                                                    width: `${imageLoading}%`,
                                                    backgroundColor: 'green'
                                                }}
                                                aria-valuenow={imageLoading}
                                                aria-valuemin='0'
                                                aria-valuemax='100'
                                            >
                                                {imageLoading}%
                                            </div>
                                        </div>}
                                        &nbsp;&nbsp;
                                        {/* <span style={{ color: "grey" }}>or drag file here </span> */}
                                    </div>
                                    <div>
                                        <div style={{ color: "#959595", fontSize: 12 }}>Filename: JPEG, JPG, PNG. Size should be less than 5M</div>
                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginTop: 10 }}>
                                            {namePhotos?.map((image, index) => <div key={index} style={{ display: "flex", flexDirection: "column" }}>
                                                <Image src={`${URL_FOR_SHOW_PHOTO}${image}`} style={{ width: 80, height: 80, marginRight: 10 }} />
                                                <div style={{ position: "absolute", marginLeft: 70, marginTop: -10 }}>
                                                    <FontAwesomeIcon onClick={() => removeImageFromArray(image)} icon={faTrash} style={{ color: "#D21C1C", cursor: "pointer" }} />
                                                </div>
                                            </div>)}
                                        </div>
                                    </div>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Note</Form.Label>
                                    <Form.Control as="textarea"
                                        name="note"
                                        onChange={handleChange}
                                        value={values.note}
                                        placeholder="If have any note" style={{ height: '100px', fontSize: "16px" }} />
                                </Form.Group>

                                <center>
                                    <CustomButton cancel title='Cancel' style={{ width: "199px", height: '44px', border: 0 }} onClick={() => history.goBack()} />
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <CustomButton addIcon title='Edit Blog' style={{ width: "199px", height: '44px' }} onClick={() => handleSubmit()} />
                                </center>

                            </Col>
                        </Row>

                    )}
                </Formik>
            </div>
        </div>
    )
}
