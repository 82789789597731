import React, { useState } from 'react'
import { Formik } from 'formik'
import useReactRouter from "use-react-router";


import Consts from "../../../consts"
import UploadPhoto from "../../../helpers/UploadPhoto";

import Banner4 from "../../../image/volunteer/1.png"
import { Title, CustomButton } from "../../../common"


import { FaAngleRight } from "react-icons/fa";
import { Modal, Container, Row, Col, Breadcrumb, Form, Button } from 'react-bootstrap'
import { FaTimes, FaCircle } from "react-icons/fa";
import { PROVINCE, DRISTRIC } from '../../../consts/addresses'
import Footer from "../../../components/footer"
import CUSD_STAFF from '../../../crud/user';


import volunteerImage from "../../../image/volunteer/volunteerImage.png"
import imgVolunteer from "../../../image/volunteer/imgVolunteer.png"


import './Index.css'


function Volunteer() {
    const { history } = useReactRouter();
    const [showFormRegister, setShowFormRegister] = useState(false);
    const handleCloseFormRegister = () => setShowFormRegister(false);
    const handleShowFormRegister = () => setShowFormRegister(true);
    const [dataDistrictBirth, setDataDistrictBirth] = useState([])
    const [dataDistrict, setDataDistrict] = useState([])
    const [genderData, setGenderData] = useState("MALE");
    const [volunteer, setVolunteerData] = useState(false);

    const [showAlertSuccess, setShowAlertSuccess] = useState(false);

    const handleCloseAlertSuccess = () => {
        setShowAlertSuccess(false);
        window.location.reload();
    }
    const handleShowAlertSuccess = () => setShowAlertSuccess(true);



    const {
        namePhoto,
        buttonUploadAndShowPhoto,
    } = UploadPhoto();
    const {
        _createVolunteer,
    } = CUSD_STAFF();
    // function

    const _selectProvinceBirth = (a) => {
        let _selectData = PROVINCE?.filter((item) => item?.pr_name === a)
        let _selectDistrict = DRISTRIC?.filter((item) => _selectData[0]?.pr_id === item?.pr_id)
        setDataDistrictBirth(_selectDistrict)
    }
    const _selectProvince = (a) => {
        let _selectData = PROVINCE?.filter((item) => item?.pr_name === a)
        let _selectDistrict = DRISTRIC?.filter((item) => _selectData[0]?.pr_id === item?.pr_id)
        setDataDistrict(_selectDistrict)
    }
    return (
        <div style={{ zIndex: 0 }}>
            <div className='hideHeight'></div>

            <Container fluid className="m-0 p-0 bgImageVolunteer">
                <Row className="m-0 p-0">
                    <Col md='12' xs={12} className="m-0 p-0 bannerVolunteer">
                        <img className='imgBgVolunteer' src={Banner4} />
                        <div className="text-left">
                            <p>Volunteers</p>
                            <button className="btn-register" onClick={handleShowFormRegister}>Register</button>
                        </div>
                    </Col>
                </Row>

            </Container>
            <Container>

                <div className='title'>

                    <h2 className='bigTitle' id='AboutVolunteer'><span className="title-Active">About</span> Our Volunteering</h2>
                    <p className="textDetail" >Since November 2019, Zero Waste Laos has been working with more than 2000 youths along with 150 youths as our team member who are delighted and actively participate in addressing environmental issue starting from Waste Management, Climate actions regarding to Sustainable Development Goals, as well as capacity-building activities for youths to improve their self-esteem and other social skills that are important to their daily life. </p>
                </div>
            </Container>

            <Container fluid>
                <Row>
                    <Col xs={12} md={6} className="p-5">
                        <img src={imgVolunteer} className="volunteerImg" style={{ borderRadius: "16px" }} />
                    </Col>
                    <Col xs={12} md={6} className="p-5">
                            <h1 className='bigTitle'>Program's <span className="title-Active">Benefit</span> </h1>

                            <p className="textDetailVolunteer" style={{padding:10}}>
                                <FaCircle style={{ color: "#00A991" }} />   Become a part of protecting the environment for our sustainable living.
                            </p>


                            <p className="textDetailVolunteer" style={{padding:10}}>
                                <FaCircle style={{ color: "#00A991" }} />   Being a part of our team will allow you to learn multiple skills: communication skills, team work, etc.
                            </p>


                            <p className="textDetailVolunteer" style={{padding:10}}>
                                <FaCircle style={{ color: "#00A991" }} />   You will be able to change your vision towards your community in many perspectives.
                            </p>



                            <p className="textDetailVolunteer" style={{padding:10}}>
                                <FaCircle style={{ color: "#00A991" }} />  Working experiences with new friends, professionals, NGOs, and other development collaborators.
                            </p>


                            <p className="textDetailVolunteer" style={{padding:10}}>
                                <FaCircle style={{ color: "#00A991" }} />   Improve your management skills to deal with difficulties.
                            </p>


                            <p className="textDetailVolunteer" style={{padding:10}}>
                                <FaCircle style={{ color: "#00A991" }} />   Learn about generosity, sharing, and caring for your better life.
                            </p>

                    </Col>
                </Row>
            </Container>
            <Container>

                <div className='title'>
                    <h2 className='bigTitle'><span className="title-Active">Our Volunteers</span>  Say</h2>
                   <center>
                    <p className='textDetail' style={{textAlign: "center" }}>It’s more than just volunteering but being together as a family ”
                        A Zero Waste Laos Volunteer
                    </p>
                    </center>
                </div>
            </Container>


            <Container fluid className="mb-5 p-0 bgImageVolunteerFooter" id='register'>
                <img className='imgBgVolunteerFoot' src={Banner4} />
                <div className="text-left-Footer">
                    <p>Volunteer</p>
                    <p className="textSubTitleDetail">Join with us today!</p>
                    <button className="btn-register-Footer"
                        onClick={handleShowFormRegister}>Register</button>
                </div>
                {/* <div className="text-right">
                    <p>volunteers</p>
                </div> */}
            </Container>
            <Footer />
            <Modal
                size="lg"
                show={showFormRegister} onHide={handleCloseFormRegister} className="modal-form">
                <div style={{ float: 'right', padding: 20 }} className="formRegisterClose">
                    <FaTimes style={{ float: 'right', fontSize: 20, cursor: "pointer" }} onClick={handleCloseFormRegister} />
                </div>
                <Modal.Body>
                    {/* <div> */}
                    {/* <div style={Consts.MAIN_CARD} > */}
                    <hr></hr>
                    <Formik
                        initialValues={{
                            email: "",
                            firstName: "",
                            birthday: "",
                            phone: "",
                            email: "",
                            educationLevel: "",
                            englishProficiency: "",
                            occupation: "",
                            workplace: "",
                            provinceOfBirth: "",
                            districtOfBirth: "",
                            villageOfBirth: "",
                            province: "",
                            district: "",
                            village: "",
                            volunteerExplain: "",
                            knowAboutUs: "",
                            everJoinedZeroWaste: "",
                            tellUs: "",
                            skillsAndProfession: "",
                            improveYourself: "",
                            expectZeroWaste: "",
                        }}
                        validate={values => {
                            const errors = {}
                            if (!values.firstName) {
                                errors.firstName = 'Required';
                            }
                            if (!values.birthday) {
                                errors.birthday = 'Required';
                            }
                            if (!values.phone) {
                                errors.phone = 'Required';
                            }
                            if (!values.email) {
                                errors.email = 'Required';
                            }
                            if (!values.educationLevel) {
                                errors.educationLevel = 'Required';
                            }
                            if (!values.englishProficiency) {
                                errors.englishProficiency = 'Required';
                            }
                            if (!values.occupation) {
                                errors.occupation = 'Required';
                            }
                            if (!values.workplace) {
                                errors.workplace = 'Required';
                            }
                            if (!values.provinceOfBirth) {
                                errors.provinceOfBirth = 'Required';
                            } else {
                                _selectProvinceBirth(values.provinceOfBirth)
                            }
                            if (!values.districtOfBirth) {
                                errors.districtOfBirth = 'Required';
                            }
                            if (!values.villageOfBirth) {
                                errors.villageOfBirth = 'Required';
                            }
                            if (!values.province) {
                                errors.province = 'Required';
                            } else {
                                _selectProvince(values.province)
                            }
                            if (!values.district) {
                                errors.district = 'Required';
                            }
                            if (!values.village) {
                                errors.village = 'Required';
                            }

                            return errors
                        }}
                        onSubmit={values => {
                            let _data = {
                                ...values,
                                role: "VOLUNTEER",
                                image: namePhoto,
                                gender: genderData,
                                volunteerBefore: volunteer,
                            }
                            // console.log("🚀AAA===>", _data)  Consts.PAGE_VOLUNTEER,
                            _createVolunteer(_data, handleShowAlertSuccess)
                            handleCloseFormRegister()
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting
                            /* and other goodies */
                        }) => (

                            <Row>
                                <Col md={12}>
                                    <Form.Label>Upload Profile <span style={{ color: "red" }}>*</span></Form.Label>

                                    {buttonUploadAndShowPhoto()}
                                </Col>
                                <Col md={12}>
                                    <div style={{ backgroundColor: "#F6F6F6", color: "#000000", fontSize: '18px', fontWeight: "400", padding: 10, marginBottom: 10 }}>
                                        General information
                                    </div>

                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Name and surname <span style={{ color: "red" }}>*</span></Form.Label>
                                        <Form.Control size='lg'
                                            type="text"
                                            name="firstName"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.firstName}
                                            className='input' placeholder="Please Name and surname" />
                                        <div style={{ color: 'red' }}>
                                            <p>{errors.firstName && touched.firstName && errors.firstName}</p>
                                        </div>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Gender <span style={{ color: "red" }}>*</span></Form.Label>
                                        <br />
                                        <div className="custom-control custom-radio custom-control-inline">

                                            <Form.Check
                                                type="radio"
                                                id="MALE"
                                                name="gender"
                                                defaultChecked
                                                onChange={() => setGenderData("MALE")}
                                                style={{ width: '40px !important', height: "40px !important" }}

                                            />
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <Form.Check.Label style={{ marginTop: 15 }}>MALE</Form.Check.Label>
                                        </div>
                                        <div className="custom-control custom-radio custom-control-inline">

                                            <Form.Check
                                                type="radio"
                                                id="FEMALE"
                                                name="gender"
                                                onChange={() => setGenderData("FEMALE")}
                                                style={{ width: '40px !important', height: "40px !important" }}

                                            />
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <Form.Check.Label style={{ marginTop: 15 }}>FEMALE</Form.Check.Label>
                                        </div>

                                        <div className="custom-control custom-radio custom-control-inline">

                                            <Form.Check
                                                type="radio"
                                                id="LGBTQ+"
                                                name="gender"
                                                onChange={() => setGenderData("LGBTQ")}
                                                style={{ width: '40px !important', height: "40px !important" }}


                                            />
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <Form.Check.Label style={{ marginTop: 15 }}>LGBTQ+</Form.Check.Label>

                                        </div>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Date of birth <span style={{ color: "red" }}>*</span></Form.Label>
                                        <Form.Control size='lg'
                                            className='input'
                                            type="date"
                                            name="birthday"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.birthday}
                                        />
                                        <div style={{ color: 'red' }}>
                                            <p>{errors.birthday && touched.birthday && errors.birthday}</p>
                                        </div>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Phone number <span style={{ color: "red" }}>*</span></Form.Label>
                                        <Form.Control size='lg'
                                            className='input'
                                            type="text"
                                            name="phone"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.phone}
                                            placeholder="20xxxxxxx" />
                                        <div style={{ color: 'red' }}>
                                            <p>{errors.phone && touched.phone && errors.phone}</p>
                                        </div>
                                    </Form.Group>


                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Email <span style={{ color: "red" }}>*</span></Form.Label>
                                        <Form.Control size='lg'
                                            className='input'
                                            type="email"
                                            name="email"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.email}
                                            placeholder="Please input Email" />
                                        <div style={{ color: 'red' }}>
                                            <p>{errors.email && touched.email && errors.email}</p>
                                        </div>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Education level <span style={{ color: "red" }}>*</span></Form.Label>
                                        <Form.Control size='lg'
                                            className='input'
                                            as="select"
                                            name="educationLevel"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.educationLevel}
                                            style={{ fontSize: "16px" }}>
                                            <option value='' selected disabled>Select the Education level</option>
                                            <option value='College'>College</option>
                                            <option value="Bachelor's degree">Bachelor's degree</option>
                                            <option value="Master's degree">Master's degree</option>
                                            <option value='PhD'>PhD</option>
                                            <option value='Other'>Other</option>
                                        </Form.Control>
                                        <div style={{ color: 'red' }}>
                                            <p>{errors.educationLevel && touched.educationLevel && errors.educationLevel}</p>
                                        </div>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>English proficiency <span style={{ color: "red" }}>*</span></Form.Label>
                                        <Form.Control size='lg' className='input'
                                            name="englishProficiency"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.englishProficiency}
                                            as="select" style={{ fontSize: "16px" }}>
                                            <option value='' selected disabled>Select the English proficiency</option>
                                            <option value='Beginner'>Beginner</option>
                                            <option value="Elementary">Elementary</option>
                                            <option value="Lower intermediate">Lower intermediate</option>
                                            <option value='Intermediate'>Intermediate</option>
                                            <option value='Upper intermediate'>Upper intermediate</option>
                                            <option value='Advance'>Advance</option>
                                        </Form.Control>
                                        <div style={{ color: 'red' }}>
                                            <p>{errors.englishProficiency && touched.englishProficiency && errors.englishProficiency}</p>
                                        </div>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Occupation <span style={{ color: "red" }}>*</span></Form.Label>
                                        <Form.Control size='lg'
                                            name="occupation"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.occupation}
                                            className='input' as="select" style={{ fontSize: "16px" }} >
                                            <option value='' selected disabled>Select the Occupation</option>
                                            <option value='Student'>Student</option>
                                            <option value="Officer">Officer</option>
                                            <option value='Other'>Other</option>
                                        </Form.Control>
                                        <div style={{ color: 'red' }}>
                                            <p>{errors.occupation && touched.occupation && errors.occupation}</p>
                                        </div>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>School/Institute/Workplace <span style={{ color: "red" }}>*</span></Form.Label>
                                        <Form.Control size='lg'
                                            type="text"
                                            name="workplace"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.workplace}
                                            className='input'
                                            placeholder="Please input your School/Institute/Workplace" />
                                        <div style={{ color: 'red' }}>
                                            <p>{errors.workplace && touched.workplace && errors.workplace}</p>
                                        </div>
                                    </Form.Group>

                                    <div style={{ backgroundColor: "#F6F6F6", color: "#000000", fontSize: '18px', fontWeight: "400", padding: 10, marginBottom: 10 }}>
                                        Brith place information
                                    </div>

                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Province of birth <span style={{ color: "red" }}>*</span></Form.Label>
                                        <Form.Control size='lg'
                                            name="provinceOfBirth"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.provinceOfBirth}
                                            className='input' as="select"
                                            style={{ fontSize: "16px" }}
                                        >
                                            <option value='' selected disabled >Select the Province</option>
                                            {PROVINCE?.map((item, index) =>
                                                <option value={item?.pr_name} key={index + 1} >{item?.pr_name}</option>
                                            )}
                                        </Form.Control>
                                        <div style={{ color: 'red' }}>
                                            <p>{errors.provinceOfBirth && touched.provinceOfBirth && errors.provinceOfBirth}</p>
                                        </div>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>District of birth <span style={{ color: "red" }}>*</span></Form.Label>
                                        <Form.Control size='lg'
                                            name="districtOfBirth"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.districtOfBirth}
                                            className='input'
                                            as="select"
                                            style={{ fontSize: "16px" }}>
                                            <option value='' selected disabled>Select the District</option>
                                            {dataDistrictBirth?.map((item, index) =>
                                                <option value={item?.dr_name} key={index + 1} >{item?.dr_name}</option>
                                            )}
                                        </Form.Control>
                                        <div style={{ color: 'red' }}>
                                            <p>{errors.districtOfBirth && touched.districtOfBirth && errors.districtOfBirth}</p>
                                        </div>
                                    </Form.Group>

                                    {/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Village of birth <span style={{ color: "red" }}>*</span></Form.Label>
                                    <Form.Control size='lg' className='input' as="select" style={{fontSize:"16px"}}>
                                        <option value=''>Select the Village</option>
                                      
                                    </Form.Control>
                                </Form.Group> */}
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Village of birth <span style={{ color: "red" }}>*</span></Form.Label>
                                        <Form.Control size='lg'
                                            type="text"
                                            name="villageOfBirth"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.villageOfBirth}
                                            className='input'
                                            placeholder="Please input your Village of birth" />
                                        <div style={{ color: 'red' }}>
                                            <p>{errors.villageOfBirth && touched.villageOfBirth && errors.villageOfBirth}</p>
                                        </div>
                                    </Form.Group>

                                    <div style={{ backgroundColor: "#F6F6F6", color: "#000000", fontSize: '18px', fontWeight: "400", padding: 10, marginBottom: 10 }}>
                                        Current address information
                                    </div>

                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Province <span style={{ color: "red" }}>*</span></Form.Label>
                                        <Form.Control size='lg'
                                            name="province"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.province}
                                            className='input' as="select" style={{ fontSize: "16px" }}>
                                            <option value='' selected disabled >Select the Province</option>
                                            {PROVINCE?.map((item, index) =>
                                                <option value={item?.pr_name} key={index + 1} >{item?.pr_name}</option>
                                            )}
                                        </Form.Control>
                                        <div style={{ color: 'red' }}>
                                            <p>{errors.province && touched.province && errors.province}</p>
                                        </div>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>District <span style={{ color: "red" }}>*</span></Form.Label>
                                        <Form.Control size='lg'
                                            name="district"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.district}
                                            className='input' as="select" style={{ fontSize: "16px" }}>
                                            <option value='' selected disabled>Select the District</option>
                                            {dataDistrict?.map((item, index) =>
                                                <option value={item?.dr_name} key={index + 1} >{item?.dr_name}</option>
                                            )}
                                        </Form.Control>
                                        <div style={{ color: 'red' }}>
                                            <p>{errors.district && touched.district && errors.district}</p>
                                        </div>
                                    </Form.Group>

                                    {/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Village <span style={{ color: "red" }}>*</span></Form.Label>
                                    <Form.Control size='lg' className='input' as="select" style={{fontSize:"16px"}}>
                                        <option value=''>Select the Village</option>
                                      
                                    </Form.Control>
                                </Form.Group> */}
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>village <span style={{ color: "red" }}>*</span></Form.Label>
                                        <Form.Control size='lg'
                                            type="text"
                                            name="village"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.village}
                                            className='input'
                                            placeholder="Please input your village" />
                                        <div style={{ color: 'red' }}>
                                            <p>{errors.village && touched.village && errors.village}</p>
                                        </div>
                                    </Form.Group>

                                    <div style={{ backgroundColor: "#F6F6F6", color: "#000000", fontSize: '18px', fontWeight: "400", padding: 10, marginBottom: 10 }}>
                                        About Zero Waste Laos
                                    </div>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Have you ever been a volunteer before? <span style={{ color: "red" }}>*</span></Form.Label>
                                        <br />

                                        <div className="custom-control custom-radio custom-control-inline">

                                            <Form.Check
                                                type="radio"
                                                id="YES"
                                                name="volunteer"
                                                onClick={() => setVolunteerData(true)}
                                                style={{ width: '40px !important', height: "40px !important" }}

                                            />
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <Form.Check.Label style={{ marginTop: 15 }}>Yes</Form.Check.Label>
                                        </div>

                                        <div className="custom-control custom-radio custom-control-inline">

                                            <Form.Check
                                                type="radio"
                                                id="NO"
                                                name="volunteer"
                                                defaultChecked
                                                onClick={() => setVolunteerData(false)}
                                                style={{ width: '40px !important', height: "40px !important" }}

                                            />
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <Form.Check.Label style={{ marginTop: 15 }}>No</Form.Check.Label>

                                        </div>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Where did you work as a volunteer? explain</Form.Label>
                                        <Form.Control size='lg'
                                            name="volunteerExplain"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.volunteerExplain}
                                            className='input' type="text" placeholder="Please explain" />
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>How do you know about us?</Form.Label>
                                        <Form.Control size='lg'
                                            name="knowAboutUs"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.knowAboutUs}
                                            className='input' as="select" style={{ fontSize: "16px" }}>
                                            <option value='' selected disabled>Please selecc</option>
                                            <option value='Facebook'>Facebook</option>
                                            <option value='Website'>Website</option>
                                            <option value='YouTube'>YouTube</option>
                                            <option value='Other'>Other</option>
                                        </Form.Control>
                                    </Form.Group>


                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Have you ever joined any event held by Zero Waste Laos?</Form.Label>
                                        <Form.Control as="textarea"
                                            name="everJoinedZeroWaste"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.everJoinedZeroWaste}
                                            placeholder="Please input .." style={{ height: '100px', fontSize: "16px" }} />
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Please tell us why do you want to be a part of our core team?</Form.Label>
                                        <Form.Control as="textarea"
                                            name="tellUs"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.tellUs}
                                            placeholder="Please input .." style={{ height: '100px', fontSize: "16px" }} />
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Please tell us about your skills and profession</Form.Label>
                                        <Form.Control as="textarea"
                                            name="skillsAndProfession"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.skillsAndProfession}
                                            placeholder="Please input .." style={{ height: '100px', fontSize: "16px" }} />
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>How do you want to improve yourself?</Form.Label>
                                        <Form.Control as="textarea"
                                            name="improveYourself"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.improveYourself}
                                            placeholder="Please input .." style={{ height: '100px', fontSize: "16px" }} />
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>What do you expect from Zero Waste Laos?</Form.Label>
                                        <Form.Control as="textarea"
                                            name="expectZeroWaste"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.expectZeroWaste}
                                            placeholder="Please input .." style={{ height: '100px', fontSize: "16px" }} />
                                    </Form.Group>

                                    <Row>
                                        <Col xs={6} sm={6} md={6} >
                                            <div style={{ float: 'right' }}>
                                                <CustomButton cancel title='Cancel' style={{ width: "150px", height: '44px', border: 0 }} onClick={() => handleCloseFormRegister()} />
                                            </div>
                                        </Col>
                                        <Col xs={6} sm={6} md={6} style={{ float: 'left' }}>
                                            <div style={{ float: 'left' }}>


                                                <CustomButton addIcon title='Save' style={{ width: "150px", height: '44px' }} onClick={() => handleSubmit()} />
                                            </div>
                                        </Col>
                                    </Row>

                                    {/* <center>
                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                            </center> */}

                                </Col>
                            </Row>
                        )}
                    </Formik>
                    {/* </div> */}
                    {/* </div> */}
                </Modal.Body>
            </Modal>


            <Modal show={showAlertSuccess}
                onHide={handleCloseAlertSuccess}
                centered
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
            >

                <Modal.Body>

                    <Row>

                        <Col xs={12} style={{ textAlign: 'right' }}>
                            <FaTimes style={{ marginTop: 15, marginRight: 15, fontSize: "20px" }} onClick={handleCloseAlertSuccess} />
                        </Col>

                        <Col xs={12}>
                            <img src={volunteerImage} style={{ width: "100%", height: "auto" }} />
                            <center>
                                <p style={{ fontSize: "18px", fontWeight: "bold" }}>
                                    Thanks we will consider soon
                                </p>
                                <p>
                                    We will notify you of the results via WhatsApp or Email ❤🌱️
                                </p>
                            </center>
                        </Col>
                    </Row>

                </Modal.Body>
            </Modal>




        </div>
    )
}
export default Volunteer