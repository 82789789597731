import React, { useState } from 'react'
import useReactRouter from "use-react-router";
import { Formik } from 'formik'

import { Breadcrumb, Container, Row, Form, Col, Image, Button } from 'react-bootstrap'
import { FaEye, FaEdit, FaTrash, FaPlusCircle } from "react-icons/fa";
import Consts from "../../../../consts"

import { Title, CustomButton } from "../../../../common"
import CUSD_CERTIFICATE_CATEGORY from '../../../../crud/CertificateCategory';


function CertificateCategoryAdd() {
    const { history } = useReactRouter();

    //state

    const {
        _createCertificateCategory,
    } = CUSD_CERTIFICATE_CATEGORY();
    
    // function
    const _certificateCategoryList = () => { history.push(Consts.PAGE_CERTIFICATE_CATEGORY_LIST) }

    return (
        <div>
            <Breadcrumb style={{ marginTop: 60, fontSize: '12px' }}>
                <Breadcrumb.Item href="#" onClick={() => _certificateCategoryList()}>Seetings</Breadcrumb.Item>
                <Breadcrumb.Item active>E-Certificate Category Add</Breadcrumb.Item>
            </Breadcrumb>

            <div style={Consts.MAIN_CARD} >
                <Title text='E-Certificate Category Add' />
                <hr></hr>
                <Formik
                    initialValues={{
                        certificateCateName:"",
                        detail:"",
                    }}
                    validate={values => {
                        const errors = {}
                        if (!values.certificateCateName) {
                          errors.certificateCateName = 'Please input certificate category name ....'
                        }
                      
                        return errors
                    }}
                    onSubmit={values => {
                        let _data ={
                            name: values?.certificateCateName,
                            detail:values?.detail
                        }
                        _createCertificateCategory(_data)
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting
                        /* and other goodies */
                    }) => (

                        <Row>
                        
                            <Col md={12}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label style={{fontSize:14}}>certificate category name <span style={{ color: "red" }}>*</span></Form.Label>
                                    <Form.Control
                                        size='lg'
                                        type="text"
                                        placeholder="Please Category name"
                                        name="certificateCateName"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.certificateCateName}
                                        style={{ height:40,fontSize:14}}
                                    />
                                    <div style={{ color: 'red' }}>
                                        <p>{errors.certificateCateName && touched.certificateCateName && errors.certificateCateName}</p>
                                    </div>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label style={{fontSize:14}}>Detail</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        placeholder="if any detail..."
                                        name="detail"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.detail}
                                        style={{ height: '100px',fontSize:14 }}
                                    />
                                </Form.Group>
                                
                                <center>
                                    <CustomButton cancel title='Cancel' style={{ width: "199px", height: '44px', border: 0 }} onClick={() => history.goBack()}/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <CustomButton addIcon title='Save' style={{ width: "199px", height: '44px' }} onClick={()=>handleSubmit()}/>
                                </center>
                            </Col>
                        </Row>
                    )}
                </Formik>
            </div>
        </div>
    )
}
export default CertificateCategoryAdd