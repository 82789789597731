import React, { useState } from 'react'
import useReactRouter from "use-react-router";
import { Formik } from 'formik';
import { useMutation } from '@apollo/react-hooks';
import { LOGIN_ADMIN } from "../../../apollo/login";
import Consts from "../../../consts";

import logoLogin from "../../../image/admin/logoLogin.png"

import { Carousel, Container, Row, Col, Button, Form } from 'react-bootstrap'
import { FaAngleRight } from "react-icons/fa";

import "./Index.css"

function Login() {
    const { history } = useReactRouter();
    const [loginUser, loginUserData] = useMutation(LOGIN_ADMIN);
    const [checkUser, setCheckUser] = useState(false);

    console.log("checkUser---->",checkUser)

    const _login = async (data) => {
        const _dataRespone = await loginUser({ variables: { data } }).then(async function (_dataRespone) {
            await localStorage.setItem(Consts.USER_KEY, JSON.stringify(_dataRespone?.data?.loginAdmin))
            history.push(Consts.PAGE_DESHBOARD)
        }).catch(function (error) {
            setCheckUser(true);
        });
    }
    return (
        <div className='login-body'>
            <Container>
                 <Formik
                    initialValues={{
                        userId: '',
                        password: ''
                    }}
                    validate={values => { }}
                    onSubmit={(values, { setSubmitting }) => {
                        // if (!values.userId) {
                        //     setCheckUser(true);
                        // } else if (!values.password) {
                        //     setCheckUser(true);
                        // } else {
                            let data = {
                                userId: values.userId,
                                password: values.password,
                            }
                            _login(data)
                        // }
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                    }) => ( 
                    <form onSubmit={handleSubmit} style={{ paddingBottom: 100 }}>
                        <Row>
                            <Col md={{ span: 6, offset: 3 }} style={{ marginTop: "10%" }}>

                                <center>
                                    <img src={logoLogin} style={{ width: "170px", height: "170px" }} />
                                </center>

                                <div className='login-card'>
                                    <p className='login-title'>ZERO WASTE LAOS-ADMIN</p>
                                    <Form.Group className="" controlId="exampleForm.ControlInput1">
                                        <p className='login-subTitle' style={{ textAlign: 'left' }}>Username <span style={{ color: "red" }}>*</span> </p>
                                        <Form.Control type="text"
                                            size="lg" placeholder="Username ..."
                                            name="userId"
                                            onChange={handleChange}
                                            value={values.userId}
                                            style={{ fontSize: "18px", height: 40 }}
                                        />
                                    </Form.Group>
                                    <br></br>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <p className='login-subTitle' style={{ textAlign: 'left' }}>Password <span style={{ color: "red" }}>*</span></p>
                                        <Form.Control
                                            type="password" size="lg"
                                            placeholder="*****"
                                            name="password"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            style={{ fontSize: "18px", height: 40 }}
                                        value={values.password}
                                        />
                                    </Form.Group>

                                    <p style={{color:"red"}}>
                                        {checkUser ? "username or password is not correct, Please try again" : "" }
                                    </p>

                                    <center>
                                        <button type='submit' className='login-button mb-3' style={{ height: 40 }}>Login <FaAngleRight /></button>
                                    </center>

                                </div>
                            </Col>
                        </Row>
                    </form>
                    )}
                </Formik> 

            </Container>
        </div>
    )
}
export default Login