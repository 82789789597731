import React, { useState } from "react";
import SideNav, { NavItem, NavIcon, NavText } from "@trendmicro/react-sidenav";
import "@trendmicro/react-sidenav/dist/react-sidenav.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCogs,
    faUsers,
    faTelevision,
    faIdCard,
    faBullhorn,
    faTree,
} from "@fortawesome/free-solid-svg-icons";
import "./sidenav.css";
import { FaCertificate,FaMoneyBillAlt } from "react-icons/fa";
// import { checkRole }from '../helper/super'
const selectedTabBackgroundColor = "#64748B";
const UN_SELECTED_TAB_TEXT = "#FFFFFF";
const APP_COLOR_FOCUS = "#F8FAFC";
const APP_BACKGROUND_FOCUS = "#00A991";

export default function Sidenav({ location, history }) {
    //   const [checkRoleData, setCheckRoleData] = useState(checkRole());/
    console.log("location.pathname: ", location.pathname)
    const [selectStatus, setSelectStatus] = useState(
        // location.pathname.split("/")[1].split("-")[0]
        location.pathname.split("/")[1]
    );

    return (
        <SideNav
            style={{
                backgroundColor: "#ffffff",
                height: "100vh",
                display: "block",
                position: "fixed",
                zIndex: 1000,
                boxShadow: "0px 0px 2px rgba(37, 42, 49, 0.16), 0px 1px 4px rgba(37, 42, 49, 0.12)"
            }}
            onSelect={(selected) => {
                // setSelectStatus(selected.split("/")[0].split("-")[0]);
                setSelectStatus(selected);
                if (selected === "admin-deshboard") {
                    selected = selected;
                }
                else if (selected === "admin-staff") {
                    selected = selected;
                }
                else if (selected === "admin-volunteer") {
                    selected = selected;
                }
                else if (selected === "admin-post") {
                    selected = selected;
                }
                else if (selected === "admin-add-post") {
                    selected = selected;
                }
                else if (selected === "admin-settings") {
                    selected = selected;
                }
                else if (selected === "admin-certificate") {
                    selected = selected;
                }
                else if (selected === "admin-climate-action-waiting-list") {
                    selected = selected;
                }
                else if (selected === "admin-moneys") {
                    selected = selected;
                }
               
               
                const to = "/" + selected;
                if (location.pathname !== to) {
                    history.push(to);
                }
            }}
        >
            <SideNav.Toggle style={{ color: "white" }} />

            <SideNav.Nav
                defaultSelected={location.pathname.split("/")[1]}
                style={{ backgroundColor: "#ffffff" }}
            >
                <NavItem eventKey="admin-dashboard">
                    <NavIcon>
                        <FontAwesomeIcon
                            icon={faTelevision}
                            style={{
                                fontSize: "20px",
                                color:
                                    selectStatus === "admin-dashboard"
                                        ? APP_COLOR_FOCUS
                                        : selectedTabBackgroundColor,
                            }}
                        />
                    </NavIcon>
                    <NavText
                        style={{
                            color:
                                selectStatus === "admin-dashboard"
                                    ? APP_COLOR_FOCUS
                                    : selectedTabBackgroundColor,
                            fontSize: "17px",
                        }}
                    >
                        Dashboard
                    </NavText>
                </NavItem>

                <NavItem
                    eventKey="admin-staff"
                >
                    <NavIcon>
                        <FontAwesomeIcon
                            icon={faIdCard}
                            style={{
                                fontSize: "20px",
                                color:
                                    selectStatus === "admin-staff"
                                        ? APP_COLOR_FOCUS
                                        : selectedTabBackgroundColor,
                            }}
                        />
                    </NavIcon>
                    <NavText
                        style={{
                            color:
                                selectStatus === "admin-staff"
                                    ? APP_COLOR_FOCUS
                                    : selectedTabBackgroundColor,
                            fontSize: "17px",
                        }}
                    >
                        Staff Management
                    </NavText>
                </NavItem>

                <NavItem
                    eventKey="admin-volunteer"
                >
                    <NavIcon>
                        <FontAwesomeIcon
                            icon={faUsers}
                            style={{
                                fontSize: "20px",
                                color:
                                    selectStatus === "admin-volunteer"
                                        ? APP_COLOR_FOCUS
                                        : selectedTabBackgroundColor,
                            }}
                        />
                    </NavIcon>
                    <NavText
                        style={{
                            color:
                                selectStatus === "admin-volunteer"
                                    ? APP_COLOR_FOCUS
                                    : selectedTabBackgroundColor,
                            fontSize: "17px",
                        }}
                    >
                        Volunteer Management
                    </NavText>
                </NavItem>

                <NavItem
                    eventKey="admin-post"
                >
                    <NavIcon>
                        <FontAwesomeIcon
                            icon={faBullhorn}
                            style={{
                                fontSize: "20px",
                                color:
                                    selectStatus === "admin-post"
                                        ? APP_COLOR_FOCUS
                                        : selectedTabBackgroundColor,
                            }}
                        />
                    </NavIcon>
                    <NavText
                        style={{
                            color:
                                selectStatus === "admin-post"
                                    ? APP_COLOR_FOCUS
                                    : selectedTabBackgroundColor,
                            fontSize: "17px",
                        }}
                    >
                     Blog Management
                    </NavText>
                </NavItem>

                <NavItem
                    eventKey="admin-certificate"
                >
                    <NavIcon>
                    <FaCertificate style={{
                                fontSize: "20px",
                                color:
                                    selectStatus === "admin-certificate"
                                        ? APP_COLOR_FOCUS
                                        : selectedTabBackgroundColor,
                            }} />

                        
                    </NavIcon>
                    <NavText
                        style={{
                            color:
                                selectStatus === "admin-certificate"
                                    ? APP_COLOR_FOCUS
                                    : selectedTabBackgroundColor,
                            fontSize: "17px",
                        }}
                    >
                       E-Cert Management
                    </NavText>
                </NavItem>

                <NavItem
                    eventKey="admin-climate"
                >
                    <NavIcon>
                    <FontAwesomeIcon
                        icon={faTree}
                    style={{
                                fontSize: "20px",
                                color:
                                    selectStatus === "admin-climate"
                                        ? APP_COLOR_FOCUS
                                        : selectedTabBackgroundColor,
                            }} />

                        
                    </NavIcon>
                    <NavText
                        style={{
                            color:
                                selectStatus === "admin-climate"
                                    ? APP_COLOR_FOCUS
                                    : selectedTabBackgroundColor,
                            fontSize: "17px",
                        }}
                    >
                       Climate Action
                    </NavText>
                </NavItem>
                <NavItem
                    eventKey="admin-donate"
                >
                    <NavIcon>
                    <FaMoneyBillAlt
                    style={{
                                fontSize: "20px",
                                color:
                                    selectStatus === "admin-donate"
                                        ? APP_COLOR_FOCUS
                                        : selectedTabBackgroundColor,
                            }} />

                        
                    </NavIcon>
                    <NavText
                        style={{
                            color:
                                selectStatus === "admin-donate"
                                    ? APP_COLOR_FOCUS
                                    : selectedTabBackgroundColor,
                            fontSize: "17px",
                        }}
                    >
                        Donation
                    </NavText>
                </NavItem>


                <NavItem
                    eventKey="admin-settings"

                >
                    <NavIcon>
                        <FontAwesomeIcon
                            icon={faCogs}
                            style={{
                                fontSize: "20px",
                                color:
                                    selectStatus === "admin-settings"
                                        ? APP_COLOR_FOCUS
                                        : selectedTabBackgroundColor,
                            }}
                        />
                    </NavIcon>
                    <NavText
                        style={{
                            color:
                                selectStatus === "admin-settings"
                                    ? APP_COLOR_FOCUS
                                    : selectedTabBackgroundColor,
                            fontSize: "17px",
                        }}
                    >
                        Settings
                    </NavText>
                </NavItem>
            </SideNav.Nav>
        </SideNav>
    );
}
