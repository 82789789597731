
import { useMutation } from '@apollo/react-hooks'
import useReactRouter from "use-react-router";
import { CREATE_CERTIFICATE_CATEGORY,UPDATE_CERTIFICATE_CATEGORY } from '../apollo/certificateCategory/Mutation'
import { successAdd, errorAdd } from '../helpers/sweetalert'
import Routers from "../consts";

export default function CreateCertificateCategory() {
    const { history } = useReactRouter();

    // ====== updateCategory ======>
    const [updateCertificateCategory] = useMutation(UPDATE_CERTIFICATE_CATEGORY)
    const _updateCertificateCategory = async (item, DataAction) => {
        try {
            if (item?.name === DataAction?.name) {
                delete item?.name
            }
            let _updateData = await updateCertificateCategory({
                variables: {
                    data: {
                        name: item?.name,
                        detail: item?.detail,
                    },
                    where: {
                        id: item?.id
                    }
                }
            })
            if (_updateData?.data?.updateCategoryCertificate) {
                await successAdd("Update Success")
                history.replace(Routers.PAGE_CERTIFICATE_CATEGORY_LIST)
            }
        } catch (error) {
            errorAdd(error)
        }
    }
    // ====== createCategory ======>
    const [createCertificateCategory] = useMutation(CREATE_CERTIFICATE_CATEGORY)
    const _createCertificateCategory = async (items) => {
        try {
            let _createData = await createCertificateCategory({
                variables: {
                    data: items
                }
            })
            if (_createData?.data?.createCategoryCertificate) {
                await successAdd("Success")
                history.replace(Routers.PAGE_CERTIFICATE_CATEGORY_LIST)
            }
        } catch (error) {
            errorAdd(error)
        }
    }
    // ====== deleteCategory ======>
    // const [deleteStaff] = useMutation(DELETE_STAFF)
    // const _deleteStaff = async (items, handleCloseDelete) => {
    //     try {
    //         let _deleteData = await deleteStaff({
    //             variables: {
    //                 where: { id: items }
    //             }
    //         })
    //         if (_deleteData?.data?.deleteUser) {
    //             await successAdd("ລົບຂໍ້ມູນສຳເລັດ")
    //             await handleCloseDelete()
    //             history.replace(Routers.PAGE_STAFF)
    //         }
    //     } catch (error) {
    //         errorAdd(error)
    //     }
    // }


    return {
        _updateCertificateCategory,
        // _deleteStaff,
        _createCertificateCategory
    }
}