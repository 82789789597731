import Swal from 'sweetalert2'
import { errorCass }from './Helper'
export const successAdd = (item) => {
    Swal.fire({
        icon: 'success',
        title: item,
        showConfirmButton: false,
        timer: 1500
    })
}
export const errorAdd = (item) => {
    Swal.fire({
        icon: 'error',
        text: errorCass(item?.message),
        showConfirmButton: false,
        timer: 1500
    })
}