import React, { useState, useEffect } from 'react'
import useReactRouter from "use-react-router";
import { useLazyQuery } from '@apollo/react-hooks'


import { Breadcrumb, Table, Row, Modal, Col, Image, Button, ListGroup } from 'react-bootstrap'
import Consts from "../../../consts"
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { Title, CustomButton } from "../../../common"
import { dateTimeLao } from "../../../helpers/Helper";
import { CLIMATE_DATA } from "../../../apollo/climate"
import CUSD_STAFF from '../../../crud/user';


function ClimateListDetail() {
    const { history, location } = useReactRouter();

    //state
    const [totalOf, setTotalOf] = useState(0)
    const [nextImage, setNextImage] = useState(0)

    const [selectMap, setSelectMap] = useState(
        {
            lat:0,
            lng:0,
        }
    )
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [dataImages, setDataImages] = useState([]);
    const [showImage, setShowImage] = useState(false);
    const handleCloseImage = () => setShowImage(false);
    const handleShowImage = (item) => {
        setDataImages(item)
        setShowImage(true)
    }

    const {
        _deleteStaff
    } = CUSD_STAFF();
    //function
    const [getUsers, { data }] = useLazyQuery(CLIMATE_DATA, {
        fetchPolicy: "network-only",
        variables: {
            where: {
               userId:location?.state?.id
            }
        }
    });
    //function
    useEffect(() => {
        getUsers()
    }, [])

    useEffect(() => {
        if (data?.plantDonations?.data) {
            let _tree=0
            for (let i = 0; i < data?.plantDonations?.data?.length; i++){
                _tree += data?.plantDonations?.data[i]?.numberDonated
            }
            setTotalOf(_tree)
        }
    }, [data?.plantDonations?.data])

    const _selectGoogleMap = (item) => {
        setSelectMap(
            {
                lat: item?.lat,
                lng: item?.long,
            }
        )
    }
    return (
        <div>
            <Breadcrumb style={{ marginTop: 60, fontSize: '12px' }}>
                <Breadcrumb.Item href="#" onClick={() => history.goBack()}>Staff Managerment</Breadcrumb.Item>
                <Breadcrumb.Item active>Climate Action View</Breadcrumb.Item>
            </Breadcrumb>

            <div style={Consts.MAIN_CARD} >
                <div style={{ display: "flex", justifyContent: "space-between", marginTop: 20, marginBottom: 20 }}>
                    <Title text='Climate Action View' />

                    <div>
                        <CustomButton editIcon title='Edit '
                            style={{ border: 0 }}
                            onClick={() => history.push(Consts.PAGE_CLIMATE_EDIT, location?.state) }//PAGE_EDIT_VOLUNTEER
                        />
                        {' '}
                        <CustomButton addDelete title='Delete '
                            style={{ border: 0 }}
                            onClick={() => handleShow()}
                        />

                    </div>
                </div>
                <hr></hr>


                <Row>
                    <Col md={2} style={{ justifyContent: 'center', display: 'flex' }}>
                        <Image src={location?.state?.image ? Consts.URL_FOR_SHOW_PHOTO + location?.state?.image : "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Circle-icons-profile.svg/1024px-Circle-icons-profile.svg.png"}
                            style={{ width: 220, height: 220, borderRadius: 10 }}
                        />
                    </Col>
                    <Col md={5}>
                        <div style={{ backgroundColor: "#F6F6F6", color: "#000000", fontSize: '18px', fontWeight: "400", padding: 10, marginBottom: 10 }}>
                            General information
                        </div>
                        <ListGroup variant="flush">
                            <ListGroup.Item style={{ fontWeight: "400", padding: 15 }}>Name and surname <span style={{ float: 'right' }}>{location?.state?.firstName ?? "-"}</span></ListGroup.Item>
                            <ListGroup.Item style={{ fontWeight: "400", padding: 15 }}>Gender <span style={{ float: 'right' }}>{location?.state?.gender ?? "-"}</span></ListGroup.Item>
                            <ListGroup.Item style={{ fontWeight: "400", padding: 15 }}>Date of birth <span style={{ float: 'right' }}>{dateTimeLao(location?.state?.birthday) ?? "-"}</span></ListGroup.Item>
                            <ListGroup.Item style={{ fontWeight: "400", padding: 15 }}>Phone number <span style={{ float: 'right' }}>{location?.state?.phone ?? "-"}</span></ListGroup.Item>
                            <ListGroup.Item style={{ fontWeight: "400", padding: 15 }}>Whatsapp <span style={{ float: 'right' }}>{location?.state?.whatsapp ?? "-"}</span></ListGroup.Item>
                            <ListGroup.Item style={{ fontWeight: "400", padding: 15 }}>Ethnicity In case you are a foreigner <span style={{ float: 'right' }}>{location?.state?.ethnicity ?? "-"}</span></ListGroup.Item>
                            <ListGroup.Item style={{ fontWeight: "400", padding: 15 }}>Nationality <span style={{ float: 'right' }}>{location?.state?.nationality ?? "-"}</span></ListGroup.Item>
                            <ListGroup.Item style={{ fontWeight: "400", padding: 15 }}>Occupation <span style={{ float: 'right' }}>{location?.state?.occupation ?? "-"}</span></ListGroup.Item>
                            <ListGroup.Item style={{ fontWeight: "400", padding: 15 }}>Company/ Organization/ Institution <span style={{ float: 'right' }}>{location?.state?.company ?? "-"}</span></ListGroup.Item>
                        </ListGroup>
                    </Col>
                    <Col md={5}>
                        <div style={{ backgroundColor: "#F6F6F6", color: "#000000", fontSize: '18px', fontWeight: "400", padding: 10, marginBottom: 10 }}>
                            Brith place information
                        </div>

                        <ListGroup variant="flush">
                            <ListGroup.Item style={{ fontWeight: "400", padding: 15 }}>Province of birth <span style={{ float: 'right' }}>{location?.state?.provinceOfBirth ?? "-"}</span></ListGroup.Item>
                            <ListGroup.Item style={{ fontWeight: "400", padding: 15 }}>District of birth <span style={{ float: 'right' }}>{location?.state?.districtOfBirth ?? "-"}</span></ListGroup.Item>
                            <ListGroup.Item style={{ fontWeight: "400", padding: 15 }}>Village of birth <span style={{ float: 'right' }}>{location?.state?.villageOfBirth ?? "-"}</span></ListGroup.Item>

                        </ListGroup>
                        <div style={{ backgroundColor: "#F6F6F6", color: "#000000", fontSize: '18px', fontWeight: "400", padding: 10, marginBottom: 10 }}>
                            User and Password
                        </div>

                        <ListGroup variant="flush">
                            <ListGroup.Item style={{ fontWeight: "400", padding: 15 }}>Email <span style={{ float: 'right' }}>{location?.state?.email ?? "-"}</span></ListGroup.Item>
                            <ListGroup.Item style={{ fontWeight: "400", padding: 15 }}>Password <span style={{ float: 'right' }}>********</span></ListGroup.Item>

                        </ListGroup>
                    </Col>

                    <Col md={{ span: 10, offset: 2 }}>
                        <div style={{ backgroundColor: "#F6F6F6", color: "#000000", fontSize: '18px', fontWeight: "400", padding: 10, marginBottom: 10 }}>
                            Plant Donate
                        </div>

                        <ListGroup variant="flush">
                            <ListGroup.Item style={{ fontWeight: "400", padding: 15 }}>
                                Would you join us in planting activity?
                                <span style={{ float: 'right' }}>{location?.state?.plantingActivity ?? "-"}</span></ListGroup.Item>
                            <ListGroup.Item style={{ fontWeight: "400", padding: 15 }}>
                                Your intention to join us 
                                <span style={{ float: 'right', overflowWrap: "break-word" }}>{location?.state?.intention ?? "-"}</span></ListGroup.Item>
                        </ListGroup>
                    </Col>
                    <Col md={{ span: 10, offset: 2 }}>
                        <div style={{ backgroundColor: "#F6F6F6", color: "#000000", fontSize: '18px', fontWeight: "400", padding: 10, marginBottom: 10 }}>
                            Lisf of Plant Donate
                        </div>
                        <Table class="table">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Category</th>
                                    <th>Province</th>
                                    <th>District</th>
                                    <th>Village</th>
                                    <th>Quantity</th>
                                    <th>Carbon</th>
                                    <th>All Carbon</th>
                                    <th>createdAt</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.plantDonations?.data?.map((item, index) =>
                                    <tr key={{index}}>
                                        <td>{index + 1}</td>
                                        <td onClick={() => handleShowImage(item?.images)}>
                                            <Image src={item?.images?.length > 0 ? Consts.URL_FOR_SHOW_PHOTO + item?.images[0] :
                                                "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Circle-icons-profile.svg/1024px-Circle-icons-profile.svg.png"}
                                                style={{ width: 40, height: 40,borderRadius:"50%" }}
                                            />
                                            &nbsp;&nbsp;&nbsp;
                                            {item?.typeOfDonated}
                                        </td>
                                        <td onClick={()=>_selectGoogleMap(item)}>{item?.province ?? "-"}</td>
                                        <td onClick={()=>_selectGoogleMap(item)}>{item?.district ?? "-"}</td>
                                        <td onClick={()=>_selectGoogleMap(item)}>{item?.village ?? "-"}</td>
                                        <td onClick={()=>_selectGoogleMap(item)}>{item?.numberDonated ?? "-"}</td>
                                        <td onClick={()=>_selectGoogleMap(item)}>15Kg</td>
                                        <td onClick={()=>_selectGoogleMap(item)}>{item?.numberDonated*15} kg</td>
                                        <td onClick={()=>_selectGoogleMap(item)}>{dateTimeLao(item?.createdAt)}</td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </Col>
                    <Col md={{ span: 10, offset: 2 }}>
                        <div style={{display:"flex",justifyContent:"space-between"}}>
                            <div className='col-5' style={{ fontWeight: "bold" }}>
                                <div style={{ padding: 10 }}>Include all trees: {totalOf}</div>
                                <div style={{ padding: 10 }}>All Carbon Included: {totalOf*15}Kg</div>
                            </div>
                            <div className='col-7' style={{textAlign:"center",fontWeight:"bold"}}>
                                <div style={{ padding: 10 }}>Google Map</div>
                                <LoadScript
                                    googleMapsApiKey={Consts.KEY_API_GOOGLEMAP}>
                                    <GoogleMap
                                        mapContainerStyle={{
                                            height: "50vh",
                                            width: "100%"
                                        }}
                                        zoom={12}
                                        center={{
                                            lat: selectMap?.lat, lng: selectMap?.lng
                                        }}
                                    >
                                        <Marker position={{ lat: selectMap?.lat, lng: selectMap?.lng }} />
                                    </GoogleMap>
                                </LoadScript>
                            </div>
                        </div>
                    </Col>
                    <div style={{ height: 50 }}></div>
                    <Col md={4}>
                        <ListGroup variant="flush">
                            <ListGroup.Item style={{ fontWeight: "400", padding: 15 }}>Created by <span style={{ float: 'right' }}>{location?.state?.createdBy?.firstName ?? "-"}<br />
                                {dateTimeLao(location?.state?.createdAt)}</span></ListGroup.Item>
                            <ListGroup.Item style={{ fontWeight: "400", padding: 15 }}>Updated by <span style={{ float: 'right' }}>{location?.state?.updatedBy?.firstName ?? "-"}<br />
                                {dateTimeLao(location?.state?.updatedAt)}</span></ListGroup.Item>
                        </ListGroup>
                    </Col>
                </Row>
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Staff</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    Do you want delete {location?.state?.firstName ?? "-"} ?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={() => {
                        _deleteStaff(location?.state?.id, handleClose, Consts.PAGE_CLIMATE_LIST)
                    }
                    }>
                        Confirm delete
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showImage}
                centered
                size="lg"
                onHide={handleCloseImage}>
                <Modal.Body className="text-center">
                    <div className='row' style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                        <div style={{ padding: 20, fontWeight: "bold" }} onClick={() => setNextImage(nextImage <= 0 ? 0 : nextImage - 1)} >Next</div>
                        <Image src={dataImages?.length > 0 ? Consts.URL_FOR_SHOW_PHOTO + dataImages[nextImage] :
                            "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Circle-icons-profile.svg/1024px-Circle-icons-profile.svg.png"}
                            style={{
                                width: "80%",
                                height: 450,
                                objectFit: "cover",
                            }}
                        />
                        <div style={{ padding: 20, fontWeight: "bold" }} onClick={() => setNextImage(nextImage <= dataImages?.length ? dataImages?.length-1 : nextImage+1)} >Next</div>
                    </div>
                    
                </Modal.Body>
            </Modal>

        </div >
    )
}
export default ClimateListDetail