// ignore_for_file: file_names, unused_import, use_key_in_widget_constructors, prefer_const_constructors, sized_box_for_whitespace, avoid_unnecessary_containers, unnecessary_new, prefer_const_literals_to_create_immutables, camel_case_types, override_on_non_overriding_member, unused_local_variable, prefer_typing_uninitialized_variables, prefer_const_declarations, unnecessary_this, prefer_const_constructors_in_immutables, no_logic_in_create_state, must_be_immutable, prefer_final_fields, avoid_print, deprecated_member_use, non_constant_identifier_names
export const ADDRESSES = {
    "provinces": [
      {"pr_name": "Select the province", "districts": []},
      {
        "pr_id": "1",
        "pr_name": "ນະຄອນຫລວງວຽງຈັນ",
        "pr_name_en": "Vientiane capital",
        "districts": [
          {"dr_name": "Select the district", "villages": []},
          {
            "dr_id": "101",
            "dr_name": "ຈັນທະບູລີ",
            "dr_name_en": "Chanthabuly",
            "pr_id": "1",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "10101",
                "vill_name": "ໜອງປິງ",
                "vill_name_en": "Nongping",
                "dr_id": "101"
              },
              {
                "vill_id": "10102",
                "vill_name": "ບໍ່ນາງົວ",
                "vill_name_en": "Bornangoua",
                "dr_id": "101"
              },
              {
                "vill_id": "10103",
                "vill_name": "ຫ້ວຍຫົງ",
                "vill_name_en": "Houyhong",
                "dr_id": "101"
              },
              {
                "vill_id": "10104",
                "vill_name": "ໂພນສະຫວ່າງ",
                "vill_name_en": "Phonsavang",
                "dr_id": "101"
              },
              {
                "vill_id": "10105",
                "vill_name": "ໜອງທາເໜືອ",
                "vill_name_en": "Nongthanuea",
                "dr_id": "101"
              },
              {
                "vill_id": "10106",
                "vill_name": "ໜອງທາໃຕ້",
                "vill_name_en": "Nongthatay",
                "dr_id": "101"
              },
              {
                "vill_id": "10107",
                "vill_name": "ດອນແດງ",
                "vill_name_en": "Donedeng",
                "dr_id": "101"
              },
              {
                "vill_id": "10108",
                "vill_name": "ໂພນຕ້ອງສະຫວາດ",
                "vill_name_en": "Phontongsavath",
                "dr_id": "101"
              },
              {
                "vill_id": "10109",
                "vill_name": "ດົງປ່າແຫຼບ",
                "vill_name_en": "Dongpalaep",
                "dr_id": "101"
              },
              {
                "vill_id": "10110",
                "vill_name": "ໂພນຕ້ອງຈອມມະນີ",
                "vill_name_en": "Phontongchommany",
                "dr_id": "101"
              },
              {
                "vill_id": "10111",
                "vill_name": "ຮ່ອງແຊງ",
                "vill_name_en": "Hongxeng",
                "dr_id": "101"
              },
              {
                "vill_id": "10112",
                "vill_name": "ຮ່ອງໄກ່ແກ້ວ",
                "vill_name_en": "Hongkaikeo",
                "dr_id": "101"
              },
              {
                "vill_id": "10113",
                "vill_name": "ທົ່ງສ້າງນາງ",
                "vill_name_en": "Thongsangnang",
                "dr_id": "101"
              },
              {
                "vill_id": "10114",
                "vill_name": "ສະຫວ່າງ",
                "vill_name_en": "Savang",
                "dr_id": "101"
              },
              {
                "vill_id": "10115",
                "vill_name": "ຮ່ອງຄ້າ",
                "vill_name_en": "Hongkha",
                "dr_id": "101"
              },
              {
                "vill_id": "10116",
                "vill_name": "ທົ່ງຕູມ",
                "vill_name_en": "Thongtoum",
                "dr_id": "101"
              },
              {
                "vill_id": "10117",
                "vill_name": "ດົງໝ້ຽງ",
                "vill_name_en": "Dongmieng",
                "dr_id": "101"
              },
              {
                "vill_id": "10118",
                "vill_name": "ສີດຳດວນ",
                "vill_name_en": "Sidamduan",
                "dr_id": "101"
              },
              {
                "vill_id": "10119",
                "vill_name": "ສີບຸນເຮືອງ",
                "vill_name_en": "Sibounheuang",
                "dr_id": "101"
              },
              {
                "vill_id": "10120",
                "vill_name": "ຂົວຫຼວງ",
                "vill_name_en": "Khualuang",
                "dr_id": "101"
              },
              {
                "vill_id": "10121",
                "vill_name": "ສີສະຫວາດ",
                "vill_name_en": "Sisavath",
                "dr_id": "101"
              },
              {
                "vill_id": "10122",
                "vill_name": "ທົ່ງຂັນຄຳ",
                "vill_name_en": "Thongkhankham",
                "dr_id": "101"
              },
              {
                "vill_id": "10123",
                "vill_name": "ສີຫອມ",
                "vill_name_en": "Sihome",
                "dr_id": "101"
              },
              {
                "vill_id": "10124",
                "vill_name": "ອານຸ",
                "vill_name_en": "Anou",
                "dr_id": "101"
              },
              {
                "vill_id": "10125",
                "vill_name": "ສາຍລົມ",
                "vill_name_en": "Saylom",
                "dr_id": "101"
              },
              {
                "vill_id": "10126",
                "vill_name": "ຫັດສະດີເໝືອ",
                "vill_name_en": "Hadsadineua",
                "dr_id": "101"
              },
              {
                "vill_id": "10127",
                "vill_name": "ຫາຍໂສກ",
                "vill_name_en": "Haixok",
                "dr_id": "101"
              },
              {
                "vill_id": "10128",
                "vill_name": "ວັດຈັນ",
                "vill_name_en": "Watchan",
                "dr_id": "101"
              },
              {
                "vill_id": "10129",
                "vill_name": "ມີໄຊ",
                "vill_name_en": "Meexay",
                "dr_id": "101"
              },
              {
                "vill_id": "10130",
                "vill_name": "ຊຽງຍຶນ",
                "vill_name_en": "Xiengnguen",
                "dr_id": "101"
              },
              {
                "vill_id": "10131",
                "vill_name": "ດົງຊຽງດີ",
                "vill_name_en": "Dongxiengdy",
                "dr_id": "101"
              },
              {
                "vill_id": "10132",
                "vill_name": "ຕານມີໄຊ",
                "vill_name_en": "Tanmeexay",
                "dr_id": "101"
              },
              {
                "vill_id": "10133",
                "vill_name": "ສີສະເກດ",
                "vill_name_en": "Sysaked",
                "dr_id": "101"
              },
              {
                "vill_id": "10134",
                "vill_name": "ຫັດສະດີໃຕ້",
                "vill_name_en": "Hatsadee tai",
                "dr_id": "101"
              },
              {
                "vill_id": "10135",
                "vill_name": "ຈອມມະນີ",
                "vill_name_en": "Jommani",
                "dr_id": "101"
              }
            ]
          },
          
          {
            "dr_id": "102",
            "dr_name": "ສີໂຄດຕະບອງ",
            "dr_name_en": "Sikhottabong",
            "pr_id": "1",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "10201",
                "vill_name": "ນາຄຳ",
                "vill_name_en": "Nakham",
                "dr_id": "102"
              },
              {
                "vill_id": "10202",
                "vill_name": "ອຸບມຸງ",
                "vill_name_en": "Obmoung",
                "dr_id": "102"
              },
              {
                "vill_id": "10203",
                "vill_name": "ໜອງປ່າໄນ",
                "vill_name_en": "Nongpanai",
                "dr_id": "102"
              },
              {
                "vill_id": "10204",
                "vill_name": "ວັດໄຕນ້ອຍທ່າ",
                "vill_name_en": "Wattainoytha",
                "dr_id": "102"
              },
              {
                "vill_id": "10205",
                "vill_name": "ວັດໄຕນ້ອຍທົ່ງ",
                "vill_name_en": "Wattainoythong",
                "dr_id": "102"
              },
              {
                "vill_id": "10206",
                "vill_name": "ໜອງສະໂນຄຳ",
                "vill_name_en": "Nongsanokham",
                "dr_id": "102"
              },
              {
                "vill_id": "10207",
                "vill_name": "ວັດໄຕໃຫ່ຍທົ່ງ",
                "vill_name_en": "",
                "dr_id": "102"
              },
              {
                "vill_id": "10208",
                "vill_name": "ວັດໄຕໃຫ່ຍທ່າ",
                "vill_name_en": "",
                "dr_id": "102"
              },
              {
                "vill_id": "10209",
                "vill_name": "ອາກາດ",
                "vill_name_en": "Akard",
                "dr_id": "102"
              },
              {
                "vill_id": "10210",
                "vill_name": "ເມຶອງວາທາ",
                "vill_name_en": "Meuangvatha",
                "dr_id": "102"
              },
              {
                "vill_id": "10211",
                "vill_name": "ເມຶອງວາທົ່ງ",
                "vill_name_en": "Meuangvathong",
                "dr_id": "102"
              },
              {
                "vill_id": "10212",
                "vill_name": "ສີໄຄທ່າ",
                "vill_name_en": "Sikhaitha",
                "dr_id": "102"
              },
              {
                "vill_id": "10213",
                "vill_name": "ສີໄຄທົ່ງ",
                "vill_name_en": "Sikhaithong",
                "dr_id": "102"
              },
              {
                "vill_id": "10214",
                "vill_name": "ຍາຜະ",
                "vill_name_en": "Yapha",
                "dr_id": "102"
              },
              {
                "vill_id": "10215",
                "vill_name": "ສີບຸນເຮືອງທ່າ",
                "vill_name_en": "Sibounheuangtha",
                "dr_id": "102"
              },
              {
                "vill_id": "10216",
                "vill_name": "ສີບຸນເຮືອງທົ່ງ",
                "vill_name_en": "Sibounheuangthong",
                "dr_id": "102"
              },
              {
                "vill_id": "10217",
                "vill_name": "ສີຊົມຊື່ນ",
                "vill_name_en": "Sisomsuen",
                "dr_id": "102"
              },
              {
                "vill_id": "10218",
                "vill_name": "ໂນນສະຫວ່າງ",
                "vill_name_en": "Nonsavang",
                "dr_id": "102"
              },
              {
                "vill_id": "10219",
                "vill_name": "ເກົ້າລ້ຽວ",
                "vill_name_en": "Kaoliew",
                "dr_id": "102"
              },
              {
                "vill_id": "10220",
                "vill_name": "ດ່ານຄຳ",
                "vill_name_en": "Dankham",
                "dr_id": "102"
              },
              {
                "vill_id": "10221",
                "vill_name": "ໂນ້ນຂີ້ເຫຼັກ",
                "vill_name_en": "Nonkhileck",
                "dr_id": "102"
              },
              {
                "vill_id": "10222",
                "vill_name": "ໂນ້ນແກ້ວ",
                "vill_name_en": "Nonkeo",
                "dr_id": "102"
              },
              {
                "vill_id": "10223",
                "vill_name": "ໂພນສະຫວາດເໜືອ",
                "vill_name_en": "Phonsavathneua",
                "dr_id": "102"
              },
              {
                "vill_id": "10224",
                "vill_name": "ໂພນສົມບູນ",
                "vill_name_en": "Phonsomboun",
                "dr_id": "102"
              },
              {
                "vill_id": "10225",
                "vill_name": "ຕາດທອງ",
                "vill_name_en": "Tadthong",
                "dr_id": "102"
              },
              {
                "vill_id": "10226",
                "vill_name": "ໜອງດາ",
                "vill_name_en": "Nongda",
                "dr_id": "102"
              },
              {
                "vill_id": "10227",
                "vill_name": "ໃໝ່",
                "vill_name_en": "May",
                "dr_id": "102"
              },
              {
                "vill_id": "10229",
                "vill_name": "ອ່າງໃຫ່ຍ",
                "vill_name_en": "Angyai",
                "dr_id": "102"
              },
              {
                "vill_id": "10230",
                "vill_name": "ໂພສີ",
                "vill_name_en": "Phosy",
                "dr_id": "102"
              },
              {
                "vill_id": "10231",
                "vill_name": "ນາແຮ່",
                "vill_name_en": "Nahae",
                "dr_id": "102"
              },
              {
                "vill_id": "10232",
                "vill_name": "ວຽງສະຫວັນ",
                "vill_name_en": "Viengsavanh",
                "dr_id": "102"
              },
              {
                "vill_id": "10233",
                "vill_name": "ທົ່ງປົ່ງ",
                "vill_name_en": "Thongpong",
                "dr_id": "102"
              },
              {
                "vill_id": "10234",
                "vill_name": "ໜອງໜ້ຽວ",
                "vill_name_en": "Nongniew",
                "dr_id": "102"
              },
              {
                "vill_id": "10235",
                "vill_name": "ໜອງແຕ່ງໃຕ້",
                "vill_name_en": "Nongthatai",
                "dr_id": "102"
              },
              {
                "vill_id": "10236",
                "vill_name": "ໜອງແຕ່ງເໝຶອ",
                "vill_name_en": "Nongthaneua",
                "dr_id": "102"
              },
              {
                "vill_id": "10237",
                "vill_name": "ວຽງຄຳ",
                "vill_name_en": "Viengkham",
                "dr_id": "102"
              },
              {
                "vill_id": "10238",
                "vill_name": "ຈຳປາ",
                "vill_name_en": "Champa",
                "dr_id": "102"
              },
              {
                "vill_id": "10239",
                "vill_name": "ດົງນາໂຊກໃຕ້",
                "vill_name_en": "Dongnaxoktai",
                "dr_id": "102"
              },
              {
                "vill_id": "10240",
                "vill_name": "ດົງນາໂຊກເໝຶອ",
                "vill_name_en": "Dongnaxokneua",
                "dr_id": "102"
              },
              {
                "vill_id": "10241",
                "vill_name": "ປາກທ້າງ",
                "vill_name_en": "Pakthang",
                "dr_id": "102"
              },
              {
                "vill_id": "10242",
                "vill_name": "ດົງນາທອງ",
                "vill_name_en": "Dongnathong",
                "dr_id": "102"
              },
              {
                "vill_id": "10243",
                "vill_name": "ຫຼັກຫີນ",
                "vill_name_en": "Luckhin",
                "dr_id": "102"
              },
              {
                "vill_id": "10244",
                "vill_name": "ໜອງບຶກເໜືອ",
                "vill_name_en": "Nongbuek",
                "dr_id": "102"
              },
              {
                "vill_id": "10245",
                "vill_name": "ດົງກະເລົາ",
                "vill_name_en": "Dongkalao",
                "dr_id": "102"
              },
              {
                "vill_id": "10246",
                "vill_name": "ຊຳເກດ",
                "vill_name_en": "Xamket",
                "dr_id": "102"
              },
              {
                "vill_id": "10247",
                "vill_name": "ຂຸນຕາທົ່ງ",
                "vill_name_en": "Khountathong",
                "dr_id": "102"
              },
              {
                "vill_id": "10248",
                "vill_name": "ຂຸນຕາທ່າ",
                "vill_name_en": "Khountatha",
                "dr_id": "102"
              },
              {
                "vill_id": "10249",
                "vill_name": "ສີຖານເໜືອ",
                "vill_name_en": "Sithanneua",
                "dr_id": "102"
              },
              {
                "vill_id": "10250",
                "vill_name": "ໂພນຄຳ",
                "vill_name_en": "Phonkham",
                "dr_id": "102"
              },
              {
                "vill_id": "10251",
                "vill_name": "ໜອງດວງເໜືອ",
                "vill_name_en": "Nongduangneua",
                "dr_id": "102"
              },
              {
                "vill_id": "10252",
                "vill_name": "ໜອງດວງໃຕ້",
                "vill_name_en": "Nongduangtai",
                "dr_id": "102"
              },
              {
                "vill_id": "10253",
                "vill_name": "ໜອງດວງທົ່ງ",
                "vill_name_en": "Nongduangthong",
                "dr_id": "102"
              },
              {
                "vill_id": "10254",
                "vill_name": "ໂພນສະຫວາດໃຕ້",
                "vill_name_en": "Phonsavathtai",
                "dr_id": "102"
              },
              {
                "vill_id": "10255",
                "vill_name": "ໜອງບົວທອງໃຕ້",
                "vill_name_en": "Nongbuathongtai",
                "dr_id": "102"
              },
              {
                "vill_id": "10256",
                "vill_name": "ໜອງບົວທອງເໜຶອ",
                "vill_name_en": "Nongbuathongneua",
                "dr_id": "102"
              },
              {
                "vill_id": "10257",
                "vill_name": "ຈັນສະຫວ່າງ",
                "vill_name_en": "Chansavang",
                "dr_id": "102"
              },
              {
                "vill_id": "10258",
                "vill_name": "ດອນຊິງຊູ້",
                "vill_name_en": "Donexingxu",
                "dr_id": "102"
              },
              {
                "vill_id": "10259",
                "vill_name": "ໜອງບຶກໃຕ້",
                "vill_name_en": "Nongbuektai",
                "dr_id": "102"
              },
              {
                "vill_id": "10260",
                "vill_name": "ຫຼັກຫີນ",
                "vill_name_en": "Lakhin",
                "dr_id": "102"
              },
              {
                "vill_id": "10261",
                "vill_name": "ຫ້ວຍຫ້ອມ",
                "vill_name_en": "Houayhom",
                "dr_id": "102"
              },
              {
                "vill_id": "10262",
                "vill_name": "ນາເລົ່າ",
                "vill_name_en": "Nalao",
                "dr_id": "102"
              },
              {
                "vill_id": "10263",
                "vill_name": "ໜອງດ້ວງ",
                "vill_name_en": "Nongduang",
                "dr_id": "102"
              },
              {
                "vill_id": "10264",
                "vill_name": "ໜອງຈັນ",
                "vill_name_en": "Nongchan",
                "dr_id": "102"
              },
              {
                "vill_id": "10265",
                "vill_name": "ໜອງອໍ້",
                "vill_name_en": "Nongeor",
                "dr_id": "102"
              },
              {
                "vill_id": "10267",
                "vill_name": "ປ່ອງຄຳ",
                "vill_name_en": "Pongkham",
                "dr_id": "102"
              }
            ]
          },
          {
            "dr_id": "103",
            "dr_name": "ໄຊເສດຖາ",
            "dr_name_en": "Xaysetha",
            "pr_id": "1",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "10301",
                "vill_name": "ຈອມະນີເໜືອ",
                "vill_name_en": "",
                "dr_id": "103"
              },
              {
                "vill_id": "10302",
                "vill_name": "ຈອມະນີກາງ",
                "vill_name_en": "",
                "dr_id": "103"
              },
              {
                "vill_id": "10303",
                "vill_name": "ຈອມະນີໃຕ້",
                "vill_name_en": "",
                "dr_id": "103"
              },
              {
                "vill_id": "10304",
                "vill_name": "ໂພນພະເນົາ",
                "vill_name_en": "",
                "dr_id": "103"
              },
              {
                "vill_id": "10305",
                "vill_name": "ໂພນເຄັງ",
                "vill_name_en": "",
                "dr_id": "103"
              },
              {
                "vill_id": "10306",
                "vill_name": "ໜອງສ້າງທໍ່",
                "vill_name_en": "",
                "dr_id": "103"
              },
              {
                "vill_id": "10307",
                "vill_name": "ໂພນສະອາດ",
                "vill_name_en": "",
                "dr_id": "103"
              },
              {
                "vill_id": "10308",
                "vill_name": "ທາດຫຼວງເໜຶອ",
                "vill_name_en": "",
                "dr_id": "103"
              },
              {
                "vill_id": "10309",
                "vill_name": "ໜອງບອນ",
                "vill_name_en": "",
                "dr_id": "103"
              },
              {
                "vill_id": "10310",
                "vill_name": "ໂພນໄຊ",
                "vill_name_en": "",
                "dr_id": "103"
              },
              {
                "vill_id": "10311",
                "vill_name": "ນາໄຊ",
                "vill_name_en": "",
                "dr_id": "103"
              },
              {
                "vill_id": "10312",
                "vill_name": "ບ້ານຝາຍ",
                "vill_name_en": "",
                "dr_id": "103"
              },
              {
                "vill_id": "10313",
                "vill_name": "ວຽງຈະເລີນ",
                "vill_name_en": "",
                "dr_id": "103"
              },
              {
                "vill_id": "10314",
                "vill_name": "ທາດຫຼວງກາງ",
                "vill_name_en": "",
                "dr_id": "103"
              },
              {
                "vill_id": "10315",
                "vill_name": "ທາດຫຼວງໃຕ້",
                "vill_name_en": "",
                "dr_id": "103"
              },
              {
                "vill_id": "10316",
                "vill_name": "ຮ່ອງແກ",
                "vill_name_en": "",
                "dr_id": "103"
              },
              {
                "vill_id": "10317",
                "vill_name": "ສີສັງວອນ",
                "vill_name_en": "",
                "dr_id": "103"
              },
              {
                "vill_id": "10318",
                "vill_name": "ສະພັງໝໍ້",
                "vill_name_en": "",
                "dr_id": "103"
              },
              {
                "vill_id": "10319",
                "vill_name": "ໂພນທັນໃຕ້",
                "vill_name_en": "",
                "dr_id": "103"
              },
              {
                "vill_id": "10320",
                "vill_name": "ຮ່ອງສຸພາບ",
                "vill_name_en": "",
                "dr_id": "103"
              },
              {
                "vill_id": "10321",
                "vill_name": "ໂນນສະຫວັນ",
                "vill_name_en": "",
                "dr_id": "103"
              },
              {
                "vill_id": "10322",
                "vill_name": "ໜອງໝ່ຽງ",
                "vill_name_en": "",
                "dr_id": "103"
              },
              {
                "vill_id": "10323",
                "vill_name": "ໂນນສະຫງ່າ",
                "vill_name_en": "",
                "dr_id": "103"
              },
              {
                "vill_id": "10324",
                "vill_name": "ອາມອນ",
                "vill_name_en": "",
                "dr_id": "103"
              },
              {
                "vill_id": "10325",
                "vill_name": "ແສງສະຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "103"
              },
              {
                "vill_id": "10326",
                "vill_name": "ໂຊກຄຳ",
                "vill_name_en": "",
                "dr_id": "103"
              },
              {
                "vill_id": "10327",
                "vill_name": "ວັງຊາຍ",
                "vill_name_en": "",
                "dr_id": "103"
              },
              {
                "vill_id": "10328",
                "vill_name": "ໂນນສະຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "103"
              },
              {
                "vill_id": "10329",
                "vill_name": "ຫົວຂົວ",
                "vill_name_en": "",
                "dr_id": "103"
              },
              {
                "vill_id": "10330",
                "vill_name": "ໂນນຄໍ້ເໜືອ",
                "vill_name_en": "",
                "dr_id": "103"
              },
              {
                "vill_id": "10332",
                "vill_name": "ຄຳສະຫວາດ",
                "vill_name_en": "",
                "dr_id": "103"
              },
              {
                "vill_id": "10333",
                "vill_name": "ໂນນຫວາຍ",
                "vill_name_en": "",
                "dr_id": "103"
              },
              {
                "vill_id": "10334",
                "vill_name": "ເມຶອງນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "103"
              },
              {
                "vill_id": "10335",
                "vill_name": "ຊຽງດາ",
                "vill_name_en": "",
                "dr_id": "103"
              },
              {
                "vill_id": "10336",
                "vill_name": "ຂາມງອຍ",
                "vill_name_en": "",
                "dr_id": "103"
              },
              {
                "vill_id": "10337",
                "vill_name": "ນາສ້າງໄພ",
                "vill_name_en": "",
                "dr_id": "103"
              },
              {
                "vill_id": "10338",
                "vill_name": "ສົມສະຫງ່າ",
                "vill_name_en": "",
                "dr_id": "103"
              },
              {
                "vill_id": "10339",
                "vill_name": "ໄຮ່ຄຳ",
                "vill_name_en": "",
                "dr_id": "103"
              },
              {
                "vill_id": "10340",
                "vill_name": "ນາຄວາຍກາງ",
                "vill_name_en": "",
                "dr_id": "103"
              },
              {
                "vill_id": "10341",
                "vill_name": "ນາຄວາຍໃຕ້",
                "vill_name_en": "",
                "dr_id": "103"
              },
              {
                "vill_id": "10342",
                "vill_name": "ໂຊກໃຫ່ຍ",
                "vill_name_en": "",
                "dr_id": "103"
              },
              {
                "vill_id": "10343",
                "vill_name": "ໂຊກນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "103"
              },
              {
                "vill_id": "10344",
                "vill_name": "ຊຳເຄ້",
                "vill_name_en": "",
                "dr_id": "103"
              },
              {
                "vill_id": "10345",
                "vill_name": "ໂພນທອງ",
                "vill_name_en": "",
                "dr_id": "103"
              },
              {
                "vill_id": "10346",
                "vill_name": "ນາບຽນ",
                "vill_name_en": "",
                "dr_id": "103"
              },
              {
                "vill_id": "10347",
                "vill_name": "ດຸງໃຫ່ຍ",
                "vill_name_en": "",
                "dr_id": "103"
              },
              {
                "vill_id": "10348",
                "vill_name": "ນາໄຫ",
                "vill_name_en": "",
                "dr_id": "103"
              },
              {
                "vill_id": "10349",
                "vill_name": "ນາໂນ",
                "vill_name_en": "",
                "dr_id": "103"
              },
              {
                "vill_id": "10350",
                "vill_name": "ຈອມລີ",
                "vill_name_en": "",
                "dr_id": "103"
              },
              {
                "vill_id": "10351",
                "vill_name": "ໂນນຄໍ້ໃຕ້",
                "vill_name_en": "",
                "dr_id": "103"
              },
              {
                "vill_id": "10352",
                "vill_name": "ຝາຍ",
                "vill_name_en": "Fai",
                "dr_id": "103"
              }
            ]
          },
          {
            "dr_id": "104",
            "dr_name": "ສີສັດຕະນາກ",
            "dr_name_en": "Sisattanak",
            "pr_id": "1",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "10401",
                "vill_name": "ເພຍວັດ",
                "vill_name_en": "",
                "dr_id": "104"
              },
              {
                "vill_id": "10402",
                "vill_name": "ເກົ້າຍອດ",
                "vill_name_en": "",
                "dr_id": "104"
              },
              {
                "vill_id": "10403",
                "vill_name": "ສີເມຶອງ",
                "vill_name_en": "",
                "dr_id": "104"
              },
              {
                "vill_id": "10404",
                "vill_name": "ໜອງຈັນ",
                "vill_name_en": "",
                "dr_id": "104"
              },
              {
                "vill_id": "10405",
                "vill_name": "ດົງປ່າລານທ່າ",
                "vill_name_en": "",
                "dr_id": "104"
              },
              {
                "vill_id": "10406",
                "vill_name": "ດົງປ່າລານທົ່ງ",
                "vill_name_en": "",
                "dr_id": "104"
              },
              {
                "vill_id": "10407",
                "vill_name": "ໂພນສີນວນ",
                "vill_name_en": "",
                "dr_id": "104"
              },
              {
                "vill_id": "10408",
                "vill_name": "ສະພານທອງເໜຶອ",
                "vill_name_en": "",
                "dr_id": "104"
              },
              {
                "vill_id": "10409",
                "vill_name": "ສະພານທອງໃຕ້",
                "vill_name_en": "",
                "dr_id": "104"
              },
              {
                "vill_id": "10410",
                "vill_name": "ທົ່ງພານທອງ",
                "vill_name_en": "",
                "dr_id": "104"
              },
              {
                "vill_id": "10411",
                "vill_name": "ທາດຂາວ",
                "vill_name_en": "",
                "dr_id": "104"
              },
              {
                "vill_id": "10412",
                "vill_name": "ພະໄຊ",
                "vill_name_en": "",
                "dr_id": "104"
              },
              {
                "vill_id": "10413",
                "vill_name": "ພະໂພ",
                "vill_name_en": "",
                "dr_id": "104"
              },
              {
                "vill_id": "10414",
                "vill_name": "ໂພນສະຫວັນເໜຶອ",
                "vill_name_en": "",
                "dr_id": "104"
              },
              {
                "vill_id": "10415",
                "vill_name": "ໂພນສະຫວັນໃຕ້",
                "vill_name_en": "",
                "dr_id": "104"
              },
              {
                "vill_id": "10416",
                "vill_name": "ບຶງຂະຫຍອງ",
                "vill_name_en": "",
                "dr_id": "104"
              },
              {
                "vill_id": "10417",
                "vill_name": "ໂສກປ່າຫຼວງ",
                "vill_name_en": "",
                "dr_id": "104"
              },
              {
                "vill_id": "10418",
                "vill_name": "ວັດນາກ",
                "vill_name_en": "",
                "dr_id": "104"
              },
              {
                "vill_id": "10419",
                "vill_name": "ທ່າພະລານໄຊ",
                "vill_name_en": "",
                "dr_id": "104"
              },
              {
                "vill_id": "10420",
                "vill_name": "ພັ້ນໜັ້ນ",
                "vill_name_en": "",
                "dr_id": "104"
              },
              {
                "vill_id": "10421",
                "vill_name": "ທົ່ງການ",
                "vill_name_en": "",
                "dr_id": "104"
              },
              {
                "vill_id": "10423",
                "vill_name": "ໂພນປາເປົ້າ",
                "vill_name_en": "",
                "dr_id": "104"
              },
              {
                "vill_id": "10424",
                "vill_name": "ດອນກອຍ",
                "vill_name_en": "",
                "dr_id": "104"
              },
              {
                "vill_id": "10425",
                "vill_name": "ດອນນົກຂຸ້ມ",
                "vill_name_en": "",
                "dr_id": "104"
              },
              {
                "vill_id": "10426",
                "vill_name": "ດອນປ່າໄໝ່",
                "vill_name_en": "",
                "dr_id": "104"
              },
              {
                "vill_id": "10427",
                "vill_name": "ວັດສົບ",
                "vill_name_en": "",
                "dr_id": "104"
              },
              {
                "vill_id": "10428",
                "vill_name": "ໂພໄຊ",
                "vill_name_en": "",
                "dr_id": "104"
              },
              {
                "vill_id": "10429",
                "vill_name": "ສວນມອນ",
                "vill_name_en": "",
                "dr_id": "104"
              },
              {
                "vill_id": "10430",
                "vill_name": "ສ້າງເຫວີຍ",
                "vill_name_en": "",
                "dr_id": "104"
              },
              {
                "vill_id": "10431",
                "vill_name": "ດົງສະຫວາດ",
                "vill_name_en": "",
                "dr_id": "104"
              },
              {
                "vill_id": "10432",
                "vill_name": "ຈອມເພັດເໝຶອ",
                "vill_name_en": "",
                "dr_id": "104"
              },
              {
                "vill_id": "10433",
                "vill_name": "ຫາຍໂສກ",
                "vill_name_en": "",
                "dr_id": "104"
              },
              {
                "vill_id": "10434",
                "vill_name": "ຈອມແຈ້ງ",
                "vill_name_en": "",
                "dr_id": "104"
              },
              {
                "vill_id": "10435",
                "vill_name": "ໂຄກນິນ",
                "vill_name_en": "",
                "dr_id": "104"
              },
              {
                "vill_id": "10436",
                "vill_name": "ຈອມເພັດໃຕ້",
                "vill_name_en": "",
                "dr_id": "104"
              },
              {
                "vill_id": "10437",
                "vill_name": "ໄຊຊະຖານ",
                "vill_name_en": "",
                "dr_id": "104"
              },
              {
                "vill_id": "10438",
                "vill_name": "ໂພນສະຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "104"
              },
              {
                "vill_id": "10439",
                "vill_name": "ທົ່ງກາງ",
                "vill_name_en": "Thongkang",
                "dr_id": "104"
              }
            ]
          },
          {
            "dr_id": "105",
            "dr_name": "ນາຊາຍທອງ",
            "dr_name_en": "Naxaithong",
            "pr_id": "1",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "10501",
                "vill_name": "ນາສ້ຽວ",
                "vill_name_en": "",
                "dr_id": "105"
              },
              {
                "vill_id": "10502",
                "vill_name": "ໄຊມຸງຄຸນ",
                "vill_name_en": "",
                "dr_id": "105"
              },
              {
                "vill_id": "10503",
                "vill_name": "ສີເກີດ",
                "vill_name_en": "",
                "dr_id": "105"
              },
              {
                "vill_id": "10504",
                "vill_name": "ຜາງແຫ້ງ",
                "vill_name_en": "",
                "dr_id": "105"
              },
              {
                "vill_id": "10505",
                "vill_name": "ນາລຽນ",
                "vill_name_en": "",
                "dr_id": "105"
              },
              {
                "vill_id": "10506",
                "vill_name": "ປາກແຮດ",
                "vill_name_en": "",
                "dr_id": "105"
              },
              {
                "vill_id": "10507",
                "vill_name": "ໂພນສະຫວັນ",
                "vill_name_en": "",
                "dr_id": "105"
              },
              {
                "vill_id": "10508",
                "vill_name": "ໂພນແກ້ວ",
                "vill_name_en": "",
                "dr_id": "105"
              },
              {
                "vill_id": "10509",
                "vill_name": "ດົງຊຽງດີ",
                "vill_name_en": "",
                "dr_id": "105"
              },
              {
                "vill_id": "10510",
                "vill_name": "ນາຊາຍໃຕ້",
                "vill_name_en": "",
                "dr_id": "105"
              },
              {
                "vill_id": "10511",
                "vill_name": "ນາຊາຍທອງ",
                "vill_name_en": "",
                "dr_id": "105"
              },
              {
                "vill_id": "10512",
                "vill_name": "ນາຊາຍກາງ",
                "vill_name_en": "",
                "dr_id": "105"
              },
              {
                "vill_id": "10513",
                "vill_name": "ນາຊາຍເໜຶອ",
                "vill_name_en": "",
                "dr_id": "105"
              },
              {
                "vill_id": "10514",
                "vill_name": "ນາຄູນເໜຶອ",
                "vill_name_en": "",
                "dr_id": "105"
              },
              {
                "vill_id": "10515",
                "vill_name": "ນາຄູນໃຕ້",
                "vill_name_en": "",
                "dr_id": "105"
              },
              {
                "vill_id": "10516",
                "vill_name": "ດົງບອນ",
                "vill_name_en": "",
                "dr_id": "105"
              },
              {
                "vill_id": "10517",
                "vill_name": "ຫົວຊ້າງ",
                "vill_name_en": "",
                "dr_id": "105"
              },
              {
                "vill_id": "10518",
                "vill_name": "ຊານດີ",
                "vill_name_en": "",
                "dr_id": "105"
              },
              {
                "vill_id": "10519",
                "vill_name": "ຫົວຂົວ",
                "vill_name_en": "",
                "dr_id": "105"
              },
              {
                "vill_id": "10520",
                "vill_name": "ນາຊອນ",
                "vill_name_en": "",
                "dr_id": "105"
              },
              {
                "vill_id": "10521",
                "vill_name": "ນາຍາງ",
                "vill_name_en": "",
                "dr_id": "105"
              },
              {
                "vill_id": "10522",
                "vill_name": "ໜອງຄັນຄູ",
                "vill_name_en": "",
                "dr_id": "105"
              },
              {
                "vill_id": "10523",
                "vill_name": "ນໍ້າກຽງໃຕ້",
                "vill_name_en": "",
                "dr_id": "105"
              },
              {
                "vill_id": "10524",
                "vill_name": "ນໍ້າກຽງເໜຶອ",
                "vill_name_en": "",
                "dr_id": "105"
              },
              {
                "vill_id": "10525",
                "vill_name": "ນາຊັບ",
                "vill_name_en": "",
                "dr_id": "105"
              },
              {
                "vill_id": "10526",
                "vill_name": "ອີໄລ່ໃຕ້",
                "vill_name_en": "",
                "dr_id": "105"
              },
              {
                "vill_id": "10527",
                "vill_name": "ອີໄລ່ເໜຶອ",
                "vill_name_en": "",
                "dr_id": "105"
              },
              {
                "vill_id": "10528",
                "vill_name": "ສອງຂົວກາງແສນ",
                "vill_name_en": "",
                "dr_id": "105"
              },
              {
                "vill_id": "10529",
                "vill_name": "ແຈ້ງສະຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "105"
              },
              {
                "vill_id": "10531",
                "vill_name": "ຫ້ວຍນໍ້າເຢັນ",
                "vill_name_en": "",
                "dr_id": "105"
              },
              {
                "vill_id": "10532",
                "vill_name": "ສີຫວາດ",
                "vill_name_en": "",
                "dr_id": "105"
              },
              {
                "vill_id": "10533",
                "vill_name": "ອ່າງນໍ້າຫຸ້ມ",
                "vill_name_en": "",
                "dr_id": "105"
              },
              {
                "vill_id": "10534",
                "vill_name": "ນາທ່ອນ",
                "vill_name_en": "",
                "dr_id": "105"
              },
              {
                "vill_id": "10535",
                "vill_name": "ສົງເປຶອຍໃຕ້",
                "vill_name_en": "",
                "dr_id": "105"
              },
              {
                "vill_id": "10536",
                "vill_name": "ສົງເປຶອຍເໜຶອ",
                "vill_name_en": "",
                "dr_id": "105"
              },
              {
                "vill_id": "10537",
                "vill_name": "ນໍ້າຫຸມ",
                "vill_name_en": "",
                "dr_id": "105"
              },
              {
                "vill_id": "10538",
                "vill_name": "ເປີກ",
                "vill_name_en": "",
                "dr_id": "105"
              },
              {
                "vill_id": "10539",
                "vill_name": "ຫຸ່ມແບ່ງ",
                "vill_name_en": "",
                "dr_id": "105"
              },
              {
                "vill_id": "10540",
                "vill_name": "ໂພນຄຳ",
                "vill_name_en": "",
                "dr_id": "105"
              },
              {
                "vill_id": "10541",
                "vill_name": "ຫົວນາ",
                "vill_name_en": "",
                "dr_id": "105"
              },
              {
                "vill_id": "10542",
                "vill_name": "ນາໝາດ",
                "vill_name_en": "",
                "dr_id": "105"
              },
              {
                "vill_id": "10543",
                "vill_name": "ໂພນມ່ວງ",
                "vill_name_en": "",
                "dr_id": "105"
              },
              {
                "vill_id": "10544",
                "vill_name": "ຮ່ອງງົວ",
                "vill_name_en": "",
                "dr_id": "105"
              },
              {
                "vill_id": "10545",
                "vill_name": "ແສນດິນ",
                "vill_name_en": "",
                "dr_id": "105"
              },
              {
                "vill_id": "10546",
                "vill_name": "ຖໍ້າ",
                "vill_name_en": "",
                "dr_id": "105"
              },
              {
                "vill_id": "10547",
                "vill_name": "ນາຂ່າ",
                "vill_name_en": "",
                "dr_id": "105"
              },
              {
                "vill_id": "10548",
                "vill_name": "ນາງ່າ",
                "vill_name_en": "",
                "dr_id": "105"
              },
              {
                "vill_id": "10549",
                "vill_name": "ສີວິໄລ",
                "vill_name_en": "",
                "dr_id": "105"
              },
              {
                "vill_id": "10550",
                "vill_name": "ໂພໄຊ",
                "vill_name_en": "",
                "dr_id": "105"
              },
              {
                "vill_id": "10551",
                "vill_name": "ໂພສີ",
                "vill_name_en": "",
                "dr_id": "105"
              },
              {
                "vill_id": "10552",
                "vill_name": "ໂພນທອງ",
                "vill_name_en": "",
                "dr_id": "105"
              },
              {
                "vill_id": "10553",
                "vill_name": "ບົວ",
                "vill_name_en": "",
                "dr_id": "105"
              },
              {
                "vill_id": "10554",
                "vill_name": "ໜອງສະ",
                "vill_name_en": "",
                "dr_id": "105"
              },
              {
                "vill_id": "10555",
                "vill_name": "ພັດທະນາ",
                "vill_name_en": "",
                "dr_id": "105"
              },
              {
                "vill_id": "10556",
                "vill_name": "ນາດີ",
                "vill_name_en": "",
                "dr_id": "105"
              },
              {
                "vill_id": "10557",
                "vill_name": "ດົງຫຼວງ",
                "vill_name_en": "",
                "dr_id": "105"
              },
              {
                "vill_id": "10557001",
                "vill_name": "ໃຫ່ຍນາຈະເລີນ",
                "vill_name_en": "Yainajalern",
                "dr_id": "105"
              },
              {
                "vill_id": "10557002",
                "vill_name": "ດ່ານຊີ",
                "vill_name_en": "Dansee",
                "dr_id": "105"
              },
              {
                "vill_id": "10557003",
                "vill_name": "ປາກແຖບ",
                "vill_name_en": "Pakthap",
                "dr_id": "105"
              },
              {
                "vill_id": "10557004",
                "vill_name": "ສີສະຫວາດ",
                "vill_name_en": "sisavad",
                "dr_id": "105"
              },
              {
                "vill_id": "10557005",
                "vill_name": "ດົງບອງ",
                "vill_name_en": "Dongbong",
                "dr_id": "105"
              },
              {
                "vill_id": "10557006",
                "vill_name": "ໜອງຍາວ",
                "vill_name_en": "Nongyao",
                "dr_id": "105"
              },
              {
                "vill_id": "10557007",
                "vill_name": "ຈອມເພັດ",
                "vill_name_en": "Jomphet",
                "dr_id": "105"
              }
            ]
          },
          {
            "dr_id": "106",
            "dr_name": "ໄຊທານີ",
            "dr_name_en": "Xaythany",
            "pr_id": "1",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "10601",
                "vill_name": "ຂຸດສາມບາດ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10602",
                "vill_name": "ທົ່ງມັງ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10603",
                "vill_name": "ນາກຸງ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10604",
                "vill_name": "ໂພນຄໍ້",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10605",
                "vill_name": "ຫາດກ້ຽງ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10606",
                "vill_name": "ໂພນໂຮງ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10607",
                "vill_name": "ໂພນໄຮ່ຄຳ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10608",
                "vill_name": "ໄຮ່",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10609",
                "vill_name": "ທ່າສະຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10610",
                "vill_name": "ເວີນຄຳ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10611",
                "vill_name": "ຈະເລີນໄຊ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10612",
                "vill_name": "ໜອງເຂັງ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10613",
                "vill_name": "ໜອງໂນ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10614",
                "vill_name": "ໂພນງາມ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10615",
                "vill_name": "ໂພນໄຊ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10616",
                "vill_name": "ສາມສະອາດ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10617",
                "vill_name": "ທ່າງອນ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10618",
                "vill_name": "ນາ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10619",
                "vill_name": "ພູຄຳ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10620",
                "vill_name": "ແກ້ງໄຄ້",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10621",
                "vill_name": "ທ່າສົມມໍ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10622",
                "vill_name": "ອຸດົມຜົນ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10623",
                "vill_name": "ລາດຄວາຍ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10624",
                "vill_name": "ໂນນສະອາດ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10625",
                "vill_name": "ດົງໝາກຄາຍ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10626",
                "vill_name": "ດ່ານຊ້າງ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10627",
                "vill_name": "ດອນຕິ້ວ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10628",
                "vill_name": "ໄຊສະຫ່ວາງ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10629",
                "vill_name": "ດອນໜູນ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10630",
                "vill_name": "ໄຊ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10631",
                "vill_name": "ນາແຄ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10632",
                "vill_name": "ສະພັງໝຶກ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10633",
                "vill_name": "ຊ້າງຄູ່",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10634",
                "vill_name": "ພະຂາວ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10635",
                "vill_name": "ສີວິໄລ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10636",
                "vill_name": "ຄຳຮຸ່ງ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10637",
                "vill_name": "ໜອງວຽງຄຳ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10638",
                "vill_name": "ຫ້ວຍແດນເມຶອງ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10639",
                "vill_name": "ດົງໂດກ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10640",
                "vill_name": "ຕານມີໄຊ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10641",
                "vill_name": "ໜອງພະຍາ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10642",
                "vill_name": "ນາທົ່ມ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10643",
                "vill_name": "ວຽງແກ້ວ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10644",
                "vill_name": "ໂນນແສງຈັນ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10645",
                "vill_name": "ຫ້ວຍເຕີຍ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10646",
                "vill_name": "ດົງສ້າງຫີນ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10647",
                "vill_name": "ໂພຄຳ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10648",
                "vill_name": "ໂຄກນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10649",
                "vill_name": "ໂຄກໃຫ່ຍ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10650",
                "vill_name": "ສະພັງຄະນົງ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10651",
                "vill_name": "ນາລ້ອມ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10652",
                "vill_name": "ໜອງບົວ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10653",
                "vill_name": "ນາມົນ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10654",
                "vill_name": "ດົງບັງ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10655",
                "vill_name": "ໂນນແຕ້",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10656",
                "vill_name": "ໂພ້ນຕ້ອງ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10657",
                "vill_name": "ຫາດພະອິນ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10658",
                "vill_name": "ດວງບຸດດີ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10659",
                "vill_name": "ທ່າດອກຄຳ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10660",
                "vill_name": "ນາແຖ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10661",
                "vill_name": "ໂພນງາມ1",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10662",
                "vill_name": "ໂພນງາມ2",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10663",
                "vill_name": "ທ່າດິນແດງເໝຶອ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10664",
                "vill_name": "ທ່າດິນແດງໃຕ້",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10665",
                "vill_name": "ນາພອກ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10666",
                "vill_name": "ປາກຊາບເກົ່າ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10667",
                "vill_name": "ປາກຊາບໃໝ່",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10669",
                "vill_name": "ສົມສະໃໝ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10670",
                "vill_name": "ເວີນແທນ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10671",
                "vill_name": "ປ່າໄລ່",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10673",
                "vill_name": "ດຸນອຽນ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10674",
                "vill_name": "ນາຂາວ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10675",
                "vill_name": "ທ່າຈຳປາ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10676",
                "vill_name": "ນາຕານ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10677",
                "vill_name": "ນາໂງ່ນໃໝ່",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10678",
                "vill_name": "ນາໂງ່ນເກົ່າ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10679",
                "vill_name": "ນາຄາຍ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10680",
                "vill_name": "ນາຄັນທຸງ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10681",
                "vill_name": "ຫົວນາ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10682",
                "vill_name": "ຫົວຂົວ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10683",
                "vill_name": "ໂພນແພງ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10684",
                "vill_name": "ຊຳບອນ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10685",
                "vill_name": "ໂຄກສີວິໄລ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10686",
                "vill_name": "ໄຊສົມບູນ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10687",
                "vill_name": "ໂຄກສະອາດ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10688",
                "vill_name": "ທ່ານາກ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10689",
                "vill_name": "ໂນນທອງ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10690",
                "vill_name": "ຫົວຊຽງ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10691",
                "vill_name": "ດົງຄວາຍ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10692",
                "vill_name": "ສ້າງຫົວບໍ່",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10693",
                "vill_name": "ໃຜ່ລ້ອມ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10694",
                "vill_name": "ສ້າງຄອມ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10695",
                "vill_name": "ສົມສະຫັວນ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10696",
                "vill_name": "ໂຄກສະຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10697",
                "vill_name": "ໂພນສະຫັວນ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10698",
                "vill_name": "ດອນລຸ່ມ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "10699",
                "vill_name": "ຜານາສຸກ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "106100",
                "vill_name": "ບໍ່ເຫຼັກ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "106101",
                "vill_name": "ຫາດວຽງຄຳ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "106102",
                "vill_name": "ນາສາລາ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "106103",
                "vill_name": "ສານໍ້າເງິນ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "106104",
                "vill_name": "ໂນນບໍ່ແກ້ວ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "106105",
                "vill_name": "ໜອງສອງຫ້ອງ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "106106",
                "vill_name": "ຫ້ວຍຈຽມ",
                "vill_name_en": "",
                "dr_id": "106"
              },
              {
                "vill_id": "106107",
                "vill_name": "ໜອງບຶກ",
                "vill_name_en": "Nongbuek",
                "dr_id": "106"
              },
              {
                "vill_id": "106108",
                "vill_name": "ແສນອຸດົມ",
                "vill_name_en": "Saen oudom",
                "dr_id": "106"
              },
              {
                "vill_id": "106109",
                "vill_name": "ຫຼັກ 27",
                "vill_name_en": "Km 27",
                "dr_id": "106"
              },
              {
                "vill_id": "106110",
                "vill_name": "ນາຜາສຸກ",
                "vill_name_en": "Naphasouk",
                "dr_id": "106"
              }
            ]
          },
          {
            "dr_id": "107",
            "dr_name": "ຫາດຊາຍຟອງ",
            "dr_name_en": "Hadxaifong",
            "pr_id": "1",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "10701",
                "vill_name": "ທ່ານາ",
                "vill_name_en": "",
                "dr_id": "107"
              },
              {
                "vill_id": "10702",
                "vill_name": "ດອນດູ",
                "vill_name_en": "",
                "dr_id": "107"
              },
              {
                "vill_id": "10703",
                "vill_name": "ບໍໂອ",
                "vill_name_en": "",
                "dr_id": "107"
              },
              {
                "vill_id": "10704",
                "vill_name": "ພ້າວ",
                "vill_name_en": "",
                "dr_id": "107"
              },
              {
                "vill_id": "10705",
                "vill_name": "ກາງ",
                "vill_name_en": "",
                "dr_id": "107"
              },
              {
                "vill_id": "10706",
                "vill_name": "ສົ້ມໂຮງ",
                "vill_name_en": "",
                "dr_id": "107"
              },
              {
                "vill_id": "10707",
                "vill_name": "ເເກ້ງຍາງ",
                "vill_name_en": "",
                "dr_id": "107"
              },
              {
                "vill_id": "10708",
                "vill_name": "ດອນຂາຊ້າຍ",
                "vill_name_en": "",
                "dr_id": "107"
              },
              {
                "vill_id": "10709",
                "vill_name": "ນາທາມ",
                "vill_name_en": "",
                "dr_id": "107"
              },
              {
                "vill_id": "10710",
                "vill_name": "ເເກ້ງປາຢ້າງ",
                "vill_name_en": "",
                "dr_id": "107"
              },
              {
                "vill_id": "10711",
                "vill_name": "ຫາດດອກເເກ້ວ",
                "vill_name_en": "",
                "dr_id": "107"
              },
              {
                "vill_id": "10712",
                "vill_name": "ຫົວຫ້າ",
                "vill_name_en": "",
                "dr_id": "107"
              },
              {
                "vill_id": "10713",
                "vill_name": "ຫາດກັນຊາ",
                "vill_name_en": "",
                "dr_id": "107"
              },
              {
                "vill_id": "10714",
                "vill_name": "ດອນເກີດ",
                "vill_name_en": "",
                "dr_id": "107"
              },
              {
                "vill_id": "10715",
                "vill_name": "ໂພເງິນ",
                "vill_name_en": "",
                "dr_id": "107"
              },
              {
                "vill_id": "10716",
                "vill_name": "ໂພສີ",
                "vill_name_en": "",
                "dr_id": "107"
              },
              {
                "vill_id": "10717",
                "vill_name": "ສະຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "107"
              },
              {
                "vill_id": "10718",
                "vill_name": "ປ່າຝາງ",
                "vill_name_en": "",
                "dr_id": "107"
              },
              {
                "vill_id": "10719",
                "vill_name": "ຈອມທອງ",
                "vill_name_en": "",
                "dr_id": "107"
              },
              {
                "vill_id": "10720",
                "vill_name": "ຫ້ອມໄຕ້",
                "vill_name_en": "",
                "dr_id": "107"
              },
              {
                "vill_id": "10721",
                "vill_name": "ຈຽມປາງ",
                "vill_name_en": "",
                "dr_id": "107"
              },
              {
                "vill_id": "10722",
                "vill_name": "ຖິ່ນເພຍ",
                "vill_name_en": "",
                "dr_id": "107"
              },
              {
                "vill_id": "10723",
                "vill_name": "ທ່າເເຂກ",
                "vill_name_en": "",
                "dr_id": "107"
              },
              {
                "vill_id": "10724",
                "vill_name": "ຊາຍຟອງເໜືອ",
                "vill_name_en": "",
                "dr_id": "107"
              },
              {
                "vill_id": "10725",
                "vill_name": "ຊາຍຟອງໃຕ້",
                "vill_name_en": "",
                "dr_id": "107"
              },
              {
                "vill_id": "10726",
                "vill_name": "ໂຄກຊາຍ",
                "vill_name_en": "",
                "dr_id": "107"
              },
              {
                "vill_id": "10727",
                "vill_name": "ສີຖານໃຕ້",
                "vill_name_en": "",
                "dr_id": "107"
              },
              {
                "vill_id": "10728",
                "vill_name": "ຫາດຊາຍຂາວ",
                "vill_name_en": "",
                "dr_id": "107"
              },
              {
                "vill_id": "10729",
                "vill_name": "ສົມຫວັງເໜືອ",
                "vill_name_en": "",
                "dr_id": "107"
              },
              {
                "vill_id": "10730",
                "vill_name": "ສົມຫວັງໃຕ້",
                "vill_name_en": "",
                "dr_id": "107"
              },
              {
                "vill_id": "10731",
                "vill_name": "ໜອງໄຮ",
                "vill_name_en": "",
                "dr_id": "107"
              },
              {
                "vill_id": "10732",
                "vill_name": "ສົມສະໜຸກ",
                "vill_name_en": "",
                "dr_id": "107"
              },
              {
                "vill_id": "10733",
                "vill_name": "ໜອງເເວງ",
                "vill_name_en": "",
                "dr_id": "107"
              },
              {
                "vill_id": "10734",
                "vill_name": "ສາລະຄຳເໜືອ",
                "vill_name_en": "",
                "dr_id": "107"
              },
              {
                "vill_id": "10735",
                "vill_name": "ນາໄຫ",
                "vill_name_en": "",
                "dr_id": "107"
              },
              {
                "vill_id": "10736",
                "vill_name": "ດົງຄຳຊ້າງ",
                "vill_name_en": "",
                "dr_id": "107"
              },
              {
                "vill_id": "10737",
                "vill_name": "ນາໄຮ່",
                "vill_name_en": "",
                "dr_id": "107"
              },
              {
                "vill_id": "10738",
                "vill_name": "ໜອງເເຫ້ວ",
                "vill_name_en": "",
                "dr_id": "107"
              },
              {
                "vill_id": "10739",
                "vill_name": "ດົງໂພສີ",
                "vill_name_en": "",
                "dr_id": "107"
              },
              {
                "vill_id": "10740",
                "vill_name": "ທ່ານາເເລ້ງ",
                "vill_name_en": "",
                "dr_id": "107"
              },
              {
                "vill_id": "10741",
                "vill_name": "ທ່າມ່ວງ",
                "vill_name_en": "",
                "dr_id": "107"
              },
              {
                "vill_id": "10742",
                "vill_name": "ດົງໂພນເເຮ່",
                "vill_name_en": "",
                "dr_id": "107"
              },
              {
                "vill_id": "10743",
                "vill_name": "ປ່າຫວ້າ",
                "vill_name_en": "",
                "dr_id": "107"
              },
              {
                "vill_id": "10744",
                "vill_name": "ທ່າເດື່ອ",
                "vill_name_en": "",
                "dr_id": "107"
              },
              {
                "vill_id": "10745",
                "vill_name": "ຖີ່ນຕົມ",
                "vill_name_en": "",
                "dr_id": "107"
              },
              {
                "vill_id": "10746",
                "vill_name": "ຄຳຈະເລີນ",
                "vill_name_en": "",
                "dr_id": "107"
              },
              {
                "vill_id": "10747",
                "vill_name": "ໜອງເເປນເໜືອ",
                "vill_name_en": "",
                "dr_id": "107"
              },
              {
                "vill_id": "10748",
                "vill_name": "ຊຽງຄວນ",
                "vill_name_en": "",
                "dr_id": "107"
              },
              {
                "vill_id": "10749",
                "vill_name": "ທ່າພະ",
                "vill_name_en": "",
                "dr_id": "107"
              },
              {
                "vill_id": "10750",
                "vill_name": "ປາກເປັ່ງ",
                "vill_name_en": "",
                "dr_id": "107"
              },
              {
                "vill_id": "10751",
                "vill_name": "ຫນອງພົງ",
                "vill_name_en": "",
                "dr_id": "107"
              },
              {
                "vill_id": "10752",
                "vill_name": "ຖິ່ນແທ່ນ",
                "vill_name_en": "",
                "dr_id": "107"
              },
              {
                "vill_id": "10753",
                "vill_name": "ຄວາຍແດງ",
                "vill_name_en": "",
                "dr_id": "107"
              },
              {
                "vill_id": "10754",
                "vill_name": "ສິມມະໂນເຫນືອ",
                "vill_name_en": "",
                "dr_id": "107"
              },
              {
                "vill_id": "10755",
                "vill_name": "ສິມມະໂນໃຕ້",
                "vill_name_en": "",
                "dr_id": "107"
              },
              {
                "vill_id": "10756",
                "vill_name": "ນາລ້ອງ",
                "vill_name_en": "",
                "dr_id": "107"
              },
              {
                "vill_id": "10757",
                "vill_name": "ດອນຝ້າຍ",
                "vill_name_en": "",
                "dr_id": "107"
              },
              {
                "vill_id": "10758",
                "vill_name": "ສາລາຄຳໃຕ້",
                "vill_name_en": "",
                "dr_id": "107"
              },
              {
                "vill_id": "10759",
                "vill_name": "ດົງໂພນເລົາ",
                "vill_name_en": "",
                "dr_id": "107"
              },
              {
                "vill_id": "10760",
                "vill_name": "ບ້ານທົ່ງໂພເງິນ",
                "vill_name_en": "Thongphongeun",
                "dr_id": "107"
              },
              {
                "vill_id": "10761",
                "vill_name": "ແກ້ງປ່າຢ້າງ",
                "vill_name_en": "Kaengpayang",
                "dr_id": "107"
              },
              {
                "vill_id": "10762",
                "vill_name": "ບ້ານດອນ",
                "vill_name_en": "Done",
                "dr_id": "107"
              }
            ]
          },
          {
            "dr_id": "108",
            "dr_name": "ສັງທອງ",
            "dr_name_en": "Sangthong",
            "pr_id": "1",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "10801",
                "vill_name": "ກົ້ວ",
                "vill_name_en": "",
                "dr_id": "108"
              },
              {
                "vill_id": "10802",
                "vill_name": "ເຕົາໃຫ",
                "vill_name_en": "",
                "dr_id": "108"
              },
              {
                "vill_id": "10803",
                "vill_name": "ໄຮ່ເໜຶອ",
                "vill_name_en": "",
                "dr_id": "108"
              },
              {
                "vill_id": "10804",
                "vill_name": "ນາຫອຍປັງ",
                "vill_name_en": "",
                "dr_id": "108"
              },
              {
                "vill_id": "10805",
                "vill_name": "ນາລາດ",
                "vill_name_en": "",
                "dr_id": "108"
              },
              {
                "vill_id": "10806",
                "vill_name": "ນາທຽມ",
                "vill_name_en": "",
                "dr_id": "108"
              },
              {
                "vill_id": "10807",
                "vill_name": "ໜອງບົວ",
                "vill_name_en": "",
                "dr_id": "108"
              },
              {
                "vill_id": "10808",
                "vill_name": "ນາໝ້ຽງ",
                "vill_name_en": "",
                "dr_id": "108"
              },
              {
                "vill_id": "10809",
                "vill_name": "ນາຕານ",
                "vill_name_en": "",
                "dr_id": "108"
              },
              {
                "vill_id": "10810",
                "vill_name": "ປາກແດກ",
                "vill_name_en": "",
                "dr_id": "108"
              },
              {
                "vill_id": "10811",
                "vill_name": "ນາສາວນາງ",
                "vill_name_en": "",
                "dr_id": "108"
              },
              {
                "vill_id": "10812",
                "vill_name": "ໄຮ່ໃຕ້",
                "vill_name_en": "",
                "dr_id": "108"
              },
              {
                "vill_id": "10813",
                "vill_name": "ຫີນສິ່ວ",
                "vill_name_en": "",
                "dr_id": "108"
              },
              {
                "vill_id": "10814",
                "vill_name": "ເພຍລາດ",
                "vill_name_en": "",
                "dr_id": "108"
              },
              {
                "vill_id": "10815",
                "vill_name": "ທ່ານາຂາມ",
                "vill_name_en": "",
                "dr_id": "108"
              },
              {
                "vill_id": "10816",
                "vill_name": "ນາຫອຍ",
                "vill_name_en": "",
                "dr_id": "108"
              },
              {
                "vill_id": "10817",
                "vill_name": "ຫີນເລັບ",
                "vill_name_en": "",
                "dr_id": "108"
              },
              {
                "vill_id": "10818",
                "vill_name": "ວັງມ້າ",
                "vill_name_en": "",
                "dr_id": "108"
              },
              {
                "vill_id": "10819",
                "vill_name": "ກ້ວຍ",
                "vill_name_en": "",
                "dr_id": "108"
              },
              {
                "vill_id": "10820",
                "vill_name": "ນາປໍ",
                "vill_name_en": "",
                "dr_id": "108"
              },
              {
                "vill_id": "10821",
                "vill_name": "ຫ້ວຍຫຼ້າ",
                "vill_name_en": "",
                "dr_id": "108"
              },
              {
                "vill_id": "10822",
                "vill_name": "ຫ້ວຍຕົມ",
                "vill_name_en": "",
                "dr_id": "108"
              },
              {
                "vill_id": "10823",
                "vill_name": "ໃໝ່",
                "vill_name_en": "",
                "dr_id": "108"
              },
              {
                "vill_id": "10824",
                "vill_name": "ຊໍ້",
                "vill_name_en": "",
                "dr_id": "108"
              },
              {
                "vill_id": "10825",
                "vill_name": "ທ່ານາສາ",
                "vill_name_en": "",
                "dr_id": "108"
              },
              {
                "vill_id": "10826",
                "vill_name": "ສະນອດ",
                "vill_name_en": "",
                "dr_id": "108"
              },
              {
                "vill_id": "10827",
                "vill_name": "ໄຄແຫ້",
                "vill_name_en": "",
                "dr_id": "108"
              },
              {
                "vill_id": "10828",
                "vill_name": "ອ່າງນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "108"
              },
              {
                "vill_id": "10829",
                "vill_name": "ສະໄກ",
                "vill_name_en": "",
                "dr_id": "108"
              },
              {
                "vill_id": "10830",
                "vill_name": "ໂຄກເພີງ",
                "vill_name_en": "",
                "dr_id": "108"
              },
              {
                "vill_id": "10831",
                "vill_name": "ປາກຕອນ",
                "vill_name_en": "",
                "dr_id": "108"
              },
              {
                "vill_id": "10832",
                "vill_name": "ທ່ານາສ້າງຫີນ",
                "vill_name_en": "",
                "dr_id": "108"
              },
              {
                "vill_id": "10833",
                "vill_name": "ດອນກາງຂອງ",
                "vill_name_en": "",
                "dr_id": "108"
              },
              {
                "vill_id": "10834",
                "vill_name": "ແກ້ງໝໍ້",
                "vill_name_en": "",
                "dr_id": "108"
              },
              {
                "vill_id": "10835",
                "vill_name": "ສຳພັນນາ",
                "vill_name_en": "",
                "dr_id": "108"
              },
              {
                "vill_id": "10836",
                "vill_name": "ຫ້ວຍຫາງ",
                "vill_name_en": "",
                "dr_id": "108"
              },
              {
                "vill_id": "10837",
                "vill_name": "ຫ້ວຍຄຳ",
                "vill_name_en": "",
                "dr_id": "108"
              },
              {
                "vill_id": "10837001",
                "vill_name": "ໃຫ່ຍນາຈະເລີນ",
                "vill_name_en": "Yainajalern",
                "dr_id": "108"
              },
              {
                "vill_id": "10837002",
                "vill_name": "ໂຄກແຫຼ້",
                "vill_name_en": "khoklae",
                "dr_id": "108"
              },
              {
                "vill_id": "10837003",
                "vill_name": "ປາກແຸບ",
                "vill_name_en": "Pakthap",
                "dr_id": "108"
              },
              {
                "vill_id": "10837004",
                "vill_name": "ອ່າງນ້ອຍ",
                "vill_name_en": "Angnoy",
                "dr_id": "108"
              }
            ]
          },
          {
            "dr_id": "109",
            "dr_name": "ປາກງື່ມ",
            "dr_name_en": "Parkngum",
            "pr_id": "1",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "10901",
                "vill_name": "ຊຽງແລ້ນາ",
                "vill_name_en": "",
                "dr_id": "109"
              },
              {
                "vill_id": "10902",
                "vill_name": "ເວີນກະເບົາ",
                "vill_name_en": "",
                "dr_id": "109"
              },
              {
                "vill_id": "10903",
                "vill_name": "ໂນນ",
                "vill_name_en": "",
                "dr_id": "109"
              },
              {
                "vill_id": "10904",
                "vill_name": "ຫົວທົ່ງນາ",
                "vill_name_en": "",
                "dr_id": "109"
              },
              {
                "vill_id": "10905",
                "vill_name": "ໂພນຂາມ",
                "vill_name_en": "",
                "dr_id": "109"
              },
              {
                "vill_id": "10906",
                "vill_name": "ນາບົງ",
                "vill_name_en": "",
                "dr_id": "109"
              },
              {
                "vill_id": "10907",
                "vill_name": "ທ່າກົກໄຮ່",
                "vill_name_en": "",
                "dr_id": "109"
              },
              {
                "vill_id": "10908",
                "vill_name": "ໜອງດົງ",
                "vill_name_en": "",
                "dr_id": "109"
              },
              {
                "vill_id": "10909",
                "vill_name": "ຫ້ວຍນຳຍຸນ",
                "vill_name_en": "",
                "dr_id": "109"
              },
              {
                "vill_id": "10910",
                "vill_name": "ທ່າຊ້າງ",
                "vill_name_en": "",
                "dr_id": "109"
              },
              {
                "vill_id": "10911",
                "vill_name": "ຊຽງແລທ່າ",
                "vill_name_en": "",
                "dr_id": "109"
              },
              {
                "vill_id": "10912",
                "vill_name": "ຝັ່ງແດງ",
                "vill_name_en": "",
                "dr_id": "109"
              },
              {
                "vill_id": "10913",
                "vill_name": "ປາກກວາງ",
                "vill_name_en": "",
                "dr_id": "109"
              },
              {
                "vill_id": "10914",
                "vill_name": "ເວີນແຄນ",
                "vill_name_en": "",
                "dr_id": "109"
              },
              {
                "vill_id": "10915",
                "vill_name": "ສົມປະເສີດ",
                "vill_name_en": "",
                "dr_id": "109"
              },
              {
                "vill_id": "10916",
                "vill_name": "ໄຮ່",
                "vill_name_en": "",
                "dr_id": "109"
              },
              {
                "vill_id": "10917",
                "vill_name": "ໂນນຊາຍ",
                "vill_name_en": "",
                "dr_id": "109"
              },
              {
                "vill_id": "10918",
                "vill_name": "ດອນສັງຄີ",
                "vill_name_en": "",
                "dr_id": "109"
              },
              {
                "vill_id": "10919",
                "vill_name": "ທຸ່ນລົວ",
                "vill_name_en": "",
                "dr_id": "109"
              },
              {
                "vill_id": "10920",
                "vill_name": "ພ້າວ",
                "vill_name_en": "",
                "dr_id": "109"
              },
              {
                "vill_id": "10921",
                "vill_name": "ດອນແຍງ",
                "vill_name_en": "",
                "dr_id": "109"
              },
              {
                "vill_id": "10922",
                "vill_name": "ທ່າກອກ",
                "vill_name_en": "",
                "dr_id": "109"
              },
              {
                "vill_id": "10923",
                "vill_name": "ນາກຸງ",
                "vill_name_en": "",
                "dr_id": "109"
              },
              {
                "vill_id": "10924",
                "vill_name": "ໜອງພູວຽງ",
                "vill_name_en": "",
                "dr_id": "109"
              },
              {
                "vill_id": "10925",
                "vill_name": "ນາຊອນ",
                "vill_name_en": "",
                "dr_id": "109"
              },
              {
                "vill_id": "10926",
                "vill_name": "ນາທາມ",
                "vill_name_en": "",
                "dr_id": "109"
              },
              {
                "vill_id": "10927",
                "vill_name": "ໜອງແຄນ",
                "vill_name_en": "",
                "dr_id": "109"
              },
              {
                "vill_id": "10928",
                "vill_name": "ດອນສ້າງໃຜ່",
                "vill_name_en": "",
                "dr_id": "109"
              },
              {
                "vill_id": "10929",
                "vill_name": "ໂນນສົມບູນ",
                "vill_name_en": "",
                "dr_id": "109"
              },
              {
                "vill_id": "10930",
                "vill_name": "ໜອງບົວທອງ",
                "vill_name_en": "",
                "dr_id": "109"
              },
              {
                "vill_id": "10931",
                "vill_name": "ໂຄກສາ",
                "vill_name_en": "",
                "dr_id": "109"
              },
              {
                "vill_id": "10932",
                "vill_name": "ດອນໄຮ່",
                "vill_name_en": "",
                "dr_id": "109"
              },
              {
                "vill_id": "10933",
                "vill_name": "ທາງໂຄ້ງ",
                "vill_name_en": "",
                "dr_id": "109"
              },
              {
                "vill_id": "10934",
                "vill_name": "ໂນນສູງ",
                "vill_name_en": "",
                "dr_id": "109"
              },
              {
                "vill_id": "10935",
                "vill_name": "ນາເລີດ",
                "vill_name_en": "",
                "dr_id": "109"
              },
              {
                "vill_id": "10936",
                "vill_name": "ໜອງວັງເຜີ້ງ",
                "vill_name_en": "",
                "dr_id": "109"
              },
              {
                "vill_id": "10937",
                "vill_name": "ໂດນເໜຶອ",
                "vill_name_en": "",
                "dr_id": "109"
              },
              {
                "vill_id": "10938",
                "vill_name": "ດົງກະລຶມ",
                "vill_name_en": "",
                "dr_id": "109"
              },
              {
                "vill_id": "10939",
                "vill_name": "ດົງສານ",
                "vill_name_en": "",
                "dr_id": "109"
              },
              {
                "vill_id": "10940",
                "vill_name": "ທ່າເດຶອສິງກອມ",
                "vill_name_en": "",
                "dr_id": "109"
              },
              {
                "vill_id": "10941",
                "vill_name": "ຖິ່ນທຽງ",
                "vill_name_en": "",
                "dr_id": "109"
              },
              {
                "vill_id": "10942",
                "vill_name": "ດອນວັງໂພ",
                "vill_name_en": "",
                "dr_id": "109"
              },
              {
                "vill_id": "10943",
                "vill_name": "ໝາກນາວໃຕ້",
                "vill_name_en": "",
                "dr_id": "109"
              },
              {
                "vill_id": "10944",
                "vill_name": "ໝາກນາວເໜຶອ",
                "vill_name_en": "",
                "dr_id": "109"
              },
              {
                "vill_id": "10945",
                "vill_name": "ນາຊາ",
                "vill_name_en": "",
                "dr_id": "109"
              },
              {
                "vill_id": "10946",
                "vill_name": "ໝາກນາວດົງ",
                "vill_name_en": "",
                "dr_id": "109"
              },
              {
                "vill_id": "10947",
                "vill_name": "ນາຝາຍ",
                "vill_name_en": "",
                "dr_id": "109"
              },
              {
                "vill_id": "10948",
                "vill_name": "ສ້າງໄສ",
                "vill_name_en": "",
                "dr_id": "109"
              },
              {
                "vill_id": "10949",
                "vill_name": "ໝາກຮຽວ",
                "vill_name_en": "",
                "dr_id": "109"
              },
              {
                "vill_id": "10950",
                "vill_name": "ສົມສະຫວາດ",
                "vill_name_en": "",
                "dr_id": "109"
              },
              {
                "vill_id": "10951",
                "vill_name": "ປາກງຶ່ມ",
                "vill_name_en": "",
                "dr_id": "109"
              },
              {
                "vill_id": "10952",
                "vill_name": "ໜອງຂຽດ",
                "vill_name_en": "",
                "dr_id": "109"
              },
              {
                "vill_id": "10953",
                "vill_name": "ສິນໄຊ1",
                "vill_name_en": "",
                "dr_id": "109"
              }
            ]
          }
        ]
      },
      {
        "pr_id": "2",
        "pr_name": "ຜົ້ງສາລີ",
        "pr_name_en": "Phongsali",
        "districts": [
          {"dr_name": "ເລືອກເມືອງປະຈຸບັນ", "villages": []},
          {
            "dr_id": "201",
            "dr_name": "ຜົ້ງສາລີ",
            "dr_name_en": "Phongsaly",
            "pr_id": "2",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "20101",
                "vill_name": "ແຊວ​ຈ້າຍ",
                "vill_name_en": "",
                "dr_id": "201"
              },
              {
                "vill_id": "20102",
                "vill_name": "ໂລງ​ຊ່າງ",
                "vill_name_en": "",
                "dr_id": "201"
              },
              {
                "vill_id": "20103",
                "vill_name": "ນ້າ​ໂປ​ຊ່າງ​ເກົ່າ",
                "vill_name_en": "",
                "dr_id": "201"
              },
              {
                "vill_id": "20104",
                "vill_name": "ພູ​ຍອດ",
                "vill_name_en": "",
                "dr_id": "201"
              },
              {
                "vill_id": "20105",
                "vill_name": "ຈາຄຳປາ",
                "vill_name_en": "",
                "dr_id": "201"
              },
              {
                "vill_id": "20106",
                "vill_name": "ເປີ​ຍີ​ຊ່າງ​ໃໝ່",
                "vill_name_en": "",
                "dr_id": "201"
              },
              {
                "vill_id": "20107",
                "vill_name": "ເປີ​ຍ​ຊ່າງ​ເກົ່າ",
                "vill_name_en": "",
                "dr_id": "201"
              },
              {
                "vill_id": "20108",
                "vill_name": "ປາຍ​ໂຈ",
                "vill_name_en": "",
                "dr_id": "201"
              },
              {
                "vill_id": "20109",
                "vill_name": "ໄຊ",
                "vill_name_en": "",
                "dr_id": "201"
              },
              {
                "vill_id": "20110",
                "vill_name": "ບໍ່​ກົ້ງ",
                "vill_name_en": "",
                "dr_id": "201"
              },
              {
                "vill_id": "20111",
                "vill_name": "ສາ​ລາ​ເອ​ເບ",
                "vill_name_en": "",
                "dr_id": "201"
              },
              {
                "vill_id": "20112",
                "vill_name": "ນ້ຳປຸ້ງ(ບ. ຫລັກ 13)",
                "vill_name_en": "",
                "dr_id": "201"
              },
              {
                "vill_id": "20113",
                "vill_name": "ຢາ​ປຸງ",
                "vill_name_en": "",
                "dr_id": "201"
              },
              {
                "vill_id": "20114",
                "vill_name": "ຈີ່​ໂຈະ",
                "vill_name_en": "",
                "dr_id": "201"
              },
              {
                "vill_id": "20115",
                "vill_name": "ບາ​ກາ​ນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "201"
              },
              {
                "vill_id": "20116",
                "vill_name": "ບາ​ກາ​ຫລວງ",
                "vill_name_en": "",
                "dr_id": "201"
              },
              {
                "vill_id": "20117",
                "vill_name": "ຫາດ​ສາ",
                "vill_name_en": "",
                "dr_id": "201"
              },
              {
                "vill_id": "20118",
                "vill_name": "ພູູ​ເມືອງ",
                "vill_name_en": "",
                "dr_id": "201"
              },
              {
                "vill_id": "20119",
                "vill_name": "ເມືອງວາ",
                "vill_name_en": "",
                "dr_id": "201"
              },
              {
                "vill_id": "20120",
                "vill_name": "ວາ​ໃຕ້",
                "vill_name_en": "",
                "dr_id": "201"
              },
              {
                "vill_id": "20121",
                "vill_name": "ພູ​ຊູມ​ເຫນືອ",
                "vill_name_en": "",
                "dr_id": "201"
              },
              {
                "vill_id": "20122",
                "vill_name": "ວັງ​ໄຊ",
                "vill_name_en": "",
                "dr_id": "201"
              },
              {
                "vill_id": "20123",
                "vill_name": "ປາງ​ຊໍ້",
                "vill_name_en": "",
                "dr_id": "201"
              },
              {
                "vill_id": "20124",
                "vill_name": "ນ້ຳ​ສາ",
                "vill_name_en": "",
                "dr_id": "201"
              },
              {
                "vill_id": "20125",
                "vill_name": "ສິນ​ຈ້າຍ​ໃຕ້",
                "vill_name_en": "",
                "dr_id": "201"
              },
              {
                "vill_id": "20126",
                "vill_name": "ງ່າຍ​ໃຕ້",
                "vill_name_en": "",
                "dr_id": "201"
              },
              {
                "vill_id": "20127",
                "vill_name": "ສຳ​ມືງ​ຫລວງ",
                "vill_name_en": "",
                "dr_id": "201"
              },
              {
                "vill_id": "20128",
                "vill_name": "ສົບ​ງາມ",
                "vill_name_en": "",
                "dr_id": "201"
              },
              {
                "vill_id": "20129",
                "vill_name": "ຈັງເ​ຕື່ນ",
                "vill_name_en": "",
                "dr_id": "201"
              },
              {
                "vill_id": "20130",
                "vill_name": "ພູ​ດອກ​ຈຳ",
                "vill_name_en": "",
                "dr_id": "201"
              },
              {
                "vill_id": "20131",
                "vill_name": "ເລົ້າ​ຟູ​ຈ້າຍ",
                "vill_name_en": "",
                "dr_id": "201"
              },
              {
                "vill_id": "20132",
                "vill_name": "ກິ້ວ",
                "vill_name_en": "",
                "dr_id": "201"
              },
              {
                "vill_id": "20133",
                "vill_name": "ນ້ຳ​ອົ້ງ",
                "vill_name_en": "",
                "dr_id": "201"
              },
              {
                "vill_id": "20134",
                "vill_name": "ຫ້ວຍ​ຊືນ",
                "vill_name_en": "",
                "dr_id": "201"
              },
              {
                "vill_id": "20135",
                "vill_name": "ຫ້ວຍ​ລຸ",
                "vill_name_en": "",
                "dr_id": "201"
              },
              {
                "vill_id": "20136",
                "vill_name": "ອຸ​ດົມ",
                "vill_name_en": "",
                "dr_id": "201"
              },
              {
                "vill_id": "20137",
                "vill_name": "ຮວງ​ຕ້າ​ເຢ",
                "vill_name_en": "",
                "dr_id": "201"
              },
              {
                "vill_id": "20138",
                "vill_name": "ເລົ້າ​ແທນ",
                "vill_name_en": "",
                "dr_id": "201"
              },
              {
                "vill_id": "20139",
                "vill_name": "ເລົ້າ​ຕ່ຽວ",
                "vill_name_en": "",
                "dr_id": "201"
              },
              {
                "vill_id": "20140",
                "vill_name": "ຢາວ​ຟາງ",
                "vill_name_en": "",
                "dr_id": "201"
              },
              {
                "vill_id": "20141",
                "vill_name": "ຕ້າແສ​ນ​ຜົ້ງ",
                "vill_name_en": "",
                "dr_id": "201"
              },
              {
                "vill_id": "20142",
                "vill_name": "ຂຸນ​ສຸກ​ນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "201"
              },
              {
                "vill_id": "20143",
                "vill_name": "ຂຸ​ນ​ສຸກ​ຫລວງ",
                "vill_name_en": "",
                "dr_id": "201"
              },
              {
                "vill_id": "20144",
                "vill_name": "ຈັນ​ຕານ",
                "vill_name_en": "",
                "dr_id": "201"
              },
              {
                "vill_id": "20145",
                "vill_name": "ຂຸນ​ຫນຸມ​ນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "201"
              },
              {
                "vill_id": "20146",
                "vill_name": "ຂຸນ​ຫນຸມ​ຫລວງ",
                "vill_name_en": "",
                "dr_id": "201"
              },
              {
                "vill_id": "20147",
                "vill_name": "ກໍ​ແມນ​ໃໝ່",
                "vill_name_en": "",
                "dr_id": "201"
              },
              {
                "vill_id": "20148",
                "vill_name": "ຜ່າ​ປຸນ",
                "vill_name_en": "",
                "dr_id": "201"
              },
              {
                "vill_id": "20149",
                "vill_name": "ໂມ​ໂຊ​ຊ່າງ",
                "vill_name_en": "",
                "dr_id": "201"
              },
              {
                "vill_id": "20150",
                "vill_name": "ກໍໍ​ແດງ",
                "vill_name_en": "",
                "dr_id": "201"
              },
              {
                "vill_id": "20151",
                "vill_name": "ຈ້າ​ພູ",
                "vill_name_en": "",
                "dr_id": "201"
              },
              {
                "vill_id": "20152",
                "vill_name": "ນ້ຳ​ແລງ",
                "vill_name_en": "",
                "dr_id": "201"
              },
              {
                "vill_id": "20153",
                "vill_name": "ພູ​ຊູມ​ໃຕ້",
                "vill_name_en": "",
                "dr_id": "201"
              },
              {
                "vill_id": "20154",
                "vill_name": "ຜົ້ງ​ແຊກ",
                "vill_name_en": "",
                "dr_id": "201"
              },
              {
                "vill_id": "20155",
                "vill_name": "ຫາດກໍ່+ ບ ຫາດໄຟ(ບ. ຫາດ​ໂກ​)",
                "vill_name_en": "",
                "dr_id": "201"
              },
              {
                "vill_id": "20156",
                "vill_name": "ລຸ່ງຈິ່ງ",
                "vill_name_en": "",
                "dr_id": "201"
              },
              {
                "vill_id": "20157",
                "vill_name": "ພະ​ຍາ​ສີ",
                "vill_name_en": "",
                "dr_id": "201"
              },
              {
                "vill_id": "20158",
                "vill_name": "ຫາດ​ຫີນ",
                "vill_name_en": "",
                "dr_id": "201"
              },
              {
                "vill_id": "20159",
                "vill_name": "ສົົບ​ກັ້ງ",
                "vill_name_en": "",
                "dr_id": "201"
              },
              {
                "vill_id": "20160",
                "vill_name": "ຕານ+ ບ ຕາຕຸງ",
                "vill_name_en": "",
                "dr_id": "201"
              },
              {
                "vill_id": "20161",
                "vill_name": "ຕາ​ຕຸງ",
                "vill_name_en": "",
                "dr_id": "201"
              },
              {
                "vill_id": "20162",
                "vill_name": "ມົງ​ເຈົ່າ",
                "vill_name_en": "",
                "dr_id": "201"
              },
              {
                "vill_id": "20163",
                "vill_name": "ໂພນ​ສະ​ອາດ",
                "vill_name_en": "",
                "dr_id": "201"
              },
              {
                "vill_id": "20164",
                "vill_name": "ຜົ້ງ​ສາ​ລີ",
                "vill_name_en": "",
                "dr_id": "201"
              },
              {
                "vill_id": "20165",
                "vill_name": "ຈອມ​ເມືອງ",
                "vill_name_en": "",
                "dr_id": "201"
              },
              {
                "vill_id": "20166",
                "vill_name": "ວັດ​ແກ້ວ",
                "vill_name_en": "",
                "dr_id": "201"
              },
              {
                "vill_id": "20167",
                "vill_name": "ສາຍ​ລົມ",
                "vill_name_en": "",
                "dr_id": "201"
              },
              {
                "vill_id": "20168",
                "vill_name": "ໜອງ​ກີນ​ນະ​ລີ",
                "vill_name_en": "",
                "dr_id": "201"
              },
              {
                "vill_id": "20169",
                "vill_name": "ໂພນ​ແກ້ວ",
                "vill_name_en": "",
                "dr_id": "201"
              },
              {
                "vill_id": "20170",
                "vill_name": "ແສນ​ສາ​ລີ",
                "vill_name_en": "",
                "dr_id": "201"
              },
              {
                "vill_id": "20171",
                "vill_name": "ຕະ​ຫຼາດ​ວິ​ໄລ",
                "vill_name_en": "",
                "dr_id": "201"
              },
              {
                "vill_id": "20172",
                "vill_name": "ຮົ່ມ​ສະ​ຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "201"
              },
              {
                "vill_id": "20173",
                "vill_name": " ໂພນໂຮມ",
                "vill_name_en": "",
                "dr_id": "201"
              },
              {
                "vill_id": "20174",
                "vill_name": " ພູ​ຟ້າ",
                "vill_name_en": "",
                "dr_id": "201"
              },
              {
                "vill_id": "20175",
                "vill_name": " ຈະເລີນ",
                "vill_name_en": "",
                "dr_id": "201"
              },
              {
                "vill_id": "20176",
                "vill_name": "  ສາມັກຄີໄຊ",
                "vill_name_en": "",
                "dr_id": "201"
              },
              {
                "vill_id": "20177",
                "vill_name": "ນ້ຳກີ້",
                "vill_name_en": "Namky",
                "dr_id": "201"
              },
              {
                "vill_id": "20178",
                "vill_name": "ໃໝ່",
                "vill_name_en": "Mai",
                "dr_id": "201"
              }
            ]
          },
          {
            "dr_id": "202",
            "dr_name": "ໃຫມ່",
            "dr_name_en": "May",
            "pr_id": "2",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "20201",
                "vill_name": "ປາງຫົກ",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20202",
                "vill_name": "ສົບ​ຮຸນ",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20204",
                "vill_name": "ສົົບ​ນ້າວ",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20205",
                "vill_name": "ປຸງ",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20206",
                "vill_name": "ແສນອາດ+ຫ້ວຍຊອງໃຕ້",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20207",
                "vill_name": "ມົກ​ຈາ​ລະ",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20208",
                "vill_name": "ຫວ້ຍຊອງ",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20209",
                "vill_name": "ຫ້ວຍຈີກ",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20210",
                "vill_name": "ນ້ຳ​ງາ",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20211",
                "vill_name": "ນາ​ຄຳ",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20212",
                "vill_name": "ນາ​ຊອງ",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20213",
                "vill_name": "ນາກາງ",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20214",
                "vill_name": "ອົມ​ກະ​ເນັ່ງ",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20215",
                "vill_name": "ຫ້ວຍ​ວາງ​ສຸດ",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20216",
                "vill_name": "ຫ້ວຍ​ອູ່ນ",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20217",
                "vill_name": "ປ່ອງສີໃໝ່( ກໍ່)",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20218",
                "vill_name": "ຕີນ​ຕົກ",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20219",
                "vill_name": "ອົມປະໂລງ",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20220",
                "vill_name": "ຫ້ວຍ​ວາງໃໝ່",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20221",
                "vill_name": "ຫ້ວຍ​ວາງເກົ່າ",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20222",
                "vill_name": "ປຸງ​ຍາງ",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20223",
                "vill_name": "ຕະ​ໂມະ​",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20224",
                "vill_name": "ຫາດ​ຈາ",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20225",
                "vill_name": "ສີ​ດາ",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20226",
                "vill_name": "ຫຍ້າ​ຍຸງ",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20227",
                "vill_name": "ກິວກະແລະ",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20228",
                "vill_name": "ຕ່າງ​ໂຊຍ",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20229",
                "vill_name": "ນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20230",
                "vill_name": "ກຸງ​ຮະ",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20231",
                "vill_name": "ປາກ​ແລ້ງ",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20232",
                "vill_name": "ປາກ​ແພ",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20233",
                "vill_name": "ປາງ​ໄຮ່​ເໜືອ",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20234",
                "vill_name": "ສູງ​ລູງ",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20235",
                "vill_name": "ຈະ​ເມີຍ",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20236",
                "vill_name": "ຢາງ​ໃຕ້",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20237",
                "vill_name": "ຈາບື",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20238",
                "vill_name": "ມູ​ຕີນ",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20239",
                "vill_name": "ປາກຢວນ",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20240",
                "vill_name": "ຫ້ວຍດ້ວຍ",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20241",
                "vill_name": "ຫ້ວຍ​ກາງ",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20242",
                "vill_name": "ນາ​ຫຼວງ",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20243",
                "vill_name": "ບວມ​ພັນ",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20244",
                "vill_name": "ແສນ​ອີນ",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20245",
                "vill_name": "ກຸ້ງ​ລູກ",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20246",
                "vill_name": "ກິວກ​ະ​ຈຳ",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20247",
                "vill_name": "ອົົມ​ພະລຸ",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20248",
                "vill_name": "ແສນ​ຫລວງ",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20249",
                "vill_name": "ເພຍ",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20250",
                "vill_name": "ຫຍ້າ​ຄາ",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20251",
                "vill_name": "ແສນ​ຕາ",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20252",
                "vill_name": "ຫ້ວຍ​ລາຍ",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20253",
                "vill_name": "ຈອມ​ແຈ່ວ​ໃຫມ່",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20254",
                "vill_name": "ຫ້ວຍ​ມື່ນ",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20255",
                "vill_name": "ນາ​ບົວ",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20256",
                "vill_name": "ໂພນ​ໄຊ",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20257",
                "vill_name": "ຫ້ວຍອັນ",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20258",
                "vill_name": "ຊາວ​ແຢ",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20259",
                "vill_name": "ເພຍ​ລໍ​ເກົ່າ",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20260",
                "vill_name": "ເພຍ​ລໍ​ໃໝ່",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20261",
                "vill_name": "ນ້ຳ​ໂຂ້ງ",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20262",
                "vill_name": "ປ່າ​ລັນ",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20263",
                "vill_name": "ຫ້ວຍ​ທອງ",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20264",
                "vill_name": "ເພຍ​ຫລວງ",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20265",
                "vill_name": "ຫ້ວຍ​ເງີຍ",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20266",
                "vill_name": "ລາວ​ມາກາງ",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20267",
                "vill_name": "ນາ​ຂວາ",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20268",
                "vill_name": "ຫ້ວຍ​ກົບ",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20269",
                "vill_name": "ນາ​ແລ",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20270",
                "vill_name": "ໃໝ່ເດຶອ",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20271",
                "vill_name": "ສົ້ມ​ປ​ອ່ຍ",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20272",
                "vill_name": "ນ້ຳ​ແນນ",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20273",
                "vill_name": "ຫ້ວຍ​ຮ່ອງ",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20274",
                "vill_name": "ຫ້ວຍ​ເຮັ້ຽ",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20275",
                "vill_name": "ສະ​ຫລວງ-ໜອງ​ແຮ້ວ",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20276",
                "vill_name": "ຫ້ວຍ​ຫລ້າ",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20277",
                "vill_name": "ກາງ+ ບ. ນາ​ລຳ",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20278",
                "vill_name": "ໃໝ່​ກໍ່​ລີວ",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20279",
                "vill_name": "ຝິ່ນ​ຫໍ່",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20280",
                "vill_name": "ຫາດ​ດີ",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20281",
                "vill_name": "ປາງໄຮ່ໃຕ້",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20282",
                "vill_name": "ຫ້ວຍ​ລອດ",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20283",
                "vill_name": "ພື້ນຕີ",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20284",
                "vill_name": "ຫົວນາ",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20285",
                "vill_name": "ຫ້ວຍ​ພຸກ( ກືມມຸ )",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20286",
                "vill_name": "ຫ້ວຍ​ຈ້າ",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20287",
                "vill_name": "ປຸງ​ເຄົ້າ",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20288",
                "vill_name": "ຈອມ​ແຈ່ວ ​ເກົ່າ",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20289",
                "vill_name": "ຂ້າ​ຈີ່",
                "vill_name_en": "",
                "dr_id": "202"
              },
              {
                "vill_id": "20290",
                "vill_name": "ນາລຳ",
                "vill_name_en": "Nalam",
                "dr_id": "202"
              }
            ]
          },
          {
            "dr_id": "203",
            "dr_name": "ຂວາ",
            "dr_name_en": "Khua",
            "pr_id": "2",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "20301",
                "vill_name": "ເມືອງ​ຂວາ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20302",
                "vill_name": "ສົບ​ອັດ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20303",
                "vill_name": "ຫ້ວຍ​ຢາງ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20304",
                "vill_name": "ນາ​ຕູ່ນ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20305",
                "vill_name": "ສະ​ຫຼອງ​ໄຊ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20306",
                "vill_name": "ຫາດ​ເດ່ືອ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20307",
                "vill_name": "ໂພ​ໄຊ​ໃຕ້",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20308",
                "vill_name": "ທ່າ​ບັກ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20309",
                "vill_name": "ໂພ​ໄຊ​ເໜືອ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20310",
                "vill_name": "ໂພນ​ໄຊ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20311",
                "vill_name": "ຕາງ​ກົກ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20312",
                "vill_name": "ຫ້ວຍລີກ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20313",
                "vill_name": "ຫ້ວຍສອຍ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20314",
                "vill_name": "ຫ້ວຍ​ພຸກ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20315",
                "vill_name": "ຫ້ວຍ​ແພ​ອູ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20316",
                "vill_name": "ຫ້ວຍ​ແອ່ນ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20317",
                "vill_name": "ຫ້ວຍ​ປາ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20318",
                "vill_name": "ຈາກ​ກຸດ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20319",
                "vill_name": "ຈັນ​ໃໜ່",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20320",
                "vill_name": "ຫ້ວຍ​ມ່ວນ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20321",
                "vill_name": "ສົບກາຍ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20322",
                "vill_name": "ຫ້ວຍ​ຄ່າງ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20323",
                "vill_name": "ລາດ​ຊ້າງ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20324",
                "vill_name": "ຫາດ​ເຊີຍ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20325",
                "vill_name": "ມອນ​ສະ​ຫວັນ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20326",
                "vill_name": "ໃໜ່",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20327",
                "vill_name": "ຫາດ​ນາງ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20328",
                "vill_name": "ສີນ​ໄຊ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20329",
                "vill_name": "ລາວ​ລີ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20330",
                "vill_name": "ແສນ​ຄຳ​ໃຕ້",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20331",
                "vill_name": "ລີ​ສຸ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20332",
                "vill_name": "ດົງ​ຄາມ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20333",
                "vill_name": "ຫ້ວຍ​ພອດ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20334",
                "vill_name": "ສັນ​ຫລວງ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20335",
                "vill_name": "ຫ້ວຍ​ແພ​(ກໍ່)",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20336",
                "vill_name": "ລະ​ຮ່າງ​ໃຫ່​ຍ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20337",
                "vill_name": "ນ້ຳ​ບ່ອນ​ໃຕ້",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20338",
                "vill_name": "ສ້າງ​ຫ້ວຍ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20339",
                "vill_name": "ນ້ຳ​ບ່ອນ​ເໜືອ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20340",
                "vill_name": "ສະ​ນຳ​ແຈບ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20341",
                "vill_name": "ສະ​ບໍ່",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20342",
                "vill_name": "ຈະ​ລະ​ໃໜ່",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20343",
                "vill_name": "ຕາງ​ກະ​ລະ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20344",
                "vill_name": "ຈະ​ລະ​ເກົ່າ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20345",
                "vill_name": "ຕອນ​ຈົ່ງ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20346",
                "vill_name": "ນ້ຳ​ມາ​ໃຕ້",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20347",
                "vill_name": "ນ້ຳ​ມ່າ​ເໜືອ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20348",
                "vill_name": "ຮ່ອງ​ເລີກ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20349",
                "vill_name": "ປູ​ລູ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20350",
                "vill_name": "ພິ​ເຈີ​ເກົ່າ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20351",
                "vill_name": "ພິິ​ເຈີ​ໃໜ່",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20352",
                "vill_name": "ມົກ​ກວາງ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20353",
                "vill_name": "ແສນ​ຄຳ​ເໜືອ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20354",
                "vill_name": "ບວມ​ພັນ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20355",
                "vill_name": "ຫ້ວຍ​ຫຼ້າ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20356",
                "vill_name": "ກົກ​ພ້າວ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20357",
                "vill_name": "ກົົກ​ງີ້ວ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20358",
                "vill_name": "ຈອມ​ວັນ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20360",
                "vill_name": "ລະ​ຮ່າງ​ນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20361",
                "vill_name": "ກີ້ວ​ທາກ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20362",
                "vill_name": "ກົົກ​ງີ້ວ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20363",
                "vill_name": "ກີ້ວ​ ( ຫ້ວຍປຸ່ງ )",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20364",
                "vill_name": "ຫ້ວຍ​ອອນ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20365",
                "vill_name": "ຢາ​ໂລະ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20366",
                "vill_name": "ແສນ​ຢາງ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20367",
                "vill_name": "ກີ້ວ​ກຳ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20368",
                "vill_name": "ມົກ​ແພກ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20369",
                "vill_name": "ພູ​ວຽງ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20370",
                "vill_name": "ຫ້ວຍ​ທອງ+ມົກໂຈນ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20371",
                "vill_name": "ກອງວັດ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20372",
                "vill_name": "ດັບ​ກະ​ຈອກ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20373",
                "vill_name": "ປາກ​ກວນ ( ນ້ຳກວນ )",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20374",
                "vill_name": "ໂພນທອງ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20375",
                "vill_name": "ຫ້ວຍ​ພຽງ+ທອງເສັດ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20376",
                "vill_name": "ອົມ​ຕະ​ລະ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20377",
                "vill_name": "ຫ້ວຍ​ສີງ( ຫ້ວຍສະແລັງ )",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20378",
                "vill_name": "ແສນ​ລາດ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20379",
                "vill_name": "ກຸງ​ເກື​ອຍ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20380",
                "vill_name": "ລາງຈາກ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20381",
                "vill_name": "ກຸງ​ລິດ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20382",
                "vill_name": "ອົົມ​ມົກ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20383",
                "vill_name": "ຫ້ວຍ​ກຸດ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20384",
                "vill_name": "ໂພນ​ສະ​ອາດ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20385",
                "vill_name": "ອົມ​ເພຍ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20386",
                "vill_name": "ຢ່າງ​ເຕີຍ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20387",
                "vill_name": "ອົມທັບ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20388",
                "vill_name": "ນາ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20389",
                "vill_name": "ວຽງຄຳ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20390",
                "vill_name": "ຕັັນ​ຫະ+ ບ ກົກງິ້ວ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20391",
                "vill_name": "ຫາດ​ແດນ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20392",
                "vill_name": "ອົມ​ຕຼຶ່ນ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20393",
                "vill_name": "ວັງກອງ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20394",
                "vill_name": "ກອງ​ບຼາງ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20395",
                "vill_name": "ກະ​ດິງ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20396",
                "vill_name": "ວຽງ​ໄຊ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20397",
                "vill_name": "ປາກ​ບານ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20398",
                "vill_name": "ຫາດ​ໂປນ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20399",
                "vill_name": "ປາກ​ນົວ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20400",
                "vill_name": "ນ້ຳ​ບູດ",
                "vill_name_en": "",
                "dr_id": "203"
              },
              {
                "vill_id": "20401",
                "vill_name": "ລາງຈາກ",
                "vill_name_en": "",
                "dr_id": "203"
              }
            ]
          },
          {
            "dr_id": "204",
            "dr_name": "ສຳພັນ",
            "dr_name_en": "Samphanh",
            "pr_id": "2",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "20402",
                "vill_name": "ນາໄຊ",
                "vill_name_en": "",
                "dr_id": "204"
              },
              {
                "vill_id": "20403",
                "vill_name": "ນາ+ຫາດລານ",
                "vill_name_en": "",
                "dr_id": "204"
              },
              {
                "vill_id": "20404",
                "vill_name": "ຂາ​ໜ້າ",
                "vill_name_en": "",
                "dr_id": "204"
              },
              {
                "vill_id": "20405",
                "vill_name": "ເສື່ອທ່ຽວ",
                "vill_name_en": "",
                "dr_id": "204"
              },
              {
                "vill_id": "20406",
                "vill_name": "ພະ​ນາງ",
                "vill_name_en": "",
                "dr_id": "204"
              },
              {
                "vill_id": "20407",
                "vill_name": "ນາ​ເຕີຍ",
                "vill_name_en": "",
                "dr_id": "204"
              },
              {
                "vill_id": "20408",
                "vill_name": "ພຽງ",
                "vill_name_en": "",
                "dr_id": "204"
              },
              {
                "vill_id": "20409",
                "vill_name": "ລາ​ຊາ​ເກົ່າ",
                "vill_name_en": "",
                "dr_id": "204"
              },
              {
                "vill_id": "20410",
                "vill_name": "ລາວ​ແສນ",
                "vill_name_en": "",
                "dr_id": "204"
              },
              {
                "vill_id": "20411",
                "vill_name": "ດອນ​ຈັນ",
                "vill_name_en": "",
                "dr_id": "204"
              },
              {
                "vill_id": "20412",
                "vill_name": "ສົມ​ບູນ",
                "vill_name_en": "",
                "dr_id": "204"
              },
              {
                "vill_id": "20413",
                "vill_name": "ລິຫວາຍ",
                "vill_name_en": "",
                "dr_id": "204"
              },
              {
                "vill_id": "20414",
                "vill_name": "ກົກ​ມ່ວງ",
                "vill_name_en": "",
                "dr_id": "204"
              },
              {
                "vill_id": "20415",
                "vill_name": "ມົກ​ລົມ",
                "vill_name_en": "",
                "dr_id": "204"
              },
              {
                "vill_id": "20416",
                "vill_name": "ນ້ຳ​ອ່ອນ",
                "vill_name_en": "",
                "dr_id": "204"
              },
              {
                "vill_id": "20417",
                "vill_name": "ຢາງເໜືອ",
                "vill_name_en": "",
                "dr_id": "204"
              },
              {
                "vill_id": "20418",
                "vill_name": "ພູ​ໄຊ",
                "vill_name_en": "",
                "dr_id": "204"
              },
              {
                "vill_id": "20419",
                "vill_name": "ອົມ​ຖ້ຳ",
                "vill_name_en": "",
                "dr_id": "204"
              },
              {
                "vill_id": "20420",
                "vill_name": "ຫ້ວຍ​ທອງ",
                "vill_name_en": "",
                "dr_id": "204"
              },
              {
                "vill_id": "20421",
                "vill_name": "ສົບ​ຄິກ",
                "vill_name_en": "",
                "dr_id": "204"
              },
              {
                "vill_id": "20422",
                "vill_name": "ສຽງ​ເໜືອ",
                "vill_name_en": "",
                "dr_id": "204"
              },
              {
                "vill_id": "20423",
                "vill_name": "ໜອງ​ຄຳ",
                "vill_name_en": "",
                "dr_id": "204"
              },
              {
                "vill_id": "20424",
                "vill_name": "ນ້ຳ​ຮຸນ",
                "vill_name_en": "",
                "dr_id": "204"
              },
              {
                "vill_id": "20425",
                "vill_name": "ນ້ຳ​ຍວນ",
                "vill_name_en": "",
                "dr_id": "204"
              },
              {
                "vill_id": "20426",
                "vill_name": "ນ້ຳ​ຮາງ",
                "vill_name_en": "",
                "dr_id": "204"
              },
              {
                "vill_id": "20427",
                "vill_name": "ນາ​ອູ",
                "vill_name_en": "",
                "dr_id": "204"
              },
              {
                "vill_id": "20428",
                "vill_name": "ຊາ​ແກ້ວ",
                "vill_name_en": "",
                "dr_id": "204"
              },
              {
                "vill_id": "20429",
                "vill_name": "ນ້ຳ​ທວງ",
                "vill_name_en": "",
                "dr_id": "204"
              },
              {
                "vill_id": "20430",
                "vill_name": "ລາວ​ປານ",
                "vill_name_en": "",
                "dr_id": "204"
              },
              {
                "vill_id": "20431",
                "vill_name": "ທ້າວ​ວົງ",
                "vill_name_en": "",
                "dr_id": "204"
              },
              {
                "vill_id": "20432",
                "vill_name": "ກອງ​ສະ​ວິ",
                "vill_name_en": "",
                "dr_id": "204"
              },
              {
                "vill_id": "20433",
                "vill_name": "ນ້ຳ​ງາ",
                "vill_name_en": "",
                "dr_id": "204"
              },
              {
                "vill_id": "20434",
                "vill_name": "ຕາງ​ຈາກ",
                "vill_name_en": "",
                "dr_id": "204"
              },
              {
                "vill_id": "20435",
                "vill_name": "ຫາດ​ງາ​ເໜືອ",
                "vill_name_en": "",
                "dr_id": "204"
              },
              {
                "vill_id": "20436",
                "vill_name": "ຫາດ​ງາ​ໃຕ້",
                "vill_name_en": "",
                "dr_id": "204"
              },
              {
                "vill_id": "20437",
                "vill_name": "ພາ​ເຕົ່າ",
                "vill_name_en": "",
                "dr_id": "204"
              },
              {
                "vill_id": "20438",
                "vill_name": "ມົົກ​ກ​ົກ​ຫຼວງ",
                "vill_name_en": "",
                "dr_id": "204"
              },
              {
                "vill_id": "20439",
                "vill_name": "ນ້ຳ​ລີ",
                "vill_name_en": "",
                "dr_id": "204"
              },
              {
                "vill_id": "20440",
                "vill_name": "ພູ​ວຽງ",
                "vill_name_en": "",
                "dr_id": "204"
              },
              {
                "vill_id": "20441",
                "vill_name": "ມົົກ​ກົກ​ນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "204"
              },
              {
                "vill_id": "20442",
                "vill_name": "ນ້ຳ​ກີ້+ດອກຄຳ",
                "vill_name_en": "",
                "dr_id": "204"
              },
              {
                "vill_id": "20443",
                "vill_name": "ເມືອງ​ຮຸນ",
                "vill_name_en": "",
                "dr_id": "204"
              },
              {
                "vill_id": "20444",
                "vill_name": "ສີລາ​ເທິງ",
                "vill_name_en": "",
                "dr_id": "204"
              },
              {
                "vill_id": "20445",
                "vill_name": "ມົົກ​ຍົນ",
                "vill_name_en": "",
                "dr_id": "204"
              },
              {
                "vill_id": "20446",
                "vill_name": "ນ້ຳ​ລອຍ",
                "vill_name_en": "",
                "dr_id": "204"
              },
              {
                "vill_id": "20447",
                "vill_name": "ກອງ​ຂູ່ມ",
                "vill_name_en": "",
                "dr_id": "204"
              },
              {
                "vill_id": "20448",
                "vill_name": "ລາວ​ເລວ",
                "vill_name_en": "",
                "dr_id": "204"
              },
              {
                "vill_id": "20449",
                "vill_name": "ຈາ​ຊື່",
                "vill_name_en": "",
                "dr_id": "204"
              },
              {
                "vill_id": "20450",
                "vill_name": "ລາວ​ປານຫຼວງ",
                "vill_name_en": "",
                "dr_id": "204"
              },
              {
                "vill_id": "20451",
                "vill_name": "ຜົ້ງ​ກູ້​ຫລວງ",
                "vill_name_en": "",
                "dr_id": "204"
              },
              {
                "vill_id": "20452",
                "vill_name": "ມູ​ຈີ​ກາງ",
                "vill_name_en": "",
                "dr_id": "204"
              },
              {
                "vill_id": "20453",
                "vill_name": "ພູ​ຊາງ​ເກົ່າ",
                "vill_name_en": "",
                "dr_id": "204"
              },
              {
                "vill_id": "20454",
                "vill_name": "ພູ​ຊາງ​ໃໜ່",
                "vill_name_en": "",
                "dr_id": "204"
              },
              {
                "vill_id": "20455",
                "vill_name": "ມູູ​ຈີ​ເກົ່າ",
                "vill_name_en": "",
                "dr_id": "204"
              },
              {
                "vill_id": "20456",
                "vill_name": "ເຄີຈາງ​ເກົ່າ+ໃໜ່",
                "vill_name_en": "",
                "dr_id": "204"
              },
              {
                "vill_id": "20457",
                "vill_name": "ໜອງ​ແກ້ວ",
                "vill_name_en": "",
                "dr_id": "204"
              },
              {
                "vill_id": "20458",
                "vill_name": "ແສນ​ພົມ",
                "vill_name_en": "",
                "dr_id": "204"
              },
              {
                "vill_id": "20459",
                "vill_name": "ແສນຄຳ",
                "vill_name_en": "",
                "dr_id": "204"
              },
              {
                "vill_id": "20460",
                "vill_name": "ມົົກ​ພະ",
                "vill_name_en": "",
                "dr_id": "204"
              },
              {
                "vill_id": "20461",
                "vill_name": "ອີ​ແລັກ",
                "vill_name_en": "",
                "dr_id": "204"
              },
              {
                "vill_id": "20462",
                "vill_name": "ອາ​ເຊີ",
                "vill_name_en": "",
                "dr_id": "204"
              },
              {
                "vill_id": "20463",
                "vill_name": "ເພຍ​ຫລ​ວງ",
                "vill_name_en": "",
                "dr_id": "204"
              },
              {
                "vill_id": "20464",
                "vill_name": "ແສນ​ຫຼວງໃຫມ່",
                "vill_name_en": "",
                "dr_id": "204"
              },
              {
                "vill_id": "20465",
                "vill_name": "ສະ​ນຳ​ຮະ",
                "vill_name_en": "",
                "dr_id": "204"
              },
              {
                "vill_id": "20466",
                "vill_name": "ຢາງ​ປາ",
                "vill_name_en": "",
                "dr_id": "204"
              },
              {
                "vill_id": "20467",
                "vill_name": "ໜອງ​ຫຸ້ມ",
                "vill_name_en": "",
                "dr_id": "204"
              },
              {
                "vill_id": "20468",
                "vill_name": "ຈາ​ພິວ​ນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "204"
              },
              {
                "vill_id": "20469",
                "vill_name": "ລີຊີໂຊເກົ່າ",
                "vill_name_en": "",
                "dr_id": "204"
              },
              {
                "vill_id": "20470",
                "vill_name": "ຈາ​ພິວ​ຫລວງ",
                "vill_name_en": "",
                "dr_id": "204"
              },
              {
                "vill_id": "20471",
                "vill_name": "ເອິ​ປ່າ",
                "vill_name_en": "",
                "dr_id": "204"
              },
              {
                "vill_id": "20472",
                "vill_name": "ສັນຕານ+ນ້ຳຍວນ",
                "vill_name_en": "",
                "dr_id": "204"
              },
              {
                "vill_id": "20473",
                "vill_name": "ນ້ຳຄີກ",
                "vill_name_en": "Namkhiek",
                "dr_id": "204"
              }
            ]
          },
          {
            "dr_id": "205",
            "dr_name": "ບູນເຫນືອ",
            "dr_name_en": "Bounneua",
            "pr_id": "2",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "20501",
                "vill_name": "ແສນ​ໄຊ",
                "vill_name_en": "",
                "dr_id": "205"
              },
              {
                "vill_id": "20502",
                "vill_name": "ບໍ່ມ່າງ",
                "vill_name_en": "",
                "dr_id": "205"
              },
              {
                "vill_id": "20503",
                "vill_name": "ແສນ​ທຳ",
                "vill_name_en": "",
                "dr_id": "205"
              },
              {
                "vill_id": "20504",
                "vill_name": "ບໍ່ໃໜ່",
                "vill_name_en": "",
                "dr_id": "205"
              },
              {
                "vill_id": "20505",
                "vill_name": "ນາ​ແລ",
                "vill_name_en": "",
                "dr_id": "205"
              },
              {
                "vill_id": "20506",
                "vill_name": "ພຽງ​ດອກ​ຄຳ",
                "vill_name_en": "",
                "dr_id": "205"
              },
              {
                "vill_id": "20507",
                "vill_name": "ກອງ",
                "vill_name_en": "",
                "dr_id": "205"
              },
              {
                "vill_id": "20508",
                "vill_name": "ສົມ​ໄຊ",
                "vill_name_en": "",
                "dr_id": "205"
              },
              {
                "vill_id": "20509",
                "vill_name": "ນ້ຳ​ບຸນ​ເກົ່າ",
                "vill_name_en": "",
                "dr_id": "205"
              },
              {
                "vill_id": "20510",
                "vill_name": "ຫ້ວຍ​ເຫຼັກ",
                "vill_name_en": "",
                "dr_id": "205"
              },
              {
                "vill_id": "20511",
                "vill_name": "ໃໜ່​ລົງ​ທົ່ງ",
                "vill_name_en": "",
                "dr_id": "205"
              },
              {
                "vill_id": "20512",
                "vill_name": "ຍອດ​ບຸນ",
                "vill_name_en": "",
                "dr_id": "205"
              },
              {
                "vill_id": "20513",
                "vill_name": "ມໍ​ຊໍ​ສານ",
                "vill_name_en": "",
                "dr_id": "205"
              },
              {
                "vill_id": "20514",
                "vill_name": "ຜາ​ປູນ​ເກົ່າ",
                "vill_name_en": "",
                "dr_id": "205"
              },
              {
                "vill_id": "20515",
                "vill_name": "ແຂມ​ກໍ",
                "vill_name_en": "",
                "dr_id": "205"
              },
              {
                "vill_id": "20516",
                "vill_name": "ຫ້ວຍ​ຊ້າງ",
                "vill_name_en": "",
                "dr_id": "205"
              },
              {
                "vill_id": "20517",
                "vill_name": "ຫ່ົມ",
                "vill_name_en": "",
                "dr_id": "205"
              },
              {
                "vill_id": "20518",
                "vill_name": "ຊຽງ​ຟ້າ",
                "vill_name_en": "",
                "dr_id": "205"
              },
              {
                "vill_id": "20519",
                "vill_name": "ນາ​ເປາະ",
                "vill_name_en": "",
                "dr_id": "205"
              },
              {
                "vill_id": "20520",
                "vill_name": "ດົງ​ເໜືອ",
                "vill_name_en": "",
                "dr_id": "205"
              },
              {
                "vill_id": "20521",
                "vill_name": "ນ້ອຍ+ພູມັ່ງ",
                "vill_name_en": "",
                "dr_id": "205"
              },
              {
                "vill_id": "20522",
                "vill_name": "ຫ້ວຍ​ຍຶ່ງ",
                "vill_name_en": "",
                "dr_id": "205"
              },
              {
                "vill_id": "20523",
                "vill_name": "ພຽງ​ໄຊ",
                "vill_name_en": "",
                "dr_id": "205"
              },
              {
                "vill_id": "20524",
                "vill_name": "ເດ່ືອ",
                "vill_name_en": "",
                "dr_id": "205"
              },
              {
                "vill_id": "20525",
                "vill_name": "ຫ​້ວ​ຍ​ຕູ້",
                "vill_name_en": "",
                "dr_id": "205"
              },
              {
                "vill_id": "20526",
                "vill_name": "ບໍ່​ຂູ້ນ",
                "vill_name_en": "",
                "dr_id": "205"
              },
              {
                "vill_id": "20527",
                "vill_name": "ຫົວ​ຂົວ",
                "vill_name_en": "",
                "dr_id": "205"
              },
              {
                "vill_id": "20528",
                "vill_name": "ປາ​ຄາ​ໃໜ່",
                "vill_name_en": "",
                "dr_id": "205"
              },
              {
                "vill_id": "20529",
                "vill_name": "ຫ້ວຍ​ຕາດ",
                "vill_name_en": "",
                "dr_id": "205"
              },
              {
                "vill_id": "20530",
                "vill_name": "ປາ​ຄາ​ເກົ່າ",
                "vill_name_en": "",
                "dr_id": "205"
              },
              {
                "vill_id": "20531",
                "vill_name": "ປາງ​ຫົກ+ສົບຂີງ",
                "vill_name_en": "",
                "dr_id": "205"
              },
              {
                "vill_id": "20532",
                "vill_name": "ງາຍເໝືອ+ຊຽງໃຕ້",
                "vill_name_en": "",
                "dr_id": "205"
              },
              {
                "vill_id": "20533",
                "vill_name": "ນ້ຳກ່າງ+ນ້ຳຮື",
                "vill_name_en": "",
                "dr_id": "205"
              },
              {
                "vill_id": "20534",
                "vill_name": "ຍໍ້",
                "vill_name_en": "",
                "dr_id": "205"
              },
              {
                "vill_id": "20535",
                "vill_name": "ບູນ​ເໜືອ",
                "vill_name_en": "",
                "dr_id": "205"
              },
              {
                "vill_id": "20536",
                "vill_name": "ອາ​ແປ​ຄໍ",
                "vill_name_en": "",
                "dr_id": "205"
              },
              {
                "vill_id": "20537",
                "vill_name": "ຫ້ວຍ​ໂປ່ງ",
                "vill_name_en": "",
                "dr_id": "205"
              },
              {
                "vill_id": "20538",
                "vill_name": "ແສນ​ດາວໃຕ້",
                "vill_name_en": "",
                "dr_id": "205"
              },
              {
                "vill_id": "20539",
                "vill_name": "ພູຊ້າ+ກິມກໍ່",
                "vill_name_en": "",
                "dr_id": "205"
              },
              {
                "vill_id": "20540",
                "vill_name": "ທົ່ງ​ເຮືອ​ບີນ",
                "vill_name_en": "",
                "dr_id": "205"
              },
              {
                "vill_id": "20541",
                "vill_name": "ນ້ຳລູ",
                "vill_name_en": "",
                "dr_id": "205"
              },
              {
                "vill_id": "20542",
                "vill_name": "ເພຽ​ເຈັນ ເກົ່າ",
                "vill_name_en": "",
                "dr_id": "205"
              },
              {
                "vill_id": "20543",
                "vill_name": "ນ້ຳ​ຮອຍ",
                "vill_name_en": "",
                "dr_id": "205"
              },
              {
                "vill_id": "20544",
                "vill_name": "ສົົມ​ບູນ",
                "vill_name_en": "",
                "dr_id": "205"
              },
              {
                "vill_id": "20545",
                "vill_name": "ຊຽງ​ປີ້",
                "vill_name_en": "",
                "dr_id": "205"
              },
              {
                "vill_id": "20546",
                "vill_name": "ດອນ​ຊາຍ",
                "vill_name_en": "",
                "dr_id": "205"
              },
              {
                "vill_id": "20547",
                "vill_name": "ນ້ຳ​ແພ",
                "vill_name_en": "",
                "dr_id": "205"
              },
              {
                "vill_id": "20548",
                "vill_name": "ວັງ​ດອຍ",
                "vill_name_en": "",
                "dr_id": "205"
              },
              {
                "vill_id": "20549",
                "vill_name": "ບູນພຽງ",
                "vill_name_en": "",
                "dr_id": "205"
              },
              {
                "vill_id": "20550",
                "vill_name": "ນ້ຳ​ຢ່ອງ​ເກົ່າ",
                "vill_name_en": "",
                "dr_id": "205"
              },
              {
                "vill_id": "20551",
                "vill_name": "ໂພນ​ໄຊ",
                "vill_name_en": "",
                "dr_id": "205"
              },
              {
                "vill_id": "20552",
                "vill_name": "ເດີນ​ກິ​ລາ",
                "vill_name_en": "",
                "dr_id": "205"
              },
              {
                "vill_id": "20553",
                "vill_name": "ໂນນ​ສະ​ຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "205"
              },
              {
                "vill_id": "20554",
                "vill_name": "ໂພນໂຮມ",
                "vill_name_en": "",
                "dr_id": "205"
              },
              {
                "vill_id": "20555",
                "vill_name": "ໃໜ່​",
                "vill_name_en": "",
                "dr_id": "205"
              },
              {
                "vill_id": "20556",
                "vill_name": "ຫ້ວຍ​ດົງ",
                "vill_name_en": "",
                "dr_id": "205"
              },
              {
                "vill_id": "20557",
                "vill_name": "ບູນ​ເຟືອງ",
                "vill_name_en": "",
                "dr_id": "205"
              },
              {
                "vill_id": "20558",
                "vill_name": "ນ້ຳ​ຍ່ອງໃໜ່",
                "vill_name_en": "",
                "dr_id": "205"
              },
              {
                "vill_id": "20559",
                "vill_name": "ຫ້ວຍ​ຂ່າ",
                "vill_name_en": "",
                "dr_id": "205"
              },
              {
                "vill_id": "20560",
                "vill_name": "ຜາແດງ+ແສນດາວ",
                "vill_name_en": "",
                "dr_id": "205"
              },
              {
                "vill_id": "20561",
                "vill_name": "ຫ້ວຍ​ລໍ",
                "vill_name_en": "",
                "dr_id": "205"
              },
              {
                "vill_id": "20562",
                "vill_name": "ຫ້ວຍແລ",
                "vill_name_en": "",
                "dr_id": "205"
              },
              {
                "vill_id": "20563",
                "vill_name": "ຫ້ວຍກັດ",
                "vill_name_en": "",
                "dr_id": "205"
              },
              {
                "vill_id": "20564",
                "vill_name": "ບູນຊາງ",
                "vill_name_en": "",
                "dr_id": "205"
              },
              {
                "vill_id": "20565",
                "vill_name": "ຫ້ວຍສູງ",
                "vill_name_en": "",
                "dr_id": "205"
              },
              {
                "vill_id": "20566",
                "vill_name": "ຫຼັກ 68",
                "vill_name_en": "",
                "dr_id": "205"
              },
              {
                "vill_id": "20567",
                "vill_name": "ຄົ້ນ",
                "vill_name_en": "Khon",
                "dr_id": "205"
              },
              {
                "vill_id": "20568",
                "vill_name": "ລ້ອງນາຍເກົ່າ",
                "vill_name_en": "longnakao",
                "dr_id": "205"
              }
            ]
          },
          {
            "dr_id": "206",
            "dr_name": "ຍອດອູ",
            "dr_name_en": "Nhot ou",
            "pr_id": "2",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "20601",
                "vill_name": "ນາໄຊ",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20602",
                "vill_name": "ເທີງ+ພູວຽງ",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20603",
                "vill_name": "ລູ່ມ",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20604",
                "vill_name": "ກາດ",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20605",
                "vill_name": "ໃຕ້",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20606",
                "vill_name": "ງ້ອງ",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20607",
                "vill_name": "ລ່າ",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20608",
                "vill_name": "ແກ່ງ",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20609",
                "vill_name": "ນາ​ຫລວງ",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20610",
                "vill_name": "ເຄີເຫນຶອ",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20611",
                "vill_name": "ສູມ​ຄຳ",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20612",
                "vill_name": "ຕ້າງ",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20613",
                "vill_name": "ປາງ​ຫົກ",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20614",
                "vill_name": "ຫ້ວຍ​ອູ",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20615",
                "vill_name": "ນາ​ຍາວ",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20616",
                "vill_name": "ພໍ້+ກ້າ+ຫີນ",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20617",
                "vill_name": "ບໍ່​ໃຕ້",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20618",
                "vill_name": "ຈອມ​ພໍ້",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20619",
                "vill_name": "ສົມ​ເຮືອງ",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20620",
                "vill_name": "ດອນ​ແຍງ",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20621",
                "vill_name": "ນາ​ວອນ",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20622",
                "vill_name": "ຫນອງ​ງາຍ",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20623",
                "vill_name": "ຊຽງ​ຄອນ",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20624",
                "vill_name": "ບໍ່​ຊາວ",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20625",
                "vill_name": "ສວຍ​ງາມ",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20626",
                "vill_name": "ຫ້ວຍ​ແປ​ເໜືອ",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20627",
                "vill_name": "ຫ້ວຍ​ແປ​ໃຕ້",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20628",
                "vill_name": "ນາ​ຫົກ",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20629",
                "vill_name": "ຕ່າກູ​ຊາງ",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20630",
                "vill_name": "ກາງ",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20631",
                "vill_name": "ນ້ຳ​ມ່າ​ໃຫ່​ຍ",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20632",
                "vill_name": "ສູ​ລີນ",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20633",
                "vill_name": "ນ້ຳ​ມາ​ນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20634",
                "vill_name": "ແຊວ​ໂຮ​ແປນ",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20635",
                "vill_name": "ເປີ​ລາວ​ວູ",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20636",
                "vill_name": "ຕົງ​ກວາ​ລີນ​ນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20637",
                "vill_name": "ຕົງ​ກວາລີນ​ໃຫ່​ຍ",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20638",
                "vill_name": "ຫ້ວຍຍ​ູມ",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20639",
                "vill_name": "ພັງ​ສານ",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20640",
                "vill_name": "ພູ​ເໜືອສານ",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20641",
                "vill_name": "ປ່າ​ຈ່າ",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20642",
                "vill_name": "ຫ້ວຍ​ວາ",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20643",
                "vill_name": "ແຈ່ນສານ+ຫ້ວຍເດືອ",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20644",
                "vill_name": "ຜ່າ​ແດງ​ນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20645",
                "vill_name": "ຢາ​ເຄີ",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20646",
                "vill_name": "ເຢ່​ນິວ​ຕຸ່ງໃຫ່ຍ",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20647",
                "vill_name": "ຫ້ວຍ​ກາ​ໃໝ່",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20648",
                "vill_name": "ຊຽງສ້ຽວ+ນາຄຳ",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20649",
                "vill_name": "ແຊວຈ້າຍ+ຫ້ວຍວ່າຍ",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20650",
                "vill_name": "ລານຕຸ່ຍ+ສ່ວນແຕງ",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20651",
                "vill_name": "ນ້ຳ​ມ່າ",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20652",
                "vill_name": "ລົງ​ທາງ",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20653",
                "vill_name": "ລີ​ສານ​ໝໍ້",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20654",
                "vill_name": "ວູເປົາເໜືອ",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20655",
                "vill_name": "ວູ​ເປົາ​ກາງ",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20656",
                "vill_name": "ຕ້າ​ເປີ​ສູ່",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20657",
                "vill_name": "ລາວ​ແມ້ວ",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20658",
                "vill_name": "ແສວ​ລີ​ສູ່",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20659",
                "vill_name": "ພີມ​ຈາງ​ກາງ",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20660",
                "vill_name": "ສີນຈ້າຍ",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20661",
                "vill_name": "ສີນຈ້າຍ",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20662",
                "vill_name": "ສີນຈ້າຍ",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20663",
                "vill_name": "ມາລີເທົ່າ+ພໍ້ຈ້າຍ",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20664",
                "vill_name": "ຊີ​ເປີ​ຈ້າຍ",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20665",
                "vill_name": "ເປີຍີນຊ່າງ+ເຫຼົ່າພູຈ້າຍ",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20666",
                "vill_name": "ເປີ​ຮ​ວາ​ສູູ່່ເກົ່າ",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20667",
                "vill_name": "ທູ​ຈິງ​ຟາງ",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20668",
                "vill_name": "ລາວ​ຟູ​ຈ້າຍ",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20669",
                "vill_name": "ຊີີ​ທົ່ງ​ປ່າ​ໃໝ່",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20670",
                "vill_name": "ຊີີ​ທົ່ງ​ປ່າເກົ່າ",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20671",
                "vill_name": "ແມ້ງກວ່າ​ຜົ້ງ",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20672",
                "vill_name": "ຊີ​ກ່າວ​ໂຮ",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20673",
                "vill_name": "ສະຫ່ວາງ+ຊີຈ່າຈ້າຍ",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20674",
                "vill_name": "ຕ່າ​ລີ​ສູ່",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20675",
                "vill_name": "ກາງ",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20676",
                "vill_name": "ສົມໄຊ+ເຄີໃຕ້",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20677",
                "vill_name": "ສີ​ວິ​ໄລ",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20678",
                "vill_name": "ນາເດືອນສານ",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20679",
                "vill_name": "ມີ​ໄຊ",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20680",
                "vill_name": "ໂຈກາເກົ່າ",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20681",
                "vill_name": "ໂຈກາໃໝ່",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20682",
                "vill_name": "ຍອດນ້ຳອູ",
                "vill_name_en": "",
                "dr_id": "206"
              },
              {
                "vill_id": "20683",
                "vill_name": "ຈອມແຍງ",
                "vill_name_en": "Chomyaeng",
                "dr_id": "206"
              },
              {
                "vill_id": "20684",
                "vill_name": "ບ້ານອູເໜືອ",
                "vill_name_en": "Ouneun",
                "dr_id": "206"
              }
            ]
          },
          {
            "dr_id": "207",
            "dr_name": "ບູນໃຕ້",
            "dr_name_en": "Boontai",
            "pr_id": "2",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "20701",
                "vill_name": "ໂພ​ທອງ",
                "vill_name_en": "",
                "dr_id": "207"
              },
              {
                "vill_id": "20702",
                "vill_name": "ບຸນ​ໃຕ້",
                "vill_name_en": "",
                "dr_id": "207"
              },
              {
                "vill_id": "20703",
                "vill_name": "ນາ​ຫວາຍ",
                "vill_name_en": "",
                "dr_id": "207"
              },
              {
                "vill_id": "20704",
                "vill_name": "ບຸນ​ຍອງ",
                "vill_name_en": "",
                "dr_id": "207"
              },
              {
                "vill_id": "20705",
                "vill_name": "ຈາ​ຫໍ",
                "vill_name_en": "",
                "dr_id": "207"
              },
              {
                "vill_id": "20706",
                "vill_name": "ເພຍສູ+ນ້ຳຂຸ້ນແກ້ວ",
                "vill_name_en": "",
                "dr_id": "207"
              },
              {
                "vill_id": "20707",
                "vill_name": "ນາ​ໄຊ",
                "vill_name_en": "",
                "dr_id": "207"
              },
              {
                "vill_id": "20708",
                "vill_name": "ຊິງ​ໄຊ",
                "vill_name_en": "",
                "dr_id": "207"
              },
              {
                "vill_id": "20709",
                "vill_name": "ລອງ​ນາຍ​ໃໜ່",
                "vill_name_en": "",
                "dr_id": "207"
              },
              {
                "vill_id": "20710",
                "vill_name": "ລອງ​ນາຍ​ເກົ່າ",
                "vill_name_en": "",
                "dr_id": "207"
              },
              {
                "vill_id": "20711",
                "vill_name": "ບໍ່​ຫາດ+ບ ຈາວາງ",
                "vill_name_en": "",
                "dr_id": "207"
              },
              {
                "vill_id": "20712",
                "vill_name": "ຫາດເສັດ",
                "vill_name_en": "",
                "dr_id": "207"
              },
              {
                "vill_id": "20713",
                "vill_name": "ປາງ​ໄຄ້",
                "vill_name_en": "",
                "dr_id": "207"
              },
              {
                "vill_id": "20714",
                "vill_name": "ນ້ຳ​ບານ",
                "vill_name_en": "",
                "dr_id": "207"
              },
              {
                "vill_id": "20715",
                "vill_name": "ນ້ຳຫີນ",
                "vill_name_en": "",
                "dr_id": "207"
              },
              {
                "vill_id": "20716",
                "vill_name": "ບໍ່ໍ່​ນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "207"
              },
              {
                "vill_id": "20717",
                "vill_name": "ຈາ​ຫຼວງ​ໃໜ່",
                "vill_name_en": "",
                "dr_id": "207"
              },
              {
                "vill_id": "20718",
                "vill_name": "ມູ​ຕືນ",
                "vill_name_en": "",
                "dr_id": "207"
              },
              {
                "vill_id": "20719",
                "vill_name": "ຈາ​ຫຼວງ​ລີ",
                "vill_name_en": "",
                "dr_id": "207"
              },
              {
                "vill_id": "20720",
                "vill_name": "ກົກຄໍ້ນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "207"
              },
              {
                "vill_id": "20721",
                "vill_name": "ນາ​ແຕ່ນ",
                "vill_name_en": "",
                "dr_id": "207"
              },
              {
                "vill_id": "20722",
                "vill_name": "ສະ​ປາ",
                "vill_name_en": "",
                "dr_id": "207"
              },
              {
                "vill_id": "20723",
                "vill_name": "ທ້າວດວງ",
                "vill_name_en": "",
                "dr_id": "207"
              },
              {
                "vill_id": "20724",
                "vill_name": "ຫ້ວຍ​ຄອງ",
                "vill_name_en": "",
                "dr_id": "207"
              },
              {
                "vill_id": "20726",
                "vill_name": "ນ້ຳ​ລານ​ນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "207"
              },
              {
                "vill_id": "20727",
                "vill_name": "ສະ​ໂນນ້ຳ​ລານ",
                "vill_name_en": "",
                "dr_id": "207"
              },
              {
                "vill_id": "20728",
                "vill_name": "ຈາ​ຄຳ​ຊາວ",
                "vill_name_en": "",
                "dr_id": "207"
              },
              {
                "vill_id": "20729",
                "vill_name": "ຈາ​ຄຳແດງ",
                "vill_name_en": "",
                "dr_id": "207"
              },
              {
                "vill_id": "20730",
                "vill_name": "ແສນອີນຫລວງ",
                "vill_name_en": "",
                "dr_id": "207"
              },
              {
                "vill_id": "20731",
                "vill_name": "ຈາ​ຄຳ​ລື+ ບ ໄອໂຈ",
                "vill_name_en": "",
                "dr_id": "207"
              },
              {
                "vill_id": "20732",
                "vill_name": "ໄອໂຈ",
                "vill_name_en": "",
                "dr_id": "207"
              },
              {
                "vill_id": "20733",
                "vill_name": "ກາວ​ສານ",
                "vill_name_en": "",
                "dr_id": "207"
              },
              {
                "vill_id": "20734",
                "vill_name": "ເຕິ​ກາ",
                "vill_name_en": "",
                "dr_id": "207"
              },
              {
                "vill_id": "20735",
                "vill_name": "ສຳ​ພັນ​ໄຊ",
                "vill_name_en": "",
                "dr_id": "207"
              },
              {
                "vill_id": "20736",
                "vill_name": "ແສນ​ອີນໃໜ່",
                "vill_name_en": "",
                "dr_id": "207"
              },
              {
                "vill_id": "20737",
                "vill_name": "ອີ​​ເປີຈິງ",
                "vill_name_en": "",
                "dr_id": "207"
              },
              {
                "vill_id": "20738",
                "vill_name": "ເຈິ​ຢາ",
                "vill_name_en": "",
                "dr_id": "207"
              },
              {
                "vill_id": "20739",
                "vill_name": "ປູ​ລີ​ໃໜ່",
                "vill_name_en": "",
                "dr_id": "207"
              },
              {
                "vill_id": "20740",
                "vill_name": "ນ້ຳກ້ວາງ",
                "vill_name_en": "",
                "dr_id": "207"
              },
              {
                "vill_id": "20741",
                "vill_name": "ສະ​ໂນໄໜ່",
                "vill_name_en": "",
                "dr_id": "207"
              },
              {
                "vill_id": "20742",
                "vill_name": "ສະ​ໂນ​ເກົ່າ",
                "vill_name_en": "",
                "dr_id": "207"
              },
              {
                "vill_id": "20743",
                "vill_name": "ປູູ​ລີ​ເກົ່າ",
                "vill_name_en": "",
                "dr_id": "207"
              },
              {
                "vill_id": "20744",
                "vill_name": "ຜົ້ງກູ້ນ້ຳ​ກວ້າງ",
                "vill_name_en": "",
                "dr_id": "207"
              },
              {
                "vill_id": "20745",
                "vill_name": "ສະ​ໜຳ​ ຢາງ​ປາ",
                "vill_name_en": "",
                "dr_id": "207"
              },
              {
                "vill_id": "20746",
                "vill_name": "ມູ​ຈີ​ນ້ຳ​ປອກ",
                "vill_name_en": "",
                "dr_id": "207"
              },
              {
                "vill_id": "20747",
                "vill_name": "ນ້ຳ​ປອກ+ ບ ອາໂຈ",
                "vill_name_en": "",
                "dr_id": "207"
              },
              {
                "vill_id": "20748",
                "vill_name": "ຈາ​ແມ້",
                "vill_name_en": "",
                "dr_id": "207"
              },
              {
                "vill_id": "20749",
                "vill_name": "ມູ​ຈີ​ຫ້ວຍ​ສ້ານ",
                "vill_name_en": "",
                "dr_id": "207"
              },
              {
                "vill_id": "20750",
                "vill_name": "ອາ​ເນັງ",
                "vill_name_en": "",
                "dr_id": "207"
              },
              {
                "vill_id": "20751",
                "vill_name": "ນາ​ມາກ",
                "vill_name_en": "",
                "dr_id": "207"
              },
              {
                "vill_id": "20752",
                "vill_name": "ອາ​ຢາ",
                "vill_name_en": "",
                "dr_id": "207"
              },
              {
                "vill_id": "20753",
                "vill_name": "ຫ້ວຍ​ຫວາຍ",
                "vill_name_en": "",
                "dr_id": "207"
              },
              {
                "vill_id": "20754",
                "vill_name": "ເພຍເຫຼັກ",
                "vill_name_en": "",
                "dr_id": "207"
              },
              {
                "vill_id": "20755",
                "vill_name": "ຈຸລາວ​ແສນ​ເກົ່າ",
                "vill_name_en": "",
                "dr_id": "207"
              },
              {
                "vill_id": "20756",
                "vill_name": "ຈຸລາວແສນ​ໃໜ່",
                "vill_name_en": "",
                "dr_id": "207"
              },
              {
                "vill_id": "20757",
                "vill_name": "ໂນນ​ບູນ​ກາງ",
                "vill_name_en": "",
                "dr_id": "207"
              },
              {
                "vill_id": "20758",
                "vill_name": "ລູ້ກູ້ໃໝ່",
                "vill_name_en": "",
                "dr_id": "207"
              },
              {
                "vill_id": "20759",
                "vill_name": "ນ້ຳ​ຂູ້ນ​ໄໜ່",
                "vill_name_en": "",
                "dr_id": "207"
              },
              {
                "vill_id": "20760",
                "vill_name": "ຫາດງາມ",
                "vill_name_en": "",
                "dr_id": "207"
              },
              {
                "vill_id": "20761",
                "vill_name": "ພີເຈີ",
                "vill_name_en": "",
                "dr_id": "207"
              },
              {
                "vill_id": "20762",
                "vill_name": "ລາວກູ້​",
                "vill_name_en": "",
                "dr_id": "207"
              },
              {
                "vill_id": "20763",
                "vill_name": "ລົງທາງ",
                "vill_name_en": "",
                "dr_id": "207"
              },
              {
                "vill_id": "20764",
                "vill_name": "ພູມອນ",
                "vill_name_en": "",
                "dr_id": "207"
              },
              {
                "vill_id": "20765",
                "vill_name": "ພູຄໍ້ ",
                "vill_name_en": "",
                "dr_id": "207"
              },
              {
                "vill_id": "20766",
                "vill_name": "ເຊັງກາງເກົ່າ",
                "vill_name_en": "",
                "dr_id": "207"
              },
              {
                "vill_id": "20767",
                "vill_name": "ພິເຈີ",
                "vill_name_en": "",
                "dr_id": "207"
              },
              {
                "vill_id": "20768",
                "vill_name": "ນານ້ຳ",
                "vill_name_en": "",
                "dr_id": "207"
              }
            ]
          }
        ]
      },
      {
        "pr_id": "3",
        "pr_name": "ຫລວງນ້ຳທາ",
        "pr_name_en": "Louang Namtha",
        "districts": [
          {"dr_name": "ເລືອກເມືອງປະຈຸບັນ", "villages": []},
          {
            "dr_id": "301",
            "dr_name": "ຫລວງນ້ຳທາ",
            "dr_name_en": "Luangnamtha",
            "pr_id": "3",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "30101",
                "vill_name": "ນໍ້າມັດໃໝ່",
                "vill_name_en": "",
                "dr_id": "301"
              },
              {
                "vill_id": "30102",
                "vill_name": "ນໍ້າມັດເກົ່າ",
                "vill_name_en": "",
                "dr_id": "301"
              },
              {
                "vill_id": "30103",
                "vill_name": "ນໍ້າຍ່າງ",
                "vill_name_en": "",
                "dr_id": "301"
              },
              {
                "vill_id": "30104",
                "vill_name": "ນໍ້າສາ",
                "vill_name_en": "",
                "dr_id": "301"
              },
              {
                "vill_id": "30105",
                "vill_name": "ນໍ້າຈ້າງ",
                "vill_name_en": "",
                "dr_id": "301"
              },
              {
                "vill_id": "30106",
                "vill_name": "ນາຫ້ອມ",
                "vill_name_en": "",
                "dr_id": "301"
              },
              {
                "vill_id": "30107",
                "vill_name": "ໄຊສົມບູນ",
                "vill_name_en": "",
                "dr_id": "301"
              },
              {
                "vill_id": "30108",
                "vill_name": "ໂພນໄຊ",
                "vill_name_en": "",
                "dr_id": "301"
              },
              {
                "vill_id": "30109",
                "vill_name": "ອຸດົມສິນ",
                "vill_name_en": "",
                "dr_id": "301"
              },
              {
                "vill_id": "30110",
                "vill_name": "ໜອງບົວວຽງ",
                "vill_name_en": "",
                "dr_id": "301"
              },
              {
                "vill_id": "30111",
                "vill_name": "ຫົວຂົວ",
                "vill_name_en": "",
                "dr_id": "301"
              },
              {
                "vill_id": "30112",
                "vill_name": "ທົ່ງອ້ອມ",
                "vill_name_en": "",
                "dr_id": "301"
              },
              {
                "vill_id": "30113",
                "vill_name": "ວຽງເໝຶອ",
                "vill_name_en": "",
                "dr_id": "301"
              },
              {
                "vill_id": "30114",
                "vill_name": "ວຽງໃຕ້",
                "vill_name_en": "",
                "dr_id": "301"
              },
              {
                "vill_id": "30115",
                "vill_name": "ຫຼວງ",
                "vill_name_en": "",
                "dr_id": "301"
              },
              {
                "vill_id": "30116",
                "vill_name": "ຂອນ",
                "vill_name_en": "",
                "dr_id": "301"
              },
              {
                "vill_id": "30117",
                "vill_name": "ດອນຄູນ",
                "vill_name_en": "",
                "dr_id": "301"
              },
              {
                "vill_id": "30118",
                "vill_name": "ນໍ້າແງ້ນ",
                "vill_name_en": "",
                "dr_id": "301"
              },
              {
                "vill_id": "30119",
                "vill_name": "ພຽງງາມ",
                "vill_name_en": "",
                "dr_id": "301"
              },
              {
                "vill_id": "30120",
                "vill_name": "ດອນມູນ",
                "vill_name_en": "",
                "dr_id": "301"
              },
              {
                "vill_id": "30121",
                "vill_name": "ທ່າແສ",
                "vill_name_en": "",
                "dr_id": "301"
              },
              {
                "vill_id": "30122",
                "vill_name": "ຈະເລີນສຸກ",
                "vill_name_en": "",
                "dr_id": "301"
              },
              {
                "vill_id": "30123",
                "vill_name": "ນາລັນເໜຶອ",
                "vill_name_en": "",
                "dr_id": "301"
              },
              {
                "vill_id": "30124",
                "vill_name": "ນໍ້າລື",
                "vill_name_en": "",
                "dr_id": "301"
              },
              {
                "vill_id": "30125",
                "vill_name": "ວຽງເງິນ",
                "vill_name_en": "",
                "dr_id": "301"
              },
              {
                "vill_id": "30126",
                "vill_name": "ທົ່ງໃຈເໜຶອ",
                "vill_name_en": "",
                "dr_id": "301"
              },
              {
                "vill_id": "30127",
                "vill_name": "ທົ່ງໃຈໃຕ້",
                "vill_name_en": "",
                "dr_id": "301"
              },
              {
                "vill_id": "30128",
                "vill_name": "ປ່າປົວະ",
                "vill_name_en": "",
                "dr_id": "301"
              },
              {
                "vill_id": "30129",
                "vill_name": "ດອນສຳພັນ",
                "vill_name_en": "",
                "dr_id": "301"
              },
              {
                "vill_id": "30130",
                "vill_name": "ປ່າສັກ",
                "vill_name_en": "",
                "dr_id": "301"
              },
              {
                "vill_id": "30131",
                "vill_name": "ປຸງ",
                "vill_name_en": "",
                "dr_id": "301"
              },
              {
                "vill_id": "30132",
                "vill_name": "ນານ້ອຍ",
                "vill_name_en": "",
                "dr_id": "301"
              },
              {
                "vill_id": "30133",
                "vill_name": "ນໍ້າທຸງ",
                "vill_name_en": "",
                "dr_id": "301"
              },
              {
                "vill_id": "30134",
                "vill_name": "ບວມພຽງ",
                "vill_name_en": "",
                "dr_id": "301"
              },
              {
                "vill_id": "30135",
                "vill_name": "ຫ້ວຍຫົກ",
                "vill_name_en": "",
                "dr_id": "301"
              },
              {
                "vill_id": "30136",
                "vill_name": "ໃໝ່",
                "vill_name_en": "",
                "dr_id": "301"
              },
              {
                "vill_id": "30137",
                "vill_name": "ນໍ້າຂອນ",
                "vill_name_en": "",
                "dr_id": "301"
              },
              {
                "vill_id": "30138",
                "vill_name": "ນໍ້າຕະລານ",
                "vill_name_en": "",
                "dr_id": "301"
              },
              {
                "vill_id": "30139",
                "vill_name": "ນໍ້າປີກ",
                "vill_name_en": "",
                "dr_id": "301"
              },
              {
                "vill_id": "30140",
                "vill_name": "ສົບສິນ",
                "vill_name_en": "",
                "dr_id": "301"
              },
              {
                "vill_id": "30141",
                "vill_name": "ສິນອຸດົມ",
                "vill_name_en": "",
                "dr_id": "301"
              },
              {
                "vill_id": "30142",
                "vill_name": "ສາມຍອດ",
                "vill_name_en": "",
                "dr_id": "301"
              },
              {
                "vill_id": "30143",
                "vill_name": "ຕາຫວານ",
                "vill_name_en": "",
                "dr_id": "301"
              },
              {
                "vill_id": "30144",
                "vill_name": "ນໍ້າແດງ",
                "vill_name_en": "",
                "dr_id": "301"
              },
              {
                "vill_id": "30145",
                "vill_name": "ຫ້ວຍດຳ",
                "vill_name_en": "",
                "dr_id": "301"
              },
              {
                "vill_id": "30146",
                "vill_name": "ນ້ຳແກນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "301"
              },
              {
                "vill_id": "30147",
                "vill_name": "ນາເຕີຍ",
                "vill_name_en": "",
                "dr_id": "301"
              },
              {
                "vill_id": "30148",
                "vill_name": "ຕີນຕົກ",
                "vill_name_en": "",
                "dr_id": "301"
              },
              {
                "vill_id": "30149",
                "vill_name": "ບໍ່ປຽດ",
                "vill_name_en": "",
                "dr_id": "301"
              },
              {
                "vill_id": "30150",
                "vill_name": "ບໍ່ເຕ່ນ",
                "vill_name_en": "",
                "dr_id": "301"
              },
              {
                "vill_id": "30151",
                "vill_name": "ກຸຍສູງ",
                "vill_name_en": "",
                "dr_id": "301"
              },
              {
                "vill_id": "30153",
                "vill_name": "ສວນຢາ",
                "vill_name_en": "",
                "dr_id": "301"
              },
              {
                "vill_id": "30154",
                "vill_name": "ຫ້ວຍຫ້ອມ",
                "vill_name_en": "",
                "dr_id": "301"
              },
              {
                "vill_id": "30155",
                "vill_name": "ສີດາ",
                "vill_name_en": "",
                "dr_id": "301"
              },
              {
                "vill_id": "30156",
                "vill_name": "ທົ່ງດີ",
                "vill_name_en": "",
                "dr_id": "301"
              },
              {
                "vill_id": "30157",
                "vill_name": "ນ້ຳດີ",
                "vill_name_en": "",
                "dr_id": "301"
              },
              {
                "vill_id": "30158",
                "vill_name": "ຫາດຍາວ",
                "vill_name_en": "",
                "dr_id": "301"
              },
              {
                "vill_id": "30159",
                "vill_name": "ໂຮງເລື່ອຍ",
                "vill_name_en": "",
                "dr_id": "301"
              },
              {
                "vill_id": "30160",
                "vill_name": "ພິນຫໍ້",
                "vill_name_en": "",
                "dr_id": "301"
              },
              {
                "vill_id": "30161",
                "vill_name": "ນ້ຳເຫລືອງໃໝ່",
                "vill_name_en": "",
                "dr_id": "301"
              },
              {
                "vill_id": "30162",
                "vill_name": "ຫລັກຄຳໃໝ່",
                "vill_name_en": "",
                "dr_id": "301"
              },
              {
                "vill_id": "30163",
                "vill_name": "ຫລັກຄຳເກົ່າ",
                "vill_name_en": "",
                "dr_id": "301"
              },
              {
                "vill_id": "30164",
                "vill_name": "ວຽງຄຳ",
                "vill_name_en": "",
                "dr_id": "301"
              },
              {
                "vill_id": "30165",
                "vill_name": "ນ້ຳກອຍ",
                "vill_name_en": "",
                "dr_id": "301"
              },
              {
                "vill_id": "30166",
                "vill_name": "ນ້ຳກົງ",
                "vill_name_en": "",
                "dr_id": "301"
              },
              {
                "vill_id": "30167",
                "vill_name": "ສົບຕຸດ",
                "vill_name_en": "",
                "dr_id": "301"
              },
              {
                "vill_id": "30168",
                "vill_name": "ວຽງທອງ",
                "vill_name_en": "",
                "dr_id": "301"
              },
              {
                "vill_id": "30169",
                "vill_name": "ຫົວນາ",
                "vill_name_en": "",
                "dr_id": "301"
              },
              {
                "vill_id": "30170",
                "vill_name": "ດອນໄຊ",
                "vill_name_en": "",
                "dr_id": "301"
              },
              {
                "vill_id": "30171",
                "vill_name": "ຕີນສານ",
                "vill_name_en": "",
                "dr_id": "301"
              },
              {
                "vill_id": "30172",
                "vill_name": "ຂົວສູງ",
                "vill_name_en": "",
                "dr_id": "301"
              },
              {
                "vill_id": "30173",
                "vill_name": "ນ້ຳຂຸ່ນ",
                "vill_name_en": "",
                "dr_id": "301"
              },
              {
                "vill_id": "30174",
                "vill_name": "ພູຫວານ",
                "vill_name_en": "",
                "dr_id": "301"
              },
              {
                "vill_id": "30175",
                "vill_name": "ນ້ຳທາ",
                "vill_name_en": "",
                "dr_id": "301"
              },
              {
                "vill_id": "30176",
                "vill_name": "ບ້ານແປ້",
                "vill_name_en": "Pea",
                "dr_id": "301"
              },
              {
                "vill_id": "30177",
                "vill_name": "ນ້ຳຫ້າ",
                "vill_name_en": "Namha",
                "dr_id": "301"
              }
            ]
          },
          {
            "dr_id": "302",
            "dr_name": "ສິງ",
            "dr_name_en": "Sing",
            "pr_id": "3",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "30201",
                "vill_name": "ຊຽງໃຈ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30202",
                "vill_name": "ຊຽງອິນ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30203",
                "vill_name": "ຊຽງແລ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30204",
                "vill_name": "ຊຽງຢືນ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30205",
                "vill_name": "ຊຽງມູນ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30206",
                "vill_name": "ຫົວຂົວ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30207",
                "vill_name": "ນາໃໝ່",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30208",
                "vill_name": "ຈອມ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30209",
                "vill_name": "ຈອມ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30210",
                "vill_name": "ຕາປ້າວ - ດອນປອຍ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30211",
                "vill_name": "ດອນໃໝ່",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30212",
                "vill_name": "ຫົວນາໃໝ່",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30213",
                "vill_name": "ຫ້ວຍແຂມ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30214",
                "vill_name": "ຫ້ວຍລອງໃໝ່",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30215",
                "vill_name": "ຫ້ວຍລະ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30216",
                "vill_name": "ສົບອິເກົ່າ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30217",
                "vill_name": "ຫ້ວຍລອງເກົ່າ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30218",
                "vill_name": "ຊໍ້",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30219",
                "vill_name": "ໜອງຄຳ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30220",
                "vill_name": "ສີລີມູນ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30221",
                "vill_name": "ນາຄຳ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30222",
                "vill_name": "ດອນໃຈ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30223",
                "vill_name": "ພະບາດນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30224",
                "vill_name": "ພະບາດໃຫ່ຍ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30225",
                "vill_name": "ພະຍາຫຼວງ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30226",
                "vill_name": "ອຸດົມສີນ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30227",
                "vill_name": "ນ້ຳແດດໃໝ່",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30228",
                "vill_name": "ຈົ່ງກາ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30229",
                "vill_name": "ນ້ຳມາຍ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30230",
                "vill_name": "ໂຮມໄຊ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30231",
                "vill_name": "ສາຍເຫັລກ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30232",
                "vill_name": "ຫຼັກຄຳ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30233",
                "vill_name": "ປຸ່ງກອກ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30234",
                "vill_name": "ປ່າຄາ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30235",
                "vill_name": "ນ້ຳແກ້ວຫຼວງ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30236",
                "vill_name": "ໜອງບົວ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30237",
                "vill_name": "ນ້ຳແກ້ວນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30238",
                "vill_name": "ປ່າຕ່ອຍ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30239",
                "vill_name": "ກຸ່ມ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30240",
                "vill_name": "ຕີນທາດ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30241",
                "vill_name": "ສົບອິໃໝ່",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30242",
                "vill_name": "ຢາງຫຼວງ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30243",
                "vill_name": "ເລົ່າເຂົ້າ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30244",
                "vill_name": "ນ້ຳເຫັລກ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30245",
                "vill_name": "ສີລິເຮືອງ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30246",
                "vill_name": "ກາງໂພນສີ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30247",
                "vill_name": "ຢາງພຽງ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30248",
                "vill_name": "ທົ່ງໃໝ່",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30249",
                "vill_name": "ນ້ຳດ້າຍ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30250",
                "vill_name": "ຂວາງ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30251",
                "vill_name": "ຫ້ວຍນາກາງ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30252",
                "vill_name": "ເອີຫຼ້າໃໝ່",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30253",
                "vill_name": "ຕາໝີ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30254",
                "vill_name": "ຫ້ວຍກົດ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30255",
                "vill_name": "ຜີເຍີ ( ປ່າຫວາຍ )",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30256",
                "vill_name": "ນ້ຳຫູ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30258",
                "vill_name": "ມໍນະ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30259",
                "vill_name": "ລໍມື",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30260",
                "vill_name": "ມອມ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30262",
                "vill_name": "ແສນແອນ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30263",
                "vill_name": "ນານ້ອຍ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30264",
                "vill_name": "ເລົາເມີຍ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30265",
                "vill_name": "ຈາພູກືນ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30266",
                "vill_name": "ຈາວັງໃໝ່",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30268",
                "vill_name": "ພະບາດມອມ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30269",
                "vill_name": "ບວກຫຍ້າໄຊໃໝ່",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30270",
                "vill_name": "ບວກຍະໄຊເກົ່າ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30271",
                "vill_name": "ປ່າພຸກ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30272",
                "vill_name": "ຫ້ວຍຕາດ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30273",
                "vill_name": "ບວກຄຸ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30274",
                "vill_name": "ເສື້ອແດງ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30275",
                "vill_name": "ຊາຍ - ຫລັງຜາ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30276",
                "vill_name": "ຊຽງແຂງ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30277",
                "vill_name": "ຈາພູູທອນ - ຈາອິນຕະ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30278",
                "vill_name": "ຜາແດງ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30279",
                "vill_name": "ຈາເຂີນ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30280",
                "vill_name": "ກໍເພນ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30281",
                "vill_name": "ໂລກູ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30282",
                "vill_name": "ເອີຫລ້າເກົ່າ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30283",
                "vill_name": "ຈາອູບ - ປາງທອງ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30284",
                "vill_name": "ກົກມ່ວງ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30285",
                "vill_name": "ຫ້ວຍຫອຍ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30286",
                "vill_name": "ພູແຍະ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30287",
                "vill_name": "ລໍໍຊີ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30288",
                "vill_name": "ສີງຈະເລີນ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30290",
                "vill_name": "ປຸ່ງສຽ້ວ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30291",
                "vill_name": "ຫ້ວຍຫຼວງ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30293",
                "vill_name": "ນ້ຳແດດສົມບຸນ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30294",
                "vill_name": "ສິງພັດທະນາ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30295",
                "vill_name": "ພູດອນທັນ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30296",
                "vill_name": "ດ່ານປາງໄຫ",
                "vill_name_en": "",
                "dr_id": "302"
              },
              {
                "vill_id": "30297",
                "vill_name": "ຈຽງໃຈ",
                "vill_name_en": "Chiengchai",
                "dr_id": "302"
              },
              {
                "vill_id": "30298",
                "vill_name": "ກັງໃໝ່",
                "vill_name_en": "Kangmai",
                "dr_id": "302"
              }
            ]
          },
          {
            "dr_id": "303",
            "dr_name": "ລອງ",
            "dr_name_en": "Long",
            "pr_id": "3",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "30301",
                "vill_name": "ຫຼວງຜາຄຳ",
                "vill_name_en": "",
                "dr_id": "303"
              },
              {
                "vill_id": "30302",
                "vill_name": "ຫົວຂົວ",
                "vill_name_en": "",
                "dr_id": "303"
              },
              {
                "vill_id": "30303",
                "vill_name": "ດອນສະຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "303"
              },
              {
                "vill_id": "30304",
                "vill_name": "ປາງອານ",
                "vill_name_en": "",
                "dr_id": "303"
              },
              {
                "vill_id": "30305",
                "vill_name": "ໜອງຄຳ",
                "vill_name_en": "",
                "dr_id": "303"
              },
              {
                "vill_id": "30306",
                "vill_name": "ໂພນສະຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "303"
              },
              {
                "vill_id": "30307",
                "vill_name": "ປ່າຄາ",
                "vill_name_en": "",
                "dr_id": "303"
              },
              {
                "vill_id": "30308",
                "vill_name": "ຫ້ວຍຖຸໃໝ່+ພໍທຳ",
                "vill_name_en": "",
                "dr_id": "303"
              },
              {
                "vill_id": "30309",
                "vill_name": "ຈອມໜອກ",
                "vill_name_en": "",
                "dr_id": "303"
              },
              {
                "vill_id": "30310",
                "vill_name": "ຈ່າຄຳຕັນລອງ",
                "vill_name_en": "",
                "dr_id": "303"
              },
              {
                "vill_id": "30311",
                "vill_name": "ຫຼັງຜາໃໝ່",
                "vill_name_en": "",
                "dr_id": "303"
              },
              {
                "vill_id": "30312",
                "vill_name": "ຊຽງກົກ",
                "vill_name_en": "",
                "dr_id": "303"
              },
              {
                "vill_id": "30313",
                "vill_name": "ຕາຊຸມໃໝ່",
                "vill_name_en": "",
                "dr_id": "303"
              },
              {
                "vill_id": "30314",
                "vill_name": "ສຸກສາລາ",
                "vill_name_en": "",
                "dr_id": "303"
              },
              {
                "vill_id": "30315",
                "vill_name": "ເຕົ້າໂຮມ",
                "vill_name_en": "",
                "dr_id": "303"
              },
              {
                "vill_id": "30316",
                "vill_name": "ສົົມປານໃຕ້",
                "vill_name_en": "",
                "dr_id": "303"
              },
              {
                "vill_id": "30317",
                "vill_name": "ເມືອງຂັນ",
                "vill_name_en": "",
                "dr_id": "303"
              },
              {
                "vill_id": "30318",
                "vill_name": "ຫຼວງໂພຄຳ",
                "vill_name_en": "",
                "dr_id": "303"
              },
              {
                "vill_id": "30319",
                "vill_name": "ຈ່າວາດ",
                "vill_name_en": "",
                "dr_id": "303"
              },
              {
                "vill_id": "30320",
                "vill_name": "ສົົມປານໃໝ່ (ສົບໂຫຼຍ)",
                "vill_name_en": "",
                "dr_id": "303"
              },
              {
                "vill_id": "30321",
                "vill_name": "ແມ່ຂົງ",
                "vill_name_en": "",
                "dr_id": "303"
              },
              {
                "vill_id": "30322",
                "vill_name": "ພະຍາຫຼວງຄຳປີງ",
                "vill_name_en": "",
                "dr_id": "303"
              },
              {
                "vill_id": "30323",
                "vill_name": "ຈ່າຄຳລື",
                "vill_name_en": "",
                "dr_id": "303"
              },
              {
                "vill_id": "30324",
                "vill_name": "ຜາງົວ",
                "vill_name_en": "",
                "dr_id": "303"
              },
              {
                "vill_id": "30325",
                "vill_name": "ຜາຄຳ",
                "vill_name_en": "",
                "dr_id": "303"
              },
              {
                "vill_id": "30326",
                "vill_name": "ຜາແຕ",
                "vill_name_en": "",
                "dr_id": "303"
              },
              {
                "vill_id": "30327",
                "vill_name": "ພໍ່ໍ່ຈັນ",
                "vill_name_en": "",
                "dr_id": "303"
              },
              {
                "vill_id": "30328",
                "vill_name": "ຈ່າຄຳປີງ",
                "vill_name_en": "",
                "dr_id": "303"
              },
              {
                "vill_id": "30329",
                "vill_name": "ສີວິໄລ",
                "vill_name_en": "",
                "dr_id": "303"
              },
              {
                "vill_id": "30330",
                "vill_name": "ແດນກາງ",
                "vill_name_en": "",
                "dr_id": "303"
              },
              {
                "vill_id": "30331",
                "vill_name": "ນ້ຳຮີ",
                "vill_name_en": "",
                "dr_id": "303"
              },
              {
                "vill_id": "30332",
                "vill_name": "ນ້ຳໂບ + ອ້າຍຕອງ",
                "vill_name_en": "",
                "dr_id": "303"
              },
              {
                "vill_id": "30333",
                "vill_name": "ພີນຫໍ້",
                "vill_name_en": "",
                "dr_id": "303"
              },
              {
                "vill_id": "30334",
                "vill_name": "ສະໃໝ່ + ສະເກົ່າ",
                "vill_name_en": "",
                "dr_id": "303"
              },
              {
                "vill_id": "30335",
                "vill_name": "ແສນຂັນຄຳ",
                "vill_name_en": "",
                "dr_id": "303"
              },
              {
                "vill_id": "30336",
                "vill_name": "ຫ້ວຍຄະ + ຫ້ວຍຍສີງ",
                "vill_name_en": "",
                "dr_id": "303"
              },
              {
                "vill_id": "30337",
                "vill_name": "ຈ່າແກ້ວ",
                "vill_name_en": "",
                "dr_id": "303"
              },
              {
                "vill_id": "30338",
                "vill_name": "ຈ່າຍີ່ + ຂາຫຼັງ",
                "vill_name_en": "",
                "dr_id": "303"
              },
              {
                "vill_id": "30339",
                "vill_name": "ຕີນທາດ",
                "vill_name_en": "",
                "dr_id": "303"
              },
              {
                "vill_id": "30340",
                "vill_name": "ສີລິມູນ",
                "vill_name_en": "",
                "dr_id": "303"
              },
              {
                "vill_id": "30341",
                "vill_name": "ສົບອີໃໝ່",
                "vill_name_en": "",
                "dr_id": "303"
              },
              {
                "vill_id": "30342",
                "vill_name": "ແພວແຢະ",
                "vill_name_en": "",
                "dr_id": "303"
              },
              {
                "vill_id": "30343",
                "vill_name": "ສົົບມະ",
                "vill_name_en": "",
                "dr_id": "303"
              },
              {
                "vill_id": "30344",
                "vill_name": "ພະຍາຫຼວງ ( ເມືອງນັງ )",
                "vill_name_en": "",
                "dr_id": "303"
              },
              {
                "vill_id": "30345",
                "vill_name": "ນ້ຳມະ",
                "vill_name_en": "",
                "dr_id": "303"
              },
              {
                "vill_id": "30346",
                "vill_name": "ຈ່າຈະນາມ",
                "vill_name_en": "",
                "dr_id": "303"
              },
              {
                "vill_id": "30347",
                "vill_name": "ຈ່າປີ + ແສນນຈອມພູ",
                "vill_name_en": "",
                "dr_id": "303"
              },
              {
                "vill_id": "30348",
                "vill_name": "ພະຍາຫຼວງ ( ບວກບໍ )",
                "vill_name_en": "",
                "dr_id": "303"
              },
              {
                "vill_id": "30349",
                "vill_name": "ທົ່ງໜໍຕຳ",
                "vill_name_en": "",
                "dr_id": "303"
              },
              {
                "vill_id": "30350",
                "vill_name": "ຫ້ວຍຖຸຸເກົ່າ",
                "vill_name_en": "",
                "dr_id": "303"
              },
              {
                "vill_id": "30351",
                "vill_name": "ຜາແຕ + ບໍ່ໃໝ່",
                "vill_name_en": "",
                "dr_id": "303"
              },
              {
                "vill_id": "30352",
                "vill_name": "ຈ່າໃໝ່",
                "vill_name_en": "",
                "dr_id": "303"
              },
              {
                "vill_id": "30353",
                "vill_name": "ປ່າຊອດ",
                "vill_name_en": "",
                "dr_id": "303"
              },
              {
                "vill_id": "30354",
                "vill_name": "ສາມເມືອງໃໝ່",
                "vill_name_en": "",
                "dr_id": "303"
              },
              {
                "vill_id": "30355",
                "vill_name": "ເຈຕະ",
                "vill_name_en": "",
                "dr_id": "303"
              },
              {
                "vill_id": "30357",
                "vill_name": "ຈ່າເຂີນ",
                "vill_name_en": "",
                "dr_id": "303"
              },
              {
                "vill_id": "30358",
                "vill_name": "ໂພນສຳພັນ",
                "vill_name_en": "",
                "dr_id": "303"
              },
              {
                "vill_id": "30359",
                "vill_name": "ຫຼວງໂພຄຳ",
                "vill_name_en": "",
                "dr_id": "303"
              },
              {
                "vill_id": "30360",
                "vill_name": "ດອນແຍງ",
                "vill_name_en": "",
                "dr_id": "303"
              },
              {
                "vill_id": "30361",
                "vill_name": "ຈະເລີນໄຊ",
                "vill_name_en": "",
                "dr_id": "303"
              },
              {
                "vill_id": "30362",
                "vill_name": "ຫ້ວຍມຸ້ງຫຼ້າ",
                "vill_name_en": "",
                "dr_id": "303"
              },
              {
                "vill_id": "30363",
                "vill_name": "ຕາບໄຮ່ ( ຈາກປາງຫົກ )",
                "vill_name_en": "",
                "dr_id": "303"
              },
              {
                "vill_id": "30364",
                "vill_name": "ຕີນດອຍ",
                "vill_name_en": "",
                "dr_id": "303"
              },
              {
                "vill_id": "30365",
                "vill_name": "ຫ້ວຍກຸ້ມ ( ຫ້ວຍອໍ້ )",
                "vill_name_en": "",
                "dr_id": "303"
              },
              {
                "vill_id": "30366",
                "vill_name": "ປ່າກ້ວຍໄໝ່",
                "vill_name_en": "",
                "dr_id": "303"
              },
              {
                "vill_id": "30367",
                "vill_name": "ຄົກຫີນ",
                "vill_name_en": "",
                "dr_id": "303"
              },
              {
                "vill_id": "30368",
                "vill_name": "ຫ້ວຍໝໍ້",
                "vill_name_en": "",
                "dr_id": "303"
              },
              {
                "vill_id": "30369",
                "vill_name": "ຈ່າຄຳຕັນກາງ",
                "vill_name_en": "",
                "dr_id": "303"
              },
              {
                "vill_id": "30370",
                "vill_name": "ນາອອນ",
                "vill_name_en": "",
                "dr_id": "303"
              },
              {
                "vill_id": "30371",
                "vill_name": "ສົົມປານເກົ່າ",
                "vill_name_en": "",
                "dr_id": "303"
              },
              {
                "vill_id": "30372",
                "vill_name": "ໄອແສງ",
                "vill_name_en": "",
                "dr_id": "303"
              }
            ]
          },
          {
            "dr_id": "304",
            "dr_name": "ວຽງພູຄາ",
            "dr_name_en": "Viengphoukha",
            "pr_id": "3",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "30401",
                "vill_name": "ທ້ຽວ",
                "vill_name_en": "",
                "dr_id": "304"
              },
              {
                "vill_id": "30402",
                "vill_name": "ໃໝ່",
                "vill_name_en": "",
                "dr_id": "304"
              },
              {
                "vill_id": "30403",
                "vill_name": "ຜາແດງ",
                "vill_name_en": "",
                "dr_id": "304"
              },
              {
                "vill_id": "30404",
                "vill_name": "ນ້ຳມ້າງ",
                "vill_name_en": "",
                "dr_id": "304"
              },
              {
                "vill_id": "30405",
                "vill_name": "ກຳປອນ",
                "vill_name_en": "",
                "dr_id": "304"
              },
              {
                "vill_id": "30406",
                "vill_name": "ວຽງໃໝ່",
                "vill_name_en": "",
                "dr_id": "304"
              },
              {
                "vill_id": "30407",
                "vill_name": "ນ້ຳປາມັນ",
                "vill_name_en": "",
                "dr_id": "304"
              },
              {
                "vill_id": "30408",
                "vill_name": "ນ້ຳກາບເໝືອ",
                "vill_name_en": "",
                "dr_id": "304"
              },
              {
                "vill_id": "30409",
                "vill_name": "ນ້ຳນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "304"
              },
              {
                "vill_id": "30410",
                "vill_name": "ໜອງຄຳ",
                "vill_name_en": "",
                "dr_id": "304"
              },
              {
                "vill_id": "30411",
                "vill_name": "ນ້ຳໂອ",
                "vill_name_en": "",
                "dr_id": "304"
              },
              {
                "vill_id": "30412",
                "vill_name": "ນ້ຳແອງ",
                "vill_name_en": "",
                "dr_id": "304"
              },
              {
                "vill_id": "30413",
                "vill_name": "ພູລັນເໜືອ",
                "vill_name_en": "",
                "dr_id": "304"
              },
              {
                "vill_id": "30414",
                "vill_name": "ນ້ຳສິງ",
                "vill_name_en": "",
                "dr_id": "304"
              },
              {
                "vill_id": "30415",
                "vill_name": "ນ້ຳລຸງ",
                "vill_name_en": "",
                "dr_id": "304"
              },
              {
                "vill_id": "30416",
                "vill_name": "ຕະລອງ",
                "vill_name_en": "",
                "dr_id": "304"
              },
              {
                "vill_id": "30417",
                "vill_name": "ປຣາງ",
                "vill_name_en": "",
                "dr_id": "304"
              },
              {
                "vill_id": "30418",
                "vill_name": "ນ້ຳວາງ",
                "vill_name_en": "",
                "dr_id": "304"
              },
              {
                "vill_id": "30419",
                "vill_name": "ພູແຍ",
                "vill_name_en": "",
                "dr_id": "304"
              },
              {
                "vill_id": "30420",
                "vill_name": "ທົ່ງລາດ",
                "vill_name_en": "",
                "dr_id": "304"
              },
              {
                "vill_id": "30421",
                "vill_name": "ນ້ຳກາບໃໝ່",
                "vill_name_en": "",
                "dr_id": "304"
              },
              {
                "vill_id": "30422",
                "vill_name": "ນ້ຳຕະແລງ",
                "vill_name_en": "",
                "dr_id": "304"
              },
              {
                "vill_id": "30423",
                "vill_name": "ຕາສຸມ",
                "vill_name_en": "",
                "dr_id": "304"
              },
              {
                "vill_id": "30424",
                "vill_name": "ທ່າຫຼວງ",
                "vill_name_en": "",
                "dr_id": "304"
              },
              {
                "vill_id": "30425",
                "vill_name": "ນ້ຳໂລະ",
                "vill_name_en": "",
                "dr_id": "304"
              },
              {
                "vill_id": "30426",
                "vill_name": "ນ້ຳຟ້າ",
                "vill_name_en": "",
                "dr_id": "304"
              },
              {
                "vill_id": "30427",
                "vill_name": "ລະມອນ",
                "vill_name_en": "",
                "dr_id": "304"
              },
              {
                "vill_id": "30428",
                "vill_name": "ນ້ຳກ້ຽງ",
                "vill_name_en": "",
                "dr_id": "304"
              },
              {
                "vill_id": "30429",
                "vill_name": "ນ້ຳເສືອ",
                "vill_name_en": "",
                "dr_id": "304"
              },
              {
                "vill_id": "30430",
                "vill_name": "ພູເລັດ",
                "vill_name_en": "",
                "dr_id": "304"
              },
              {
                "vill_id": "30431",
                "vill_name": "ໂພນທອງ",
                "vill_name_en": "",
                "dr_id": "304"
              },
              {
                "vill_id": "30432",
                "vill_name": "ໄລລອດ",
                "vill_name_en": "",
                "dr_id": "304"
              },
              {
                "vill_id": "30433",
                "vill_name": "ສາໂກນ",
                "vill_name_en": "",
                "dr_id": "304"
              },
              {
                "vill_id": "30434",
                "vill_name": "ນ້ຳເງິນ",
                "vill_name_en": "",
                "dr_id": "304"
              },
              {
                "vill_id": "30435",
                "vill_name": "ປາງຊາຍ",
                "vill_name_en": "",
                "dr_id": "304"
              },
              {
                "vill_id": "30436",
                "vill_name": "ນ້ຳແພ",
                "vill_name_en": "",
                "dr_id": "304"
              },
              {
                "vill_id": "30437",
                "vill_name": "ພູລັນໄຕ້ ( ນ້ຳແພ )",
                "vill_name_en": "",
                "dr_id": "304"
              },
              {
                "vill_id": "30438",
                "vill_name": "ທົ່ງໄຫ່",
                "vill_name_en": "",
                "dr_id": "304"
              },
              {
                "vill_id": "30439",
                "vill_name": "ກອນທຸ",
                "vill_name_en": "",
                "dr_id": "304"
              },
              {
                "vill_id": "30440",
                "vill_name": "ດົງວຽງ",
                "vill_name_en": "",
                "dr_id": "304"
              },
              {
                "vill_id": "30441",
                "vill_name": "ດອນໃໝ່",
                "vill_name_en": "",
                "dr_id": "304"
              },
              {
                "vill_id": "30442",
                "vill_name": "ທົ່ງນາແປນ",
                "vill_name_en": "",
                "dr_id": "304"
              },
              {
                "vill_id": "30443",
                "vill_name": "ນ້ຳຈະຣິ",
                "vill_name_en": "",
                "dr_id": "304"
              },
              {
                "vill_id": "30444",
                "vill_name": "ຫ້ວຍຫົກ",
                "vill_name_en": "",
                "dr_id": "304"
              },
              {
                "vill_id": "30446",
                "vill_name": "ວຽງສະຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "304"
              }
            ]
          },
          {
            "dr_id": "305",
            "dr_name": "ນາແລ",
            "dr_name_en": "Nalae",
            "pr_id": "3",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "30501",
                "vill_name": "ຫາດນາແລ້ງ",
                "vill_name_en": "",
                "dr_id": "305"
              },
              {
                "vill_id": "30502",
                "vill_name": "ຫາດຈະລະ",
                "vill_name_en": "",
                "dr_id": "305"
              },
              {
                "vill_id": "30503",
                "vill_name": "ຫາດລອຍ",
                "vill_name_en": "",
                "dr_id": "305"
              },
              {
                "vill_id": "30504",
                "vill_name": "ຫາດລົມ",
                "vill_name_en": "",
                "dr_id": "305"
              },
              {
                "vill_id": "30505",
                "vill_name": "ເລົາ",
                "vill_name_en": "",
                "dr_id": "305"
              },
              {
                "vill_id": "30506",
                "vill_name": "ພູຈະແລ",
                "vill_name_en": "",
                "dr_id": "305"
              },
              {
                "vill_id": "30507",
                "vill_name": "ແວນ",
                "vill_name_en": "",
                "dr_id": "305"
              },
              {
                "vill_id": "30508",
                "vill_name": "ນ້ຳແຫງ",
                "vill_name_en": "",
                "dr_id": "305"
              },
              {
                "vill_id": "30509",
                "vill_name": "ດອນຊາຍ",
                "vill_name_en": "",
                "dr_id": "305"
              },
              {
                "vill_id": "30510",
                "vill_name": "ວັງມີໄຊ",
                "vill_name_en": "",
                "dr_id": "305"
              },
              {
                "vill_id": "30511",
                "vill_name": "ນາແລ້ງ",
                "vill_name_en": "",
                "dr_id": "305"
              },
              {
                "vill_id": "30512",
                "vill_name": "ຄອນຈັນ",
                "vill_name_en": "",
                "dr_id": "305"
              },
              {
                "vill_id": "30513",
                "vill_name": "ຜາຮູ",
                "vill_name_en": "",
                "dr_id": "305"
              },
              {
                "vill_id": "30514",
                "vill_name": "ເຜີ້ງ",
                "vill_name_en": "",
                "dr_id": "305"
              },
              {
                "vill_id": "30515",
                "vill_name": "ຜາງບົກ",
                "vill_name_en": "",
                "dr_id": "305"
              },
              {
                "vill_id": "30516",
                "vill_name": "ກອນລາງ",
                "vill_name_en": "",
                "dr_id": "305"
              },
              {
                "vill_id": "30517",
                "vill_name": "ພູໂຫນ",
                "vill_name_en": "",
                "dr_id": "305"
              },
              {
                "vill_id": "30518",
                "vill_name": "ຜາວີ",
                "vill_name_en": "",
                "dr_id": "305"
              },
              {
                "vill_id": "30519",
                "vill_name": "ວາດ",
                "vill_name_en": "",
                "dr_id": "305"
              },
              {
                "vill_id": "30520",
                "vill_name": "ໜອງຄຳ",
                "vill_name_en": "",
                "dr_id": "305"
              },
              {
                "vill_id": "30521",
                "vill_name": "ສະແກນ",
                "vill_name_en": "",
                "dr_id": "305"
              },
              {
                "vill_id": "30523",
                "vill_name": "ກັນຫະ",
                "vill_name_en": "",
                "dr_id": "305"
              },
              {
                "vill_id": "30524",
                "vill_name": "ມົກກູດ",
                "vill_name_en": "",
                "dr_id": "305"
              },
              {
                "vill_id": "30525",
                "vill_name": "ໂຮມຈະເລີນ",
                "vill_name_en": "",
                "dr_id": "305"
              },
              {
                "vill_id": "30526",
                "vill_name": "ລ້ອງແຄນ",
                "vill_name_en": "",
                "dr_id": "305"
              },
              {
                "vill_id": "30527",
                "vill_name": "ມົກຈອດ",
                "vill_name_en": "",
                "dr_id": "305"
              },
              {
                "vill_id": "30528",
                "vill_name": "ມົກພຼາດ",
                "vill_name_en": "",
                "dr_id": "305"
              },
              {
                "vill_id": "30529",
                "vill_name": "ເປືອນ",
                "vill_name_en": "",
                "dr_id": "305"
              },
              {
                "vill_id": "30530",
                "vill_name": "ຄອນຄຳ",
                "vill_name_en": "",
                "dr_id": "305"
              },
              {
                "vill_id": "30531",
                "vill_name": "ພູລົມ",
                "vill_name_en": "",
                "dr_id": "305"
              },
              {
                "vill_id": "30532",
                "vill_name": "ຫ້ວຍລາວ",
                "vill_name_en": "",
                "dr_id": "305"
              },
              {
                "vill_id": "30533",
                "vill_name": "ພູໂທນ",
                "vill_name_en": "",
                "dr_id": "305"
              },
              {
                "vill_id": "30534",
                "vill_name": "ສາງແຫນ",
                "vill_name_en": "",
                "dr_id": "305"
              },
              {
                "vill_id": "30535",
                "vill_name": "ງວນ",
                "vill_name_en": "",
                "dr_id": "305"
              },
              {
                "vill_id": "30536",
                "vill_name": "ຕາວັນ",
                "vill_name_en": "",
                "dr_id": "305"
              },
              {
                "vill_id": "30537",
                "vill_name": "ຫາດດາວ",
                "vill_name_en": "",
                "dr_id": "305"
              },
              {
                "vill_id": "30538",
                "vill_name": "ດອນທີບ",
                "vill_name_en": "",
                "dr_id": "305"
              },
              {
                "vill_id": "30539",
                "vill_name": "ນ້ຳລີງ",
                "vill_name_en": "",
                "dr_id": "305"
              },
              {
                "vill_id": "30540",
                "vill_name": "ລະອາງ",
                "vill_name_en": "",
                "dr_id": "305"
              },
              {
                "vill_id": "30541",
                "vill_name": "ຕະລາງ",
                "vill_name_en": "",
                "dr_id": "305"
              },
              {
                "vill_id": "30542",
                "vill_name": "ຕະເຄິງ",
                "vill_name_en": "",
                "dr_id": "305"
              },
              {
                "vill_id": "30544",
                "vill_name": "ກະເຢະ",
                "vill_name_en": "",
                "dr_id": "305"
              },
              {
                "vill_id": "30545",
                "vill_name": "ສະພິມ",
                "vill_name_en": "",
                "dr_id": "305"
              },
              {
                "vill_id": "30546",
                "vill_name": "ຕະກຸ",
                "vill_name_en": "",
                "dr_id": "305"
              },
              {
                "vill_id": "30547",
                "vill_name": "ຕະລຸຍໃຫຍ່",
                "vill_name_en": "",
                "dr_id": "305"
              },
              {
                "vill_id": "30548",
                "vill_name": "ສະເຫຼືອງ",
                "vill_name_en": "",
                "dr_id": "305"
              },
              {
                "vill_id": "30550",
                "vill_name": "ສະກັບ",
                "vill_name_en": "",
                "dr_id": "305"
              },
              {
                "vill_id": "30552",
                "vill_name": "ຮຸນ",
                "vill_name_en": "",
                "dr_id": "305"
              },
              {
                "vill_id": "30553",
                "vill_name": "ນາແລ",
                "vill_name_en": "",
                "dr_id": "305"
              },
              {
                "vill_id": "30554",
                "vill_name": "ຕົງລະຫາງ",
                "vill_name_en": "",
                "dr_id": "305"
              },
              {
                "vill_id": "30555",
                "vill_name": "ຫາດແຕະ",
                "vill_name_en": "",
                "dr_id": "305"
              },
              {
                "vill_id": "30556",
                "vill_name": "ພູຮອງຫາດຮັບ",
                "vill_name_en": "",
                "dr_id": "305"
              },
              {
                "vill_id": "30557",
                "vill_name": "ພູຄາງ",
                "vill_name_en": "",
                "dr_id": "305"
              },
              {
                "vill_id": "30558",
                "vill_name": "ລ້ອງມູນແກ້ວ",
                "vill_name_en": "",
                "dr_id": "305"
              },
              {
                "vill_id": "30559",
                "vill_name": "ຫ້ວຍເຄື່ອງ",
                "vill_name_en": "",
                "dr_id": "305"
              },
              {
                "vill_id": "30560",
                "vill_name": "ພູຕີນ",
                "vill_name_en": "",
                "dr_id": "305"
              },
              {
                "vill_id": "30561",
                "vill_name": "ອ້ອມ",
                "vill_name_en": "",
                "dr_id": "305"
              },
              {
                "vill_id": "30562",
                "vill_name": "ທົ່ງຖອນ",
                "vill_name_en": "",
                "dr_id": "305"
              },
              {
                "vill_id": "30563",
                "vill_name": "ລ້ອງມູນສິງໄຊ",
                "vill_name_en": "",
                "dr_id": "305"
              },
              {
                "vill_id": "30564",
                "vill_name": "ສະໂຫຼຍ",
                "vill_name_en": "",
                "dr_id": "305"
              },
              {
                "vill_id": "30565",
                "vill_name": "ຫາດເຕາະ",
                "vill_name_en": "",
                "dr_id": "305"
              },
              {
                "vill_id": "30566",
                "vill_name": "ພູລວງມົກຈົງ",
                "vill_name_en": "",
                "dr_id": "305"
              },
              {
                "vill_id": "30567",
                "vill_name": "ສາງອາກ",
                "vill_name_en": "",
                "dr_id": "305"
              },
              {
                "vill_id": "30568",
                "vill_name": "ປຸງຫຼວງ",
                "vill_name_en": "",
                "dr_id": "305"
              },
              {
                "vill_id": "30569",
                "vill_name": "ພູວຽງ",
                "vill_name_en": "",
                "dr_id": "305"
              },
              {
                "vill_id": "30570",
                "vill_name": "ພູຕາງ",
                "vill_name_en": "",
                "dr_id": "305"
              },
              {
                "vill_id": "30571",
                "vill_name": "ຫາດຈອນ",
                "vill_name_en": "",
                "dr_id": "305"
              },
              {
                "vill_id": "30572",
                "vill_name": "ໜອງປ່າແສດ",
                "vill_name_en": "",
                "dr_id": "305"
              },
              {
                "vill_id": "30573",
                "vill_name": "ມົກຄະ",
                "vill_name_en": "Mokkha",
                "dr_id": "305"
              }
            ]
          }
        ]
      },
      {
        "pr_id": "4",
        "pr_name": "ອຸດົມໄຊ",
        "pr_name_en": "Oudomxai",
        "districts": [
          {"dr_name": "ເລືອກເມືອງປະຈຸບັນ", "villages": []},
          {
            "dr_id": "401",
            "dr_name": "ໄຊ",
            "dr_name_en": "Xay",
            "pr_id": "4",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "40101",
                "vill_name": "ປ່າສັກ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40102",
                "vill_name": "ມອນໃຕ້",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40103",
                "vill_name": "ວຽງສາ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40104",
                "vill_name": "ລ້ອງກໍເດືອ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40105",
                "vill_name": "ພູຂຽວ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40107",
                "vill_name": "ໂຮມສຸກ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40108",
                "vill_name": "ຫຼັກ4",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40109",
                "vill_name": "ຖິ່ນ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40110",
                "vill_name": "ວັງໄຮ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40111",
                "vill_name": "ນາຫວານນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40112",
                "vill_name": "ເຈງ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40113",
                "vill_name": "ໜອງແມງດາ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40114",
                "vill_name": "ດອນໄຊ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40115",
                "vill_name": "ດອນແກ້ວ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40116",
                "vill_name": "ນາເລົາ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40117",
                "vill_name": "ນາໜີ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40118",
                "vill_name": "ນາສ້າວ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40119",
                "vill_name": "ນາແລ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40120",
                "vill_name": "ນາແສນຄຳ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40121",
                "vill_name": "ຫ້ວຍຂຸມ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40122",
                "vill_name": "ທ້ຽວ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40123",
                "vill_name": "ນາງີ້ວ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40124",
                "vill_name": "ຈອມຈັງ+ຫ້ວຍຈາ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40125",
                "vill_name": "ບໍ່",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40126",
                "vill_name": "ແຟນ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40127",
                "vill_name": "ໃໝ່",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40129",
                "vill_name": "ນ້ຳປຸງ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40130",
                "vill_name": "ນ້ຳປຣະ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40131",
                "vill_name": "ກິ່ວຈຸ ( ກີ່ວຈະລຸ)",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40132",
                "vill_name": "ຈອມອອງ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40133",
                "vill_name": "ກະລົວະ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40134",
                "vill_name": "ຫ້ວຍອຸ່ນ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40135",
                "vill_name": "ນາອອ້ມ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40136",
                "vill_name": "ນາງາມ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40137",
                "vill_name": "ມົກຈາກ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40138",
                "vill_name": "ລາງ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40139",
                "vill_name": "ທ່າສະເຫຼືອງ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40140",
                "vill_name": "ນາຊາຍທອງ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40141",
                "vill_name": "ຫຼັກ44",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40142",
                "vill_name": "ຫຼັກ32",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40143",
                "vill_name": "ຫຼັກ37 (ປຸງວິງ)",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40144",
                "vill_name": "ຍ້າວໃຫ່ຍ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40145",
                "vill_name": "ກິ້ວເຮ້ຍ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40146",
                "vill_name": "ລາວວ້າ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40147",
                "vill_name": "ປຸງລຽນ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40148",
                "vill_name": "ຫ້ວຍແພນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40149",
                "vill_name": "ພູສະຫວັນ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40150",
                "vill_name": "ລ້ອງຍາ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40151",
                "vill_name": "ປຸງຫຽງ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40152",
                "vill_name": "ຫ້ວຍຊາງ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40154",
                "vill_name": "ຫົວນ້ຳບາກ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40155",
                "vill_name": "ແສນສຸວັນ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40156",
                "vill_name": "ຕະອຶງ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40157",
                "vill_name": "ຕາງກົກ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40158",
                "vill_name": "ຈອມແສນ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40159",
                "vill_name": "ທຸຕິງ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40160",
                "vill_name": "ສະຄ່ານ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40161",
                "vill_name": "ນ້ຳປະ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40162",
                "vill_name": "ເພີນ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40163",
                "vill_name": "ແສນຂັນ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40164",
                "vill_name": "ພູວຽງ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40165",
                "vill_name": "ຫ້ວຍຮຸມ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40166",
                "vill_name": "ນາຮ້ວງ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40167",
                "vill_name": "ກອນແກ່ນ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40168",
                "vill_name": "ປາງແມງ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40169",
                "vill_name": "ນາມົນ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40170",
                "vill_name": "ຫ້ວຍມອກ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40171",
                "vill_name": "ວັງຍານ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40172",
                "vill_name": "ສະລວງ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40173",
                "vill_name": "ນ້ຳແຫງເໜືອ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40174",
                "vill_name": "ນ້ຳແຫງໃຕ້ (ນ້ຳກູດ),­ນໍ້າແຫງເໜຶອ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40175",
                "vill_name": "ແປນ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40176",
                "vill_name": "ຕົງປຼາງ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40177",
                "vill_name": "ມົກຄະ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40178",
                "vill_name": "ນ້ຳຈອງ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40179",
                "vill_name": "ຫ້ວຍຕອງ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40180",
                "vill_name": "ກໍ່ນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40181",
                "vill_name": "ຫຼັກ22",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40182",
                "vill_name": "ຫ້ວຍຕ້າ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40183",
                "vill_name": "ຫ້ວຍຄ່າ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40184",
                "vill_name": "ຫຼັກ10",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40185",
                "vill_name": "ຫ້ວຍຍຳ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40186",
                "vill_name": "ໂຮມໄຊ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40187",
                "vill_name": "ມູນເມືອງ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40188",
                "vill_name": "ຫ້ວຍເຮ້ຍ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40189",
                "vill_name": "ນາຈາງ+ຫ້ວຍຕຳ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40190",
                "vill_name": "ສະນາງປິ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40191",
                "vill_name": "ຫ້ວຍລ້ຽງ+ພຽງໄຊ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40192",
                "vill_name": "ວັງມົນ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40193",
                "vill_name": "ໂພນໂຮມ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40194",
                "vill_name": "ຫ້ວຍປໍ",
                "vill_name_en": "",
                "dr_id": "401"
              },
              {
                "vill_id": "40195",
                "vill_name": "ກີວທຸງ",
                "vill_name_en": "Kiewthoung",
                "dr_id": "401"
              },
              {
                "vill_id": "40196",
                "vill_name": "ໜອງແລ້ງ",
                "vill_name_en": "Nonglaeng",
                "dr_id": "401"
              }
            ]
          },
          {
            "dr_id": "402",
            "dr_name": "ຫລາ",
            "dr_name_en": "La",
            "pr_id": "4",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "40201",
                "vill_name": "ອາໂນ",
                "vill_name_en": "",
                "dr_id": "402"
              },
              {
                "vill_id": "40202",
                "vill_name": "ແສນຫຼວງ",
                "vill_name_en": "",
                "dr_id": "402"
              },
              {
                "vill_id": "40203",
                "vill_name": "ລາວຫວາງ",
                "vill_name_en": "",
                "dr_id": "402"
              },
              {
                "vill_id": "40204",
                "vill_name": "ອາຄັງ",
                "vill_name_en": "",
                "dr_id": "402"
              },
              {
                "vill_id": "40205",
                "vill_name": "ມົກລະຫາງ",
                "vill_name_en": "",
                "dr_id": "402"
              },
              {
                "vill_id": "40206",
                "vill_name": "ຫ້ວຍໂຕນ",
                "vill_name_en": "",
                "dr_id": "402"
              },
              {
                "vill_id": "40207",
                "vill_name": "ຈາກແປຼະ",
                "vill_name_en": "",
                "dr_id": "402"
              },
              {
                "vill_id": "40208",
                "vill_name": "ຕະໂລະລົມ",
                "vill_name_en": "",
                "dr_id": "402"
              },
              {
                "vill_id": "40209",
                "vill_name": "ປາກປຸຍ",
                "vill_name_en": "",
                "dr_id": "402"
              },
              {
                "vill_id": "40210",
                "vill_name": "ຈາກຈັງ",
                "vill_name_en": "",
                "dr_id": "402"
              },
              {
                "vill_id": "40211",
                "vill_name": "ມົກຕົນ",
                "vill_name_en": "",
                "dr_id": "402"
              },
              {
                "vill_id": "40212",
                "vill_name": "ປຼາງປໍ+ຕົງເຊີ",
                "vill_name_en": "",
                "dr_id": "402"
              },
              {
                "vill_id": "40213",
                "vill_name": "ກົກໄມ້ໃຫຍ່",
                "vill_name_en": "",
                "dr_id": "402"
              },
              {
                "vill_id": "40214",
                "vill_name": "ໂພນສະຫ່ວາງ",
                "vill_name_en": "",
                "dr_id": "402"
              },
              {
                "vill_id": "40215",
                "vill_name": "ຫ້ວຍຊັງ",
                "vill_name_en": "",
                "dr_id": "402"
              },
              {
                "vill_id": "40216",
                "vill_name": "ຫ້ວຍອໍ້",
                "vill_name_en": "",
                "dr_id": "402"
              },
              {
                "vill_id": "40217",
                "vill_name": "ຫ້ວຍແພ",
                "vill_name_en": "",
                "dr_id": "402"
              },
              {
                "vill_id": "40218",
                "vill_name": "ຫາດແອ່ນ",
                "vill_name_en": "",
                "dr_id": "402"
              },
              {
                "vill_id": "40220",
                "vill_name": "ຫົວແກ້ງ",
                "vill_name_en": "",
                "dr_id": "402"
              },
              {
                "vill_id": "40221",
                "vill_name": "ຫ້ວຍຈາຍ",
                "vill_name_en": "",
                "dr_id": "402"
              },
              {
                "vill_id": "40222",
                "vill_name": "ປາກກໍ",
                "vill_name_en": "",
                "dr_id": "402"
              },
              {
                "vill_id": "40223",
                "vill_name": "ດອນສະອາດ",
                "vill_name_en": "",
                "dr_id": "402"
              },
              {
                "vill_id": "40224",
                "vill_name": "ວຽງຄຳ",
                "vill_name_en": "",
                "dr_id": "402"
              },
              {
                "vill_id": "40225",
                "vill_name": "ວັງວັນ",
                "vill_name_en": "",
                "dr_id": "402"
              },
              {
                "vill_id": "40226",
                "vill_name": "ບວມສົມ",
                "vill_name_en": "",
                "dr_id": "402"
              },
              {
                "vill_id": "40227",
                "vill_name": "ໜອງບົວ",
                "vill_name_en": "",
                "dr_id": "402"
              },
              {
                "vill_id": "40228",
                "vill_name": "ຫ້ວຍຫຼາ",
                "vill_name_en": "",
                "dr_id": "402"
              },
              {
                "vill_id": "40229",
                "vill_name": "ໂພນໄຊ",
                "vill_name_en": "",
                "dr_id": "402"
              },
              {
                "vill_id": "40230",
                "vill_name": "ສະມັກຄີໄຊ",
                "vill_name_en": "",
                "dr_id": "402"
              },
              {
                "vill_id": "40231",
                "vill_name": "ຫ້ວຍຊູ",
                "vill_name_en": "",
                "dr_id": "402"
              },
              {
                "vill_id": "40232",
                "vill_name": "ຕະໜອງປໍ",
                "vill_name_en": "",
                "dr_id": "402"
              },
              {
                "vill_id": "40233",
                "vill_name": "ຜາວີ",
                "vill_name_en": "",
                "dr_id": "402"
              },
              {
                "vill_id": "40234",
                "vill_name": "ຕາດມ່ວນ",
                "vill_name_en": "",
                "dr_id": "402"
              },
              {
                "vill_id": "40235",
                "vill_name": "ໃໝ່",
                "vill_name_en": "",
                "dr_id": "402"
              },
              {
                "vill_id": "40236",
                "vill_name": "ນາຄອຍ",
                "vill_name_en": "",
                "dr_id": "402"
              },
              {
                "vill_id": "40237",
                "vill_name": "ຫ້ວຍແລ້ງ",
                "vill_name_en": "",
                "dr_id": "402"
              },
              {
                "vill_id": "40238",
                "vill_name": "ຕ່າງເງີຍ",
                "vill_name_en": "",
                "dr_id": "402"
              },
              {
                "vill_id": "40239",
                "vill_name": "ມົກໂຮງ",
                "vill_name_en": "",
                "dr_id": "402"
              },
              {
                "vill_id": "40240",
                "vill_name": "ຫ້ວຍທອງ",
                "vill_name_en": "",
                "dr_id": "402"
              },
              {
                "vill_id": "40241",
                "vill_name": "ປາງສະບັດ",
                "vill_name_en": "",
                "dr_id": "402"
              },
              {
                "vill_id": "40242",
                "vill_name": "ພູສະຖິ",
                "vill_name_en": "",
                "dr_id": "402"
              },
              {
                "vill_id": "40243",
                "vill_name": "ໂພນສະຫັວດ",
                "vill_name_en": "",
                "dr_id": "402"
              },
              {
                "vill_id": "40244",
                "vill_name": "ໃໝ່+ປ່າງສົມ",
                "vill_name_en": "",
                "dr_id": "402"
              },
              {
                "vill_id": "40245",
                "vill_name": "ສົບຈ່າຍ",
                "vill_name_en": "",
                "dr_id": "402"
              },
              {
                "vill_id": "40246",
                "vill_name": "ນ້ຳແທງ",
                "vill_name_en": "",
                "dr_id": "402"
              }
            ]
          },
          {
            "dr_id": "403",
            "dr_name": "ນາໝໍ້ ",
            "dr_name_en": "Namor",
            "pr_id": "4",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "40301",
                "vill_name": "ໂຮມໄຊ",
                "vill_name_en": "",
                "dr_id": "403"
              },
              {
                "vill_id": "40302",
                "vill_name": "ຫ້ວຍອ່ອນ",
                "vill_name_en": "",
                "dr_id": "403"
              },
              {
                "vill_id": "40303",
                "vill_name": "ນ້ຳແຊ",
                "vill_name_en": "",
                "dr_id": "403"
              },
              {
                "vill_id": "40304",
                "vill_name": "ນາຕ່ອງ",
                "vill_name_en": "",
                "dr_id": "403"
              },
              {
                "vill_id": "40305",
                "vill_name": "ປາກນ້ຳຕອງ",
                "vill_name_en": "",
                "dr_id": "403"
              },
              {
                "vill_id": "40306",
                "vill_name": "ປາງສາ",
                "vill_name_en": "",
                "dr_id": "403"
              },
              {
                "vill_id": "40307",
                "vill_name": "ຈອມແສນ",
                "vill_name_en": "",
                "dr_id": "403"
              },
              {
                "vill_id": "40308",
                "vill_name": "ແສນໄຊ",
                "vill_name_en": "",
                "dr_id": "403"
              },
              {
                "vill_id": "40309",
                "vill_name": "ຫ້ວຍຫົກ",
                "vill_name_en": "",
                "dr_id": "403"
              },
              {
                "vill_id": "40310",
                "vill_name": "ພູລີ",
                "vill_name_en": "",
                "dr_id": "403"
              },
              {
                "vill_id": "40311",
                "vill_name": "ໃໝ່ນາເຕົ່າ",
                "vill_name_en": "",
                "dr_id": "403"
              },
              {
                "vill_id": "40312",
                "vill_name": "ນາສະຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "403"
              },
              {
                "vill_id": "40313",
                "vill_name": "ຂວາງຄຳ",
                "vill_name_en": "",
                "dr_id": "403"
              },
              {
                "vill_id": "40314",
                "vill_name": "ຫ້ວຍຫຼັກ",
                "vill_name_en": "",
                "dr_id": "403"
              },
              {
                "vill_id": "40315",
                "vill_name": "ນ້ຳກໍ້",
                "vill_name_en": "",
                "dr_id": "403"
              },
              {
                "vill_id": "40316",
                "vill_name": "ມູ່ຕື່ນ",
                "vill_name_en": "",
                "dr_id": "403"
              },
              {
                "vill_id": "40317",
                "vill_name": "ນ້ຳເງິນ",
                "vill_name_en": "",
                "dr_id": "403"
              },
              {
                "vill_id": "40318",
                "vill_name": "ນ້ຳຍອນ",
                "vill_name_en": "",
                "dr_id": "403"
              },
              {
                "vill_id": "40319",
                "vill_name": "ຫົວນາ",
                "vill_name_en": "",
                "dr_id": "403"
              },
              {
                "vill_id": "40320",
                "vill_name": "ນ້ຳແດງ",
                "vill_name_en": "",
                "dr_id": "403"
              },
              {
                "vill_id": "40321",
                "vill_name": "ສະປຶ",
                "vill_name_en": "",
                "dr_id": "403"
              },
              {
                "vill_id": "40322",
                "vill_name": "ເພຍໄມ້ໃຫຍ່",
                "vill_name_en": "",
                "dr_id": "403"
              },
              {
                "vill_id": "40323",
                "vill_name": "ເພຍໄມ້ນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "403"
              },
              {
                "vill_id": "40324",
                "vill_name": "ຜາຄຳ",
                "vill_name_en": "",
                "dr_id": "403"
              },
              {
                "vill_id": "40325",
                "vill_name": "ນ້ຳໂມງ",
                "vill_name_en": "",
                "dr_id": "403"
              },
              {
                "vill_id": "40326",
                "vill_name": "ກີ່ວລານ",
                "vill_name_en": "",
                "dr_id": "403"
              },
              {
                "vill_id": "40327",
                "vill_name": "ໄຊສຳພັນ(ມີສຳພັນ)",
                "vill_name_en": "",
                "dr_id": "403"
              },
              {
                "vill_id": "40328",
                "vill_name": "ນາໝໍ້ເໜືອ",
                "vill_name_en": "",
                "dr_id": "403"
              },
              {
                "vill_id": "40329",
                "vill_name": "ນາໝໍ້ໄຕ້",
                "vill_name_en": "",
                "dr_id": "403"
              },
              {
                "vill_id": "40330",
                "vill_name": "ປາງທອງ",
                "vill_name_en": "",
                "dr_id": "403"
              },
              {
                "vill_id": "40331",
                "vill_name": "ນ້ຳແພງ",
                "vill_name_en": "",
                "dr_id": "403"
              },
              {
                "vill_id": "40332",
                "vill_name": "ຫ້ວຍຊາງ",
                "vill_name_en": "",
                "dr_id": "403"
              },
              {
                "vill_id": "40333",
                "vill_name": "ປາງດູ່",
                "vill_name_en": "",
                "dr_id": "403"
              },
              {
                "vill_id": "40334",
                "vill_name": "ນາຂາມ",
                "vill_name_en": "",
                "dr_id": "403"
              },
              {
                "vill_id": "40335",
                "vill_name": "ນ້ຳມູດ",
                "vill_name_en": "",
                "dr_id": "403"
              },
              {
                "vill_id": "40336",
                "vill_name": "ພູທອງ",
                "vill_name_en": "",
                "dr_id": "403"
              },
              {
                "vill_id": "40337",
                "vill_name": "ເລົ່າ",
                "vill_name_en": "",
                "dr_id": "403"
              },
              {
                "vill_id": "40338",
                "vill_name": "ແພງ",
                "vill_name_en": "",
                "dr_id": "403"
              },
              {
                "vill_id": "40339",
                "vill_name": "ພູເຄືອ",
                "vill_name_en": "",
                "dr_id": "403"
              },
              {
                "vill_id": "40340",
                "vill_name": "ພູເອືອດ",
                "vill_name_en": "",
                "dr_id": "403"
              },
              {
                "vill_id": "40341",
                "vill_name": "ນ້ຳວິນໃຕ້",
                "vill_name_en": "",
                "dr_id": "403"
              },
              {
                "vill_id": "40342",
                "vill_name": "ນາຖົວ",
                "vill_name_en": "",
                "dr_id": "403"
              },
              {
                "vill_id": "40343",
                "vill_name": "ນາທົ່ງ",
                "vill_name_en": "",
                "dr_id": "403"
              },
              {
                "vill_id": "40344",
                "vill_name": "ນ້ຳລຶນເໜືອ",
                "vill_name_en": "",
                "dr_id": "403"
              },
              {
                "vill_id": "40345",
                "vill_name": "ພູຕູມ",
                "vill_name_en": "",
                "dr_id": "403"
              },
              {
                "vill_id": "40346",
                "vill_name": "ກີ່ວແຈບ",
                "vill_name_en": "",
                "dr_id": "403"
              },
              {
                "vill_id": "40347",
                "vill_name": "ກູລົງ",
                "vill_name_en": "",
                "dr_id": "403"
              },
              {
                "vill_id": "40348",
                "vill_name": "ບໍ່ຕາໄກ່",
                "vill_name_en": "",
                "dr_id": "403"
              },
              {
                "vill_id": "40349",
                "vill_name": "ໝາກຈຸກ",
                "vill_name_en": "",
                "dr_id": "403"
              },
              {
                "vill_id": "40350",
                "vill_name": "ຫ້ວຍຮູ",
                "vill_name_en": "",
                "dr_id": "403"
              },
              {
                "vill_id": "40351",
                "vill_name": "ຫວ້ຍປອດ",
                "vill_name_en": "",
                "dr_id": "403"
              },
              {
                "vill_id": "40352",
                "vill_name": "ຫ້ວຍຊອຍ",
                "vill_name_en": "",
                "dr_id": "403"
              },
              {
                "vill_id": "40353",
                "vill_name": "ພູອົມ",
                "vill_name_en": "",
                "dr_id": "403"
              },
              {
                "vill_id": "40354",
                "vill_name": "ມົກຄະ",
                "vill_name_en": "",
                "dr_id": "403"
              },
              {
                "vill_id": "40355",
                "vill_name": "ຫ້ວຍຫາ",
                "vill_name_en": "",
                "dr_id": "403"
              },
              {
                "vill_id": "40356",
                "vill_name": "ວັງອ່າງ",
                "vill_name_en": "",
                "dr_id": "403"
              },
              {
                "vill_id": "40357",
                "vill_name": "ນ້ຳຕອງ",
                "vill_name_en": "",
                "dr_id": "403"
              },
              {
                "vill_id": "40358",
                "vill_name": "ຫ້ວຍແຮ",
                "vill_name_en": "",
                "dr_id": "403"
              },
              {
                "vill_id": "40359",
                "vill_name": "ຫ້ວຍແຮ",
                "vill_name_en": "",
                "dr_id": "403"
              },
              {
                "vill_id": "40360",
                "vill_name": "ພູປາດ",
                "vill_name_en": "",
                "dr_id": "403"
              },
              {
                "vill_id": "40361",
                "vill_name": "ພູລາ",
                "vill_name_en": "",
                "dr_id": "403"
              },
              {
                "vill_id": "40362",
                "vill_name": "ສະແງ",
                "vill_name_en": "",
                "dr_id": "403"
              },
              {
                "vill_id": "40363",
                "vill_name": "ສະແງ",
                "vill_name_en": "",
                "dr_id": "403"
              },
              {
                "vill_id": "40364",
                "vill_name": "ສະກົວະ",
                "vill_name_en": "",
                "dr_id": "403"
              },
              {
                "vill_id": "40365",
                "vill_name": "ນາໂຮມ",
                "vill_name_en": "",
                "dr_id": "403"
              }
            ]
          },
          {
            "dr_id": "404",
            "dr_name": "ງາ",
            "dr_name_en": "Nga",
            "pr_id": "4",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "40401",
                "vill_name": "ດອນແອ່ນ",
                "vill_name_en": "",
                "dr_id": "404"
              },
              {
                "vill_id": "40402",
                "vill_name": "ດອນງີ້ວ",
                "vill_name_en": "",
                "dr_id": "404"
              },
              {
                "vill_id": "40403",
                "vill_name": "ຫ້ວຍຄານ",
                "vill_name_en": "",
                "dr_id": "404"
              },
              {
                "vill_id": "40404",
                "vill_name": "ເມືອງງາ",
                "vill_name_en": "",
                "dr_id": "404"
              },
              {
                "vill_id": "40405",
                "vill_name": "ຫ້ວຍລຽງ",
                "vill_name_en": "",
                "dr_id": "404"
              },
              {
                "vill_id": "40406",
                "vill_name": "ຫ້ວຍເລີມ",
                "vill_name_en": "",
                "dr_id": "404"
              },
              {
                "vill_id": "40407",
                "vill_name": "ຫ້ວຍປອງ+ປຸງ",
                "vill_name_en": "",
                "dr_id": "404"
              },
              {
                "vill_id": "40408",
                "vill_name": "ໃໝ່",
                "vill_name_en": "",
                "dr_id": "404"
              },
              {
                "vill_id": "40409",
                "vill_name": "ນາຄົກ",
                "vill_name_en": "",
                "dr_id": "404"
              },
              {
                "vill_id": "40410",
                "vill_name": "ຮອງຖູໃຫຍ່+ນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "404"
              },
              {
                "vill_id": "40411",
                "vill_name": "ແສນໄຊ+ປ່າດົງ",
                "vill_name_en": "",
                "dr_id": "404"
              },
              {
                "vill_id": "40412",
                "vill_name": "ຕົງຕູນ້ອຍ+ຫ້ວຍລັນ",
                "vill_name_en": "",
                "dr_id": "404"
              },
              {
                "vill_id": "40413",
                "vill_name": "ຖິ່ນ",
                "vill_name_en": "",
                "dr_id": "404"
              },
              {
                "vill_id": "40414",
                "vill_name": "­ນ້ຳງິ້ວ",
                "vill_name_en": "",
                "dr_id": "404"
              },
              {
                "vill_id": "40415",
                "vill_name": "­ແສນຈອດ+ຫ້ວຍຫົກ",
                "vill_name_en": "",
                "dr_id": "404"
              },
              {
                "vill_id": "40416",
                "vill_name": "­ຫ້ວຍເຢັນ(ຫ້ວຍແທນຄູນ)",
                "vill_name_en": "",
                "dr_id": "404"
              },
              {
                "vill_id": "40417",
                "vill_name": "­ຫ້ວຍລ້ອມ",
                "vill_name_en": "",
                "dr_id": "404"
              },
              {
                "vill_id": "40418",
                "vill_name": "­ຫ້ວຍເຊີຍ",
                "vill_name_en": "",
                "dr_id": "404"
              },
              {
                "vill_id": "40419",
                "vill_name": "­ຫ້ວຍສະຫຼາກ",
                "vill_name_en": "",
                "dr_id": "404"
              },
              {
                "vill_id": "40420",
                "vill_name": "­ກີ່ວລັກຄຳ+ສຳພັນເກົ່າ",
                "vill_name_en": "",
                "dr_id": "404"
              },
              {
                "vill_id": "40421",
                "vill_name": "­ມົກລະວານ",
                "vill_name_en": "",
                "dr_id": "404"
              },
              {
                "vill_id": "40422",
                "vill_name": "­ທ່າກ້ວາງ+ຫ້ວຍປອນ",
                "vill_name_en": "",
                "dr_id": "404"
              },
              {
                "vill_id": "40423",
                "vill_name": "­ຫ້ວຍງ້ວນ",
                "vill_name_en": "",
                "dr_id": "404"
              },
              {
                "vill_id": "40424",
                "vill_name": "­ຫ້ວຍລະເປີ",
                "vill_name_en": "",
                "dr_id": "404"
              },
              {
                "vill_id": "40425",
                "vill_name": "­ໜອງເຕົ່າ",
                "vill_name_en": "",
                "dr_id": "404"
              },
              {
                "vill_id": "40426",
                "vill_name": "­ລາດຫານ",
                "vill_name_en": "",
                "dr_id": "404"
              },
              {
                "vill_id": "40427",
                "vill_name": "­ຄົກພູ",
                "vill_name_en": "",
                "dr_id": "404"
              },
              {
                "vill_id": "40428",
                "vill_name": "­ຍອຍໄຮ+ຜານໍໃຫຍ່",
                "vill_name_en": "",
                "dr_id": "404"
              },
              {
                "vill_id": "40429",
                "vill_name": "­ຫ້ວຍຫີນ",
                "vill_name_en": "",
                "dr_id": "404"
              },
              {
                "vill_id": "40430",
                "vill_name": "­ຫ້ວຍພາງ",
                "vill_name_en": "",
                "dr_id": "404"
              },
              {
                "vill_id": "40431",
                "vill_name": "­ພູຫວດໃຫຍ່",
                "vill_name_en": "",
                "dr_id": "404"
              },
              {
                "vill_id": "40433",
                "vill_name": "­ຫ້ວຍລວມ",
                "vill_name_en": "",
                "dr_id": "404"
              },
              {
                "vill_id": "40434",
                "vill_name": "­ຂະຫຍາຍໃຫຍ່+ມົກກົກ",
                "vill_name_en": "",
                "dr_id": "404"
              },
              {
                "vill_id": "40435",
                "vill_name": "­ຫ້ວຍຄະ",
                "vill_name_en": "",
                "dr_id": "404"
              },
              {
                "vill_id": "40436",
                "vill_name": "­ບໍ່",
                "vill_name_en": "",
                "dr_id": "404"
              },
              {
                "vill_id": "40437",
                "vill_name": "­ໂພນສະຫ່ວາງ",
                "vill_name_en": "",
                "dr_id": "404"
              },
              {
                "vill_id": "40438",
                "vill_name": "­ລາດຂະມູນ",
                "vill_name_en": "",
                "dr_id": "404"
              },
              {
                "vill_id": "40440",
                "vill_name": "­ຫ້ວຍຈັນ+ຫ້ວຍມ່ວງ",
                "vill_name_en": "",
                "dr_id": "404"
              },
              {
                "vill_id": "40441",
                "vill_name": "­ພູກໍ+ຫ້ວຍຄໍ້",
                "vill_name_en": "",
                "dr_id": "404"
              },
              {
                "vill_id": "40442",
                "vill_name": "ພູສູງນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "404"
              },
              {
                "vill_id": "40443",
                "vill_name": "ມົກລອຍ+ຫ້ວຍຈິງ",
                "vill_name_en": "",
                "dr_id": "404"
              },
              {
                "vill_id": "40445",
                "vill_name": "ຫ້ວຍເຍືອງ",
                "vill_name_en": "",
                "dr_id": "404"
              },
              {
                "vill_id": "40446",
                "vill_name": "ແກ່ວ",
                "vill_name_en": "",
                "dr_id": "404"
              },
              {
                "vill_id": "40447",
                "vill_name": "ຄອນທອຍ+ມົກລົມ",
                "vill_name_en": "",
                "dr_id": "404"
              },
              {
                "vill_id": "40448",
                "vill_name": "ນ້ຳຍິງ+ພູວຽງ",
                "vill_name_en": "",
                "dr_id": "404"
              },
              {
                "vill_id": "40449",
                "vill_name": "ຂະຫຍາຍນ້ອຍ+ມົກໄປຼ",
                "vill_name_en": "",
                "dr_id": "404"
              },
              {
                "vill_id": "40450",
                "vill_name": "ຫ້ວຍຜັງ",
                "vill_name_en": "",
                "dr_id": "404"
              },
              {
                "vill_id": "40451",
                "vill_name": "ຫ້ວຍໂອ",
                "vill_name_en": "",
                "dr_id": "404"
              },
              {
                "vill_id": "40452",
                "vill_name": "ພູຈີ",
                "vill_name_en": "",
                "dr_id": "404"
              },
              {
                "vill_id": "40453",
                "vill_name": "ຫາດຂາມ",
                "vill_name_en": "",
                "dr_id": "404"
              },
              {
                "vill_id": "40454",
                "vill_name": "ລາດແອ່ນ",
                "vill_name_en": "",
                "dr_id": "404"
              },
              {
                "vill_id": "40455",
                "vill_name": "ຫ້ວຍຫີງ",
                "vill_name_en": "",
                "dr_id": "404"
              },
              {
                "vill_id": "40456",
                "vill_name": "ແກ້ງຮາງ",
                "vill_name_en": "",
                "dr_id": "404"
              },
              {
                "vill_id": "40457",
                "vill_name": "ຫ້ວຍຊານ+ກິ່ວວິງ",
                "vill_name_en": "",
                "dr_id": "404"
              },
              {
                "vill_id": "40458",
                "vill_name": "ຫາດເຕິະ",
                "vill_name_en": "",
                "dr_id": "404"
              },
              {
                "vill_id": "40459",
                "vill_name": "ປາກຫ້ວຍລາວ",
                "vill_name_en": "",
                "dr_id": "404"
              },
              {
                "vill_id": "40460",
                "vill_name": "ນາຊານ+ຫ້ວຍໂມ",
                "vill_name_en": "",
                "dr_id": "404"
              },
              {
                "vill_id": "40461",
                "vill_name": "ໂພນຄຳ",
                "vill_name_en": "",
                "dr_id": "404"
              },
              {
                "vill_id": "40462",
                "vill_name": "ຫ້ວຍໄລ",
                "vill_name_en": "",
                "dr_id": "404"
              },
              {
                "vill_id": "40463",
                "vill_name": "ລາດກືມ",
                "vill_name_en": "",
                "dr_id": "404"
              },
              {
                "vill_id": "40464",
                "vill_name": "ແທນຄູນ",
                "vill_name_en": "Tankhoun",
                "dr_id": "404"
              },
              {
                "vill_id": "40465",
                "vill_name": "ບໍ",
                "vill_name_en": "Bor",
                "dr_id": "404"
              },
              {
                "vill_id": "40466",
                "vill_name": "ຫ້ວຍໝໍ້",
                "vill_name_en": "Houaymor",
                "dr_id": "404"
              }
            ]
          },
          {
            "dr_id": "405",
            "dr_name": "ແບງ",
            "dr_name_en": "Beng",
            "pr_id": "4",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "40501",
                "vill_name": "ເພຍຫົວນ້ຳ",
                "vill_name_en": "",
                "dr_id": "405"
              },
              {
                "vill_id": "40502",
                "vill_name": "ໂພນສະອາດ",
                "vill_name_en": "",
                "dr_id": "405"
              },
              {
                "vill_id": "40503",
                "vill_name": "ນາປາ",
                "vill_name_en": "",
                "dr_id": "405"
              },
              {
                "vill_id": "40504",
                "vill_name": "ນາປາໃຕ້",
                "vill_name_en": "",
                "dr_id": "405"
              },
              {
                "vill_id": "40505",
                "vill_name": "ນາແມດ ",
                "vill_name_en": "",
                "dr_id": "405"
              },
              {
                "vill_id": "40506",
                "vill_name": "ພູໂຮງ(ເມືອງໂຮງ) ",
                "vill_name_en": "",
                "dr_id": "405"
              },
              {
                "vill_id": "40507",
                "vill_name": "ໄລ່ໃຫຍ່",
                "vill_name_en": "",
                "dr_id": "405"
              },
              {
                "vill_id": "40508",
                "vill_name": "ໄລນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "405"
              },
              {
                "vill_id": "40509",
                "vill_name": "ທ່າມື່ນ",
                "vill_name_en": "",
                "dr_id": "405"
              },
              {
                "vill_id": "40510",
                "vill_name": "ປາງທອງ",
                "vill_name_en": "",
                "dr_id": "405"
              },
              {
                "vill_id": "40511",
                "vill_name": "ສົບລໍ ( ຫ້ວຍລໍ )",
                "vill_name_en": "",
                "dr_id": "405"
              },
              {
                "vill_id": "40512",
                "vill_name": "ຊຽງແລ",
                "vill_name_en": "",
                "dr_id": "405"
              },
              {
                "vill_id": "40513",
                "vill_name": "ນ້ຳຕຸຍ",
                "vill_name_en": "",
                "dr_id": "405"
              },
              {
                "vill_id": "40514",
                "vill_name": "ນາງົວ",
                "vill_name_en": "",
                "dr_id": "405"
              },
              {
                "vill_id": "40515",
                "vill_name": "ມາງ",
                "vill_name_en": "",
                "dr_id": "405"
              },
              {
                "vill_id": "40516",
                "vill_name": "ປ້ອມ",
                "vill_name_en": "",
                "dr_id": "405"
              },
              {
                "vill_id": "40517",
                "vill_name": "ຕາງດູ່",
                "vill_name_en": "",
                "dr_id": "405"
              },
              {
                "vill_id": "40518",
                "vill_name": "ບົງ",
                "vill_name_en": "",
                "dr_id": "405"
              },
              {
                "vill_id": "40519",
                "vill_name": "ກຳ",
                "vill_name_en": "",
                "dr_id": "405"
              },
              {
                "vill_id": "40520",
                "vill_name": "ຕາງຈອງ",
                "vill_name_en": "",
                "dr_id": "405"
              },
              {
                "vill_id": "40521",
                "vill_name": "ກັນວາງ",
                "vill_name_en": "",
                "dr_id": "405"
              },
              {
                "vill_id": "40522",
                "vill_name": "ກັນວາງ",
                "vill_name_en": "",
                "dr_id": "405"
              },
              {
                "vill_id": "40523",
                "vill_name": "ຍໍ້",
                "vill_name_en": "",
                "dr_id": "405"
              },
              {
                "vill_id": "40524",
                "vill_name": "ນາໄລ",
                "vill_name_en": "",
                "dr_id": "405"
              },
              {
                "vill_id": "40525",
                "vill_name": "ຫ້ວຍຫົກ",
                "vill_name_en": "",
                "dr_id": "405"
              },
              {
                "vill_id": "40526",
                "vill_name": "ປາງເດື່ອ",
                "vill_name_en": "",
                "dr_id": "405"
              },
              {
                "vill_id": "40527",
                "vill_name": "ບ້ານໂພແກ້ວ",
                "vill_name_en": "",
                "dr_id": "405"
              },
              {
                "vill_id": "40528",
                "vill_name": "ພູສີງ",
                "vill_name_en": "",
                "dr_id": "405"
              },
              {
                "vill_id": "40529",
                "vill_name": "ຕົງຖູນ",
                "vill_name_en": "",
                "dr_id": "405"
              },
              {
                "vill_id": "40530",
                "vill_name": "ມົກວຽງ",
                "vill_name_en": "",
                "dr_id": "405"
              },
              {
                "vill_id": "40531",
                "vill_name": "ພູໄລ",
                "vill_name_en": "",
                "dr_id": "405"
              },
              {
                "vill_id": "40532",
                "vill_name": "ຂ້ອນ",
                "vill_name_en": "",
                "dr_id": "405"
              },
              {
                "vill_id": "40533",
                "vill_name": "ຈັນໄໝ່",
                "vill_name_en": "",
                "dr_id": "405"
              },
              {
                "vill_id": "40534",
                "vill_name": "ສະໂຄຍ",
                "vill_name_en": "",
                "dr_id": "405"
              },
              {
                "vill_id": "40535",
                "vill_name": "ນ້ຳຂອງ",
                "vill_name_en": "",
                "dr_id": "405"
              },
              {
                "vill_id": "40536",
                "vill_name": "ມົກກົກ",
                "vill_name_en": "",
                "dr_id": "405"
              },
              {
                "vill_id": "40537",
                "vill_name": "ນາງີ້ວ",
                "vill_name_en": "",
                "dr_id": "405"
              },
              {
                "vill_id": "40538",
                "vill_name": "ແບງຫຼວງ",
                "vill_name_en": "",
                "dr_id": "405"
              },
              {
                "vill_id": "40539",
                "vill_name": "ແບງຄຳ",
                "vill_name_en": "",
                "dr_id": "405"
              },
              {
                "vill_id": "40540",
                "vill_name": "ຫ້ວຍລາ",
                "vill_name_en": "",
                "dr_id": "405"
              },
              {
                "vill_id": "40541",
                "vill_name": "ທ່າກາດv",
                "vill_name_en": "",
                "dr_id": "405"
              },
              {
                "vill_id": "40542",
                "vill_name": "ທ່າກາດ",
                "vill_name_en": "",
                "dr_id": "405"
              },
              {
                "vill_id": "40543",
                "vill_name": "ນ້ຳດໍ້",
                "vill_name_en": "",
                "dr_id": "405"
              },
              {
                "vill_id": "40544",
                "vill_name": "ກອນຄຳ",
                "vill_name_en": "",
                "dr_id": "405"
              },
              {
                "vill_id": "40545",
                "vill_name": "ນາບອນ",
                "vill_name_en": "",
                "dr_id": "405"
              },
              {
                "vill_id": "40546",
                "vill_name": "ໂຮມໄຊຄຳ",
                "vill_name_en": "",
                "dr_id": "405"
              },
              {
                "vill_id": "40547",
                "vill_name": "ກົກງີ້ວ",
                "vill_name_en": "",
                "dr_id": "405"
              },
              {
                "vill_id": "40548",
                "vill_name": "ພູຄຳ",
                "vill_name_en": "",
                "dr_id": "405"
              },
              {
                "vill_id": "40549",
                "vill_name": "ສາມກາງ",
                "vill_name_en": "",
                "dr_id": "405"
              },
              {
                "vill_id": "40550",
                "vill_name": "ປາງສົມ",
                "vill_name_en": "",
                "dr_id": "405"
              },
              {
                "vill_id": "40551",
                "vill_name": "ວັງວະ",
                "vill_name_en": "",
                "dr_id": "405"
              },
              {
                "vill_id": "40552",
                "vill_name": "ແສນສີ",
                "vill_name_en": "",
                "dr_id": "405"
              },
              {
                "vill_id": "40553",
                "vill_name": "ເພຍເຕົ່າ",
                "vill_name_en": "",
                "dr_id": "405"
              },
              {
                "vill_id": "40554",
                "vill_name": "ຮາມົວ",
                "vill_name_en": "",
                "dr_id": "405"
              },
              {
                "vill_id": "40555",
                "vill_name": "ພູລຸ້ງ",
                "vill_name_en": "",
                "dr_id": "405"
              },
              {
                "vill_id": "40556",
                "vill_name": "ຫຼັກ",
                "vill_name_en": "",
                "dr_id": "405"
              },
              {
                "vill_id": "40557",
                "vill_name": "ນາໂຮມ",
                "vill_name_en": "",
                "dr_id": "405"
              },
              {
                "vill_id": "40558",
                "vill_name": "ຜາແກ້ວ+ນ້ຳແພ",
                "vill_name_en": "",
                "dr_id": "405"
              },
              {
                "vill_id": "40559",
                "vill_name": "ກີ່ວສັງວັນ",
                "vill_name_en": "",
                "dr_id": "405"
              },
              {
                "vill_id": "40560",
                "vill_name": "ນາຫ້ວຍ",
                "vill_name_en": "",
                "dr_id": "405"
              },
              {
                "vill_id": "40561",
                "vill_name": "ໂພນສີ",
                "vill_name_en": "",
                "dr_id": "405"
              },
              {
                "vill_id": "40562",
                "vill_name": "ໂພນສະອາດ",
                "vill_name_en": "Phonsaeath",
                "dr_id": "405"
              },
              {
                "vill_id": "40563",
                "vill_name": "ເພຍເກົ່າ",
                "vill_name_en": "Phaerkao",
                "dr_id": "405"
              },
              {
                "vill_id": "40564",
                "vill_name": "ໂພໄຊ",
                "vill_name_en": "Phoxay",
                "dr_id": "405"
              },
              {
                "vill_id": "40565",
                "vill_name": "ທ່າສົມ",
                "vill_name_en": "Thasom",
                "dr_id": "405"
              },
              {
                "vill_id": "40566",
                "vill_name": "ມົກລະວະ",
                "vill_name_en": "Moklava",
                "dr_id": "405"
              }
            ]
          },
          {
            "dr_id": "406",
            "dr_name": "ຮຸນ",
            "dr_name_en": "Hoon",
            "pr_id": "4",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "40601",
                "vill_name": "ໂພໄຊ(ໂພນໄຊ)",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40602",
                "vill_name": "ວັງຕັງ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40603",
                "vill_name": "ລາງຈິງ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40604",
                "vill_name": "ນາເງິນ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40605",
                "vill_name": "ດອນຄຳ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40606",
                "vill_name": "ເພຍຫລັງ(ເພຍເລັງ)",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40607",
                "vill_name": "ໄຊມົວ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40608",
                "vill_name": "ມົກໄປຼ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40609",
                "vill_name": "ວັງຫຼ່ຳ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40610",
                "vill_name": "ວັງຫຼ່ຳ + ໂພນແກ້ວ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40612",
                "vill_name": "ດອນແກ້ວ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40613",
                "vill_name": "ໜອງບົວແດງ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40614",
                "vill_name": "ນາຝາງ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40615",
                "vill_name": "ໂພນສະຫວັນ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40616",
                "vill_name": "ໂພນສະຫວາດ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40617",
                "vill_name": "ນາໂຄ້ງ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40618",
                "vill_name": "ນາໂຮມ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40619",
                "vill_name": "ນ້ຳອຸ່ນ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40620",
                "vill_name": "ແຟນສີວິໄລ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40621",
                "vill_name": "ໜອງດິນ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40622",
                "vill_name": "ກອນຄຳ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40623",
                "vill_name": "ມົກສວນ(ມົກໄປຼ່)",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40624",
                "vill_name": "ຜາງ່າມ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40625",
                "vill_name": "ບ້ານຈັນໄຕ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40626",
                "vill_name": "ນາທອງ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40627",
                "vill_name": "ນ້ຳຄໍ້",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40628",
                "vill_name": "ບວມເລົາ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40629",
                "vill_name": "ນ້ຳຍ້ອນ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40630",
                "vill_name": "ນາວາງ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40631",
                "vill_name": "ລອ້ງອີ່ນ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40632",
                "vill_name": "ນ້ຳໝ້ຽງ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40633",
                "vill_name": "ພູຕູມ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40634",
                "vill_name": "ກອງເໝືອງ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40635",
                "vill_name": "ດອນເມືອງ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40636",
                "vill_name": "ສີບຸນເຮືອງ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40637",
                "vill_name": "ຫົວນ້ຳມ້າວ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40638",
                "vill_name": "ໂພນສະຫ່ວາງ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40639",
                "vill_name": "ອຸດົມ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40640",
                "vill_name": "ພູຄອຍ(ຕາດຕະແລ)",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40641",
                "vill_name": "ດູ່",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40642",
                "vill_name": "ນ້ຳພັກ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40643",
                "vill_name": "ພູແຊ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40644",
                "vill_name": "ພູລອດ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40645",
                "vill_name": "ຕາງໂລນ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40646",
                "vill_name": "ຕາງຢາ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40647",
                "vill_name": "ລ້ອງຄູ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40648",
                "vill_name": "ສົມໄຊ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40649",
                "vill_name": "ນາ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40650",
                "vill_name": "ນາຄຳ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40651",
                "vill_name": "ສົມພອນ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40652",
                "vill_name": "ນ້ຳລຽນ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40653",
                "vill_name": "ແຈ້ງ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40654",
                "vill_name": "ຫ້ວຍຫົກ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40655",
                "vill_name": "ຫ້ວຍຫົກ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40656",
                "vill_name": "ຈອງນັ່ງ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40657",
                "vill_name": "ຜາຍຳ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40658",
                "vill_name": "ພູທອງ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40659",
                "vill_name": "ມົກກະຈອກ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40660",
                "vill_name": "ມົກຄະ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40661",
                "vill_name": "ພູຢວກ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40662",
                "vill_name": "ກອນໂຕຼງ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40663",
                "vill_name": "ສັນຢາງ(ນ້ຳກ້ວາງ)",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40664",
                "vill_name": "ກາງ ( ພູວຽງໄຊ )",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40665",
                "vill_name": "ພູລາດ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40666",
                "vill_name": "ກອນເທີຍ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40667",
                "vill_name": "ພູເລົາ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40668",
                "vill_name": "ມົກຮັງ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40669",
                "vill_name": "ຈັນວາງ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40670",
                "vill_name": "ນ້ຳພູນ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40671",
                "vill_name": "ພູສອນ ( ແມ່ )",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40672",
                "vill_name": "ນ້ຳຕຳ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40673",
                "vill_name": "ມົກພອດ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40674",
                "vill_name": "ຕະລຶບ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40675",
                "vill_name": "ໂພນຄຳ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40676",
                "vill_name": "ມົກຫຼັກ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40677",
                "vill_name": "ນ້ຳພວນ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40678",
                "vill_name": "ພູໂພນ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40679",
                "vill_name": "ນາໂພກ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40680",
                "vill_name": "ໂພນໄຊ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40681",
                "vill_name": "ດອນນາ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40682",
                "vill_name": "ງີ້ວ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40683",
                "vill_name": "ກະຕ່າງຍາ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40684",
                "vill_name": "ນ້ຳຍຶງ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40685",
                "vill_name": "ຈອມແລງ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40686",
                "vill_name": "ກີ່ວຍາບ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40687",
                "vill_name": "ມົກຈາກ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40688",
                "vill_name": "ແມ່",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40689",
                "vill_name": "ນ້ຳພ້າວ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40690",
                "vill_name": "ກອນລາງ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40691",
                "vill_name": "ນາຊຽງດີ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40692",
                "vill_name": "ໃໝ່",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40693",
                "vill_name": "ໃໝ່",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40694",
                "vill_name": "ຜຽງຫໍ້",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40695",
                "vill_name": "ນ້ຳນອງ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40696",
                "vill_name": "ແກ້ວ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40697",
                "vill_name": "ກີ່ວຜາ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40698",
                "vill_name": "ນ້ຳນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40699",
                "vill_name": "ພຽງຫຍ້າ",
                "vill_name_en": "",
                "dr_id": "406"
              },
              {
                "vill_id": "40700",
                "vill_name": "ໜໍ່ຂົມ",
                "vill_name_en": "Norkhom",
                "dr_id": "406"
              }
            ]
          },
          {
            "dr_id": "407",
            "dr_name": "ປາກແບງ",
            "dr_name_en": "Pakbeng",
            "pr_id": "4",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "40701",
                "vill_name": "ປາກແບງ",
                "vill_name_en": "",
                "dr_id": "407"
              },
              {
                "vill_id": "40702",
                "vill_name": "ປາກເງີຍ",
                "vill_name_en": "",
                "dr_id": "407"
              },
              {
                "vill_id": "40703",
                "vill_name": "ດອນແຈ້ງ",
                "vill_name_en": "",
                "dr_id": "407"
              },
              {
                "vill_id": "40704",
                "vill_name": "ຫ້ວຍໂທມ",
                "vill_name_en": "",
                "dr_id": "407"
              },
              {
                "vill_id": "40705",
                "vill_name": "ຫ້ວຍເລົາ",
                "vill_name_en": "",
                "dr_id": "407"
              },
              {
                "vill_id": "40706",
                "vill_name": "ກີ່ວຄຸຍ",
                "vill_name_en": "",
                "dr_id": "407"
              },
              {
                "vill_id": "40707",
                "vill_name": "ແກ້ງຊ້າງ",
                "vill_name_en": "",
                "dr_id": "407"
              },
              {
                "vill_id": "40708",
                "vill_name": "ດອນສະອາດ",
                "vill_name_en": "",
                "dr_id": "407"
              },
              {
                "vill_id": "40709",
                "vill_name": "ມົກພາງ",
                "vill_name_en": "",
                "dr_id": "407"
              },
              {
                "vill_id": "40710",
                "vill_name": "ພູລວງ",
                "vill_name_en": "",
                "dr_id": "407"
              },
              {
                "vill_id": "40711",
                "vill_name": "ໄຊຊະນະ",
                "vill_name_en": "",
                "dr_id": "407"
              },
              {
                "vill_id": "40712",
                "vill_name": "ຫົວນ້ຳປາ",
                "vill_name_en": "",
                "dr_id": "407"
              },
              {
                "vill_id": "40713",
                "vill_name": "ຄົກກະ",
                "vill_name_en": "",
                "dr_id": "407"
              },
              {
                "vill_id": "40714",
                "vill_name": "ຫ້ວຍໂມ່ງ",
                "vill_name_en": "",
                "dr_id": "407"
              },
              {
                "vill_id": "40715",
                "vill_name": "ໂຮມໄຊ",
                "vill_name_en": "",
                "dr_id": "407"
              },
              {
                "vill_id": "40716",
                "vill_name": "ມົກໄປຼ່ກາງ",
                "vill_name_en": "",
                "dr_id": "407"
              },
              {
                "vill_id": "40717",
                "vill_name": "ມົກໄປຼ່ລຸ່ມ",
                "vill_name_en": "",
                "dr_id": "407"
              },
              {
                "vill_id": "40718",
                "vill_name": "ກາງ",
                "vill_name_en": "",
                "dr_id": "407"
              },
              {
                "vill_id": "40719",
                "vill_name": "ຈອມແສນ",
                "vill_name_en": "",
                "dr_id": "407"
              },
              {
                "vill_id": "40720",
                "vill_name": "ນ້ຳເຢັນ",
                "vill_name_en": "",
                "dr_id": "407"
              },
              {
                "vill_id": "40721",
                "vill_name": "ຕະຫງ່ອນ",
                "vill_name_en": "",
                "dr_id": "407"
              },
              {
                "vill_id": "40722",
                "vill_name": "ລ່ອງໂຕ່ງ",
                "vill_name_en": "",
                "dr_id": "407"
              },
              {
                "vill_id": "40723",
                "vill_name": "ແກ້ງແຫຼະ",
                "vill_name_en": "",
                "dr_id": "407"
              },
              {
                "vill_id": "40724",
                "vill_name": "ແຕງສາ",
                "vill_name_en": "",
                "dr_id": "407"
              },
              {
                "vill_id": "40725",
                "vill_name": "ຜາລ້ອມ",
                "vill_name_en": "",
                "dr_id": "407"
              },
              {
                "vill_id": "40726",
                "vill_name": "ພູຫຸ່ງ",
                "vill_name_en": "",
                "dr_id": "407"
              },
              {
                "vill_id": "40727",
                "vill_name": "ມົກແຫວນ",
                "vill_name_en": "",
                "dr_id": "407"
              },
              {
                "vill_id": "40728",
                "vill_name": "ຕອງ",
                "vill_name_en": "",
                "dr_id": "407"
              },
              {
                "vill_id": "40729",
                "vill_name": "ພູຫຼວງ",
                "vill_name_en": "",
                "dr_id": "407"
              },
              {
                "vill_id": "40730",
                "vill_name": "ລອງຍອງ",
                "vill_name_en": "",
                "dr_id": "407"
              },
              {
                "vill_id": "40731",
                "vill_name": "ມົກຫັວດ",
                "vill_name_en": "",
                "dr_id": "407"
              },
              {
                "vill_id": "40732",
                "vill_name": "ກະຕາງຕອນ",
                "vill_name_en": "",
                "dr_id": "407"
              },
              {
                "vill_id": "40733",
                "vill_name": "ພຽງ",
                "vill_name_en": "",
                "dr_id": "407"
              },
              {
                "vill_id": "40734",
                "vill_name": "ມົກກ່າ",
                "vill_name_en": "",
                "dr_id": "407"
              },
              {
                "vill_id": "40735",
                "vill_name": "ມົກກ່າ",
                "vill_name_en": "",
                "dr_id": "407"
              },
              {
                "vill_id": "40736",
                "vill_name": "ປີງ",
                "vill_name_en": "",
                "dr_id": "407"
              },
              {
                "vill_id": "40737",
                "vill_name": "ພູສູງ",
                "vill_name_en": "",
                "dr_id": "407"
              },
              {
                "vill_id": "40738",
                "vill_name": "ກອນລາງ",
                "vill_name_en": "",
                "dr_id": "407"
              },
              {
                "vill_id": "40739",
                "vill_name": "ມົກແຄະ",
                "vill_name_en": "",
                "dr_id": "407"
              },
              {
                "vill_id": "40740",
                "vill_name": "ຈອມແລງໃຫ່ຍ",
                "vill_name_en": "",
                "dr_id": "407"
              },
              {
                "vill_id": "40741",
                "vill_name": "ຈອມແລງນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "407"
              },
              {
                "vill_id": "40742",
                "vill_name": "ຫ້ວຍແຊງຄຳ",
                "vill_name_en": "",
                "dr_id": "407"
              },
              {
                "vill_id": "40743",
                "vill_name": "ຄຳ",
                "vill_name_en": "",
                "dr_id": "407"
              },
              {
                "vill_id": "40744",
                "vill_name": "ຫ້ວຍກະ",
                "vill_name_en": "",
                "dr_id": "407"
              },
              {
                "vill_id": "40745",
                "vill_name": "ງອນ",
                "vill_name_en": "",
                "dr_id": "407"
              },
              {
                "vill_id": "40746",
                "vill_name": "ພູຊາງ (ຈອມພູ)",
                "vill_name_en": "",
                "dr_id": "407"
              },
              {
                "vill_id": "40747",
                "vill_name": "ມົກກ່ານ",
                "vill_name_en": "",
                "dr_id": "407"
              },
              {
                "vill_id": "40748",
                "vill_name": "ຈອມຊາງນ້ຳເຢັນ",
                "vill_name_en": "",
                "dr_id": "407"
              },
              {
                "vill_id": "40749",
                "vill_name": "ມົກໂຄໃຫ່ຍ",
                "vill_name_en": "",
                "dr_id": "407"
              },
              {
                "vill_id": "40750",
                "vill_name": "ມົກສະຕອງ",
                "vill_name_en": "",
                "dr_id": "407"
              },
              {
                "vill_id": "40751",
                "vill_name": "ມົກຕຸ",
                "vill_name_en": "",
                "dr_id": "407"
              },
              {
                "vill_id": "40752",
                "vill_name": "ມົກຕຸ",
                "vill_name_en": "",
                "dr_id": "407"
              },
              {
                "vill_id": "40753",
                "vill_name": "ມົກໂຄກາງ",
                "vill_name_en": "",
                "dr_id": "407"
              },
              {
                "vill_id": "40754",
                "vill_name": "ລາງຈິງ",
                "vill_name_en": "",
                "dr_id": "407"
              },
              {
                "vill_id": "40755",
                "vill_name": "ກອງວາງ",
                "vill_name_en": "",
                "dr_id": "407"
              },
              {
                "vill_id": "40756",
                "vill_name": "ສຸກໄຊ",
                "vill_name_en": "",
                "dr_id": "407"
              }
            ]
          }
        ]
      },
      {
        "pr_id": "5",
        "pr_name": "ບໍ່ແກ້ວ",
        "pr_name_en": "Bokeo",
        "districts": [
          {"dr_name": "ເລືອກເມືອງປະຈຸບັນ", "villages": []},
          {
            "dr_id": "501",
            "dr_name": "ຫ້ວຍຊາຍ",
            "dr_name_en": "Houixay",
            "pr_id": "5",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "50101",
                "vill_name": "ນ້ຳຍອນເກົ່າ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50102",
                "vill_name": "ນ້ຳຍອນໃຫມ່",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50103",
                "vill_name": "ລາວຫລວງ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50104",
                "vill_name": "ພູໄຊສະຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50105",
                "vill_name": "ປາກງາວ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50106",
                "vill_name": "ດອນຄູນ+ຫາດພວນ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50107",
                "vill_name": "ນ້ຳແສນ+ທຸງຊາຍ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50108",
                "vill_name": "ນ້ຳແລ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50109",
                "vill_name": "ນ້ຳຈ້ວມ+ຈັນສະຫວ່າງ+ພູຕີ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50110",
                "vill_name": "ສີໂພໄຊ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50111",
                "vill_name": "ນ້ຳປອງ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50112",
                "vill_name": "ໂພນໄພ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50113",
                "vill_name": "ອອນ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50114",
                "vill_name": "ນ້ຳຈວມ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50115",
                "vill_name": "ຈອມສີ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50116",
                "vill_name": "ທ່າຟ້າ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50117",
                "vill_name": "ລະໂກນ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50118",
                "vill_name": "ນ້ຳແຊວ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50119",
                "vill_name": "ຊອດ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50120",
                "vill_name": "ສະຕຸນ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50121",
                "vill_name": "ມົກສຸກ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50122",
                "vill_name": "ປາງສະລາວ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50123",
                "vill_name": "ຕູບ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50124",
                "vill_name": "ດອນໃຈ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50125",
                "vill_name": "ດອນຄຳ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50126",
                "vill_name": "ນາງາມ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50127",
                "vill_name": "ນ້ຳທຸງ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50128",
                "vill_name": "ນາຫລວງ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50129",
                "vill_name": "ປູ່ງນາໜູນ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50130",
                "vill_name": "ຫນອງຄຳ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50131",
                "vill_name": "ນ້ຳເດື່ອ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50132",
                "vill_name": "ດອນພ້າວ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50133",
                "vill_name": "ໂພຄຳ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50134",
                "vill_name": "ນ້ຳມ້າ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50135",
                "vill_name": "ຫ້ວຍດິນຈີ່",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50136",
                "vill_name": "ນ້ຳປູ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50137",
                "vill_name": "ນ້ຳງາວ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50138",
                "vill_name": "ນ້ຳແພດ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50139",
                "vill_name": "ພີມົນສິນ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50140",
                "vill_name": "ນ້ຳປຸກ+ດອນໄຊ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50141",
                "vill_name": "ນ້ຳສະຫມອກໃຕ້",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50142",
                "vill_name": "ຝາຍ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50143",
                "vill_name": "ນ້ຳສະຫມອກເຫນືອ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50144",
                "vill_name": "ແລ້ງ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50145",
                "vill_name": "ຫ້ວຍຊາຍນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50146",
                "vill_name": "ຫ້ວຍທູຍ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50147",
                "vill_name": "ຫ້ວຍຈອດ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50148",
                "vill_name": "ໂພນໄຊ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50149",
                "vill_name": "ໂພນສະອາດ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50150",
                "vill_name": "ດ່ານຖິ່ນ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50151",
                "vill_name": "ດອນສະຫວັນ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50152",
                "vill_name": "ຄົກຫລວງ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50153",
                "vill_name": "ຫ້ວຍຕາບ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50154",
                "vill_name": "ຫ້ວຍມອນ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50155",
                "vill_name": "ຂອນແກ້ວ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50156",
                "vill_name": "ບໍ່ແກ້ວ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50157",
                "vill_name": "ມຸງຄຸນສີນ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50158",
                "vill_name": "ຫນອງຊາຍ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50159",
                "vill_name": "ມີໄຊສະຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50160",
                "vill_name": "ຫ້ວຍຊາຍໃຕ້",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50161",
                "vill_name": "ສີບຸນເຮືອງ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50162",
                "vill_name": "ມ່ວງແກ້ວ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50163",
                "vill_name": "ຫ້ວຍຊາຍເຫນືອ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50164",
                "vill_name": "ໃຫມ່ພູຄາ+ນາແລ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50165",
                "vill_name": "ທົ່ງແສງຈັນ+ຊຽງງາມ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50166",
                "vill_name": "ໃຫມ່ນິຍົມ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50167",
                "vill_name": "ນ້ຳຕ້ອຍ+ດອນແຍງ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50168",
                "vill_name": "ນ້ຳຈ້າງ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50169",
                "vill_name": "ໂພນທອງ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50170",
                "vill_name": "ບໍ່ເຫລັກ+ປາງປອດ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50171",
                "vill_name": "ປາງພູລຶບ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50172",
                "vill_name": "ພູຫວານໃຕ້",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50173",
                "vill_name": "ນ້ຳຕີນ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50174",
                "vill_name": "ພູຫວານເຫນືອ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50175",
                "vill_name": "ນ້ຳຕອງໄຕ້+ນ້ຳຕອງເໜືອ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50176",
                "vill_name": "ຫ້ວຍສາລາ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50177",
                "vill_name": "ຕີນທາດ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50178",
                "vill_name": "ປ່າອ້ອຍ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50179",
                "vill_name": "ສີນອຸດົມ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50180",
                "vill_name": "ປາກຮາວເຫນືອ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50181",
                "vill_name": "ມົກກະຈອກ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50182",
                "vill_name": "ນ້ຳຫໍ້ໃຕ້",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50183",
                "vill_name": "ພີບຸນທອງ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50184",
                "vill_name": "ປາກຮາວໃຕ້",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50185",
                "vill_name": "ວຽງໃຫມ່",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50186",
                "vill_name": "ດອນ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50187",
                "vill_name": "ນ້ຳຄຳໃຕ້",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50188",
                "vill_name": "ຈອມຈຸກ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50189",
                "vill_name": "ຊາຍຈະເລີນ",
                "vill_name_en": "",
                "dr_id": "501"
              },
              {
                "vill_id": "50190",
                "vill_name": "ໂພນສະຫວ່າງ",
                "vill_name_en": "Phonsavang",
                "dr_id": "501"
              }
            ]
          },
          {
            "dr_id": "502",
            "dr_name": "ຕົ້ນເຜິ້ງ",
            "dr_name_en": "Tongpheung",
            "pr_id": "5",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "50201",
                "vill_name": "ມ່ວງຄຳ",
                "vill_name_en": "",
                "dr_id": "502"
              },
              {
                "vill_id": "50202",
                "vill_name": "ເຂດເສດຖະກິດພິເສດສາມຫລຽມຄຳ",
                "vill_name_en": "",
                "dr_id": "502"
              },
              {
                "vill_id": "50203",
                "vill_name": "ສີບຸນເຮືອງ",
                "vill_name_en": "",
                "dr_id": "502"
              },
              {
                "vill_id": "50204",
                "vill_name": "ມົກກະຈອກ",
                "vill_name_en": "",
                "dr_id": "502"
              },
              {
                "vill_id": "50205",
                "vill_name": "ມົກກະຈອກ",
                "vill_name_en": "",
                "dr_id": "502"
              },
              {
                "vill_id": "50206",
                "vill_name": "ພຽງງາມ",
                "vill_name_en": "",
                "dr_id": "502"
              },
              {
                "vill_id": "50207",
                "vill_name": "ພຽງສະຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "502"
              },
              {
                "vill_id": "50208",
                "vill_name": "ດອນມູນ",
                "vill_name_en": "",
                "dr_id": "502"
              },
              {
                "vill_id": "50209",
                "vill_name": "ໂພນໂຮມ",
                "vill_name_en": "",
                "dr_id": "502"
              },
              {
                "vill_id": "50210",
                "vill_name": "ຕົ້ນເຜິ້ງ",
                "vill_name_en": "",
                "dr_id": "502"
              },
              {
                "vill_id": "50211",
                "vill_name": "ວຽງສະຫວັນ",
                "vill_name_en": "",
                "dr_id": "502"
              },
              {
                "vill_id": "50212",
                "vill_name": "ພອນສະຫວັນ",
                "vill_name_en": "",
                "dr_id": "502"
              },
              {
                "vill_id": "50213",
                "vill_name": "ດອນສະຫວັນ",
                "vill_name_en": "",
                "dr_id": "502"
              },
              {
                "vill_id": "50214",
                "vill_name": "ຮົມເຢັນ",
                "vill_name_en": "",
                "dr_id": "502"
              },
              {
                "vill_id": "50215",
                "vill_name": "ດອນທາດ",
                "vill_name_en": "",
                "dr_id": "502"
              },
              {
                "vill_id": "50216",
                "vill_name": "ສີມຸງຄຸນ",
                "vill_name_en": "",
                "dr_id": "502"
              },
              {
                "vill_id": "50217",
                "vill_name": "ສີເມືອງງາມກາງ",
                "vill_name_en": "",
                "dr_id": "502"
              },
              {
                "vill_id": "50218",
                "vill_name": "ສີເມືອງງາມໃຕ້",
                "vill_name_en": "",
                "dr_id": "502"
              },
              {
                "vill_id": "50219",
                "vill_name": "ສີດອນຄູນ",
                "vill_name_en": "",
                "dr_id": "502"
              },
              {
                "vill_id": "50220",
                "vill_name": "ນ້ຳແງ້ນ",
                "vill_name_en": "",
                "dr_id": "502"
              },
              {
                "vill_id": "50221",
                "vill_name": "ດອນແກ້ວ",
                "vill_name_en": "",
                "dr_id": "502"
              },
              {
                "vill_id": "50222",
                "vill_name": "ຄູນບົງ",
                "vill_name_en": "",
                "dr_id": "502"
              },
              {
                "vill_id": "50223",
                "vill_name": "ສາມສິບ",
                "vill_name_en": "",
                "dr_id": "502"
              },
              {
                "vill_id": "50224",
                "vill_name": "ນ້ຳເກິ່ງເກົ່າ",
                "vill_name_en": "",
                "dr_id": "502"
              },
              {
                "vill_id": "50225",
                "vill_name": "ນ້ຳເກິ່ງໃຫມ່",
                "vill_name_en": "",
                "dr_id": "502"
              },
              {
                "vill_id": "50226",
                "vill_name": "ສີດອນໄຊ",
                "vill_name_en": "",
                "dr_id": "502"
              },
              {
                "vill_id": "50228",
                "vill_name": "ດອຍແດງ",
                "vill_name_en": "",
                "dr_id": "502"
              },
              {
                "vill_id": "50229",
                "vill_name": "ສີທາດ",
                "vill_name_en": "",
                "dr_id": "502"
              },
              {
                "vill_id": "50230",
                "vill_name": "ຕ້າງອໍ",
                "vill_name_en": "",
                "dr_id": "502"
              },
              {
                "vill_id": "50231",
                "vill_name": "ຫລວງສີງໄຈ",
                "vill_name_en": "",
                "dr_id": "502"
              },
              {
                "vill_id": "50232",
                "vill_name": "ນ້ຳຟ້າ",
                "vill_name_en": "",
                "dr_id": "502"
              },
              {
                "vill_id": "50233",
                "vill_name": "ຢາງຄຳ",
                "vill_name_en": "",
                "dr_id": "502"
              },
              {
                "vill_id": "50234",
                "vill_name": "ດອຍງາມ",
                "vill_name_en": "",
                "dr_id": "502"
              },
              {
                "vill_id": "50235",
                "vill_name": "ສີດອນມີ",
                "vill_name_en": "",
                "dr_id": "502"
              },
              {
                "vill_id": "50236",
                "vill_name": "ຫ້ວຍຕ້າງ",
                "vill_name_en": "",
                "dr_id": "502"
              },
              {
                "vill_id": "50237",
                "vill_name": "ມອມ",
                "vill_name_en": "",
                "dr_id": "502"
              },
              {
                "vill_id": "50238",
                "vill_name": "ຫ້ວຍເຜິ້ງ",
                "vill_name_en": "",
                "dr_id": "502"
              },
              {
                "vill_id": "50239",
                "vill_name": "ຫ້ວຍຫອນ",
                "vill_name_en": "",
                "dr_id": "502"
              },
              {
                "vill_id": "50240",
                "vill_name": "ຫ້ວຍລົມ",
                "vill_name_en": "",
                "dr_id": "502"
              },
              {
                "vill_id": "50241",
                "vill_name": "ຫ້ວຍໄຄ້",
                "vill_name_en": "",
                "dr_id": "502"
              },
              {
                "vill_id": "50242",
                "vill_name": "ດອນເງິນ",
                "vill_name_en": "",
                "dr_id": "502"
              },
              {
                "vill_id": "50243",
                "vill_name": "ນາຄຳ",
                "vill_name_en": "",
                "dr_id": "502"
              },
              {
                "vill_id": "50244",
                "vill_name": "ໃຫມ່ພັດທະນາ",
                "vill_name_en": "",
                "dr_id": "502"
              },
              {
                "vill_id": "50245",
                "vill_name": "ປ່າສັກ",
                "vill_name_en": "",
                "dr_id": "502"
              },
              {
                "vill_id": "50246",
                "vill_name": "ກໍ່ສ້າງ",
                "vill_name_en": "",
                "dr_id": "502"
              },
              {
                "vill_id": "50247",
                "vill_name": "ຢາງໄຕ້",
                "vill_name_en": "",
                "dr_id": "502"
              },
              {
                "vill_id": "50248",
                "vill_name": "ຫ້ວຍອົງ",
                "vill_name_en": "",
                "dr_id": "502"
              },
              {
                "vill_id": "50249",
                "vill_name": "ເບີນຶ່ງ",
                "vill_name_en": "",
                "dr_id": "502"
              },
              {
                "vill_id": "50250",
                "vill_name": "ບົວຈະເລີນ",
                "vill_name_en": "",
                "dr_id": "502"
              },
              {
                "vill_id": "50251",
                "vill_name": "ນ້ຳຕີ",
                "vill_name_en": "",
                "dr_id": "502"
              }
            ]
          },
          {
            "dr_id": "503",
            "dr_name": "ເມິງ",
            "dr_name_en": "Meung",
            "pr_id": "5",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "50301",
                "vill_name": "ນ້ຳເມີງ",
                "vill_name_en": "",
                "dr_id": "503"
              },
              {
                "vill_id": "50302",
                "vill_name": "ໂພນສະຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "503"
              },
              {
                "vill_id": "50303",
                "vill_name": "ໂພນຈະເລີນ",
                "vill_name_en": "",
                "dr_id": "503"
              },
              {
                "vill_id": "50304",
                "vill_name": "ຫ້ວຍທາດ",
                "vill_name_en": "",
                "dr_id": "503"
              },
              {
                "vill_id": "50305",
                "vill_name": "ຊຽງດາວ",
                "vill_name_en": "",
                "dr_id": "503"
              },
              {
                "vill_id": "50306",
                "vill_name": "ຫມອນແຫລມ",
                "vill_name_en": "",
                "dr_id": "503"
              },
              {
                "vill_id": "50307",
                "vill_name": "ຈອມແສງ",
                "vill_name_en": "",
                "dr_id": "503"
              },
              {
                "vill_id": "50308",
                "vill_name": "ຫ້ວຍອໍ້(ພະຍາຫລວງ)",
                "vill_name_en": "",
                "dr_id": "503"
              },
              {
                "vill_id": "50309",
                "vill_name": "ຕໍແລ",
                "vill_name_en": "",
                "dr_id": "503"
              },
              {
                "vill_id": "50310",
                "vill_name": "ຈອມແຈ້ງ",
                "vill_name_en": "",
                "dr_id": "503"
              },
              {
                "vill_id": "50311",
                "vill_name": "ດອນມູນ",
                "vill_name_en": "",
                "dr_id": "503"
              },
              {
                "vill_id": "50312",
                "vill_name": "ຜາດຳ",
                "vill_name_en": "",
                "dr_id": "503"
              },
              {
                "vill_id": "50313",
                "vill_name": "ຜາຂາວ",
                "vill_name_en": "",
                "dr_id": "503"
              },
              {
                "vill_id": "50314",
                "vill_name": "ຫ້ວຍສ້ານ",
                "vill_name_en": "",
                "dr_id": "503"
              },
              {
                "vill_id": "50315",
                "vill_name": "ຫົວນ້ຳຄາ",
                "vill_name_en": "",
                "dr_id": "503"
              },
              {
                "vill_id": "50316",
                "vill_name": "ປຸງຜາ",
                "vill_name_en": "",
                "dr_id": "503"
              },
              {
                "vill_id": "50317",
                "vill_name": "ປຸ່ງເລາະ",
                "vill_name_en": "",
                "dr_id": "503"
              },
              {
                "vill_id": "50318",
                "vill_name": "ນ້ຳຄາ",
                "vill_name_en": "",
                "dr_id": "503"
              },
              {
                "vill_id": "50319",
                "vill_name": "ນາງາມ+ປຸງຫີນ",
                "vill_name_en": "",
                "dr_id": "503"
              },
              {
                "vill_id": "50320",
                "vill_name": "ໄຊສະຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "503"
              },
              {
                "vill_id": "50321",
                "vill_name": "ລ້ອງພະບາດ",
                "vill_name_en": "",
                "dr_id": "503"
              },
              {
                "vill_id": "50322",
                "vill_name": "ຫ້ວຍດອກທອງ",
                "vill_name_en": "",
                "dr_id": "503"
              },
              {
                "vill_id": "50323",
                "vill_name": "ຖ້ຳພະບາດ",
                "vill_name_en": "",
                "dr_id": "503"
              },
              {
                "vill_id": "50324",
                "vill_name": "ໄຊພັດທະນາ",
                "vill_name_en": "",
                "dr_id": "503"
              },
              {
                "vill_id": "50325",
                "vill_name": "ໄຊຈະເລີນ",
                "vill_name_en": "",
                "dr_id": "503"
              },
              {
                "vill_id": "50326",
                "vill_name": "ສາລີເຮືອງ",
                "vill_name_en": "",
                "dr_id": "503"
              },
              {
                "vill_id": "50327",
                "vill_name": "ນ້ຳຮອກ",
                "vill_name_en": "",
                "dr_id": "503"
              }
            ]
          },
          {
            "dr_id": "504",
            "dr_name": "ຜາອຸດົມ",
            "dr_name_en": "Phaoudom",
            "pr_id": "5",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "50401",
                "vill_name": "ຫາດ​ຄຳ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50402",
                "vill_name": "ດອນ​ໃຈ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50403",
                "vill_name": "ຫາດ​ລີ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50404",
                "vill_name": "ປາກ​ຫາດ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50405",
                "vill_name": "ດອນ​ແກ້ວ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50406",
                "vill_name": "ປາງ​ທອງ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50407",
                "vill_name": "ວັງ​ປອ້ມ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50408",
                "vill_name": "ຫາດ​ໜວກ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50409",
                "vill_name": "ຫ້ວຍ​ບົງ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50410",
                "vill_name": "ລັງ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50411",
                "vill_name": "ນ້ຳ​ຍາວ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50412",
                "vill_name": "ນາ​ໂຮມ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50413",
                "vill_name": "ຫ້ວຍ​ແປນ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50414",
                "vill_name": "ຕິ​ນຜາ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50415",
                "vill_name": "ທົ່ງ​ກາງ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50416",
                "vill_name": "ຫາດ​ໂຊນ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50417",
                "vill_name": "ຫາດ​ຫອມ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50418",
                "vill_name": "ຫາດ​ໜາມ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50419",
                "vill_name": "ວັງ​ເຫລັກ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50420",
                "vill_name": "ລະ​ອາງ​ໃຕ້",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50421",
                "vill_name": "ລະ​ອາງ​ເຫນືອ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50422",
                "vill_name": "ຫາງດອຍ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50423",
                "vill_name": "ຕະ​ກຸ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50424",
                "vill_name": "ສະ​ຕຸ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50425",
                "vill_name": "ຜາ​ຊ້າງ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50426",
                "vill_name": "ບ່ອງ​ເທິງ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50427",
                "vill_name": "ຕະ​ຄອງ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50428",
                "vill_name": "ຕະ​ແຄນ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50429",
                "vill_name": "ໂພນ​ທອງ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50430",
                "vill_name": "ໂຮມ​ພອນ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50431",
                "vill_name": "ຈອມ​ແກ້ວ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50432",
                "vill_name": "ສະ​ມີນ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50433",
                "vill_name": "ຈອມ​ໄຊ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50434",
                "vill_name": "ລະ​ຄອຍ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50435",
                "vill_name": "ສຸກ​ຈະ​ເລີນ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50436",
                "vill_name": "ຕະ​ພູ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50437",
                "vill_name": "ກ້ອນ​ຊອດ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50438",
                "vill_name": "ຖາ​ວອນ​ໄຊ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50439",
                "vill_name": "ປຸງ​ລາດ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50441",
                "vill_name": "ຄຳ​ພອງ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50442",
                "vill_name": "ຄະ​ໜອງ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50443",
                "vill_name": "ກະລົມ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50444",
                "vill_name": "ເດື່ອ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50445",
                "vill_name": "ໃໝ່",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50446",
                "vill_name": "ເພັງ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50447",
                "vill_name": "ພູສູງ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50448",
                "vill_name": "ກາງ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50449",
                "vill_name": "ໄຊສະຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50450",
                "vill_name": "ນາ​ຄູນ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50451",
                "vill_name": "ນາ​ໂຄງ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50453",
                "vill_name": "ໂພນ​ວິ​ໄລ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50454",
                "vill_name": "ນ້ຳຄາ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50455",
                "vill_name": "ໂຮມ​ສຸກ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50456",
                "vill_name": "ມົກ​ຄະ​ກາງ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50457",
                "vill_name": "ຫ້ວຍ​ສັງ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50458",
                "vill_name": "ຫ້້​ວຍ​ກຸນ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50459",
                "vill_name": "ແກ່ນຄຳ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50460",
                "vill_name": "ວຽງ​ຄຳໃຫຍ່",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50461",
                "vill_name": "ມົກໂຊ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50462",
                "vill_name": "ພູວຽງ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50463",
                "vill_name": "ສີບຸນເຮືອງ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50464",
                "vill_name": "ໂພນໄຊ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50466",
                "vill_name": "ຜາອຸດົມ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50467",
                "vill_name": "ສົມສະຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50468",
                "vill_name": "ບ້ານຖີ່ນແກ້ວ​ເຫືນອ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50469",
                "vill_name": "ພຽງຄຳ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50470",
                "vill_name": "ວຽງ​ຄຳ​ນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50471",
                "vill_name": "ມົກສຸກ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50472",
                "vill_name": "ມົົກ​ປອນ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50473",
                "vill_name": "ມົົກ​ຄະ​ເທີງ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50474",
                "vill_name": "ພຽງ​ຫາດ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50475",
                "vill_name": "ພູູ​ແກ້ວ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50476",
                "vill_name": "ບວກ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50477",
                "vill_name": "ດອນ​ຄຳ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50478",
                "vill_name": "ຂວາງ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50479",
                "vill_name": "ວຽງ​ໄຊ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50480",
                "vill_name": "ວຽງ​ພັດ​ທະ​ນາ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50481",
                "vill_name": "ເຍັງກົກ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50482",
                "vill_name": "ໄຊອຸດົມ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50483",
                "vill_name": "ສົົມ​ສະ​ໜຸກ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50484",
                "vill_name": "ນາ​ຕຸ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50485",
                "vill_name": "ຄາກ​ຄຳ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50486",
                "vill_name": "ພູ​ວຽງ​ໄຊ",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50487",
                "vill_name": "ຖ້ຳປ່າແກ່",
                "vill_name_en": "",
                "dr_id": "504"
              },
              {
                "vill_id": "50488",
                "vill_name": "ບ້ານເກີນ",
                "vill_name_en": "Keun",
                "dr_id": "504"
              }
            ]
          },
          {
            "dr_id": "505",
            "dr_name": "ປາກທາ",
            "dr_name_en": "Paktha",
            "pr_id": "5",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "50501",
                "vill_name": "ຫາດສະ",
                "vill_name_en": "",
                "dr_id": "505"
              },
              {
                "vill_id": "50502",
                "vill_name": "ປາກທາ",
                "vill_name_en": "",
                "dr_id": "505"
              },
              {
                "vill_id": "50503",
                "vill_name": "ຫ້ວຍຄີວ",
                "vill_name_en": "",
                "dr_id": "505"
              },
              {
                "vill_id": "50504",
                "vill_name": "ດອນສະຫວັນ",
                "vill_name_en": "",
                "dr_id": "505"
              },
              {
                "vill_id": "50505",
                "vill_name": "ຫ້ວຍທອງ",
                "vill_name_en": "",
                "dr_id": "505"
              },
              {
                "vill_id": "50506",
                "vill_name": "ຫ້ວຍຊົວະ",
                "vill_name_en": "",
                "dr_id": "505"
              },
              {
                "vill_id": "50507",
                "vill_name": "ຫ້ວຍລຳແຜນ",
                "vill_name_en": "",
                "dr_id": "505"
              },
              {
                "vill_id": "50508",
                "vill_name": "ຫ້ວຍຫນໍ່ຂົມ",
                "vill_name_en": "",
                "dr_id": "505"
              },
              {
                "vill_id": "50509",
                "vill_name": "ຫ້ວຍສະງຶກ",
                "vill_name_en": "",
                "dr_id": "505"
              },
              {
                "vill_id": "50510",
                "vill_name": "ຫ້ວຍພະລາມ",
                "vill_name_en": "",
                "dr_id": "505"
              },
              {
                "vill_id": "50511",
                "vill_name": "ຫ້ວຍແມງ",
                "vill_name_en": "",
                "dr_id": "505"
              },
              {
                "vill_id": "50512",
                "vill_name": "ຫ້ວຍສາກເທິງ",
                "vill_name_en": "",
                "dr_id": "505"
              },
              {
                "vill_id": "50513",
                "vill_name": "ກິວລົມ",
                "vill_name_en": "",
                "dr_id": "505"
              },
              {
                "vill_id": "50514",
                "vill_name": "ຫາດດອນແກ້ວ",
                "vill_name_en": "",
                "dr_id": "505"
              },
              {
                "vill_id": "50516",
                "vill_name": "ຫ້ວຍແອນ",
                "vill_name_en": "",
                "dr_id": "505"
              },
              {
                "vill_id": "50517",
                "vill_name": "ຫ້ວຍຮາວ",
                "vill_name_en": "",
                "dr_id": "505"
              },
              {
                "vill_id": "50518",
                "vill_name": "ກ້ອນຕື້ນ",
                "vill_name_en": "",
                "dr_id": "505"
              },
              {
                "vill_id": "50519",
                "vill_name": "ແກ້ງພາກ",
                "vill_name_en": "",
                "dr_id": "505"
              },
              {
                "vill_id": "50520",
                "vill_name": "ຫ້ວຍໄມ້ຊາງ",
                "vill_name_en": "",
                "dr_id": "505"
              },
              {
                "vill_id": "50521",
                "vill_name": "ປາກຊົວະ",
                "vill_name_en": "",
                "dr_id": "505"
              },
              {
                "vill_id": "50522",
                "vill_name": "ປາງສາ",
                "vill_name_en": "",
                "dr_id": "505"
              },
              {
                "vill_id": "50523",
                "vill_name": "ຫ້ວຍໂຄດ",
                "vill_name_en": "",
                "dr_id": "505"
              },
              {
                "vill_id": "50525",
                "vill_name": "ດົງ",
                "vill_name_en": "",
                "dr_id": "505"
              },
              {
                "vill_id": "50526",
                "vill_name": "ມົກກະຈອກ",
                "vill_name_en": "",
                "dr_id": "505"
              },
              {
                "vill_id": "50527",
                "vill_name": "ພູສະແຖນ",
                "vill_name_en": "",
                "dr_id": "505"
              },
              {
                "vill_id": "50528",
                "vill_name": "ປ່າດົງ",
                "vill_name_en": "",
                "dr_id": "505"
              },
              {
                "vill_id": "50529",
                "vill_name": "ຜາຄອຍ",
                "vill_name_en": "",
                "dr_id": "505"
              },
              {
                "vill_id": "50530",
                "vill_name": "ຫ້ວຍພະ",
                "vill_name_en": "",
                "dr_id": "505"
              },
              {
                "vill_id": "50531",
                "vill_name": "ດອນມີໄຊ",
                "vill_name_en": "",
                "dr_id": "505"
              },
              {
                "vill_id": "50532",
                "vill_name": "ແຄ",
                "vill_name_en": "",
                "dr_id": "505"
              },
              {
                "vill_id": "50533",
                "vill_name": "ໂພນທອງ",
                "vill_name_en": "",
                "dr_id": "505"
              },
              {
                "vill_id": "50534",
                "vill_name": "ຫາດສາກ",
                "vill_name_en": "",
                "dr_id": "505"
              },
              {
                "vill_id": "50536",
                "vill_name": "ຫົວນ້ຳ",
                "vill_name_en": "",
                "dr_id": "505"
              },
              {
                "vill_id": "50537",
                "vill_name": "ຫ້ວຍແສດ",
                "vill_name_en": "",
                "dr_id": "505"
              },
              {
                "vill_id": "50538",
                "vill_name": "ຈຽງຕອງ",
                "vill_name_en": "Jiengtong",
                "dr_id": "505"
              }
            ]
          }
        ]
      },
      {
        "pr_id": "6",
        "pr_name": "ຫຼວງພະບາງ",
        "pr_name_en": "Louang Phabang",
        "districts": [
          {"dr_name": "ເລືອກເມືອງປະຈຸບັນ", "villages": []},
          {
            "dr_id": "601",
            "dr_name": "ຫຼວງພະບາງ",
            "dr_name_en": "Luangprabang",
            "pr_id": "6",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "60101",
                "vill_name": "ຊຽງທອງ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60102",
                "vill_name": "ຊຽງທອງ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60103",
                "vill_name": "ໂພນເຮຶອງ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60104",
                "vill_name": "ຄີລີ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60105",
                "vill_name": "ວັດແສນ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60106",
                "vill_name": "ວັດຫນອງ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60107",
                "vill_name": "ຊຽງມ່ວນ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60108",
                "vill_name": "ຈູມຄ້ອງ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60109",
                "vill_name": "ປ່າຂາມ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60110",
                "vill_name": "ຂາມຍ່ອງ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60111",
                "vill_name": "ອາຮາມ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60112",
                "vill_name": "ອາໄພ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60113",
                "vill_name": "ວິຊຸນ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60114",
                "vill_name": "ຫມືນນາ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60115",
                "vill_name": "ທາດໂບສົດ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60116",
                "vill_name": "ວຽງໃຫມ່",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60117",
                "vill_name": "ນາສ້າງເຫີຍ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60118",
                "vill_name": "ຜາມົນ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60119",
                "vill_name": "ດອນແກ້ວ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60120",
                "vill_name": "ເມືອງງາ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60122",
                "vill_name": "ຜາສຸກ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60123",
                "vill_name": "ຫາດຮ່ຽນ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60124",
                "vill_name": "ທົງຈະເລີນ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60125",
                "vill_name": "ຫົວຊຽງ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60126",
                "vill_name": "ວັດທາດ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60127",
                "vill_name": "ທາດຫລວງ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60128",
                "vill_name": "ພະບາດ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60129",
                "vill_name": "ພະບາດ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60130",
                "vill_name": "ໂພສີ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60131",
                "vill_name": "ສາຍລົມ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60132",
                "vill_name": "ຫນອງຄຳ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60133",
                "vill_name": "ໂພນແພງ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60134",
                "vill_name": "ປ່ອງຄຳ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60135",
                "vill_name": "ມະໂນ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60136",
                "vill_name": "ວຽງໄຊ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60137",
                "vill_name": "ວຽງແກ້ວ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60138",
                "vill_name": "ນາວຽງຄຳ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60139",
                "vill_name": "ນາສຳພັນ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60140",
                "vill_name": "ນາຫລວງ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60141",
                "vill_name": "ຂົວທີ1",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60142",
                "vill_name": "ດອນກາງ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60143",
                "vill_name": "ມ້າ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60144",
                "vill_name": "ນາຊາງ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60145",
                "vill_name": "ພູຫມອກ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60146",
                "vill_name": "ຄອຍ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60147",
                "vill_name": "ສັງກະໂລ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60148",
                "vill_name": "ຊຽງແກ້ວ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60149",
                "vill_name": "ໂພນໄຊ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60150",
                "vill_name": "ຈອມງົວ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60151",
                "vill_name": "ນາເດືອຍ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60152",
                "vill_name": "ປົ່ງຫວ້ານ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60153",
                "vill_name": "ຫ້ວຍເອືອດ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60154",
                "vill_name": "ຫລັກ8",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60155",
                "vill_name": "ໂພນສະອາດ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60156",
                "vill_name": "ຊຽງເຫລັກ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60157",
                "vill_name": "ຊ່າງຄ້ອງ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60158",
                "vill_name": "ຫນອງຊາຍ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60159",
                "vill_name": "ໂຄມຂວາງ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60160",
                "vill_name": "ຜາໂຄມ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60161",
                "vill_name": "ຄົກຫວ່າ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60162",
                "vill_name": "ດອນເກົ່າ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60163",
                "vill_name": "ດອນໃຫມ່",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60164",
                "vill_name": "ພອນໄຊ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60165",
                "vill_name": "ຜາໂອ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60166",
                "vill_name": "ແສນສຸກ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60167",
                "vill_name": "ປາກເຊືອງ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60168",
                "vill_name": "ວຽງສະຫວັນ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60169",
                "vill_name": "ໂພນງາມ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60170",
                "vill_name": "ປາກປາ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60171",
                "vill_name": "ກອກຫວານ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60172",
                "vill_name": "ນາດອນຄູນ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60173",
                "vill_name": "ນາຕານ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60174",
                "vill_name": "ບໍແຫ້",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60175",
                "vill_name": "ທ່າອູ້ຍ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60176",
                "vill_name": "ຫ້ວຍເລິກ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60177",
                "vill_name": "ຜາແດງ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60178",
                "vill_name": "ຜາວຽງ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60179",
                "vill_name": "ລ້ອງລັນ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60180",
                "vill_name": "ໂພນສະຫວາດ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60181",
                "vill_name": "ແດນສະຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60182",
                "vill_name": "ຊຽງລ້ອມ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60183",
                "vill_name": "ພີກໃຫຍ່",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60184",
                "vill_name": "ພີກນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60185",
                "vill_name": "ນູູນສະຫວາດ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60186",
                "vill_name": "ກົກງິ້ວ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60187",
                "vill_name": "ຫລັກ10",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60188",
                "vill_name": "ນາໄຊ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60189",
                "vill_name": "ຊາດ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60190",
                "vill_name": "ຫ້ອຍທອງ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60191",
                "vill_name": "ຊຽງມວກ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60192",
                "vill_name": "ປ່າຫນໍ້",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60193",
                "vill_name": "ຕີນຜາ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60194",
                "vill_name": "ຫນອງໂຕກ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60195",
                "vill_name": "ນາຊ່າວ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60196",
                "vill_name": "ນາໂພ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60197",
                "vill_name": "ສີງ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60198",
                "vill_name": "ຖິ່ນຊົມ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60199",
                "vill_name": "ເມືອງຂາຍ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "60209",
                "vill_name": "ນາອວນ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "601200",
                "vill_name": "ອູ້",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "601213",
                "vill_name": "ປາກເພາະ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "601214",
                "vill_name": "ຫນອງຊາຍ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "601215",
                "vill_name": "ຄົກປາ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "601216",
                "vill_name": "ຫ້ວຍເຈ້ຍ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "601217",
                "vill_name": "ຫ້ວຍຫ່ານ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "601218",
                "vill_name": "ຫ້ວຍລ້ອງ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "601219",
                "vill_name": "ພູຊ້າງຄຳ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "601220",
                "vill_name": "ບ ພູເຫລັກ ຈະເລີນ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "601221",
                "vill_name": "ໜອງບົວ​ຄຳ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "601222",
                "vill_name": "ແອນສະຫວັນ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "601223",
                "vill_name": "ລ້ອງອໍ້",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "601224",
                "vill_name": "ໜອງບົວ​ຄຳ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "602201",
                "vill_name": "ປາກຊີ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "602202",
                "vill_name": "ຄົກມັນ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "602203",
                "vill_name": "ທ່າແປ້ນ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "602204",
                "vill_name": "ຖິ່ນແກ້ວ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "602205",
                "vill_name": "ໂນນສະອາດ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "602206",
                "vill_name": "ຫນອງແຫ້ວ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "602207",
                "vill_name": "ລ້ອງເລົາ1",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "602208",
                "vill_name": "ລ້ອງເລົາ 2",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "602210",
                "vill_name": "ຢາງ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "602211",
                "vill_name": "ຫ້ອຍສ້ຽວ",
                "vill_name_en": "",
                "dr_id": "601"
              },
              {
                "vill_id": "602212",
                "vill_name": "ແຊນຄະລົກ",
                "vill_name_en": "",
                "dr_id": "601"
              }
            ]
          },
          {
            "dr_id": "602",
            "dr_name": "ຊຽງເງິນ",
            "dr_name_en": "Xiengngeun",
            "pr_id": "6",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "60201",
                "vill_name": "ແອນສະຫວັນ",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60202",
                "vill_name": "ລ້ອງອໍ້",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60203",
                "vill_name": "ສວນດາລາ",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60204",
                "vill_name": "ຫ້ວຍຝາຍ",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60205",
                "vill_name": "ສວນຫລວງ",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60206",
                "vill_name": "ບວມອໍ້",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60207",
                "vill_name": "ຫ້ວຍເຢັນ1",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60208",
                "vill_name": "ມູດ",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60210",
                "vill_name": "ມູດ",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60211",
                "vill_name": "ປາກແວດ",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60212",
                "vill_name": "ຫ້ວຍເທົາ",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60213",
                "vill_name": "ມູດ",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60214",
                "vill_name": "ປາກແວດ",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60215",
                "vill_name": "ຫ້ວຍເທົາ",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60216",
                "vill_name": "ປາກຂັນ",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60217",
                "vill_name": "ປາກທໍ່",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60218",
                "vill_name": "ນາຄ່າ",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60220",
                "vill_name": "ລ້ອງສ້ານ",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60221",
                "vill_name": "ຫ້ວຍແພງ",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60222",
                "vill_name": "ຫ້ວຍໂຄດ",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60223",
                "vill_name": "ຫ້ວຍຄັງ",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60224",
                "vill_name": "ໂພນສະຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60225",
                "vill_name": "ໂພນໄຊ",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60226",
                "vill_name": "ຫ້ວຍໂຄ້ງ",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60227",
                "vill_name": "ຊຽງເງິນ",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60228",
                "vill_name": "ດອນໂມ",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60229",
                "vill_name": "ຫ້ວຍສະທັນ",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60230",
                "vill_name": "ຄອນຫວາຍ",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60231",
                "vill_name": "ຫ້ວຍເຮັ້ຍ",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60232",
                "vill_name": "ກິ່ວຈະລວງ",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60233",
                "vill_name": "ພູຂວາ",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60234",
                "vill_name": "ພູຂວາ",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60235",
                "vill_name": "ກິ່ວຫຍ້າ",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60236",
                "vill_name": "ກິ່ວຫມາກນາວ1",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60237",
                "vill_name": "ກິ່ວຫມາກນາວ2",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60238",
                "vill_name": "ແສນອຸດົມ",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60239",
                "vill_name": "ຖິ່ນແກ້ວ ",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60240",
                "vill_name": "ຖິ່ນແກ້ວ ",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60241",
                "vill_name": "ເບີ10",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60242",
                "vill_name": "ເບີ11(ບ.ຕາດກະຈຳ)",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60243",
                "vill_name": "ສີລາເລກ",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60244",
                "vill_name": "ສີລາເລກ",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60245",
                "vill_name": "ສີລາເລກ",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60246",
                "vill_name": "ສີລາເລກ",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60247",
                "vill_name": "ຫ້ວຍກະສັກ",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60248",
                "vill_name": "ຫ້ວຍກະສັກ",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60249",
                "vill_name": "ວຽງໄຊ",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60250",
                "vill_name": "ກອກ",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60251",
                "vill_name": "ປາກໂປງ",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60252",
                "vill_name": "ມົກກະປາງ",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60253",
                "vill_name": "ປາກແຟນ",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60254",
                "vill_name": "ສະເພີນ",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60255",
                "vill_name": "ຖິ່ນ",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60256",
                "vill_name": "ປາກຊ້າ",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60257",
                "vill_name": "ແກ້ງກຸ້ງ",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60258",
                "vill_name": "ໜອງດີ",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60259",
                "vill_name": "ສະມຸ່ຍ",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60260",
                "vill_name": "ບໍ່ຊືນ",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60261",
                "vill_name": "ດ່ານ",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60262",
                "vill_name": "ສົບຈູນ",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60263",
                "vill_name": "ຂົວນ້ຳມີງ",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60264",
                "vill_name": "ປາກສະນານ",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60265",
                "vill_name": "ຫ້ວຍຮ່າວ",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60266",
                "vill_name": "ກິ່ວຕະລັນເທິງ",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60267",
                "vill_name": "ພູດຳ",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60268",
                "vill_name": "ກິ່ວກະຈຳ",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60269",
                "vill_name": "ກິ່ວຕະລຸນ I",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60270",
                "vill_name": "ຫນອງໂອນ",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60271",
                "vill_name": "ກິ່ວຂາມປ້ອມ",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60272",
                "vill_name": "ນ້ຳມົກ",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60273",
                "vill_name": "ພູທາດ",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60274",
                "vill_name": "ຜາສະນິນ",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60275",
                "vill_name": "ກິ່ວໄມລໍ",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60276",
                "vill_name": "ລ້ອງເກົ່າ",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60277",
                "vill_name": "ໂພນສະອາດ",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60278",
                "vill_name": "ຫ້ວຍກຸກ",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60279",
                "vill_name": "ແກ້ງກີບ",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60280",
                "vill_name": "ຫ້ວຍຊຽງ",
                "vill_name_en": "",
                "dr_id": "602"
              },
              {
                "vill_id": "60281",
                "vill_name": "ຜາທຸງ",
                "vill_name_en": "phathoung",
                "dr_id": "602"
              },
              {
                "vill_id": "60282",
                "vill_name": "ປ່າຫຍ້າຄາ",
                "vill_name_en": "Payakha",
                "dr_id": "602"
              },
              {
                "vill_id": "60283",
                "vill_name": "ບ້ານນ້ຳລິນ",
                "vill_name_en": "Namlin",
                "dr_id": "602"
              },
              {
                "vill_id": "60284",
                "vill_name": "ຕາດກະຈຳ",
                "vill_name_en": "​​​Tardkacham",
                "dr_id": "602"
              }
            ]
          },
          {
            "dr_id": "603",
            "dr_name": "ນານ",
            "dr_name_en": "Nan",
            "pr_id": "6",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "60301",
                "vill_name": "ສີມຸງຄຸນ",
                "vill_name_en": "",
                "dr_id": "603"
              },
              {
                "vill_id": "60302",
                "vill_name": "ສີບຸນເຮືອງ",
                "vill_name_en": "",
                "dr_id": "603"
              },
              {
                "vill_id": "60303",
                "vill_name": "ປ່າໄຜ່",
                "vill_name_en": "",
                "dr_id": "603"
              },
              {
                "vill_id": "60304",
                "vill_name": "ໂພນຫີນ",
                "vill_name_en": "",
                "dr_id": "603"
              },
              {
                "vill_id": "60305",
                "vill_name": "ທາດ",
                "vill_name_en": "",
                "dr_id": "603"
              },
              {
                "vill_id": "60306",
                "vill_name": "ໂພນ",
                "vill_name_en": "",
                "dr_id": "603"
              },
              {
                "vill_id": "60307",
                "vill_name": "ນາເຄີນ",
                "vill_name_en": "",
                "dr_id": "603"
              },
              {
                "vill_id": "60308",
                "vill_name": "ປາກຄອນ",
                "vill_name_en": "",
                "dr_id": "603"
              },
              {
                "vill_id": "60309",
                "vill_name": "ຫ້ວຍຫລາ",
                "vill_name_en": "",
                "dr_id": "603"
              },
              {
                "vill_id": "60310",
                "vill_name": "ທົ່ງພຽງວິໄລ",
                "vill_name_en": "",
                "dr_id": "603"
              },
              {
                "vill_id": "60311",
                "vill_name": "ສີວິໄລ",
                "vill_name_en": "",
                "dr_id": "603"
              },
              {
                "vill_id": "60312",
                "vill_name": "ດອນຕູມ",
                "vill_name_en": "",
                "dr_id": "603"
              },
              {
                "vill_id": "60313",
                "vill_name": "ປ່າເຜິ່ນ",
                "vill_name_en": "",
                "dr_id": "603"
              },
              {
                "vill_id": "60314",
                "vill_name": "ນາເລົາ",
                "vill_name_en": "",
                "dr_id": "603"
              },
              {
                "vill_id": "60315",
                "vill_name": "ນາຝາຍ",
                "vill_name_en": "",
                "dr_id": "603"
              },
              {
                "vill_id": "60316",
                "vill_name": "ຜາຢວກ",
                "vill_name_en": "",
                "dr_id": "603"
              },
              {
                "vill_id": "60317",
                "vill_name": "ຫ້ວຍເຮັ້ຍ",
                "vill_name_en": "",
                "dr_id": "603"
              },
              {
                "vill_id": "60318",
                "vill_name": "ຜາຫນີບ",
                "vill_name_en": "",
                "dr_id": "603"
              },
              {
                "vill_id": "60319",
                "vill_name": "ປົ່ງດົງ",
                "vill_name_en": "",
                "dr_id": "603"
              },
              {
                "vill_id": "60320",
                "vill_name": "ນາເມືອງໃຫຍ່",
                "vill_name_en": "",
                "dr_id": "603"
              },
              {
                "vill_id": "60321",
                "vill_name": "ນາເມືອງກາງ",
                "vill_name_en": "",
                "dr_id": "603"
              },
              {
                "vill_id": "60322",
                "vill_name": "ຫ້ວຍລາດ",
                "vill_name_en": "",
                "dr_id": "603"
              },
              {
                "vill_id": "60323",
                "vill_name": "ທົ່ງຄັງ",
                "vill_name_en": "",
                "dr_id": "603"
              },
              {
                "vill_id": "60324",
                "vill_name": "ຜາຕ້ອງລົມ",
                "vill_name_en": "",
                "dr_id": "603"
              },
              {
                "vill_id": "60325",
                "vill_name": "ດ່ານ",
                "vill_name_en": "",
                "dr_id": "603"
              },
              {
                "vill_id": "60326",
                "vill_name": "ຫ້ວຍຫອຍ",
                "vill_name_en": "",
                "dr_id": "603"
              },
              {
                "vill_id": "60327",
                "vill_name": "ນ້ຳພັກ",
                "vill_name_en": "",
                "dr_id": "603"
              },
              {
                "vill_id": "60328",
                "vill_name": "ຫ້ວຍແມະ",
                "vill_name_en": "",
                "dr_id": "603"
              },
              {
                "vill_id": "60329",
                "vill_name": "ຫ້ວຍອຸ່ນ",
                "vill_name_en": "",
                "dr_id": "603"
              },
              {
                "vill_id": "60330",
                "vill_name": "ຕູຫໍ້",
                "vill_name_en": "",
                "dr_id": "603"
              },
              {
                "vill_id": "60331",
                "vill_name": "ທ່າລີ່",
                "vill_name_en": "",
                "dr_id": "603"
              },
              {
                "vill_id": "60332",
                "vill_name": "ຜາກວາງ",
                "vill_name_en": "",
                "dr_id": "603"
              },
              {
                "vill_id": "60334",
                "vill_name": "ຫ້ວຍສາລາ",
                "vill_name_en": "",
                "dr_id": "603"
              },
              {
                "vill_id": "60335",
                "vill_name": "ຄົກຕົມ",
                "vill_name_en": "",
                "dr_id": "603"
              },
              {
                "vill_id": "60336",
                "vill_name": "ຫາດຊາຍຄຳ",
                "vill_name_en": "",
                "dr_id": "603"
              },
              {
                "vill_id": "60337",
                "vill_name": "ຄໍແລ",
                "vill_name_en": "",
                "dr_id": "603"
              },
              {
                "vill_id": "60338",
                "vill_name": "ນາລິນ",
                "vill_name_en": "",
                "dr_id": "603"
              },
              {
                "vill_id": "60339",
                "vill_name": "ນານວນ",
                "vill_name_en": "",
                "dr_id": "603"
              },
              {
                "vill_id": "60340",
                "vill_name": "ພູຈອງ",
                "vill_name_en": "",
                "dr_id": "603"
              },
              {
                "vill_id": "60341",
                "vill_name": "ຫ້ວຍຄົງ",
                "vill_name_en": "",
                "dr_id": "603"
              },
              {
                "vill_id": "60342",
                "vill_name": "ປາກມ່ອນ",
                "vill_name_en": "",
                "dr_id": "603"
              },
              {
                "vill_id": "60343",
                "vill_name": "ປາກລ້ານ",
                "vill_name_en": "",
                "dr_id": "603"
              },
              {
                "vill_id": "60344",
                "vill_name": "ແກ້ວມະນີ",
                "vill_name_en": "",
                "dr_id": "603"
              },
              {
                "vill_id": "60345",
                "vill_name": "ໂພນໄຊ",
                "vill_name_en": "",
                "dr_id": "603"
              },
              {
                "vill_id": "60346",
                "vill_name": "ໂພນສີ",
                "vill_name_en": "",
                "dr_id": "603"
              },
              {
                "vill_id": "60347",
                "vill_name": "ພອນສະຫວັນ",
                "vill_name_en": "",
                "dr_id": "603"
              },
              {
                "vill_id": "60348",
                "vill_name": "ຫ້ວຍຜັກເຫນົາ",
                "vill_name_en": "",
                "dr_id": "603"
              },
              {
                "vill_id": "60349",
                "vill_name": "ປາກເນີນ",
                "vill_name_en": "",
                "dr_id": "603"
              },
              {
                "vill_id": "60350",
                "vill_name": "ປາກບໍ່",
                "vill_name_en": "",
                "dr_id": "603"
              },
              {
                "vill_id": "60351",
                "vill_name": "ໜ໐ເູປ",
                "vill_name_en": "",
                "dr_id": "603"
              },
              {
                "vill_id": "60352",
                "vill_name": "ກາງ",
                "vill_name_en": "",
                "dr_id": "603"
              },
              {
                "vill_id": "60353",
                "vill_name": "ຄົກໃຫຍ່",
                "vill_name_en": "",
                "dr_id": "603"
              },
              {
                "vill_id": "60354",
                "vill_name": "ຫ້ວຍຫີບ",
                "vill_name_en": "",
                "dr_id": "603"
              },
              {
                "vill_id": "60355",
                "vill_name": "ຫ້ວຍເດື່ອ",
                "vill_name_en": "",
                "dr_id": "603"
              },
              {
                "vill_id": "60356",
                "vill_name": "ຫ້ວຍກາງ",
                "vill_name_en": "",
                "dr_id": "603"
              },
              {
                "vill_id": "60357",
                "vill_name": "ຫາດແຄ",
                "vill_name_en": "",
                "dr_id": "603"
              },
              {
                "vill_id": "60358",
                "vill_name": "ຫ້ວຍເດື່ອ",
                "vill_name_en": "",
                "dr_id": "603"
              }
            ]
          },
          {
            "dr_id": "604",
            "dr_name": "ປາກອູ",
            "dr_name_en": "Parkou",
            "pr_id": "6",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "60401",
                "vill_name": "ຫ້ວຍເດື່ອ",
                "vill_name_en": "",
                "dr_id": "604"
              },
              {
                "vill_id": "60402",
                "vill_name": "ຫາດແຄ",
                "vill_name_en": "",
                "dr_id": "604"
              },
              {
                "vill_id": "60403",
                "vill_name": "ກຽດ",
                "vill_name_en": "",
                "dr_id": "604"
              },
              {
                "vill_id": "60404",
                "vill_name": "ທາດປາງ",
                "vill_name_en": "",
                "dr_id": "604"
              },
              {
                "vill_id": "60405",
                "vill_name": "ຫ້ວຍຫມາກ",
                "vill_name_en": "",
                "dr_id": "604"
              },
              {
                "vill_id": "60406",
                "vill_name": "ຫ້ວຍແລ",
                "vill_name_en": "",
                "dr_id": "604"
              },
              {
                "vill_id": "60407",
                "vill_name": "ຄົກຫັນ",
                "vill_name_en": "",
                "dr_id": "604"
              },
              {
                "vill_id": "60408",
                "vill_name": "ຫ້ວຍແປນ",
                "vill_name_en": "",
                "dr_id": "604"
              },
              {
                "vill_id": "60409",
                "vill_name": "ລາດກອກ",
                "vill_name_en": "",
                "dr_id": "604"
              },
              {
                "vill_id": "60410",
                "vill_name": "ຝາຍ",
                "vill_name_en": "",
                "dr_id": "604"
              },
              {
                "vill_id": "60411",
                "vill_name": "ຫ້ວຍເຫລືອງ",
                "vill_name_en": "",
                "dr_id": "604"
              },
              {
                "vill_id": "60412",
                "vill_name": "ຫາດຊວາ",
                "vill_name_en": "",
                "dr_id": "604"
              },
              {
                "vill_id": "60413",
                "vill_name": "ປາກແຈກ",
                "vill_name_en": "",
                "dr_id": "604"
              },
              {
                "vill_id": "60414",
                "vill_name": "ຫ້ວຍທູມ",
                "vill_name_en": "",
                "dr_id": "604"
              },
              {
                "vill_id": "60415",
                "vill_name": "ຢານາງ",
                "vill_name_en": "",
                "dr_id": "604"
              },
              {
                "vill_id": "60416",
                "vill_name": "ບວກຄວາຍ",
                "vill_name_en": "",
                "dr_id": "604"
              },
              {
                "vill_id": "60417",
                "vill_name": "ຜາປົ່ນ",
                "vill_name_en": "",
                "dr_id": "604"
              },
              {
                "vill_id": "60418",
                "vill_name": "ຫ້ວຍໂອດ",
                "vill_name_en": "",
                "dr_id": "604"
              },
              {
                "vill_id": "60419",
                "vill_name": "ຫ້ວຍເລີນ",
                "vill_name_en": "",
                "dr_id": "604"
              },
              {
                "vill_id": "60420",
                "vill_name": "ຄອນຄຳ",
                "vill_name_en": "",
                "dr_id": "604"
              },
              {
                "vill_id": "60421",
                "vill_name": "ຜາທຶງ",
                "vill_name_en": "",
                "dr_id": "604"
              },
              {
                "vill_id": "60422",
                "vill_name": "ລາດທະແຮ່",
                "vill_name_en": "",
                "dr_id": "604"
              },
              {
                "vill_id": "60423",
                "vill_name": "ຫ້ວຍຄ່າ",
                "vill_name_en": "",
                "dr_id": "604"
              },
              {
                "vill_id": "60424",
                "vill_name": "ນາສະຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "604"
              },
              {
                "vill_id": "60425",
                "vill_name": "ຫ້ວຍກອກ",
                "vill_name_en": "",
                "dr_id": "604"
              },
              {
                "vill_id": "60426",
                "vill_name": "ນານ້ອຍ",
                "vill_name_en": "",
                "dr_id": "604"
              },
              {
                "vill_id": "60427",
                "vill_name": "ຫ້ວຍກືນ",
                "vill_name_en": "",
                "dr_id": "604"
              },
              {
                "vill_id": "60428",
                "vill_name": "ມົກຈົງ",
                "vill_name_en": "",
                "dr_id": "604"
              },
              {
                "vill_id": "60429",
                "vill_name": "ຖ້ຳລຸ່ມ",
                "vill_name_en": "",
                "dr_id": "604"
              },
              {
                "vill_id": "60430",
                "vill_name": "ຫ້ວຍຄໍ້",
                "vill_name_en": "",
                "dr_id": "604"
              },
              {
                "vill_id": "60431",
                "vill_name": "ຫວ້ຍໂລນອກ",
                "vill_name_en": "",
                "dr_id": "604"
              },
              {
                "vill_id": "60432",
                "vill_name": "ຫາດຄໍ້",
                "vill_name_en": "",
                "dr_id": "604"
              },
              {
                "vill_id": "60433",
                "vill_name": "ສົມສະຫນຸກ",
                "vill_name_en": "",
                "dr_id": "604"
              },
              {
                "vill_id": "60434",
                "vill_name": "ຫາດຍາ",
                "vill_name_en": "",
                "dr_id": "604"
              },
              {
                "vill_id": "60435",
                "vill_name": "ຫ້ວຍຂາງ",
                "vill_name_en": "",
                "dr_id": "604"
              },
              {
                "vill_id": "60436",
                "vill_name": "ຄົກຂ້າມ",
                "vill_name_en": "",
                "dr_id": "604"
              },
              {
                "vill_id": "60437",
                "vill_name": "ຄົກ",
                "vill_name_en": "",
                "dr_id": "604"
              },
              {
                "vill_id": "60438",
                "vill_name": "ວັງແລ",
                "vill_name_en": "",
                "dr_id": "604"
              },
              {
                "vill_id": "60439",
                "vill_name": "ບ້ານຫ້ວຍມາດ",
                "vill_name_en": "",
                "dr_id": "604"
              },
              {
                "vill_id": "60440",
                "vill_name": "ປາກອູ",
                "vill_name_en": "",
                "dr_id": "604"
              },
              {
                "vill_id": "60441",
                "vill_name": "ຊ່າງໄຫ",
                "vill_name_en": "",
                "dr_id": "604"
              },
              {
                "vill_id": "60442",
                "vill_name": "ຫາດຄັ່ງ",
                "vill_name_en": "",
                "dr_id": "604"
              },
              {
                "vill_id": "60443",
                "vill_name": "ເຄຍລວງ",
                "vill_name_en": "",
                "dr_id": "604"
              },
              {
                "vill_id": "60444",
                "vill_name": "ແລສີວິໄລ",
                "vill_name_en": "",
                "dr_id": "604"
              },
              {
                "vill_id": "60445",
                "vill_name": "ໂພນໂຮມ",
                "vill_name_en": "",
                "dr_id": "604"
              },
              {
                "vill_id": "60446",
                "vill_name": "ໂພນສະຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "604"
              },
              {
                "vill_id": "60447",
                "vill_name": "ສ້ຽວ",
                "vill_name_en": "",
                "dr_id": "604"
              },
              {
                "vill_id": "60448",
                "vill_name": "ຖິ່ນຈະເລີນ",
                "vill_name_en": "",
                "dr_id": "604"
              },
              {
                "vill_id": "60449",
                "vill_name": "ຫາດມາດ",
                "vill_name_en": "",
                "dr_id": "604"
              },
              {
                "vill_id": "60450",
                "vill_name": "ຫາດຂາມ",
                "vill_name_en": "",
                "dr_id": "604"
              },
              {
                "vill_id": "60451",
                "vill_name": "ໂນນສະຫວັນ",
                "vill_name_en": "",
                "dr_id": "604"
              },
              {
                "vill_id": "60452",
                "vill_name": "ມົກເຫຼັກ",
                "vill_name_en": "Mokleck",
                "dr_id": "604"
              },
              {
                "vill_id": "60453",
                "vill_name": "ບ້ານບໍແຫ້",
                "vill_name_en": "Borhea",
                "dr_id": "604"
              }
            ]
          },
          {
            "dr_id": "605",
            "dr_name": "ນ້ຳບາກ",
            "dr_name_en": "Nambak",
            "pr_id": "6",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "60501",
                "vill_name": "ຜາທອງ",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60502",
                "vill_name": "ຕະແວ",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60503",
                "vill_name": "ລານກາງ",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60504",
                "vill_name": "ບວມ(ຫ້ວຍເສືອ)",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60505",
                "vill_name": "ຂີງກາງ",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60506",
                "vill_name": "ປາກຂັນ",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60507",
                "vill_name": "ຂັນລຸ່ມ",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60508",
                "vill_name": "ຂັນເທິງ",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60509",
                "vill_name": "ດ່ວນໃຕ້",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60510",
                "vill_name": "ປຸ່ງຈອງ",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60511",
                "vill_name": "ມົກຈົງ",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60512",
                "vill_name": "ມົກແວດ",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60513",
                "vill_name": "ຫ້ອຍກ້ອງ",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60514",
                "vill_name": "ວຽງຫີນສູງ",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60515",
                "vill_name": "ນາໃຫມ່",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60516",
                "vill_name": "ໂພນໂຮມ1",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60517",
                "vill_name": "ດອນເລົາ",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60518",
                "vill_name": "ຫ້ວຍຮີດລຸ່ມ",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60519",
                "vill_name": "ນາຍາງເຫນືອ",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60520",
                "vill_name": "ຫ້ວຍຫ້າ",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60521",
                "vill_name": "ນາຍາງໃຕ້",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60522",
                "vill_name": "ຫ້ວຍເຢັນ",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60523",
                "vill_name": "ໂພນໄຊ1",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60524",
                "vill_name": "ປາກມອງ",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60525",
                "vill_name": "ວັງຫີນ",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60526",
                "vill_name": "ນ້ຳມອງ",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60527",
                "vill_name": "ກະເຈີດ",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60528",
                "vill_name": "ຫມາກຜຸກ",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60529",
                "vill_name": "ຊົງຈ່າ",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60530",
                "vill_name": "ກຣະລາງ",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60532",
                "vill_name": "ໂຄ້ງ",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60533",
                "vill_name": "ນ້ຳລາ",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60534",
                "vill_name": "ວຽງຄຳ",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60535",
                "vill_name": "ນະຄອນ",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60536",
                "vill_name": "ລີ",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60537",
                "vill_name": "ດອນຄຳ",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60538",
                "vill_name": "ບົມ",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60539",
                "vill_name": "ໂພນສະອາດ",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60540",
                "vill_name": "ຟ້າ",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60541",
                "vill_name": "ທ່າລີເໜືອ",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60542",
                "vill_name": "ທ່າລີໃຕ້",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60543",
                "vill_name": "ນາກອກ",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60544",
                "vill_name": "ພູເກີ",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60545",
                "vill_name": "ລ້ອງຈົກ",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60546",
                "vill_name": "ກິ່ວຈະລິ",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60547",
                "vill_name": "ທ່າບູ້",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60548",
                "vill_name": "ໂພນຊຽງ",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60549",
                "vill_name": "ມົກລັກ",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60550",
                "vill_name": "ຫ້ວຍຍາງ",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60551",
                "vill_name": "ຫ້ວຍເວີ",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60552",
                "vill_name": "ນ້ຳຂານ 2",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60553",
                "vill_name": "ມ່ວນ",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60554",
                "vill_name": "ໂພນສະຫວັນ",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60555",
                "vill_name": "ໂພນມະນີ",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60556",
                "vill_name": "ນ້ຳຖ້ວມເຫນືອ",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60557",
                "vill_name": "ຊາງ",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60558",
                "vill_name": "ຫນອງເຫນືອ",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60559",
                "vill_name": "ແສນຫລວງ",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60560",
                "vill_name": "ປ່ອງໃຕ້",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60561",
                "vill_name": "ຫ້ວຍເຄືອ",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60562",
                "vill_name": "ຜາຫລວງ",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60563",
                "vill_name": "ໂພນສະຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60564",
                "vill_name": "ນ້ຳຄ້າ",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60565",
                "vill_name": "ຊຽງດາ",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60566",
                "vill_name": "ຊຽງດາ",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60567",
                "vill_name": "ຫົວນາ",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60568",
                "vill_name": "ກິ່ວຫາ",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60569",
                "vill_name": "ທອງເທິງ",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60570",
                "vill_name": "ລາວເຫລົາ",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60571",
                "vill_name": "ຫ້ວຍຍີມ",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60572",
                "vill_name": "ພູກູ້",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60573",
                "vill_name": "ນາຊຽງດີ",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60574",
                "vill_name": "ເມຶອງແດງ",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60575",
                "vill_name": "ຄົກນາງ",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60576",
                "vill_name": "ຫນອງແກ້ວ",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60577",
                "vill_name": "ສີມຸງຄຸນ",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60578",
                "vill_name": "ນ້ຳງາ",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60579",
                "vill_name": "ສີບຸນເຮືອງ",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60580",
                "vill_name": "ແນ່",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60581",
                "vill_name": "ຫ້ວຍຮຽມ",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60582",
                "vill_name": "ຫ້ວຍຫົກ",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60583",
                "vill_name": "ນາຮຳ",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60584",
                "vill_name": "ນ້ຳຖ້ວມໃຕ້",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60585",
                "vill_name": "ຫລັກ 50",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60586",
                "vill_name": "ກິ່ວຊ້າງເຫນືອ",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60587",
                "vill_name": "ຫລັກ 50",
                "vill_name_en": "",
                "dr_id": "605"
              },
              {
                "vill_id": "60588",
                "vill_name": "ວົງຄຳ",
                "vill_name_en": "vongkham",
                "dr_id": "605"
              },
              {
                "vill_id": "60589",
                "vill_name": "ໂພນແລ",
                "vill_name_en": "Phonlare",
                "dr_id": "605"
              },
              {
                "vill_id": "60590",
                "vill_name": "ບ້ານຫ້ວຍແວ",
                "vill_name_en": "Houyvae",
                "dr_id": "605"
              }
            ]
          },
          {
            "dr_id": "606",
            "dr_name": "ງອຍ",
            "dr_name_en": "Ngoi",
            "pr_id": "6",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "60601",
                "vill_name": "ຫລັກ 50",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60602",
                "vill_name": "ກິ່ວຊ້າງເຫນືອ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60603",
                "vill_name": "ຫ້ວຍສີ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60604",
                "vill_name": "ປາກບຸດ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60605",
                "vill_name": "ຫາດສາ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60606",
                "vill_name": "ຫ້ວຍລໍ້ສູງ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60607",
                "vill_name": "ຫ້ວຍລໍ້ເທິງ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60608",
                "vill_name": "ປາກລວງ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60609",
                "vill_name": "ແຈ້ງກາງ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60610",
                "vill_name": "ໂພນໄຊ(ລອງນ້ຳ)",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60611",
                "vill_name": "ວັງງູ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60612",
                "vill_name": "ສົບຂີງ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60613",
                "vill_name": "ໂພນຊະນະ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60614",
                "vill_name": "ຫາດຂາມ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60615",
                "vill_name": "ໂພນໄຊ (ຕິດທາງ)",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60616",
                "vill_name": "ໂພນ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60617",
                "vill_name": "ໂພນຄຳ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60618",
                "vill_name": "ໂດນ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60619",
                "vill_name": "ຊາງ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60620",
                "vill_name": "ຫ້ວຍນາ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60621",
                "vill_name": "ຫາດຈັ້ນ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60622",
                "vill_name": "ສົບກອງ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60623",
                "vill_name": "ກິ່ວຂັ້ນ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60624",
                "vill_name": "ວຽງໄຊ ( ສົບຄານ )",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60625",
                "vill_name": "ຫາດສະເພີຍ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60626",
                "vill_name": "ເມືອງງອຍ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60627",
                "vill_name": "ນາກາງ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60628",
                "vill_name": "ຫ້ວຍບໍ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60629",
                "vill_name": "ຫ້ວຍແສນ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60630",
                "vill_name": "ຜາຢ້ອງ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60631",
                "vill_name": "ສົບແຈມ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60632",
                "vill_name": "ດອນລອມ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60633",
                "vill_name": "ຫ້ວຍລານ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60634",
                "vill_name": "ມົກດູ່",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60635",
                "vill_name": "ຈອມຍິງ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60636",
                "vill_name": "ຫ້ວຍທອງ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60637",
                "vill_name": "ຫ້ວຍຂ້ອງ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60638",
                "vill_name": "ກິ່ວກ້ານ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60639",
                "vill_name": "ກິ່ວກ້ານ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60640",
                "vill_name": "ຫີນແປ້ນ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60641",
                "vill_name": "ເລົາເສົາ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60642",
                "vill_name": "ຫ້ວຍເຄີງ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60643",
                "vill_name": "ພູວຽງ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60644",
                "vill_name": "ຫ້ວຍແຊວ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60645",
                "vill_name": "ລ້ອງຄຳ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60646",
                "vill_name": "ຫ້ວຍເຫລັກ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60647",
                "vill_name": "ຫ້ວຍປຸ່ງ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60648",
                "vill_name": "ພູຫລວງ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60649",
                "vill_name": "ພູຕິດເຜີ້ງ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60650",
                "vill_name": "ປາກບາກ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60651",
                "vill_name": "ຫນອງອ່ຽນ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60652",
                "vill_name": "ສົບວັນ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60653",
                "vill_name": "ຫາດຊາວ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60654",
                "vill_name": "ສົບຮຸນ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60655",
                "vill_name": "ຫາດຮວນ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60656",
                "vill_name": "ຫນອງຂຽວເຫນືອ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60657",
                "vill_name": "ລາດແອ່ນ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60658",
                "vill_name": "ແທ່ນແຄນ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60659",
                "vill_name": "ຫນອງຄຳ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60660",
                "vill_name": "ຫ້ວຍກຸ້ງ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60661",
                "vill_name": "ດອນຄູນ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60662",
                "vill_name": "ຫ້ວຍຮາງ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60663",
                "vill_name": "ຫ້ວຍແຄນ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60664",
                "vill_name": "ກະຕາງຊຽງ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60665",
                "vill_name": "ຕູບໃນ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60666",
                "vill_name": "ດອນເງິນ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60667",
                "vill_name": "ຄອນເກີ່ງ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60668",
                "vill_name": "ປາກຈີມ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60669",
                "vill_name": "ຫາດຄີບ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60670",
                "vill_name": "ຫາດຜ່າງ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60671",
                "vill_name": "ຫາດຜ່າງ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60672",
                "vill_name": "ຫ້ວຍຜ່າງ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60673",
                "vill_name": "ເຊິ່ງຫົກ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60674",
                "vill_name": "ຫລັງຜາ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60675",
                "vill_name": "ນາແລ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60676",
                "vill_name": "ພູສູງ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60677",
                "vill_name": "ເມືອງຊື່ນ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60678",
                "vill_name": "ຫ້ວຍແລ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60679",
                "vill_name": "ໃຫມ່",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60680",
                "vill_name": "ຫ້ວຍໂງ່ນ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60681",
                "vill_name": "ຫ້ວຍຫອຍ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60682",
                "vill_name": "ມົກແລບ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60683",
                "vill_name": "ໂພນສະຫັວນ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60684",
                "vill_name": "ຫ້ວຍຈີມ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60685",
                "vill_name": "ມົກລັກ",
                "vill_name_en": "",
                "dr_id": "606"
              },
              {
                "vill_id": "60686",
                "vill_name": "ໜອງຂຽວ",
                "vill_name_en": "ືNongkiew",
                "dr_id": "606"
              },
              {
                "vill_id": "60687",
                "vill_name": "ປ່າງ່າ",
                "vill_name_en": "Panja",
                "dr_id": "606"
              },
              {
                "vill_id": "60688",
                "vill_name": "ຫ້ວຍທອງ",
                "vill_name_en": "Houaythong",
                "dr_id": "606"
              },
              {
                "vill_id": "60689",
                "vill_name": "ບ້ານສົບຄານ",
                "vill_name_en": "Sopkhan",
                "dr_id": "606"
              }
            ]
          },
          {
            "dr_id": "607",
            "dr_name": "ປາກແຊງ",
            "dr_name_en": "Pakxeng",
            "pr_id": "6",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "60701",
                "vill_name": "ງິ້ວ",
                "vill_name_en": "",
                "dr_id": "607"
              },
              {
                "vill_id": "60702",
                "vill_name": "ຫາດແກ້ວ",
                "vill_name_en": "",
                "dr_id": "607"
              },
              {
                "vill_id": "60703",
                "vill_name": "ມ່ວງຄຳ",
                "vill_name_en": "",
                "dr_id": "607"
              },
              {
                "vill_id": "60704",
                "vill_name": "ນາໃຫມ່",
                "vill_name_en": "",
                "dr_id": "607"
              },
              {
                "vill_id": "60705",
                "vill_name": "ໂພນທອງ",
                "vill_name_en": "",
                "dr_id": "607"
              },
              {
                "vill_id": "60706",
                "vill_name": "ຫາດແຄນ",
                "vill_name_en": "",
                "dr_id": "607"
              },
              {
                "vill_id": "60707",
                "vill_name": "ຫາດເຢັນ",
                "vill_name_en": "",
                "dr_id": "607"
              },
              {
                "vill_id": "60708",
                "vill_name": "ຜາບັກ",
                "vill_name_en": "",
                "dr_id": "607"
              },
              {
                "vill_id": "60709",
                "vill_name": "ຫາດຕືນ",
                "vill_name_en": "",
                "dr_id": "607"
              },
              {
                "vill_id": "60710",
                "vill_name": "ວັງພົມ",
                "vill_name_en": "",
                "dr_id": "607"
              },
              {
                "vill_id": "60711",
                "vill_name": "ບອມພະແສງ",
                "vill_name_en": "",
                "dr_id": "607"
              },
              {
                "vill_id": "60712",
                "vill_name": "ປາກແຊງ",
                "vill_name_en": "",
                "dr_id": "607"
              },
              {
                "vill_id": "60713",
                "vill_name": "ຫາດຜະອົດ",
                "vill_name_en": "",
                "dr_id": "607"
              },
              {
                "vill_id": "60714",
                "vill_name": "ດອນຄູນ",
                "vill_name_en": "",
                "dr_id": "607"
              },
              {
                "vill_id": "60715",
                "vill_name": "ສົບແຈກ",
                "vill_name_en": "",
                "dr_id": "607"
              },
              {
                "vill_id": "60716",
                "vill_name": "ສົບໂຈະ",
                "vill_name_en": "",
                "dr_id": "607"
              },
              {
                "vill_id": "60717",
                "vill_name": "ຫ້ວຍໂພ",
                "vill_name_en": "",
                "dr_id": "607"
              },
              {
                "vill_id": "60718",
                "vill_name": "ຫາດຮີດ",
                "vill_name_en": "",
                "dr_id": "607"
              },
              {
                "vill_id": "60719",
                "vill_name": "ຫົວສະຄິງ",
                "vill_name_en": "",
                "dr_id": "607"
              },
              {
                "vill_id": "60720",
                "vill_name": "ຫາດຮວາຍ",
                "vill_name_en": "",
                "dr_id": "607"
              },
              {
                "vill_id": "60721",
                "vill_name": "ປາກເກງ",
                "vill_name_en": "",
                "dr_id": "607"
              },
              {
                "vill_id": "60722",
                "vill_name": "ຫາດສະງອນ",
                "vill_name_en": "",
                "dr_id": "607"
              },
              {
                "vill_id": "60723",
                "vill_name": "ຫົວແກ້ງ",
                "vill_name_en": "",
                "dr_id": "607"
              },
              {
                "vill_id": "60724",
                "vill_name": "ນາໂພ",
                "vill_name_en": "",
                "dr_id": "607"
              },
              {
                "vill_id": "60725",
                "vill_name": "ຫາດຊຳ",
                "vill_name_en": "",
                "dr_id": "607"
              },
              {
                "vill_id": "60726",
                "vill_name": "ວັງເງິນ",
                "vill_name_en": "",
                "dr_id": "607"
              },
              {
                "vill_id": "60727",
                "vill_name": "ວັງເງິນ",
                "vill_name_en": "",
                "dr_id": "607"
              },
              {
                "vill_id": "60728",
                "vill_name": "ຫ້ວຍໂທນ",
                "vill_name_en": "",
                "dr_id": "607"
              },
              {
                "vill_id": "60729",
                "vill_name": "ຫາດງຳ",
                "vill_name_en": "",
                "dr_id": "607"
              },
              {
                "vill_id": "60730",
                "vill_name": "ຫ້ວຍສະອູ",
                "vill_name_en": "",
                "dr_id": "607"
              },
              {
                "vill_id": "60731",
                "vill_name": "ຫາດພວນ",
                "vill_name_en": "",
                "dr_id": "607"
              },
              {
                "vill_id": "60732",
                "vill_name": "ລ້ອງເອື້ອງ",
                "vill_name_en": "",
                "dr_id": "607"
              },
              {
                "vill_id": "60733",
                "vill_name": "ຫຼ໐໌ ໜîໃຸ໐",
                "vill_name_en": "",
                "dr_id": "607"
              },
              {
                "vill_id": "60734",
                "vill_name": "ຫ້ວຍເຢັນ",
                "vill_name_en": "",
                "dr_id": "607"
              },
              {
                "vill_id": "60735",
                "vill_name": "ວຽງທອງ",
                "vill_name_en": "",
                "dr_id": "607"
              },
              {
                "vill_id": "60736",
                "vill_name": "ສະກວນ",
                "vill_name_en": "",
                "dr_id": "607"
              },
              {
                "vill_id": "60737",
                "vill_name": "ລັງກອນ",
                "vill_name_en": "",
                "dr_id": "607"
              },
              {
                "vill_id": "60738",
                "vill_name": "ຫາດຈາງ",
                "vill_name_en": "",
                "dr_id": "607"
              },
              {
                "vill_id": "60739",
                "vill_name": "ສະແນນ",
                "vill_name_en": "",
                "dr_id": "607"
              },
              {
                "vill_id": "60740",
                "vill_name": "ຫ້ວຍທອງ",
                "vill_name_en": "",
                "dr_id": "607"
              },
              {
                "vill_id": "60741",
                "vill_name": "ບອມຄູນ",
                "vill_name_en": "",
                "dr_id": "607"
              },
              {
                "vill_id": "60742",
                "vill_name": "ຫາດຫວານ",
                "vill_name_en": "",
                "dr_id": "607"
              },
              {
                "vill_id": "60743",
                "vill_name": "ບວມກອກ",
                "vill_name_en": "",
                "dr_id": "607"
              },
              {
                "vill_id": "60744",
                "vill_name": "ກິ່ວຂວາງ",
                "vill_name_en": "",
                "dr_id": "607"
              },
              {
                "vill_id": "60745",
                "vill_name": "ຫ້ວຍຍໍ້",
                "vill_name_en": "",
                "dr_id": "607"
              },
              {
                "vill_id": "60746",
                "vill_name": "ໜອງຟ້າແດດ",
                "vill_name_en": "",
                "dr_id": "607"
              },
              {
                "vill_id": "60747",
                "vill_name": "ສົບວັດ",
                "vill_name_en": "",
                "dr_id": "607"
              },
              {
                "vill_id": "60748",
                "vill_name": "ໂພ",
                "vill_name_en": "",
                "dr_id": "607"
              },
              {
                "vill_id": "60749",
                "vill_name": "ວັງອໍ້",
                "vill_name_en": "",
                "dr_id": "607"
              },
              {
                "vill_id": "60750",
                "vill_name": "ຫນາດຄຳ",
                "vill_name_en": "",
                "dr_id": "607"
              },
              {
                "vill_id": "60751",
                "vill_name": "ຫ້ວຍວັດ",
                "vill_name_en": "",
                "dr_id": "607"
              },
              {
                "vill_id": "60752",
                "vill_name": "ສົບເຜີອກ",
                "vill_name_en": "",
                "dr_id": "607"
              },
              {
                "vill_id": "60753",
                "vill_name": "ຫນອງຄຳ",
                "vill_name_en": "",
                "dr_id": "607"
              },
              {
                "vill_id": "60754",
                "vill_name": "ຫ້ວຍມົດ",
                "vill_name_en": "",
                "dr_id": "607"
              },
              {
                "vill_id": "60755",
                "vill_name": "ກິ່ວເອືອດ",
                "vill_name_en": "",
                "dr_id": "607"
              },
              {
                "vill_id": "60756",
                "vill_name": "ຫາດສາງ",
                "vill_name_en": "",
                "dr_id": "607"
              },
              {
                "vill_id": "60757",
                "vill_name": "ພູຫນໍ່ຫວານ",
                "vill_name_en": "",
                "dr_id": "607"
              },
              {
                "vill_id": "60758",
                "vill_name": "ຕາດແສງ",
                "vill_name_en": "",
                "dr_id": "607"
              },
              {
                "vill_id": "60759",
                "vill_name": "ຕາດແສງ",
                "vill_name_en": "",
                "dr_id": "607"
              },
              {
                "vill_id": "60760",
                "vill_name": "ໜອງ",
                "vill_name_en": "",
                "dr_id": "607"
              },
              {
                "vill_id": "60761",
                "vill_name": "ປຸງລານ",
                "vill_name_en": "",
                "dr_id": "607"
              },
              {
                "vill_id": "60762",
                "vill_name": "ບວມສະໄໝ",
                "vill_name_en": "Buamsamay",
                "dr_id": "607"
              }
            ]
          },
          {
            "dr_id": "608",
            "dr_name": "ໂພນໄຊ",
            "dr_name_en": "Phonxay",
            "pr_id": "6",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "60801",
                "vill_name": "ພັກຫົກ",
                "vill_name_en": "",
                "dr_id": "608"
              },
              {
                "vill_id": "60802",
                "vill_name": "ພັກບົງ",
                "vill_name_en": "",
                "dr_id": "608"
              },
              {
                "vill_id": "60803",
                "vill_name": "ນາພຽງ",
                "vill_name_en": "",
                "dr_id": "608"
              },
              {
                "vill_id": "60804",
                "vill_name": "ຫ້ວຍດອງ",
                "vill_name_en": "",
                "dr_id": "608"
              },
              {
                "vill_id": "60805",
                "vill_name": "ຈອມຈຽງ",
                "vill_name_en": "",
                "dr_id": "608"
              },
              {
                "vill_id": "60806",
                "vill_name": "ລອ້ງລາດ",
                "vill_name_en": "",
                "dr_id": "608"
              },
              {
                "vill_id": "60807",
                "vill_name": "ກະຕ່າງສະເລື່ອງ",
                "vill_name_en": "",
                "dr_id": "608"
              },
              {
                "vill_id": "60808",
                "vill_name": "ປາກສີມງ",
                "vill_name_en": "",
                "dr_id": "608"
              },
              {
                "vill_id": "60809",
                "vill_name": "ສັນພູຫລວງ",
                "vill_name_en": "",
                "dr_id": "608"
              },
              {
                "vill_id": "60810",
                "vill_name": "ຫີນຕັ້ງ",
                "vill_name_en": "",
                "dr_id": "608"
              },
              {
                "vill_id": "60811",
                "vill_name": "ປຸງລັດ",
                "vill_name_en": "",
                "dr_id": "608"
              },
              {
                "vill_id": "60812",
                "vill_name": "ກາງສັນ",
                "vill_name_en": "",
                "dr_id": "608"
              },
              {
                "vill_id": "60813",
                "vill_name": "ຫ້ວຍຮ່າວ",
                "vill_name_en": "",
                "dr_id": "608"
              },
              {
                "vill_id": "60814",
                "vill_name": "ດອນເງິນ( ຫາດຄູນ )",
                "vill_name_en": "",
                "dr_id": "608"
              },
              {
                "vill_id": "60815",
                "vill_name": "ສະກວນ",
                "vill_name_en": "",
                "dr_id": "608"
              },
              {
                "vill_id": "60816",
                "vill_name": "ຫາດຈອງ",
                "vill_name_en": "",
                "dr_id": "608"
              },
              {
                "vill_id": "60817",
                "vill_name": "ຫ້ວຍພາ",
                "vill_name_en": "",
                "dr_id": "608"
              },
              {
                "vill_id": "60818",
                "vill_name": "ສົບຫຸດ",
                "vill_name_en": "",
                "dr_id": "608"
              },
              {
                "vill_id": "60819",
                "vill_name": "ດອນຄຳ",
                "vill_name_en": "",
                "dr_id": "608"
              },
              {
                "vill_id": "60820",
                "vill_name": "ວຽງຈະເລີນ",
                "vill_name_en": "",
                "dr_id": "608"
              },
              {
                "vill_id": "60821",
                "vill_name": "ວຽງຈະເລີນ",
                "vill_name_en": "",
                "dr_id": "608"
              },
              {
                "vill_id": "60822",
                "vill_name": "ກີວໄລ",
                "vill_name_en": "",
                "dr_id": "608"
              },
              {
                "vill_id": "60823",
                "vill_name": "ວັງຂອນ ພູສະໄໝ",
                "vill_name_en": "",
                "dr_id": "608"
              },
              {
                "vill_id": "60824",
                "vill_name": "ຫ້ວຍຮ່າ",
                "vill_name_en": "",
                "dr_id": "608"
              },
              {
                "vill_id": "60825",
                "vill_name": "ໂພນທອງ",
                "vill_name_en": "",
                "dr_id": "608"
              },
              {
                "vill_id": "60826",
                "vill_name": "ນາງອຍ",
                "vill_name_en": "",
                "dr_id": "608"
              },
              {
                "vill_id": "60827",
                "vill_name": "ບວກຄຳ",
                "vill_name_en": "",
                "dr_id": "608"
              },
              {
                "vill_id": "60828",
                "vill_name": "ຫ້ວຍຂີງ",
                "vill_name_en": "",
                "dr_id": "608"
              },
              {
                "vill_id": "60829",
                "vill_name": "ຜາຕູບເໜືອ",
                "vill_name_en": "",
                "dr_id": "608"
              },
              {
                "vill_id": "60830",
                "vill_name": "ຫ້ວຍລຸ້ງເທິງ",
                "vill_name_en": "",
                "dr_id": "608"
              },
              {
                "vill_id": "60831",
                "vill_name": "ທ່າຂ້າມ",
                "vill_name_en": "",
                "dr_id": "608"
              },
              {
                "vill_id": "60832",
                "vill_name": "ຫ້ວຍເຈ້ຍ",
                "vill_name_en": "",
                "dr_id": "608"
              },
              {
                "vill_id": "60833",
                "vill_name": "ຫ້ວຍງາ",
                "vill_name_en": "",
                "dr_id": "608"
              },
              {
                "vill_id": "60834",
                "vill_name": "ຫ້ວຍລຸ່ງສູງ",
                "vill_name_en": "",
                "dr_id": "608"
              },
              {
                "vill_id": "60835",
                "vill_name": "ຫ້ວຍສີບົວ",
                "vill_name_en": "",
                "dr_id": "608"
              },
              {
                "vill_id": "60836",
                "vill_name": "ຫ້ວຍມະຫາ",
                "vill_name_en": "",
                "dr_id": "608"
              },
              {
                "vill_id": "60837",
                "vill_name": "ຫ້ວຍປ່ຽນ",
                "vill_name_en": "",
                "dr_id": "608"
              },
              {
                "vill_id": "60838",
                "vill_name": "ປາກງາ",
                "vill_name_en": "",
                "dr_id": "608"
              },
              {
                "vill_id": "60839",
                "vill_name": "ຜາຕູບໃຕ້",
                "vill_name_en": "",
                "dr_id": "608"
              },
              {
                "vill_id": "60840",
                "vill_name": "ສັນກາງ",
                "vill_name_en": "",
                "dr_id": "608"
              },
              {
                "vill_id": "60841",
                "vill_name": "ສົບເຈັ້ຍ",
                "vill_name_en": "",
                "dr_id": "608"
              },
              {
                "vill_id": "60842",
                "vill_name": "ຫົວເມືອງ",
                "vill_name_en": "",
                "dr_id": "608"
              },
              {
                "vill_id": "60843",
                "vill_name": "ຫ້ວຍມັນ",
                "vill_name_en": "",
                "dr_id": "608"
              },
              {
                "vill_id": "60844",
                "vill_name": "ລ້ອງຍົມໃຫຍ່",
                "vill_name_en": "",
                "dr_id": "608"
              },
              {
                "vill_id": "60845",
                "vill_name": "ຫ້ວຍລອນ",
                "vill_name_en": "",
                "dr_id": "608"
              },
              {
                "vill_id": "60846",
                "vill_name": "ທ່າໂພ",
                "vill_name_en": "",
                "dr_id": "608"
              },
              {
                "vill_id": "60847",
                "vill_name": "ຫນອງຄຳ",
                "vill_name_en": "",
                "dr_id": "608"
              },
              {
                "vill_id": "60848",
                "vill_name": "ນ້ຳບໍ່",
                "vill_name_en": "",
                "dr_id": "608"
              },
              {
                "vill_id": "60849",
                "vill_name": "ເຮືອໂດຍ",
                "vill_name_en": "",
                "dr_id": "608"
              },
              {
                "vill_id": "60850",
                "vill_name": "ດອນໄຊ",
                "vill_name_en": "",
                "dr_id": "608"
              },
              {
                "vill_id": "60851",
                "vill_name": "ປາກວັງ",
                "vill_name_en": "",
                "dr_id": "608"
              },
              {
                "vill_id": "60852",
                "vill_name": "ນາງິ້ວ",
                "vill_name_en": "",
                "dr_id": "608"
              },
              {
                "vill_id": "60853",
                "vill_name": "ຫ້ວຍສາລາ",
                "vill_name_en": "",
                "dr_id": "608"
              },
              {
                "vill_id": "60854",
                "vill_name": "ພູຂວາງ",
                "vill_name_en": "",
                "dr_id": "608"
              },
              {
                "vill_id": "60855",
                "vill_name": "ລ້ອງຢວກ",
                "vill_name_en": "",
                "dr_id": "608"
              },
              {
                "vill_id": "60856",
                "vill_name": "ລ້ອງແງດ",
                "vill_name_en": "",
                "dr_id": "608"
              },
              {
                "vill_id": "60857",
                "vill_name": "ກະລ່ອງກ່ອງ",
                "vill_name_en": "",
                "dr_id": "608"
              },
              {
                "vill_id": "60858",
                "vill_name": "ຜາແຕ້ມ",
                "vill_name_en": "",
                "dr_id": "608"
              },
              {
                "vill_id": "60859",
                "vill_name": "ຫ້ວຍໂທ້",
                "vill_name_en": "",
                "dr_id": "608"
              },
              {
                "vill_id": "60860",
                "vill_name": "ຫ້ວຍໂນະ",
                "vill_name_en": "",
                "dr_id": "608"
              },
              {
                "vill_id": "60861",
                "vill_name": "ປຸ່ງເປົ້າ",
                "vill_name_en": "",
                "dr_id": "608"
              },
              {
                "vill_id": "60862",
                "vill_name": "ຕາດທອງ",
                "vill_name_en": "",
                "dr_id": "608"
              },
              {
                "vill_id": "60863",
                "vill_name": "ກົກງີ້ວ",
                "vill_name_en": "",
                "dr_id": "608"
              },
              {
                "vill_id": "60864",
                "vill_name": "ແກ້ງຮາງ",
                "vill_name_en": "",
                "dr_id": "608"
              },
              {
                "vill_id": "60865",
                "vill_name": "ກົກງີ້ວ ",
                "vill_name_en": "",
                "dr_id": "608"
              },
              {
                "vill_id": "60866",
                "vill_name": "ບ້ານລ້ອງສະເອົາ",
                "vill_name_en": "Longsaout",
                "dr_id": "608"
              }
            ]
          },
          {
            "dr_id": "609",
            "dr_name": "ຈອມເພັດ",
            "dr_name_en": "Chomphet",
            "pr_id": "6",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "60901",
                "vill_name": "ແກ້ງຮາງ",
                "vill_name_en": "",
                "dr_id": "609"
              },
              {
                "vill_id": "60902",
                "vill_name": "ຫ້ວຍຫາກ",
                "vill_name_en": "",
                "dr_id": "609"
              },
              {
                "vill_id": "60903",
                "vill_name": "ນາງກາງ",
                "vill_name_en": "",
                "dr_id": "609"
              },
              {
                "vill_id": "60904",
                "vill_name": "ແລ",
                "vill_name_en": "",
                "dr_id": "609"
              },
              {
                "vill_id": "60905",
                "vill_name": "ນາອ່າງ",
                "vill_name_en": "",
                "dr_id": "609"
              },
              {
                "vill_id": "60906",
                "vill_name": "ປາກຫ້ວຍຄໍ້ (ນາຊາວ)",
                "vill_name_en": "",
                "dr_id": "609"
              },
              {
                "vill_id": "60907",
                "vill_name": "ຫ້ວຍຖ້ຳ",
                "vill_name_en": "",
                "dr_id": "609"
              },
              {
                "vill_id": "60908",
                "vill_name": "ຕອນ",
                "vill_name_en": "",
                "dr_id": "609"
              },
              {
                "vill_id": "60909",
                "vill_name": "ຄົກຫລວງ",
                "vill_name_en": "",
                "dr_id": "609"
              },
              {
                "vill_id": "60910",
                "vill_name": "ຫ້ວຍຫມ້ຽງ",
                "vill_name_en": "",
                "dr_id": "609"
              },
              {
                "vill_id": "60911",
                "vill_name": "ຈົກ",
                "vill_name_en": "",
                "dr_id": "609"
              },
              {
                "vill_id": "60912",
                "vill_name": "ເກືອບຈົກ",
                "vill_name_en": "",
                "dr_id": "609"
              },
              {
                "vill_id": "60913",
                "vill_name": "ຫ້ວຍອ່ອນ",
                "vill_name_en": "",
                "dr_id": "609"
              },
              {
                "vill_id": "60914",
                "vill_name": "ຊຳອໍ້",
                "vill_name_en": "",
                "dr_id": "609"
              },
              {
                "vill_id": "60915",
                "vill_name": "ນາ",
                "vill_name_en": "",
                "dr_id": "609"
              },
              {
                "vill_id": "60930",
                "vill_name": "ຫ້ວຍມາດ",
                "vill_name_en": "",
                "dr_id": "609"
              },
              {
                "vill_id": "60931",
                "vill_name": "ລ້ອງຊຽງ",
                "vill_name_en": "",
                "dr_id": "609"
              },
              {
                "vill_id": "60932",
                "vill_name": "ແກ້ງແຄນ",
                "vill_name_en": "",
                "dr_id": "609"
              },
              {
                "vill_id": "60933",
                "vill_name": "ຫ້ວຍຕ່ານ",
                "vill_name_en": "",
                "dr_id": "609"
              },
              {
                "vill_id": "60934",
                "vill_name": "ໂສມນ",
                "vill_name_en": "",
                "dr_id": "609"
              },
              {
                "vill_id": "60935",
                "vill_name": "ໂສມ",
                "vill_name_en": "",
                "dr_id": "609"
              },
              {
                "vill_id": "60936",
                "vill_name": "ຫ້ວຍແຂ້ ພູດຳ",
                "vill_name_en": "",
                "dr_id": "609"
              },
              {
                "vill_id": "60937",
                "vill_name": "ຫ້ວຍແຂ້ ພູດຳ",
                "vill_name_en": "",
                "dr_id": "609"
              },
              {
                "vill_id": "60938",
                "vill_name": "ໂພນໂຮມ",
                "vill_name_en": "",
                "dr_id": "609"
              },
              {
                "vill_id": "60939",
                "vill_name": "ນາສັງ",
                "vill_name_en": "",
                "dr_id": "609"
              },
              {
                "vill_id": "60940",
                "vill_name": "ຫ້ວຍຍໍ້",
                "vill_name_en": "",
                "dr_id": "609"
              },
              {
                "vill_id": "60941",
                "vill_name": "ມ່ວງແກ້ວ",
                "vill_name_en": "",
                "dr_id": "609"
              },
              {
                "vill_id": "60942",
                "vill_name": "ພູຫລວງໃຕ້",
                "vill_name_en": "",
                "dr_id": "609"
              },
              {
                "vill_id": "60943",
                "vill_name": "ຫ້ວຍກໍ້",
                "vill_name_en": "",
                "dr_id": "609"
              },
              {
                "vill_id": "60944",
                "vill_name": "ຖິ່ນໂຮງ",
                "vill_name_en": "",
                "dr_id": "609"
              },
              {
                "vill_id": "60945",
                "vill_name": "ສະນົກ",
                "vill_name_en": "",
                "dr_id": "609"
              },
              {
                "vill_id": "60946",
                "vill_name": "ມ່ວງຄຳ",
                "vill_name_en": "",
                "dr_id": "609"
              },
              {
                "vill_id": "60947",
                "vill_name": "ນາໄຊຈະເລີນ",
                "vill_name_en": "",
                "dr_id": "609"
              },
              {
                "vill_id": "60948",
                "vill_name": "ນາຄຳ",
                "vill_name_en": "",
                "dr_id": "609"
              },
              {
                "vill_id": "60949",
                "vill_name": "ຊຽງແມນ",
                "vill_name_en": "",
                "dr_id": "609"
              },
              {
                "vill_id": "60950",
                "vill_name": "ທ່າໂພ",
                "vill_name_en": "",
                "dr_id": "609"
              },
              {
                "vill_id": "60951",
                "vill_name": "ບວມເລົາ",
                "vill_name_en": "",
                "dr_id": "609"
              },
              {
                "vill_id": "60952",
                "vill_name": "ບວມຊຽງາ",
                "vill_name_en": "",
                "dr_id": "609"
              },
              {
                "vill_id": "60953",
                "vill_name": "ຈານເຫນືອ",
                "vill_name_en": "",
                "dr_id": "609"
              },
              {
                "vill_id": "60954",
                "vill_name": "ຈ່ານໃຕ້",
                "vill_name_en": "",
                "dr_id": "609"
              },
              {
                "vill_id": "60955",
                "vill_name": "ປາກລືງ",
                "vill_name_en": "",
                "dr_id": "609"
              },
              {
                "vill_id": "60956",
                "vill_name": "ຫນອງພຸ",
                "vill_name_en": "",
                "dr_id": "609"
              },
              {
                "vill_id": "60957",
                "vill_name": "ດອນໄຊ",
                "vill_name_en": "",
                "dr_id": "609"
              },
              {
                "vill_id": "60958",
                "vill_name": "ສະເລື່ອນ",
                "vill_name_en": "",
                "dr_id": "609"
              },
              {
                "vill_id": "60959",
                "vill_name": "ປາກລືໍ້",
                "vill_name_en": "",
                "dr_id": "609"
              },
              {
                "vill_id": "60960",
                "vill_name": "ນາເກືອ",
                "vill_name_en": "",
                "dr_id": "609"
              },
              {
                "vill_id": "60961",
                "vill_name": "ນາແຄ້ງ",
                "vill_name_en": "",
                "dr_id": "609"
              },
              {
                "vill_id": "60962",
                "vill_name": "ຫ້ວຍຄານ",
                "vill_name_en": "",
                "dr_id": "609"
              },
              {
                "vill_id": "60963",
                "vill_name": "ນາງິ່ວ",
                "vill_name_en": "",
                "dr_id": "609"
              },
              {
                "vill_id": "60964",
                "vill_name": "ຊ້ອງເຫນືອ",
                "vill_name_en": "",
                "dr_id": "609"
              },
              {
                "vill_id": "60965",
                "vill_name": "ຊ້ອງໃຕ້",
                "vill_name_en": "",
                "dr_id": "609"
              },
              {
                "vill_id": "60966",
                "vill_name": "ຫ້ວຍຕ່າວ",
                "vill_name_en": "",
                "dr_id": "609"
              },
              {
                "vill_id": "60967",
                "vill_name": "ນາໄຮ",
                "vill_name_en": "",
                "dr_id": "609"
              },
              {
                "vill_id": "60968",
                "vill_name": "ພູຫລວງໃຕ້ ( ເທັົîໃ)",
                "vill_name_en": "",
                "dr_id": "609"
              },
              {
                "vill_id": "60969",
                "vill_name": "ພູປອດ",
                "vill_name_en": "",
                "dr_id": "609"
              },
              {
                "vill_id": "60970",
                "vill_name": "ວັງສາ",
                "vill_name_en": "",
                "dr_id": "609"
              },
              {
                "vill_id": "60971",
                "vill_name": "ຫ້ວຍກະຕ່ອຍ",
                "vill_name_en": "",
                "dr_id": "609"
              },
              {
                "vill_id": "60972",
                "vill_name": "ຫ້ວຍແສ ້",
                "vill_name_en": "",
                "dr_id": "609"
              },
              {
                "vill_id": "60973",
                "vill_name": "ວັງມ່ວງ",
                "vill_name_en": "",
                "dr_id": "609"
              },
              {
                "vill_id": "60974",
                "vill_name": "ນ້ຳຮາງ",
                "vill_name_en": "",
                "dr_id": "609"
              },
              {
                "vill_id": "60975",
                "vill_name": "ສາມພັນໄຊ",
                "vill_name_en": "",
                "dr_id": "609"
              },
              {
                "vill_id": "60976",
                "vill_name": "ຫ້ວຍຊາຍຂາວ",
                "vill_name_en": "",
                "dr_id": "609"
              },
              {
                "vill_id": "60977",
                "vill_name": "ນາທ່າ",
                "vill_name_en": "",
                "dr_id": "609"
              },
              {
                "vill_id": "60978",
                "vill_name": "ໂຄກສະຫວ່າງ ສາມສູນ",
                "vill_name_en": "",
                "dr_id": "609"
              },
              {
                "vill_id": "60979",
                "vill_name": "ຫ້ວຍແຊ່ງ",
                "vill_name_en": "",
                "dr_id": "609"
              },
              {
                "vill_id": "60980",
                "vill_name": "ສາມພັນໄຊ ນາແລ",
                "vill_name_en": "",
                "dr_id": "609"
              },
              {
                "vill_id": "60981",
                "vill_name": "ສາມພັນໄຊ ນາແລ",
                "vill_name_en": "",
                "dr_id": "609"
              },
              {
                "vill_id": "60982",
                "vill_name": "ກິ່ວແທ່ງ",
                "vill_name_en": "",
                "dr_id": "609"
              },
              {
                "vill_id": "60983",
                "vill_name": "ພູໝ້ຽງ",
                "vill_name_en": "",
                "dr_id": "609"
              },
              {
                "vill_id": "60984",
                "vill_name": "ປາກໂກ່ນ",
                "vill_name_en": "",
                "dr_id": "609"
              },
              {
                "vill_id": "60985",
                "vill_name": "ຫ້ວຍໂປງ",
                "vill_name_en": "",
                "dr_id": "609"
              },
              {
                "vill_id": "60986",
                "vill_name": "ຕາດແລ",
                "vill_name_en": "",
                "dr_id": "609"
              }
            ]
          },
          {
            "dr_id": "610",
            "dr_name": "ວຽງຄຳ",
            "dr_name_en": "Viengkham",
            "pr_id": "6",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "61001",
                "vill_name": "ສົບຊາງ",
                "vill_name_en": "",
                "dr_id": "610"
              },
              {
                "vill_id": "61002",
                "vill_name": "ນາກາງ",
                "vill_name_en": "",
                "dr_id": "610"
              },
              {
                "vill_id": "61003",
                "vill_name": "ວັງບົງ",
                "vill_name_en": "",
                "dr_id": "610"
              },
              {
                "vill_id": "61004",
                "vill_name": "ນ້ຳມີ້",
                "vill_name_en": "",
                "dr_id": "610"
              },
              {
                "vill_id": "61005",
                "vill_name": "ໃຫມ່",
                "vill_name_en": "",
                "dr_id": "610"
              },
              {
                "vill_id": "61006",
                "vill_name": "ພູຜັກແພວ",
                "vill_name_en": "",
                "dr_id": "610"
              },
              {
                "vill_id": "61007",
                "vill_name": "ກິ່ວໄລ",
                "vill_name_en": "",
                "dr_id": "610"
              },
              {
                "vill_id": "61008",
                "vill_name": "ລ້ອງຍາ",
                "vill_name_en": "",
                "dr_id": "610"
              },
              {
                "vill_id": "61009",
                "vill_name": "ດອນຄູນ",
                "vill_name_en": "",
                "dr_id": "610"
              },
              {
                "vill_id": "61010",
                "vill_name": "ຫາດຮຸງ",
                "vill_name_en": "",
                "dr_id": "610"
              },
              {
                "vill_id": "61011",
                "vill_name": "ຫ້ວຍກວ້ງ",
                "vill_name_en": "",
                "dr_id": "610"
              },
              {
                "vill_id": "61012",
                "vill_name": "ຫ້ວຍສ້ອຍ",
                "vill_name_en": "",
                "dr_id": "610"
              },
              {
                "vill_id": "61013",
                "vill_name": "ນານ້ອຍ",
                "vill_name_en": "",
                "dr_id": "610"
              },
              {
                "vill_id": "61014",
                "vill_name": "ຫ້ວຍຈັນ",
                "vill_name_en": "",
                "dr_id": "610"
              },
              {
                "vill_id": "61015",
                "vill_name": "ບວມຫວານ",
                "vill_name_en": "",
                "dr_id": "610"
              },
              {
                "vill_id": "61016",
                "vill_name": "ຫາດຍາວ",
                "vill_name_en": "",
                "dr_id": "610"
              },
              {
                "vill_id": "61075",
                "vill_name": "ວັງເຫິງ",
                "vill_name_en": "",
                "dr_id": "610"
              },
              {
                "vill_id": "61076",
                "vill_name": "ວັງໄບ",
                "vill_name_en": "",
                "dr_id": "610"
              },
              {
                "vill_id": "61077",
                "vill_name": "ແສ້",
                "vill_name_en": "",
                "dr_id": "610"
              },
              {
                "vill_id": "61078",
                "vill_name": "ວຽງຄຳ",
                "vill_name_en": "",
                "dr_id": "610"
              },
              {
                "vill_id": "61079",
                "vill_name": "ຫາດກໍ່",
                "vill_name_en": "",
                "dr_id": "610"
              },
              {
                "vill_id": "61080",
                "vill_name": "ທ່າວານ",
                "vill_name_en": "",
                "dr_id": "610"
              },
              {
                "vill_id": "61081",
                "vill_name": "ໂພນງາມ",
                "vill_name_en": "",
                "dr_id": "610"
              },
              {
                "vill_id": "61082",
                "vill_name": "ໂພນຄຳ",
                "vill_name_en": "",
                "dr_id": "610"
              },
              {
                "vill_id": "61083",
                "vill_name": "ສາມຕົ້ນ",
                "vill_name_en": "",
                "dr_id": "610"
              },
              {
                "vill_id": "61084",
                "vill_name": "ຫ້ວຍເຈາະ",
                "vill_name_en": "",
                "dr_id": "610"
              },
              {
                "vill_id": "61085",
                "vill_name": "ອົມປີງ",
                "vill_name_en": "",
                "dr_id": "610"
              },
              {
                "vill_id": "61086",
                "vill_name": "ພູຄອງ",
                "vill_name_en": "",
                "dr_id": "610"
              },
              {
                "vill_id": "61087",
                "vill_name": "ຫ້ວຍຊວງ",
                "vill_name_en": "",
                "dr_id": "610"
              },
              {
                "vill_id": "61088",
                "vill_name": "ສາມສຸມ",
                "vill_name_en": "",
                "dr_id": "610"
              },
              {
                "vill_id": "61089",
                "vill_name": "ຫ້ວຍກູ້",
                "vill_name_en": "",
                "dr_id": "610"
              },
              {
                "vill_id": "61090",
                "vill_name": "ຫ້ວຍທອງ",
                "vill_name_en": "",
                "dr_id": "610"
              },
              {
                "vill_id": "61091",
                "vill_name": "ວຽງໄຊ",
                "vill_name_en": "",
                "dr_id": "610"
              },
              {
                "vill_id": "61092",
                "vill_name": "ພູແກ້ວ",
                "vill_name_en": "",
                "dr_id": "610"
              },
              {
                "vill_id": "61093",
                "vill_name": "ປາກລາວ",
                "vill_name_en": "",
                "dr_id": "610"
              },
              {
                "vill_id": "61094",
                "vill_name": "ນ້ຳລາວ",
                "vill_name_en": "",
                "dr_id": "610"
              },
              {
                "vill_id": "61095",
                "vill_name": "ໂພນໂຮມ",
                "vill_name_en": "",
                "dr_id": "610"
              },
              {
                "vill_id": "61096",
                "vill_name": "ໂພນແກ້ວ",
                "vill_name_en": "",
                "dr_id": "610"
              },
              {
                "vill_id": "61097",
                "vill_name": "ຜູກາງ",
                "vill_name_en": "",
                "dr_id": "610"
              },
              {
                "vill_id": "61098",
                "vill_name": "ດອນແກ້ວ",
                "vill_name_en": "",
                "dr_id": "610"
              },
              {
                "vill_id": "61099",
                "vill_name": "ດອນແກ້ວ",
                "vill_name_en": "",
                "dr_id": "610"
              },
              {
                "vill_id": "61100",
                "vill_name": "ບວມມື້",
                "vill_name_en": "",
                "dr_id": "610"
              },
              {
                "vill_id": "61101",
                "vill_name": "ມົກວັດ",
                "vill_name_en": "",
                "dr_id": "610"
              },
              {
                "vill_id": "61102",
                "vill_name": "ພູສາລີ",
                "vill_name_en": "",
                "dr_id": "610"
              },
              {
                "vill_id": "61103",
                "vill_name": "ພູສະຫມາມ",
                "vill_name_en": "",
                "dr_id": "610"
              },
              {
                "vill_id": "61104",
                "vill_name": "ມົກຈະລີ",
                "vill_name_en": "",
                "dr_id": "610"
              },
              {
                "vill_id": "61106",
                "vill_name": "ເມືອງຶິວຍ",
                "vill_name_en": "",
                "dr_id": "610"
              },
              {
                "vill_id": "61107",
                "vill_name": "ຫ້ວຍໂກນ",
                "vill_name_en": "",
                "dr_id": "610"
              },
              {
                "vill_id": "61108",
                "vill_name": "ວຽງທອງ",
                "vill_name_en": "",
                "dr_id": "610"
              },
              {
                "vill_id": "61109",
                "vill_name": "ພູໂຕ່ງ",
                "vill_name_en": "",
                "dr_id": "610"
              },
              {
                "vill_id": "61110",
                "vill_name": "ມົກຕະເມັດ",
                "vill_name_en": "",
                "dr_id": "610"
              },
              {
                "vill_id": "61111",
                "vill_name": "ມົກເສິກ",
                "vill_name_en": "",
                "dr_id": "610"
              },
              {
                "vill_id": "61112",
                "vill_name": "ສາ",
                "vill_name_en": "",
                "dr_id": "610"
              },
              {
                "vill_id": "61113",
                "vill_name": "ມົກຄະ",
                "vill_name_en": "",
                "dr_id": "610"
              },
              {
                "vill_id": "61114",
                "vill_name": "ປາ່ຊ້າງ",
                "vill_name_en": "",
                "dr_id": "610"
              },
              {
                "vill_id": "61115",
                "vill_name": "ພູຄຳ",
                "vill_name_en": "",
                "dr_id": "610"
              },
              {
                "vill_id": "61116",
                "vill_name": "ຫ້ວຍໄກ້",
                "vill_name_en": "",
                "dr_id": "610"
              },
              {
                "vill_id": "61117",
                "vill_name": "ວັງຄຳ",
                "vill_name_en": "",
                "dr_id": "610"
              },
              {
                "vill_id": "61118",
                "vill_name": "ຕູ້ມມີງ",
                "vill_name_en": "",
                "dr_id": "610"
              },
              {
                "vill_id": "61119",
                "vill_name": "ສົບເຮືອງ",
                "vill_name_en": "",
                "dr_id": "610"
              },
              {
                "vill_id": "61120",
                "vill_name": "ປ່າໄຜ່",
                "vill_name_en": "",
                "dr_id": "610"
              },
              {
                "vill_id": "61121",
                "vill_name": "ປ່າໄຜ່",
                "vill_name_en": "",
                "dr_id": "610"
              },
              {
                "vill_id": "61122",
                "vill_name": "ຫາດຮຽນ",
                "vill_name_en": "",
                "dr_id": "610"
              },
              {
                "vill_id": "61123",
                "vill_name": "ຫນອງຄຳ",
                "vill_name_en": "",
                "dr_id": "610"
              },
              {
                "vill_id": "61124",
                "vill_name": "ຫ້ວຍພຽງ",
                "vill_name_en": "",
                "dr_id": "610"
              },
              {
                "vill_id": "61125",
                "vill_name": "ຈ່ອງໃຕ້",
                "vill_name_en": "",
                "dr_id": "610"
              },
              {
                "vill_id": "61126",
                "vill_name": "ຈັກກາງ",
                "vill_name_en": "",
                "dr_id": "610"
              },
              {
                "vill_id": "61127",
                "vill_name": "ນ້ຳຊອຍ",
                "vill_name_en": "",
                "dr_id": "610"
              },
              {
                "vill_id": "61128",
                "vill_name": "ນາແວນ",
                "vill_name_en": "",
                "dr_id": "610"
              },
              {
                "vill_id": "61129",
                "vill_name": "ຫາດເລົາ",
                "vill_name_en": "",
                "dr_id": "610"
              },
              {
                "vill_id": "61130",
                "vill_name": "ພູເລີຍ",
                "vill_name_en": "",
                "dr_id": "610"
              },
              {
                "vill_id": "61131",
                "vill_name": "ຫາດເລົາ",
                "vill_name_en": "",
                "dr_id": "610"
              },
              {
                "vill_id": "61132",
                "vill_name": "ໂພນໄຊ",
                "vill_name_en": "Phonsai",
                "dr_id": "610"
              },
              {
                "vill_id": "61133",
                "vill_name": "ທ່າເລົ່າ",
                "vill_name_en": "Thalout",
                "dr_id": "610"
              }
            ]
          },
          {
            "dr_id": "611",
            "dr_name": "ພູຄູນ",
            "dr_name_en": "Phoukhoune",
            "pr_id": "6",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "611002",
                "vill_name": "ພູເລີຍ",
                "vill_name_en": "",
                "dr_id": "611"
              },
              {
                "vill_id": "611003",
                "vill_name": "ພູເລີຍ",
                "vill_name_en": "",
                "dr_id": "611"
              },
              {
                "vill_id": "611004",
                "vill_name": "ທົງສະຕາ",
                "vill_name_en": "",
                "dr_id": "611"
              },
              {
                "vill_id": "611005",
                "vill_name": "ພະລັງຫນູ",
                "vill_name_en": "",
                "dr_id": "611"
              },
              {
                "vill_id": "611006",
                "vill_name": "ໂພນໄຊ",
                "vill_name_en": "",
                "dr_id": "611"
              },
              {
                "vill_id": "611007",
                "vill_name": "ຜາແກ້ງນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "611"
              },
              {
                "vill_id": "611008",
                "vill_name": "ຜາແກ້ງໃຫຍ່",
                "vill_name_en": "",
                "dr_id": "611"
              },
              {
                "vill_id": "611009",
                "vill_name": "ພູຄູນ",
                "vill_name_en": "",
                "dr_id": "611"
              },
              {
                "vill_id": "611010",
                "vill_name": "ຈັ່ງເອືອນ",
                "vill_name_en": "",
                "dr_id": "611"
              },
              {
                "vill_id": "611011",
                "vill_name": "ຊາຍທອງ",
                "vill_name_en": "",
                "dr_id": "611"
              },
              {
                "vill_id": "611012",
                "vill_name": "ຕັ່ງອຸຍ",
                "vill_name_en": "",
                "dr_id": "611"
              },
              {
                "vill_id": "611013",
                "vill_name": "ນ້ຳສວນ",
                "vill_name_en": "",
                "dr_id": "611"
              },
              {
                "vill_id": "611014",
                "vill_name": "ພູຕະແຊະ",
                "vill_name_en": "",
                "dr_id": "611"
              },
              {
                "vill_id": "611015",
                "vill_name": "ບວມພໍ",
                "vill_name_en": "",
                "dr_id": "611"
              },
              {
                "vill_id": "611016",
                "vill_name": "ນາກຶນ",
                "vill_name_en": "",
                "dr_id": "611"
              },
              {
                "vill_id": "611017",
                "vill_name": "ຫີນຕັງ",
                "vill_name_en": "",
                "dr_id": "611"
              },
              {
                "vill_id": "611018",
                "vill_name": "ສະມັງ",
                "vill_name_en": "",
                "dr_id": "611"
              },
              {
                "vill_id": "611019",
                "vill_name": "ໂພນຄຳ",
                "vill_name_en": "",
                "dr_id": "611"
              },
              {
                "vill_id": "611020",
                "vill_name": "ພູວຽງໃຫຍ່",
                "vill_name_en": "",
                "dr_id": "611"
              },
              {
                "vill_id": "611021",
                "vill_name": "ພູວຽງນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "611"
              },
              {
                "vill_id": "611022",
                "vill_name": "ນານ່ານ",
                "vill_name_en": "",
                "dr_id": "611"
              },
              {
                "vill_id": "611023",
                "vill_name": "ສົມບູນນ",
                "vill_name_en": "",
                "dr_id": "611"
              },
              {
                "vill_id": "611024",
                "vill_name": "ສົມບູນນ",
                "vill_name_en": "",
                "dr_id": "611"
              },
              {
                "vill_id": "611025",
                "vill_name": "ພູຢາງ",
                "vill_name_en": "",
                "dr_id": "611"
              },
              {
                "vill_id": "611026",
                "vill_name": "ກິ່ວຢອກ",
                "vill_name_en": "",
                "dr_id": "611"
              },
              {
                "vill_id": "611027",
                "vill_name": "ສວນຫມາກ",
                "vill_name_en": "",
                "dr_id": "611"
              },
              {
                "vill_id": "611028",
                "vill_name": "ພູສູງ",
                "vill_name_en": "",
                "dr_id": "611"
              },
              {
                "vill_id": "611029",
                "vill_name": "ລ້ອງຫມຽງ",
                "vill_name_en": "",
                "dr_id": "611"
              },
              {
                "vill_id": "611030",
                "vill_name": "ເມືອງຈີມ",
                "vill_name_en": "",
                "dr_id": "611"
              },
              {
                "vill_id": "611031",
                "vill_name": "ແສນສີ",
                "vill_name_en": "",
                "dr_id": "611"
              },
              {
                "vill_id": "611032",
                "vill_name": "ສາມແຍກ",
                "vill_name_en": "",
                "dr_id": "611"
              },
              {
                "vill_id": "611034",
                "vill_name": "ບັງກະໂລ",
                "vill_name_en": "",
                "dr_id": "611"
              },
              {
                "vill_id": "611035",
                "vill_name": "ວຽງຄຳລ",
                "vill_name_en": "",
                "dr_id": "611"
              },
              {
                "vill_id": "611036",
                "vill_name": "ພູລັງຈັງ",
                "vill_name_en": "",
                "dr_id": "611"
              },
              {
                "vill_id": "611037",
                "vill_name": "ລ້ອງໂປດ",
                "vill_name_en": "",
                "dr_id": "611"
              },
              {
                "vill_id": "611038",
                "vill_name": "ຜາແກ້ວ",
                "vill_name_en": "",
                "dr_id": "611"
              },
              {
                "vill_id": "611039",
                "vill_name": "ນ້ຳມາດາວ",
                "vill_name_en": "",
                "dr_id": "611"
              },
              {
                "vill_id": "611040",
                "vill_name": "ຫລັກ 5",
                "vill_name_en": "",
                "dr_id": "611"
              },
              {
                "vill_id": "611041",
                "vill_name": "ວຽງສະໄຫມ",
                "vill_name_en": "",
                "dr_id": "611"
              },
              {
                "vill_id": "611042",
                "vill_name": "ຜາຫວາຍ",
                "vill_name_en": "",
                "dr_id": "611"
              },
              {
                "vill_id": "611043",
                "vill_name": "ນ້ຳຕີ່ງ",
                "vill_name_en": "",
                "dr_id": "611"
              },
              {
                "vill_id": "611044",
                "vill_name": "ນ້ຳພໍ",
                "vill_name_en": "",
                "dr_id": "611"
              },
              {
                "vill_id": "611045",
                "vill_name": "ບ້ານວັງຊຽງ",
                "vill_name_en": "",
                "dr_id": "611"
              },
              {
                "vill_id": "611046",
                "vill_name": "ນ້ຳຈາວ",
                "vill_name_en": "",
                "dr_id": "611"
              }
            ]
          },
          {
            "dr_id": "612",
            "dr_name": "ໂພນທອງ",
            "dr_name_en": "Phonthong",
            "pr_id": "6",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "612001",
                "vill_name": "ວັງຊຽງ",
                "vill_name_en": "",
                "dr_id": "612"
              },
              {
                "vill_id": "612002",
                "vill_name": "ປຸ່ງບໍ່",
                "vill_name_en": "",
                "dr_id": "612"
              },
              {
                "vill_id": "612003",
                "vill_name": "ໂດນ",
                "vill_name_en": "",
                "dr_id": "612"
              },
              {
                "vill_id": "612004",
                "vill_name": "ກາງ",
                "vill_name_en": "",
                "dr_id": "612"
              },
              {
                "vill_id": "612005",
                "vill_name": "ປາກອືມ",
                "vill_name_en": "",
                "dr_id": "612"
              },
              {
                "vill_id": "612006",
                "vill_name": "ນາແຈ່ນ",
                "vill_name_en": "",
                "dr_id": "612"
              },
              {
                "vill_id": "612007",
                "vill_name": "ວັງປານ",
                "vill_name_en": "",
                "dr_id": "612"
              },
              {
                "vill_id": "612008",
                "vill_name": "ດອນເງິນ",
                "vill_name_en": "",
                "dr_id": "612"
              },
              {
                "vill_id": "612009",
                "vill_name": "ນາເບີງ",
                "vill_name_en": "",
                "dr_id": "612"
              },
              {
                "vill_id": "612010",
                "vill_name": "ນາໃໝ່",
                "vill_name_en": "",
                "dr_id": "612"
              },
              {
                "vill_id": "612011",
                "vill_name": "ນາຊອນ",
                "vill_name_en": "",
                "dr_id": "612"
              },
              {
                "vill_id": "612012",
                "vill_name": "ລ້ອງຍາງ(ບວກຄວາຍ)",
                "vill_name_en": "",
                "dr_id": "612"
              },
              {
                "vill_id": "612013",
                "vill_name": "ຫີນນແສ້ (ຫີນນແຮ່)",
                "vill_name_en": "",
                "dr_id": "612"
              },
              {
                "vill_id": "612014",
                "vill_name": "ນາຫລວງ",
                "vill_name_en": "",
                "dr_id": "612"
              },
              {
                "vill_id": "612015",
                "vill_name": "ປຸ່ງຮ້ອນ",
                "vill_name_en": "",
                "dr_id": "612"
              },
              {
                "vill_id": "612016",
                "vill_name": "ຫວຍລານ",
                "vill_name_en": "",
                "dr_id": "612"
              },
              {
                "vill_id": "612017",
                "vill_name": "ທອງສີ",
                "vill_name_en": "",
                "dr_id": "612"
              },
              {
                "vill_id": "612018",
                "vill_name": "ທອງໃຕ້",
                "vill_name_en": "",
                "dr_id": "612"
              },
              {
                "vill_id": "612019",
                "vill_name": "ທອງເໜືອ",
                "vill_name_en": "",
                "dr_id": "612"
              },
              {
                "vill_id": "612020",
                "vill_name": "ຫ້ວຍເຮ້ຍ",
                "vill_name_en": "",
                "dr_id": "612"
              },
              {
                "vill_id": "612021",
                "vill_name": "ຫ້ວຍໄຄ້",
                "vill_name_en": "",
                "dr_id": "612"
              },
              {
                "vill_id": "612022",
                "vill_name": "ຊົງຢ່າງ",
                "vill_name_en": "",
                "dr_id": "612"
              },
              {
                "vill_id": "612023",
                "vill_name": "ພູຍີ",
                "vill_name_en": "",
                "dr_id": "612"
              },
              {
                "vill_id": "612024",
                "vill_name": "ກໍ່ນອດ",
                "vill_name_en": "",
                "dr_id": "612"
              },
              {
                "vill_id": "612025",
                "vill_name": "ຫ້ວຍທອງ",
                "vill_name_en": "",
                "dr_id": "612"
              },
              {
                "vill_id": "612026",
                "vill_name": "ເມືອງຣື້ບ",
                "vill_name_en": "",
                "dr_id": "612"
              },
              {
                "vill_id": "612027",
                "vill_name": "ບວມກໍ່",
                "vill_name_en": "",
                "dr_id": "612"
              },
              {
                "vill_id": "612028",
                "vill_name": "ຂາມປ້ອມ",
                "vill_name_en": "",
                "dr_id": "612"
              },
              {
                "vill_id": "612029",
                "vill_name": "ສາມມື່ນ",
                "vill_name_en": "",
                "dr_id": "612"
              },
              {
                "vill_id": "612030",
                "vill_name": "ຍອດສີ",
                "vill_name_en": "",
                "dr_id": "612"
              },
              {
                "vill_id": "612031",
                "vill_name": "ປຸງທອງ",
                "vill_name_en": "",
                "dr_id": "612"
              },
              {
                "vill_id": "612032",
                "vill_name": "ໂພນສຸກ",
                "vill_name_en": "",
                "dr_id": "612"
              },
              {
                "vill_id": "612033",
                "vill_name": "ສົບພົນ",
                "vill_name_en": "",
                "dr_id": "612"
              },
              {
                "vill_id": "612034",
                "vill_name": "ນາຫັວດ",
                "vill_name_en": "",
                "dr_id": "612"
              },
              {
                "vill_id": "612035",
                "vill_name": "ຫ້ວຍແວ່ນ",
                "vill_name_en": "",
                "dr_id": "612"
              },
              {
                "vill_id": "612036",
                "vill_name": "ອົມມົກ",
                "vill_name_en": "",
                "dr_id": "612"
              },
              {
                "vill_id": "612037",
                "vill_name": "ຫ້ວຍຕະໂລ່ງ",
                "vill_name_en": "",
                "dr_id": "612"
              },
              {
                "vill_id": "612038",
                "vill_name": "ພູວຽງ",
                "vill_name_en": "",
                "dr_id": "612"
              },
              {
                "vill_id": "612039",
                "vill_name": "ແຈ້ງເໜືອ",
                "vill_name_en": "",
                "dr_id": "612"
              }
            ]
          }
        ]
      },
      {
        "pr_id": "7",
        "pr_name": "ຫົວພັນ",
        "pr_name_en": "Houaphan",
        "districts": [
          {"dr_name": "ເລືອກເມືອງປະຈຸບັນ", "villages": []},
          {
            "dr_id": "701",
            "dr_name": "ຊຳເໜືອ",
            "dr_name_en": "Xamneua",
            "pr_id": "7",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "70101",
                "vill_name": "ມີ​ສຸກ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70102",
                "vill_name": "ນາ​ອ່າງ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70103",
                "vill_name": "ຫົວ​ຊຽງ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70104",
                "vill_name": "ຄັັ້ງທີ່",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70105",
                "vill_name": "ຊາມ​ງົວ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70106",
                "vill_name": "ຫ້ວຍ ອວງ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70107",
                "vill_name": "ມ່ວນ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70108",
                "vill_name": "ຫ້ວຍ ຫ້ອມ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70109",
                "vill_name": "ອົ້ງ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70110",
                "vill_name": "ກາງ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70111",
                "vill_name": "ຖ້ຳ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70112",
                "vill_name": "ນາ​ໜອງ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70113",
                "vill_name": "ສົບຊາຍ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70114",
                "vill_name": "ກາງ​ນ້ຳ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70115",
                "vill_name": "ນາ​ສະ​ມອງ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70116",
                "vill_name": "ຫ້ວຍຍອງ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70117",
                "vill_name": "ນາ​ສະ​ລາ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70118",
                "vill_name": "ນາ​ຕ້າງ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70119",
                "vill_name": "ພຽງ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70120",
                "vill_name": "ຫີ້ມ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70121",
                "vill_name": "ນາ​ດວງ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70122",
                "vill_name": "ນາຄຳ(ປ່າຍຸງ)",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70123",
                "vill_name": "ພຽງ​ດີ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70124",
                "vill_name": "ນາ​ຄູນ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70125",
                "vill_name": "ຄັງ​ໂຄ່",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70126",
                "vill_name": "ຄັງ​ແຄກ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70127",
                "vill_name": "ຫ້ວຍ​ໂຂ້",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70128",
                "vill_name": "ຫາດກອກ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70129",
                "vill_name": "ປ່າຈ້ານອ້ຍ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70130",
                "vill_name": "ພູ​ຫຼວງ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70131",
                "vill_name": "ພູ​ໂລນ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70132",
                "vill_name": "ຫີນ​ດຳ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70133",
                "vill_name": "ນາເຢີງ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70134",
                "vill_name": "ທັບ​ເຜີ້ງ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70135",
                "vill_name": "ຫົວ​ພູ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70136",
                "vill_name": "ຫ້ວຍ​ເບືອນ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70137",
                "vill_name": "ເມືອງ​ແວ່ນ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70138",
                "vill_name": "ນາ​ລືມ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70139",
                "vill_name": "ນາ​ຫ້ວຍ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70140",
                "vill_name": "ນາ​ກາວ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70141",
                "vill_name": "ຮັງ​ຮອນ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70142",
                "vill_name": "ປ່າ​ຫວານ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70143",
                "vill_name": "ຫ້ວຍ​ຕັງ( ສ້ານ )",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70144",
                "vill_name": "ຫ້ວຍ​ຫານ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70145",
                "vill_name": "ໂຄ້ງ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70146",
                "vill_name": "ເຕື້ອມ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70147",
                "vill_name": "ຫ້ວຍ​ມ້າ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70148",
                "vill_name": "ສົບ​ກ່າ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70149",
                "vill_name": "ເມືອງ​ຍືດ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70150",
                "vill_name": "ນາ​ຄ້າ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70151",
                "vill_name": "ເຮົ້າ​ເໜືອ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70152",
                "vill_name": "ເຮົ້ົ້​າ​ໃຕ້",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70153",
                "vill_name": "ຫົວ​ຄັງ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70154",
                "vill_name": "ຄົດ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70155",
                "vill_name": "ສະ​ເຫລື້ຍ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70156",
                "vill_name": "ແປ້ນ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70157",
                "vill_name": "ຫ້ວຍຈ່ອນ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70158",
                "vill_name": "ຫ້ວຍ​ຖຸມ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70159",
                "vill_name": "ນາ​ທອງ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70160",
                "vill_name": "ອໍ້",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70161",
                "vill_name": "ນ້ຳ​ອີ້",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70162",
                "vill_name": "ລ້ອງ​ເ​ອືອດ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70163",
                "vill_name": "ປ່າ​ຈ້າ​ໃຫ່​ຍ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70164",
                "vill_name": "ນາ​ເຊັງ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70165",
                "vill_name": "ໜອງ​ຄ້າງ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70166",
                "vill_name": "ສະ​ແນນ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70167",
                "vill_name": "ນາ​ເອົ່າ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70168",
                "vill_name": "ໜອງ​ແດງ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70169",
                "vill_name": "ຂົວ​ດີນ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70170",
                "vill_name": "ລ້ອງ​ເມົ້າ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70171",
                "vill_name": "ຮາ​ມ​ໃຕ້",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70172",
                "vill_name": "ນາ​ເມືອງ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70173",
                "vill_name": "ຮາມ​ເໜືອ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70174",
                "vill_name": "ນາ​ຈອງ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70175",
                "vill_name": "ຫ້ວຍ​ຊ່ອນ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70176",
                "vill_name": "ຫຼັກ 12",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70177",
                "vill_name": "ຫ້ວຍສານ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70178",
                "vill_name": "ຊາງ​ຄຳ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70179",
                "vill_name": "ນາ​ຄາ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70180",
                "vill_name": "ນາ​ພຽງ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70181",
                "vill_name": "ກໍ​ໄຮ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70182",
                "vill_name": "ແກ້ວ​ກ້​ວາງ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70183",
                "vill_name": "ໂພນ​ໄຊ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70184",
                "vill_name": "ຫ້ວຍ​ສາດ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70185",
                "vill_name": "ຕະ​ບົງ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70186",
                "vill_name": "ດອນ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70187",
                "vill_name": "ເມືອງຢອງ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70188",
                "vill_name": "ຫ້ວຍ​ຄົງ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70189",
                "vill_name": "ຊຳ​ເໜືອ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70190",
                "vill_name": "ປຸ່ງ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70191",
                "vill_name": "ກ້ານ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70192",
                "vill_name": "ກໍ່",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70193",
                "vill_name": "ງີ້ວ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70194",
                "vill_name": "ລູ້",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70195",
                "vill_name": "ຫ້ວຍໄຄ້​ໃຕ້",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70196",
                "vill_name": "ນາ​ລີວ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70197",
                "vill_name": "ນາ​ວຽງ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70198",
                "vill_name": "ນາໜອງ​ບົວ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "70199",
                "vill_name": "ນາ​ທົ່ງ​ຈອງ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "701099",
                "vill_name": "ພັນ​ໄຊ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "701100",
                "vill_name": "ທາດ​ເມືອງ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "701101",
                "vill_name": "ໂພໄຊ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "701102",
                "vill_name": "ນາ​ສະ​ກາງ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "701103",
                "vill_name": "ບັນ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "701104",
                "vill_name": "ຫັຼກ 20",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "701105",
                "vill_name": "ສິວິໄລ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "701106",
                "vill_name": "ຢາດ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "701107",
                "vill_name": "ໂພນ​ຄຳ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "701108",
                "vill_name": "ຫ້ວຍ​ແປກ",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "701109",
                "vill_name": "ຜາຖີ່",
                "vill_name_en": "",
                "dr_id": "701"
              },
              {
                "vill_id": "701110",
                "vill_name": "ຟອງງ່າ",
                "vill_name_en": "fhongnga",
                "dr_id": "701"
              },
              {
                "vill_id": "701111",
                "vill_name": "ປີກ",
                "vill_name_en": "Napeak",
                "dr_id": "701"
              },
              {
                "vill_id": "701112",
                "vill_name": "ບ້ານນ້ຳກຸບ",
                "vill_name_en": "Namkoup",
                "dr_id": "701"
              },
              {
                "vill_id": "701113",
                "vill_name": "ຕະໂມ",
                "vill_name_en": "Tamo",
                "dr_id": "701"
              },
              {
                "vill_id": "701114",
                "vill_name": "ພຽງໂພ",
                "vill_name_en": "Phiengpho",
                "dr_id": "701"
              },
              {
                "vill_id": "701115",
                "vill_name": "ສະໝອດ",
                "vill_name_en": "Smos",
                "dr_id": "701"
              },
              {
                "vill_id": "701116",
                "vill_name": "ລ້ອງໝໍ້",
                "vill_name_en": "Longmor",
                "dr_id": "701"
              },
              {
                "vill_id": "701117",
                "vill_name": "ວັງທີ",
                "vill_name_en": "vangthee",
                "dr_id": "701"
              }
            ]
          },
          {
            "dr_id": "702",
            "dr_name": "ຊຽງຄໍ້",
            "dr_name_en": "Xiengkhor",
            "pr_id": "7",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "70201",
                "vill_name": "ມອງນ້ຳ",
                "vill_name_en": "",
                "dr_id": "702"
              },
              {
                "vill_id": "70202",
                "vill_name": "ຜາລ້ອງ",
                "vill_name_en": "",
                "dr_id": "702"
              },
              {
                "vill_id": "70203",
                "vill_name": "ທະລາດ",
                "vill_name_en": "",
                "dr_id": "702"
              },
              {
                "vill_id": "70204",
                "vill_name": "ປຸ່ງແສດ",
                "vill_name_en": "",
                "dr_id": "702"
              },
              {
                "vill_id": "70205",
                "vill_name": "ສົບຜ່ອງ",
                "vill_name_en": "",
                "dr_id": "702"
              },
              {
                "vill_id": "70206",
                "vill_name": "ຫົວພູ",
                "vill_name_en": "",
                "dr_id": "702"
              },
              {
                "vill_id": "70207",
                "vill_name": "ປ່າຂົມ",
                "vill_name_en": "",
                "dr_id": "702"
              },
              {
                "vill_id": "70208",
                "vill_name": "ແທ່ນສານ",
                "vill_name_en": "",
                "dr_id": "702"
              },
              {
                "vill_id": "70209",
                "vill_name": "ນາໜອງ",
                "vill_name_en": "",
                "dr_id": "702"
              },
              {
                "vill_id": "70210",
                "vill_name": "ນາເດື່ອ",
                "vill_name_en": "",
                "dr_id": "702"
              },
              {
                "vill_id": "70211",
                "vill_name": "ນາຕອງ",
                "vill_name_en": "",
                "dr_id": "702"
              },
              {
                "vill_id": "70212",
                "vill_name": "ສົບປີ້ນ",
                "vill_name_en": "",
                "dr_id": "702"
              },
              {
                "vill_id": "70213",
                "vill_name": "ຊຽງແດນ",
                "vill_name_en": "",
                "dr_id": "702"
              },
              {
                "vill_id": "70214",
                "vill_name": "ສົບດຸງ",
                "vill_name_en": "",
                "dr_id": "702"
              },
              {
                "vill_id": "70215",
                "vill_name": "ສົບມອນ",
                "vill_name_en": "",
                "dr_id": "702"
              },
              {
                "vill_id": "70216",
                "vill_name": "ພຽງຍຳ",
                "vill_name_en": "",
                "dr_id": "702"
              },
              {
                "vill_id": "70217",
                "vill_name": "ຕະອອນໃຕ້",
                "vill_name_en": "",
                "dr_id": "702"
              },
              {
                "vill_id": "70218",
                "vill_name": "ຫວ້ຍປີດ",
                "vill_name_en": "",
                "dr_id": "702"
              },
              {
                "vill_id": "70219",
                "vill_name": "ນາເຮື້ອ",
                "vill_name_en": "",
                "dr_id": "702"
              },
              {
                "vill_id": "70220",
                "vill_name": "ຫວ້ຍມອນ",
                "vill_name_en": "",
                "dr_id": "702"
              },
              {
                "vill_id": "70221",
                "vill_name": "ໂພນທອງ",
                "vill_name_en": "",
                "dr_id": "702"
              },
              {
                "vill_id": "70222",
                "vill_name": "ນາວຽງ",
                "vill_name_en": "",
                "dr_id": "702"
              },
              {
                "vill_id": "70223",
                "vill_name": "ພຽງສາ",
                "vill_name_en": "",
                "dr_id": "702"
              },
              {
                "vill_id": "70224",
                "vill_name": "ກໍໄຮ",
                "vill_name_en": "",
                "dr_id": "702"
              },
              {
                "vill_id": "70225",
                "vill_name": "ນາຄ້າງ",
                "vill_name_en": "",
                "dr_id": "702"
              },
              {
                "vill_id": "70226",
                "vill_name": "ແຈຝາຍ",
                "vill_name_en": "",
                "dr_id": "702"
              },
              {
                "vill_id": "70227",
                "vill_name": "ຜາເອົ່າ",
                "vill_name_en": "",
                "dr_id": "702"
              },
              {
                "vill_id": "70228",
                "vill_name": "ພຽງໂຮມ A",
                "vill_name_en": "",
                "dr_id": "702"
              },
              {
                "vill_id": "70229",
                "vill_name": "ແກ່ວແວ່ນ",
                "vill_name_en": "",
                "dr_id": "702"
              },
              {
                "vill_id": "70230",
                "vill_name": "ພຽງໂຮມ B",
                "vill_name_en": "",
                "dr_id": "702"
              },
              {
                "vill_id": "70231",
                "vill_name": "ດິນແດງ",
                "vill_name_en": "",
                "dr_id": "702"
              },
              {
                "vill_id": "70232",
                "vill_name": "ສົບເປຍ",
                "vill_name_en": "",
                "dr_id": "702"
              },
              {
                "vill_id": "70233",
                "vill_name": "ຄູນຄຳ",
                "vill_name_en": "",
                "dr_id": "702"
              },
              {
                "vill_id": "70234",
                "vill_name": "ລູບ",
                "vill_name_en": "",
                "dr_id": "702"
              },
              {
                "vill_id": "70235",
                "vill_name": "ຜຸກ",
                "vill_name_en": "",
                "dr_id": "702"
              },
              {
                "vill_id": "70236",
                "vill_name": "ແສ້",
                "vill_name_en": "",
                "dr_id": "702"
              },
              {
                "vill_id": "70237",
                "vill_name": "ສົບສານ",
                "vill_name_en": "",
                "dr_id": "702"
              },
              {
                "vill_id": "70238",
                "vill_name": "ຊຽງຄໍ້",
                "vill_name_en": "",
                "dr_id": "702"
              },
              {
                "vill_id": "70240",
                "vill_name": "ງວມ",
                "vill_name_en": "",
                "dr_id": "702"
              },
              {
                "vill_id": "70241",
                "vill_name": "ໂພນນາ",
                "vill_name_en": "",
                "dr_id": "702"
              },
              {
                "vill_id": "70242",
                "vill_name": "ໂນາຍົມ",
                "vill_name_en": "",
                "dr_id": "702"
              },
              {
                "vill_id": "70243",
                "vill_name": "ນາຍົມ",
                "vill_name_en": "",
                "dr_id": "702"
              },
              {
                "vill_id": "70244",
                "vill_name": "ຫ້ວຍລາງ",
                "vill_name_en": "",
                "dr_id": "702"
              },
              {
                "vill_id": "70245",
                "vill_name": "ບາວ",
                "vill_name_en": "",
                "dr_id": "702"
              },
              {
                "vill_id": "70246",
                "vill_name": "ຮັບ",
                "vill_name_en": "",
                "dr_id": "702"
              },
              {
                "vill_id": "70247",
                "vill_name": "ຜົ້ງຊາຍ",
                "vill_name_en": "",
                "dr_id": "702"
              },
              {
                "vill_id": "70248",
                "vill_name": "ວັງຕັ້ງ",
                "vill_name_en": "",
                "dr_id": "702"
              },
              {
                "vill_id": "70249",
                "vill_name": "ສົບຊາຍ",
                "vill_name_en": "",
                "dr_id": "702"
              },
              {
                "vill_id": "70250",
                "vill_name": "ສົບລ້ອງ",
                "vill_name_en": "",
                "dr_id": "702"
              },
              {
                "vill_id": "70251",
                "vill_name": "ສົບໂກຍ",
                "vill_name_en": "",
                "dr_id": "702"
              },
              {
                "vill_id": "70252",
                "vill_name": "ນາຂາມ",
                "vill_name_en": "",
                "dr_id": "702"
              },
              {
                "vill_id": "70253",
                "vill_name": "ພຽງຊາງ",
                "vill_name_en": "",
                "dr_id": "702"
              },
              {
                "vill_id": "70254",
                "vill_name": "ຫົວນາ",
                "vill_name_en": "",
                "dr_id": "702"
              },
              {
                "vill_id": "70255",
                "vill_name": "ກົກໄຊ",
                "vill_name_en": "",
                "dr_id": "702"
              },
              {
                "vill_id": "70256",
                "vill_name": "ກົງຄຳ",
                "vill_name_en": "",
                "dr_id": "702"
              },
              {
                "vill_id": "70257",
                "vill_name": "ສົບວັ້ນ",
                "vill_name_en": "",
                "dr_id": "702"
              },
              {
                "vill_id": "70258",
                "vill_name": "ໜອງຄຳ",
                "vill_name_en": "",
                "dr_id": "702"
              },
              {
                "vill_id": "70259",
                "vill_name": "ມ່ວງ",
                "vill_name_en": "",
                "dr_id": "702"
              },
              {
                "vill_id": "70260",
                "vill_name": "ແກ່ວລົມ",
                "vill_name_en": "",
                "dr_id": "702"
              }
            ]
          },
          {
            "dr_id": "703",
            "dr_name": "ຮ້ຽມ",
            "dr_name_en": "Hiam",
            "pr_id": "7",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "70301",
                "vill_name": "ນາທວນ",
                "vill_name_en": "",
                "dr_id": "703"
              },
              {
                "vill_id": "70302",
                "vill_name": "ສົບມານ",
                "vill_name_en": "",
                "dr_id": "703"
              },
              {
                "vill_id": "70303",
                "vill_name": "ຄອນງົວ",
                "vill_name_en": "",
                "dr_id": "703"
              },
              {
                "vill_id": "70304",
                "vill_name": "ໂພນຄຳ",
                "vill_name_en": "",
                "dr_id": "703"
              },
              {
                "vill_id": "70305",
                "vill_name": "ພັນລໍ",
                "vill_name_en": "",
                "dr_id": "703"
              },
              {
                "vill_id": "70306",
                "vill_name": "ພຽງດີ",
                "vill_name_en": "",
                "dr_id": "703"
              },
              {
                "vill_id": "70307",
                "vill_name": "ນາແລ",
                "vill_name_en": "",
                "dr_id": "703"
              },
              {
                "vill_id": "70308",
                "vill_name": "ໂຄ້ງ",
                "vill_name_en": "",
                "dr_id": "703"
              },
              {
                "vill_id": "70309",
                "vill_name": "ຫາດສາ",
                "vill_name_en": "",
                "dr_id": "703"
              },
              {
                "vill_id": "70310",
                "vill_name": "ສະກົກ",
                "vill_name_en": "",
                "dr_id": "703"
              },
              {
                "vill_id": "70311",
                "vill_name": "ດອນຄູນ",
                "vill_name_en": "",
                "dr_id": "703"
              },
              {
                "vill_id": "70312",
                "vill_name": "ນາຫນົມ",
                "vill_name_en": "",
                "dr_id": "703"
              },
              {
                "vill_id": "70313",
                "vill_name": "ທາດ",
                "vill_name_en": "",
                "dr_id": "703"
              },
              {
                "vill_id": "70315",
                "vill_name": "ວັດ",
                "vill_name_en": "",
                "dr_id": "703"
              },
              {
                "vill_id": "70316",
                "vill_name": "ຫ້ວຍຫອມ",
                "vill_name_en": "",
                "dr_id": "703"
              },
              {
                "vill_id": "70317",
                "vill_name": "ຊ່ອນເຫນືອ",
                "vill_name_en": "",
                "dr_id": "703"
              },
              {
                "vill_id": "70318",
                "vill_name": "ແລ້ງ",
                "vill_name_en": "",
                "dr_id": "703"
              },
              {
                "vill_id": "70319",
                "vill_name": "ນ້ຳສາດ",
                "vill_name_en": "",
                "dr_id": "703"
              },
              {
                "vill_id": "70320",
                "vill_name": "ຫ້ວຍສະຫງອນ",
                "vill_name_en": "",
                "dr_id": "703"
              },
              {
                "vill_id": "70321",
                "vill_name": "ມັ້ນ",
                "vill_name_en": "",
                "dr_id": "703"
              },
              {
                "vill_id": "70322",
                "vill_name": "ໂງ່ນ",
                "vill_name_en": "",
                "dr_id": "703"
              },
              {
                "vill_id": "70323",
                "vill_name": "ຊ່ອນໃຕ້",
                "vill_name_en": "",
                "dr_id": "703"
              },
              {
                "vill_id": "70324",
                "vill_name": "ບໍ່",
                "vill_name_en": "",
                "dr_id": "703"
              },
              {
                "vill_id": "70325",
                "vill_name": "ປຸ່ງໄຮ",
                "vill_name_en": "",
                "dr_id": "703"
              },
              {
                "vill_id": "70326",
                "vill_name": "ພູຫລວງ",
                "vill_name_en": "",
                "dr_id": "703"
              },
              {
                "vill_id": "70327",
                "vill_name": "ກໍກ້ຽງ",
                "vill_name_en": "",
                "dr_id": "703"
              },
              {
                "vill_id": "70328",
                "vill_name": "ພຽງໂດນ",
                "vill_name_en": "",
                "dr_id": "703"
              },
              {
                "vill_id": "70329",
                "vill_name": "ສາມສຸມ",
                "vill_name_en": "",
                "dr_id": "703"
              },
              {
                "vill_id": "70330",
                "vill_name": "ນາປວກ",
                "vill_name_en": "",
                "dr_id": "703"
              },
              {
                "vill_id": "70331",
                "vill_name": "ຖ້ຳລາເຫນືອ",
                "vill_name_en": "",
                "dr_id": "703"
              },
              {
                "vill_id": "70332",
                "vill_name": "ນາກູດ",
                "vill_name_en": "",
                "dr_id": "703"
              },
              {
                "vill_id": "70334",
                "vill_name": "ນາໂພນ",
                "vill_name_en": "",
                "dr_id": "703"
              },
              {
                "vill_id": "70335",
                "vill_name": "ຖ້ຳລາໃຕ້",
                "vill_name_en": "",
                "dr_id": "703"
              },
              {
                "vill_id": "70336",
                "vill_name": "ຫນອງໃສ",
                "vill_name_en": "",
                "dr_id": "703"
              },
              {
                "vill_id": "70337",
                "vill_name": "ຫ້ວຍຍາມ",
                "vill_name_en": "",
                "dr_id": "703"
              },
              {
                "vill_id": "70338",
                "vill_name": "ຫ້ວຍເຫມືອຍ",
                "vill_name_en": "",
                "dr_id": "703"
              },
              {
                "vill_id": "70339",
                "vill_name": "ນາວຽງ",
                "vill_name_en": "",
                "dr_id": "703"
              },
              {
                "vill_id": "70340",
                "vill_name": "ທາດຮ້ຽມ",
                "vill_name_en": "",
                "dr_id": "703"
              },
              {
                "vill_id": "70341",
                "vill_name": "ກາງ",
                "vill_name_en": "",
                "dr_id": "703"
              },
              {
                "vill_id": "70342",
                "vill_name": "ນ້ຳເນີນ",
                "vill_name_en": "",
                "dr_id": "703"
              },
              {
                "vill_id": "70343",
                "vill_name": "ເມືອງຮ້ຽມ",
                "vill_name_en": "",
                "dr_id": "703"
              },
              {
                "vill_id": "70344",
                "vill_name": "ສຳພັນທອງ",
                "vill_name_en": "",
                "dr_id": "703"
              },
              {
                "vill_id": "70345",
                "vill_name": "ພູວຽງ",
                "vill_name_en": "",
                "dr_id": "703"
              },
              {
                "vill_id": "70346",
                "vill_name": "ບວມຝາດ",
                "vill_name_en": "",
                "dr_id": "703"
              },
              {
                "vill_id": "70347",
                "vill_name": "ໂພນຊ່ອງ",
                "vill_name_en": "",
                "dr_id": "703"
              },
              {
                "vill_id": "70348",
                "vill_name": "ນາທົ່ງປໍ",
                "vill_name_en": "",
                "dr_id": "703"
              },
              {
                "vill_id": "70349",
                "vill_name": "ນ້ຳງາວ",
                "vill_name_en": "",
                "dr_id": "703"
              },
              {
                "vill_id": "70350",
                "vill_name": "ວັງແຟນ",
                "vill_name_en": "",
                "dr_id": "703"
              },
              {
                "vill_id": "70351",
                "vill_name": "ຫ້ວຍສູ້",
                "vill_name_en": "",
                "dr_id": "703"
              },
              {
                "vill_id": "70352",
                "vill_name": "ນາກຽນ",
                "vill_name_en": "",
                "dr_id": "703"
              },
              {
                "vill_id": "70353",
                "vill_name": "ເມືອງເປີ້",
                "vill_name_en": "",
                "dr_id": "703"
              },
              {
                "vill_id": "70354",
                "vill_name": "ສົບຕິວ",
                "vill_name_en": "",
                "dr_id": "703"
              },
              {
                "vill_id": "70355",
                "vill_name": "ບົງ",
                "vill_name_en": "",
                "dr_id": "703"
              },
              {
                "vill_id": "70356",
                "vill_name": "ໂພນສະອາດ",
                "vill_name_en": "",
                "dr_id": "703"
              },
              {
                "vill_id": "70357",
                "vill_name": "ແທ່ນຮີງ",
                "vill_name_en": "",
                "dr_id": "703"
              },
              {
                "vill_id": "70358",
                "vill_name": "ນ້ຳປຸງ",
                "vill_name_en": "",
                "dr_id": "703"
              },
              {
                "vill_id": "70359",
                "vill_name": "ເມືອງເກົ້າ",
                "vill_name_en": "",
                "dr_id": "703"
              },
              {
                "vill_id": "70360",
                "vill_name": "ວັງຄວ້າງ",
                "vill_name_en": "",
                "dr_id": "703"
              },
              {
                "vill_id": "70361",
                "vill_name": "ຈັກ",
                "vill_name_en": "",
                "dr_id": "703"
              },
              {
                "vill_id": "70362",
                "vill_name": "ລ້ອງງົວປ່າ",
                "vill_name_en": "",
                "dr_id": "703"
              },
              {
                "vill_id": "70363",
                "vill_name": "ນາຫມໍ້",
                "vill_name_en": "",
                "dr_id": "703"
              },
              {
                "vill_id": "70364",
                "vill_name": "ຊາຍ",
                "vill_name_en": "",
                "dr_id": "703"
              },
              {
                "vill_id": "70365",
                "vill_name": "ພູດ່ານ",
                "vill_name_en": "",
                "dr_id": "703"
              },
              {
                "vill_id": "70366",
                "vill_name": "ຫົວພູ",
                "vill_name_en": "",
                "dr_id": "703"
              },
              {
                "vill_id": "70367",
                "vill_name": "ຫ້ວຍສາ",
                "vill_name_en": "",
                "dr_id": "703"
              },
              {
                "vill_id": "70368",
                "vill_name": "ປຸ່ງປິ່ນ",
                "vill_name_en": "",
                "dr_id": "703"
              },
              {
                "vill_id": "70369",
                "vill_name": "ຫ້ວຍເລົາ",
                "vill_name_en": "",
                "dr_id": "703"
              },
              {
                "vill_id": "70370",
                "vill_name": "ຫ້ວຍຕືນ",
                "vill_name_en": "",
                "dr_id": "703"
              },
              {
                "vill_id": "70371",
                "vill_name": "ວຽງທອງ",
                "vill_name_en": "viengthong",
                "dr_id": "703"
              }
            ]
          },
          {
            "dr_id": "704",
            "dr_name": "ວຽງໄຊ",
            "dr_name_en": "Viengxay",
            "pr_id": "7",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "70401",
                "vill_name": "ບັກ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70402",
                "vill_name": "ພູໄຊ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70403",
                "vill_name": "ລ້ອງກູ້",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70404",
                "vill_name": "ວັງແຊງ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70405",
                "vill_name": "ນາແຮງ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70406",
                "vill_name": "ກະລືນ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70407",
                "vill_name": "ສາດ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70408",
                "vill_name": "ປຸ່ງ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70410",
                "vill_name": "ເມືອງປົວ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70411",
                "vill_name": "ນາມ່າວ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70412",
                "vill_name": "ນາສາ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70413",
                "vill_name": "ນາລຸ້ງ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70415",
                "vill_name": "ປຸ່ງນາຄາວ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70416",
                "vill_name": "ຄໍ້",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70417",
                "vill_name": "ນາເຮັ້ຍ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70418",
                "vill_name": "ບໍ່ຜາ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70419",
                "vill_name": "ຊຽງແມນ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70420",
                "vill_name": "ອານຸໄຊ(ວຽງໄຊ)",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70421",
                "vill_name": "ຊຽງ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70422",
                "vill_name": "ພຽງໄຄ້",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70423",
                "vill_name": "ສົ້ມ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70424",
                "vill_name": "ພູນເກົ່າ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70425",
                "vill_name": "ພູນໃຫມ່",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70426",
                "vill_name": "ເດີຍ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70427",
                "vill_name": "ພຽງເກີນ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70428",
                "vill_name": "ປອກ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70429",
                "vill_name": "ຈາດ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70430",
                "vill_name": "ນາຕານ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70431",
                "vill_name": "ສັ້ງ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70432",
                "vill_name": "ວຽງ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70433",
                "vill_name": "ແຮ້ວ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70434",
                "vill_name": "ແຍ້",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70435",
                "vill_name": "ນາຢ້າງ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70436",
                "vill_name": "ອ່າງລັງ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70437",
                "vill_name": "ອ່າງສັ້ງ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70438",
                "vill_name": "ພຽງເຈ້ຍ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70439",
                "vill_name": "ອ່າງກ້ວນ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70440",
                "vill_name": "ນາຄາວ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70441",
                "vill_name": "ຍອດອ່າງ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70442",
                "vill_name": "ດ່ານເຜົ້າ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70443",
                "vill_name": "ບໍ່",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70444",
                "vill_name": "ກ້າງທາດ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70446",
                "vill_name": "ກ້າງມົງ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70447",
                "vill_name": "ນາຫໍ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70448",
                "vill_name": "ນາງົວ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70449",
                "vill_name": "ນາຝູງ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70450",
                "vill_name": "ໂສຍ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70451",
                "vill_name": "ກ້ຽນ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70452",
                "vill_name": "ແທ່ນ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70453",
                "vill_name": "ງິ້ວ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70454",
                "vill_name": "ດ່ານ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70455",
                "vill_name": "ດ່ານໄຊ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70456",
                "vill_name": "ນາວີດ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70457",
                "vill_name": "ຊຽງລວງ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70458",
                "vill_name": "ນາລວງ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70459",
                "vill_name": "ນາມອນ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70460",
                "vill_name": "ໂພນໄຊ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70461",
                "vill_name": "ນາເມືອງ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70462",
                "vill_name": "ຊຽງຊີ້",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70463",
                "vill_name": "ຫ້ວຍມ່ວງ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70464",
                "vill_name": "ລຸນ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70465",
                "vill_name": "ນາຈອງ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70466",
                "vill_name": "ຕະແອນ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70467",
                "vill_name": "ຝາດ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70468",
                "vill_name": "ເຫມືອດ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70469",
                "vill_name": "ນາໂພ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70470",
                "vill_name": "ເມືອງປານ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70471",
                "vill_name": "ນາເຮືອ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70472",
                "vill_name": "ນາຮາມ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70473",
                "vill_name": "ນາມັ້ນ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70474",
                "vill_name": "ຫີນດຳ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70475",
                "vill_name": "ພ້ອງງາ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70476",
                "vill_name": "ພູນເຫນືອ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70477",
                "vill_name": "ພູນສ້ານ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70478",
                "vill_name": "ພູນກາງ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70479",
                "vill_name": "ພ້ອງຊ້າງ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70480",
                "vill_name": "ພຽງໄຊ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70481",
                "vill_name": "ແກ້ງປູນ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70482",
                "vill_name": "ແຈຝາຍ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70483",
                "vill_name": "ເມືອງລຽດ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70484",
                "vill_name": "ນາປຸ່ງ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70485",
                "vill_name": "ພູເຫມືອດ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70486",
                "vill_name": "ຫາງລ້ອງ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70487",
                "vill_name": "ວັງໄຮ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70488",
                "vill_name": "ຈະຄຽນ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70489",
                "vill_name": "ເມືອງງາ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70490",
                "vill_name": "ນາແທ່ນ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70491",
                "vill_name": "ຊຽງແກນ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70492",
                "vill_name": "ໂພນທອງ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70493",
                "vill_name": "ຕອງ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70494",
                "vill_name": "ພຽງລຽງ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70495",
                "vill_name": "ລ້ອງເລົາ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70496",
                "vill_name": "ມັ້ນ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70497",
                "vill_name": "ນາໄກ່",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70498",
                "vill_name": "ເອີນ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70499",
                "vill_name": "ເປີມ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "70500",
                "vill_name": "ສຳພັນໄຊ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "704099",
                "vill_name": "ສຳພັນໄຊ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "704100",
                "vill_name": "ພຽງຄ່າ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "704101",
                "vill_name": "ລ້ອງກ້ານ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "704102",
                "vill_name": "ຫ້ວຍຫຍ້າ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "704103",
                "vill_name": "ໂຮມພັນ",
                "vill_name_en": "",
                "dr_id": "704"
              },
              {
                "vill_id": "704104",
                "vill_name": "ສັງ",
                "vill_name_en": "Sang",
                "dr_id": "704"
              },
              {
                "vill_id": "704105",
                "vill_name": "ປຸງປົວ",
                "vill_name_en": "puongphue",
                "dr_id": "704"
              },
              {
                "vill_id": "704106",
                "vill_name": "ຖ້ຳຄວາຍ",
                "vill_name_en": "Thumkheuay",
                "dr_id": "704"
              },
              {
                "vill_id": "704107",
                "vill_name": "ຊ່ອນເໜືອ",
                "vill_name_en": "Soneneao",
                "dr_id": "704"
              },
              {
                "vill_id": "704108",
                "vill_name": "ວຽງໄຊ",
                "vill_name_en": "Viengxay",
                "dr_id": "704"
              },
              {
                "vill_id": "704109",
                "vill_name": "ກ້າງເມືອງ",
                "vill_name_en": "Kangmoaung",
                "dr_id": "704"
              }
            ]
          },
          {
            "dr_id": "705",
            "dr_name": "ຫົວເມືອງ",
            "dr_name_en": "Huameuang",
            "pr_id": "7",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "70501",
                "vill_name": "ສົບລາບ",
                "vill_name_en": "",
                "dr_id": "705"
              },
              {
                "vill_id": "70502",
                "vill_name": "ໂຮມທອງ",
                "vill_name_en": "",
                "dr_id": "705"
              },
              {
                "vill_id": "70503",
                "vill_name": "ໂຮມພັນ",
                "vill_name_en": "",
                "dr_id": "705"
              },
              {
                "vill_id": "70504",
                "vill_name": "ນາພຽງ",
                "vill_name_en": "",
                "dr_id": "705"
              },
              {
                "vill_id": "70505",
                "vill_name": "ຄັງຄາວ",
                "vill_name_en": "",
                "dr_id": "705"
              },
              {
                "vill_id": "70506",
                "vill_name": "ຫ້ວຍຫູ",
                "vill_name_en": "",
                "dr_id": "705"
              },
              {
                "vill_id": "70507",
                "vill_name": "ຜ່ານາງ",
                "vill_name_en": "",
                "dr_id": "705"
              },
              {
                "vill_id": "70508",
                "vill_name": "ລອ້ງອັ່ງ",
                "vill_name_en": "",
                "dr_id": "705"
              },
              {
                "vill_id": "70509",
                "vill_name": "ຫວ້ຍໄຄ້",
                "vill_name_en": "",
                "dr_id": "705"
              },
              {
                "vill_id": "70510",
                "vill_name": "ທາດຜາຕັ້ງ",
                "vill_name_en": "",
                "dr_id": "705"
              },
              {
                "vill_id": "70511",
                "vill_name": "ບໍ",
                "vill_name_en": "",
                "dr_id": "705"
              },
              {
                "vill_id": "70512",
                "vill_name": "ບວກ",
                "vill_name_en": "",
                "dr_id": "705"
              },
              {
                "vill_id": "70513",
                "vill_name": "ຖ້ຳ",
                "vill_name_en": "",
                "dr_id": "705"
              },
              {
                "vill_id": "70514",
                "vill_name": "ຖ້ຳຫຼອດ",
                "vill_name_en": "",
                "dr_id": "705"
              },
              {
                "vill_id": "70515",
                "vill_name": "ແກ່ວເລິກ",
                "vill_name_en": "",
                "dr_id": "705"
              },
              {
                "vill_id": "70516",
                "vill_name": "ແກ່ວສະລາ",
                "vill_name_en": "",
                "dr_id": "705"
              },
              {
                "vill_id": "70517",
                "vill_name": "ຜ່າສິວ",
                "vill_name_en": "",
                "dr_id": "705"
              },
              {
                "vill_id": "70518",
                "vill_name": "ສົງຄາວ",
                "vill_name_en": "",
                "dr_id": "705"
              },
              {
                "vill_id": "70519",
                "vill_name": "ນ້ຳແລມ",
                "vill_name_en": "",
                "dr_id": "705"
              },
              {
                "vill_id": "70520",
                "vill_name": "ປ່າ",
                "vill_name_en": "",
                "dr_id": "705"
              },
              {
                "vill_id": "70521",
                "vill_name": "ນາງິວ",
                "vill_name_en": "",
                "dr_id": "705"
              },
              {
                "vill_id": "70522",
                "vill_name": "ລອ້ງ",
                "vill_name_en": "",
                "dr_id": "705"
              },
              {
                "vill_id": "70524",
                "vill_name": "ສະໜອດ",
                "vill_name_en": "",
                "dr_id": "705"
              },
              {
                "vill_id": "70525",
                "vill_name": "ກໍພຸກ",
                "vill_name_en": "",
                "dr_id": "705"
              },
              {
                "vill_id": "70526",
                "vill_name": "ນາໜູ",
                "vill_name_en": "",
                "dr_id": "705"
              },
              {
                "vill_id": "70527",
                "vill_name": "ນ້ຳຕີບ",
                "vill_name_en": "Namtip",
                "dr_id": "705"
              },
              {
                "vill_id": "70528",
                "vill_name": "ນາເກງ",
                "vill_name_en": "nakeng",
                "dr_id": "705"
              },
              {
                "vill_id": "70529",
                "vill_name": "ຫາດໄກ້",
                "vill_name_en": "hardkaiy",
                "dr_id": "705"
              },
              {
                "vill_id": "70530",
                "vill_name": "ລານຊຽງ",
                "vill_name_en": "Lansieng",
                "dr_id": "705"
              },
              {
                "vill_id": "70531",
                "vill_name": "ບວມງາມ",
                "vill_name_en": "Boumngarm",
                "dr_id": "705"
              },
              {
                "vill_id": "70532",
                "vill_name": "ໂພນຄັງ",
                "vill_name_en": "Phonkhang",
                "dr_id": "705"
              },
              {
                "vill_id": "70533",
                "vill_name": "ຫົວຊຽງ",
                "vill_name_en": "Huaxieng",
                "dr_id": "705"
              },
              {
                "vill_id": "70534",
                "vill_name": "ສຸມລວງ",
                "vill_name_en": "Huaxieng",
                "dr_id": "705"
              },
              {
                "vill_id": "70535",
                "vill_name": "ສົບເລົ້າ",
                "vill_name_en": "Soplao",
                "dr_id": "705"
              },
              {
                "vill_id": "70536",
                "vill_name": "ພຽງດີ",
                "vill_name_en": "Phiengdee",
                "dr_id": "705"
              },
              {
                "vill_id": "70537",
                "vill_name": "ພ່ານ",
                "vill_name_en": "Phan",
                "dr_id": "705"
              }
            ]
          },
          {
            "dr_id": "706",
            "dr_name": "ຊຳໃຕ້",
            "dr_name_en": "Xamtay",
            "pr_id": "7",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "706001",
                "vill_name": "ພຽງເຜືອ",
                "vill_name_en": "Phiengpheua",
                "dr_id": "706"
              },
              {
                "vill_id": "706002",
                "vill_name": "ເມືອງຂັນ",
                "vill_name_en": "Meuangkhan",
                "dr_id": "706"
              },
              {
                "vill_id": "706003",
                "vill_name": "ສົບແກ້ມ",
                "vill_name_en": "sopkaem",
                "dr_id": "706"
              },
              {
                "vill_id": "706004",
                "vill_name": "ນາມົນ",
                "vill_name_en": "Namon",
                "dr_id": "706"
              },
              {
                "vill_id": "706005",
                "vill_name": "ພຽງຄູນ",
                "vill_name_en": "Phiengkoun",
                "dr_id": "706"
              },
              {
                "vill_id": "706006",
                "vill_name": "ບ້ານໂຄ້ງເລົາ",
                "vill_name_en": "Khonglao",
                "dr_id": "706"
              },
              {
                "vill_id": "706007",
                "vill_name": "ເຕົ້າ",
                "vill_name_en": "TAO",
                "dr_id": "706"
              },
              {
                "vill_id": "706008",
                "vill_name": "ບ້ານນາວີ້ນ",
                "vill_name_en": "Navin",
                "dr_id": "706"
              },
              {
                "vill_id": "706009",
                "vill_name": "ເລົາ",
                "vill_name_en": "Lao",
                "dr_id": "706"
              },
              {
                "vill_id": "706010",
                "vill_name": "ຖິ່ນ",
                "vill_name_en": "Thin",
                "dr_id": "706"
              },
              {
                "vill_id": "706011",
                "vill_name": "ຕີນຜາ",
                "vill_name_en": "Tinpha",
                "dr_id": "706"
              },
              {
                "vill_id": "706012",
                "vill_name": "ຄໍ້",
                "vill_name_en": "Khor",
                "dr_id": "706"
              },
              {
                "vill_id": "706013",
                "vill_name": "ກາງ",
                "vill_name_en": "Kang",
                "dr_id": "706"
              },
              {
                "vill_id": "706014",
                "vill_name": "ຫ້ວຍສະແຫຼບ",
                "vill_name_en": "Houaysalaep",
                "dr_id": "706"
              },
              {
                "vill_id": "706015",
                "vill_name": "ຊຽງດີ",
                "vill_name_en": "Xiengdy",
                "dr_id": "706"
              },
              {
                "vill_id": "706016",
                "vill_name": "ບ້ານພຽງໃຫມ່",
                "vill_name_en": "Phiengmai",
                "dr_id": "706"
              },
              {
                "vill_id": "706017",
                "vill_name": "ນາແຊວ",
                "vill_name_en": "Nasaew",
                "dr_id": "706"
              },
              {
                "vill_id": "706018",
                "vill_name": "ເຜື່ອເໜືອ",
                "vill_name_en": "Pheannean",
                "dr_id": "706"
              },
              {
                "vill_id": "706019",
                "vill_name": "ອີດ",
                "vill_name_en": "Eea",
                "dr_id": "706"
              },
              {
                "vill_id": "706020",
                "vill_name": "ພັດໃຕ້",
                "vill_name_en": "Phattai",
                "dr_id": "706"
              },
              {
                "vill_id": "706021",
                "vill_name": "ສົບສານ",
                "vill_name_en": "Sopsan",
                "dr_id": "706"
              },
              {
                "vill_id": "706022",
                "vill_name": "ພັນສະຫວັນ",
                "vill_name_en": "Phansavane",
                "dr_id": "706"
              },
              {
                "vill_id": "706023",
                "vill_name": "ຕົ່ງອູ",
                "vill_name_en": "Tongeou",
                "dr_id": "706"
              },
              {
                "vill_id": "706024",
                "vill_name": "ຕົ່ງອູ",
                "vill_name_en": "Tongeou",
                "dr_id": "706"
              },
              {
                "vill_id": "706025",
                "vill_name": "ຫົວຊຽງ",
                "vill_name_en": "Hoausieng",
                "dr_id": "706"
              },
              {
                "vill_id": "706026",
                "vill_name": "ແຕ້ນ",
                "vill_name_en": "Taan",
                "dr_id": "706"
              },
              {
                "vill_id": "706027",
                "vill_name": "ດ່ານ",
                "vill_name_en": "Dan",
                "dr_id": "706"
              },
              {
                "vill_id": "706028",
                "vill_name": "ເມືອງນາ",
                "vill_name_en": "Meuangna",
                "dr_id": "706"
              },
              {
                "vill_id": "706029",
                "vill_name": "ທາເລົ່າ",
                "vill_name_en": "Thaloue",
                "dr_id": "706"
              },
              {
                "vill_id": "706030",
                "vill_name": "ວື້ນ",
                "vill_name_en": "Vuan",
                "dr_id": "706"
              },
              {
                "vill_id": "706031",
                "vill_name": "ຫ້ວຍສາລາ",
                "vill_name_en": "Hoauwsala",
                "dr_id": "706"
              },
              {
                "vill_id": "706032",
                "vill_name": "ຫາງລ້ອງ",
                "vill_name_en": "Hanglok",
                "dr_id": "706"
              },
              {
                "vill_id": "706033",
                "vill_name": "ຫົວນາ",
                "vill_name_en": "Houawna",
                "dr_id": "706"
              }
            ]
          },
          {
            "dr_id": "707",
            "dr_name": "ສົບເບົາ",
            "dr_name_en": "Sopbao",
            "pr_id": "7",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "70701",
                "vill_name": "ສົບຮາວ",
                "vill_name_en": "",
                "dr_id": "707"
              },
              {
                "vill_id": "70703",
                "vill_name": "ກາງຮາວ",
                "vill_name_en": "",
                "dr_id": "707"
              },
              {
                "vill_id": "70704",
                "vill_name": "ນາລົກ(ນາໂພນສຸກ)",
                "vill_name_en": "",
                "dr_id": "707"
              },
              {
                "vill_id": "70705",
                "vill_name": "ນາຊາງ",
                "vill_name_en": "",
                "dr_id": "707"
              },
              {
                "vill_id": "70706",
                "vill_name": "ໂພນຊາຍ",
                "vill_name_en": "",
                "dr_id": "707"
              },
              {
                "vill_id": "70707",
                "vill_name": "ນາຍົມ",
                "vill_name_en": "",
                "dr_id": "707"
              },
              {
                "vill_id": "70708",
                "vill_name": "ຫ້ວຍຕຸງ",
                "vill_name_en": "",
                "dr_id": "707"
              },
              {
                "vill_id": "70709",
                "vill_name": "ນ້ຳແຫ້ງ",
                "vill_name_en": "",
                "dr_id": "707"
              },
              {
                "vill_id": "70710",
                "vill_name": "ນາພາມ",
                "vill_name_en": "",
                "dr_id": "707"
              },
              {
                "vill_id": "70711",
                "vill_name": "ຮາວແສນ",
                "vill_name_en": "",
                "dr_id": "707"
              },
              {
                "vill_id": "70712",
                "vill_name": "ນາຄຳຮາວ",
                "vill_name_en": "",
                "dr_id": "707"
              },
              {
                "vill_id": "70713",
                "vill_name": "ນາຊອນ",
                "vill_name_en": "",
                "dr_id": "707"
              },
              {
                "vill_id": "70714",
                "vill_name": "ນາພຽງ",
                "vill_name_en": "",
                "dr_id": "707"
              },
              {
                "vill_id": "70715",
                "vill_name": "ນາກາ",
                "vill_name_en": "",
                "dr_id": "707"
              },
              {
                "vill_id": "70716",
                "vill_name": "ໂພນໄຊ",
                "vill_name_en": "",
                "dr_id": "707"
              },
              {
                "vill_id": "70717",
                "vill_name": "ນາອອນ",
                "vill_name_en": "",
                "dr_id": "707"
              },
              {
                "vill_id": "70718",
                "vill_name": "ສົມຫວັງ",
                "vill_name_en": "",
                "dr_id": "707"
              },
              {
                "vill_id": "70719",
                "vill_name": "ເມືອງຮັງ",
                "vill_name_en": "",
                "dr_id": "707"
              },
              {
                "vill_id": "70720",
                "vill_name": "ນາງົວ",
                "vill_name_en": "",
                "dr_id": "707"
              },
              {
                "vill_id": "70721",
                "vill_name": "ວຽງຮັງ",
                "vill_name_en": "",
                "dr_id": "707"
              },
              {
                "vill_id": "70722",
                "vill_name": "ຕະລາງ",
                "vill_name_en": "",
                "dr_id": "707"
              },
              {
                "vill_id": "70723",
                "vill_name": "ພຽງແງ",
                "vill_name_en": "",
                "dr_id": "707"
              },
              {
                "vill_id": "70724",
                "vill_name": "ນາເສື້ອ",
                "vill_name_en": "",
                "dr_id": "707"
              },
              {
                "vill_id": "70725",
                "vill_name": "ເມືອງດຶງ",
                "vill_name_en": "",
                "dr_id": "707"
              },
              {
                "vill_id": "70726",
                "vill_name": "ຄັງກາດ",
                "vill_name_en": "",
                "dr_id": "707"
              },
              {
                "vill_id": "70727",
                "vill_name": "ໂພນຮັງ",
                "vill_name_en": "",
                "dr_id": "707"
              },
              {
                "vill_id": "70728",
                "vill_name": "ນາໃຫມ່",
                "vill_name_en": "",
                "dr_id": "707"
              },
              {
                "vill_id": "70729",
                "vill_name": "ດ່ານຮັງ",
                "vill_name_en": "",
                "dr_id": "707"
              },
              {
                "vill_id": "70730",
                "vill_name": "ນາຄູນ",
                "vill_name_en": "",
                "dr_id": "707"
              },
              {
                "vill_id": "70731",
                "vill_name": "ນາຄຳຮັງ",
                "vill_name_en": "",
                "dr_id": "707"
              },
              {
                "vill_id": "70732",
                "vill_name": "ຕາດ",
                "vill_name_en": "",
                "dr_id": "707"
              },
              {
                "vill_id": "70733",
                "vill_name": "ເມືອງຮົມ",
                "vill_name_en": "",
                "dr_id": "707"
              },
              {
                "vill_id": "70734",
                "vill_name": "ຫາດສ້ານ",
                "vill_name_en": "",
                "dr_id": "707"
              },
              {
                "vill_id": "70735",
                "vill_name": "ປຸ່ງລ້ອງ (ປຸ່ງຮົມ)",
                "vill_name_en": "",
                "dr_id": "707"
              },
              {
                "vill_id": "70736",
                "vill_name": "ລ້ອງຮົມ",
                "vill_name_en": "",
                "dr_id": "707"
              },
              {
                "vill_id": "70737",
                "vill_name": "ໂບ້",
                "vill_name_en": "",
                "dr_id": "707"
              },
              {
                "vill_id": "70738",
                "vill_name": "ບອນ",
                "vill_name_en": "",
                "dr_id": "707"
              },
              {
                "vill_id": "70739",
                "vill_name": "ດ່ານຮົມ",
                "vill_name_en": "",
                "dr_id": "707"
              },
              {
                "vill_id": "70740",
                "vill_name": "ສົບເບົາ",
                "vill_name_en": "",
                "dr_id": "707"
              },
              {
                "vill_id": "70741",
                "vill_name": "ພຽງໄຊ",
                "vill_name_en": "",
                "dr_id": "707"
              },
              {
                "vill_id": "70742",
                "vill_name": "ນາ",
                "vill_name_en": "",
                "dr_id": "707"
              },
              {
                "vill_id": "70743",
                "vill_name": "ນາບຶງ",
                "vill_name_en": "",
                "dr_id": "707"
              },
              {
                "vill_id": "70744",
                "vill_name": "ຜົ້ງເບົາ",
                "vill_name_en": "",
                "dr_id": "707"
              },
              {
                "vill_id": "70745",
                "vill_name": "ປ້ອງ",
                "vill_name_en": "",
                "dr_id": "707"
              },
              {
                "vill_id": "70746",
                "vill_name": "ນາປ່ານ",
                "vill_name_en": "",
                "dr_id": "707"
              },
              {
                "vill_id": "70747",
                "vill_name": "ນາສູນ",
                "vill_name_en": "",
                "dr_id": "707"
              },
              {
                "vill_id": "70748",
                "vill_name": "ກາງລ້ອງ",
                "vill_name_en": "",
                "dr_id": "707"
              },
              {
                "vill_id": "70750",
                "vill_name": "ເມືອງລ້ອງ",
                "vill_name_en": "",
                "dr_id": "707"
              },
              {
                "vill_id": "70751",
                "vill_name": "ກ້ອງຄູນ",
                "vill_name_en": "",
                "dr_id": "707"
              },
              {
                "vill_id": "70754",
                "vill_name": "ຫ້ວຍຄູນ",
                "vill_name_en": "",
                "dr_id": "707"
              },
              {
                "vill_id": "70755",
                "vill_name": "ປຸ່ງ (ສົບປຸ່ງ)",
                "vill_name_en": "",
                "dr_id": "707"
              },
              {
                "vill_id": "70756",
                "vill_name": "ນາໂງ້ນ",
                "vill_name_en": "",
                "dr_id": "707"
              },
              {
                "vill_id": "70757",
                "vill_name": "ລອງເງິນ",
                "vill_name_en": "",
                "dr_id": "707"
              },
              {
                "vill_id": "70758",
                "vill_name": "ປ່າຮ່າງ",
                "vill_name_en": "",
                "dr_id": "707"
              },
              {
                "vill_id": "70759",
                "vill_name": "ນ້ຳເຕີບ",
                "vill_name_en": "",
                "dr_id": "707"
              },
              {
                "vill_id": "70760",
                "vill_name": "ປ່າຂົມໃຫຍ່",
                "vill_name_en": "",
                "dr_id": "707"
              },
              {
                "vill_id": "70761",
                "vill_name": "ຜາລ້ອງ",
                "vill_name_en": "",
                "dr_id": "707"
              },
              {
                "vill_id": "70762",
                "vill_name": "ປ່າຂົມນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "707"
              },
              {
                "vill_id": "70763",
                "vill_name": "ຫ້ວຍປາ",
                "vill_name_en": "",
                "dr_id": "707"
              },
              {
                "vill_id": "70764",
                "vill_name": "ຫ້ວຍເງີ້ມ",
                "vill_name_en": "",
                "dr_id": "707"
              },
              {
                "vill_id": "70765",
                "vill_name": "ລ້ອງຕອງ",
                "vill_name_en": "",
                "dr_id": "707"
              },
              {
                "vill_id": "70766",
                "vill_name": "ຫ້ວຍຮຽງ",
                "vill_name_en": "",
                "dr_id": "707"
              },
              {
                "vill_id": "70767",
                "vill_name": "ປຶງ",
                "vill_name_en": "",
                "dr_id": "707"
              },
              {
                "vill_id": "70768",
                "vill_name": "ມ່ວງ",
                "vill_name_en": "",
                "dr_id": "707"
              },
              {
                "vill_id": "70769",
                "vill_name": "ປ່າຂົມເປັດ",
                "vill_name_en": "",
                "dr_id": "707"
              },
              {
                "vill_id": "70770",
                "vill_name": "ເວິງ",
                "vill_name_en": "Voang",
                "dr_id": "707"
              },
              {
                "vill_id": "70771",
                "vill_name": "ນາໂພນສຸກ",
                "vill_name_en": "Naphonsouk",
                "dr_id": "707"
              },
              {
                "vill_id": "70772",
                "vill_name": "ນາສ້ຽວ",
                "vill_name_en": "Nasewn",
                "dr_id": "707"
              },
              {
                "vill_id": "70773",
                "vill_name": "ຄອນສີ",
                "vill_name_en": "Khonsee",
                "dr_id": "707"
              }
            ]
          },
          {
            "dr_id": "708",
            "dr_name": "ແອດ",
            "dr_name_en": "Add",
            "pr_id": "7",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "70801",
                "vill_name": "ຫ້ວຍລາ",
                "vill_name_en": "",
                "dr_id": "708"
              },
              {
                "vill_id": "70802",
                "vill_name": "ນາເຊົ້າ",
                "vill_name_en": "",
                "dr_id": "708"
              },
              {
                "vill_id": "708003",
                "vill_name": "ຫ້ວຍເຫມັ້ນ",
                "vill_name_en": "",
                "dr_id": "708"
              },
              {
                "vill_id": "708004",
                "vill_name": "ເຫມັ້ນ",
                "vill_name_en": "",
                "dr_id": "708"
              },
              {
                "vill_id": "708005",
                "vill_name": "ນາແງ",
                "vill_name_en": "",
                "dr_id": "708"
              },
              {
                "vill_id": "708006",
                "vill_name": "ນາຫອຍ",
                "vill_name_en": "",
                "dr_id": "708"
              },
              {
                "vill_id": "708007",
                "vill_name": "ນາໄກ່",
                "vill_name_en": "",
                "dr_id": "708"
              },
              {
                "vill_id": "708008",
                "vill_name": "ນາຍົມ",
                "vill_name_en": "",
                "dr_id": "708"
              },
              {
                "vill_id": "708009",
                "vill_name": "ນາຮີ",
                "vill_name_en": "",
                "dr_id": "708"
              },
              {
                "vill_id": "708010",
                "vill_name": "ແທ່ນລວງ",
                "vill_name_en": "",
                "dr_id": "708"
              },
              {
                "vill_id": "708011",
                "vill_name": "ດ່ານ",
                "vill_name_en": "",
                "dr_id": "708"
              },
              {
                "vill_id": "708012",
                "vill_name": "ຫ້ວຍກູ້",
                "vill_name_en": "",
                "dr_id": "708"
              },
              {
                "vill_id": "708013",
                "vill_name": "ຫາດກູ້",
                "vill_name_en": "",
                "dr_id": "708"
              },
              {
                "vill_id": "708014",
                "vill_name": "ເບັ້ຍ",
                "vill_name_en": "",
                "dr_id": "708"
              },
              {
                "vill_id": "708015",
                "vill_name": "ຊຽງຄູນ",
                "vill_name_en": "",
                "dr_id": "708"
              },
              {
                "vill_id": "708016",
                "vill_name": "ກາງ",
                "vill_name_en": "",
                "dr_id": "708"
              },
              {
                "vill_id": "708017",
                "vill_name": "ນາປາ",
                "vill_name_en": "",
                "dr_id": "708"
              },
              {
                "vill_id": "708018",
                "vill_name": "ແຜ້",
                "vill_name_en": "",
                "dr_id": "708"
              },
              {
                "vill_id": "708019",
                "vill_name": "ສໍ",
                "vill_name_en": "",
                "dr_id": "708"
              },
              {
                "vill_id": "708020",
                "vill_name": "ຫ້ວຍປວກ",
                "vill_name_en": "",
                "dr_id": "708"
              },
              {
                "vill_id": "708021",
                "vill_name": "ຫ້ວຍຈອກ",
                "vill_name_en": "",
                "dr_id": "708"
              },
              {
                "vill_id": "708022",
                "vill_name": "ຫ້ວຍຫມໍ້",
                "vill_name_en": "",
                "dr_id": "708"
              },
              {
                "vill_id": "708023",
                "vill_name": "ຕະໂມ",
                "vill_name_en": "",
                "dr_id": "708"
              },
              {
                "vill_id": "708024",
                "vill_name": "ຫ້ວຍຮ້າ",
                "vill_name_en": "",
                "dr_id": "708"
              },
              {
                "vill_id": "708025",
                "vill_name": "ນາປຸງ",
                "vill_name_en": "",
                "dr_id": "708"
              },
              {
                "vill_id": "708026",
                "vill_name": "ນາຂາມ",
                "vill_name_en": "",
                "dr_id": "708"
              },
              {
                "vill_id": "708027",
                "vill_name": "ລ້ອງສີມ",
                "vill_name_en": "",
                "dr_id": "708"
              },
              {
                "vill_id": "708028",
                "vill_name": "ຖ້ຳຊ່ອງ",
                "vill_name_en": "",
                "dr_id": "708"
              },
              {
                "vill_id": "708029",
                "vill_name": "ວັງໂປງ",
                "vill_name_en": "",
                "dr_id": "708"
              },
              {
                "vill_id": "708030",
                "vill_name": "ຜາປູນ",
                "vill_name_en": "",
                "dr_id": "708"
              },
              {
                "vill_id": "708031",
                "vill_name": "ນາຂວາງ",
                "vill_name_en": "",
                "dr_id": "708"
              },
              {
                "vill_id": "708032",
                "vill_name": "ປາຫນໍ່",
                "vill_name_en": "",
                "dr_id": "708"
              },
              {
                "vill_id": "708033",
                "vill_name": "ກາງນ້ຳແອດ",
                "vill_name_en": "",
                "dr_id": "708"
              },
              {
                "vill_id": "708034",
                "vill_name": "ປໍລໍ",
                "vill_name_en": "",
                "dr_id": "708"
              },
              {
                "vill_id": "708035",
                "vill_name": "ນາ",
                "vill_name_en": "",
                "dr_id": "708"
              },
              {
                "vill_id": "708036",
                "vill_name": "ນາພຽງ",
                "vill_name_en": "",
                "dr_id": "708"
              },
              {
                "vill_id": "708037",
                "vill_name": "ລ້ອງແກ້ວ",
                "vill_name_en": "",
                "dr_id": "708"
              },
              {
                "vill_id": "708038",
                "vill_name": "ສົບແວກ",
                "vill_name_en": "",
                "dr_id": "708"
              },
              {
                "vill_id": "708039",
                "vill_name": "ກະຕັນ",
                "vill_name_en": "",
                "dr_id": "708"
              },
              {
                "vill_id": "708040",
                "vill_name": "ນາວີນ",
                "vill_name_en": "",
                "dr_id": "708"
              },
              {
                "vill_id": "708041",
                "vill_name": "ຜາແດງ",
                "vill_name_en": "",
                "dr_id": "708"
              },
              {
                "vill_id": "708042",
                "vill_name": "ເມືອງຊືມ",
                "vill_name_en": "",
                "dr_id": "708"
              },
              {
                "vill_id": "708043",
                "vill_name": "ຫນອງໄຊ",
                "vill_name_en": "",
                "dr_id": "708"
              },
              {
                "vill_id": "708044",
                "vill_name": "ຜາເທີບ",
                "vill_name_en": "",
                "dr_id": "708"
              },
              {
                "vill_id": "708045",
                "vill_name": "ຫນອງຕົບ",
                "vill_name_en": "",
                "dr_id": "708"
              },
              {
                "vill_id": "708046",
                "vill_name": "ແຈຝາຍ",
                "vill_name_en": "",
                "dr_id": "708"
              },
              {
                "vill_id": "708047",
                "vill_name": "ນາມາດ",
                "vill_name_en": "",
                "dr_id": "708"
              },
              {
                "vill_id": "708048",
                "vill_name": "ນາເກືອເຫນືອ",
                "vill_name_en": "",
                "dr_id": "708"
              },
              {
                "vill_id": "708049",
                "vill_name": "ນາຮ້າ",
                "vill_name_en": "",
                "dr_id": "708"
              },
              {
                "vill_id": "708050",
                "vill_name": "ນາຄຳ",
                "vill_name_en": "",
                "dr_id": "708"
              },
              {
                "vill_id": "708051",
                "vill_name": "ດ້າຍ",
                "vill_name_en": "",
                "dr_id": "708"
              },
              {
                "vill_id": "708052",
                "vill_name": "ເມືອງແອດ",
                "vill_name_en": "",
                "dr_id": "708"
              },
              {
                "vill_id": "708053",
                "vill_name": "ແກ່ວກູ້",
                "vill_name_en": "",
                "dr_id": "708"
              },
              {
                "vill_id": "708054",
                "vill_name": "ນາເກືອໃຕ້",
                "vill_name_en": "",
                "dr_id": "708"
              },
              {
                "vill_id": "708055",
                "vill_name": "ຫີນຄູນ",
                "vill_name_en": "",
                "dr_id": "708"
              },
              {
                "vill_id": "708056",
                "vill_name": "ນາໂຮມ",
                "vill_name_en": "",
                "dr_id": "708"
              },
              {
                "vill_id": "708057",
                "vill_name": "ຫ້ວຍກອກ",
                "vill_name_en": "",
                "dr_id": "708"
              },
              {
                "vill_id": "708058",
                "vill_name": "ລ້ອງຫອຍ",
                "vill_name_en": "",
                "dr_id": "708"
              },
              {
                "vill_id": "708059",
                "vill_name": "ນາຢາງ",
                "vill_name_en": "",
                "dr_id": "708"
              },
              {
                "vill_id": "708060",
                "vill_name": "ລູ້",
                "vill_name_en": "",
                "dr_id": "708"
              },
              {
                "vill_id": "708061",
                "vill_name": "ພັກແຝນ",
                "vill_name_en": "",
                "dr_id": "708"
              },
              {
                "vill_id": "708062",
                "vill_name": "ໂຊດ",
                "vill_name_en": "",
                "dr_id": "708"
              },
              {
                "vill_id": "708063",
                "vill_name": "ຫ້ວຍຢອງ",
                "vill_name_en": "",
                "dr_id": "708"
              },
              {
                "vill_id": "708064",
                "vill_name": "ນາຮີດ",
                "vill_name_en": "",
                "dr_id": "708"
              },
              {
                "vill_id": "708065",
                "vill_name": "ນາສ້ານ",
                "vill_name_en": "",
                "dr_id": "708"
              },
              {
                "vill_id": "708066",
                "vill_name": "ນາແລງ",
                "vill_name_en": "",
                "dr_id": "708"
              },
              {
                "vill_id": "708067",
                "vill_name": "ກາງ",
                "vill_name_en": "",
                "dr_id": "708"
              },
              {
                "vill_id": "708068",
                "vill_name": "ເມືອງວັ້ນ",
                "vill_name_en": "",
                "dr_id": "708"
              },
              {
                "vill_id": "708069",
                "vill_name": "ຫ້ວຍຫລົ່ມ",
                "vill_name_en": "",
                "dr_id": "708"
              },
              {
                "vill_id": "708070",
                "vill_name": "ນາເງິນ",
                "vill_name_en": "",
                "dr_id": "708"
              },
              {
                "vill_id": "708071",
                "vill_name": "ຜາແກ້ວ",
                "vill_name_en": "",
                "dr_id": "708"
              },
              {
                "vill_id": "708072",
                "vill_name": "ພຽງລູ້",
                "vill_name_en": "",
                "dr_id": "708"
              },
              {
                "vill_id": "708073",
                "vill_name": "ນາໂຮງ",
                "vill_name_en": "",
                "dr_id": "708"
              },
              {
                "vill_id": "708074",
                "vill_name": "ນາແມນ",
                "vill_name_en": "",
                "dr_id": "708"
              },
              {
                "vill_id": "708075",
                "vill_name": "ບວມໂພ",
                "vill_name_en": "",
                "dr_id": "708"
              },
              {
                "vill_id": "708076",
                "vill_name": "ກະລັງ",
                "vill_name_en": "",
                "dr_id": "708"
              },
              {
                "vill_id": "708077",
                "vill_name": "ແກ່ວແຮ່",
                "vill_name_en": "",
                "dr_id": "708"
              }
            ]
          },
          {
            "dr_id": "709",
            "dr_name": "ກວັນ",
            "dr_name_en": "Kuan",
            "pr_id": "7",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "70902",
                "vill_name": "ສົບກັງ",
                "vill_name_en": "Sopkang",
                "dr_id": "709"
              },
              {
                "vill_id": "709001",
                "vill_name": "ເມືອງກວນ",
                "vill_name_en": "",
                "dr_id": "709"
              },
              {
                "vill_id": "709002",
                "vill_name": "ນາຫັນງ",
                "vill_name_en": "",
                "dr_id": "709"
              },
              {
                "vill_id": "709003",
                "vill_name": "ເມືອງນາ",
                "vill_name_en": "",
                "dr_id": "709"
              },
              {
                "vill_id": "709004",
                "vill_name": "ຫ້ວຍຍືນ",
                "vill_name_en": "Huayyuen",
                "dr_id": "709"
              },
              {
                "vill_id": "709005",
                "vill_name": "ຖ້າເຂືອງ",
                "vill_name_en": "Thakheuang",
                "dr_id": "709"
              },
              {
                "vill_id": "709006",
                "vill_name": "ນາວີ້ນ",
                "vill_name_en": "Namvin",
                "dr_id": "709"
              },
              {
                "vill_id": "709007",
                "vill_name": "ພຽງດີ",
                "vill_name_en": "Phiengdee",
                "dr_id": "709"
              },
              {
                "vill_id": "709008",
                "vill_name": "ຫ້ວຍເຮັງ",
                "vill_name_en": "Huayheng",
                "dr_id": "709"
              },
              {
                "vill_id": "709009",
                "vill_name": "ນາອໍ້",
                "vill_name_en": "Naor",
                "dr_id": "709"
              },
              {
                "vill_id": "709010",
                "vill_name": "ນາທອງ",
                "vill_name_en": "Nathong",
                "dr_id": "709"
              },
              {
                "vill_id": "709011",
                "vill_name": "ດອນ",
                "vill_name_en": "Done",
                "dr_id": "709"
              },
              {
                "vill_id": "709012",
                "vill_name": "ສຸມລວງ",
                "vill_name_en": "Soomluang",
                "dr_id": "709"
              }
            ]
          },
          {
            "dr_id": "710",
            "dr_name": "ຊອນ",
            "dr_name_en": "Xone",
            "pr_id": "7",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "710001",
                "vill_name": "ແລ້ງ",
                "vill_name_en": "Lang",
                "dr_id": "710"
              },
              {
                "vill_id": "710002",
                "vill_name": "ນາໜົມ",
                "vill_name_en": "Narmom",
                "dr_id": "710"
              },
              {
                "vill_id": "710003",
                "vill_name": "ທາດ",
                "vill_name_en": "Thard",
                "dr_id": "710"
              },
              {
                "vill_id": "710004",
                "vill_name": "ວັດ",
                "vill_name_en": "Vard",
                "dr_id": "710"
              },
              {
                "vill_id": "710005",
                "vill_name": "ຊ່ອນເໝຶອ",
                "vill_name_en": "Soneneu",
                "dr_id": "710"
              },
              {
                "vill_id": "710006",
                "vill_name": "ຫ້ວຍຫອມ",
                "vill_name_en": "Heuhom",
                "dr_id": "710"
              },
              {
                "vill_id": "710007",
                "vill_name": "ຫ້ວຍສະຫງອນ",
                "vill_name_en": "Heungone",
                "dr_id": "710"
              },
              {
                "vill_id": "710008",
                "vill_name": "ມັ້ນ",
                "vill_name_en": "Maun",
                "dr_id": "710"
              },
              {
                "vill_id": "710009",
                "vill_name": "ໂງ່ນ",
                "vill_name_en": "Ngoun",
                "dr_id": "710"
              },
              {
                "vill_id": "710011",
                "vill_name": "ບໍ່",
                "vill_name_en": "Br",
                "dr_id": "710"
              },
              {
                "vill_id": "710012",
                "vill_name": "ສາມສູນ",
                "vill_name_en": "Samsone",
                "dr_id": "710"
              },
              {
                "vill_id": "710013",
                "vill_name": "ໝອງໃສ",
                "vill_name_en": "Nongsai",
                "dr_id": "710"
              },
              {
                "vill_id": "710014",
                "vill_name": "ຫ້ວຍຍາມ",
                "vill_name_en": "Heiyai",
                "dr_id": "710"
              },
              {
                "vill_id": "710015",
                "vill_name": "ຫ້ວຍເໝຶອຍ",
                "vill_name_en": "Heimeu",
                "dr_id": "710"
              },
              {
                "vill_id": "710016",
                "vill_name": "ນໍ່້າເນີ່ນ",
                "vill_name_en": "Numnern",
                "dr_id": "710"
              },
              {
                "vill_id": "710017",
                "vill_name": "ບວມຟາດ",
                "vill_name_en": "Buempard",
                "dr_id": "710"
              },
              {
                "vill_id": "710018",
                "vill_name": "ໂພນຊອງ",
                "vill_name_en": "Phonesong",
                "dr_id": "710"
              },
              {
                "vill_id": "710019",
                "vill_name": "ນາທົ່ງປໍ",
                "vill_name_en": "Natongpor",
                "dr_id": "710"
              },
              {
                "vill_id": "710020",
                "vill_name": "ນໍ້າງາວ",
                "vill_name_en": "Numjao",
                "dr_id": "710"
              },
              {
                "vill_id": "710021",
                "vill_name": "ວັງແຟນ",
                "vill_name_en": "Vangfan",
                "dr_id": "710"
              },
              {
                "vill_id": "710022",
                "vill_name": "ຫ້ວຍສູ",
                "vill_name_en": "Heusou",
                "dr_id": "710"
              },
              {
                "vill_id": "710023",
                "vill_name": "ນາກຽນ",
                "vill_name_en": "Nakien",
                "dr_id": "710"
              },
              {
                "vill_id": "710024",
                "vill_name": "ເມຶອງເປີ້",
                "vill_name_en": "Meuanper",
                "dr_id": "710"
              },
              {
                "vill_id": "710025",
                "vill_name": "ສົບຕິ້ວ",
                "vill_name_en": "Sobtea",
                "dr_id": "710"
              },
              {
                "vill_id": "710026",
                "vill_name": "ບົງ",
                "vill_name_en": "Bong",
                "dr_id": "710"
              },
              {
                "vill_id": "710027",
                "vill_name": "ເມຶອງເກົ້າ",
                "vill_name_en": "Meangkao",
                "dr_id": "710"
              },
              {
                "vill_id": "710028",
                "vill_name": "ວັງຄ້ວາງ",
                "vill_name_en": "Vangkou",
                "dr_id": "710"
              },
              {
                "vill_id": "710029",
                "vill_name": "ນາຈັກ",
                "vill_name_en": "Najak",
                "dr_id": "710"
              },
              {
                "vill_id": "710030",
                "vill_name": "ລ້ອງງົວປາ",
                "vill_name_en": "Longngeopa",
                "dr_id": "710"
              },
              {
                "vill_id": "710031",
                "vill_name": "ນາໝໍ້",
                "vill_name_en": "Namor",
                "dr_id": "710"
              },
              {
                "vill_id": "710032",
                "vill_name": "ຊາຍ",
                "vill_name_en": "Say",
                "dr_id": "710"
              },
              {
                "vill_id": "710033",
                "vill_name": "ຫ້ວຍເລົ່າ",
                "vill_name_en": "Heulao",
                "dr_id": "710"
              },
              {
                "vill_id": "710034",
                "vill_name": "ຫ້ວຍຕິນ",
                "vill_name_en": "Heutin",
                "dr_id": "710"
              },
              {
                "vill_id": "710035",
                "vill_name": "ນອມ",
                "vill_name_en": "Nom",
                "dr_id": "710"
              }
            ]
          }
        ]
      },
      {
        "pr_id": "8",
        "pr_name": "ໄຊຍະບູລີ",
        "pr_name_en": "Xaignabouli",
        "districts": [
          {"dr_name": "ເລືອກເມືອງປະຈຸບັນ", "villages": []},
          {
            "dr_id": "801",
            "dr_name": "ໄຊຍະບູລີ",
            "dr_name_en": "Xayabury",
            "pr_id": "8",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "801001",
                "vill_name": "ສີເມືອງ",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801002",
                "vill_name": "ຖິ່ນ",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801003",
                "vill_name": "ສີມຸງຄຸນ",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801004",
                "vill_name": "ດອນໃຫມ່",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801005",
                "vill_name": "ດອນເງິນ",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801006",
                "vill_name": "ບຸ່ງ",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801007",
                "vill_name": "ປົ່ງ",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801008",
                "vill_name": "ທ່ານາ",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801009",
                "vill_name": "ນາຍາວ",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801010",
                "vill_name": "ແກ້ງ",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801011",
                "vill_name": "ຜາປູນ",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801013",
                "vill_name": "ນາໄຮ",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801014",
                "vill_name": "ໃຫຍ່",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801015",
                "vill_name": "ຫນອງແຊງ",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801016",
                "vill_name": "ນາເລົາ",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801017",
                "vill_name": "ນາຕໍນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801018",
                "vill_name": "ນາຕໍໃຫຍ່",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801019",
                "vill_name": "ວັງຂາມ",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801020",
                "vill_name": "ລ້ອງປໍ",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801021",
                "vill_name": "ໂນນສະຫວັນ",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801022",
                "vill_name": "ນາຊ່ຳ",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801023",
                "vill_name": "ຫ້ວຍດົກ",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801024",
                "vill_name": "ບ້ານຜາຊ້າງ",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801026",
                "vill_name": "ນ້ຳຕວນ",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801027",
                "vill_name": "ໂພນໄຊ(ຫຼັກ18)",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801028",
                "vill_name": "ຫ້ວຍເລີກ",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801029",
                "vill_name": "ນາລວມ",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801030",
                "vill_name": "ປາຫທັງ",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801031",
                "vill_name": "ຫ້ວຍຊ້ຳອໍ້",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801032",
                "vill_name": "ຫ້ວຍແສນຄຳ",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801033",
                "vill_name": "ນາລາ",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801034",
                "vill_name": "ສວນ",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801035",
                "vill_name": "ວັງຊອຍ",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801036",
                "vill_name": "ຫ້ວຍແກ້ງ",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801037",
                "vill_name": "ນາຕາກ",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801038",
                "vill_name": "ຫາດຮ່ອມ",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801039",
                "vill_name": "ໂພນສະອາດ",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801040",
                "vill_name": "ນາງີ້ວ",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801041",
                "vill_name": "ໄຊສະຫວາດ",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801042",
                "vill_name": "ນາກວາງ",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801043",
                "vill_name": "ວຽງໄຊ",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801044",
                "vill_name": "ນາຄານ",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801045",
                "vill_name": "ຫ້ວຍລຸນ",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801046",
                "vill_name": "ຫນອງຫນ່ອງ",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801047",
                "vill_name": "ທ່າ",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801048",
                "vill_name": "ໂພນໂຮມ",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801049",
                "vill_name": "ນາມອນ",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801050",
                "vill_name": "ຫ້ວຍຊ່າວ",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801051",
                "vill_name": "ຫ້ວຍສະແງມ",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801052",
                "vill_name": "ຫ້ວຍເພາະ",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801053",
                "vill_name": "ຫ້ວຍເລີຍ",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801054",
                "vill_name": "ນາແລ້ງຫລວງ",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801055",
                "vill_name": "ນາແລ້ງນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801056",
                "vill_name": "ນາທ່ອນ",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801057",
                "vill_name": "ນາຫລາ",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801058",
                "vill_name": "ນາຄູນ",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801059",
                "vill_name": "ຕາລານ",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801060",
                "vill_name": "ຖ້ຳລອດ",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801061",
                "vill_name": "ໂພນແກ້ວ",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801062",
                "vill_name": "ປາງຄອມ",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801063",
                "vill_name": "ທ່າເດື່ອ",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801064",
                "vill_name": "ປາກປາ",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801065",
                "vill_name": "ປາກເຮົ້າ",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801066",
                "vill_name": "ຫາດແກ້ວ",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801067",
                "vill_name": "ຫ້ວຍຈິດ",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801068",
                "vill_name": "ປາກຄາ",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801069",
                "vill_name": "ນ້ຳຄາ",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801070",
                "vill_name": "ຫ້ວຍຊຸຍ",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801071",
                "vill_name": "ປາກຮຸ່ງ",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801072",
                "vill_name": "ຫ້ວຍຂົວຫລວງ",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801073",
                "vill_name": "ຄົກຟາກ",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801074",
                "vill_name": "ປາກປຸຍ",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801075",
                "vill_name": "ໂພນສີດາ",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801076",
                "vill_name": "ນາຂອນແກ່ນ",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801077",
                "vill_name": "ແກ້ວ",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801078",
                "vill_name": "ນາທັງ",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801079",
                "vill_name": "ຄອນປຽດ",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801080",
                "vill_name": "ຫ້ວຍພວນ",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801081",
                "vill_name": "ລ້ອງແຊງ",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801082",
                "vill_name": "ນ້ຳລຽບ",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801083",
                "vill_name": "ປາກທຽວ",
                "vill_name_en": "",
                "dr_id": "801"
              },
              {
                "vill_id": "801084",
                "vill_name": "ປາກໄຜ່",
                "vill_name_en": "",
                "dr_id": "801"
              }
            ]
          },
          {
            "dr_id": "802",
            "dr_name": "ຄອບ",
            "dr_name_en": "Khop",
            "pr_id": "8",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "802001",
                "vill_name": "ບໍ່",
                "vill_name_en": "",
                "dr_id": "802"
              },
              {
                "vill_id": "802002",
                "vill_name": "ຫາດງາມ",
                "vill_name_en": "",
                "dr_id": "802"
              },
              {
                "vill_id": "802003",
                "vill_name": "ປາກຄອບ",
                "vill_name_en": "",
                "dr_id": "802"
              },
              {
                "vill_id": "802004",
                "vill_name": "ຄົກເມີຍ",
                "vill_name_en": "",
                "dr_id": "802"
              },
              {
                "vill_id": "802005",
                "vill_name": "ປາງໄຮ",
                "vill_name_en": "",
                "dr_id": "802"
              },
              {
                "vill_id": "802006",
                "vill_name": "ປ່າຄາ",
                "vill_name_en": "",
                "dr_id": "802"
              },
              {
                "vill_id": "802007",
                "vill_name": "ຫ້ວຍເລົາ",
                "vill_name_en": "",
                "dr_id": "802"
              },
              {
                "vill_id": "802008",
                "vill_name": "ດອນຍົມ",
                "vill_name_en": "",
                "dr_id": "802"
              },
              {
                "vill_id": "802009",
                "vill_name": "ເຄິ່ງ",
                "vill_name_en": "",
                "dr_id": "802"
              },
              {
                "vill_id": "802010",
                "vill_name": "ສະຖານ",
                "vill_name_en": "",
                "dr_id": "802"
              },
              {
                "vill_id": "802011",
                "vill_name": "ຖ້ຳ",
                "vill_name_en": "",
                "dr_id": "802"
              },
              {
                "vill_id": "802012",
                "vill_name": "ຫ້ວຍນົກກົດ",
                "vill_name_en": "",
                "dr_id": "802"
              },
              {
                "vill_id": "802013",
                "vill_name": "ນ້ຳພ້າວ",
                "vill_name_en": "",
                "dr_id": "802"
              },
              {
                "vill_id": "802014",
                "vill_name": "ຫົວເມືອງ",
                "vill_name_en": "",
                "dr_id": "802"
              },
              {
                "vill_id": "802015",
                "vill_name": "ມ່ວງດອນມູນ",
                "vill_name_en": "",
                "dr_id": "802"
              },
              {
                "vill_id": "802016",
                "vill_name": "ປາງມອນ",
                "vill_name_en": "",
                "dr_id": "802"
              },
              {
                "vill_id": "802017",
                "vill_name": "ໂພນສະຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "802"
              },
              {
                "vill_id": "802018",
                "vill_name": "ເຫລັ້ມ",
                "vill_name_en": "",
                "dr_id": "802"
              },
              {
                "vill_id": "802019",
                "vill_name": "ທົ່ງເຫມັ້ນ",
                "vill_name_en": "",
                "dr_id": "802"
              },
              {
                "vill_id": "802020",
                "vill_name": "ນ້ຳຄ່າ",
                "vill_name_en": "",
                "dr_id": "802"
              },
              {
                "vill_id": "802021",
                "vill_name": "ປ້າຊ່າງ",
                "vill_name_en": "",
                "dr_id": "802"
              },
              {
                "vill_id": "802022",
                "vill_name": "ດອຍແຮ່",
                "vill_name_en": "",
                "dr_id": "802"
              },
              {
                "vill_id": "802023",
                "vill_name": "ດອນຕັນ",
                "vill_name_en": "",
                "dr_id": "802"
              },
              {
                "vill_id": "802024",
                "vill_name": "ນ້ຳໂຕ່ມ",
                "vill_name_en": "",
                "dr_id": "802"
              },
              {
                "vill_id": "802025",
                "vill_name": "ໃຈສະຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "802"
              },
              {
                "vill_id": "802026",
                "vill_name": "ຫ້ວຍເງິນ",
                "vill_name_en": "",
                "dr_id": "802"
              }
            ]
          },
          {
            "dr_id": "803",
            "dr_name": "ຫົງສາ",
            "dr_name_en": "Hongsa",
            "pr_id": "8",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "803001",
                "vill_name": "ສີບຸນເຮືອງ",
                "vill_name_en": "",
                "dr_id": "803"
              },
              {
                "vill_id": "803002",
                "vill_name": "ດອນໄຊ",
                "vill_name_en": "",
                "dr_id": "803"
              },
              {
                "vill_id": "803003",
                "vill_name": "ໂພນໄຊ",
                "vill_name_en": "",
                "dr_id": "803"
              },
              {
                "vill_id": "803004",
                "vill_name": "ໂພນສະອາດ",
                "vill_name_en": "",
                "dr_id": "803"
              },
              {
                "vill_id": "803005",
                "vill_name": "ແທ່ນຄຳ",
                "vill_name_en": "",
                "dr_id": "803"
              },
              {
                "vill_id": "803006",
                "vill_name": "ຫ້ວຍເຍີ",
                "vill_name_en": "",
                "dr_id": "803"
              },
              {
                "vill_id": "803007",
                "vill_name": "ນ້ຳແກນ",
                "vill_name_en": "",
                "dr_id": "803"
              },
              {
                "vill_id": "803008",
                "vill_name": "ນາປຸ່ງ",
                "vill_name_en": "",
                "dr_id": "803"
              },
              {
                "vill_id": "803009",
                "vill_name": "ໂພນສູງ",
                "vill_name_en": "",
                "dr_id": "803"
              },
              {
                "vill_id": "803010",
                "vill_name": "ພູແລ້ງໃຕ້",
                "vill_name_en": "",
                "dr_id": "803"
              },
              {
                "vill_id": "803011",
                "vill_name": "ຫ້ວຍຈ້ວງ",
                "vill_name_en": "",
                "dr_id": "803"
              },
              {
                "vill_id": "803012",
                "vill_name": "ພູແລ້ງກາງ",
                "vill_name_en": "",
                "dr_id": "803"
              },
              {
                "vill_id": "803013",
                "vill_name": "ຈອມແຈ້ງ",
                "vill_name_en": "",
                "dr_id": "803"
              },
              {
                "vill_id": "803014",
                "vill_name": "ສີມຸງຄຸນ",
                "vill_name_en": "",
                "dr_id": "803"
              },
              {
                "vill_id": "803015",
                "vill_name": "ຈຳປາ",
                "vill_name_en": "",
                "dr_id": "803"
              },
              {
                "vill_id": "803016",
                "vill_name": "ວຽງແກ້ວ",
                "vill_name_en": "",
                "dr_id": "803"
              },
              {
                "vill_id": "803017",
                "vill_name": "ນາສ້ານ",
                "vill_name_en": "",
                "dr_id": "803"
              },
              {
                "vill_id": "803018",
                "vill_name": "ນາໄມ້ຍົມ",
                "vill_name_en": "",
                "dr_id": "803"
              },
              {
                "vill_id": "803019",
                "vill_name": "ກິ່ວມ່ວງ",
                "vill_name_en": "",
                "dr_id": "803"
              },
              {
                "vill_id": "803020",
                "vill_name": "ຫານ",
                "vill_name_en": "",
                "dr_id": "803"
              },
              {
                "vill_id": "803021",
                "vill_name": "ໂພນຈັນ",
                "vill_name_en": "",
                "dr_id": "803"
              },
              {
                "vill_id": "803022",
                "vill_name": "ນາຫນອງຄຳ",
                "vill_name_en": "",
                "dr_id": "803"
              },
              {
                "vill_id": "803023",
                "vill_name": "ນາຊາຍຄຳ",
                "vill_name_en": "",
                "dr_id": "803"
              },
              {
                "vill_id": "803024",
                "vill_name": "ນ້ຳຂາມ",
                "vill_name_en": "",
                "dr_id": "803"
              },
              {
                "vill_id": "803025",
                "vill_name": "ນ້ຳຍາງ",
                "vill_name_en": "",
                "dr_id": "803"
              },
              {
                "vill_id": "803026",
                "vill_name": "ນ້ຳສິບ",
                "vill_name_en": "",
                "dr_id": "803"
              },
              {
                "vill_id": "803027",
                "vill_name": "ທ່າຊ່ວງ",
                "vill_name_en": "",
                "dr_id": "803"
              },
              {
                "vill_id": "803028",
                "vill_name": "ແກ້ງແອນ",
                "vill_name_en": "",
                "dr_id": "803"
              },
              {
                "vill_id": "803029",
                "vill_name": "ປາກງື່ມ",
                "vill_name_en": "",
                "dr_id": "803"
              },
              {
                "vill_id": "803030",
                "vill_name": "ຫ້ວຍຊອງ",
                "vill_name_en": "",
                "dr_id": "803"
              },
              {
                "vill_id": "803031",
                "vill_name": "ກິ່ວສາລາ",
                "vill_name_en": "",
                "dr_id": "803"
              },
              {
                "vill_id": "803032",
                "vill_name": "ທ່ານຸ່ນ",
                "vill_name_en": "",
                "dr_id": "803"
              },
              {
                "vill_id": "803033",
                "vill_name": "ນ້ຳຕັບ",
                "vill_name_en": "",
                "dr_id": "803"
              },
              {
                "vill_id": "803034",
                "vill_name": "ຫ້ວຍໄລ່",
                "vill_name_en": "",
                "dr_id": "803"
              },
              {
                "vill_id": "803035",
                "vill_name": "ຄົກແອກ",
                "vill_name_en": "",
                "dr_id": "803"
              },
              {
                "vill_id": "803036",
                "vill_name": "ແກ້ງຮາງນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "803"
              },
              {
                "vill_id": "803037",
                "vill_name": "ພູດອກໄມ້",
                "vill_name_en": "",
                "dr_id": "803"
              },
              {
                "vill_id": "803038",
                "vill_name": "ປາຫ້ວຍຍາງ",
                "vill_name_en": "",
                "dr_id": "803"
              },
              {
                "vill_id": "803039",
                "vill_name": "ປາກຫ້ວຍສີງ",
                "vill_name_en": "",
                "dr_id": "803"
              },
              {
                "vill_id": "803040",
                "vill_name": "ດອນໃຫມ່",
                "vill_name_en": "",
                "dr_id": "803"
              },
              {
                "vill_id": "803041",
                "vill_name": "ພູສຳພັນ",
                "vill_name_en": "",
                "dr_id": "803"
              },
              {
                "vill_id": "803042",
                "vill_name": "ໜອງຫຼວງ",
                "vill_name_en": "",
                "dr_id": "803"
              },
              {
                "vill_id": "803043",
                "vill_name": "ໂຮມໄຊ",
                "vill_name_en": "",
                "dr_id": "803"
              },
              {
                "vill_id": "803044",
                "vill_name": "ໜອງຄຳ",
                "vill_name_en": "Nongkham",
                "dr_id": "803"
              }
            ]
          },
          {
            "dr_id": "804",
            "dr_name": "ເງິນ",
            "dr_name_en": "Ngeun",
            "pr_id": "8",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "804001",
                "vill_name": "ເພຍງາມ",
                "vill_name_en": "",
                "dr_id": "804"
              },
              {
                "vill_id": "804002",
                "vill_name": "ປີ້ຫມີ",
                "vill_name_en": "",
                "dr_id": "804"
              },
              {
                "vill_id": "804003",
                "vill_name": "ຂອນ",
                "vill_name_en": "",
                "dr_id": "804"
              },
              {
                "vill_id": "804004",
                "vill_name": "ກາງ",
                "vill_name_en": "",
                "dr_id": "804"
              },
              {
                "vill_id": "804005",
                "vill_name": "ແກ້ວດອນຄູນ",
                "vill_name_en": "",
                "dr_id": "804"
              },
              {
                "vill_id": "804006",
                "vill_name": "ຫລວງ",
                "vill_name_en": "",
                "dr_id": "804"
              },
              {
                "vill_id": "804007",
                "vill_name": "ນ້ຳເງິນ",
                "vill_name_en": "",
                "dr_id": "804"
              },
              {
                "vill_id": "804008",
                "vill_name": "ນາງົວ",
                "vill_name_en": "",
                "dr_id": "804"
              },
              {
                "vill_id": "804009",
                "vill_name": "ນາຍາງຄຳ",
                "vill_name_en": "",
                "dr_id": "804"
              },
              {
                "vill_id": "804010",
                "vill_name": "ທອງ",
                "vill_name_en": "",
                "dr_id": "804"
              },
              {
                "vill_id": "804011",
                "vill_name": "ດອນສະຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "804"
              },
              {
                "vill_id": "804012",
                "vill_name": "ນ້ຳທົນ",
                "vill_name_en": "",
                "dr_id": "804"
              },
              {
                "vill_id": "804013",
                "vill_name": "ດອນເກດ",
                "vill_name_en": "",
                "dr_id": "804"
              },
              {
                "vill_id": "804014",
                "vill_name": "ຜາແດງ",
                "vill_name_en": "",
                "dr_id": "804"
              },
              {
                "vill_id": "804015",
                "vill_name": "ປຸ່ງຝາດ",
                "vill_name_en": "",
                "dr_id": "804"
              },
              {
                "vill_id": "804016",
                "vill_name": "ບໍ່ຫລວງ",
                "vill_name_en": "",
                "dr_id": "804"
              },
              {
                "vill_id": "804017",
                "vill_name": "ປາງບົງ",
                "vill_name_en": "",
                "dr_id": "804"
              },
              {
                "vill_id": "804018",
                "vill_name": "ກິ່ວງິ້ວ",
                "vill_name_en": "",
                "dr_id": "804"
              },
              {
                "vill_id": "804019",
                "vill_name": "ໂຮມໄຊ",
                "vill_name_en": "",
                "dr_id": "804"
              },
              {
                "vill_id": "804020",
                "vill_name": "ຫ້ວຍເງີຍ",
                "vill_name_en": "",
                "dr_id": "804"
              },
              {
                "vill_id": "804021",
                "vill_name": "ປາກຫ້ວຍແຄນ",
                "vill_name_en": "",
                "dr_id": "804"
              },
              {
                "vill_id": "804022",
                "vill_name": "ຫ້ວຍເຜິ້ງ",
                "vill_name_en": "",
                "dr_id": "804"
              },
              {
                "vill_id": "804023",
                "vill_name": "ບາ້ນ ຫຼວງມີໄຊ",
                "vill_name_en": "Luoangmesai",
                "dr_id": "804"
              }
            ]
          },
          {
            "dr_id": "805",
            "dr_name": "ຊຽງຮ່ອນ",
            "dr_name_en": "Xienghone",
            "pr_id": "8",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "805001",
                "vill_name": "ຫ້ວຍຄູນ",
                "vill_name_en": "",
                "dr_id": "805"
              },
              {
                "vill_id": "805002",
                "vill_name": "ປາກສິດ",
                "vill_name_en": "",
                "dr_id": "805"
              },
              {
                "vill_id": "805003",
                "vill_name": "ປາກເປັດ",
                "vill_name_en": "",
                "dr_id": "805"
              },
              {
                "vill_id": "805004",
                "vill_name": "ໂຮມໄຊ",
                "vill_name_en": "",
                "dr_id": "805"
              },
              {
                "vill_id": "805005",
                "vill_name": "ຮ່ອງຄູ່",
                "vill_name_en": "",
                "dr_id": "805"
              },
              {
                "vill_id": "805006",
                "vill_name": "ນ້ຳແບ່ງ",
                "vill_name_en": "",
                "dr_id": "805"
              },
              {
                "vill_id": "805007",
                "vill_name": "ລາວສະໂນ",
                "vill_name_en": "",
                "dr_id": "805"
              },
              {
                "vill_id": "805008",
                "vill_name": "ນ້ຳແປ",
                "vill_name_en": "",
                "dr_id": "805"
              },
              {
                "vill_id": "805009",
                "vill_name": "ພູລ້ານ",
                "vill_name_en": "",
                "dr_id": "805"
              },
              {
                "vill_id": "805010",
                "vill_name": "ຫົວເມືອງ",
                "vill_name_en": "",
                "dr_id": "805"
              },
              {
                "vill_id": "805011",
                "vill_name": "ດອນມູນ",
                "vill_name_en": "",
                "dr_id": "805"
              },
              {
                "vill_id": "805012",
                "vill_name": "ນ້ຳກອກ",
                "vill_name_en": "",
                "dr_id": "805"
              },
              {
                "vill_id": "805013",
                "vill_name": "ຫົວນາ",
                "vill_name_en": "",
                "dr_id": "805"
              },
              {
                "vill_id": "805014",
                "vill_name": "ນາອອນ",
                "vill_name_en": "",
                "dr_id": "805"
              },
              {
                "vill_id": "805015",
                "vill_name": "ດອນເຟືອງ",
                "vill_name_en": "",
                "dr_id": "805"
              },
              {
                "vill_id": "805017",
                "vill_name": "ດອນໄຊ",
                "vill_name_en": "",
                "dr_id": "805"
              },
              {
                "vill_id": "805018",
                "vill_name": "ແກ່ນ",
                "vill_name_en": "",
                "dr_id": "805"
              },
              {
                "vill_id": "805019",
                "vill_name": "ມົກສະຕູ",
                "vill_name_en": "",
                "dr_id": "805"
              },
              {
                "vill_id": "805020",
                "vill_name": "ດົງ",
                "vill_name_en": "",
                "dr_id": "805"
              },
              {
                "vill_id": "805021",
                "vill_name": "ຫ້ວຍສະເຄັງ",
                "vill_name_en": "",
                "dr_id": "805"
              },
              {
                "vill_id": "805022",
                "vill_name": "ຫ້ວຍຢວກ",
                "vill_name_en": "",
                "dr_id": "805"
              },
              {
                "vill_id": "805023",
                "vill_name": "ຊ້າງ",
                "vill_name_en": "",
                "dr_id": "805"
              },
              {
                "vill_id": "805024",
                "vill_name": "ຂີງ",
                "vill_name_en": "",
                "dr_id": "805"
              },
              {
                "vill_id": "805025",
                "vill_name": "ຄ້ຳ",
                "vill_name_en": "",
                "dr_id": "805"
              },
              {
                "vill_id": "805026",
                "vill_name": "ປົ່ງຄຳ",
                "vill_name_en": "",
                "dr_id": "805"
              },
              {
                "vill_id": "805027",
                "vill_name": "ໃຫມ່",
                "vill_name_en": "",
                "dr_id": "805"
              },
              {
                "vill_id": "805028",
                "vill_name": "ທາດ",
                "vill_name_en": "",
                "dr_id": "805"
              },
              {
                "vill_id": "805029",
                "vill_name": "ກູກ",
                "vill_name_en": "",
                "dr_id": "805"
              },
              {
                "vill_id": "805030",
                "vill_name": "ນ້ຳຄ່ອມ",
                "vill_name_en": "",
                "dr_id": "805"
              },
              {
                "vill_id": "805031",
                "vill_name": "ດອນຕັນ",
                "vill_name_en": "",
                "dr_id": "805"
              },
              {
                "vill_id": "805032",
                "vill_name": "ປ່າຫວ້ານ",
                "vill_name_en": "",
                "dr_id": "805"
              },
              {
                "vill_id": "805033",
                "vill_name": "ດອນໄຈ",
                "vill_name_en": "",
                "dr_id": "805"
              },
              {
                "vill_id": "805034",
                "vill_name": "ລັບ",
                "vill_name_en": "",
                "dr_id": "805"
              },
              {
                "vill_id": "805035",
                "vill_name": "ນ້ຳລ້ອມ",
                "vill_name_en": "",
                "dr_id": "805"
              },
              {
                "vill_id": "805036",
                "vill_name": "ປົ່ງແປ້ນ",
                "vill_name_en": "",
                "dr_id": "805"
              },
              {
                "vill_id": "805037",
                "vill_name": "ແກສີພົມ",
                "vill_name_en": "",
                "dr_id": "805"
              },
              {
                "vill_id": "805038",
                "vill_name": "ທົ່ງກາບ",
                "vill_name_en": "",
                "dr_id": "805"
              },
              {
                "vill_id": "805039",
                "vill_name": "ສີນົກໄຊ້",
                "vill_name_en": "",
                "dr_id": "805"
              },
              {
                "vill_id": "805040",
                "vill_name": "ຫ້ວຍຮາກ",
                "vill_name_en": "",
                "dr_id": "805"
              },
              {
                "vill_id": "805041",
                "vill_name": "ຫ້ວຍຕອງ",
                "vill_name_en": "",
                "dr_id": "805"
              },
              {
                "vill_id": "805042",
                "vill_name": "ດອນປຸ່ງ",
                "vill_name_en": "",
                "dr_id": "805"
              },
              {
                "vill_id": "805043",
                "vill_name": "ສາມັກຄີໄຊ",
                "vill_name_en": "",
                "dr_id": "805"
              }
            ]
          },
          {
            "dr_id": "806",
            "dr_name": "ພຽງ",
            "dr_name_en": "Phieng",
            "pr_id": "8",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "806001",
                "vill_name": "ດົງເງິນ",
                "vill_name_en": "",
                "dr_id": "806"
              },
              {
                "vill_id": "806002",
                "vill_name": "ຫນອງງົວ",
                "vill_name_en": "",
                "dr_id": "806"
              },
              {
                "vill_id": "806003",
                "vill_name": "ແສງຈະເລີນ",
                "vill_name_en": "",
                "dr_id": "806"
              },
              {
                "vill_id": "806004",
                "vill_name": "ເມືອງພຽງ",
                "vill_name_en": "",
                "dr_id": "806"
              },
              {
                "vill_id": "806005",
                "vill_name": "ໂພນໄຊ",
                "vill_name_en": "",
                "dr_id": "806"
              },
              {
                "vill_id": "806006",
                "vill_name": "ໂພນຫີນ",
                "vill_name_en": "",
                "dr_id": "806"
              },
              {
                "vill_id": "806007",
                "vill_name": "ກາງ",
                "vill_name_en": "",
                "dr_id": "806"
              },
              {
                "vill_id": "806008",
                "vill_name": "ນ້ຳສົ້ງ",
                "vill_name_en": "",
                "dr_id": "806"
              },
              {
                "vill_id": "806009",
                "vill_name": "ກິ່ວແຄ່ມ",
                "vill_name_en": "",
                "dr_id": "806"
              },
              {
                "vill_id": "806010",
                "vill_name": "ຫ້ວຍຊາກ",
                "vill_name_en": "",
                "dr_id": "806"
              },
              {
                "vill_id": "806011",
                "vill_name": "ປົ່ງວາງ",
                "vill_name_en": "",
                "dr_id": "806"
              },
              {
                "vill_id": "806012",
                "vill_name": "ດົງຫລວງ",
                "vill_name_en": "",
                "dr_id": "806"
              },
              {
                "vill_id": "806013",
                "vill_name": "ໂພນສະຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "806"
              },
              {
                "vill_id": "806014",
                "vill_name": "ນາກອກ",
                "vill_name_en": "",
                "dr_id": "806"
              },
              {
                "vill_id": "806015",
                "vill_name": "ນ້ຳເຮ້ຍ",
                "vill_name_en": "",
                "dr_id": "806"
              },
              {
                "vill_id": "806016",
                "vill_name": "ນ້ຳປັງ",
                "vill_name_en": "",
                "dr_id": "806"
              },
              {
                "vill_id": "806017",
                "vill_name": "ສົມສະຫວັນ",
                "vill_name_en": "",
                "dr_id": "806"
              },
              {
                "vill_id": "806018",
                "vill_name": "ຫນອງບົວພີ່",
                "vill_name_en": "",
                "dr_id": "806"
              },
              {
                "vill_id": "806020",
                "vill_name": "ສີບຸນເຮືອງ",
                "vill_name_en": "",
                "dr_id": "806"
              },
              {
                "vill_id": "806021",
                "vill_name": "ນາໂສມ",
                "vill_name_en": "",
                "dr_id": "806"
              },
              {
                "vill_id": "806022",
                "vill_name": "ນາຕານ",
                "vill_name_en": "",
                "dr_id": "806"
              },
              {
                "vill_id": "806023",
                "vill_name": "ນາຊິງ",
                "vill_name_en": "",
                "dr_id": "806"
              },
              {
                "vill_id": "806024",
                "vill_name": "ນາບວມ",
                "vill_name_en": "",
                "dr_id": "806"
              },
              {
                "vill_id": "806025",
                "vill_name": "ຫນອງບົວຊາຍ",
                "vill_name_en": "",
                "dr_id": "806"
              },
              {
                "vill_id": "806026",
                "vill_name": "ໂພນຊຽງ",
                "vill_name_en": "",
                "dr_id": "806"
              },
              {
                "vill_id": "806027",
                "vill_name": "ຫນອງບົວທອງ",
                "vill_name_en": "",
                "dr_id": "806"
              },
              {
                "vill_id": "806028",
                "vill_name": "ຫົວນາ",
                "vill_name_en": "",
                "dr_id": "806"
              },
              {
                "vill_id": "806029",
                "vill_name": "ນາແຄມ",
                "vill_name_en": "",
                "dr_id": "806"
              },
              {
                "vill_id": "806030",
                "vill_name": "ຫນອງຮຸງ",
                "vill_name_en": "",
                "dr_id": "806"
              },
              {
                "vill_id": "806031",
                "vill_name": "ວຽງຄຳ",
                "vill_name_en": "",
                "dr_id": "806"
              },
              {
                "vill_id": "806032",
                "vill_name": "ຫ້ວຍອູມ",
                "vill_name_en": "",
                "dr_id": "806"
              },
              {
                "vill_id": "806033",
                "vill_name": "ວຽງຈະເລີນ",
                "vill_name_en": "",
                "dr_id": "806"
              },
              {
                "vill_id": "806034",
                "vill_name": "ນ້ຳປຸຍ",
                "vill_name_en": "",
                "dr_id": "806"
              },
              {
                "vill_id": "806035",
                "vill_name": "ປາກຊອງ",
                "vill_name_en": "",
                "dr_id": "806"
              },
              {
                "vill_id": "806036",
                "vill_name": "ນາແວນ",
                "vill_name_en": "",
                "dr_id": "806"
              },
              {
                "vill_id": "806037",
                "vill_name": "ພູຟ້າມີໄຊ",
                "vill_name_en": "",
                "dr_id": "806"
              },
              {
                "vill_id": "806038",
                "vill_name": "ນ້ຳຍາບ",
                "vill_name_en": "",
                "dr_id": "806"
              },
              {
                "vill_id": "806040",
                "vill_name": "ນາມໍ້",
                "vill_name_en": "",
                "dr_id": "806"
              },
              {
                "vill_id": "806041",
                "vill_name": "ນ້ຳພອນ",
                "vill_name_en": "",
                "dr_id": "806"
              },
              {
                "vill_id": "806042",
                "vill_name": "ຫ້ວຍຫານ",
                "vill_name_en": "",
                "dr_id": "806"
              },
              {
                "vill_id": "806043",
                "vill_name": "ນາແລ",
                "vill_name_en": "",
                "dr_id": "806"
              },
              {
                "vill_id": "806044",
                "vill_name": "ວັງຄຳ",
                "vill_name_en": "",
                "dr_id": "806"
              },
              {
                "vill_id": "806045",
                "vill_name": "ໂພນສະອາດ",
                "vill_name_en": "",
                "dr_id": "806"
              },
              {
                "vill_id": "806046",
                "vill_name": "ສົມສະຫວາດ",
                "vill_name_en": "",
                "dr_id": "806"
              },
              {
                "vill_id": "806047",
                "vill_name": "ນາອູມ",
                "vill_name_en": "",
                "dr_id": "806"
              },
              {
                "vill_id": "806048",
                "vill_name": "ໂພນທອງ",
                "vill_name_en": "",
                "dr_id": "806"
              },
              {
                "vill_id": "806049",
                "vill_name": "ນາກອງ",
                "vill_name_en": "",
                "dr_id": "806"
              },
              {
                "vill_id": "806050",
                "vill_name": "ໂພນສັກ",
                "vill_name_en": "",
                "dr_id": "806"
              },
              {
                "vill_id": "806051",
                "vill_name": "ໂຮມໄຊ",
                "vill_name_en": "",
                "dr_id": "806"
              },
              {
                "vill_id": "806052",
                "vill_name": "ນາໜອງຮຸງ",
                "vill_name_en": "Nanonghoung",
                "dr_id": "806"
              }
            ]
          },
          {
            "dr_id": "807",
            "dr_name": "ປາກລາຍ",
            "dr_name_en": "Parklai",
            "pr_id": "8",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "807001",
                "vill_name": "ນາຕຸງ",
                "vill_name_en": "",
                "dr_id": "807"
              },
              {
                "vill_id": "807002",
                "vill_name": "ນາຄານ",
                "vill_name_en": "",
                "dr_id": "807"
              },
              {
                "vill_id": "807003",
                "vill_name": "ປາ​ຕຸງ",
                "vill_name_en": "",
                "dr_id": "807"
              },
              {
                "vill_id": "807004",
                "vill_name": "ຫນອງໄຄ້",
                "vill_name_en": "",
                "dr_id": "807"
              },
              {
                "vill_id": "807005",
                "vill_name": "ນາຈັນ",
                "vill_name_en": "",
                "dr_id": "807"
              },
              {
                "vill_id": "807006",
                "vill_name": "ເມືອງປາ",
                "vill_name_en": "",
                "dr_id": "807"
              },
              {
                "vill_id": "807007",
                "vill_name": "ຜາລຽບ",
                "vill_name_en": "",
                "dr_id": "807"
              },
              {
                "vill_id": "807008",
                "vill_name": "ນາໂພ",
                "vill_name_en": "",
                "dr_id": "807"
              },
              {
                "vill_id": "807009",
                "vill_name": "ນາຄະຍາງ",
                "vill_name_en": "",
                "dr_id": "807"
              },
              {
                "vill_id": "807010",
                "vill_name": "ວັງພະຫມອນ",
                "vill_name_en": "",
                "dr_id": "807"
              },
              {
                "vill_id": "807011",
                "vill_name": "ໂພນງາມ",
                "vill_name_en": "",
                "dr_id": "807"
              },
              {
                "vill_id": "807012",
                "vill_name": "ທາດດ້າຍ",
                "vill_name_en": "",
                "dr_id": "807"
              },
              {
                "vill_id": "807013",
                "vill_name": "ນາຕ້າ",
                "vill_name_en": "",
                "dr_id": "807"
              },
              {
                "vill_id": "807014",
                "vill_name": "ມ່ວງເຫນືອ",
                "vill_name_en": "",
                "dr_id": "807"
              },
              {
                "vill_id": "807015",
                "vill_name": "ມ່ວງໃຕ້",
                "vill_name_en": "",
                "dr_id": "807"
              },
              {
                "vill_id": "807016",
                "vill_name": "ທ່າປໍ",
                "vill_name_en": "",
                "dr_id": "807"
              },
              {
                "vill_id": "807017",
                "vill_name": "ທ່ານາ",
                "vill_name_en": "",
                "dr_id": "807"
              },
              {
                "vill_id": "807018",
                "vill_name": "ໂພນໄຊ",
                "vill_name_en": "",
                "dr_id": "807"
              },
              {
                "vill_id": "807019",
                "vill_name": "ກາງ",
                "vill_name_en": "",
                "dr_id": "807"
              },
              {
                "vill_id": "807020",
                "vill_name": "ສຸວັນນະພູມ",
                "vill_name_en": "",
                "dr_id": "807"
              },
              {
                "vill_id": "807021",
                "vill_name": "ນາກາງ",
                "vill_name_en": "",
                "dr_id": "807"
              },
              {
                "vill_id": "807022",
                "vill_name": "ປາງຄຳ",
                "vill_name_en": "",
                "dr_id": "807"
              },
              {
                "vill_id": "807023",
                "vill_name": "ນ້ຳລີນ",
                "vill_name_en": "",
                "dr_id": "807"
              },
              {
                "vill_id": "807024",
                "vill_name": "ດອນ",
                "vill_name_en": "",
                "dr_id": "807"
              },
              {
                "vill_id": "807025",
                "vill_name": "ແຄ",
                "vill_name_en": "",
                "dr_id": "807"
              },
              {
                "vill_id": "807026",
                "vill_name": "ໂຄກສະຫວັນ",
                "vill_name_en": "",
                "dr_id": "807"
              },
              {
                "vill_id": "807027",
                "vill_name": "ນາແຊງ",
                "vill_name_en": "",
                "dr_id": "807"
              },
              {
                "vill_id": "807028",
                "vill_name": "ທ່າມີໄຊ",
                "vill_name_en": "",
                "dr_id": "807"
              },
              {
                "vill_id": "807029",
                "vill_name": "ຫ້ວຍມົດສົ້ມ",
                "vill_name_en": "",
                "dr_id": "807"
              },
              {
                "vill_id": "807030",
                "vill_name": "ນາໃຫມ່",
                "vill_name_en": "",
                "dr_id": "807"
              },
              {
                "vill_id": "807031",
                "vill_name": "ເມືອງວາ",
                "vill_name_en": "",
                "dr_id": "807"
              },
              {
                "vill_id": "807032",
                "vill_name": "ບົວບານ",
                "vill_name_en": "",
                "dr_id": "807"
              },
              {
                "vill_id": "807033",
                "vill_name": "ໂພນ",
                "vill_name_en": "",
                "dr_id": "807"
              },
              {
                "vill_id": "807034",
                "vill_name": "ນາຄຳ",
                "vill_name_en": "",
                "dr_id": "807"
              },
              {
                "vill_id": "807035",
                "vill_name": "ປົ່ງຫວານ",
                "vill_name_en": "",
                "dr_id": "807"
              },
              {
                "vill_id": "807036",
                "vill_name": "ວັງທຸ່ມ",
                "vill_name_en": "",
                "dr_id": "807"
              },
              {
                "vill_id": "807037",
                "vill_name": "ປົ່ງຄໍ້",
                "vill_name_en": "",
                "dr_id": "807"
              },
              {
                "vill_id": "807038",
                "vill_name": "ປາງຄ່​",
                "vill_name_en": "",
                "dr_id": "807"
              },
              {
                "vill_id": "807039",
                "vill_name": "ນ້ຳຊົ້ງ",
                "vill_name_en": "",
                "dr_id": "807"
              },
              {
                "vill_id": "807040",
                "vill_name": "ຫ້ວຍລາຍນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "807"
              },
              {
                "vill_id": "807041",
                "vill_name": "ແສງລາຍ",
                "vill_name_en": "",
                "dr_id": "807"
              },
              {
                "vill_id": "807042",
                "vill_name": "ສີສະຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "807"
              },
              {
                "vill_id": "807043",
                "vill_name": "ນາສະຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "807"
              },
              {
                "vill_id": "807044",
                "vill_name": "ຫ້ວຍຊາຍຄຳ",
                "vill_name_en": "",
                "dr_id": "807"
              },
              {
                "vill_id": "807045",
                "vill_name": "ສີສະອາດຊົມພູ",
                "vill_name_en": "",
                "dr_id": "807"
              },
              {
                "vill_id": "807046",
                "vill_name": "ໄຊຍະມຸງຄຸນ",
                "vill_name_en": "",
                "dr_id": "807"
              },
              {
                "vill_id": "807047",
                "vill_name": "ນາສັກ",
                "vill_name_en": "",
                "dr_id": "807"
              },
              {
                "vill_id": "807048",
                "vill_name": "ແກ້ງສາວ",
                "vill_name_en": "",
                "dr_id": "807"
              },
              {
                "vill_id": "807049",
                "vill_name": "ບຸ່ງມ່າ",
                "vill_name_en": "",
                "dr_id": "807"
              },
              {
                "vill_id": "807050",
                "vill_name": "ຕາກແດດ",
                "vill_name_en": "",
                "dr_id": "807"
              },
              {
                "vill_id": "807051",
                "vill_name": "ຫ້ວຍຕ່າງ",
                "vill_name_en": "",
                "dr_id": "807"
              },
              {
                "vill_id": "807052",
                "vill_name": "ດົງສະຫງວນ",
                "vill_name_en": "",
                "dr_id": "807"
              },
              {
                "vill_id": "807053",
                "vill_name": "ຫ້ວຍໄຮ",
                "vill_name_en": "",
                "dr_id": "807"
              },
              {
                "vill_id": "807054",
                "vill_name": "ປາກແລງ",
                "vill_name_en": "",
                "dr_id": "807"
              },
              {
                "vill_id": "807055",
                "vill_name": "ຫ້ວຍເດື່ອຫວານ",
                "vill_name_en": "",
                "dr_id": "807"
              },
              {
                "vill_id": "807056",
                "vill_name": "ຫ້ວຍ​ຫລັກ",
                "vill_name_en": "",
                "dr_id": "807"
              },
              {
                "vill_id": "807057",
                "vill_name": "ນ້ຳຍາງ",
                "vill_name_en": "",
                "dr_id": "807"
              },
              {
                "vill_id": "807058",
                "vill_name": "ຂອນແກ່ນ",
                "vill_name_en": "",
                "dr_id": "807"
              },
              {
                "vill_id": "807059",
                "vill_name": "ຜາແກ້ວ",
                "vill_name_en": "",
                "dr_id": "807"
              },
              {
                "vill_id": "807060",
                "vill_name": "ບວມເລົາ",
                "vill_name_en": "",
                "dr_id": "807"
              },
              {
                "vill_id": "807061",
                "vill_name": "ແສນພົນ",
                "vill_name_en": "",
                "dr_id": "807"
              },
              {
                "vill_id": "807062",
                "vill_name": "ປ່າໄລ່",
                "vill_name_en": "",
                "dr_id": "807"
              },
              {
                "vill_id": "807063",
                "vill_name": "ປ່າໄລ່",
                "vill_name_en": "",
                "dr_id": "807"
              },
              {
                "vill_id": "807064",
                "vill_name": "ລາດສະຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "807"
              },
              {
                "vill_id": "807065",
                "vill_name": "ວັງຍາວ",
                "vill_name_en": "",
                "dr_id": "807"
              },
              {
                "vill_id": "807066",
                "vill_name": "ປອນຂ້າມ",
                "vill_name_en": "",
                "dr_id": "807"
              },
              {
                "vill_id": "807067",
                "vill_name": "ຫ້ວຍຄວາຍ",
                "vill_name_en": "",
                "dr_id": "807"
              },
              {
                "vill_id": "807068",
                "vill_name": "ຫ້ວຍຊາຍຂາວ",
                "vill_name_en": "",
                "dr_id": "807"
              },
              {
                "vill_id": "807069",
                "vill_name": "ປາກຄານ",
                "vill_name_en": "",
                "dr_id": "807"
              },
              {
                "vill_id": "807070",
                "vill_name": "ນ້ຳປາ",
                "vill_name_en": "",
                "dr_id": "807"
              },
              {
                "vill_id": "807071",
                "vill_name": "ສີວິໄລ",
                "vill_name_en": "",
                "dr_id": "807"
              },
              {
                "vill_id": "807072",
                "vill_name": "ຫ້ວຍງູ",
                "vill_name_en": "",
                "dr_id": "807"
              },
              {
                "vill_id": "807073",
                "vill_name": "ຫາດດ້າຍ",
                "vill_name_en": "Harddai",
                "dr_id": "807"
              }
            ]
          },
          {
            "dr_id": "808",
            "dr_name": "ແກ່ນທ້າວ",
            "dr_name_en": "Kenethao",
            "pr_id": "8",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "808001",
                "vill_name": "ຈອມແກ້ວ",
                "vill_name_en": "",
                "dr_id": "808"
              },
              {
                "vill_id": "808002",
                "vill_name": "ຈອມເພັດ",
                "vill_name_en": "",
                "dr_id": "808"
              },
              {
                "vill_id": "808003",
                "vill_name": "ເມືອງເກົ່າ",
                "vill_name_en": "",
                "dr_id": "808"
              },
              {
                "vill_id": "808004",
                "vill_name": "ທ້າວແກ່ນ",
                "vill_name_en": "",
                "dr_id": "808"
              },
              {
                "vill_id": "808005",
                "vill_name": "ພວນ",
                "vill_name_en": "",
                "dr_id": "808"
              },
              {
                "vill_id": "808006",
                "vill_name": "ຫາດແດງ",
                "vill_name_en": "",
                "dr_id": "808"
              },
              {
                "vill_id": "808007",
                "vill_name": "ຫ້ວຍອີ່ຮູມ",
                "vill_name_en": "",
                "dr_id": "808"
              },
              {
                "vill_id": "808008",
                "vill_name": "ນາບອນ",
                "vill_name_en": "",
                "dr_id": "808"
              },
              {
                "vill_id": "808009",
                "vill_name": "ຫ້ວຍຕໍ",
                "vill_name_en": "",
                "dr_id": "808"
              },
              {
                "vill_id": "808010",
                "vill_name": "ເມືອງຫມໍ",
                "vill_name_en": "",
                "dr_id": "808"
              },
              {
                "vill_id": "808011",
                "vill_name": "ນາແກ້ງມ້າ",
                "vill_name_en": "",
                "dr_id": "808"
              },
              {
                "vill_id": "808012",
                "vill_name": "ກອນຕາປູ່",
                "vill_name_en": "",
                "dr_id": "808"
              },
              {
                "vill_id": "808013",
                "vill_name": "ໂພນສະຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "808"
              },
              {
                "vill_id": "808014",
                "vill_name": "ກົກກະບົກ",
                "vill_name_en": "",
                "dr_id": "808"
              },
              {
                "vill_id": "808015",
                "vill_name": "ດົງສາມແສນ",
                "vill_name_en": "",
                "dr_id": "808"
              },
              {
                "vill_id": "808016",
                "vill_name": "ກ້ວຍແຂ້",
                "vill_name_en": "",
                "dr_id": "808"
              },
              {
                "vill_id": "808017",
                "vill_name": "ນາຫີນ",
                "vill_name_en": "",
                "dr_id": "808"
              },
              {
                "vill_id": "808018",
                "vill_name": "ສ້າມຊົ້ງ",
                "vill_name_en": "",
                "dr_id": "808"
              },
              {
                "vill_id": "808019",
                "vill_name": "ປາກຕົມ",
                "vill_name_en": "",
                "dr_id": "808"
              },
              {
                "vill_id": "808020",
                "vill_name": "ມ່ວງເພົ້າ",
                "vill_name_en": "",
                "dr_id": "808"
              },
              {
                "vill_id": "808021",
                "vill_name": "ຫ້ວຍເຮ້ຍ",
                "vill_name_en": "",
                "dr_id": "808"
              },
              {
                "vill_id": "808022",
                "vill_name": "ຫ້ວຍຄາ",
                "vill_name_en": "",
                "dr_id": "808"
              },
              {
                "vill_id": "808023",
                "vill_name": "ປາກແຄມ",
                "vill_name_en": "",
                "dr_id": "808"
              },
              {
                "vill_id": "808024",
                "vill_name": "ນ້ຳເຕົ່າ",
                "vill_name_en": "",
                "dr_id": "808"
              },
              {
                "vill_id": "808025",
                "vill_name": "ຫົວຂົວ",
                "vill_name_en": "",
                "dr_id": "808"
              },
              {
                "vill_id": "808026",
                "vill_name": "ໂພນປ່າປໍ",
                "vill_name_en": "",
                "dr_id": "808"
              },
              {
                "vill_id": "808027",
                "vill_name": "ກົກກະທອນ",
                "vill_name_en": "",
                "dr_id": "808"
              },
              {
                "vill_id": "808028",
                "vill_name": "ກົກໄຮຄຳ",
                "vill_name_en": "",
                "dr_id": "808"
              },
              {
                "vill_id": "808029",
                "vill_name": "ຂຸ້ມສ້ຽວ",
                "vill_name_en": "",
                "dr_id": "808"
              },
              {
                "vill_id": "808030",
                "vill_name": "ຫນອງ",
                "vill_name_en": "",
                "dr_id": "808"
              },
              {
                "vill_id": "808031",
                "vill_name": "ປົ່ງແດງ",
                "vill_name_en": "",
                "dr_id": "808"
              },
              {
                "vill_id": "808032",
                "vill_name": "ວັງປູນ",
                "vill_name_en": "",
                "dr_id": "808"
              },
              {
                "vill_id": "808033",
                "vill_name": "ຫ້ວຍເລິກ",
                "vill_name_en": "",
                "dr_id": "808"
              },
              {
                "vill_id": "808034",
                "vill_name": "ວັງປາ",
                "vill_name_en": "",
                "dr_id": "808"
              },
              {
                "vill_id": "808035",
                "vill_name": "ສວນກ້ວຍ",
                "vill_name_en": "",
                "dr_id": "808"
              },
              {
                "vill_id": "808036",
                "vill_name": "ຫ້ວຍບູຮາ",
                "vill_name_en": "",
                "dr_id": "808"
              },
              {
                "vill_id": "808037",
                "vill_name": "ລາດຂາມປ້ອມ",
                "vill_name_en": "",
                "dr_id": "808"
              },
              {
                "vill_id": "808038",
                "vill_name": "ຫ້ວຍເປັດ",
                "vill_name_en": "",
                "dr_id": "808"
              },
              {
                "vill_id": "808039",
                "vill_name": "ຫ້ວຍຫລອດ",
                "vill_name_en": "",
                "dr_id": "808"
              },
              {
                "vill_id": "808040",
                "vill_name": "ນາຄູນ",
                "vill_name_en": "",
                "dr_id": "808"
              },
              {
                "vill_id": "808042",
                "vill_name": "ດອນເມນ",
                "vill_name_en": "",
                "dr_id": "808"
              },
              {
                "vill_id": "808043",
                "vill_name": "ແຄ",
                "vill_name_en": "",
                "dr_id": "808"
              },
              {
                "vill_id": "808044",
                "vill_name": "ນາຄຳ",
                "vill_name_en": "",
                "dr_id": "808"
              },
              {
                "vill_id": "808045",
                "vill_name": "ດອນສັ້ງ",
                "vill_name_en": "",
                "dr_id": "808"
              },
              {
                "vill_id": "808046",
                "vill_name": "ຫ້ວຍລ້າ",
                "vill_name_en": "",
                "dr_id": "808"
              },
              {
                "vill_id": "808047",
                "vill_name": "ເວີນຄຳ",
                "vill_name_en": "",
                "dr_id": "808"
              },
              {
                "vill_id": "808048",
                "vill_name": "ປຸ່ງຄ້າ",
                "vill_name_en": "",
                "dr_id": "808"
              }
            ]
          },
          {
            "dr_id": "809",
            "dr_name": "ບໍ່ແຕນ",
            "dr_name_en": "Botene",
            "pr_id": "8",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "809001",
                "vill_name": "ຫນອງປະຈີດ",
                "vill_name_en": "",
                "dr_id": "809"
              },
              {
                "vill_id": "809002",
                "vill_name": "ຂອນແກ່ນ",
                "vill_name_en": "",
                "dr_id": "809"
              },
              {
                "vill_id": "809003",
                "vill_name": "ນາບົງເຫນືອ",
                "vill_name_en": "",
                "dr_id": "809"
              },
              {
                "vill_id": "809004",
                "vill_name": "ນາບົງໃຕ້",
                "vill_name_en": "",
                "dr_id": "809"
              },
              {
                "vill_id": "809005",
                "vill_name": "ນາເມືອງ",
                "vill_name_en": "",
                "dr_id": "809"
              },
              {
                "vill_id": "809006",
                "vill_name": "ນ້ຳພຸ",
                "vill_name_en": "",
                "dr_id": "809"
              },
              {
                "vill_id": "809007",
                "vill_name": "ນ້ຳດົ້ນ",
                "vill_name_en": "",
                "dr_id": "809"
              },
              {
                "vill_id": "809009",
                "vill_name": "ຕາເລິ່ງ",
                "vill_name_en": "",
                "dr_id": "809"
              },
              {
                "vill_id": "809010",
                "vill_name": "ນາກອກ",
                "vill_name_en": "",
                "dr_id": "809"
              },
              {
                "vill_id": "809012",
                "vill_name": "ນາສ້ານ",
                "vill_name_en": "",
                "dr_id": "809"
              },
              {
                "vill_id": "809013",
                "vill_name": "ໃຫມ່ໂພນໄຊ",
                "vill_name_en": "",
                "dr_id": "809"
              },
              {
                "vill_id": "809015",
                "vill_name": "ຫ້ວຍຕາປູ",
                "vill_name_en": "",
                "dr_id": "809"
              },
              {
                "vill_id": "809016",
                "vill_name": "ນາແລ້ງ",
                "vill_name_en": "",
                "dr_id": "809"
              },
              {
                "vill_id": "809017",
                "vill_name": "ກຸ່ມ",
                "vill_name_en": "",
                "dr_id": "809"
              },
              {
                "vill_id": "809018",
                "vill_name": "ຫນອງໃຫຍ່",
                "vill_name_en": "",
                "dr_id": "809"
              },
              {
                "vill_id": "809019",
                "vill_name": "ຫນອງຜັກບົ້ງ",
                "vill_name_en": "",
                "dr_id": "809"
              },
              {
                "vill_id": "809020",
                "vill_name": "ທ່ານາງ",
                "vill_name_en": "",
                "dr_id": "809"
              },
              {
                "vill_id": "809021",
                "vill_name": "ບໍ່ແຕນ",
                "vill_name_en": "",
                "dr_id": "809"
              },
              {
                "vill_id": "809022",
                "vill_name": "ດອນສະວ່າງ",
                "vill_name_en": "",
                "dr_id": "809"
              },
              {
                "vill_id": "809023",
                "vill_name": "ເຕົາເກືອ",
                "vill_name_en": "",
                "dr_id": "809"
              },
              {
                "vill_id": "809024",
                "vill_name": "ໃຫຍ່",
                "vill_name_en": "",
                "dr_id": "809"
              },
              {
                "vill_id": "809025",
                "vill_name": "ນາຂ່າ",
                "vill_name_en": "",
                "dr_id": "809"
              },
              {
                "vill_id": "809026",
                "vill_name": "ເຫນືອງແຜ່",
                "vill_name_en": "",
                "dr_id": "809"
              },
              {
                "vill_id": "809027",
                "vill_name": "ບໍ່ຫາງນາງ",
                "vill_name_en": "",
                "dr_id": "809"
              },
              {
                "vill_id": "809028",
                "vill_name": "ນາບໍ່ນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "809"
              },
              {
                "vill_id": "809029",
                "vill_name": "ຕົ້ນຕານ",
                "vill_name_en": "",
                "dr_id": "809"
              },
              {
                "vill_id": "809030",
                "vill_name": "ບໍ່ສ້ານ",
                "vill_name_en": "",
                "dr_id": "809"
              }
            ]
          },
          {
            "dr_id": "810",
            "dr_name": "ທົ່ງມີໄຊ",
            "dr_name_en": "Thongmyxay",
            "pr_id": "8",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "810001",
                "vill_name": "ນາມ່ວງ",
                "vill_name_en": "",
                "dr_id": "810"
              },
              {
                "vill_id": "810002",
                "vill_name": "ແດດ",
                "vill_name_en": "",
                "dr_id": "810"
              },
              {
                "vill_id": "810003",
                "vill_name": "ນາຝາຍ",
                "vill_name_en": "",
                "dr_id": "810"
              },
              {
                "vill_id": "810004",
                "vill_name": "ໃຫມ່",
                "vill_name_en": "",
                "dr_id": "810"
              },
              {
                "vill_id": "810005",
                "vill_name": "ສະຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "810"
              },
              {
                "vill_id": "810006",
                "vill_name": "ໃຫຍ່",
                "vill_name_en": "",
                "dr_id": "810"
              },
              {
                "vill_id": "810007",
                "vill_name": "ດອນພູນ",
                "vill_name_en": "",
                "dr_id": "810"
              },
              {
                "vill_id": "810008",
                "vill_name": "ນາເປືອຍ",
                "vill_name_en": "",
                "dr_id": "810"
              },
              {
                "vill_id": "810009",
                "vill_name": "ນາຫມອກ",
                "vill_name_en": "",
                "dr_id": "810"
              },
              {
                "vill_id": "810010",
                "vill_name": "ນາມົນ",
                "vill_name_en": "",
                "dr_id": "810"
              },
              {
                "vill_id": "810011",
                "vill_name": "ທາດ",
                "vill_name_en": "",
                "dr_id": "810"
              },
              {
                "vill_id": "810012",
                "vill_name": "ດ່ານ",
                "vill_name_en": "",
                "dr_id": "810"
              },
              {
                "vill_id": "810013",
                "vill_name": "ແຄ່ນ",
                "vill_name_en": "",
                "dr_id": "810"
              }
            ]
          },
          {
            "dr_id": "811",
            "dr_name": "ໄຊຊະຖານ",
            "dr_name_en": "Xaysathan",
            "pr_id": "8",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "811001",
                "vill_name": "ສະແມດໃຫຍ່",
                "vill_name_en": "",
                "dr_id": "811"
              },
              {
                "vill_id": "811002",
                "vill_name": "ກໍ່ໃຫຍ່",
                "vill_name_en": "",
                "dr_id": "811"
              },
              {
                "vill_id": "811003",
                "vill_name": "ສາມັກຄີໄຊ",
                "vill_name_en": "",
                "dr_id": "811"
              },
              {
                "vill_id": "811004",
                "vill_name": "ເສົາດຽວ",
                "vill_name_en": "",
                "dr_id": "811"
              },
              {
                "vill_id": "811005",
                "vill_name": "ພູຕຸ່ຍ",
                "vill_name_en": "",
                "dr_id": "811"
              },
              {
                "vill_id": "811006",
                "vill_name": "ປາກລອງ",
                "vill_name_en": "",
                "dr_id": "811"
              },
              {
                "vill_id": "811007",
                "vill_name": "ສັນແຕ",
                "vill_name_en": "",
                "dr_id": "811"
              },
              {
                "vill_id": "811008",
                "vill_name": "ມີໄຊ",
                "vill_name_en": "",
                "dr_id": "811"
              },
              {
                "vill_id": "811009",
                "vill_name": "ສະມະ",
                "vill_name_en": "",
                "dr_id": "811"
              },
              {
                "vill_id": "811010",
                "vill_name": "ສະແມດນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "811"
              },
              {
                "vill_id": "811011",
                "vill_name": "ສະແມດໃຫຍ່",
                "vill_name_en": "",
                "dr_id": "811"
              },
              {
                "vill_id": "811012",
                "vill_name": "ດອຍກາວ",
                "vill_name_en": "",
                "dr_id": "811"
              },
              {
                "vill_id": "811013",
                "vill_name": "ສີສາຍທອງ",
                "vill_name_en": "",
                "dr_id": "811"
              },
              {
                "vill_id": "811014",
                "vill_name": "ສະຖານ",
                "vill_name_en": "",
                "dr_id": "811"
              },
              {
                "vill_id": "811015",
                "vill_name": "ພູເລິ່ນ",
                "vill_name_en": "",
                "dr_id": "811"
              },
              {
                "vill_id": "811016",
                "vill_name": "ຫ້ວຍສະຫລອດ",
                "vill_name_en": "",
                "dr_id": "811"
              },
              {
                "vill_id": "811017",
                "vill_name": "ກ້ອງທ່ຽງ",
                "vill_name_en": "",
                "dr_id": "811"
              },
              {
                "vill_id": "811018",
                "vill_name": "ກິ່ວເປາະ",
                "vill_name_en": "",
                "dr_id": "811"
              },
              {
                "vill_id": "811019",
                "vill_name": "ສະລາ",
                "vill_name_en": "",
                "dr_id": "811"
              },
              {
                "vill_id": "811020",
                "vill_name": "ສະປີ",
                "vill_name_en": "",
                "dr_id": "811"
              },
              {
                "vill_id": "811021",
                "vill_name": "ສະເຂດ",
                "vill_name_en": "",
                "dr_id": "811"
              },
              {
                "vill_id": "811022",
                "vill_name": "ກິິ່ວກອກ",
                "vill_name_en": "",
                "dr_id": "811"
              }
            ]
          }
        ]
      },
      {
        "pr_id": "9",
        "pr_name": "ຊຽງຂວາງ",
        "pr_name_en": "Xiangkhoang",
        "districts": [
          {"dr_name": "ເລືອກເມືອງປະຈຸບັນ", "villages": []},
          {
            "dr_id": "901",
            "dr_name": "ແປກ",
            "dr_name_en": "Pek",
            "pr_id": "9",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "90101",
                "vill_name": "ເມືອງນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "90102",
                "vill_name": "ຊ້ວນ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "90103",
                "vill_name": "ອໍ້ອັ່ນ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "90104",
                "vill_name": "ບວກກົບ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "90105",
                "vill_name": "ນາສາລາ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "90106",
                "vill_name": "ປ່າຂົມ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "90107",
                "vill_name": "ນ້ຳຂະ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "90108",
                "vill_name": "ຜາແກ້ວ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "90109",
                "vill_name": "ທ່າໂຈກ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "90110",
                "vill_name": "ຄັງໂດນ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "90111",
                "vill_name": "ຕ້າ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "90113",
                "vill_name": "ຍອມ",
                "vill_name_en": "่່jom",
                "dr_id": "901"
              },
              {
                "vill_id": "901014",
                "vill_name": "ຍອດງື່ມ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901015",
                "vill_name": "ໂພນຄຳ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901016",
                "vill_name": "ມວນ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901017",
                "vill_name": "ລາດເທີນ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901018",
                "vill_name": "ວຽງໄຊ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901019",
                "vill_name": "ແກ່ວ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901020",
                "vill_name": "ຄາຍ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901021",
                "vill_name": "ຍວນທອງ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901022",
                "vill_name": "ນ້ຳເກາະ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901023",
                "vill_name": "ດ່ອງ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901024",
                "vill_name": "ຄັງໄຂ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901025",
                "vill_name": "ຄັງໄຂ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901026",
                "vill_name": "ໜອງແຮດ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901027",
                "vill_name": "ຊ້າງ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901028",
                "vill_name": "ວຽງ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901029",
                "vill_name": "ນາພົງ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901030",
                "vill_name": "ມອນ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901031",
                "vill_name": "ຮົວະ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901032",
                "vill_name": "ຄູ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901033",
                "vill_name": "ໂພນສະຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901034",
                "vill_name": "ໂພນມີໄຊ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901035",
                "vill_name": "ໂພນສະອາດເໜືອ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901036",
                "vill_name": "ຢອນ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901037",
                "vill_name": "ເທີນ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901038",
                "vill_name": "ໂພນຄຳ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901039",
                "vill_name": "ໂພນທອງ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901040",
                "vill_name": "ນ້ຳຫງຳ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901041",
                "vill_name": "ໂພນງາມເໜືອ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901042",
                "vill_name": "ໂພນສະຫວັນເໜືອ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901043",
                "vill_name": "ໂພນສະຫວັນໃຕ້",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901044",
                "vill_name": "ໂງຍ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901045",
                "vill_name": "ລີ່",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901046",
                "vill_name": "ຍວນ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901047",
                "vill_name": "ບີ້",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901048",
                "vill_name": "ນາໂອ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901049",
                "vill_name": "ນ້ຳເສີງ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901050",
                "vill_name": "ເຫຼັກ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901051",
                "vill_name": "ລາດຮ້ວງ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901052",
                "vill_name": "ນ້ຳຕົ້ມ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901053",
                "vill_name": "ຄັງຍາວ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901054",
                "vill_name": "ໂຕນໃຕ້",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901055",
                "vill_name": "ໂຕນເໜືອ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901056",
                "vill_name": "ນານູ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901057",
                "vill_name": "ພັ້ນ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901058",
                "vill_name": "ສີ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901059",
                "vill_name": "ໂພນໄຊ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901060",
                "vill_name": "ໝີ່",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901061",
                "vill_name": "ຄອງ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901062",
                "vill_name": "ປຸ່່ງແວນ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901063",
                "vill_name": "ວຽງທອງ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901064",
                "vill_name": "ໜອງ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901065",
                "vill_name": "ວຽງຄຳ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901067",
                "vill_name": "ພູຮຸ້ມ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901068",
                "vill_name": "ນາຄູນ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901069",
                "vill_name": "ນາຫອຍ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901070",
                "vill_name": "ລາດງ່ອນ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901071",
                "vill_name": "ນາເຮືອງ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901072",
                "vill_name": "ນາເທົາ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901073",
                "vill_name": "ຫ້ວຍສັ້ງ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901074",
                "vill_name": "ພູວຽງ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901075",
                "vill_name": "ນາດີ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901076",
                "vill_name": "ຄັງໜອງຫຼວງ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901077",
                "vill_name": "ນາຟ້າ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901078",
                "vill_name": "ວຽງຄວນ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901079",
                "vill_name": "ຫຍາບສີ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901080",
                "vill_name": "ຫວ້ານ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901081",
                "vill_name": "ນາເພີງ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901082",
                "vill_name": "ສີເມືອງ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901083",
                "vill_name": "ເປີບ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901084",
                "vill_name": "ໝີ່",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901085",
                "vill_name": "ມອນ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901086",
                "vill_name": "ໂພສີ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901088",
                "vill_name": "ໂພນ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901089",
                "vill_name": "ຍອດປຽງ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901090",
                "vill_name": "ມ່ວງພາ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901091",
                "vill_name": "ໂພນທອງ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901092",
                "vill_name": "ສີຄູນ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901093",
                "vill_name": "ນາລາມ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901094",
                "vill_name": "ໝ້ຽງ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901095",
                "vill_name": "ປຸ່ງປັນ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901096",
                "vill_name": "ຄັງວຽງ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901097",
                "vill_name": "ໝັ້ນຊົມ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901098",
                "vill_name": "ໄຜ່ງຳ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901099",
                "vill_name": "ລາວ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901100",
                "vill_name": "ສາຍລົມ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901101",
                "vill_name": "ໂພນສະຫວັນກາງ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901102",
                "vill_name": "ໂພນສະຫວັນໄຊ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901103",
                "vill_name": "ພູສັນ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901105",
                "vill_name": "ນາຄວນ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901106",
                "vill_name": "ນາຊາຍ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901107",
                "vill_name": "ໂພນງາມໃຕ້",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901108",
                "vill_name": "ໂພນງາມໃຕ້",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901109",
                "vill_name": "ໂພນສະອາດໃຕ້",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901110",
                "vill_name": "ທົ່ງມີໄຊ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901111",
                "vill_name": "ນາ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901112",
                "vill_name": "ລາດບວກ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901113",
                "vill_name": "ດູ່ຊາງ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901114",
                "vill_name": "ນາຄຳແພງ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901115",
                "vill_name": "ສີໂພໄຊ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901116",
                "vill_name": "ວຽງ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901117",
                "vill_name": "ອ່າງ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901118",
                "vill_name": "ນາຊູ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901119",
                "vill_name": "ສູນສຸຂະພາບ",
                "vill_name_en": "",
                "dr_id": "901"
              },
              {
                "vill_id": "901120",
                "vill_name": "ນາຊາລາ",
                "vill_name_en": "Nasala",
                "dr_id": "901"
              },
              {
                "vill_id": "901121",
                "vill_name": "ນາຄ່ອນ",
                "vill_name_en": "Nakhone",
                "dr_id": "901"
              },
              {
                "vill_id": "901122",
                "vill_name": "ໜອງຄາ",
                "vill_name_en": "Nongkha",
                "dr_id": "901"
              },
              {
                "vill_id": "901123",
                "vill_name": "ເດັກ SOS",
                "vill_name_en": "Lex SOS",
                "dr_id": "901"
              },
              {
                "vill_id": "901124",
                "vill_name": "ມຽນ",
                "vill_name_en": "Mien",
                "dr_id": "901"
              }
            ]
          },
          {
            "dr_id": "902",
            "dr_name": "ຄຳ",
            "dr_name_en": "Kham",
            "pr_id": "9",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "902001",
                "vill_name": "ໂພສີ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902002",
                "vill_name": "ໂພນໄຊ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902003",
                "vill_name": "ນາພຽງ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902004",
                "vill_name": "ໂພນທັນ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902005",
                "vill_name": "ຫີນ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902006",
                "vill_name": "ສັນ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902007",
                "vill_name": "ບານ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902008",
                "vill_name": "ຕ່ອງ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902009",
                "vill_name": "ຊາງ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902010",
                "vill_name": "ກວ້າຍ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902011",
                "vill_name": "ຈອມທອງ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902012",
                "vill_name": "ຕາດຫຼວງ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902013",
                "vill_name": "ມຸ້ນ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902014",
                "vill_name": "ໂພນຄຳ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902015",
                "vill_name": "ບົວໂພນຄຳ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902016",
                "vill_name": "ນາອຸງ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902017",
                "vill_name": "ໄຮ່ໜ່ຽງ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902018",
                "vill_name": "ລາດນົນ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902020",
                "vill_name": "ຍູ້ມຈອງ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902021",
                "vill_name": "ຮີບ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902022",
                "vill_name": "ດອນຄຳ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902023",
                "vill_name": "ນາປາ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902024",
                "vill_name": "ນ້ຳຮົ່ມ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902025",
                "vill_name": "ຜາທ້າງ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902026",
                "vill_name": "ຮວດ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902028",
                "vill_name": "ຝາຍ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902029",
                "vill_name": "ແລ້ງ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902030",
                "vill_name": "ບວມລອງ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902031",
                "vill_name": "ນາເມືອງ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902032",
                "vill_name": "ນ້ຳຈາກ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902033",
                "vill_name": "ຍອດເກື້ອ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902034",
                "vill_name": "ວຽງຄຳ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902035",
                "vill_name": "ພູເມືອງ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902036",
                "vill_name": "ຊ່ຽງກ່ຽວ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902037",
                "vill_name": "ນາທອງ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902038",
                "vill_name": "ຊາມ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902039",
                "vill_name": "ນາຕູມ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902040",
                "vill_name": "ໄຄ້ວຽງ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902041",
                "vill_name": "ທ້າ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902042",
                "vill_name": "ສັນພັນ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902043",
                "vill_name": "ນາໄຊ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902044",
                "vill_name": "ນາພັນ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902045",
                "vill_name": "ສົບອໍ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902047",
                "vill_name": "ປຸງມວນ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902049",
                "vill_name": "ສຳພັນໄຊ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902050",
                "vill_name": "ວັງຊົງ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902051",
                "vill_name": "ສຸມແໜນ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902052",
                "vill_name": "ສອງ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902053",
                "vill_name": "ວຽງ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902054",
                "vill_name": "ແກ່ວເລິກ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902055",
                "vill_name": "ຫວ້ຍຫອນ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902056",
                "vill_name": "ສັນແຟນ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902057",
                "vill_name": "ສັນໂພ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902058",
                "vill_name": "ສັນຄຳ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902059",
                "vill_name": "ຫາດຄຳ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902060",
                "vill_name": "ຍອດລ້ຽງ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902061",
                "vill_name": "ສວນມອນ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902062",
                "vill_name": "ພຽງດ້າງ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902063",
                "vill_name": "ສົບທ້າງ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902064",
                "vill_name": "ສົບນາ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902065",
                "vill_name": "ສົບເປີນ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902066",
                "vill_name": "ພຽງພ່າງ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902067",
                "vill_name": "ສັນແກ່ວ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902068",
                "vill_name": "ແກ່ວເຮືອ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902069",
                "vill_name": "ກາງຄຳ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902070",
                "vill_name": "ພຽງໂຄ້ງ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902071",
                "vill_name": "ແທ່ນຈອງ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902072",
                "vill_name": "ຜາຫານ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902073",
                "vill_name": "ໂພນຂາມ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902074",
                "vill_name": "ໂພນໂຮມ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902075",
                "vill_name": "ນ້ຳທຸ້ມ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902076",
                "vill_name": "ຜາກອກ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902078",
                "vill_name": "ສັນຈໍ່ໃຕ້",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902079",
                "vill_name": "ສັນຈໍ່ເໜືອ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902080",
                "vill_name": "ຍອດປຽກ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902081",
                "vill_name": "ຊົມ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902082",
                "vill_name": "ນາເໝືອຍ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902083",
                "vill_name": "ກໍ່ງີ້ວ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902084",
                "vill_name": "ຖາງ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902086",
                "vill_name": "ນາໄຜ່",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902087",
                "vill_name": "ນາຍອງ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902088",
                "vill_name": "ພຽງດີ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902089",
                "vill_name": "ພຽງຈັນ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902090",
                "vill_name": "ດອກຄຳ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902092",
                "vill_name": "ບວມພຽງ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902093",
                "vill_name": "ພູຫີນ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902094",
                "vill_name": "ແທ່ນໂທ້",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902095",
                "vill_name": "ນາງຫຼວງ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902096",
                "vill_name": "ຫ້ວຍພັດ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902097",
                "vill_name": "ໜອງໂອນ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902098",
                "vill_name": "ຄັງແຄ້",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902099",
                "vill_name": "ຈອມທອງໃຕ້",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902100",
                "vill_name": "ແງ້ວ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902102",
                "vill_name": "ໂພນແຈ້ງ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902103",
                "vill_name": "ໃໝ່ກໍຄາຍ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902104",
                "vill_name": "ຊາຍ-ນາດູ່",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902105",
                "vill_name": "ໂພໄຊ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902106",
                "vill_name": "ມ່ວງໄຊ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902108",
                "vill_name": "ນາດໍ່",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902109",
                "vill_name": "ຈອມສີ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902110",
                "vill_name": "ນ້ຳສາຍ",
                "vill_name_en": "",
                "dr_id": "902"
              },
              {
                "vill_id": "902111",
                "vill_name": "ທາດ",
                "vill_name_en": "Thad",
                "dr_id": "902"
              },
              {
                "vill_id": "902112",
                "vill_name": "ນາມວນ",
                "vill_name_en": "Namouane",
                "dr_id": "902"
              },
              {
                "vill_id": "902113",
                "vill_name": "ວັງບົວ",
                "vill_name_en": "vangbuon",
                "dr_id": "902"
              },
              {
                "vill_id": "902114",
                "vill_name": "ສັນກາງ",
                "vill_name_en": "Sankang",
                "dr_id": "902"
              },
              {
                "vill_id": "902115",
                "vill_name": "ລອງປີວ",
                "vill_name_en": "longpiew",
                "dr_id": "902"
              },
              {
                "vill_id": "902116",
                "vill_name": "ຕາດ",
                "vill_name_en": "Tard",
                "dr_id": "902"
              },
              {
                "vill_id": "902117",
                "vill_name": "ແລະນາດໍ່",
                "vill_name_en": "Laenador",
                "dr_id": "902"
              },
              {
                "vill_id": "902118",
                "vill_name": "ນາດາ",
                "vill_name_en": "Nada",
                "dr_id": "902"
              },
              {
                "vill_id": "902119",
                "vill_name": "ລຳຄວາຍ",
                "vill_name_en": "Lamkoauw",
                "dr_id": "902"
              },
              {
                "vill_id": "902120",
                "vill_name": "ຜາເຜິ້ງ",
                "vill_name_en": "Phaphuang",
                "dr_id": "902"
              }
            ]
          },
          {
            "dr_id": "903",
            "dr_name": "ໜອງແຮດ",
            "dr_name_en": "Nonghed",
            "pr_id": "9",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "903001",
                "vill_name": "ກໍສ້ານ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903002",
                "vill_name": "ພຽງຮັ່ງ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903003",
                "vill_name": "ແກ່ວເຈ້ຍ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903004",
                "vill_name": "ພຽງຊືນ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903005",
                "vill_name": "ປ່າໜີ່",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903006",
                "vill_name": "ຊ້າງເກັດ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903007",
                "vill_name": "ສັນຍົມ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903008",
                "vill_name": "ເຍຍ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903009",
                "vill_name": "ຫ້ວຍກໍ້",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903010",
                "vill_name": "ປ່າຈ້າ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903011",
                "vill_name": "ສວນອ້ອຍ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903012",
                "vill_name": "ຫ້ວຍດ່ານ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903014",
                "vill_name": "ໂພ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903015",
                "vill_name": "ດົງກໍ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903016",
                "vill_name": "ນ້ຳກຸດ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903017",
                "vill_name": "ຕີນຜາ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903018",
                "vill_name": "ບວມຟາດ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903019",
                "vill_name": "ໜອງ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903021",
                "vill_name": "ປ່າເລົ່າ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903022",
                "vill_name": "ວຽງ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903023",
                "vill_name": "ຍອດຕື່ນ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903024",
                "vill_name": "ຜາຫຼວງ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903025",
                "vill_name": "ປຸ່ງຫ້ອມ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903026",
                "vill_name": "ສັນໂດນ ກໍ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903027",
                "vill_name": "ສັນໂດນ ຂໍ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903028",
                "vill_name": "ຜາລີນ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903029",
                "vill_name": "ໜອງອໍ້",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903030",
                "vill_name": "ຖ້ຳຄຸ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903031",
                "vill_name": "ແກ່ວປະຕູ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903032",
                "vill_name": "ໜອງສາມແຈ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903033",
                "vill_name": "ຖ້ຳທຸ້ມ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903034",
                "vill_name": "ປ່າຫົກ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903035",
                "vill_name": "ຖ້ຳເສືອ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903036",
                "vill_name": "ປຸ່ງແຊງ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903037",
                "vill_name": "ຈ່າໝື່ນ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903038",
                "vill_name": "ໂພນໄຊ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903039",
                "vill_name": "ຊ່ຳຢວກ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903040",
                "vill_name": "ຫ້ວຍຄ້າງ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903041",
                "vill_name": "ໜອງຂຽວ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903042",
                "vill_name": "ຫ້ວຍເທົ່າ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903043",
                "vill_name": "ສັນຍົມ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903044",
                "vill_name": "ນ້ຳປີ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903045",
                "vill_name": "ຖ້ຳທ້າວ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903046",
                "vill_name": "ຫັວຍແອ່ນ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903047",
                "vill_name": "ປ່າລັນ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903048",
                "vill_name": "ກະບໍ້",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903049",
                "vill_name": "ຍອດຄາ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903050",
                "vill_name": "ຜາຫວາຍ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903051",
                "vill_name": "ຫ້ວຍຊວງ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903052",
                "vill_name": "ຄອກໜູ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903053",
                "vill_name": "ນ້ຳກົ້ນງົວ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903054",
                "vill_name": "ນ້ຳເໜັນ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903055",
                "vill_name": "ຜາງ້ອນ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903056",
                "vill_name": "ຜາແອ່ນ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903057",
                "vill_name": "ບວມດາວ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903058",
                "vill_name": "ພູໜອງ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903059",
                "vill_name": "ຜາອໍ້",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903060",
                "vill_name": "ຍອດຮວຍ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903061",
                "vill_name": "ນ້ຳຮວຍ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903062",
                "vill_name": "ພຽງມອນ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903063",
                "vill_name": "ສົບແຕນ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903064",
                "vill_name": "ກໍດູ່",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903065",
                "vill_name": "ຫ້ວຍຄ້ອງ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903066",
                "vill_name": "ຖ້ຳໄຊ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903067",
                "vill_name": "ໜອງແຮດ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903068",
                "vill_name": "ນ້ຳກວາງ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903069",
                "vill_name": "ໜອງຊ້າງ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903070",
                "vill_name": "ປ່າຂົມ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903071",
                "vill_name": "ໜອງແຫຼ້",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903072",
                "vill_name": "ຄັງພານຽນ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903073",
                "vill_name": "ພັກຫຼັກ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903074",
                "vill_name": "ແກ່ວບອນ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903075",
                "vill_name": "ຫ້ວຍລົມ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903077",
                "vill_name": "ຫາດບໍ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903078",
                "vill_name": "ຫ້ວຍນາ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903079",
                "vill_name": "ພັກແຄະໄຕ້",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903081",
                "vill_name": "ພັກແຄະເໜືອ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903082",
                "vill_name": "ໜອງກົບ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903083",
                "vill_name": "ຜາເມົາ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903085",
                "vill_name": "ຍອດຊາວ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903086",
                "vill_name": "ກໍຫາດ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903087",
                "vill_name": "ແກ່ວກອງ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903088",
                "vill_name": "ຖ້ຳຫີບ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903089",
                "vill_name": "ພູຫົວຊ້າງ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903090",
                "vill_name": "ຜາບ່ອງ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903091",
                "vill_name": "ຜາເພີ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903092",
                "vill_name": "ວັງຄຳ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903093",
                "vill_name": "ກໍທອງ ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903094",
                "vill_name": "ກໍມອນ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903095",
                "vill_name": "ໜອງແຮດໄຕ້",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903096",
                "vill_name": "ປາກາ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903097",
                "vill_name": "ດີນດຳ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903098",
                "vill_name": "ຖ້ຳປ່ອງ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903099",
                "vill_name": "ຫ້ວຍຄ້າງ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903100",
                "vill_name": "ບວກເສີ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903101",
                "vill_name": "ຫວ້ຍລົມ  ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903102",
                "vill_name": "ສັນຍົມ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903104",
                "vill_name": "ສຸມໄຜ່",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903105",
                "vill_name": "ຜາແວ່ນ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903106",
                "vill_name": "ນາເມືອງ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903107",
                "vill_name": "ແກ້ງແລດ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903108",
                "vill_name": "ຫວ້ຍເດື່ອ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903109",
                "vill_name": "ລ້ອງກວ້າງ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903110",
                "vill_name": "ຫວ້ຍແລນ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903111",
                "vill_name": "ສອງຮອງ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903112",
                "vill_name": "ສາມແຈ",
                "vill_name_en": "",
                "dr_id": "903"
              },
              {
                "vill_id": "903113",
                "vill_name": "ກ່ຽວກົງ",
                "vill_name_en": "Kiewkong",
                "dr_id": "903"
              }
            ]
          },
          {
            "dr_id": "904",
            "dr_name": "ຄູນ",
            "dr_name_en": "Khoune",
            "pr_id": "9",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "904001",
                "vill_name": "ສາມແຈ",
                "vill_name_en": "",
                "dr_id": "904"
              },
              {
                "vill_id": "904002",
                "vill_name": "ສີພົມ",
                "vill_name_en": "",
                "dr_id": "904"
              },
              {
                "vill_id": "904003",
                "vill_name": "ເພຍວັດ",
                "vill_name_en": "",
                "dr_id": "904"
              },
              {
                "vill_id": "904004",
                "vill_name": "ນາໄຊ",
                "vill_name_en": "",
                "dr_id": "904"
              },
              {
                "vill_id": "904005",
                "vill_name": "ນາສີ",
                "vill_name_en": "",
                "dr_id": "904"
              },
              {
                "vill_id": "904006",
                "vill_name": "ໂພນແຍງ",
                "vill_name_en": "",
                "dr_id": "904"
              },
              {
                "vill_id": "904007",
                "vill_name": "ນາຮໍ້",
                "vill_name_en": "",
                "dr_id": "904"
              },
              {
                "vill_id": "904008",
                "vill_name": "ຊ້າງ",
                "vill_name_en": "",
                "dr_id": "904"
              },
              {
                "vill_id": "904009",
                "vill_name": "ນາອູ",
                "vill_name_en": "",
                "dr_id": "904"
              },
              {
                "vill_id": "904010",
                "vill_name": "ໂຮງສີ",
                "vill_name_en": "",
                "dr_id": "904"
              },
              {
                "vill_id": "904011",
                "vill_name": "ໂພສີ",
                "vill_name_en": "",
                "dr_id": "904"
              },
              {
                "vill_id": "904012",
                "vill_name": "ທຸ້ມ",
                "vill_name_en": "",
                "dr_id": "904"
              },
              {
                "vill_id": "904013",
                "vill_name": "ໄຜ່",
                "vill_name_en": "",
                "dr_id": "904"
              },
              {
                "vill_id": "904014",
                "vill_name": "ນາໂພ",
                "vill_name_en": "",
                "dr_id": "904"
              },
              {
                "vill_id": "904016",
                "vill_name": "ສັນຄີິງ",
                "vill_name_en": "",
                "dr_id": "904"
              },
              {
                "vill_id": "904017",
                "vill_name": "ຊຳຍຸງ",
                "vill_name_en": "",
                "dr_id": "904"
              },
              {
                "vill_id": "904018",
                "vill_name": "ພູມຸ່ງເມືອງ",
                "vill_name_en": "",
                "dr_id": "904"
              },
              {
                "vill_id": "904019",
                "vill_name": "ນາທອງ",
                "vill_name_en": "",
                "dr_id": "904"
              },
              {
                "vill_id": "904020",
                "vill_name": "ນາລາມ",
                "vill_name_en": "",
                "dr_id": "904"
              },
              {
                "vill_id": "904021",
                "vill_name": "ຄັງໂຮງ",
                "vill_name_en": "",
                "dr_id": "904"
              },
              {
                "vill_id": "904022",
                "vill_name": "ທ່າລີນ",
                "vill_name_en": "",
                "dr_id": "904"
              },
              {
                "vill_id": "904023",
                "vill_name": "ຫວ້ຍຫົກ",
                "vill_name_en": "",
                "dr_id": "904"
              },
              {
                "vill_id": "904024",
                "vill_name": "ຫວ້ຍຫົກ",
                "vill_name_en": "",
                "dr_id": "904"
              },
              {
                "vill_id": "904025",
                "vill_name": "ຊັນນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "904"
              },
              {
                "vill_id": "904026",
                "vill_name": "ແທ່ນທອງ",
                "vill_name_en": "",
                "dr_id": "904"
              },
              {
                "vill_id": "904027",
                "vill_name": "ຄາບ",
                "vill_name_en": "",
                "dr_id": "904"
              },
              {
                "vill_id": "904028",
                "vill_name": "ແທ່ນພູນ",
                "vill_name_en": "",
                "dr_id": "904"
              },
              {
                "vill_id": "904029",
                "vill_name": "ແກ່ວເລິກ",
                "vill_name_en": "",
                "dr_id": "904"
              },
              {
                "vill_id": "904030",
                "vill_name": "ຜາຂາວ",
                "vill_name_en": "",
                "dr_id": "904"
              },
              {
                "vill_id": "904031",
                "vill_name": "ຫວ້ຍລຸນ",
                "vill_name_en": "",
                "dr_id": "904"
              },
              {
                "vill_id": "904032",
                "vill_name": "ຜາເຫຼອື້ມ",
                "vill_name_en": "",
                "dr_id": "904"
              },
              {
                "vill_id": "904033",
                "vill_name": "ປ່າຄານ້ອຍ",
                "vill_name_en": "",
                "dr_id": "904"
              },
              {
                "vill_id": "904034",
                "vill_name": "ນາເມືອງ",
                "vill_name_en": "",
                "dr_id": "904"
              },
              {
                "vill_id": "904035",
                "vill_name": "ສີວຽງຄຳ",
                "vill_name_en": "",
                "dr_id": "904"
              },
              {
                "vill_id": "904036",
                "vill_name": "ສີບຸນເຮືອງ",
                "vill_name_en": "",
                "dr_id": "904"
              },
              {
                "vill_id": "904037",
                "vill_name": "ອົ້ມ",
                "vill_name_en": "",
                "dr_id": "904"
              },
              {
                "vill_id": "904038",
                "vill_name": "ພູກາເຟ",
                "vill_name_en": "",
                "dr_id": "904"
              },
              {
                "vill_id": "904039",
                "vill_name": "ແກ່ວກວ້າງ",
                "vill_name_en": "",
                "dr_id": "904"
              },
              {
                "vill_id": "904040",
                "vill_name": "ສັນຫຼວງ",
                "vill_name_en": "",
                "dr_id": "904"
              },
              {
                "vill_id": "904041",
                "vill_name": "ໜອງ",
                "vill_name_en": "",
                "dr_id": "904"
              },
              {
                "vill_id": "904042",
                "vill_name": "ຕັນເໜືອ",
                "vill_name_en": "",
                "dr_id": "904"
              },
              {
                "vill_id": "904043",
                "vill_name": "ຕັນໃຕ້",
                "vill_name_en": "",
                "dr_id": "904"
              },
              {
                "vill_id": "904044",
                "vill_name": "ປຽງ",
                "vill_name_en": "",
                "dr_id": "904"
              },
              {
                "vill_id": "904045",
                "vill_name": "ພັກຮີ້ງ",
                "vill_name_en": "",
                "dr_id": "904"
              },
              {
                "vill_id": "904046",
                "vill_name": "ປຸງ",
                "vill_name_en": "",
                "dr_id": "904"
              },
              {
                "vill_id": "904047",
                "vill_name": "ຖ້ຳ",
                "vill_name_en": "",
                "dr_id": "904"
              },
              {
                "vill_id": "904048",
                "vill_name": "ໂພນໄຊ",
                "vill_name_en": "",
                "dr_id": "904"
              },
              {
                "vill_id": "904050",
                "vill_name": "ດົງດ່ານ",
                "vill_name_en": "",
                "dr_id": "904"
              },
              {
                "vill_id": "904051",
                "vill_name": "ຍຸນ",
                "vill_name_en": "",
                "dr_id": "904"
              },
              {
                "vill_id": "904052",
                "vill_name": "ໜອງຜື",
                "vill_name_en": "",
                "dr_id": "904"
              },
              {
                "vill_id": "904053",
                "vill_name": "ກົ້ວ",
                "vill_name_en": "",
                "dr_id": "904"
              },
              {
                "vill_id": "904054",
                "vill_name": "ໜອງນ້ຳ",
                "vill_name_en": "",
                "dr_id": "904"
              },
              {
                "vill_id": "904055",
                "vill_name": "ບົວເໜືອ",
                "vill_name_en": "",
                "dr_id": "904"
              },
              {
                "vill_id": "904056",
                "vill_name": "ບົວໃຕ້",
                "vill_name_en": "",
                "dr_id": "904"
              },
              {
                "vill_id": "904057",
                "vill_name": "ເຄັກ",
                "vill_name_en": "",
                "dr_id": "904"
              },
              {
                "vill_id": "904058",
                "vill_name": "ນາສົມ",
                "vill_name_en": "",
                "dr_id": "904"
              },
              {
                "vill_id": "904059",
                "vill_name": "ນາບົງ",
                "vill_name_en": "",
                "dr_id": "904"
              },
              {
                "vill_id": "904060",
                "vill_name": "ດອກໄມ້",
                "vill_name_en": "",
                "dr_id": "904"
              },
              {
                "vill_id": "904061",
                "vill_name": "ໜອງຫຼວງ",
                "vill_name_en": "",
                "dr_id": "904"
              },
              {
                "vill_id": "904062",
                "vill_name": "ນາຕໍ",
                "vill_name_en": "",
                "dr_id": "904"
              },
              {
                "vill_id": "904063",
                "vill_name": "ກໍສີ",
                "vill_name_en": "",
                "dr_id": "904"
              },
              {
                "vill_id": "904064",
                "vill_name": "ນ້ຳພານ",
                "vill_name_en": "",
                "dr_id": "904"
              },
              {
                "vill_id": "904065",
                "vill_name": "ເປີນ",
                "vill_name_en": "",
                "dr_id": "904"
              },
              {
                "vill_id": "904066",
                "vill_name": "ນາຮອນ",
                "vill_name_en": "",
                "dr_id": "904"
              },
              {
                "vill_id": "904067",
                "vill_name": "ເມືອງ",
                "vill_name_en": "",
                "dr_id": "904"
              },
              {
                "vill_id": "904068",
                "vill_name": "ພູວຽງ",
                "vill_name_en": "",
                "dr_id": "904"
              },
              {
                "vill_id": "904069",
                "vill_name": "ສີຊຽງໃໝ່",
                "vill_name_en": "",
                "dr_id": "904"
              },
              {
                "vill_id": "904070",
                "vill_name": "ນາດີ",
                "vill_name_en": "",
                "dr_id": "904"
              },
              {
                "vill_id": "904071",
                "vill_name": "ຢ້ຽມ",
                "vill_name_en": "",
                "dr_id": "904"
              },
              {
                "vill_id": "904072",
                "vill_name": "ຫີນໂງ່ນ",
                "vill_name_en": "",
                "dr_id": "904"
              },
              {
                "vill_id": "904073",
                "vill_name": "ຢ່ຽນ",
                "vill_name_en": "",
                "dr_id": "904"
              },
              {
                "vill_id": "904074",
                "vill_name": "ຊຳກວ້າງ",
                "vill_name_en": "",
                "dr_id": "904"
              },
              {
                "vill_id": "904075",
                "vill_name": "ຖ້ຳຫົວເຫຼົ່າ",
                "vill_name_en": "",
                "dr_id": "904"
              },
              {
                "vill_id": "904076",
                "vill_name": "ພາງ",
                "vill_name_en": "",
                "dr_id": "904"
              },
              {
                "vill_id": "904077",
                "vill_name": "ພັກຊົວະ",
                "vill_name_en": "",
                "dr_id": "904"
              },
              {
                "vill_id": "904078",
                "vill_name": "ນ້ຳລັນ",
                "vill_name_en": "",
                "dr_id": "904"
              },
              {
                "vill_id": "904079",
                "vill_name": "ພູກາເຟ",
                "vill_name_en": "",
                "dr_id": "904"
              },
              {
                "vill_id": "904080",
                "vill_name": "ນ່າຍ",
                "vill_name_en": "Nice",
                "dr_id": "904"
              },
              {
                "vill_id": "904081",
                "vill_name": "ກາງນາ",
                "vill_name_en": "kangna",
                "dr_id": "904"
              },
              {
                "vill_id": "904082",
                "vill_name": "ພູວຽງຊຽງໃໝ່",
                "vill_name_en": "Phouviengsiengmai",
                "dr_id": "904"
              },
              {
                "vill_id": "904083",
                "vill_name": "ຊຽງໃໝ່",
                "vill_name_en": "Xiengmai",
                "dr_id": "904"
              },
              {
                "vill_id": "904084",
                "vill_name": "ບ້ານທົ່ງ",
                "vill_name_en": "Thong",
                "dr_id": "904"
              },
              {
                "vill_id": "904085",
                "vill_name": "ຜາເຜິ້ງ",
                "vill_name_en": "Phavuang",
                "dr_id": "904"
              },
              {
                "vill_id": "904086",
                "vill_name": "ຫອຍ",
                "vill_name_en": "Hoy",
                "dr_id": "904"
              }
            ]
          },
          {
            "dr_id": "905",
            "dr_name": "ໝອກ",
            "dr_name_en": "Mork",
            "pr_id": "9",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "905001",
                "vill_name": "ປຸງ​ມາຍ",
                "vill_name_en": "",
                "dr_id": "905"
              },
              {
                "vill_id": "905002",
                "vill_name": "ຖ້ຳ​ໜອງ",
                "vill_name_en": "",
                "dr_id": "905"
              },
              {
                "vill_id": "905003",
                "vill_name": "ຊຳ​ທອງ ຖ້ຳ​ຕາດ",
                "vill_name_en": "",
                "dr_id": "905"
              },
              {
                "vill_id": "905004",
                "vill_name": "ສາມ​ແຈ",
                "vill_name_en": "",
                "dr_id": "905"
              },
              {
                "vill_id": "905005",
                "vill_name": "ປ່າ​ຄາ",
                "vill_name_en": "",
                "dr_id": "905"
              },
              {
                "vill_id": "905006",
                "vill_name": "ໝໍ້-ນ້ຳກ່ຽວ",
                "vill_name_en": "",
                "dr_id": "905"
              },
              {
                "vill_id": "905007",
                "vill_name": "ປ່າ​ເປັ້ງ-​ນ້ຳ​ຈາວ​ໃຕ້",
                "vill_name_en": "",
                "dr_id": "905"
              },
              {
                "vill_id": "905008",
                "vill_name": "ພູ​ລີນ-​ພູ​ມອນ",
                "vill_name_en": "",
                "dr_id": "905"
              },
              {
                "vill_id": "905009",
                "vill_name": "ທົ່ງ​ເປີ້",
                "vill_name_en": "",
                "dr_id": "905"
              },
              {
                "vill_id": "905010",
                "vill_name": "ນາ​ເມືອງ-ໃໝ່",
                "vill_name_en": "",
                "dr_id": "905"
              },
              {
                "vill_id": "905011",
                "vill_name": "ນ້ຳ​ເຢັນ-ນ້ຳໂຮງ",
                "vill_name_en": "",
                "dr_id": "905"
              },
              {
                "vill_id": "905012",
                "vill_name": "ຖ້ຳສ່ອຍ",
                "vill_name_en": "",
                "dr_id": "905"
              },
              {
                "vill_id": "905013",
                "vill_name": "ຜາ​ແດງ",
                "vill_name_en": "",
                "dr_id": "905"
              },
              {
                "vill_id": "905014",
                "vill_name": "ຄັງ​ວຽງ-ນາເທີ້",
                "vill_name_en": "",
                "dr_id": "905"
              },
              {
                "vill_id": "905015",
                "vill_name": "ນາ​ຄ່ອນ​ໃຕ້+ເໜືອ",
                "vill_name_en": "",
                "dr_id": "905"
              },
              {
                "vill_id": "905016",
                "vill_name": "ນາ​ມອນ",
                "vill_name_en": "",
                "dr_id": "905"
              },
              {
                "vill_id": "905017",
                "vill_name": "ຊຽງ​ເໝັ້ນ-ນາແຄ",
                "vill_name_en": "",
                "dr_id": "905"
              },
              {
                "vill_id": "905018",
                "vill_name": "ຊຽງ​ເໝັ້ນ-ນາແຄ",
                "vill_name_en": "",
                "dr_id": "905"
              },
              {
                "vill_id": "905019",
                "vill_name": "ຢວກ-​ມູນ",
                "vill_name_en": "",
                "dr_id": "905"
              },
              {
                "vill_id": "905020",
                "vill_name": "ນ້ຳ​ງາດ",
                "vill_name_en": "",
                "dr_id": "905"
              },
              {
                "vill_id": "905021",
                "vill_name": "ນ້ຳ​ຢ້ຽມ-ນ້ຳຊອງ",
                "vill_name_en": "",
                "dr_id": "905"
              },
              {
                "vill_id": "905022",
                "vill_name": "ນ້ຳຕຶນ-ນ້ຳ​ໂມງ",
                "vill_name_en": "",
                "dr_id": "905"
              },
              {
                "vill_id": "905023",
                "vill_name": "ນ້ຳ​ເກັ່ງ",
                "vill_name_en": "",
                "dr_id": "905"
              },
              {
                "vill_id": "905024",
                "vill_name": "ນ້ຳ​ລຽງ-​ນ້ຳ​ຈັດ",
                "vill_name_en": "",
                "dr_id": "905"
              },
              {
                "vill_id": "905025",
                "vill_name": "ນາແຄ",
                "vill_name_en": "Nakaa",
                "dr_id": "905"
              }
            ]
          },
          {
            "dr_id": "906",
            "dr_name": "ພູກູດ",
            "dr_name_en": "Phookood",
            "pr_id": "9",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "906001",
                "vill_name": "ລ້ອງຄາວ",
                "vill_name_en": "",
                "dr_id": "906"
              },
              {
                "vill_id": "906002",
                "vill_name": "ລ້ອງຄານ",
                "vill_name_en": "",
                "dr_id": "906"
              },
              {
                "vill_id": "906003",
                "vill_name": "ມ່ວງຄຳ",
                "vill_name_en": "",
                "dr_id": "906"
              },
              {
                "vill_id": "906004",
                "vill_name": "ຍອດແພ",
                "vill_name_en": "",
                "dr_id": "906"
              },
              {
                "vill_id": "906005",
                "vill_name": "ແລທອງ",
                "vill_name_en": "",
                "dr_id": "906"
              },
              {
                "vill_id": "906006",
                "vill_name": "ຢາຍ",
                "vill_name_en": "",
                "dr_id": "906"
              },
              {
                "vill_id": "906007",
                "vill_name": "ຢາຍ",
                "vill_name_en": "",
                "dr_id": "906"
              },
              {
                "vill_id": "906008",
                "vill_name": "ໂຈຍ",
                "vill_name_en": "",
                "dr_id": "906"
              },
              {
                "vill_id": "906009",
                "vill_name": "ມີໄຊ",
                "vill_name_en": "",
                "dr_id": "906"
              },
              {
                "vill_id": "906010",
                "vill_name": "ບົງ",
                "vill_name_en": "",
                "dr_id": "906"
              },
              {
                "vill_id": "906011",
                "vill_name": "ວັ່ງໂຕ່ງ",
                "vill_name_en": "",
                "dr_id": "906"
              },
              {
                "vill_id": "906012",
                "vill_name": "ລ້ອງຮາງ",
                "vill_name_en": "",
                "dr_id": "906"
              },
              {
                "vill_id": "906013",
                "vill_name": "ມຽນ",
                "vill_name_en": "",
                "dr_id": "906"
              },
              {
                "vill_id": "906014",
                "vill_name": "ປຸ່ງໝັ້ນ",
                "vill_name_en": "",
                "dr_id": "906"
              },
              {
                "vill_id": "906015",
                "vill_name": "ຮະງວມ",
                "vill_name_en": "",
                "dr_id": "906"
              },
              {
                "vill_id": "906016",
                "vill_name": "ນາຊາຍທອງ",
                "vill_name_en": "",
                "dr_id": "906"
              },
              {
                "vill_id": "906017",
                "vill_name": "ພູວຽງ",
                "vill_name_en": "",
                "dr_id": "906"
              },
              {
                "vill_id": "906018",
                "vill_name": "ຊາຍ",
                "vill_name_en": "",
                "dr_id": "906"
              },
              {
                "vill_id": "906019",
                "vill_name": "ອ່າງ",
                "vill_name_en": "",
                "dr_id": "906"
              },
              {
                "vill_id": "906020",
                "vill_name": "ຊອງ",
                "vill_name_en": "",
                "dr_id": "906"
              },
              {
                "vill_id": "906021",
                "vill_name": "ໜອງກາງ",
                "vill_name_en": "",
                "dr_id": "906"
              },
              {
                "vill_id": "906022",
                "vill_name": "ສັກສອດ",
                "vill_name_en": "",
                "dr_id": "906"
              },
              {
                "vill_id": "906023",
                "vill_name": "ນາຄວນ",
                "vill_name_en": "",
                "dr_id": "906"
              },
              {
                "vill_id": "906024",
                "vill_name": "ໜອງຕັ່ງ",
                "vill_name_en": "",
                "dr_id": "906"
              },
              {
                "vill_id": "906025",
                "vill_name": "ສຸຍ",
                "vill_name_en": "",
                "dr_id": "906"
              },
              {
                "vill_id": "906026",
                "vill_name": "ປັ້ງປາ",
                "vill_name_en": "",
                "dr_id": "906"
              },
              {
                "vill_id": "906027",
                "vill_name": "ນ້ຳຈັດ",
                "vill_name_en": "",
                "dr_id": "906"
              },
              {
                "vill_id": "906028",
                "vill_name": "ນ້ຳສາມ",
                "vill_name_en": "",
                "dr_id": "906"
              },
              {
                "vill_id": "906029",
                "vill_name": "ຊຽງແດດ",
                "vill_name_en": "",
                "dr_id": "906"
              },
              {
                "vill_id": "906030",
                "vill_name": "ແປນ",
                "vill_name_en": "",
                "dr_id": "906"
              },
              {
                "vill_id": "906031",
                "vill_name": "ເຄີງ",
                "vill_name_en": "",
                "dr_id": "906"
              },
              {
                "vill_id": "906032",
                "vill_name": "ຫວດຕັນ",
                "vill_name_en": "",
                "dr_id": "906"
              },
              {
                "vill_id": "906033",
                "vill_name": "ພຽງຫຼວງ",
                "vill_name_en": "",
                "dr_id": "906"
              },
              {
                "vill_id": "906034",
                "vill_name": "ລັ້ງຈອງ",
                "vill_name_en": "",
                "dr_id": "906"
              },
              {
                "vill_id": "906035",
                "vill_name": "ປົວ",
                "vill_name_en": "",
                "dr_id": "906"
              },
              {
                "vill_id": "906036",
                "vill_name": "ດຸກ",
                "vill_name_en": "",
                "dr_id": "906"
              },
              {
                "vill_id": "906037",
                "vill_name": "ມາງ",
                "vill_name_en": "",
                "dr_id": "906"
              },
              {
                "vill_id": "906038",
                "vill_name": "ຈອມສີ",
                "vill_name_en": "",
                "dr_id": "906"
              },
              {
                "vill_id": "906039",
                "vill_name": "ນາຂ່າ",
                "vill_name_en": "",
                "dr_id": "906"
              },
              {
                "vill_id": "906040",
                "vill_name": "ພູແຊວ",
                "vill_name_en": "",
                "dr_id": "906"
              },
              {
                "vill_id": "906041",
                "vill_name": "ຊຽງງາ",
                "vill_name_en": "",
                "dr_id": "906"
              },
              {
                "vill_id": "906042",
                "vill_name": "ສົມບຸນ",
                "vill_name_en": "",
                "dr_id": "906"
              },
              {
                "vill_id": "906043",
                "vill_name": "ໄຊອຸດົມ",
                "vill_name_en": "",
                "dr_id": "906"
              }
            ]
          },
          {
            "dr_id": "907",
            "dr_name": "ຜາໄຊ",
            "dr_name_en": "Phaxay",
            "pr_id": "9",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "907001",
                "vill_name": "ນາ​ເພຍນາແຖງ",
                "vill_name_en": "",
                "dr_id": "907"
              },
              {
                "vill_id": "907002",
                "vill_name": "ຫໍ​ໄຊ",
                "vill_name_en": "",
                "dr_id": "907"
              },
              {
                "vill_id": "907003",
                "vill_name": "ໂພນ",
                "vill_name_en": "",
                "dr_id": "907"
              },
              {
                "vill_id": "907005",
                "vill_name": "ງົ້ວ",
                "vill_name_en": "",
                "dr_id": "907"
              },
              {
                "vill_id": "907006",
                "vill_name": "ພາ​ໄຕ້",
                "vill_name_en": "",
                "dr_id": "907"
              },
              {
                "vill_id": "907007",
                "vill_name": "ນາ​ຄື່",
                "vill_name_en": "",
                "dr_id": "907"
              },
              {
                "vill_id": "907008",
                "vill_name": "ເຕົາ",
                "vill_name_en": "",
                "dr_id": "907"
              },
              {
                "vill_id": "907009",
                "vill_name": "ຫໍ​ສິມຖ້ຳຜຸກ",
                "vill_name_en": "",
                "dr_id": "907"
              },
              {
                "vill_id": "907010",
                "vill_name": "ນ້ຳ​ກະ",
                "vill_name_en": "",
                "dr_id": "907"
              },
              {
                "vill_id": "907011",
                "vill_name": "ພາ​ເໜືອ",
                "vill_name_en": "",
                "dr_id": "907"
              },
              {
                "vill_id": "907012",
                "vill_name": "ສ່ອມ",
                "vill_name_en": "",
                "dr_id": "907"
              },
              {
                "vill_id": "907013",
                "vill_name": "ຊົວ",
                "vill_name_en": "",
                "dr_id": "907"
              },
              {
                "vill_id": "907014",
                "vill_name": "ທາງ",
                "vill_name_en": "",
                "dr_id": "907"
              },
              {
                "vill_id": "907015",
                "vill_name": "ຫໍໍ​ກາງ",
                "vill_name_en": "",
                "dr_id": "907"
              },
              {
                "vill_id": "907016",
                "vill_name": "ນາ​ໂຄ້",
                "vill_name_en": "",
                "dr_id": "907"
              },
              {
                "vill_id": "907017",
                "vill_name": "ນາ​ສົມ",
                "vill_name_en": "",
                "dr_id": "907"
              },
              {
                "vill_id": "907018",
                "vill_name": "ໜອງ",
                "vill_name_en": "",
                "dr_id": "907"
              },
              {
                "vill_id": "907019",
                "vill_name": "ຊຽງ​ດີ",
                "vill_name_en": "",
                "dr_id": "907"
              },
              {
                "vill_id": "907020",
                "vill_name": "ທຸ້ມ",
                "vill_name_en": "",
                "dr_id": "907"
              },
              {
                "vill_id": "907021",
                "vill_name": "ນາຫວ້ານ",
                "vill_name_en": "",
                "dr_id": "907"
              },
              {
                "vill_id": "907022",
                "vill_name": "ໄຮ",
                "vill_name_en": "",
                "dr_id": "907"
              },
              {
                "vill_id": "907023",
                "vill_name": "ໂພ​ສີ",
                "vill_name_en": "",
                "dr_id": "907"
              },
              {
                "vill_id": "907024",
                "vill_name": "ມາຍ",
                "vill_name_en": "",
                "dr_id": "907"
              },
              {
                "vill_id": "907025",
                "vill_name": "ລາດ​ແສນ",
                "vill_name_en": "",
                "dr_id": "907"
              },
              {
                "vill_id": "907026",
                "vill_name": "ຈ່າ​ນ້ຳ",
                "vill_name_en": "",
                "dr_id": "907"
              },
              {
                "vill_id": "907027",
                "vill_name": "ປຸ່ງ",
                "vill_name_en": "",
                "dr_id": "907"
              },
              {
                "vill_id": "907028",
                "vill_name": "ນາ​ມຸນ",
                "vill_name_en": "",
                "dr_id": "907"
              },
              {
                "vill_id": "907029",
                "vill_name": "ນາ​ຮຸງ",
                "vill_name_en": "",
                "dr_id": "907"
              },
              {
                "vill_id": "907030",
                "vill_name": "ນາ​ໄຊ",
                "vill_name_en": "",
                "dr_id": "907"
              },
              {
                "vill_id": "907031",
                "vill_name": "ນ້ຳສຽມ",
                "vill_name_en": "",
                "dr_id": "907"
              },
              {
                "vill_id": "907032",
                "vill_name": "ຖ້ຳຕຳລຶງ",
                "vill_name_en": "",
                "dr_id": "907"
              },
              {
                "vill_id": "907033",
                "vill_name": "ນ້ຳເສ້ຍ",
                "vill_name_en": "",
                "dr_id": "907"
              },
              {
                "vill_id": "907034",
                "vill_name": "ນາປັນ",
                "vill_name_en": "",
                "dr_id": "907"
              },
              {
                "vill_id": "907035",
                "vill_name": "ລາດຄ້າຍ",
                "vill_name_en": "latkaiy",
                "dr_id": "907"
              }
            ]
          }
        ]
      },
      {
        "pr_id": "10",
        "pr_name": "ວຽງຈັນ",
        "pr_name_en": "Vientiane",
        "districts": [
          {"dr_name": "ເລືອກເມືອງປະຈຸບັນ", "villages": []},
          {
            "dr_id": "1001",
            "dr_name": "ໂພນໂຮງ",
            "dr_name_en": "Phonhong",
            "pr_id": "10",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "1001001",
                "vill_name": "ນາໂພເຫນືອ",
                "vill_name_en": "",
                "dr_id": "1001"
              },
              {
                "vill_id": "1001002",
                "vill_name": "ນາມ່ວງ",
                "vill_name_en": "",
                "dr_id": "1001"
              },
              {
                "vill_id": "1001003",
                "vill_name": "ໂພນຫໍ",
                "vill_name_en": "",
                "dr_id": "1001"
              },
              {
                "vill_id": "1001004",
                "vill_name": "ສ້າງໃຫຍ່",
                "vill_name_en": "",
                "dr_id": "1001"
              },
              {
                "vill_id": "1001005",
                "vill_name": "ນາກ່ຳ",
                "vill_name_en": "",
                "dr_id": "1001"
              },
              {
                "vill_id": "1001006",
                "vill_name": "ນາຊອມ",
                "vill_name_en": "",
                "dr_id": "1001"
              },
              {
                "vill_id": "1001007",
                "vill_name": "ໂພນໂຮງ",
                "vill_name_en": "",
                "dr_id": "1001"
              },
              {
                "vill_id": "1001008",
                "vill_name": "ໂພນງາມ",
                "vill_name_en": "",
                "dr_id": "1001"
              },
              {
                "vill_id": "1001009",
                "vill_name": "ໂພນສີໃຕ້",
                "vill_name_en": "",
                "dr_id": "1001"
              },
              {
                "vill_id": "1001010",
                "vill_name": "ນ້ຳລີນ",
                "vill_name_en": "",
                "dr_id": "1001"
              },
              {
                "vill_id": "1001011",
                "vill_name": "ນ້ຳແຈ້ງ",
                "vill_name_en": "",
                "dr_id": "1001"
              },
              {
                "vill_id": "1001012",
                "vill_name": "ຫ້ວຍທົນ",
                "vill_name_en": "",
                "dr_id": "1001"
              },
              {
                "vill_id": "1001013",
                "vill_name": "ຫ້ວຍປຸງ",
                "vill_name_en": "",
                "dr_id": "1001"
              },
              {
                "vill_id": "1001014",
                "vill_name": "ຫນອງບົວ",
                "vill_name_en": "",
                "dr_id": "1001"
              },
              {
                "vill_id": "1001015",
                "vill_name": "ນາຊູ",
                "vill_name_en": "",
                "dr_id": "1001"
              },
              {
                "vill_id": "1001016",
                "vill_name": "ໂພນຂາມ",
                "vill_name_en": "",
                "dr_id": "1001"
              },
              {
                "vill_id": "1001017",
                "vill_name": "ແສນຊຸມ",
                "vill_name_en": "",
                "dr_id": "1001"
              },
              {
                "vill_id": "1001018",
                "vill_name": "ທາດ",
                "vill_name_en": "",
                "dr_id": "1001"
              },
              {
                "vill_id": "1001019",
                "vill_name": "ໂພນແກ້ວ",
                "vill_name_en": "",
                "dr_id": "1001"
              },
              {
                "vill_id": "1001020",
                "vill_name": "ຂາມ",
                "vill_name_en": "",
                "dr_id": "1001"
              },
              {
                "vill_id": "1001021",
                "vill_name": "ໂພນສີເຫນືອ",
                "vill_name_en": "",
                "dr_id": "1001"
              },
              {
                "vill_id": "1001022",
                "vill_name": "ນາໂພພະລາດ",
                "vill_name_en": "",
                "dr_id": "1001"
              },
              {
                "vill_id": "1001023",
                "vill_name": "ນາທອງ",
                "vill_name_en": "",
                "dr_id": "1001"
              },
              {
                "vill_id": "1001024",
                "vill_name": "ໂພນ",
                "vill_name_en": "",
                "dr_id": "1001"
              },
              {
                "vill_id": "1001025",
                "vill_name": "ໂພນສະຫວາດ",
                "vill_name_en": "",
                "dr_id": "1001"
              },
              {
                "vill_id": "1001026",
                "vill_name": "ນາເຫລົ່າ",
                "vill_name_en": "",
                "dr_id": "1001"
              },
              {
                "vill_id": "1001027",
                "vill_name": "ໂພນທາ",
                "vill_name_en": "",
                "dr_id": "1001"
              },
              {
                "vill_id": "1001028",
                "vill_name": "ນາຍາງ",
                "vill_name_en": "",
                "dr_id": "1001"
              },
              {
                "vill_id": "1001029",
                "vill_name": "ສະກ້າ",
                "vill_name_en": "",
                "dr_id": "1001"
              },
              {
                "vill_id": "1001030",
                "vill_name": "ວັງມົນ",
                "vill_name_en": "",
                "dr_id": "1001"
              },
              {
                "vill_id": "1001031",
                "vill_name": "ນາບອນ",
                "vill_name_en": "",
                "dr_id": "1001"
              },
              {
                "vill_id": "1001032",
                "vill_name": "ຫນອງຂອນ",
                "vill_name_en": "",
                "dr_id": "1001"
              },
              {
                "vill_id": "1001033",
                "vill_name": "ໂພນທັນ",
                "vill_name_en": "",
                "dr_id": "1001"
              },
              {
                "vill_id": "1001034",
                "vill_name": "ໂຮງເລື່ອຍ",
                "vill_name_en": "",
                "dr_id": "1001"
              },
              {
                "vill_id": "1001035",
                "vill_name": "ຫ້ວຍເດື່ອ",
                "vill_name_en": "",
                "dr_id": "1001"
              },
              {
                "vill_id": "1001036",
                "vill_name": "ນາໂພໃຕ້",
                "vill_name_en": "",
                "dr_id": "1001"
              },
              {
                "vill_id": "1001037",
                "vill_name": "ບ້ານນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1001"
              },
              {
                "vill_id": "1001038",
                "vill_name": "ໂພນຕ້ອງ",
                "vill_name_en": "",
                "dr_id": "1001"
              },
              {
                "vill_id": "1001039",
                "vill_name": "ໂພນເງິນ",
                "vill_name_en": "",
                "dr_id": "1001"
              },
              {
                "vill_id": "1001040",
                "vill_name": "ໂພນສະຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "1001"
              },
              {
                "vill_id": "1001041",
                "vill_name": "ໂພນໄຊໃຕ້",
                "vill_name_en": "",
                "dr_id": "1001"
              },
              {
                "vill_id": "1001042",
                "vill_name": "ດົງຂາວ",
                "vill_name_en": "",
                "dr_id": "1001"
              },
              {
                "vill_id": "1001043",
                "vill_name": "ສີວິໄລ",
                "vill_name_en": "",
                "dr_id": "1001"
              },
              {
                "vill_id": "1001044",
                "vill_name": "ໃຫມ່",
                "vill_name_en": "",
                "dr_id": "1001"
              },
              {
                "vill_id": "1001045",
                "vill_name": "ນາເທບ",
                "vill_name_en": "",
                "dr_id": "1001"
              },
              {
                "vill_id": "1001046",
                "vill_name": "ແຈ້ງສະຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "1001"
              },
              {
                "vill_id": "1001047",
                "vill_name": "ຫນອງປຸງ",
                "vill_name_en": "",
                "dr_id": "1001"
              },
              {
                "vill_id": "1001048",
                "vill_name": "ໂພນຄ້ອງ",
                "vill_name_en": "",
                "dr_id": "1001"
              },
              {
                "vill_id": "1001049",
                "vill_name": "ຫລັກ 52",
                "vill_name_en": "",
                "dr_id": "1001"
              },
              {
                "vill_id": "1001050",
                "vill_name": "ແອກຊ້າງ",
                "vill_name_en": "",
                "dr_id": "1001"
              },
              {
                "vill_id": "1001051",
                "vill_name": "ນາເລົາ",
                "vill_name_en": "",
                "dr_id": "1001"
              },
              {
                "vill_id": "1001052",
                "vill_name": "ຫນອງນາກ",
                "vill_name_en": "",
                "dr_id": "1001"
              },
              {
                "vill_id": "1001053",
                "vill_name": "ໂພນງາມໃຕ້",
                "vill_name_en": "",
                "dr_id": "1001"
              },
              {
                "vill_id": "1001054",
                "vill_name": "ໂພນຄຳເຫນືອ",
                "vill_name_en": "",
                "dr_id": "1001"
              },
              {
                "vill_id": "1001055",
                "vill_name": "ໂພນຄຳໃຕ້",
                "vill_name_en": "",
                "dr_id": "1001"
              },
              {
                "vill_id": "1001056",
                "vill_name": "ພູສັນ",
                "vill_name_en": "",
                "dr_id": "1001"
              },
              {
                "vill_id": "1001057",
                "vill_name": "ເຕົາຖ່ານ",
                "vill_name_en": "",
                "dr_id": "1001"
              },
              {
                "vill_id": "1001058",
                "vill_name": "ໂພນສົມບູນ",
                "vill_name_en": "",
                "dr_id": "1001"
              },
              {
                "vill_id": "1001059",
                "vill_name": "ໂພນໄຊ",
                "vill_name_en": "Phonxay",
                "dr_id": "1001"
              },
              {
                "vill_id": "1001060",
                "vill_name": "ວຽງຄຳ",
                "vill_name_en": "Viengkham",
                "dr_id": "1001"
              }
            ]
          },
          {
            "dr_id": "1002",
            "dr_name": "ທຸລະຄົມ",
            "dr_name_en": "Thoulakhom",
            "pr_id": "10",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "1002001",
                "vill_name": "ໂພນສົມບູນ",
                "vill_name_en": "",
                "dr_id": "1002"
              },
              {
                "vill_id": "1002002",
                "vill_name": "ເກີນກາງ",
                "vill_name_en": "",
                "dr_id": "1002"
              },
              {
                "vill_id": "1002003",
                "vill_name": "ເກີນໃຕ້",
                "vill_name_en": "",
                "dr_id": "1002"
              },
              {
                "vill_id": "1002004",
                "vill_name": "ນາໝາກ",
                "vill_name_en": "",
                "dr_id": "1002"
              },
              {
                "vill_id": "1002005",
                "vill_name": "ນາລ້າ",
                "vill_name_en": "",
                "dr_id": "1002"
              },
              {
                "vill_id": "1002006",
                "vill_name": "ບໍ່",
                "vill_name_en": "",
                "dr_id": "1002"
              },
              {
                "vill_id": "1002007",
                "vill_name": "ນາບໍ່",
                "vill_name_en": "",
                "dr_id": "1002"
              },
              {
                "vill_id": "1002008",
                "vill_name": "ນາເພີຍ",
                "vill_name_en": "",
                "dr_id": "1002"
              },
              {
                "vill_id": "1002009",
                "vill_name": "ພູເກົ້ານາງ",
                "vill_name_en": "",
                "dr_id": "1002"
              },
              {
                "vill_id": "1002010",
                "vill_name": "ນ້ຳອ່າງ",
                "vill_name_en": "",
                "dr_id": "1002"
              },
              {
                "vill_id": "1002011",
                "vill_name": "ບຸ່ງພ້າວ",
                "vill_name_en": "",
                "dr_id": "1002"
              },
              {
                "vill_id": "1002012",
                "vill_name": "ຫາດໄຊ",
                "vill_name_en": "",
                "dr_id": "1002"
              },
              {
                "vill_id": "1002013",
                "vill_name": "ນາຄອງ",
                "vill_name_en": "",
                "dr_id": "1002"
              },
              {
                "vill_id": "1002014",
                "vill_name": "ໂພນຄຳ",
                "vill_name_en": "",
                "dr_id": "1002"
              },
              {
                "vill_id": "1002015",
                "vill_name": "ໂພນງາມນາຝ້າຍ",
                "vill_name_en": "",
                "dr_id": "1002"
              },
              {
                "vill_id": "1002016",
                "vill_name": "ໂພນໂຮງນາຝ້າຍ",
                "vill_name_en": "",
                "dr_id": "1002"
              },
              {
                "vill_id": "1002017",
                "vill_name": "ປາກຫາງ",
                "vill_name_en": "",
                "dr_id": "1002"
              },
              {
                "vill_id": "1002019",
                "vill_name": "ນາແກ່ວ",
                "vill_name_en": "",
                "dr_id": "1002"
              },
              {
                "vill_id": "1002020",
                "vill_name": "ນາຫວ້າ",
                "vill_name_en": "",
                "dr_id": "1002"
              },
              {
                "vill_id": "1002021",
                "vill_name": "ນ້ຳຫຍາມ",
                "vill_name_en": "",
                "dr_id": "1002"
              },
              {
                "vill_id": "1002022",
                "vill_name": "ພູເຂົາຄວາຍ",
                "vill_name_en": "",
                "dr_id": "1002"
              },
              {
                "vill_id": "1002023",
                "vill_name": "ວັງເຮືອ",
                "vill_name_en": "",
                "dr_id": "1002"
              },
              {
                "vill_id": "1002024",
                "vill_name": "ນາແພງ",
                "vill_name_en": "",
                "dr_id": "1002"
              },
              {
                "vill_id": "1002025",
                "vill_name": "ໂພນແກ້ວ",
                "vill_name_en": "",
                "dr_id": "1002"
              },
              {
                "vill_id": "1002026",
                "vill_name": "ໄຮ່ຢ່ອຍໃຫມ່",
                "vill_name_en": "",
                "dr_id": "1002"
              },
              {
                "vill_id": "1002027",
                "vill_name": "ດົງ",
                "vill_name_en": "",
                "dr_id": "1002"
              },
              {
                "vill_id": "1002028",
                "vill_name": "ນານົກຂຸ້ມ",
                "vill_name_en": "",
                "dr_id": "1002"
              },
              {
                "vill_id": "1002029",
                "vill_name": "ນາຍາງ",
                "vill_name_en": "",
                "dr_id": "1002"
              },
              {
                "vill_id": "1002030",
                "vill_name": "ນາເງິນ",
                "vill_name_en": "",
                "dr_id": "1002"
              },
              {
                "vill_id": "1002031",
                "vill_name": "ຫນອງພົງ",
                "vill_name_en": "",
                "dr_id": "1002"
              },
              {
                "vill_id": "1002032",
                "vill_name": "ສຸກສາລາ",
                "vill_name_en": "",
                "dr_id": "1002"
              },
              {
                "vill_id": "1002033",
                "vill_name": "ຕານປ່ຽວ",
                "vill_name_en": "",
                "dr_id": "1002"
              },
              {
                "vill_id": "1002034",
                "vill_name": "ຫາດສວນ",
                "vill_name_en": "",
                "dr_id": "1002"
              },
              {
                "vill_id": "1002035",
                "vill_name": "ຈູ້ມ",
                "vill_name_en": "",
                "dr_id": "1002"
              },
              {
                "vill_id": "1002036",
                "vill_name": "ແຈ້ງ",
                "vill_name_en": "",
                "dr_id": "1002"
              },
              {
                "vill_id": "1002037",
                "vill_name": "ຖິ່ນຄຳ",
                "vill_name_en": "",
                "dr_id": "1002"
              },
              {
                "vill_id": "1002038",
                "vill_name": "ລິ່ງຊັນ",
                "vill_name_en": "",
                "dr_id": "1002"
              },
              {
                "vill_id": "1002039",
                "vill_name": "ໂພນແພງ",
                "vill_name_en": "",
                "dr_id": "1002"
              },
              {
                "vill_id": "1002040",
                "vill_name": "ຫາດສ້ຽວ",
                "vill_name_en": "",
                "dr_id": "1002"
              },
              {
                "vill_id": "1002042",
                "vill_name": "ໜອງໃຫຄຳ",
                "vill_name_en": "",
                "dr_id": "1002"
              },
              {
                "vill_id": "1002043",
                "vill_name": "ສີວິໄລ",
                "vill_name_en": "sivilay",
                "dr_id": "1002"
              },
              {
                "vill_id": "1002044",
                "vill_name": "ບ້ານພະທາວ",
                "vill_name_en": "Phathao",
                "dr_id": "1002"
              },
              {
                "vill_id": "1002045",
                "vill_name": "ເກິນເໝືອ",
                "vill_name_en": "kuenneau",
                "dr_id": "1002"
              },
              {
                "vill_id": "1002046",
                "vill_name": "ບ້ານໂພນແຮ່",
                "vill_name_en": "Phonhea",
                "dr_id": "1002"
              },
              {
                "vill_id": "1002047",
                "vill_name": "ບ້ານພູເຂົາຄຳ",
                "vill_name_en": "Phukhaokham",
                "dr_id": "1002"
              },
              {
                "vill_id": "1002048",
                "vill_name": "ໂພນໄຮ",
                "vill_name_en": "phonehaiy",
                "dr_id": "1002"
              }
            ]
          },
          {
            "dr_id": "1003",
            "dr_name": "ແກ້ວອຸດົມ",
            "dr_name_en": "Keooudom",
            "pr_id": "10",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "1003001",
                "vill_name": "ນາປູນ",
                "vill_name_en": "",
                "dr_id": "1003"
              },
              {
                "vill_id": "1003002",
                "vill_name": "ທ່າຊັນ",
                "vill_name_en": "",
                "dr_id": "1003"
              },
              {
                "vill_id": "1003003",
                "vill_name": "ທ່າລາດ",
                "vill_name_en": "",
                "dr_id": "1003"
              },
              {
                "vill_id": "1003004",
                "vill_name": "ຖິ່ນແກ້ວ",
                "vill_name_en": "",
                "dr_id": "1003"
              },
              {
                "vill_id": "1003005",
                "vill_name": "ພູເຂົາຄຳ",
                "vill_name_en": "",
                "dr_id": "1003"
              },
              {
                "vill_id": "1003006",
                "vill_name": "ໂພນມ່ວງ",
                "vill_name_en": "",
                "dr_id": "1003"
              },
              {
                "vill_id": "1003007",
                "vill_name": "ລ້ອງແຄນ",
                "vill_name_en": "",
                "dr_id": "1003"
              },
              {
                "vill_id": "1003008",
                "vill_name": "ໂພນແຍງ",
                "vill_name_en": "",
                "dr_id": "1003"
              },
              {
                "vill_id": "1003009",
                "vill_name": "ດານສະຫວັນ",
                "vill_name_en": "",
                "dr_id": "1003"
              },
              {
                "vill_id": "1003010",
                "vill_name": "ຫ້ວຍໂຄ້ງ",
                "vill_name_en": "",
                "dr_id": "1003"
              },
              {
                "vill_id": "1003011",
                "vill_name": "ແກ້ວກູ່",
                "vill_name_en": "",
                "dr_id": "1003"
              },
              {
                "vill_id": "1003012",
                "vill_name": "ໂພນຄຳ",
                "vill_name_en": "",
                "dr_id": "1003"
              },
              {
                "vill_id": "1003013",
                "vill_name": "ຂ້າມຊ້າງ",
                "vill_name_en": "",
                "dr_id": "1003"
              },
              {
                "vill_id": "1003014",
                "vill_name": "ໂພນສະຫວັນ",
                "vill_name_en": "",
                "dr_id": "1003"
              },
              {
                "vill_id": "1003015",
                "vill_name": "ຫາດເດື່ອ",
                "vill_name_en": "",
                "dr_id": "1003"
              },
              {
                "vill_id": "1003016",
                "vill_name": "ກົກກ້ຽງ",
                "vill_name_en": "",
                "dr_id": "1003"
              },
              {
                "vill_id": "1003017",
                "vill_name": "ວຽງແກ້ວ",
                "vill_name_en": "",
                "dr_id": "1003"
              },
              {
                "vill_id": "1003018",
                "vill_name": "ວຽງຄຳ",
                "vill_name_en": "",
                "dr_id": "1003"
              },
              {
                "vill_id": "1003019",
                "vill_name": "ຫາດທາ",
                "vill_name_en": "",
                "dr_id": "1003"
              },
              {
                "vill_id": "1003020",
                "vill_name": "ນາແຊະ",
                "vill_name_en": "",
                "dr_id": "1003"
              },
              {
                "vill_id": "1003021",
                "vill_name": "ດອນໄຊອຸດົມ",
                "vill_name_en": "",
                "dr_id": "1003"
              },
              {
                "vill_id": "1003022",
                "vill_name": "ຫ້ວຍປຸ່ງ",
                "vill_name_en": "",
                "dr_id": "1003"
              },
              {
                "vill_id": "1003023",
                "vill_name": "ໃຫມ່",
                "vill_name_en": "",
                "dr_id": "1003"
              },
              {
                "vill_id": "1003024",
                "vill_name": "ແສງສະຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "1003"
              },
              {
                "vill_id": "1003025",
                "vill_name": "ສາມະຄີໄຊ",
                "vill_name_en": "",
                "dr_id": "1003"
              }
            ]
          },
          {
            "dr_id": "1004",
            "dr_name": "ກາສີ",
            "dr_name_en": "Kasy",
            "pr_id": "10",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "1004001",
                "vill_name": "ຫົວເມືອງ",
                "vill_name_en": "",
                "dr_id": "1004"
              },
              {
                "vill_id": "1004002",
                "vill_name": "ໂພນສີດາ",
                "vill_name_en": "",
                "dr_id": "1004"
              },
              {
                "vill_id": "1004003",
                "vill_name": "ແກ່ວຊົມພູ",
                "vill_name_en": "",
                "dr_id": "1004"
              },
              {
                "vill_id": "1004004",
                "vill_name": "ໂພນງາມ",
                "vill_name_en": "",
                "dr_id": "1004"
              },
              {
                "vill_id": "1004005",
                "vill_name": "ນາຊູ",
                "vill_name_en": "",
                "dr_id": "1004"
              },
              {
                "vill_id": "1004006",
                "vill_name": "ພູຄຳ",
                "vill_name_en": "",
                "dr_id": "1004"
              },
              {
                "vill_id": "1004007",
                "vill_name": "ວຽງແກ້ວ",
                "vill_name_en": "",
                "dr_id": "1004"
              },
              {
                "vill_id": "1004008",
                "vill_name": "ນ້ຳພົດ",
                "vill_name_en": "",
                "dr_id": "1004"
              },
              {
                "vill_id": "1004009",
                "vill_name": "ນາແທ່ນ",
                "vill_name_en": "",
                "dr_id": "1004"
              },
              {
                "vill_id": "1004010",
                "vill_name": "ໂພນທັນ",
                "vill_name_en": "",
                "dr_id": "1004"
              },
              {
                "vill_id": "1004011",
                "vill_name": "ນາມົນໃຫມ່",
                "vill_name_en": "",
                "dr_id": "1004"
              },
              {
                "vill_id": "1004012",
                "vill_name": "ນາມົນໃຫຍ່",
                "vill_name_en": "",
                "dr_id": "1004"
              },
              {
                "vill_id": "1004013",
                "vill_name": "ວຽງສະໄຫມ",
                "vill_name_en": "",
                "dr_id": "1004"
              },
              {
                "vill_id": "1004014",
                "vill_name": "ຫນອງບົວທອງ",
                "vill_name_en": "",
                "dr_id": "1004"
              },
              {
                "vill_id": "1004015",
                "vill_name": "ໂພນສະຫວາດ",
                "vill_name_en": "",
                "dr_id": "1004"
              },
              {
                "vill_id": "1004016",
                "vill_name": "ຖ້ຳໃຕ້",
                "vill_name_en": "",
                "dr_id": "1004"
              },
              {
                "vill_id": "1004017",
                "vill_name": "ທົ່ງເຫມືອດ",
                "vill_name_en": "",
                "dr_id": "1004"
              },
              {
                "vill_id": "1004018",
                "vill_name": "ນາຊາຍຂາວ",
                "vill_name_en": "",
                "dr_id": "1004"
              },
              {
                "vill_id": "1004019",
                "vill_name": "ນາໂພ",
                "vill_name_en": "",
                "dr_id": "1004"
              },
              {
                "vill_id": "1004020",
                "vill_name": "ໂພນທ່ຽງ",
                "vill_name_en": "",
                "dr_id": "1004"
              },
              {
                "vill_id": "1004021",
                "vill_name": "ນາມອນ",
                "vill_name_en": "",
                "dr_id": "1004"
              },
              {
                "vill_id": "1004022",
                "vill_name": "ສີສັງວອນ",
                "vill_name_en": "",
                "dr_id": "1004"
              },
              {
                "vill_id": "1004023",
                "vill_name": "ໂພນຫມີ",
                "vill_name_en": "",
                "dr_id": "1004"
              },
              {
                "vill_id": "1004024",
                "vill_name": "ໂພຄຳ",
                "vill_name_en": "",
                "dr_id": "1004"
              },
              {
                "vill_id": "1004025",
                "vill_name": "ວຽງຄຳ",
                "vill_name_en": "",
                "dr_id": "1004"
              },
              {
                "vill_id": "1004026",
                "vill_name": "ຈຽງ",
                "vill_name_en": "",
                "dr_id": "1004"
              },
              {
                "vill_id": "1004027",
                "vill_name": "ວຽງທອງ",
                "vill_name_en": "",
                "dr_id": "1004"
              },
              {
                "vill_id": "1004028",
                "vill_name": "ໂພນແບ່ງ",
                "vill_name_en": "",
                "dr_id": "1004"
              },
              {
                "vill_id": "1004029",
                "vill_name": "ຜາຊ້າງ",
                "vill_name_en": "",
                "dr_id": "1004"
              },
              {
                "vill_id": "1004030",
                "vill_name": "ບວມຜູກ",
                "vill_name_en": "",
                "dr_id": "1004"
              },
              {
                "vill_id": "1004031",
                "vill_name": "ຫນອງຄຳ",
                "vill_name_en": "",
                "dr_id": "1004"
              },
              {
                "vill_id": "1004032",
                "vill_name": "ຫ້ວຍຫົກ",
                "vill_name_en": "",
                "dr_id": "1004"
              },
              {
                "vill_id": "1004033",
                "vill_name": "ແດນດິນ",
                "vill_name_en": "",
                "dr_id": "1004"
              },
              {
                "vill_id": "1004034",
                "vill_name": "ແດນດິນ",
                "vill_name_en": "",
                "dr_id": "1004"
              },
              {
                "vill_id": "1004035",
                "vill_name": "ນາລັນ",
                "vill_name_en": "",
                "dr_id": "1004"
              },
              {
                "vill_id": "1004036",
                "vill_name": "ປຸ່ງຫລັກ",
                "vill_name_en": "",
                "dr_id": "1004"
              },
              {
                "vill_id": "1004037",
                "vill_name": "ຜາຕໍ່",
                "vill_name_en": "",
                "dr_id": "1004"
              },
              {
                "vill_id": "1004038",
                "vill_name": "ຫ້ວຍພັນຫລາ",
                "vill_name_en": "",
                "dr_id": "1004"
              },
              {
                "vill_id": "1004039",
                "vill_name": "ຫ້ວຍຕັ່ງນາຍ",
                "vill_name_en": "",
                "dr_id": "1004"
              },
              {
                "vill_id": "1004040",
                "vill_name": "ພູຫີນເຫລັກໄຟ",
                "vill_name_en": "",
                "dr_id": "1004"
              },
              {
                "vill_id": "1004041",
                "vill_name": "ຫີນໂງ່ນ",
                "vill_name_en": "",
                "dr_id": "1004"
              },
              {
                "vill_id": "1004042",
                "vill_name": "ນ້ຳແປກ",
                "vill_name_en": "",
                "dr_id": "1004"
              },
              {
                "vill_id": "1004043",
                "vill_name": "ກິວກວ້າງ",
                "vill_name_en": "",
                "dr_id": "1004"
              },
              {
                "vill_id": "1004044",
                "vill_name": "ນ້ຳແກ່ນ",
                "vill_name_en": "",
                "dr_id": "1004"
              },
              {
                "vill_id": "1004045",
                "vill_name": "ຜາເຈົ້າ",
                "vill_name_en": "",
                "dr_id": "1004"
              },
              {
                "vill_id": "1004046",
                "vill_name": "ໂພນຄຳ",
                "vill_name_en": "",
                "dr_id": "1004"
              },
              {
                "vill_id": "1004047",
                "vill_name": "ຫ້ວຍສ້ານ",
                "vill_name_en": "",
                "dr_id": "1004"
              },
              {
                "vill_id": "1004048",
                "vill_name": "ນາສັງທອງ",
                "vill_name_en": "",
                "dr_id": "1004"
              },
              {
                "vill_id": "1004049",
                "vill_name": "ລ້ອງຫມາກຄາຍ",
                "vill_name_en": "",
                "dr_id": "1004"
              },
              {
                "vill_id": "1004050",
                "vill_name": "ຜາຫລັກ",
                "vill_name_en": "",
                "dr_id": "1004"
              },
              {
                "vill_id": "1004051",
                "vill_name": "ໜອງສານ",
                "vill_name_en": "Nongsan",
                "dr_id": "1004"
              }
            ]
          },
          {
            "dr_id": "1005",
            "dr_name": "ວັງວຽງ",
            "dr_name_en": "Vangvieng",
            "pr_id": "10",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "1005001",
                "vill_name": "ສົມສະຫວາດ",
                "vill_name_en": "",
                "dr_id": "1005"
              },
              {
                "vill_id": "1005002",
                "vill_name": "ຜາຮອມ",
                "vill_name_en": "",
                "dr_id": "1005"
              },
              {
                "vill_id": "1005003",
                "vill_name": "ຜາແດງ",
                "vill_name_en": "",
                "dr_id": "1005"
              },
              {
                "vill_id": "1005004",
                "vill_name": "ຫ້ວຍນ້ຳເຢັນ",
                "vill_name_en": "",
                "dr_id": "1005"
              },
              {
                "vill_id": "1005005",
                "vill_name": "ແກ່ວກວ້າງ",
                "vill_name_en": "",
                "dr_id": "1005"
              },
              {
                "vill_id": "1005006",
                "vill_name": "ຜາຕັ້ງ",
                "vill_name_en": "",
                "dr_id": "1005"
              },
              {
                "vill_id": "1005007",
                "vill_name": "ນາດາວ",
                "vill_name_en": "",
                "dr_id": "1005"
              },
              {
                "vill_id": "1005008",
                "vill_name": "ຜາເທົາ",
                "vill_name_en": "",
                "dr_id": "1005"
              },
              {
                "vill_id": "1005009",
                "vill_name": "ວຽງສະໄຫມ",
                "vill_name_en": "",
                "dr_id": "1005"
              },
              {
                "vill_id": "1005010",
                "vill_name": "ປາກເປາະ",
                "vill_name_en": "",
                "dr_id": "1005"
              },
              {
                "vill_id": "1005011",
                "vill_name": "ນາແຄ",
                "vill_name_en": "",
                "dr_id": "1005"
              },
              {
                "vill_id": "1005012",
                "vill_name": "ພູດິນແດງ",
                "vill_name_en": "",
                "dr_id": "1005"
              },
              {
                "vill_id": "1005013",
                "vill_name": "ຫ້ວຍສະເຫງົ້າ",
                "vill_name_en": "",
                "dr_id": "1005"
              },
              {
                "vill_id": "1005014",
                "vill_name": "ວັງວຽງ",
                "vill_name_en": "",
                "dr_id": "1005"
              },
              {
                "vill_id": "1005015",
                "vill_name": "ໂພນເພັງ",
                "vill_name_en": "",
                "dr_id": "1005"
              },
              {
                "vill_id": "1005016",
                "vill_name": "ນາດ້ວງ",
                "vill_name_en": "",
                "dr_id": "1005"
              },
              {
                "vill_id": "1005017",
                "vill_name": "ວຽງແກ້ວ",
                "vill_name_en": "",
                "dr_id": "1005"
              },
              {
                "vill_id": "1005018",
                "vill_name": "ເມືອງຊອງ",
                "vill_name_en": "",
                "dr_id": "1005"
              },
              {
                "vill_id": "1005019",
                "vill_name": "ນາຄູນ",
                "vill_name_en": "",
                "dr_id": "1005"
              },
              {
                "vill_id": "1005020",
                "vill_name": "ວຽງໄຊນາຫລວງ",
                "vill_name_en": "",
                "dr_id": "1005"
              },
              {
                "vill_id": "1005021",
                "vill_name": "ຫ້ວຍງາມ",
                "vill_name_en": "",
                "dr_id": "1005"
              },
              {
                "vill_id": "1005022",
                "vill_name": "ໂພນຊູ",
                "vill_name_en": "",
                "dr_id": "1005"
              },
              {
                "vill_id": "1005023",
                "vill_name": "ໂພນສູງ",
                "vill_name_en": "",
                "dr_id": "1005"
              },
              {
                "vill_id": "1005024",
                "vill_name": "ຂັນຫມາກ",
                "vill_name_en": "",
                "dr_id": "1005"
              },
              {
                "vill_id": "1005025",
                "vill_name": "ບ້ານວັງຊອງ",
                "vill_name_en": "",
                "dr_id": "1005"
              },
              {
                "vill_id": "1005026",
                "vill_name": "ນ້ຳແປ",
                "vill_name_en": "",
                "dr_id": "1005"
              },
              {
                "vill_id": "1005027",
                "vill_name": "ນາໄຊ",
                "vill_name_en": "",
                "dr_id": "1005"
              },
              {
                "vill_id": "1005028",
                "vill_name": "ໂພນໄຊໃຕ້",
                "vill_name_en": "",
                "dr_id": "1005"
              },
              {
                "vill_id": "1005029",
                "vill_name": "ນາຊົມ",
                "vill_name_en": "",
                "dr_id": "1005"
              },
              {
                "vill_id": "1005030",
                "vill_name": "ນາທອງ",
                "vill_name_en": "",
                "dr_id": "1005"
              },
              {
                "vill_id": "1005031",
                "vill_name": "ນາມ່ວງ",
                "vill_name_en": "",
                "dr_id": "1005"
              },
              {
                "vill_id": "1005032",
                "vill_name": "ວັງຫມ້ຽງ",
                "vill_name_en": "",
                "dr_id": "1005"
              },
              {
                "vill_id": "1005033",
                "vill_name": "ນາມອນໃຕ້",
                "vill_name_en": "",
                "dr_id": "1005"
              },
              {
                "vill_id": "1005034",
                "vill_name": "ນາມອນເຫນືອ",
                "vill_name_en": "",
                "dr_id": "1005"
              },
              {
                "vill_id": "1005035",
                "vill_name": "ໂພນສະຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "1005"
              },
              {
                "vill_id": "1005036",
                "vill_name": "ໂພນແກ້ວ",
                "vill_name_en": "",
                "dr_id": "1005"
              },
              {
                "vill_id": "1005037",
                "vill_name": "ບ້ານງີ້ວໃຕ້",
                "vill_name_en": "",
                "dr_id": "1005"
              },
              {
                "vill_id": "1005039",
                "vill_name": "ນາຂົມ",
                "vill_name_en": "",
                "dr_id": "1005"
              },
              {
                "vill_id": "1005040",
                "vill_name": "ໂພນຍາງ",
                "vill_name_en": "",
                "dr_id": "1005"
              },
              {
                "vill_id": "1005041",
                "vill_name": "ວັງເຮືອ",
                "vill_name_en": "",
                "dr_id": "1005"
              },
              {
                "vill_id": "1005042",
                "vill_name": "ທ່າເຮືອເຫນືອ",
                "vill_name_en": "",
                "dr_id": "1005"
              },
              {
                "vill_id": "1005043",
                "vill_name": "ທ່າເຮືອໃຕ້",
                "vill_name_en": "",
                "dr_id": "1005"
              },
              {
                "vill_id": "1005044",
                "vill_name": "ຫ້ວຍປາມ້ອມ",
                "vill_name_en": "",
                "dr_id": "1005"
              },
              {
                "vill_id": "1005045",
                "vill_name": "ນ້ຳປາດໃຕ້",
                "vill_name_en": "",
                "dr_id": "1005"
              },
              {
                "vill_id": "1005046",
                "vill_name": "ຫ້ວຍຊີ",
                "vill_name_en": "",
                "dr_id": "1005"
              },
              {
                "vill_id": "1005047",
                "vill_name": "ນ້ຳພ້າວ",
                "vill_name_en": "",
                "dr_id": "1005"
              },
              {
                "vill_id": "1005048",
                "vill_name": "ຫ້ວຍແຍ້",
                "vill_name_en": "",
                "dr_id": "1005"
              },
              {
                "vill_id": "1005049",
                "vill_name": "ນ້ຳງາດ",
                "vill_name_en": "",
                "dr_id": "1005"
              },
              {
                "vill_id": "1005050",
                "vill_name": "ຖິ່ນອ່ອນ",
                "vill_name_en": "",
                "dr_id": "1005"
              },
              {
                "vill_id": "1005051",
                "vill_name": "ນາແຍ້",
                "vill_name_en": "",
                "dr_id": "1005"
              },
              {
                "vill_id": "1005052",
                "vill_name": "ສວ່າງ",
                "vill_name_en": "",
                "dr_id": "1005"
              },
              {
                "vill_id": "1005053",
                "vill_name": "ນາຂວາງ",
                "vill_name_en": "",
                "dr_id": "1005"
              },
              {
                "vill_id": "1005054",
                "vill_name": "ວັງໂພ",
                "vill_name_en": "",
                "dr_id": "1005"
              },
              {
                "vill_id": "1005055",
                "vill_name": "ໂພນວຽງ",
                "vill_name_en": "",
                "dr_id": "1005"
              },
              {
                "vill_id": "1005056",
                "vill_name": "ໂພນເງິນ",
                "vill_name_en": "",
                "dr_id": "1005"
              },
              {
                "vill_id": "1005057",
                "vill_name": "ໂຊກໄຊ",
                "vill_name_en": "",
                "dr_id": "1005"
              },
              {
                "vill_id": "1005058",
                "vill_name": "ສະຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "1005"
              },
              {
                "vill_id": "1005059",
                "vill_name": "ນາລ້ອງກວາງ",
                "vill_name_en": "",
                "dr_id": "1005"
              },
              {
                "vill_id": "1005060",
                "vill_name": "ໂພນຄຳ",
                "vill_name_en": "",
                "dr_id": "1005"
              },
              {
                "vill_id": "1005061",
                "vill_name": "ຜາທອງ",
                "vill_name_en": "",
                "dr_id": "1005"
              },
              {
                "vill_id": "1005062",
                "vill_name": "ແກ່ວຄູນ",
                "vill_name_en": "",
                "dr_id": "1005"
              },
              {
                "vill_id": "1005063",
                "vill_name": "ຫ້ວຍໝໍ້",
                "vill_name_en": "",
                "dr_id": "1005"
              },
              {
                "vill_id": "1005064",
                "vill_name": "ນາໄຊສະຫວາງ",
                "vill_name_en": "",
                "dr_id": "1005"
              },
              {
                "vill_id": "1005065",
                "vill_name": "ໂພນພະ",
                "vill_name_en": "",
                "dr_id": "1005"
              },
              {
                "vill_id": "1005066",
                "vill_name": "ຫີນຫົວເສືອ",
                "vill_name_en": "",
                "dr_id": "1005"
              },
              {
                "vill_id": "1005067",
                "vill_name": "ມ່ວງສຸມ",
                "vill_name_en": "",
                "dr_id": "1005"
              },
              {
                "vill_id": "1005068",
                "vill_name": "ໄຊຈະເລີນ",
                "vill_name_en": "",
                "dr_id": "1005"
              },
              {
                "vill_id": "1005069",
                "vill_name": "ວຽງໄຊ",
                "vill_name_en": "Viengxay",
                "dr_id": "1005"
              },
              {
                "vill_id": "1005070",
                "vill_name": "ວັງໝ້ຽງ",
                "vill_name_en": "Vangmieng",
                "dr_id": "1005"
              },
              {
                "vill_id": "1005071",
                "vill_name": "ນ້ຳຊ່ວງ",
                "vill_name_en": "Namsouang",
                "dr_id": "1005"
              },
              {
                "vill_id": "1005072",
                "vill_name": "ງິ້ວ",
                "vill_name_en": "Eiew",
                "dr_id": "1005"
              }
            ]
          },
          {
            "dr_id": "1006",
            "dr_name": "ເຟືອງ",
            "dr_name_en": "Feuang",
            "pr_id": "10",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "1006001",
                "vill_name": "ປາກຮາງ",
                "vill_name_en": "",
                "dr_id": "1006"
              },
              {
                "vill_id": "1006002",
                "vill_name": "ນາຄຳ",
                "vill_name_en": "",
                "dr_id": "1006"
              },
              {
                "vill_id": "1006003",
                "vill_name": "ໂພນສະຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "1006"
              },
              {
                "vill_id": "1006004",
                "vill_name": "ນາເມືອງ",
                "vill_name_en": "",
                "dr_id": "1006"
              },
              {
                "vill_id": "1006005",
                "vill_name": "ນາອ່າງ",
                "vill_name_en": "",
                "dr_id": "1006"
              },
              {
                "vill_id": "1006006",
                "vill_name": "ນາລັງ",
                "vill_name_en": "",
                "dr_id": "1006"
              },
              {
                "vill_id": "1006007",
                "vill_name": "ນາຂອບ",
                "vill_name_en": "",
                "dr_id": "1006"
              },
              {
                "vill_id": "1006008",
                "vill_name": "ນາມົນ",
                "vill_name_en": "",
                "dr_id": "1006"
              },
              {
                "vill_id": "1006009",
                "vill_name": "ນາແຂງ",
                "vill_name_en": "",
                "dr_id": "1006"
              },
              {
                "vill_id": "1006010",
                "vill_name": "ໂພນທອນ",
                "vill_name_en": "",
                "dr_id": "1006"
              },
              {
                "vill_id": "1006011",
                "vill_name": "ໂພນແບ່ງ",
                "vill_name_en": "",
                "dr_id": "1006"
              },
              {
                "vill_id": "1006012",
                "vill_name": "ນ້ຳໄຮ",
                "vill_name_en": "",
                "dr_id": "1006"
              },
              {
                "vill_id": "1006013",
                "vill_name": "ນາທູນ",
                "vill_name_en": "",
                "dr_id": "1006"
              },
              {
                "vill_id": "1006014",
                "vill_name": "ພູພຽງ",
                "vill_name_en": "",
                "dr_id": "1006"
              },
              {
                "vill_id": "1006015",
                "vill_name": "ໂພນໄຊ",
                "vill_name_en": "",
                "dr_id": "1006"
              },
              {
                "vill_id": "1006016",
                "vill_name": "ນາທອງ",
                "vill_name_en": "",
                "dr_id": "1006"
              },
              {
                "vill_id": "1006017",
                "vill_name": "ເລົາຄຳ",
                "vill_name_en": "",
                "dr_id": "1006"
              },
              {
                "vill_id": "1006018",
                "vill_name": "ນ້ຳປຸງ",
                "vill_name_en": "",
                "dr_id": "1006"
              },
              {
                "vill_id": "1006019",
                "vill_name": "ນາຫວ້ານ",
                "vill_name_en": "",
                "dr_id": "1006"
              },
              {
                "vill_id": "1006020",
                "vill_name": "ນາກາງ",
                "vill_name_en": "",
                "dr_id": "1006"
              },
              {
                "vill_id": "1006021",
                "vill_name": "ທ່າ",
                "vill_name_en": "",
                "dr_id": "1006"
              },
              {
                "vill_id": "1006022",
                "vill_name": "ນາທົ່ວ",
                "vill_name_en": "",
                "dr_id": "1006"
              },
              {
                "vill_id": "1006024",
                "vill_name": "ເມືອງເຟືອງ",
                "vill_name_en": "",
                "dr_id": "1006"
              },
              {
                "vill_id": "1006025",
                "vill_name": "ສາມຫມື່ນ",
                "vill_name_en": "",
                "dr_id": "1006"
              },
              {
                "vill_id": "1006026",
                "vill_name": "ຄອນເລືອງ",
                "vill_name_en": "",
                "dr_id": "1006"
              },
              {
                "vill_id": "1006027",
                "vill_name": "ແກ້ງຊຽງ",
                "vill_name_en": "",
                "dr_id": "1006"
              },
              {
                "vill_id": "1006028",
                "vill_name": "ສີສະອາດ",
                "vill_name_en": "",
                "dr_id": "1006"
              },
              {
                "vill_id": "1006029",
                "vill_name": "ນາໃຫມ່",
                "vill_name_en": "",
                "dr_id": "1006"
              },
              {
                "vill_id": "1006030",
                "vill_name": "ຫນອງເປັດ",
                "vill_name_en": "",
                "dr_id": "1006"
              },
              {
                "vill_id": "1006031",
                "vill_name": "ນາບອນ",
                "vill_name_en": "",
                "dr_id": "1006"
              },
              {
                "vill_id": "1006032",
                "vill_name": "ນາແຊງ",
                "vill_name_en": "",
                "dr_id": "1006"
              },
              {
                "vill_id": "1006033",
                "vill_name": "ຜາສັງເຫນືອ",
                "vill_name_en": "",
                "dr_id": "1006"
              },
              {
                "vill_id": "1006034",
                "vill_name": "ໂພນຫີນແຮ່",
                "vill_name_en": "",
                "dr_id": "1006"
              },
              {
                "vill_id": "1006035",
                "vill_name": "ຫ້ວຍເດື່ອ",
                "vill_name_en": "",
                "dr_id": "1006"
              },
              {
                "vill_id": "1006036",
                "vill_name": "ໂພນແຍງ",
                "vill_name_en": "",
                "dr_id": "1006"
              },
              {
                "vill_id": "1006037",
                "vill_name": "ນາພຶງ",
                "vill_name_en": "",
                "dr_id": "1006"
              },
              {
                "vill_id": "1006038",
                "vill_name": "ດອນ",
                "vill_name_en": "",
                "dr_id": "1006"
              },
              {
                "vill_id": "1006039",
                "vill_name": "ໂພສີ",
                "vill_name_en": "",
                "dr_id": "1006"
              },
              {
                "vill_id": "1006040",
                "vill_name": "ປາກງ້ວາ",
                "vill_name_en": "",
                "dr_id": "1006"
              },
              {
                "vill_id": "1006041",
                "vill_name": "ຜາຫລວງ",
                "vill_name_en": "",
                "dr_id": "1006"
              },
              {
                "vill_id": "1006042",
                "vill_name": "ຫນອງປໍ",
                "vill_name_en": "",
                "dr_id": "1006"
              },
              {
                "vill_id": "1006043",
                "vill_name": "ຜາສັງໃຕ້",
                "vill_name_en": "",
                "dr_id": "1006"
              },
              {
                "vill_id": "1006044",
                "vill_name": "ແສນໄຊ",
                "vill_name_en": "",
                "dr_id": "1006"
              },
              {
                "vill_id": "1006045",
                "vill_name": "ໂພນສະຫວາດ",
                "vill_name_en": "",
                "dr_id": "1006"
              },
              {
                "vill_id": "1006046",
                "vill_name": "ນ້ຳໄຊ",
                "vill_name_en": "Namxay",
                "dr_id": "1006"
              }
            ]
          },
          {
            "dr_id": "1007",
            "dr_name": "ຊະນະຄາມ",
            "dr_name_en": "Xanakham",
            "pr_id": "10",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "1007001",
                "vill_name": "ດອນໂຊກ",
                "vill_name_en": "",
                "dr_id": "1007"
              },
              {
                "vill_id": "1007002",
                "vill_name": "ສີພູມ",
                "vill_name_en": "",
                "dr_id": "1007"
              },
              {
                "vill_id": "1007003",
                "vill_name": "ຈວນສະຫວັນ",
                "vill_name_en": "",
                "dr_id": "1007"
              },
              {
                "vill_id": "1007004",
                "vill_name": "ຕາກແດດ",
                "vill_name_en": "",
                "dr_id": "1007"
              },
              {
                "vill_id": "1007005",
                "vill_name": "ປາກມີ້",
                "vill_name_en": "",
                "dr_id": "1007"
              },
              {
                "vill_id": "1007006",
                "vill_name": "ນາສົມບູນ",
                "vill_name_en": "",
                "dr_id": "1007"
              },
              {
                "vill_id": "1007007",
                "vill_name": "ໂພນສະຫວາດ",
                "vill_name_en": "",
                "dr_id": "1007"
              },
              {
                "vill_id": "1007008",
                "vill_name": "ແສນຈະເລີນ",
                "vill_name_en": "",
                "dr_id": "1007"
              },
              {
                "vill_id": "1007009",
                "vill_name": "ນາກາງ",
                "vill_name_en": "",
                "dr_id": "1007"
              },
              {
                "vill_id": "1007010",
                "vill_name": "ໂນນນາແກບ",
                "vill_name_en": "",
                "dr_id": "1007"
              },
              {
                "vill_id": "1007011",
                "vill_name": "ນາມອຍ",
                "vill_name_en": "",
                "dr_id": "1007"
              },
              {
                "vill_id": "1007012",
                "vill_name": "ນາດີ",
                "vill_name_en": "",
                "dr_id": "1007"
              },
              {
                "vill_id": "1007013",
                "vill_name": "ນ້ຳກວນ",
                "vill_name_en": "",
                "dr_id": "1007"
              },
              {
                "vill_id": "1007014",
                "vill_name": "ຫ້ວຍຕາວ",
                "vill_name_en": "",
                "dr_id": "1007"
              },
              {
                "vill_id": "1007015",
                "vill_name": "ຫ້ວຍຂະແຍງ",
                "vill_name_en": "",
                "dr_id": "1007"
              },
              {
                "vill_id": "1007016",
                "vill_name": "ຄົກງິ້ວ",
                "vill_name_en": "",
                "dr_id": "1007"
              },
              {
                "vill_id": "1007017",
                "vill_name": "ຄົກເຂົ້າດໍ",
                "vill_name_en": "",
                "dr_id": "1007"
              },
              {
                "vill_id": "1007018",
                "vill_name": "ຫ້ວຍເຕົ່າ",
                "vill_name_en": "",
                "dr_id": "1007"
              },
              {
                "vill_id": "1007019",
                "vill_name": "ໃຫມ່ປາກທູນ",
                "vill_name_en": "",
                "dr_id": "1007"
              },
              {
                "vill_id": "1007020",
                "vill_name": "ດອນພຸງ",
                "vill_name_en": "",
                "dr_id": "1007"
              },
              {
                "vill_id": "1007021",
                "vill_name": "ນາເດື່ອ",
                "vill_name_en": "",
                "dr_id": "1007"
              },
              {
                "vill_id": "1007022",
                "vill_name": "ໂພນສະຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "1007"
              },
              {
                "vill_id": "1007023",
                "vill_name": "ໂນນສະຫວັນ",
                "vill_name_en": "",
                "dr_id": "1007"
              },
              {
                "vill_id": "1007024",
                "vill_name": "ນາສັກ",
                "vill_name_en": "",
                "dr_id": "1007"
              },
              {
                "vill_id": "1007025",
                "vill_name": "ດົງຂ່າ",
                "vill_name_en": "",
                "dr_id": "1007"
              },
              {
                "vill_id": "1007027",
                "vill_name": "ບົວຈະເລີນ",
                "vill_name_en": "",
                "dr_id": "1007"
              },
              {
                "vill_id": "1007028",
                "vill_name": "ນ້ຳພອກ",
                "vill_name_en": "",
                "dr_id": "1007"
              },
              {
                "vill_id": "1007029",
                "vill_name": "ນາປາຝາ",
                "vill_name_en": "",
                "dr_id": "1007"
              },
              {
                "vill_id": "1007030",
                "vill_name": "ນາພຽງ",
                "vill_name_en": "",
                "dr_id": "1007"
              },
              {
                "vill_id": "1007031",
                "vill_name": "ປົ່ງ",
                "vill_name_en": "",
                "dr_id": "1007"
              },
              {
                "vill_id": "1007032",
                "vill_name": "ຜາລາດ",
                "vill_name_en": "",
                "dr_id": "1007"
              },
              {
                "vill_id": "1007033",
                "vill_name": "ປາກພາງ",
                "vill_name_en": "",
                "dr_id": "1007"
              },
              {
                "vill_id": "1007034",
                "vill_name": "ຊະນະຄາມ",
                "vill_name_en": "",
                "dr_id": "1007"
              },
              {
                "vill_id": "1007035",
                "vill_name": "ໂພນງາມ",
                "vill_name_en": "",
                "dr_id": "1007"
              },
              {
                "vill_id": "1008001",
                "vill_name": "ນາດອກຄູນ",
                "vill_name_en": "",
                "dr_id": "1007"
              },
              {
                "vill_id": "1008002",
                "vill_name": "ນາງິ້ວ",
                "vill_name_en": "",
                "dr_id": "1007"
              },
              {
                "vill_id": "1008003",
                "vill_name": "ນາອຸດົມເໝືອ",
                "vill_name_en": "",
                "dr_id": "1007"
              },
              {
                "vill_id": "1008004",
                "vill_name": "ຫ້ວຍຕົມ",
                "vill_name_en": "",
                "dr_id": "1007"
              },
              {
                "vill_id": "1008005",
                "vill_name": "ນາເທົາ",
                "vill_name_en": "",
                "dr_id": "1007"
              },
              {
                "vill_id": "1008006",
                "vill_name": "ນາກ້າງປາ",
                "vill_name_en": "",
                "dr_id": "1007"
              },
              {
                "vill_id": "1008007",
                "vill_name": "ນ້ຳຍອນ",
                "vill_name_en": "",
                "dr_id": "1007"
              },
              {
                "vill_id": "1008008",
                "vill_name": "ຫາດຍາວ",
                "vill_name_en": "",
                "dr_id": "1007"
              },
              {
                "vill_id": "1008009",
                "vill_name": "ນາປາຈາດ",
                "vill_name_en": "",
                "dr_id": "1007"
              },
              {
                "vill_id": "1008010",
                "vill_name": "ເມືອງແມດ",
                "vill_name_en": "",
                "dr_id": "1007"
              },
              {
                "vill_id": "1008011",
                "vill_name": "ນາໂພ",
                "vill_name_en": "",
                "dr_id": "1007"
              },
              {
                "vill_id": "1008012",
                "vill_name": "ນາປ່ານ",
                "vill_name_en": "",
                "dr_id": "1007"
              },
              {
                "vill_id": "1008013",
                "vill_name": "ນ້ຳຮວາງ",
                "vill_name_en": "",
                "dr_id": "1007"
              },
              {
                "vill_id": "1008014",
                "vill_name": "ຫ້ວຍປ່າຫມາກ",
                "vill_name_en": "",
                "dr_id": "1007"
              },
              {
                "vill_id": "1008015",
                "vill_name": "ນ້ຳເຟືອງ",
                "vill_name_en": "",
                "dr_id": "1007"
              },
              {
                "vill_id": "1008016",
                "vill_name": "ສີສະຫວາດ",
                "vill_name_en": "",
                "dr_id": "1007"
              },
              {
                "vill_id": "1008017",
                "vill_name": "ປາກຍັນ",
                "vill_name_en": "",
                "dr_id": "1007"
              },
              {
                "vill_id": "1008018",
                "vill_name": "ນາດີ",
                "vill_name_en": "",
                "dr_id": "1007"
              },
              {
                "vill_id": "1008019",
                "vill_name": "ສີສະອາດເຫນືອ",
                "vill_name_en": "",
                "dr_id": "1007"
              },
              {
                "vill_id": "1008020",
                "vill_name": "ນາຍາບ",
                "vill_name_en": "",
                "dr_id": "1007"
              },
              {
                "vill_id": "1008021",
                "vill_name": "ນ້ຳປູ",
                "vill_name_en": "",
                "dr_id": "1007"
              }
            ]
          },
          {
            "dr_id": "1008",
            "dr_name": "ແມດ",
            "dr_name_en": "Mad",
            "pr_id": "10",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "1008022",
                "vill_name": "ໂນນສະຫວາດ",
                "vill_name_en": "",
                "dr_id": "1008"
              },
              {
                "vill_id": "1008023",
                "vill_name": "ນາມົນ",
                "vill_name_en": "",
                "dr_id": "1008"
              },
              {
                "vill_id": "1008024",
                "vill_name": "ສີສະອາດໃຕ້",
                "vill_name_en": "",
                "dr_id": "1008"
              },
              {
                "vill_id": "1008025",
                "vill_name": "ປາກແຜນ",
                "vill_name_en": "",
                "dr_id": "1008"
              },
              {
                "vill_id": "1008026",
                "vill_name": "ນ້ຳແຜນ",
                "vill_name_en": "",
                "dr_id": "1008"
              },
              {
                "vill_id": "1008027",
                "vill_name": "ສາມັກຄີໄຊ",
                "vill_name_en": "",
                "dr_id": "1008"
              },
              {
                "vill_id": "1008028",
                "vill_name": "ໂພນແຍງ",
                "vill_name_en": "",
                "dr_id": "1008"
              },
              {
                "vill_id": "1008029",
                "vill_name": "ເມືອງຄີ່",
                "vill_name_en": "",
                "dr_id": "1008"
              },
              {
                "vill_id": "1008030",
                "vill_name": "ນາອຸດົມໃຕ້",
                "vill_name_en": "",
                "dr_id": "1008"
              },
              {
                "vill_id": "1008031",
                "vill_name": "ນາສະຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "1008"
              },
              {
                "vill_id": "1008032",
                "vill_name": "ຫ້ວຍກວ່າ",
                "vill_name_en": "",
                "dr_id": "1008"
              },
              {
                "vill_id": "1008033",
                "vill_name": "ດອນຊາຍງາມ",
                "vill_name_en": "",
                "dr_id": "1008"
              },
              {
                "vill_id": "1008034",
                "vill_name": "ນາປານ",
                "vill_name_en": "",
                "dr_id": "1008"
              },
              {
                "vill_id": "10080035",
                "vill_name": "ນ້ຳຮ່ວງ",
                "vill_name_en": "Namhuang",
                "dr_id": "1008"
              },
              {
                "vill_id": "10080036",
                "vill_name": "ຫາດຍາວ",
                "vill_name_en": "hardyao",
                "dr_id": "1008"
              },
              {
                "vill_id": "10080037",
                "vill_name": "ເມືອງແມດ",
                "vill_name_en": "MEUANGMAET",
                "dr_id": "1008"
              },
              {
                "vill_id": "10080038",
                "vill_name": "ນາເທົາ",
                "vill_name_en": "TANMIXAY",
                "dr_id": "1008"
              },
              {
                "vill_id": "10080039",
                "vill_name": "ນາກ້າງປາ",
                "vill_name_en": "Nakhangpa",
                "dr_id": "1008"
              },
              {
                "vill_id": "10080040",
                "vill_name": "ແມດ",
                "vill_name_en": "Maed",
                "dr_id": "1008"
              },
              {
                "vill_id": "10080041",
                "vill_name": "ສີສະຫວາດ",
                "vill_name_en": "sisawad",
                "dr_id": "1008"
              },
              {
                "vill_id": "10080042",
                "vill_name": "ປາກຍັນ",
                "vill_name_en": "Pakyan",
                "dr_id": "1008"
              },
              {
                "vill_id": "10080043",
                "vill_name": "ຫ້ວຍປ່າໝາກ",
                "vill_name_en": "Hoauwpamak",
                "dr_id": "1008"
              }
            ]
          },
          {
            "dr_id": "1009",
            "dr_name": "ວຽງຄຳ",
            "dr_name_en": "Viengkham",
            "pr_id": "10",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "1009001",
                "vill_name": "ປາກກະຍຸງ",
                "vill_name_en": "",
                "dr_id": "1009"
              },
              {
                "vill_id": "1009002",
                "vill_name": "ທ່າໂພໄຊ",
                "vill_name_en": "",
                "dr_id": "1009"
              },
              {
                "vill_id": "1009003",
                "vill_name": "ເມືອງເກົ່າ",
                "vill_name_en": "",
                "dr_id": "1009"
              },
              {
                "vill_id": "1009004",
                "vill_name": "ເວີນແສນ",
                "vill_name_en": "",
                "dr_id": "1009"
              },
              {
                "vill_id": "1009005",
                "vill_name": "ນາເຄືອ",
                "vill_name_en": "",
                "dr_id": "1009"
              },
              {
                "vill_id": "1009006",
                "vill_name": "ນານິນ",
                "vill_name_en": "",
                "dr_id": "1009"
              },
              {
                "vill_id": "1009007",
                "vill_name": "ຫາດຊາຍຄູນ",
                "vill_name_en": "",
                "dr_id": "1009"
              },
              {
                "vill_id": "1009008",
                "vill_name": "ໂນນສະຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "1009"
              },
              {
                "vill_id": "1009009",
                "vill_name": "ໂພນຫມີເໜືອ",
                "vill_name_en": "",
                "dr_id": "1009"
              },
              {
                "vill_id": "1009010",
                "vill_name": "ໂພນຊອງ",
                "vill_name_en": "",
                "dr_id": "1009"
              },
              {
                "vill_id": "1009011",
                "vill_name": "ປາກແຈ້ງ",
                "vill_name_en": "",
                "dr_id": "1009"
              },
              {
                "vill_id": "1009012",
                "vill_name": "ໃຫມ່ໜອງຄວາຍ",
                "vill_name_en": "",
                "dr_id": "1009"
              },
              {
                "vill_id": "1009013",
                "vill_name": "ໂພນຮັງ",
                "vill_name_en": "",
                "dr_id": "1009"
              },
              {
                "vill_id": "1009014",
                "vill_name": "ດອນກວດ",
                "vill_name_en": "",
                "dr_id": "1009"
              },
              {
                "vill_id": "1009015",
                "vill_name": "ຖິ່ນຍຸງ",
                "vill_name_en": "",
                "dr_id": "1009"
              },
              {
                "vill_id": "1009016",
                "vill_name": "ວຽງຄຳ",
                "vill_name_en": "",
                "dr_id": "1009"
              },
              {
                "vill_id": "1009017",
                "vill_name": "ໂພນຫມີໃຕ້",
                "vill_name_en": "",
                "dr_id": "1009"
              }
            ]
          },
          {
            "dr_id": "1010",
            "dr_name": "ຫີນເຫີບ",
            "dr_name_en": "Hinherb",
            "pr_id": "10",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "1010001",
                "vill_name": "ຫີນໂງ່ນ",
                "vill_name_en": "",
                "dr_id": "1010"
              },
              {
                "vill_id": "1010002",
                "vill_name": "ວຽງທອງ",
                "vill_name_en": "",
                "dr_id": "1010"
              },
              {
                "vill_id": "1010003",
                "vill_name": "ໂພນສູງ",
                "vill_name_en": "",
                "dr_id": "1010"
              },
              {
                "vill_id": "1010004",
                "vill_name": "ຄອນພຸກ",
                "vill_name_en": "",
                "dr_id": "1010"
              },
              {
                "vill_id": "1010005",
                "vill_name": "ບໍ່ຈານ",
                "vill_name_en": "",
                "dr_id": "1010"
              },
              {
                "vill_id": "1010006",
                "vill_name": "ໂພນມ່ວງ",
                "vill_name_en": "",
                "dr_id": "1010"
              },
              {
                "vill_id": "1010007",
                "vill_name": "ຫີນເຫີບໃຕ້",
                "vill_name_en": "",
                "dr_id": "1010"
              },
              {
                "vill_id": "1010008",
                "vill_name": "ຫີນເຫີບເຫນືອ",
                "vill_name_en": "",
                "dr_id": "1010"
              },
              {
                "vill_id": "1010009",
                "vill_name": "ວັງຄີ",
                "vill_name_en": "",
                "dr_id": "1010"
              },
              {
                "vill_id": "1010010",
                "vill_name": "ສີວິໄລ",
                "vill_name_en": "",
                "dr_id": "1010"
              },
              {
                "vill_id": "1010011",
                "vill_name": "ສົມສະນຸກ",
                "vill_name_en": "",
                "dr_id": "1010"
              },
              {
                "vill_id": "1010012",
                "vill_name": "ໂພນທອງເໜືອ",
                "vill_name_en": "",
                "dr_id": "1010"
              },
              {
                "vill_id": "1010013",
                "vill_name": "ນ້ຳປັດ",
                "vill_name_en": "",
                "dr_id": "1010"
              },
              {
                "vill_id": "1010014",
                "vill_name": "ຫ້ວຍດອກໄມ້",
                "vill_name_en": "",
                "dr_id": "1010"
              },
              {
                "vill_id": "1010015",
                "vill_name": "ຫີນຕີດ",
                "vill_name_en": "",
                "dr_id": "1010"
              },
              {
                "vill_id": "1010016",
                "vill_name": "ປ່ອງຊອງ",
                "vill_name_en": "",
                "dr_id": "1010"
              },
              {
                "vill_id": "1010017",
                "vill_name": "ນາກາງ",
                "vill_name_en": "",
                "dr_id": "1010"
              },
              {
                "vill_id": "1010018",
                "vill_name": "ຜາບ່ອງ",
                "vill_name_en": "",
                "dr_id": "1010"
              },
              {
                "vill_id": "1010019",
                "vill_name": "ນາເລືອງ",
                "vill_name_en": "",
                "dr_id": "1010"
              },
              {
                "vill_id": "1010020",
                "vill_name": "ນາຕ້າຍ",
                "vill_name_en": "",
                "dr_id": "1010"
              },
              {
                "vill_id": "1010021",
                "vill_name": "ນາອັນ",
                "vill_name_en": "",
                "dr_id": "1010"
              },
              {
                "vill_id": "1010022",
                "vill_name": "ນາພ້ອງ",
                "vill_name_en": "",
                "dr_id": "1010"
              },
              {
                "vill_id": "1010023",
                "vill_name": "ນາຊັດ",
                "vill_name_en": "",
                "dr_id": "1010"
              },
              {
                "vill_id": "1010024",
                "vill_name": "ນາຄູ້ມ",
                "vill_name_en": "",
                "dr_id": "1010"
              },
              {
                "vill_id": "1010025",
                "vill_name": "ຕັ່ງຄຳ",
                "vill_name_en": "",
                "dr_id": "1010"
              },
              {
                "vill_id": "1010026",
                "vill_name": "ຫ້ວຍອີເລີດ",
                "vill_name_en": "",
                "dr_id": "1010"
              },
              {
                "vill_id": "1010027",
                "vill_name": "ໂພນວິໄລ",
                "vill_name_en": "",
                "dr_id": "1010"
              },
              {
                "vill_id": "1010028",
                "vill_name": "ໂພນສະຫວັນ",
                "vill_name_en": "",
                "dr_id": "1010"
              },
              {
                "vill_id": "1010029",
                "vill_name": "ຫ້ວຍຫີນລັບ",
                "vill_name_en": "",
                "dr_id": "1010"
              },
              {
                "vill_id": "1010030",
                "vill_name": "ໂພນກາງ",
                "vill_name_en": "",
                "dr_id": "1010"
              },
              {
                "vill_id": "1010031",
                "vill_name": "ໂພນເງິນ",
                "vill_name_en": "",
                "dr_id": "1010"
              },
              {
                "vill_id": "1010032",
                "vill_name": "ໂພນຄຳ",
                "vill_name_en": "",
                "dr_id": "1010"
              },
              {
                "vill_id": "1010033",
                "vill_name": "ຄອນແກ້ວ",
                "vill_name_en": "",
                "dr_id": "1010"
              },
              {
                "vill_id": "1010034",
                "vill_name": "ສວນມອນ",
                "vill_name_en": "",
                "dr_id": "1010"
              },
              {
                "vill_id": "1010035",
                "vill_name": "ນາພົງ",
                "vill_name_en": "",
                "dr_id": "1010"
              },
              {
                "vill_id": "1010036",
                "vill_name": "ວຽງຄຳ",
                "vill_name_en": "",
                "dr_id": "1010"
              },
              {
                "vill_id": "1010037",
                "vill_name": "ນ້ຳໂທ່ມ",
                "vill_name_en": "",
                "dr_id": "1010"
              },
              {
                "vill_id": "1010038",
                "vill_name": "ມຸກ",
                "vill_name_en": "",
                "dr_id": "1010"
              },
              {
                "vill_id": "1010039",
                "vill_name": "ນາຫວາຍ",
                "vill_name_en": "",
                "dr_id": "1010"
              },
              {
                "vill_id": "1010040",
                "vill_name": "ນາທອງ",
                "vill_name_en": "",
                "dr_id": "1010"
              },
              {
                "vill_id": "1010041",
                "vill_name": "ນາຊຳ",
                "vill_name_en": "",
                "dr_id": "1010"
              },
              {
                "vill_id": "1010042",
                "vill_name": "ຂອນແກ່ນ",
                "vill_name_en": "",
                "dr_id": "1010"
              },
              {
                "vill_id": "1010043",
                "vill_name": "ໜອງຫລວງ",
                "vill_name_en": "",
                "dr_id": "1010"
              },
              {
                "vill_id": "1010044",
                "vill_name": "ໂພນວິໄລ",
                "vill_name_en": "",
                "dr_id": "1010"
              }
            ]
          },
          {
            "dr_id": "1012",
            "dr_name": "ໝື່ນ",
            "dr_name_en": "Meun",
            "pr_id": "10",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "1012001",
                "vill_name": "ຜາລະແວກ",
                "vill_name_en": "",
                "dr_id": "1012"
              },
              {
                "vill_id": "1012002",
                "vill_name": "ນ້ຳຄຽນ",
                "vill_name_en": "",
                "dr_id": "1012"
              },
              {
                "vill_id": "1012003",
                "vill_name": "ຮົ່ມທາດ",
                "vill_name_en": "",
                "dr_id": "1012"
              },
              {
                "vill_id": "1012004",
                "vill_name": "ນ້ຳປຸ່ງ",
                "vill_name_en": "",
                "dr_id": "1012"
              },
              {
                "vill_id": "1012005",
                "vill_name": "ສາມເສົ້າ",
                "vill_name_en": "",
                "dr_id": "1012"
              },
              {
                "vill_id": "1012006",
                "vill_name": "ຫ້ວຍຊາຍ",
                "vill_name_en": "",
                "dr_id": "1012"
              },
              {
                "vill_id": "1012007",
                "vill_name": "ນ້ຳຢວກ",
                "vill_name_en": "",
                "dr_id": "1012"
              },
              {
                "vill_id": "1012008",
                "vill_name": "ຫນອງ",
                "vill_name_en": "",
                "dr_id": "1012"
              },
              {
                "vill_id": "1012009",
                "vill_name": "ສົບພວນ",
                "vill_name_en": "",
                "dr_id": "1012"
              },
              {
                "vill_id": "1012010",
                "vill_name": "ຫ້ວຍປາມ້ອມ",
                "vill_name_en": "",
                "dr_id": "1012"
              },
              {
                "vill_id": "1012011",
                "vill_name": "ພູກະທະ",
                "vill_name_en": "",
                "dr_id": "1012"
              },
              {
                "vill_id": "1012012",
                "vill_name": "ນ້ຳທວາຍ",
                "vill_name_en": "",
                "dr_id": "1012"
              },
              {
                "vill_id": "1012013",
                "vill_name": "ນ້ຳມຸ້ງ",
                "vill_name_en": "",
                "dr_id": "1012"
              },
              {
                "vill_id": "1012014",
                "vill_name": "ນ້ຳເຢັນ",
                "vill_name_en": "",
                "dr_id": "1012"
              },
              {
                "vill_id": "1012015",
                "vill_name": "ແກ້ວສາມມີ້",
                "vill_name_en": "",
                "dr_id": "1012"
              },
              {
                "vill_id": "1012016",
                "vill_name": "ນາມອນ",
                "vill_name_en": "",
                "dr_id": "1012"
              },
              {
                "vill_id": "1012017",
                "vill_name": "ດອນສຳພັນ",
                "vill_name_en": "",
                "dr_id": "1012"
              },
              {
                "vill_id": "1012018",
                "vill_name": "ໂພນສະຫວາດ",
                "vill_name_en": "",
                "dr_id": "1012"
              },
              {
                "vill_id": "1012019",
                "vill_name": "ສັນປາຕອງ",
                "vill_name_en": "",
                "dr_id": "1012"
              },
              {
                "vill_id": "1012020",
                "vill_name": "ຫີນສໍ",
                "vill_name_en": "",
                "dr_id": "1012"
              },
              {
                "vill_id": "1012021",
                "vill_name": "ພູຜາດ່າງ",
                "vill_name_en": "",
                "dr_id": "1012"
              },
              {
                "vill_id": "1012022",
                "vill_name": "ທ່າເຮືອ",
                "vill_name_en": "",
                "dr_id": "1012"
              },
              {
                "vill_id": "1012023",
                "vill_name": "ຖ້ຳດິນ",
                "vill_name_en": "",
                "dr_id": "1012"
              },
              {
                "vill_id": "1012024",
                "vill_name": "ພູປ່າມັນ",
                "vill_name_en": "",
                "dr_id": "1012"
              },
              {
                "vill_id": "1012025",
                "vill_name": "ໂພນມ່ວງ",
                "vill_name_en": "",
                "dr_id": "1012"
              },
              {
                "vill_id": "1012026",
                "vill_name": "ຄອນວັດ",
                "vill_name_en": "",
                "dr_id": "1012"
              },
              {
                "vill_id": "1012027",
                "vill_name": "ຊຳຄອນ",
                "vill_name_en": "",
                "dr_id": "1012"
              },
              {
                "vill_id": "1012028",
                "vill_name": "ໂພນເລົ່າ",
                "vill_name_en": "",
                "dr_id": "1012"
              },
              {
                "vill_id": "1012029",
                "vill_name": "ວັງຫລວງ",
                "vill_name_en": "",
                "dr_id": "1012"
              },
              {
                "vill_id": "1012030",
                "vill_name": "ຜາແອ່ນ",
                "vill_name_en": "",
                "dr_id": "1012"
              },
              {
                "vill_id": "1012031",
                "vill_name": "ນາໄຊ",
                "vill_name_en": "",
                "dr_id": "1012"
              },
              {
                "vill_id": "1012032",
                "vill_name": "ໂພນໄຊ",
                "vill_name_en": "",
                "dr_id": "1012"
              },
              {
                "vill_id": "1012033",
                "vill_name": "ນາໂພ",
                "vill_name_en": "",
                "dr_id": "1012"
              },
              {
                "vill_id": "1012034",
                "vill_name": "ຊຽງມີ",
                "vill_name_en": "",
                "dr_id": "1012"
              },
              {
                "vill_id": "1012035",
                "vill_name": "ນ້ຳຍິ້ງ",
                "vill_name_en": "",
                "dr_id": "1012"
              },
              {
                "vill_id": "1012036",
                "vill_name": "ນ້ຳຄຸຍ",
                "vill_name_en": "",
                "dr_id": "1012"
              },
              {
                "vill_id": "1012037",
                "vill_name": "ເມືອງຫລົງ",
                "vill_name_en": "",
                "dr_id": "1012"
              },
              {
                "vill_id": "1012038",
                "vill_name": "ຫ້ວຍສຽດ",
                "vill_name_en": "",
                "dr_id": "1012"
              },
              {
                "vill_id": "1012039",
                "vill_name": "ໂພນສະອາດ",
                "vill_name_en": "",
                "dr_id": "1012"
              },
              {
                "vill_id": "1012040",
                "vill_name": "ນາບົວ",
                "vill_name_en": "",
                "dr_id": "1012"
              },
              {
                "vill_id": "1012041",
                "vill_name": "ບ້ານນ້ຳແພດ",
                "vill_name_en": "Nampheak",
                "dr_id": "1012"
              },
              {
                "vill_id": "1012042",
                "vill_name": "ນາປ່າໄຜ່",
                "vill_name_en": "Napapaiy",
                "dr_id": "1012"
              },
              {
                "vill_id": "1012043",
                "vill_name": "ນ້ຳເພີນ",
                "vill_name_en": "Numphen",
                "dr_id": "1012"
              },
              {
                "vill_id": "1012044",
                "vill_name": "ປາກຈັນ",
                "vill_name_en": "Pakchanh",
                "dr_id": "1012"
              },
              {
                "vill_id": "1012045",
                "vill_name": "ປາກຊາວ",
                "vill_name_en": "Pakchao",
                "dr_id": "1012"
              },
              {
                "vill_id": "1012046",
                "vill_name": "ນາຍາວ",
                "vill_name_en": "Nayao",
                "dr_id": "1012"
              },
              {
                "vill_id": "1012047",
                "vill_name": "ນ້ຳຮອນ",
                "vill_name_en": "Namhon",
                "dr_id": "1012"
              },
              {
                "vill_id": "1012048",
                "vill_name": "ໂນ້ນຕ້ອງ",
                "vill_name_en": "Nonetong",
                "dr_id": "1012"
              },
              {
                "vill_id": "1012049",
                "vill_name": "ດອນຮຽງ",
                "vill_name_en": "Donhieng",
                "dr_id": "1012"
              },
              {
                "vill_id": "1012050",
                "vill_name": "ບ້ານໂພນງາມ",
                "vill_name_en": "Phonngam",
                "dr_id": "1012"
              },
              {
                "vill_id": "1012051",
                "vill_name": "ບ້ານໂພນສີວິໄລ",
                "vill_name_en": "Phonsivilai",
                "dr_id": "1012"
              },
              {
                "vill_id": "1012052",
                "vill_name": "ໂນນໄຮ",
                "vill_name_en": "Nonehaiy",
                "dr_id": "1012"
              },
              {
                "vill_id": "1012053",
                "vill_name": "ໂພນແສນ",
                "vill_name_en": "Phonsang",
                "dr_id": "1012"
              },
              {
                "vill_id": "1012054",
                "vill_name": "ນາໝໍ້",
                "vill_name_en": "Namor",
                "dr_id": "1012"
              },
              {
                "vill_id": "1012055",
                "vill_name": "ກ້ອນຄຳ",
                "vill_name_en": "Konkham",
                "dr_id": "1012"
              },
              {
                "vill_id": "1012056",
                "vill_name": "ນາໝໍ້",
                "vill_name_en": "Nammor",
                "dr_id": "1012"
              },
              {
                "vill_id": "1012057",
                "vill_name": "ເມືອງໝື່ນ",
                "vill_name_en": "Moungmoun",
                "dr_id": "1012"
              }
            ]
          }
        ]
      },
      {
        "pr_id": "11",
        "pr_name": "ບໍລິຄຳໄຊ",
        "pr_name_en": "Boli khamxai",
        "districts": [
          {"dr_name": "ເລືອກເມືອງປະຈຸບັນ", "villages": []},
          {
            "dr_id": "1101",
            "dr_name": "ປາກຊັນ",
            "dr_name_en": "Pakxane",
            "pr_id": "11",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "110100",
                "vill_name": "ປາກ​ຊັນ​ໃຕ້",
                "vill_name_en": "",
                "dr_id": "1101"
              },
              {
                "vill_id": "110101",
                "vill_name": "ໂພນໄຊ",
                "vill_name_en": "",
                "dr_id": "1101"
              },
              {
                "vill_id": "110102",
                "vill_name": "ໂພສີ",
                "vill_name_en": "",
                "dr_id": "1101"
              },
              {
                "vill_id": "110103",
                "vill_name": "ສີມຸງຄຸນ",
                "vill_name_en": "",
                "dr_id": "1101"
              },
              {
                "vill_id": "110104",
                "vill_name": "ປາກຊັນເຫນືອ",
                "vill_name_en": "",
                "dr_id": "1101"
              },
              {
                "vill_id": "110105",
                "vill_name": "ໂພ​ໄຊ",
                "vill_name_en": "",
                "dr_id": "1101"
              },
              {
                "vill_id": "110106",
                "vill_name": "ປາກປຶງ",
                "vill_name_en": "",
                "dr_id": "1101"
              },
              {
                "vill_id": "110107",
                "vill_name": "ມີໄຊ",
                "vill_name_en": "",
                "dr_id": "1101"
              },
              {
                "vill_id": "110108",
                "vill_name": "ຫ້ວຍສຽດ",
                "vill_name_en": "",
                "dr_id": "1101"
              },
              {
                "vill_id": "110109",
                "vill_name": "ສີສະອາດ",
                "vill_name_en": "",
                "dr_id": "1101"
              },
              {
                "vill_id": "1101001",
                "vill_name": "ເກີນເຫນືອ",
                "vill_name_en": "",
                "dr_id": "1101"
              },
              {
                "vill_id": "11010010",
                "vill_name": "ໂພນສະອາດ",
                "vill_name_en": "",
                "dr_id": "1101"
              },
              {
                "vill_id": "11010011",
                "vill_name": "ໂພນສະອາດ",
                "vill_name_en": "",
                "dr_id": "1101"
              },
              {
                "vill_id": "11010012",
                "vill_name": "ທ່າສີໄຄ",
                "vill_name_en": "",
                "dr_id": "1101"
              },
              {
                "vill_id": "11010013",
                "vill_name": "ສີວິໄລ",
                "vill_name_en": "",
                "dr_id": "1101"
              },
              {
                "vill_id": "11010014",
                "vill_name": "ສວນສະຫວັນ",
                "vill_name_en": "",
                "dr_id": "1101"
              },
              {
                "vill_id": "11010015",
                "vill_name": "ນາຈິກ",
                "vill_name_en": "",
                "dr_id": "1101"
              },
              {
                "vill_id": "11010016",
                "vill_name": "ຄຳໄຜ່",
                "vill_name_en": "",
                "dr_id": "1101"
              },
              {
                "vill_id": "11010017",
                "vill_name": "ນາຂາມ",
                "vill_name_en": "",
                "dr_id": "1101"
              },
              {
                "vill_id": "11010018",
                "vill_name": "ຄຳບອນ",
                "vill_name_en": "",
                "dr_id": "1101"
              },
              {
                "vill_id": "11010019",
                "vill_name": "ນາອ້ອຍຫນູ",
                "vill_name_en": "",
                "dr_id": "1101"
              },
              {
                "vill_id": "11010020",
                "vill_name": "ນາເຂົ້າລົ້ມ",
                "vill_name_en": "",
                "dr_id": "1101"
              },
              {
                "vill_id": "11010021",
                "vill_name": "ສົມສະຫວາດ",
                "vill_name_en": "",
                "dr_id": "1101"
              },
              {
                "vill_id": "11010022",
                "vill_name": "ນາໃຫມ",
                "vill_name_en": "",
                "dr_id": "1101"
              },
              {
                "vill_id": "11010023",
                "vill_name": "ທົ່ງຫວ້ານ",
                "vill_name_en": "",
                "dr_id": "1101"
              },
              {
                "vill_id": "11010024",
                "vill_name": "ຫາງຊິງ",
                "vill_name_en": "",
                "dr_id": "1101"
              },
              {
                "vill_id": "11010025",
                "vill_name": "ກ້ວຍ​ຫີນ​ລາດ",
                "vill_name_en": "",
                "dr_id": "1101"
              },
              {
                "vill_id": "11010026",
                "vill_name": "ແສນອຸດົມ",
                "vill_name_en": "",
                "dr_id": "1101"
              },
              {
                "vill_id": "11010027",
                "vill_name": "ນ້ຳ​ງຽບ",
                "vill_name_en": "",
                "dr_id": "1101"
              },
              {
                "vill_id": "11010028",
                "vill_name": "ໄຊ​ສະ​ຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "1101"
              },
              {
                "vill_id": "11010029",
                "vill_name": "ໂພນສີ(ທ່າກົກແຄນ)",
                "vill_name_en": "",
                "dr_id": "1101"
              },
              {
                "vill_id": "11010030",
                "vill_name": "ຫາດຊາຍຄູນ",
                "vill_name_en": "",
                "dr_id": "1101"
              },
              {
                "vill_id": "11010031",
                "vill_name": "ສິນໄຊ",
                "vill_name_en": "",
                "dr_id": "1101"
              },
              {
                "vill_id": "11010032",
                "vill_name": "ໂພນງາມ",
                "vill_name_en": "",
                "dr_id": "1101"
              },
              {
                "vill_id": "11010033",
                "vill_name": "ໂພນງາມ",
                "vill_name_en": "",
                "dr_id": "1101"
              },
              {
                "vill_id": "11010034",
                "vill_name": "ໂພນ​ສະ​ຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "1101"
              },
              {
                "vill_id": "11010035",
                "vill_name": "ໂພນ​ສະ​ຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "1101"
              },
              {
                "vill_id": "11010036",
                "vill_name": "ທ່ານາ",
                "vill_name_en": "",
                "dr_id": "1101"
              },
              {
                "vill_id": "11010037",
                "vill_name": "ປາກກະດັນ",
                "vill_name_en": "",
                "dr_id": "1101"
              },
              {
                "vill_id": "11010038",
                "vill_name": "ນາສົ້ມມໍ",
                "vill_name_en": "",
                "dr_id": "1101"
              },
              {
                "vill_id": "11010039",
                "vill_name": "ທົ່ງນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1101"
              },
              {
                "vill_id": "11010040",
                "vill_name": "ທົ່ງໃຫຍ່",
                "vill_name_en": "",
                "dr_id": "1101"
              },
              {
                "vill_id": "11010041",
                "vill_name": "ສີບຸນເຮືອງ",
                "vill_name_en": "",
                "dr_id": "1101"
              },
              {
                "vill_id": "11010042",
                "vill_name": "ຫນອງແວງ ",
                "vill_name_en": "",
                "dr_id": "1101"
              },
              {
                "vill_id": "11010043",
                "vill_name": "ຫນອງບົວ",
                "vill_name_en": "",
                "dr_id": "1101"
              },
              {
                "vill_id": "11010044",
                "vill_name": "ນາຫົວພູ",
                "vill_name_en": "",
                "dr_id": "1101"
              },
              {
                "vill_id": "11010045",
                "vill_name": "ນາດີ",
                "vill_name_en": "",
                "dr_id": "1101"
              },
              {
                "vill_id": "11010046",
                "vill_name": "ສອງຄອນ",
                "vill_name_en": "",
                "dr_id": "1101"
              },
              {
                "vill_id": "11010047",
                "vill_name": "ນາສະຫວັນ",
                "vill_name_en": "",
                "dr_id": "1101"
              },
              {
                "vill_id": "11010048",
                "vill_name": "ໂນນອຸດົມ",
                "vill_name_en": "",
                "dr_id": "1101"
              },
              {
                "vill_id": "11010049",
                "vill_name": "ທ່າບໍ່",
                "vill_name_en": "",
                "dr_id": "1101"
              },
              {
                "vill_id": "11010050",
                "vill_name": "ປັດຊຸມ",
                "vill_name_en": "",
                "dr_id": "1101"
              },
              {
                "vill_id": "11010051",
                "vill_name": "ໂດນ",
                "vill_name_en": "",
                "dr_id": "1101"
              },
              {
                "vill_id": "11010052",
                "vill_name": "ແກ້ງສະດອກ",
                "vill_name_en": "",
                "dr_id": "1101"
              },
              {
                "vill_id": "11010053",
                "vill_name": "ໂພນໂຮມ",
                "vill_name_en": "",
                "dr_id": "1101"
              },
              {
                "vill_id": "11010054",
                "vill_name": "ໂຫ້ວຍຊາຍ",
                "vill_name_en": "",
                "dr_id": "1101"
              },
              {
                "vill_id": "11010055",
                "vill_name": "ນາມ່ວງ",
                "vill_name_en": "",
                "dr_id": "1101"
              },
              {
                "vill_id": "11010056",
                "vill_name": "ໂພນໄຊ(ທາງແບ່ງ)",
                "vill_name_en": "",
                "dr_id": "1101"
              },
              {
                "vill_id": "11010057",
                "vill_name": "ຫນອງສາມານ",
                "vill_name_en": "",
                "dr_id": "1101"
              },
              {
                "vill_id": "11010058",
                "vill_name": "ບົງ",
                "vill_name_en": "",
                "dr_id": "1101"
              },
              {
                "vill_id": "11010059",
                "vill_name": "ຊະນະໄຊ",
                "vill_name_en": "",
                "dr_id": "1101"
              },
              {
                "vill_id": "11010060",
                "vill_name": "ຫົງໄຊ",
                "vill_name_en": "",
                "dr_id": "1101"
              },
              {
                "vill_id": "11010061",
                "vill_name": "ອານຸສອນໄຊ",
                "vill_name_en": "",
                "dr_id": "1101"
              },
              {
                "vill_id": "11010062",
                "vill_name": "ບົວແວງຄຳ",
                "vill_name_en": "Buavaengkham",
                "dr_id": "1101"
              }
            ]
          },
          {
            "dr_id": "1102",
            "dr_name": "ທ່າພະບາດ",
            "dr_name_en": "Thaphabath",
            "pr_id": "11",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "1102001",
                "vill_name": "ທ່າບົກ",
                "vill_name_en": "",
                "dr_id": "1102"
              },
              {
                "vill_id": "1102002",
                "vill_name": "ປ່າໄລ່",
                "vill_name_en": "",
                "dr_id": "1102"
              },
              {
                "vill_id": "1102003",
                "vill_name": "ນາຄຳ",
                "vill_name_en": "",
                "dr_id": "1102"
              },
              {
                "vill_id": "1102004",
                "vill_name": "ໂພນສະຫວັນ",
                "vill_name_en": "",
                "dr_id": "1102"
              },
              {
                "vill_id": "1102005",
                "vill_name": "ສີສົມໄຊ",
                "vill_name_en": "",
                "dr_id": "1102"
              },
              {
                "vill_id": "1102006",
                "vill_name": "ຫວຍເລີກ",
                "vill_name_en": "",
                "dr_id": "1102"
              },
              {
                "vill_id": "1102007",
                "vill_name": "ຫາດໄຄ",
                "vill_name_en": "",
                "dr_id": "1102"
              },
              {
                "vill_id": "1102008",
                "vill_name": "ໂພນງາມ",
                "vill_name_en": "",
                "dr_id": "1102"
              },
              {
                "vill_id": "1102009",
                "vill_name": "ຍ່າງເຄືອ",
                "vill_name_en": "",
                "dr_id": "1102"
              },
              {
                "vill_id": "1102010",
                "vill_name": "ຜາໂປງ",
                "vill_name_en": "",
                "dr_id": "1102"
              },
              {
                "vill_id": "1102011",
                "vill_name": "ອຸດົມໄຊ",
                "vill_name_en": "",
                "dr_id": "1102"
              },
              {
                "vill_id": "1102012",
                "vill_name": "ພະບາດ",
                "vill_name_en": "",
                "dr_id": "1102"
              },
              {
                "vill_id": "1102013",
                "vill_name": "ທ່າໂພນສັນ",
                "vill_name_en": "",
                "dr_id": "1102"
              },
              {
                "vill_id": "1102014",
                "vill_name": "ນາ",
                "vill_name_en": "",
                "dr_id": "1102"
              },
              {
                "vill_id": "1102015",
                "vill_name": "ສົມສະອາດ",
                "vill_name_en": "",
                "dr_id": "1102"
              },
              {
                "vill_id": "1102016",
                "vill_name": "ທວຍແປງ",
                "vill_name_en": "",
                "dr_id": "1102"
              },
              {
                "vill_id": "1102017",
                "vill_name": "ທວາຍໃໝ່",
                "vill_name_en": "",
                "dr_id": "1102"
              },
              {
                "vill_id": "1102018",
                "vill_name": "ທວາຍໃຫ່ຍ",
                "vill_name_en": "",
                "dr_id": "1102"
              },
              {
                "vill_id": "1102019",
                "vill_name": "ຫົງທອງ",
                "vill_name_en": "",
                "dr_id": "1102"
              },
              {
                "vill_id": "1102020",
                "vill_name": "ຫາດໄຊ",
                "vill_name_en": "",
                "dr_id": "1102"
              },
              {
                "vill_id": "1102021",
                "vill_name": "ນາໄຊ",
                "vill_name_en": "",
                "dr_id": "1102"
              },
              {
                "vill_id": "1102022",
                "vill_name": "ເລົ່າຄາ",
                "vill_name_en": "",
                "dr_id": "1102"
              },
              {
                "vill_id": "1102023",
                "vill_name": "ຫວຍສາຍພານ",
                "vill_name_en": "",
                "dr_id": "1102"
              },
              {
                "vill_id": "1102024",
                "vill_name": "ໄຊສະຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "1102"
              },
              {
                "vill_id": "1102025",
                "vill_name": "ປາກທວຍເໜືອ",
                "vill_name_en": "",
                "dr_id": "1102"
              },
              {
                "vill_id": "1102026",
                "vill_name": "ປາກທວາຍໃຕ້",
                "vill_name_en": "",
                "dr_id": "1102"
              },
              {
                "vill_id": "1102027",
                "vill_name": "ເວີ່ນທາດ",
                "vill_name_en": "",
                "dr_id": "1102"
              },
              {
                "vill_id": "1102028",
                "vill_name": "ໜອງເກີນ",
                "vill_name_en": "",
                "dr_id": "1102"
              },
              {
                "vill_id": "1102029",
                "vill_name": "ຍອຍໄຮ",
                "vill_name_en": "",
                "dr_id": "1102"
              },
              {
                "vill_id": "1102031",
                "vill_name": "ນໍ້າໂລ້",
                "vill_name_en": "",
                "dr_id": "1102"
              },
              {
                "vill_id": "1102032",
                "vill_name": "ດອນຄອນລາຍ",
                "vill_name_en": "",
                "dr_id": "1102"
              },
              {
                "vill_id": "1102033",
                "vill_name": "ນາແຄ",
                "vill_name_en": "",
                "dr_id": "1102"
              },
              {
                "vill_id": "1102034",
                "vill_name": "ໄຊສະຫວັນ",
                "vill_name_en": "",
                "dr_id": "1102"
              },
              {
                "vill_id": "1102035",
                "vill_name": "ນາແຄນ",
                "vill_name_en": "Nakan",
                "dr_id": "1102"
              },
              {
                "vill_id": "1102036",
                "vill_name": "ພະບາດໂພນສັນ",
                "vill_name_en": "Phabadphongsane",
                "dr_id": "1102"
              },
              {
                "vill_id": "1102037",
                "vill_name": "ບ້ານສາມະຄີໄຊ",
                "vill_name_en": "Samakhesai",
                "dr_id": "1102"
              },
              {
                "vill_id": "1102038",
                "vill_name": "ສາຍພາຍ",
                "vill_name_en": "Saiphai",
                "dr_id": "1102"
              }
            ]
          },
          {
            "dr_id": "1103",
            "dr_name": "ປາກກະດິງ",
            "dr_name_en": "Pakkading",
            "pr_id": "11",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "1103001",
                "vill_name": "ປາກກະດິງເໜືອ",
                "vill_name_en": "",
                "dr_id": "1103"
              },
              {
                "vill_id": "1103002",
                "vill_name": "ປາກກະດິງໃຕ້",
                "vill_name_en": "",
                "dr_id": "1103"
              },
              {
                "vill_id": "1103003",
                "vill_name": "ແສນສຳລານ",
                "vill_name_en": "",
                "dr_id": "1103"
              },
              {
                "vill_id": "1103004",
                "vill_name": "ປາກສາ",
                "vill_name_en": "",
                "dr_id": "1103"
              },
              {
                "vill_id": "1103005",
                "vill_name": "ນາບອນ",
                "vill_name_en": "",
                "dr_id": "1103"
              },
              {
                "vill_id": "1103006",
                "vill_name": "ບໍ່ທືນ",
                "vill_name_en": "",
                "dr_id": "1103"
              },
              {
                "vill_id": "1103007",
                "vill_name": "ປາກບາງ",
                "vill_name_en": "",
                "dr_id": "1103"
              },
              {
                "vill_id": "1103008",
                "vill_name": "ດອນໄຊ",
                "vill_name_en": "",
                "dr_id": "1103"
              },
              {
                "vill_id": "1103009",
                "vill_name": "ສົມສະໜຸກ",
                "vill_name_en": "",
                "dr_id": "1103"
              },
              {
                "vill_id": "1103010",
                "vill_name": "ຊອດ",
                "vill_name_en": "",
                "dr_id": "1103"
              },
              {
                "vill_id": "1103011",
                "vill_name": "ທ່າກັນຖິນ",
                "vill_name_en": "",
                "dr_id": "1103"
              },
              {
                "vill_id": "1103012",
                "vill_name": "ຂອນຂ້ວາງ",
                "vill_name_en": "",
                "dr_id": "1103"
              },
              {
                "vill_id": "1103013",
                "vill_name": "ປາກທອນ",
                "vill_name_en": "",
                "dr_id": "1103"
              },
              {
                "vill_id": "1103014",
                "vill_name": "ທົ່ງນານ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1103"
              },
              {
                "vill_id": "1103015",
                "vill_name": "ແກ້ງຫຼວງ",
                "vill_name_en": "",
                "dr_id": "1103"
              },
              {
                "vill_id": "1103016",
                "vill_name": "ທ່າສະອາດ",
                "vill_name_en": "",
                "dr_id": "1103"
              },
              {
                "vill_id": "1103017",
                "vill_name": "ນາຫຼວງ",
                "vill_name_en": "",
                "dr_id": "1103"
              },
              {
                "vill_id": "1103018",
                "vill_name": "ບຸ່ງກວ້າງ",
                "vill_name_en": "",
                "dr_id": "1103"
              },
              {
                "vill_id": "1103019",
                "vill_name": "ດອນ",
                "vill_name_en": "",
                "dr_id": "1103"
              },
              {
                "vill_id": "1103020",
                "vill_name": "ນາຄໍາ",
                "vill_name_en": "",
                "dr_id": "1103"
              },
              {
                "vill_id": "1103021",
                "vill_name": "ຫາດຊາຍຄຳ",
                "vill_name_en": "",
                "dr_id": "1103"
              },
              {
                "vill_id": "1103022",
                "vill_name": "ປາກຊັນ",
                "vill_name_en": "",
                "dr_id": "1103"
              },
              {
                "vill_id": "1103023",
                "vill_name": "ໂພນໄຊ",
                "vill_name_en": "",
                "dr_id": "1103"
              },
              {
                "vill_id": "1103025",
                "vill_name": "ຫວຍເລີກ",
                "vill_name_en": "",
                "dr_id": "1103"
              },
              {
                "vill_id": "1103026",
                "vill_name": "ໂພນສີ",
                "vill_name_en": "",
                "dr_id": "1103"
              },
              {
                "vill_id": "1103027",
                "vill_name": "ໂພນງາມ",
                "vill_name_en": "",
                "dr_id": "1103"
              },
              {
                "vill_id": "1103028",
                "vill_name": "ນໍ້າເດື່ອ",
                "vill_name_en": "",
                "dr_id": "1103"
              },
              {
                "vill_id": "1103029",
                "vill_name": "ນາເຄືອນອກ",
                "vill_name_en": "",
                "dr_id": "1103"
              },
              {
                "vill_id": "1103030",
                "vill_name": "ນາເຄືອ",
                "vill_name_en": "",
                "dr_id": "1103"
              },
              {
                "vill_id": "1103031",
                "vill_name": "ນາຫີນ",
                "vill_name_en": "",
                "dr_id": "1103"
              },
              {
                "vill_id": "1103032",
                "vill_name": "ທົ່ງຮາບ",
                "vill_name_en": "",
                "dr_id": "1103"
              },
              {
                "vill_id": "1103033",
                "vill_name": "ນາບວຍ",
                "vill_name_en": "",
                "dr_id": "1103"
              },
              {
                "vill_id": "1103034",
                "vill_name": "ນໍ້າຄູ່",
                "vill_name_en": "",
                "dr_id": "1103"
              },
              {
                "vill_id": "1103035",
                "vill_name": "ໜອງບົວ",
                "vill_name_en": "",
                "dr_id": "1103"
              },
              {
                "vill_id": "1103036",
                "vill_name": "ໂພນສະຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "1103"
              },
              {
                "vill_id": "1103037",
                "vill_name": "ນໍ້າສາງ",
                "vill_name_en": "",
                "dr_id": "1103"
              },
              {
                "vill_id": "1103038",
                "vill_name": "ກອງຄຳ",
                "vill_name_en": "",
                "dr_id": "1103"
              },
              {
                "vill_id": "1103039",
                "vill_name": "ວຽງຄຳ",
                "vill_name_en": "",
                "dr_id": "1103"
              },
              {
                "vill_id": "1103040",
                "vill_name": "ເລົ່າ",
                "vill_name_en": "",
                "dr_id": "1103"
              },
              {
                "vill_id": "1103041",
                "vill_name": "ນາສັງ",
                "vill_name_en": "",
                "dr_id": "1103"
              },
              {
                "vill_id": "1103042",
                "vill_name": "ຫວ້ຍໄຮ່",
                "vill_name_en": "",
                "dr_id": "1103"
              },
              {
                "vill_id": "1103043",
                "vill_name": "ຫວຍເພັດ",
                "vill_name_en": "",
                "dr_id": "1103"
              },
              {
                "vill_id": "1103044",
                "vill_name": "ນາພົງ",
                "vill_name_en": "",
                "dr_id": "1103"
              },
              {
                "vill_id": "1103045",
                "vill_name": "ນຳທອນ",
                "vill_name_en": "",
                "dr_id": "1103"
              },
              {
                "vill_id": "1103046",
                "vill_name": "ສອນເສົາ",
                "vill_name_en": "",
                "dr_id": "1103"
              },
              {
                "vill_id": "1103047",
                "vill_name": "ໂພນທອງ",
                "vill_name_en": "",
                "dr_id": "1103"
              },
              {
                "vill_id": "1103048",
                "vill_name": "ນາອິນ",
                "vill_name_en": "",
                "dr_id": "1103"
              },
              {
                "vill_id": "1103049",
                "vill_name": "ນາລ້ຽງ",
                "vill_name_en": "",
                "dr_id": "1103"
              },
              {
                "vill_id": "1103050",
                "vill_name": "ທົ່ງນາມີເໝືອ",
                "vill_name_en": "",
                "dr_id": "1103"
              },
              {
                "vill_id": "1103051",
                "vill_name": "ທົ່ງນາມີໃຕ້",
                "vill_name_en": "",
                "dr_id": "1103"
              },
              {
                "vill_id": "1103052",
                "vill_name": "ຊ່ອນພັນໄຮ່",
                "vill_name_en": "",
                "dr_id": "1103"
              },
              {
                "vill_id": "1103053",
                "vill_name": "ບ້ານຜານາລຽງ",
                "vill_name_en": "Phanalieng",
                "dr_id": "1103"
              },
              {
                "vill_id": "1103054",
                "vill_name": "ປາກຊຸນ",
                "vill_name_en": "Paksoon",
                "dr_id": "1103"
              }
            ]
          },
          {
            "dr_id": "1104",
            "dr_name": "ບໍລິຄັນ",
            "dr_name_en": "Bolikhanh",
            "pr_id": "11",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "1104001",
                "vill_name": "ວັດ​ທາດ",
                "vill_name_en": "",
                "dr_id": "1104"
              },
              {
                "vill_id": "1104002",
                "vill_name": "ໂພນ​ສີ",
                "vill_name_en": "",
                "dr_id": "1104"
              },
              {
                "vill_id": "1104003",
                "vill_name": "ສີ​ມຸງ​ຄຸນ",
                "vill_name_en": "",
                "dr_id": "1104"
              },
              {
                "vill_id": "1104004",
                "vill_name": "ສີີ​ສະ​ຫວາດ",
                "vill_name_en": "",
                "dr_id": "1104"
              },
              {
                "vill_id": "1104005",
                "vill_name": "ນາ​ແຫນ",
                "vill_name_en": "",
                "dr_id": "1104"
              },
              {
                "vill_id": "1104006",
                "vill_name": "ດອນ",
                "vill_name_en": "",
                "dr_id": "1104"
              },
              {
                "vill_id": "1104007",
                "vill_name": "ຫາດ​ຊ​ຽງ​ຕອມ",
                "vill_name_en": "",
                "dr_id": "1104"
              },
              {
                "vill_id": "1104008",
                "vill_name": "ນາ​ໂອ",
                "vill_name_en": "",
                "dr_id": "1104"
              },
              {
                "vill_id": "1104009",
                "vill_name": "ໂພນຂາມ",
                "vill_name_en": "",
                "dr_id": "1104"
              },
              {
                "vill_id": "1104010",
                "vill_name": "ຫາດ​ໂພ",
                "vill_name_en": "",
                "dr_id": "1104"
              },
              {
                "vill_id": "1104011",
                "vill_name": "ໂພນທອງ",
                "vill_name_en": "",
                "dr_id": "1104"
              },
              {
                "vill_id": "1104012",
                "vill_name": "ໂພນສົມບູນ",
                "vill_name_en": "",
                "dr_id": "1104"
              },
              {
                "vill_id": "1104013",
                "vill_name": "ພຽງ​ດີ",
                "vill_name_en": "",
                "dr_id": "1104"
              },
              {
                "vill_id": "1104023",
                "vill_name": "ຫ້ວຍ​ຄູນ",
                "vill_name_en": "",
                "dr_id": "1104"
              },
              {
                "vill_id": "1104024",
                "vill_name": "ຊົມ​ຊື່ນ",
                "vill_name_en": "",
                "dr_id": "1104"
              },
              {
                "vill_id": "1104025",
                "vill_name": "ຫາດ​ຢື້ນ",
                "vill_name_en": "",
                "dr_id": "1104"
              },
              {
                "vill_id": "1104026",
                "vill_name": "​ທ່າ​ເຮືອ",
                "vill_name_en": "",
                "dr_id": "1104"
              },
              {
                "vill_id": "1104027",
                "vill_name": "​ໂພນ​ສະ​ຫວາດ",
                "vill_name_en": "",
                "dr_id": "1104"
              },
              {
                "vill_id": "1104028",
                "vill_name": "​ນາ​ລ້ອງ",
                "vill_name_en": "",
                "dr_id": "1104"
              },
              {
                "vill_id": "1104029",
                "vill_name": "​ຫົວ​ນາ",
                "vill_name_en": "",
                "dr_id": "1104"
              },
              {
                "vill_id": "1104030",
                "vill_name": "ຜາ​ເມືອງ",
                "vill_name_en": "",
                "dr_id": "1104"
              },
              {
                "vill_id": "1104031",
                "vill_name": "ດົງ",
                "vill_name_en": "",
                "dr_id": "1104"
              },
              {
                "vill_id": "1104032",
                "vill_name": "ແຄນ​ຢ່ອງ",
                "vill_name_en": "",
                "dr_id": "1104"
              },
              {
                "vill_id": "1104033",
                "vill_name": "ປາກ​ເຫືອງ",
                "vill_name_en": "",
                "dr_id": "1104"
              },
              {
                "vill_id": "1104034",
                "vill_name": "ທ່າສີ",
                "vill_name_en": "",
                "dr_id": "1104"
              },
              {
                "vill_id": "1104035",
                "vill_name": "ນາ​ກຸນ",
                "vill_name_en": "",
                "dr_id": "1104"
              },
              {
                "vill_id": "1104036",
                "vill_name": "ຊ່ຽງ​ຊຽນ​",
                "vill_name_en": "",
                "dr_id": "1104"
              },
              {
                "vill_id": "1104037",
                "vill_name": "ຊຽງ​ລື",
                "vill_name_en": "",
                "dr_id": "1104"
              },
              {
                "vill_id": "1104038",
                "vill_name": "ນ້ຳ​ປາ",
                "vill_name_en": "",
                "dr_id": "1104"
              },
              {
                "vill_id": "1104040",
                "vill_name": "ໂພນຄຳ",
                "vill_name_en": "",
                "dr_id": "1104"
              },
              {
                "vill_id": "1104041",
                "vill_name": "ບ້ານໂພນ​ແຍງ",
                "vill_name_en": "",
                "dr_id": "1104"
              },
              {
                "vill_id": "1104042",
                "vill_name": "ໂພນ​ໄຊ",
                "vill_name_en": "",
                "dr_id": "1104"
              },
              {
                "vill_id": "1104043",
                "vill_name": "ບໍ່",
                "vill_name_en": "",
                "dr_id": "1104"
              },
              {
                "vill_id": "1104044",
                "vill_name": "ໂພນ​ສົງ",
                "vill_name_en": "",
                "dr_id": "1104"
              },
              {
                "vill_id": "1104045",
                "vill_name": "ນາ​ຫັນ",
                "vill_name_en": "",
                "dr_id": "1104"
              },
              {
                "vill_id": "1104046",
                "vill_name": "ຜາ​ໃດ",
                "vill_name_en": "",
                "dr_id": "1104"
              },
              {
                "vill_id": "1104047",
                "vill_name": "ທົ່ງ​ພັນ​ຄຳ",
                "vill_name_en": "",
                "dr_id": "1104"
              },
              {
                "vill_id": "1104048",
                "vill_name": "ທົ່ງ​ພັນ​ຄຳ",
                "vill_name_en": "",
                "dr_id": "1104"
              },
              {
                "vill_id": "1104049",
                "vill_name": "ຫີນ​ໂງ່ນ",
                "vill_name_en": "",
                "dr_id": "1104"
              },
              {
                "vill_id": "1104050",
                "vill_name": "ປາກ​ໂພຍ",
                "vill_name_en": "",
                "dr_id": "1104"
              },
              {
                "vill_id": "1104051",
                "vill_name": "ຜັກ​ເບືອກ",
                "vill_name_en": "",
                "dr_id": "1104"
              },
              {
                "vill_id": "1104052",
                "vill_name": "ກົກ​ໂພ",
                "vill_name_en": "",
                "dr_id": "1104"
              },
              {
                "vill_id": "1104053",
                "vill_name": "ຫວ້າ",
                "vill_name_en": "",
                "dr_id": "1104"
              },
              {
                "vill_id": "1104054",
                "vill_name": "ໜອງ​ແດງ",
                "vill_name_en": "",
                "dr_id": "1104"
              },
              {
                "vill_id": "1104055",
                "vill_name": "ນ້ຳ​ເຕັກ",
                "vill_name_en": "",
                "dr_id": "1104"
              },
              {
                "vill_id": "1104056",
                "vill_name": "ໂພນ​ມີໄຊ",
                "vill_name_en": "",
                "dr_id": "1104"
              },
              {
                "vill_id": "1104057",
                "vill_name": "ຫາດດອນຄູນ",
                "vill_name_en": "Harddonkeo",
                "dr_id": "1104"
              },
              {
                "vill_id": "1104058",
                "vill_name": "ໂພນມຸງຄຸນ",
                "vill_name_en": "Phonmoungkhoun",
                "dr_id": "1104"
              }
            ]
          },
          {
            "dr_id": "1105",
            "dr_name": "ຄຳເກີດ",
            "dr_name_en": "Khamkheuth",
            "pr_id": "11",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "1105001",
                "vill_name": "ຫຼັກ 10",
                "vill_name_en": "",
                "dr_id": "1105"
              },
              {
                "vill_id": "1105002",
                "vill_name": "ຫຼັກ 7 ",
                "vill_name_en": "",
                "dr_id": "1105"
              },
              {
                "vill_id": "1105003",
                "vill_name": "ຫາງ​ນາ",
                "vill_name_en": "",
                "dr_id": "1105"
              },
              {
                "vill_id": "1105004",
                "vill_name": "ນາ​ເຮືອງ",
                "vill_name_en": "",
                "dr_id": "1105"
              },
              {
                "vill_id": "1105005",
                "vill_name": "ວັັງ​ກໍ່",
                "vill_name_en": "",
                "dr_id": "1105"
              },
              {
                "vill_id": "1105006",
                "vill_name": "ທົ່ງ​ແຄະ",
                "vill_name_en": "",
                "dr_id": "1105"
              },
              {
                "vill_id": "1105007",
                "vill_name": "ນາ​ທ່ອນ",
                "vill_name_en": "",
                "dr_id": "1105"
              },
              {
                "vill_id": "1105008",
                "vill_name": "ນາ​ກະ​ດົກ",
                "vill_name_en": "",
                "dr_id": "1105"
              },
              {
                "vill_id": "1105009",
                "vill_name": "ນາ​ໄຮ",
                "vill_name_en": "",
                "dr_id": "1105"
              },
              {
                "vill_id": "1105010",
                "vill_name": "ສົົ​ບຂົມ",
                "vill_name_en": "",
                "dr_id": "1105"
              },
              {
                "vill_id": "1105011",
                "vill_name": "ໜອງ​ດົງ",
                "vill_name_en": "",
                "dr_id": "1105"
              },
              {
                "vill_id": "1105012",
                "vill_name": "ທ່າ​ແວ້ງ",
                "vill_name_en": "",
                "dr_id": "1105"
              },
              {
                "vill_id": "1105013",
                "vill_name": "ຊຳ​ເຕີຍ",
                "vill_name_en": "",
                "dr_id": "1105"
              },
              {
                "vill_id": "1105014",
                "vill_name": "ໂພນ​ໄຊ",
                "vill_name_en": "",
                "dr_id": "1105"
              },
              {
                "vill_id": "1105015",
                "vill_name": "ນາ​ຈະໄຫລ້",
                "vill_name_en": "",
                "dr_id": "1105"
              },
              {
                "vill_id": "1105016",
                "vill_name": "ສົົບ​ຍ້ວງ",
                "vill_name_en": "",
                "dr_id": "1105"
              },
              {
                "vill_id": "1105017",
                "vill_name": "ທ່າ​ບັກ",
                "vill_name_en": "",
                "dr_id": "1105"
              },
              {
                "vill_id": "1105018",
                "vill_name": "ແກ້ງ​ບິດ​",
                "vill_name_en": "",
                "dr_id": "1105"
              },
              {
                "vill_id": "1105019",
                "vill_name": "ໜອງກອກ",
                "vill_name_en": "",
                "dr_id": "1105"
              },
              {
                "vill_id": "1105020",
                "vill_name": "ໂພນ​ວິ​ໄລ",
                "vill_name_en": "",
                "dr_id": "1105"
              },
              {
                "vill_id": "1105021",
                "vill_name": "ປ່າ​ຄາ",
                "vill_name_en": "",
                "dr_id": "1105"
              },
              {
                "vill_id": "1105022",
                "vill_name": "ໂພນ​ຕານ",
                "vill_name_en": "",
                "dr_id": "1105"
              },
              {
                "vill_id": "1105023",
                "vill_name": "ກວນ​ຈັນ",
                "vill_name_en": "",
                "dr_id": "1105"
              },
              {
                "vill_id": "1105024",
                "vill_name": "ປຸ່ງປ່າຕາວ",
                "vill_name_en": "",
                "dr_id": "1105"
              },
              {
                "vill_id": "1105025",
                "vill_name": "ຜາ​ເມືອງ",
                "vill_name_en": "",
                "dr_id": "1105"
              },
              {
                "vill_id": "1105026",
                "vill_name": "ໜອງ​ຊົງ",
                "vill_name_en": "",
                "dr_id": "1105"
              },
              {
                "vill_id": "1105027",
                "vill_name": "ໂພນ​ລົມ",
                "vill_name_en": "",
                "dr_id": "1105"
              },
              {
                "vill_id": "1105028",
                "vill_name": "ວັງ​ຜາ",
                "vill_name_en": "",
                "dr_id": "1105"
              },
              {
                "vill_id": "1105029",
                "vill_name": "ໂພນ​ສີ",
                "vill_name_en": "",
                "dr_id": "1105"
              },
              {
                "vill_id": "1105030",
                "vill_name": "ກໍ​ໄຮ​",
                "vill_name_en": "",
                "dr_id": "1105"
              },
              {
                "vill_id": "1105031",
                "vill_name": "ໂພນ​ສະ​ອາດ",
                "vill_name_en": "",
                "dr_id": "1105"
              },
              {
                "vill_id": "1105032",
                "vill_name": "ໜອງ​ເມກ",
                "vill_name_en": "",
                "dr_id": "1105"
              },
              {
                "vill_id": "1105033",
                "vill_name": "ນ້ຳ​ເດີນ",
                "vill_name_en": "",
                "dr_id": "1105"
              },
              {
                "vill_id": "1105034",
                "vill_name": "ນາ​ເດື່ອ",
                "vill_name_en": "",
                "dr_id": "1105"
              },
              {
                "vill_id": "1105035",
                "vill_name": "ຫ້ວຍ​ແກ້ວ",
                "vill_name_en": "",
                "dr_id": "1105"
              },
              {
                "vill_id": "1105036",
                "vill_name": "ດອນ​ຊາດ",
                "vill_name_en": "",
                "dr_id": "1105"
              },
              {
                "vill_id": "1105037",
                "vill_name": "ສົບ​ພວນ",
                "vill_name_en": "",
                "dr_id": "1105"
              },
              {
                "vill_id": "1105038",
                "vill_name": "ໂພນທອງ",
                "vill_name_en": "",
                "dr_id": "1105"
              },
              {
                "vill_id": "1105039",
                "vill_name": "ດົງ​ບັງ",
                "vill_name_en": "",
                "dr_id": "1105"
              },
              {
                "vill_id": "1105040",
                "vill_name": "ຄຳ​ມ່ວນ",
                "vill_name_en": "",
                "dr_id": "1105"
              },
              {
                "vill_id": "1105041",
                "vill_name": "ໂພນ​ງາມ",
                "vill_name_en": "",
                "dr_id": "1105"
              },
              {
                "vill_id": "1105042",
                "vill_name": "ນາ​ງອຍ",
                "vill_name_en": "",
                "dr_id": "1105"
              },
              {
                "vill_id": "1105043",
                "vill_name": "ຫຼັກ 20",
                "vill_name_en": "",
                "dr_id": "1105"
              },
              {
                "vill_id": "1105044",
                "vill_name": "ໂພນ​ແພງ",
                "vill_name_en": "",
                "dr_id": "1105"
              },
              {
                "vill_id": "1105045",
                "vill_name": "ນ້ຳ​ພາວ",
                "vill_name_en": "",
                "dr_id": "1105"
              },
              {
                "vill_id": "1105046",
                "vill_name": "ແຈ້ງ​ສະ​ຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "1105"
              },
              {
                "vill_id": "1105047",
                "vill_name": "ແສນອຸດົມ",
                "vill_name_en": "",
                "dr_id": "1105"
              },
              {
                "vill_id": "1105048",
                "vill_name": "ໜອງ​ປອງ",
                "vill_name_en": "",
                "dr_id": "1105"
              },
              {
                "vill_id": "1105049",
                "vill_name": "ຫ້ວຍ​ແກ້ວ",
                "vill_name_en": "",
                "dr_id": "1105"
              },
              {
                "vill_id": "1105050",
                "vill_name": "ທ່າ​ສາ​ລາ",
                "vill_name_en": "",
                "dr_id": "1105"
              },
              {
                "vill_id": "1105051",
                "vill_name": "ຊອດ",
                "vill_name_en": "",
                "dr_id": "1105"
              },
              {
                "vill_id": "1105052",
                "vill_name": "ປອນ",
                "vill_name_en": "",
                "dr_id": "1105"
              },
              {
                "vill_id": "1105053",
                "vill_name": "ທົ່ງແປ",
                "vill_name_en": "",
                "dr_id": "1105"
              },
              {
                "vill_id": "1105054",
                "vill_name": "ນາ​ຄຳ",
                "vill_name_en": "",
                "dr_id": "1105"
              },
              {
                "vill_id": "1105055",
                "vill_name": "ນາ​ແປ່",
                "vill_name_en": "",
                "dr_id": "1105"
              },
              {
                "vill_id": "1105056",
                "vill_name": "ໜອງ​ອໍ້",
                "vill_name_en": "",
                "dr_id": "1105"
              },
              {
                "vill_id": "1105057",
                "vill_name": "ບຸ່ງ​ຄຳ",
                "vill_name_en": "",
                "dr_id": "1105"
              },
              {
                "vill_id": "1105058",
                "vill_name": "ທົ່ງ​ຈະ​ເລີນ",
                "vill_name_en": "",
                "dr_id": "1105"
              },
              {
                "vill_id": "1105059",
                "vill_name": "ໂພນໂຮງ",
                "vill_name_en": "",
                "dr_id": "1105"
              },
              {
                "vill_id": "1105060",
                "vill_name": "ສົມ​ສະ​ໜຸກ",
                "vill_name_en": "",
                "dr_id": "1105"
              },
              {
                "vill_id": "1105061",
                "vill_name": "ນາ​ຫາດ",
                "vill_name_en": "",
                "dr_id": "1105"
              },
              {
                "vill_id": "1105062",
                "vill_name": "ທົ່ົ່ງ​ວຽງ​ຄຳ",
                "vill_name_en": "",
                "dr_id": "1105"
              },
              {
                "vill_id": "1105063",
                "vill_name": "ນາ​ເມືອງ",
                "vill_name_en": "",
                "dr_id": "1105"
              },
              {
                "vill_id": "1105064",
                "vill_name": "ຫຼັກ 5",
                "vill_name_en": "",
                "dr_id": "1105"
              },
              {
                "vill_id": "1105065",
                "vill_name": "ນ້ຳທີ່ ( ໂພນ​ໂຮງ )",
                "vill_name_en": "",
                "dr_id": "1105"
              },
              {
                "vill_id": "1105066",
                "vill_name": "ນາ​ປ່າ​ຫວ້ານ",
                "vill_name_en": "",
                "dr_id": "1105"
              },
              {
                "vill_id": "1105067",
                "vill_name": "ໂພນ​ເມືອງ​ນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1105"
              },
              {
                "vill_id": "1105068",
                "vill_name": "ນ້ຳປານ",
                "vill_name_en": "",
                "dr_id": "1105"
              },
              {
                "vill_id": "1105069",
                "vill_name": "ແກ້ງແດງ",
                "vill_name_en": "",
                "dr_id": "1105"
              },
              {
                "vill_id": "1105070",
                "vill_name": "ທົ່ງແສນ",
                "vill_name_en": "Thongsaen",
                "dr_id": "1105"
              },
              {
                "vill_id": "1105071",
                "vill_name": "ແຍງ",
                "vill_name_en": "Yang",
                "dr_id": "1105"
              },
              {
                "vill_id": "1105072",
                "vill_name": "ບ້ານອຸດົມ",
                "vill_name_en": "Oudom",
                "dr_id": "1105"
              },
              {
                "vill_id": "1105073",
                "vill_name": "ບຸ່ງຊ້າງ",
                "vill_name_en": "Boungxang",
                "dr_id": "1105"
              },
              {
                "vill_id": "1105074",
                "vill_name": "ຫຼັກ 12",
                "vill_name_en": "Luck 12",
                "dr_id": "1105"
              },
              {
                "vill_id": "1105075",
                "vill_name": "ສົບຫອຍ",
                "vill_name_en": "Sophoy",
                "dr_id": "1105"
              },
              {
                "vill_id": "1105076",
                "vill_name": "ສົບເຮ້ຍ",
                "vill_name_en": "Sophia",
                "dr_id": "1105"
              },
              {
                "vill_id": "1105077",
                "vill_name": "ນ້ຳນຽນ",
                "vill_name_en": "Namnien",
                "dr_id": "1105"
              }
            ]
          },
          {
            "dr_id": "1106",
            "dr_name": "ວຽງທອງ",
            "dr_name_en": "Viengthong",
            "pr_id": "11",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "1106001",
                "vill_name": "ຈອມທອງ",
                "vill_name_en": "Jomtong",
                "dr_id": "1106"
              },
              {
                "vill_id": "1106002",
                "vill_name": "ນ້ຳຢ້າງ",
                "vill_name_en": "Namyang",
                "dr_id": "1106"
              },
              {
                "vill_id": "1106003",
                "vill_name": "ນ້ຳຕ້ອງ",
                "vill_name_en": "Namtong",
                "dr_id": "1106"
              },
              {
                "vill_id": "1106004",
                "vill_name": "ທ່າເດື່ອ",
                "vill_name_en": "Thadeua",
                "dr_id": "1106"
              },
              {
                "vill_id": "1106005",
                "vill_name": "ພອນຕ່າວ",
                "vill_name_en": "Phonetao",
                "dr_id": "1106"
              },
              {
                "vill_id": "1106006",
                "vill_name": "ຄອນຕ່າວ",
                "vill_name_en": "khonetao",
                "dr_id": "1106"
              },
              {
                "vill_id": "1106007",
                "vill_name": "ມີໄຊ",
                "vill_name_en": "mixay",
                "dr_id": "1106"
              },
              {
                "vill_id": "1106008",
                "vill_name": "ວຽງທອງ",
                "vill_name_en": "Viengthong",
                "dr_id": "1106"
              },
              {
                "vill_id": "1106009",
                "vill_name": "ນ້ຳສ້ຽວ",
                "vill_name_en": "namsiew",
                "dr_id": "1106"
              },
              {
                "vill_id": "1106010",
                "vill_name": "ນາຊວງ",
                "vill_name_en": "Nasuang",
                "dr_id": "1106"
              },
              {
                "vill_id": "1106011",
                "vill_name": "ນ້ຳເນີນ",
                "vill_name_en": "Numneon",
                "dr_id": "1106"
              },
              {
                "vill_id": "1106012",
                "vill_name": "ທ່າແພ",
                "vill_name_en": "Thaphae",
                "dr_id": "1106"
              },
              {
                "vill_id": "1106013",
                "vill_name": "ວັງໝາກ",
                "vill_name_en": "Vangmark",
                "dr_id": "1106"
              },
              {
                "vill_id": "1106014",
                "vill_name": "ໂພນດູ່",
                "vill_name_en": "Phondou",
                "dr_id": "1106"
              },
              {
                "vill_id": "1106015",
                "vill_name": "ສົບນາ",
                "vill_name_en": "Sobna",
                "dr_id": "1106"
              },
              {
                "vill_id": "1106016",
                "vill_name": "ກົກຂາມ",
                "vill_name_en": "Kokkham",
                "dr_id": "1106"
              },
              {
                "vill_id": "1106017",
                "vill_name": "ຫ້ວຍຫອຍ",
                "vill_name_en": "Huayhoy",
                "dr_id": "1106"
              },
              {
                "vill_id": "1106018",
                "vill_name": "ວັງຫີນ",
                "vill_name_en": "Vanghin",
                "dr_id": "1106"
              },
              {
                "vill_id": "1106019",
                "vill_name": "ອຸດົມໄຊ",
                "vill_name_en": "Oudomxai",
                "dr_id": "1106"
              },
              {
                "vill_id": "1106020",
                "vill_name": "ໜອງປາດ",
                "vill_name_en": "Nongpad",
                "dr_id": "1106"
              },
              {
                "vill_id": "1106021",
                "vill_name": "ນາດີ",
                "vill_name_en": "Nadee",
                "dr_id": "1106"
              },
              {
                "vill_id": "1106022",
                "vill_name": "ຫີນໂງ່ນ",
                "vill_name_en": "Hinngon",
                "dr_id": "1106"
              },
              {
                "vill_id": "1106023",
                "vill_name": "ນ້ຳກ້າງ",
                "vill_name_en": "Numkang",
                "dr_id": "1106"
              },
              {
                "vill_id": "1106024",
                "vill_name": "ນາວັງໄຄ້",
                "vill_name_en": "Navangkhai",
                "dr_id": "1106"
              },
              {
                "vill_id": "1106025",
                "vill_name": "ພຽງຕົ້ນ",
                "vill_name_en": "Phiengton",
                "dr_id": "1106"
              },
              {
                "vill_id": "1106026",
                "vill_name": "ສົບບັດ (ພູພຽງ)",
                "vill_name_en": "Sobbath",
                "dr_id": "1106"
              },
              {
                "vill_id": "1106027",
                "vill_name": "ກົກຕົ້ນ",
                "vill_name_en": "Kokton",
                "dr_id": "1106"
              },
              {
                "vill_id": "1106028",
                "vill_name": "ເມືອງມ່ວນ",
                "vill_name_en": "Meuangmouane",
                "dr_id": "1106"
              },
              {
                "vill_id": "1106029",
                "vill_name": "ໄຊຈະເລີນ",
                "vill_name_en": "Saichalern",
                "dr_id": "1106"
              },
              {
                "vill_id": "1106030",
                "vill_name": "ນາຕັນ",
                "vill_name_en": "Natan",
                "dr_id": "1106"
              },
              {
                "vill_id": "1106031",
                "vill_name": "ວັງພຽງ",
                "vill_name_en": "Vangphieng",
                "dr_id": "1106"
              },
              {
                "vill_id": "1106032",
                "vill_name": "ວັງແປນ",
                "vill_name_en": "Vangpan",
                "dr_id": "1106"
              },
              {
                "vill_id": "1106033",
                "vill_name": "ຜາຂອດ",
                "vill_name_en": "Phakhod",
                "dr_id": "1106"
              },
              {
                "vill_id": "1106034",
                "vill_name": "ເມືອງຊັງ (ທອງງມີໄຊ)",
                "vill_name_en": "Meuangsang",
                "dr_id": "1106"
              },
              {
                "vill_id": "1106035",
                "vill_name": "ນາເພີນ",
                "vill_name_en": "Naphern",
                "dr_id": "1106"
              },
              {
                "vill_id": "1106036",
                "vill_name": "ໄຊບົວທອງ",
                "vill_name_en": "Saibuathong",
                "dr_id": "1106"
              },
              {
                "vill_id": "1106037",
                "vill_name": "ນາຍາງ",
                "vill_name_en": "Nayang",
                "dr_id": "1106"
              },
              {
                "vill_id": "1106038",
                "vill_name": "ນາໂທ",
                "vill_name_en": "Nathoo",
                "dr_id": "1106"
              },
              {
                "vill_id": "1106039",
                "vill_name": "ນ້ຳກູ້",
                "vill_name_en": "Numkoo",
                "dr_id": "1106"
              },
              {
                "vill_id": "1106040",
                "vill_name": "ນ້ຳຈົງ (ສີວິໄລ)",
                "vill_name_en": "Numchong",
                "dr_id": "1106"
              },
              {
                "vill_id": "1106041",
                "vill_name": "ໂຮມຈະເລີນ",
                "vill_name_en": "Homchalern",
                "dr_id": "1106"
              },
              {
                "vill_id": "1106042",
                "vill_name": "ຜາບໍ່",
                "vill_name_en": "Phabor",
                "dr_id": "1106"
              },
              {
                "vill_id": "1106043",
                "vill_name": "ນ້ຳມືດ",
                "vill_name_en": "Nummeud",
                "dr_id": "1106"
              }
            ]
          },
          {
            "dr_id": "1107",
            "dr_name": "ໄຊຈຳພອນ",
            "dr_name_en": "Xaychamphone",
            "pr_id": "11",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "1107001",
                "vill_name": "ຍອດຄີ",
                "vill_name_en": "yordkhey",
                "dr_id": "1107"
              },
              {
                "vill_id": "1107002",
                "vill_name": "ຖ້າແກ",
                "vill_name_en": "Thamkae",
                "dr_id": "1107"
              },
              {
                "vill_id": "1107003",
                "vill_name": "ສົບຄີ",
                "vill_name_en": "Sobkhi",
                "dr_id": "1107"
              },
              {
                "vill_id": "1107004",
                "vill_name": "ນ້ຳປັນ",
                "vill_name_en": "Nampan",
                "dr_id": "1107"
              },
              {
                "vill_id": "1107005",
                "vill_name": "ແກ້ວແສນຄຳ",
                "vill_name_en": "KEOSAENGKHAM",
                "dr_id": "1107"
              },
              {
                "vill_id": "1107006",
                "vill_name": "ໂພນສີ",
                "vill_name_en": "PHONESY",
                "dr_id": "1107"
              }
            ]
          }
        ]
      },
      {
        "pr_id": "12",
        "pr_name": "ຄຳມ່ວນ",
        "pr_name_en": "Khammouan",
        "districts": [
          {"dr_name": "ເລືອກເມືອງປະຈຸບັນ", "villages": []},
          {
            "dr_id": "1201",
            "dr_name": "ທ່າແຂກ",
            "dr_name_en": "Thakhek",
            "pr_id": "12",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "1201001",
                "vill_name": "ໂພນຄູນ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201002",
                "vill_name": "ຫາດຄຳນ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201003",
                "vill_name": "ນາແງວ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201004",
                "vill_name": "ນາກອກ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201005",
                "vill_name": "ໂພນງາມ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201006",
                "vill_name": "ນາໄກ່ເຂ່ຍ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201007",
                "vill_name": "ສະໂງມ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201008",
                "vill_name": "ເວີນ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201009",
                "vill_name": "ກົກຕ້ອງ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201010",
                "vill_name": "ນາມ່ວງ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201011",
                "vill_name": "ນ້ຳໂດນ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201012",
                "vill_name": "ນາຕາດ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201013",
                "vill_name": "ດອນໂດນ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201014",
                "vill_name": "ໂພນສູງ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201015",
                "vill_name": "ໃຫມ່ໂພສີ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201016",
                "vill_name": "ນາຫຍ້າຫວາຍ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201017",
                "vill_name": "ໂພສີທ່າ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201018",
                "vill_name": "ນາເມືອງ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201019",
                "vill_name": "ຫ້ວຍນາງລີ້",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201020",
                "vill_name": "ຈອມເພັດ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201021",
                "vill_name": "ຈອມແກ້ວ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201022",
                "vill_name": "ຈອມແກ້ວ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201023",
                "vill_name": "ທ່າແຂກເໜືອ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201024",
                "vill_name": "ທ່າແຂກກາງ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201025",
                "vill_name": "ນາໂບ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201026",
                "vill_name": "ສົມສະອາດ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201027",
                "vill_name": "ສົມສະໜຸກ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201028",
                "vill_name": "ດອນເຄື່ອນຊ້າງ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201029",
                "vill_name": "ສີວິໄລ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201030",
                "vill_name": "ໂພນສະອາດ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201031",
                "vill_name": "ໂພນສະໜາມ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201032",
                "vill_name": "ນາບົງ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201033",
                "vill_name": "ຈອມທອງ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201034",
                "vill_name": "ສັນຕິສຸກ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201035",
                "vill_name": "ສຸກສະຫວັນ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201036",
                "vill_name": "ວຽງວິໄລ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201037",
                "vill_name": "ໜອງບົວຄຳ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201038",
                "vill_name": "ປາກດົງ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201039",
                "vill_name": "ໂພນພິມ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201040",
                "vill_name": "ດອນມ່ວງ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201041",
                "vill_name": "ນາບວບ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201042",
                "vill_name": "ກຸງສີ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201043",
                "vill_name": "ນາໂດນ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201044",
                "vill_name": "ນາມະລາດ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201045",
                "vill_name": "ຍາງໂກ່ມ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201046",
                "vill_name": "ໂຄກເຫັດ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201047",
                "vill_name": "ມ່ວງລາດຄວາຍ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201048",
                "vill_name": "ໂພນເຍັຍໃຫຍ່",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201049",
                "vill_name": "ໂພນເຍັຍນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201050",
                "vill_name": "ຄຳຂີ້ໄກ່",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201051",
                "vill_name": "ຍາງເກົ່າ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201052",
                "vill_name": "ນາກຸ່ມ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201053",
                "vill_name": "ດົງກະແສນ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201054",
                "vill_name": "ຄຳບຸ່ນ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201055",
                "vill_name": "ນາບໍ່",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201056",
                "vill_name": "ໂຄກຊ້າງ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201057",
                "vill_name": "ຊຽງແລ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201058",
                "vill_name": "ນາກາງ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201059",
                "vill_name": "ໜອງຫ້າງ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201060",
                "vill_name": "ໂພນໄຊ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201061",
                "vill_name": "ປາກເປັ່ງ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201062",
                "vill_name": "ດອນທົ່ງ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201063",
                "vill_name": "ນາດິນຈີ່",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201064",
                "vill_name": "ນາໂພທ່າ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201065",
                "vill_name": "ດົງໃຕ້",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201066",
                "vill_name": "ທ່າງາມ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201067",
                "vill_name": "ທ່າເດືອ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201068",
                "vill_name": "ດອນມະໄຫລ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201069",
                "vill_name": "ຫນອງຜື",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201070",
                "vill_name": "ມ່ວງສຸມ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201071",
                "vill_name": "ໜອງໝ້ຽງ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201072",
                "vill_name": "ປາກສີມັງ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201073",
                "vill_name": "ເລົ້າໂພນຄຳ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201074",
                "vill_name": "ທ່າແຂກໃຕ້",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201075",
                "vill_name": "ຈອມແຈ້ງ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201076",
                "vill_name": "ເລົ່າໂພໄຊ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201077",
                "vill_name": "ເລົ່າໂພຄຳ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201078",
                "vill_name": "ດົງມ່ວງໄຂ່",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201079",
                "vill_name": "ຕານ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201080",
                "vill_name": "ເລົ່າງົວ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201081",
                "vill_name": "ຊຽງແຫວນ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201082",
                "vill_name": "ຖ້ຳ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201083",
                "vill_name": "ທ່າແຄ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201084",
                "vill_name": "ນາຂ້າງຊ້າງ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201085",
                "vill_name": "ສອງຄອນ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201086",
                "vill_name": "ພີນ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201087",
                "vill_name": "ນາຍໍ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201088",
                "vill_name": "ນາດູ່",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201089",
                "vill_name": "ນາເສ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201090",
                "vill_name": "ດ່ອຍ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201091",
                "vill_name": "ຜາແຫລມ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201092",
                "vill_name": "ໂນນສະຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201093",
                "vill_name": "ນາສະອາດ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201094",
                "vill_name": "ມະຫາໄຊ​​​",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201095",
                "vill_name": "ນາສະອາດ",
                "vill_name_en": "",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201096",
                "vill_name": "ລຳມະລາດ",
                "vill_name_en": "Lammalard",
                "dr_id": "1201"
              },
              {
                "vill_id": "1201097",
                "vill_name": "ໜອງປີງ",
                "vill_name_en": "Nongping",
                "dr_id": "1201"
              }
            ]
          },
          {
            "dr_id": "1202",
            "dr_name": "ມະຫາໄຊ",
            "dr_name_en": "Mahaxay",
            "pr_id": "12",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "1202001",
                "vill_name": "ບ້ານມະຫາໄຊ​​​",
                "vill_name_en": "",
                "dr_id": "1202"
              },
              {
                "vill_id": "1202002",
                "vill_name": "ໂພວາໃຕ້",
                "vill_name_en": "",
                "dr_id": "1202"
              },
              {
                "vill_id": "1202003",
                "vill_name": "ຜານາງ",
                "vill_name_en": "",
                "dr_id": "1202"
              },
              {
                "vill_id": "1202004",
                "vill_name": "ປົ່ງ",
                "vill_name_en": "",
                "dr_id": "1202"
              },
              {
                "vill_id": "1202005",
                "vill_name": "ແກ້ງສະຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "1202"
              },
              {
                "vill_id": "1202006",
                "vill_name": "ຜາຈູມຄ້ອງ",
                "vill_name_en": "",
                "dr_id": "1202"
              },
              {
                "vill_id": "1202007",
                "vill_name": "ໂພນສະອາດ",
                "vill_name_en": "",
                "dr_id": "1202"
              },
              {
                "vill_id": "1202008",
                "vill_name": "ນາກອກຫລັກ7",
                "vill_name_en": "",
                "dr_id": "1202"
              },
              {
                "vill_id": "1202009",
                "vill_name": "ໂພນຄຳ",
                "vill_name_en": "",
                "dr_id": "1202"
              },
              {
                "vill_id": "1202010",
                "vill_name": "ນາແສ",
                "vill_name_en": "",
                "dr_id": "1202"
              },
              {
                "vill_id": "1202011",
                "vill_name": "ປາກຊ່ອງ",
                "vill_name_en": "",
                "dr_id": "1202"
              },
              {
                "vill_id": "1202012",
                "vill_name": "ນາຕຸງ",
                "vill_name_en": "",
                "dr_id": "1202"
              },
              {
                "vill_id": "1202013",
                "vill_name": "ນາກອກໃນ",
                "vill_name_en": "",
                "dr_id": "1202"
              },
              {
                "vill_id": "1202014",
                "vill_name": "ຫລັກ9",
                "vill_name_en": "",
                "dr_id": "1202"
              },
              {
                "vill_id": "1202015",
                "vill_name": "ແຕນ",
                "vill_name_en": "",
                "dr_id": "1202"
              },
              {
                "vill_id": "1202016",
                "vill_name": "ນາດີ",
                "vill_name_en": "",
                "dr_id": "1202"
              },
              {
                "vill_id": "1202017",
                "vill_name": "ກວນຄວາຍ",
                "vill_name_en": "",
                "dr_id": "1202"
              },
              {
                "vill_id": "1202018",
                "vill_name": "ໂພນໄລ່",
                "vill_name_en": "",
                "dr_id": "1202"
              },
              {
                "vill_id": "1202019",
                "vill_name": "ນາດູ່",
                "vill_name_en": "",
                "dr_id": "1202"
              },
              {
                "vill_id": "1202020",
                "vill_name": "ນາຄຳ",
                "vill_name_en": "",
                "dr_id": "1202"
              },
              {
                "vill_id": "1202021",
                "vill_name": "ເຫລົ່າ",
                "vill_name_en": "",
                "dr_id": "1202"
              },
              {
                "vill_id": "1202022",
                "vill_name": "ນາຮີ",
                "vill_name_en": "",
                "dr_id": "1202"
              },
              {
                "vill_id": "1202023",
                "vill_name": "ເວີນ",
                "vill_name_en": "",
                "dr_id": "1202"
              },
              {
                "vill_id": "1202024",
                "vill_name": "ອີ່ລານ",
                "vill_name_en": "",
                "dr_id": "1202"
              },
              {
                "vill_id": "1202025",
                "vill_name": "ດ້າງ ( ດ້າງກາງ )",
                "vill_name_en": "",
                "dr_id": "1202"
              },
              {
                "vill_id": "1202026",
                "vill_name": "ສົມສະໜຸກ",
                "vill_name_en": "",
                "dr_id": "1202"
              },
              {
                "vill_id": "1202027",
                "vill_name": "ນາກຽວ",
                "vill_name_en": "",
                "dr_id": "1202"
              },
              {
                "vill_id": "1202028",
                "vill_name": "ກະວະ",
                "vill_name_en": "",
                "dr_id": "1202"
              },
              {
                "vill_id": "1202029",
                "vill_name": "ຜາກິ່ວ",
                "vill_name_en": "",
                "dr_id": "1202"
              },
              {
                "vill_id": "1202030",
                "vill_name": "ຜາແຄນ",
                "vill_name_en": "",
                "dr_id": "1202"
              },
              {
                "vill_id": "1202031",
                "vill_name": "ນາຈານ",
                "vill_name_en": "",
                "dr_id": "1202"
              },
              {
                "vill_id": "1202032",
                "vill_name": "ນາແຄ",
                "vill_name_en": "",
                "dr_id": "1202"
              },
              {
                "vill_id": "1202033",
                "vill_name": "ໂພນສະໝຶນ",
                "vill_name_en": "",
                "dr_id": "1202"
              },
              {
                "vill_id": "1202034",
                "vill_name": "ກວນຫລວງ",
                "vill_name_en": "",
                "dr_id": "1202"
              },
              {
                "vill_id": "1202035",
                "vill_name": "ຄຳຜາແຫລງ",
                "vill_name_en": "",
                "dr_id": "1202"
              },
              {
                "vill_id": "1202036",
                "vill_name": "ໂພນນາດີ",
                "vill_name_en": "",
                "dr_id": "1202"
              },
              {
                "vill_id": "1202037",
                "vill_name": "ຊອງ",
                "vill_name_en": "",
                "dr_id": "1202"
              },
              {
                "vill_id": "1202038",
                "vill_name": "ໂນນແດງ",
                "vill_name_en": "",
                "dr_id": "1202"
              },
              {
                "vill_id": "1202039",
                "vill_name": "ຄຳແພດົງ",
                "vill_name_en": "",
                "dr_id": "1202"
              },
              {
                "vill_id": "1202040",
                "vill_name": "ນາງິ້ວ",
                "vill_name_en": "",
                "dr_id": "1202"
              },
              {
                "vill_id": "1202041",
                "vill_name": "ນາທ່ອນ",
                "vill_name_en": "",
                "dr_id": "1202"
              },
              {
                "vill_id": "1202042",
                "vill_name": "ຄຳເຟືອງ",
                "vill_name_en": "",
                "dr_id": "1202"
              },
              {
                "vill_id": "1202043",
                "vill_name": "ຄຳແພນາ",
                "vill_name_en": "",
                "dr_id": "1202"
              },
              {
                "vill_id": "1202044",
                "vill_name": "ຄຳແພໃຫຍ່",
                "vill_name_en": "",
                "dr_id": "1202"
              },
              {
                "vill_id": "1202045",
                "vill_name": "ໃໝ່ຍ່",
                "vill_name_en": "",
                "dr_id": "1202"
              },
              {
                "vill_id": "1202046",
                "vill_name": "ນ້ຳມະລາ",
                "vill_name_en": "",
                "dr_id": "1202"
              },
              {
                "vill_id": "1202047",
                "vill_name": "ດອນທົ່ງ",
                "vill_name_en": "",
                "dr_id": "1202"
              },
              {
                "vill_id": "1202048",
                "vill_name": "ດອນດົງ",
                "vill_name_en": "",
                "dr_id": "1202"
              },
              {
                "vill_id": "1202049",
                "vill_name": "ປ່າໜາມ",
                "vill_name_en": "",
                "dr_id": "1202"
              },
              {
                "vill_id": "1202050",
                "vill_name": "ນາໝາກບ້າ",
                "vill_name_en": "",
                "dr_id": "1202"
              },
              {
                "vill_id": "1202051",
                "vill_name": "ນາສະຖຸງ",
                "vill_name_en": "",
                "dr_id": "1202"
              },
              {
                "vill_id": "1202052",
                "vill_name": "ຈະລູມ",
                "vill_name_en": "",
                "dr_id": "1202"
              },
              {
                "vill_id": "1202053",
                "vill_name": "ນາກະທ້າງທົ່ງ",
                "vill_name_en": "",
                "dr_id": "1202"
              },
              {
                "vill_id": "1202054",
                "vill_name": "ໂພນໄຊ",
                "vill_name_en": "",
                "dr_id": "1202"
              },
              {
                "vill_id": "1202055",
                "vill_name": "ນາກະທ້າງດົງ",
                "vill_name_en": "",
                "dr_id": "1202"
              },
              {
                "vill_id": "1202056",
                "vill_name": "ກວນຕັນ",
                "vill_name_en": "",
                "dr_id": "1202"
              },
              {
                "vill_id": "1202057",
                "vill_name": "ນາປວກ",
                "vill_name_en": "",
                "dr_id": "1202"
              },
              {
                "vill_id": "1202058",
                "vill_name": "ນາຕະພອຍ",
                "vill_name_en": "",
                "dr_id": "1202"
              },
              {
                "vill_id": "1202059",
                "vill_name": "ໂພນເຫລັກ",
                "vill_name_en": "",
                "dr_id": "1202"
              },
              {
                "vill_id": "1202060",
                "vill_name": "ສ້າງພອກ",
                "vill_name_en": "",
                "dr_id": "1202"
              },
              {
                "vill_id": "1202061",
                "vill_name": "ໝັ້ນ",
                "vill_name_en": "",
                "dr_id": "1202"
              },
              {
                "vill_id": "1202062",
                "vill_name": "ໂພນເມືອງ",
                "vill_name_en": "",
                "dr_id": "1202"
              },
              {
                "vill_id": "1202063",
                "vill_name": "ໂພນແດງ",
                "vill_name_en": "",
                "dr_id": "1202"
              },
              {
                "vill_id": "1202064",
                "vill_name": "ບ້ານທົ່ງກວ້າງ",
                "vill_name_en": "",
                "dr_id": "1202"
              },
              {
                "vill_id": "1202065",
                "vill_name": "ໄຮ່ເໜືອ",
                "vill_name_en": "",
                "dr_id": "1202"
              },
              {
                "vill_id": "1202066",
                "vill_name": "ກາວາກ",
                "vill_name_en": "",
                "dr_id": "1202"
              },
              {
                "vill_id": "1202067",
                "vill_name": "ນາຜາແກ້ວ",
                "vill_name_en": "",
                "dr_id": "1202"
              },
              {
                "vill_id": "1202068",
                "vill_name": "ດົງໝາກໄຟ",
                "vill_name_en": "",
                "dr_id": "1202"
              },
              {
                "vill_id": "1202069",
                "vill_name": "ມະຫາໄຊ",
                "vill_name_en": "",
                "dr_id": "1202"
              },
              {
                "vill_id": "1202070",
                "vill_name": "ໂພວາ",
                "vill_name_en": "",
                "dr_id": "1202"
              },
              {
                "vill_id": "1202071",
                "vill_name": "ນາທານ",
                "vill_name_en": "",
                "dr_id": "1202"
              },
              {
                "vill_id": "1202072",
                "vill_name": "ແຕງ",
                "vill_name_en": "",
                "dr_id": "1202"
              }
            ]
          },
          {
            "dr_id": "1203",
            "dr_name": "ໜອງບົກ",
            "dr_name_en": "Nongbok",
            "pr_id": "12",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "1203001",
                "vill_name": "ປົ້ງກິ່ວ",
                "vill_name_en": "",
                "dr_id": "1203"
              },
              {
                "vill_id": "1203002",
                "vill_name": "ໂນນສີລາ",
                "vill_name_en": "",
                "dr_id": "1203"
              },
              {
                "vill_id": "1203003",
                "vill_name": "ນາຈຳປາ",
                "vill_name_en": "",
                "dr_id": "1203"
              },
              {
                "vill_id": "1203004",
                "vill_name": "ນາຫູນ",
                "vill_name_en": "",
                "dr_id": "1203"
              },
              {
                "vill_id": "1203005",
                "vill_name": "ໂພນແພງ",
                "vill_name_en": "",
                "dr_id": "1203"
              },
              {
                "vill_id": "1203006",
                "vill_name": "ໂນນແຕ້",
                "vill_name_en": "",
                "dr_id": "1203"
              },
              {
                "vill_id": "1203007",
                "vill_name": "ນານ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1203"
              },
              {
                "vill_id": "1203008",
                "vill_name": "ໜອງຜຳ",
                "vill_name_en": "",
                "dr_id": "1203"
              },
              {
                "vill_id": "1203009",
                "vill_name": "ດົງຄຸງ",
                "vill_name_en": "",
                "dr_id": "1203"
              },
              {
                "vill_id": "1203010",
                "vill_name": "ໂນນຈິກ",
                "vill_name_en": "",
                "dr_id": "1203"
              },
              {
                "vill_id": "1203011",
                "vill_name": "ນາຄຳ",
                "vill_name_en": "",
                "dr_id": "1203"
              },
              {
                "vill_id": "1203012",
                "vill_name": "ນ້ຳພຸ",
                "vill_name_en": "",
                "dr_id": "1203"
              },
              {
                "vill_id": "1203013",
                "vill_name": "ດົງກະສິນ",
                "vill_name_en": "",
                "dr_id": "1203"
              },
              {
                "vill_id": "1203014",
                "vill_name": "ດົງປູນ",
                "vill_name_en": "",
                "dr_id": "1203"
              },
              {
                "vill_id": "1203015",
                "vill_name": "ກຸດຈັບ",
                "vill_name_en": "",
                "dr_id": "1203"
              },
              {
                "vill_id": "1203016",
                "vill_name": "ສອງໜອງເໜືອ",
                "vill_name_en": "",
                "dr_id": "1203"
              },
              {
                "vill_id": "1203017",
                "vill_name": "ສອງໜອງໃຕ້",
                "vill_name_en": "",
                "dr_id": "1203"
              },
              {
                "vill_id": "1203018",
                "vill_name": "ຊຽງຫວາງທ່າ",
                "vill_name_en": "",
                "dr_id": "1203"
              },
              {
                "vill_id": "1203019",
                "vill_name": "ຊຽງຫວາງທົ່ງ",
                "vill_name_en": "",
                "dr_id": "1203"
              },
              {
                "vill_id": "1203020",
                "vill_name": "ຊຽງຫວາງທົ່ງ",
                "vill_name_en": "",
                "dr_id": "1203"
              },
              {
                "vill_id": "1203021",
                "vill_name": "ໜອງປາລາດ",
                "vill_name_en": "",
                "dr_id": "1203"
              },
              {
                "vill_id": "1203022",
                "vill_name": "ດົງຍາງ",
                "vill_name_en": "",
                "dr_id": "1203"
              },
              {
                "vill_id": "1203023",
                "vill_name": "ດົງຂວາງ",
                "vill_name_en": "",
                "dr_id": "1203"
              },
              {
                "vill_id": "1203024",
                "vill_name": "ໂພນ",
                "vill_name_en": "",
                "dr_id": "1203"
              },
              {
                "vill_id": "1203025",
                "vill_name": "ໜອງບົກ",
                "vill_name_en": "",
                "dr_id": "1203"
              },
              {
                "vill_id": "1203026",
                "vill_name": "ນາມັນປາ",
                "vill_name_en": "",
                "dr_id": "1203"
              },
              {
                "vill_id": "1203027",
                "vill_name": "ສອກເບາະ",
                "vill_name_en": "",
                "dr_id": "1203"
              },
              {
                "vill_id": "1203028",
                "vill_name": "ນາໃຕ້",
                "vill_name_en": "",
                "dr_id": "1203"
              },
              {
                "vill_id": "1203029",
                "vill_name": "ຜັກອີ່ຕູ່",
                "vill_name_en": "",
                "dr_id": "1203"
              },
              {
                "vill_id": "1203030",
                "vill_name": "ດົງສະຫງຳ",
                "vill_name_en": "",
                "dr_id": "1203"
              },
              {
                "vill_id": "1203031",
                "vill_name": "ຫາດຊຽງດີ",
                "vill_name_en": "",
                "dr_id": "1203"
              },
              {
                "vill_id": "1203032",
                "vill_name": "ບຶງສານທ່າ",
                "vill_name_en": "",
                "dr_id": "1203"
              },
              {
                "vill_id": "1203033",
                "vill_name": "ບຶງສານເທິງ",
                "vill_name_en": "",
                "dr_id": "1203"
              },
              {
                "vill_id": "1203034",
                "vill_name": "ດອນຂຽວເໜືອ",
                "vill_name_en": "",
                "dr_id": "1203"
              },
              {
                "vill_id": "1203035",
                "vill_name": "ມ່ວງໄຂ່",
                "vill_name_en": "",
                "dr_id": "1203"
              },
              {
                "vill_id": "1203036",
                "vill_name": "ດອນຂຽວໃຕ້",
                "vill_name_en": "",
                "dr_id": "1203"
              },
              {
                "vill_id": "1203037",
                "vill_name": "ໜອງຫລົ່ມ",
                "vill_name_en": "",
                "dr_id": "1203"
              },
              {
                "vill_id": "1203038",
                "vill_name": "ດ່ານປາກເຊ",
                "vill_name_en": "",
                "dr_id": "1203"
              },
              {
                "vill_id": "1203039",
                "vill_name": "ດອນໝາກໂມ",
                "vill_name_en": "",
                "dr_id": "1203"
              },
              {
                "vill_id": "1203040",
                "vill_name": "ໂຄກກ່ອງ",
                "vill_name_en": "",
                "dr_id": "1203"
              },
              {
                "vill_id": "1203041",
                "vill_name": "ດົງພັງເພົາ",
                "vill_name_en": "",
                "dr_id": "1203"
              },
              {
                "vill_id": "1203042",
                "vill_name": "ໂພນສາວເອ້",
                "vill_name_en": "",
                "dr_id": "1203"
              },
              {
                "vill_id": "1203043",
                "vill_name": "ທ່າມ່ວງ",
                "vill_name_en": "",
                "dr_id": "1203"
              },
              {
                "vill_id": "1203044",
                "vill_name": "ສະດີໄຕ້",
                "vill_name_en": "",
                "dr_id": "1203"
              },
              {
                "vill_id": "1203045",
                "vill_name": "ສະດື",
                "vill_name_en": "",
                "dr_id": "1203"
              },
              {
                "vill_id": "1203046",
                "vill_name": "ນາວາງນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1203"
              },
              {
                "vill_id": "1203047",
                "vill_name": "ນາວາງທົ່ງ",
                "vill_name_en": "",
                "dr_id": "1203"
              },
              {
                "vill_id": "1203048",
                "vill_name": "ຊຳນາດີ",
                "vill_name_en": "",
                "dr_id": "1203"
              },
              {
                "vill_id": "1203049",
                "vill_name": "ດົງຜັກເພື້ອ",
                "vill_name_en": "",
                "dr_id": "1203"
              },
              {
                "vill_id": "1203050",
                "vill_name": "ຫາດຊາຍຟອງ",
                "vill_name_en": "",
                "dr_id": "1203"
              },
              {
                "vill_id": "1203051",
                "vill_name": "ນອງສະພັງທົ່ງ",
                "vill_name_en": "",
                "dr_id": "1203"
              },
              {
                "vill_id": "1203052",
                "vill_name": "ຕານເທິງ",
                "vill_name_en": "",
                "dr_id": "1203"
              },
              {
                "vill_id": "1203053",
                "vill_name": "ໜອງລີ",
                "vill_name_en": "",
                "dr_id": "1203"
              },
              {
                "vill_id": "1203054",
                "vill_name": "ໂຄກສະຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "1203"
              },
              {
                "vill_id": "1203055",
                "vill_name": "ດອນປາແດກ",
                "vill_name_en": "",
                "dr_id": "1203"
              },
              {
                "vill_id": "1203056",
                "vill_name": "ດົງບູນ",
                "vill_name_en": "",
                "dr_id": "1203"
              },
              {
                "vill_id": "1203057",
                "vill_name": "ສະດື",
                "vill_name_en": "",
                "dr_id": "1203"
              },
              {
                "vill_id": "1203058",
                "vill_name": "ນາວາງ",
                "vill_name_en": "",
                "dr_id": "1203"
              }
            ]
          },
          {
            "dr_id": "1204",
            "dr_name": "ຫີນບູນ",
            "dr_name_en": "Hinboon",
            "pr_id": "12",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "1204001",
                "vill_name": "ອໍ້",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204002",
                "vill_name": "ຍາງ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204003",
                "vill_name": "ກູບ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204004",
                "vill_name": "ມ່ວງເໜືອ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204005",
                "vill_name": "ໂພນແຍງ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204006",
                "vill_name": "ນ້ຳນອນ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204007",
                "vill_name": "ແກ້ງ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204008",
                "vill_name": "ນາຄຳ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204009",
                "vill_name": "ນາເມືອງ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204010",
                "vill_name": "ນາມະຮາງ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204011",
                "vill_name": "ນາຊ້າງຂ້າມ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204012",
                "vill_name": "ຜາຄ້ອງ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204013",
                "vill_name": "ມ່ວງນ້ຳສ້າງ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204014",
                "vill_name": "ໜອງຮາງ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204015",
                "vill_name": "ໂພນທອງ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204016",
                "vill_name": "ຄູນຄຳ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204017",
                "vill_name": "ຄູນເງິນ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204018",
                "vill_name": "ນາຂາມ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204019",
                "vill_name": "ທົ່ງລົມ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204020",
                "vill_name": "ນາຄົກ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204021",
                "vill_name": "ຝັ່ງແດງ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204022",
                "vill_name": "ນາຄາ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204023",
                "vill_name": "ຜາຮາງເໜືອ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204024",
                "vill_name": "ຜາຮາງໃຕ້",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204025",
                "vill_name": "ຫ້ວຍເປືອກ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204026",
                "vill_name": "ທ່ານາເໜືອ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204027",
                "vill_name": "ທ່ານາໃຕ້",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204028",
                "vill_name": "ຜາຈົວ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204029",
                "vill_name": "ວັງມົນ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204030",
                "vill_name": "ບ້ານຊ່າງ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204031",
                "vill_name": "ສອງຄອນ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204032",
                "vill_name": "ປາກທຶກ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204033",
                "vill_name": "ປາກແວ່ງ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204034",
                "vill_name": "ໜອງດົງ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204035",
                "vill_name": "ນາເຕີຍ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204036",
                "vill_name": "ໜອງບົວໃຫຍ່",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204037",
                "vill_name": "ຫີນຄັນ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204038",
                "vill_name": "ໂພນໝັ້ນ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204039",
                "vill_name": "ໜອງຈັງ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204040",
                "vill_name": "ໜອງຂຸ່ນ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204041",
                "vill_name": "ນາໂນ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204042",
                "vill_name": "ນາຮ່າງ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204043",
                "vill_name": "ບົງ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204044",
                "vill_name": "ຜີແປງ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204045",
                "vill_name": "ນາເມືອງບໍ່",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204046",
                "vill_name": "ນາອັ້ນ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204047",
                "vill_name": "ນາຮີ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204048",
                "vill_name": "ຫ້ວຍຊາຍນາຮີ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204049",
                "vill_name": "ຫົວນາ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204050",
                "vill_name": "ບວມລູ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204051",
                "vill_name": "ກວນກະຈາ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204052",
                "vill_name": "ບ້ານໂພນຕີ້ວ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204053",
                "vill_name": "ນາໂດນ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204054",
                "vill_name": "ນາໂດນ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204055",
                "vill_name": "ມ່ວງໄຂ່ໃຕ້",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204056",
                "vill_name": "ມ່ວງໄຂ່ກາງ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204057",
                "vill_name": "ມ່ວງໄຂ່ເໜືອ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204058",
                "vill_name": "ນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204059",
                "vill_name": "ນາຄຳບໍ່",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204060",
                "vill_name": "ບໍ່ແໜ່ງ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204061",
                "vill_name": "ທ່າຍໍ້",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204062",
                "vill_name": "ວັງເຜີ້ງ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204063",
                "vill_name": "ນາສາ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204064",
                "vill_name": "ຊາວ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204065",
                "vill_name": "ຜາແຫລມ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204066",
                "vill_name": "ໜອງຊື່ນ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204067",
                "vill_name": "ທົ່ງຄາ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204068",
                "vill_name": "ທ່າໝີ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204069",
                "vill_name": "ນາຄີ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204070",
                "vill_name": "ຜາແດງ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204071",
                "vill_name": "ໂພນຂາມ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204072",
                "vill_name": "ນ້ຳດິກ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204073",
                "vill_name": "ໂຮມພັນ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204074",
                "vill_name": "ນາໂພ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204075",
                "vill_name": "ນາທານ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204076",
                "vill_name": "ໂພຄ້ຳ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204077",
                "vill_name": "ນາຂໍ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204078",
                "vill_name": "ຫາດແຮ່",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204079",
                "vill_name": "ຫີນບູນໃຕ້",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204080",
                "vill_name": "ປຸ່ງ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204081",
                "vill_name": "ດອນດູ່",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204082",
                "vill_name": "ຕ້ອນນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204083",
                "vill_name": "ໜອງຜື",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204084",
                "vill_name": "ໂພນຄ້ຳ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204085",
                "vill_name": "ໜອງບົວນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204086",
                "vill_name": "ໃໝ່ນ້ຳປະກັນ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204087",
                "vill_name": "ສອງຫ້ອງ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204088",
                "vill_name": "ສາມັກຄີໄຊ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204089",
                "vill_name": "ຄຳແກ້ວ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204090",
                "vill_name": "ສີຊົມຊື່ນ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204091",
                "vill_name": "ແກ້ງພັກຄຳ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204092",
                "vill_name": "ໜອງຫອຍ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204093",
                "vill_name": "ວັງຫົວປາ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204094",
                "vill_name": "ປາກປະກັນ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204095",
                "vill_name": "ໂພນມ່ວງ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204097",
                "vill_name": "ຫີນບູນເໜືອງ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204098",
                "vill_name": "ຜາວັງ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204099",
                "vill_name": "ບ້ານໂພນສະຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204100",
                "vill_name": "ຫ້ວຍສະກູບ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204101",
                "vill_name": "ໜອງຫລວງ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204102",
                "vill_name": "ຫາດນາງກ້ອມ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204103",
                "vill_name": "ຫ້ວຍເຂົ້າໝີ້ນໃຫຍ່",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204104",
                "vill_name": "ຫ້ວຍຂະໝິ້ນ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204105",
                "vill_name": "ດົງກາງ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204106",
                "vill_name": "ນາບອນ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204107",
                "vill_name": "ຫາດໃຫຍ່",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204108",
                "vill_name": "ຫົວຫາດ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204109",
                "vill_name": "ໃຫມ່ສີວິໄລ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204110",
                "vill_name": "ຫ້ວຍບອນ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204111",
                "vill_name": "ນາກະແດ້ງ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204112",
                "vill_name": "ຫ້ວຍກະວະ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204113",
                "vill_name": "ຫ້ວຍກະຫວະເໜືອ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204115",
                "vill_name": "ທ່າສະຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204116",
                "vill_name": "ຫ້ວຍຂຽວ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204117",
                "vill_name": "ດອນລັບ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204118",
                "vill_name": "ຫ້ວຍຕິ້ວ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204119",
                "vill_name": "ທ່າສົ້ມໂຮງ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204120",
                "vill_name": "ປຸ່ງ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204121",
                "vill_name": "ນາເໜືອ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204122",
                "vill_name": "ຫ້ວຍກະຊະ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204123",
                "vill_name": "ໂພນຄໍ້",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204124",
                "vill_name": "ພົງ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204125",
                "vill_name": "ພົງໃຕ້",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204126",
                "vill_name": "ຫາດຊາຍຄຳ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204127",
                "vill_name": "ຫາດຊາຍຄຳ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204128",
                "vill_name": "ຫີນລາດ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204129",
                "vill_name": "ແມງ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204130",
                "vill_name": "ສີວິໄລ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204131",
                "vill_name": "ໂພນດີ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204132",
                "vill_name": "ວຽງທອງ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204133",
                "vill_name": "ໂພນໄຊ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204134",
                "vill_name": "ກະແຕບ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204135",
                "vill_name": "ເລົ່າຄາ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204136",
                "vill_name": "ໂພນສະອາດ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204137",
                "vill_name": "ໂພນສູງ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204138",
                "vill_name": "ດານຮີ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204139",
                "vill_name": "ຫ້ວຍເຮືອ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204140",
                "vill_name": "ນາເຮືອງ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204141",
                "vill_name": "ນາເຮືອງ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204142",
                "vill_name": "ໄຊສົມບູນ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204143",
                "vill_name": "ກອງລໍ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204144",
                "vill_name": "ນາກາງ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204145",
                "vill_name": "ນ້ຳສະນາມ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204146",
                "vill_name": "ພູຊາດ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204147",
                "vill_name": "ພູຫມາກແຫນ່ງ",
                "vill_name_en": "",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204148",
                "vill_name": "ບ້ານແກ້ງຄົດ",
                "vill_name_en": "kengkhod",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204149",
                "vill_name": "ຫີນບູນ",
                "vill_name_en": "Hinboun",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204150",
                "vill_name": "ໂພນຕິ້ວ",
                "vill_name_en": "Phonetea",
                "dr_id": "1204"
              },
              {
                "vill_id": "1204151",
                "vill_name": "ນາຫ້າງ",
                "vill_name_en": "Nahang",
                "dr_id": "1204"
              }
            ]
          },
          {
            "dr_id": "1205",
            "dr_name": "ຍົມມະລາດ",
            "dr_name_en": "Nhommalath",
            "pr_id": "12",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "1205001",
                "vill_name": "ເຫລົ່າ ( ເລົ່ານາງາມ )",
                "vill_name_en": "",
                "dr_id": "1205"
              },
              {
                "vill_id": "1205002",
                "vill_name": "ນາໄຟໃຫມ້ ( ນາມີໄຊ )",
                "vill_name_en": "",
                "dr_id": "1205"
              },
              {
                "vill_id": "1205003",
                "vill_name": "ຍົມມະລາດເໜືອ",
                "vill_name_en": "",
                "dr_id": "1205"
              },
              {
                "vill_id": "1205004",
                "vill_name": "ຍົມມະລາດໃຕ້",
                "vill_name_en": "",
                "dr_id": "1205"
              },
              {
                "vill_id": "1205005",
                "vill_name": "ໜອງປີງ",
                "vill_name_en": "",
                "dr_id": "1205"
              },
              {
                "vill_id": "1205006",
                "vill_name": "ໂພນສະຫວັນ",
                "vill_name_en": "",
                "dr_id": "1205"
              },
              {
                "vill_id": "1205007",
                "vill_name": "ນາເທີດ",
                "vill_name_en": "",
                "dr_id": "1205"
              },
              {
                "vill_id": "1205008",
                "vill_name": "ແກ້ງເຫລັກ",
                "vill_name_en": "",
                "dr_id": "1205"
              },
              {
                "vill_id": "1205009",
                "vill_name": "ຫ້ວຍເຢັນ",
                "vill_name_en": "",
                "dr_id": "1205"
              },
              {
                "vill_id": "1205010",
                "vill_name": "ບຸ່ງເບົາ",
                "vill_name_en": "",
                "dr_id": "1205"
              },
              {
                "vill_id": "1205011",
                "vill_name": "ໂພນແສດ",
                "vill_name_en": "",
                "dr_id": "1205"
              },
              {
                "vill_id": "1205012",
                "vill_name": "ນາກະຕັງ",
                "vill_name_en": "",
                "dr_id": "1205"
              },
              {
                "vill_id": "1205013",
                "vill_name": "ດອນເປືອຍ",
                "vill_name_en": "",
                "dr_id": "1205"
              },
              {
                "vill_id": "1205014",
                "vill_name": "ຜາທຸງ",
                "vill_name_en": "",
                "dr_id": "1205"
              },
              {
                "vill_id": "1205015",
                "vill_name": "ໂຄກສະຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "1205"
              },
              {
                "vill_id": "1205016",
                "vill_name": "ກວນພັນ",
                "vill_name_en": "",
                "dr_id": "1205"
              },
              {
                "vill_id": "1205017",
                "vill_name": "ພີດສີໄຄ",
                "vill_name_en": "",
                "dr_id": "1205"
              },
              {
                "vill_id": "1205018",
                "vill_name": "ໂພນສັງ",
                "vill_name_en": "",
                "dr_id": "1205"
              },
              {
                "vill_id": "1205019",
                "vill_name": "ຕາດ ( ຫ້ວຍຕາດ )",
                "vill_name_en": "",
                "dr_id": "1205"
              },
              {
                "vill_id": "1205020",
                "vill_name": "ປອມຄູນ",
                "vill_name_en": "",
                "dr_id": "1205"
              },
              {
                "vill_id": "1205021",
                "vill_name": "ໂພນສີ",
                "vill_name_en": "",
                "dr_id": "1205"
              },
              {
                "vill_id": "1205022",
                "vill_name": "ໂພນນາດ",
                "vill_name_en": "",
                "dr_id": "1205"
              },
              {
                "vill_id": "1205023",
                "vill_name": "ນາແດນ",
                "vill_name_en": "",
                "dr_id": "1205"
              },
              {
                "vill_id": "1205024",
                "vill_name": "ເຕືອນ ( ນາເຕືອນໃຈ )",
                "vill_name_en": "",
                "dr_id": "1205"
              },
              {
                "vill_id": "1205025",
                "vill_name": "ໂພນແກ້ວ",
                "vill_name_en": "",
                "dr_id": "1205"
              },
              {
                "vill_id": "1205026",
                "vill_name": "ຫາດຊອນ",
                "vill_name_en": "",
                "dr_id": "1205"
              },
              {
                "vill_id": "1205027",
                "vill_name": "ແກ້ງມ່ວງ",
                "vill_name_en": "",
                "dr_id": "1205"
              },
              {
                "vill_id": "1205028",
                "vill_name": "ຈະກ້ວນ",
                "vill_name_en": "",
                "dr_id": "1205"
              },
              {
                "vill_id": "1205029",
                "vill_name": "ທ່າໂຈ້ນ",
                "vill_name_en": "",
                "dr_id": "1205"
              },
              {
                "vill_id": "1205030",
                "vill_name": "ທົ່ງກອງ",
                "vill_name_en": "",
                "dr_id": "1205"
              },
              {
                "vill_id": "1205031",
                "vill_name": "ນາບໍ່",
                "vill_name_en": "",
                "dr_id": "1205"
              },
              {
                "vill_id": "1205032",
                "vill_name": "ຄຳແຮ່",
                "vill_name_en": "",
                "dr_id": "1205"
              },
              {
                "vill_id": "1205033",
                "vill_name": "ຫາງການ",
                "vill_name_en": "",
                "dr_id": "1205"
              },
              {
                "vill_id": "1205034",
                "vill_name": "ຊຽງດາວ",
                "vill_name_en": "",
                "dr_id": "1205"
              },
              {
                "vill_id": "1205035",
                "vill_name": "ສ້າງ-ປຸ່ງບອນ",
                "vill_name_en": "",
                "dr_id": "1205"
              },
              {
                "vill_id": "1205036",
                "vill_name": "ຕະຫລາກ",
                "vill_name_en": "",
                "dr_id": "1205"
              },
              {
                "vill_id": "1205037",
                "vill_name": "ທ່າໂທດ",
                "vill_name_en": "",
                "dr_id": "1205"
              },
              {
                "vill_id": "1205038",
                "vill_name": "ນາລາດຄວາຍ",
                "vill_name_en": "",
                "dr_id": "1205"
              },
              {
                "vill_id": "1205039",
                "vill_name": "ສ້າງແກ້ວ",
                "vill_name_en": "",
                "dr_id": "1205"
              },
              {
                "vill_id": "1205040",
                "vill_name": "ທາດ",
                "vill_name_en": "",
                "dr_id": "1205"
              },
              {
                "vill_id": "1205041",
                "vill_name": "ເຫມືອງ",
                "vill_name_en": "",
                "dr_id": "1205"
              },
              {
                "vill_id": "1205042",
                "vill_name": "ນາໂຊກ",
                "vill_name_en": "",
                "dr_id": "1205"
              },
              {
                "vill_id": "1205043",
                "vill_name": "ຫລວງ",
                "vill_name_en": "",
                "dr_id": "1205"
              },
              {
                "vill_id": "1205044",
                "vill_name": "ໄຮ່",
                "vill_name_en": "",
                "dr_id": "1205"
              },
              {
                "vill_id": "1205045",
                "vill_name": "ໂພນຄຳ",
                "vill_name_en": "",
                "dr_id": "1205"
              },
              {
                "vill_id": "1205046",
                "vill_name": "ຖ້ຳພວງ",
                "vill_name_en": "",
                "dr_id": "1205"
              },
              {
                "vill_id": "1205047",
                "vill_name": "ສົມສະໜຸກ",
                "vill_name_en": "Songsanouk",
                "dr_id": "1205"
              }
            ]
          },
          {
            "dr_id": "1206",
            "dr_name": "ບົວລະພາ",
            "dr_name_en": "Bualapha",
            "pr_id": "12",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "1206001",
                "vill_name": "ນາປຸງ",
                "vill_name_en": "",
                "dr_id": "1206"
              },
              {
                "vill_id": "1206002",
                "vill_name": "ນາພະນັງ",
                "vill_name_en": "",
                "dr_id": "1206"
              },
              {
                "vill_id": "1206003",
                "vill_name": "ທ່າແຮ່",
                "vill_name_en": "",
                "dr_id": "1206"
              },
              {
                "vill_id": "1206004",
                "vill_name": "ນາສົມບູນ",
                "vill_name_en": "",
                "dr_id": "1206"
              },
              {
                "vill_id": "1206005",
                "vill_name": "ປ່າກ້ວຍໃຕ້",
                "vill_name_en": "",
                "dr_id": "1206"
              },
              {
                "vill_id": "1206006",
                "vill_name": "ທ່າກະຈັນ",
                "vill_name_en": "",
                "dr_id": "1206"
              },
              {
                "vill_id": "1206007",
                "vill_name": "ນາເປງ",
                "vill_name_en": "",
                "dr_id": "1206"
              },
              {
                "vill_id": "1206008",
                "vill_name": "ສົບເປງ",
                "vill_name_en": "",
                "dr_id": "1206"
              },
              {
                "vill_id": "1206009",
                "vill_name": "ນາປາງ",
                "vill_name_en": "",
                "dr_id": "1206"
              },
              {
                "vill_id": "1206010",
                "vill_name": "ປາກພະນັງ",
                "vill_name_en": "",
                "dr_id": "1206"
              },
              {
                "vill_id": "1206011",
                "vill_name": "ໂພນສ້າງໄພ",
                "vill_name_en": "",
                "dr_id": "1206"
              },
              {
                "vill_id": "1206012",
                "vill_name": "ຫຍ້າແວດ",
                "vill_name_en": "",
                "dr_id": "1206"
              },
              {
                "vill_id": "1206013",
                "vill_name": "ຕະຫລອງ",
                "vill_name_en": "",
                "dr_id": "1206"
              },
              {
                "vill_id": "1206014",
                "vill_name": "ສະອາງ",
                "vill_name_en": "",
                "dr_id": "1206"
              },
              {
                "vill_id": "1206015",
                "vill_name": "ຄະຍູ້",
                "vill_name_en": "",
                "dr_id": "1206"
              },
              {
                "vill_id": "1206016",
                "vill_name": "ກາງ",
                "vill_name_en": "",
                "dr_id": "1206"
              },
              {
                "vill_id": "1206017",
                "vill_name": "ແກ້ງຫຍ້າລວນ",
                "vill_name_en": "",
                "dr_id": "1206"
              },
              {
                "vill_id": "1206018",
                "vill_name": "ບ້ານໂສຍ",
                "vill_name_en": "",
                "dr_id": "1206"
              },
              {
                "vill_id": "1206019",
                "vill_name": "ແສນພັນ",
                "vill_name_en": "",
                "dr_id": "1206"
              },
              {
                "vill_id": "1206020",
                "vill_name": "ແກ້ງວັກ",
                "vill_name_en": "",
                "dr_id": "1206"
              },
              {
                "vill_id": "1206022",
                "vill_name": "ກະແຕ້",
                "vill_name_en": "",
                "dr_id": "1206"
              },
              {
                "vill_id": "1206023",
                "vill_name": "ນາຕັ້ງໃຈ",
                "vill_name_en": "",
                "dr_id": "1206"
              },
              {
                "vill_id": "1206024",
                "vill_name": "ກະຈາມນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1206"
              },
              {
                "vill_id": "1206025",
                "vill_name": "ບ້ານຊອກ",
                "vill_name_en": "",
                "dr_id": "1206"
              },
              {
                "vill_id": "1206026",
                "vill_name": "ກະຈາມໃຫຍ່",
                "vill_name_en": "",
                "dr_id": "1206"
              },
              {
                "vill_id": "1206027",
                "vill_name": "ຫຍ້າຫວາຍ",
                "vill_name_en": "",
                "dr_id": "1206"
              },
              {
                "vill_id": "1206028",
                "vill_name": "ນາຄະໂຍມ",
                "vill_name_en": "",
                "dr_id": "1206"
              },
              {
                "vill_id": "1206029",
                "vill_name": "ທົ່ງຂາມ",
                "vill_name_en": "",
                "dr_id": "1206"
              },
              {
                "vill_id": "1206030",
                "vill_name": "ບ້ານໂພນ",
                "vill_name_en": "",
                "dr_id": "1206"
              },
              {
                "vill_id": "1206031",
                "vill_name": "ບ້ານທາດ",
                "vill_name_en": "",
                "dr_id": "1206"
              },
              {
                "vill_id": "1206032",
                "vill_name": "ນາຈັດ",
                "vill_name_en": "",
                "dr_id": "1206"
              },
              {
                "vill_id": "1206033",
                "vill_name": "ຫລັງຄັງ",
                "vill_name_en": "",
                "dr_id": "1206"
              },
              {
                "vill_id": "1206034",
                "vill_name": "ນາຫອມ",
                "vill_name_en": "",
                "dr_id": "1206"
              },
              {
                "vill_id": "1206035",
                "vill_name": "ໜອງບົວ",
                "vill_name_en": "",
                "dr_id": "1206"
              },
              {
                "vill_id": "1206036",
                "vill_name": "ຜານົບ",
                "vill_name_en": "",
                "dr_id": "1206"
              },
              {
                "vill_id": "1206037",
                "vill_name": "ວັງຄົມ",
                "vill_name_en": "",
                "dr_id": "1206"
              },
              {
                "vill_id": "1206038",
                "vill_name": "ໜອງປີງ",
                "vill_name_en": "",
                "dr_id": "1206"
              },
              {
                "vill_id": "1206039",
                "vill_name": "ໜອງແສງ",
                "vill_name_en": "",
                "dr_id": "1206"
              },
              {
                "vill_id": "1206040",
                "vill_name": "ໜອງໂນ",
                "vill_name_en": "",
                "dr_id": "1206"
              },
              {
                "vill_id": "1206041",
                "vill_name": "ບ້ານດຸ",
                "vill_name_en": "",
                "dr_id": "1206"
              },
              {
                "vill_id": "1206042",
                "vill_name": "ວັງມະເນີ",
                "vill_name_en": "",
                "dr_id": "1206"
              },
              {
                "vill_id": "1206043",
                "vill_name": "ທົ່ງຊຳ",
                "vill_name_en": "",
                "dr_id": "1206"
              },
              {
                "vill_id": "1206044",
                "vill_name": "ທ່າພະໂຈ້ມ",
                "vill_name_en": "",
                "dr_id": "1206"
              },
              {
                "vill_id": "1206045",
                "vill_name": "ໂຄກ",
                "vill_name_en": "",
                "dr_id": "1206"
              },
              {
                "vill_id": "1206046",
                "vill_name": "ຜາຂຽດ",
                "vill_name_en": "",
                "dr_id": "1206"
              },
              {
                "vill_id": "1206047",
                "vill_name": "ຫ້ວຍທ່າ",
                "vill_name_en": "",
                "dr_id": "1206"
              },
              {
                "vill_id": "1206048",
                "vill_name": "ບ້ານເຄາະ",
                "vill_name_en": "",
                "dr_id": "1206"
              },
              {
                "vill_id": "1206049",
                "vill_name": "ບ້ານລາຍ",
                "vill_name_en": "",
                "dr_id": "1206"
              },
              {
                "vill_id": "1206050",
                "vill_name": "ປ່າກ້ວຍເໜືອ",
                "vill_name_en": "",
                "dr_id": "1206"
              },
              {
                "vill_id": "1206051",
                "vill_name": "ນາສະລອນ",
                "vill_name_en": "",
                "dr_id": "1206"
              },
              {
                "vill_id": "1206052",
                "vill_name": "ມຸກ",
                "vill_name_en": "",
                "dr_id": "1206"
              },
              {
                "vill_id": "1206053",
                "vill_name": "ແກ້ງຕາປາ",
                "vill_name_en": "",
                "dr_id": "1206"
              },
              {
                "vill_id": "1206054",
                "vill_name": "ໜອງໜ່ຽວ",
                "vill_name_en": "",
                "dr_id": "1206"
              },
              {
                "vill_id": "1206055",
                "vill_name": "ໜອງກະປາດ",
                "vill_name_en": "",
                "dr_id": "1206"
              },
              {
                "vill_id": "1206056",
                "vill_name": "ວັງເງືອກ",
                "vill_name_en": "",
                "dr_id": "1206"
              },
              {
                "vill_id": "1206057",
                "vill_name": "ນ້ຳອອກຮູ",
                "vill_name_en": "",
                "dr_id": "1206"
              },
              {
                "vill_id": "1206058",
                "vill_name": "ກະແຕະ",
                "vill_name_en": "",
                "dr_id": "1206"
              },
              {
                "vill_id": "1206059",
                "vill_name": "ຫີນລັບ",
                "vill_name_en": "",
                "dr_id": "1206"
              },
              {
                "vill_id": "1206060",
                "vill_name": "ລະຈັງ",
                "vill_name_en": "",
                "dr_id": "1206"
              },
              {
                "vill_id": "1206061",
                "vill_name": "ຈະແລດ",
                "vill_name_en": "",
                "dr_id": "1206"
              },
              {
                "vill_id": "1206062",
                "vill_name": "ໂຮງ",
                "vill_name_en": "",
                "dr_id": "1206"
              },
              {
                "vill_id": "1206063",
                "vill_name": "ວັງຫລວງ",
                "vill_name_en": "",
                "dr_id": "1206"
              },
              {
                "vill_id": "1206064",
                "vill_name": "ໜອງກຸ້ງ",
                "vill_name_en": "",
                "dr_id": "1206"
              },
              {
                "vill_id": "1206065",
                "vill_name": "ບ້ານລາດ",
                "vill_name_en": "",
                "dr_id": "1206"
              },
              {
                "vill_id": "1206066",
                "vill_name": "ບ້ານທັບລາວ",
                "vill_name_en": "",
                "dr_id": "1206"
              },
              {
                "vill_id": "1206067",
                "vill_name": "ກະອີ",
                "vill_name_en": "",
                "dr_id": "1206"
              },
              {
                "vill_id": "1206068",
                "vill_name": "ນ້ຳຈະຫລາ",
                "vill_name_en": "",
                "dr_id": "1206"
              },
              {
                "vill_id": "1206069",
                "vill_name": "ລະໂບຍາ",
                "vill_name_en": "",
                "dr_id": "1206"
              },
              {
                "vill_id": "1206070",
                "vill_name": "ໜອງນ້າ",
                "vill_name_en": "",
                "dr_id": "1206"
              },
              {
                "vill_id": "1206071",
                "vill_name": "ປາຊິວ",
                "vill_name_en": "",
                "dr_id": "1206"
              },
              {
                "vill_id": "1206072",
                "vill_name": "ຄວາຍແມບ",
                "vill_name_en": "",
                "dr_id": "1206"
              },
              {
                "vill_id": "1206073",
                "vill_name": "ງຶກ",
                "vill_name_en": "",
                "dr_id": "1206"
              },
              {
                "vill_id": "1206074",
                "vill_name": "ຈະໂນນ",
                "vill_name_en": "",
                "dr_id": "1206"
              },
              {
                "vill_id": "1206075",
                "vill_name": "ນາພຽງລ້ານ",
                "vill_name_en": "",
                "dr_id": "1206"
              },
              {
                "vill_id": "1206076",
                "vill_name": "ຫ້ວຍແຮດ",
                "vill_name_en": "",
                "dr_id": "1206"
              },
              {
                "vill_id": "1206077",
                "vill_name": "ປາເກິນ",
                "vill_name_en": "",
                "dr_id": "1206"
              },
              {
                "vill_id": "1206078",
                "vill_name": "ທາງແບ່ງ",
                "vill_name_en": "",
                "dr_id": "1206"
              }
            ]
          },
          {
            "dr_id": "1207",
            "dr_name": "ນາກາຍ",
            "dr_name_en": "Nakai",
            "pr_id": "12",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "1207001",
                "vill_name": "ອຸດົມສຸກ",
                "vill_name_en": "",
                "dr_id": "1207"
              },
              {
                "vill_id": "1207002",
                "vill_name": "ນາກາຍ",
                "vill_name_en": "",
                "dr_id": "1207"
              },
              {
                "vill_id": "1207003",
                "vill_name": "ຫ້ວຍມາໄລ",
                "vill_name_en": "",
                "dr_id": "1207"
              },
              {
                "vill_id": "1207004",
                "vill_name": "ບົວມາ",
                "vill_name_en": "",
                "dr_id": "1207"
              },
              {
                "vill_id": "1207005",
                "vill_name": "ດອນ",
                "vill_name_en": "",
                "dr_id": "1207"
              },
              {
                "vill_id": "1207006",
                "vill_name": "ຂອນແກ່ນ",
                "vill_name_en": "",
                "dr_id": "1207"
              },
              {
                "vill_id": "1207007",
                "vill_name": "ທົ່ງ",
                "vill_name_en": "",
                "dr_id": "1207"
              },
              {
                "vill_id": "1207008",
                "vill_name": "ເມືອງຫຼວງ",
                "vill_name_en": "",
                "dr_id": "1207"
              },
              {
                "vill_id": "1207009",
                "vill_name": "ຍາງ",
                "vill_name_en": "",
                "dr_id": "1207"
              },
              {
                "vill_id": "1207010",
                "vill_name": "ກວນ",
                "vill_name_en": "",
                "dr_id": "1207"
              },
              {
                "vill_id": "1207011",
                "vill_name": "ນາບອນ",
                "vill_name_en": "",
                "dr_id": "1207"
              },
              {
                "vill_id": "1207012",
                "vill_name": "ຜ່າດາງ",
                "vill_name_en": "",
                "dr_id": "1207"
              },
              {
                "vill_id": "1207013",
                "vill_name": "ນາຫ້າງ",
                "vill_name_en": "",
                "dr_id": "1207"
              },
              {
                "vill_id": "1207015",
                "vill_name": "ນາຕານ",
                "vill_name_en": "",
                "dr_id": "1207"
              },
              {
                "vill_id": "1207016",
                "vill_name": "ນາວາງ",
                "vill_name_en": "",
                "dr_id": "1207"
              },
              {
                "vill_id": "1207017",
                "vill_name": "ນາຫາວ",
                "vill_name_en": "",
                "dr_id": "1207"
              },
              {
                "vill_id": "1207018",
                "vill_name": "ໜາກເຟຶອງ",
                "vill_name_en": "",
                "dr_id": "1207"
              },
              {
                "vill_id": "1207019",
                "vill_name": "ທ່າໄຜ່ບ້ານ",
                "vill_name_en": "",
                "dr_id": "1207"
              },
              {
                "vill_id": "1207020",
                "vill_name": "ທົ່ງນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1207"
              },
              {
                "vill_id": "1207021",
                "vill_name": "ເຕີງ",
                "vill_name_en": "",
                "dr_id": "1207"
              },
              {
                "vill_id": "1207022",
                "vill_name": "ມະກາ",
                "vill_name_en": "",
                "dr_id": "1207"
              },
              {
                "vill_id": "1207023",
                "vill_name": "ນາເມິຍ",
                "vill_name_en": "",
                "dr_id": "1207"
              },
              {
                "vill_id": "1207024",
                "vill_name": "ບ້ານຫວ້ຍມະໄລ",
                "vill_name_en": "",
                "dr_id": "1207"
              },
              {
                "vill_id": "1207025",
                "vill_name": "ດອນຈະເລີນ",
                "vill_name_en": "",
                "dr_id": "1207"
              },
              {
                "vill_id": "1207026",
                "vill_name": "ໜອງຮວມ",
                "vill_name_en": "",
                "dr_id": "1207"
              },
              {
                "vill_id": "1207027",
                "vill_name": "ໂພນສະອອນ",
                "vill_name_en": "",
                "dr_id": "1207"
              },
              {
                "vill_id": "1207028",
                "vill_name": "ຝັງແດງ",
                "vill_name_en": "",
                "dr_id": "1207"
              },
              {
                "vill_id": "1207029",
                "vill_name": "ວັງເທີນຄຳ",
                "vill_name_en": "",
                "dr_id": "1207"
              },
              {
                "vill_id": "1207030",
                "vill_name": "ປຸ່ງສານ",
                "vill_name_en": "",
                "dr_id": "1207"
              },
              {
                "vill_id": "1207031",
                "vill_name": "ປຸ່ງສານ",
                "vill_name_en": "",
                "dr_id": "1207"
              },
              {
                "vill_id": "1207032",
                "vill_name": "ສົບຄຳ",
                "vill_name_en": "",
                "dr_id": "1207"
              },
              {
                "vill_id": "1207033",
                "vill_name": "ທ່າລັງ",
                "vill_name_en": "Thalang",
                "dr_id": "1207"
              }
            ]
          },
          {
            "dr_id": "1208",
            "dr_name": "ເຊບັ້ງໄຟ",
            "dr_name_en": "Xebangfay",
            "pr_id": "12",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "1208001",
                "vill_name": "ຂົວເຊ",
                "vill_name_en": "",
                "dr_id": "1208"
              },
              {
                "vill_id": "1208002",
                "vill_name": "ໜອງບອນ",
                "vill_name_en": "",
                "dr_id": "1208"
              },
              {
                "vill_id": "1208003",
                "vill_name": "ຕຶງ",
                "vill_name_en": "",
                "dr_id": "1208"
              },
              {
                "vill_id": "1208004",
                "vill_name": "ດົງໝາກບ້າ",
                "vill_name_en": "",
                "dr_id": "1208"
              },
              {
                "vill_id": "1208005",
                "vill_name": "ຫາດເພັກ",
                "vill_name_en": "",
                "dr_id": "1208"
              },
              {
                "vill_id": "1208006",
                "vill_name": "ເວີນສະນັ່ນ",
                "vill_name_en": "",
                "dr_id": "1208"
              },
              {
                "vill_id": "1208007",
                "vill_name": "ເຊນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1208"
              },
              {
                "vill_id": "1208008",
                "vill_name": "ທ່າສີດາ",
                "vill_name_en": "",
                "dr_id": "1208"
              },
              {
                "vill_id": "1208009",
                "vill_name": "ນາທານ",
                "vill_name_en": "",
                "dr_id": "1208"
              },
              {
                "vill_id": "1208010",
                "vill_name": "ນາຊອຍ",
                "vill_name_en": "",
                "dr_id": "1208"
              },
              {
                "vill_id": "1208011",
                "vill_name": "ດ້າງທ່າ",
                "vill_name_en": "",
                "dr_id": "1208"
              },
              {
                "vill_id": "1208012",
                "vill_name": "ຍາງຄຳ",
                "vill_name_en": "",
                "dr_id": "1208"
              },
              {
                "vill_id": "1208013",
                "vill_name": "ຫາດຄຳຮຽງ",
                "vill_name_en": "",
                "dr_id": "1208"
              },
              {
                "vill_id": "1208015",
                "vill_name": "ສົ້ມ",
                "vill_name_en": "",
                "dr_id": "1208"
              },
              {
                "vill_id": "1208016",
                "vill_name": "ນາຄອມເກົ່າ",
                "vill_name_en": "",
                "dr_id": "1208"
              },
              {
                "vill_id": "1208017",
                "vill_name": "ນາຄອມທົ່ງ",
                "vill_name_en": "",
                "dr_id": "1208"
              },
              {
                "vill_id": "1208018",
                "vill_name": "ໂຄກແກ້ງແຄນ",
                "vill_name_en": "",
                "dr_id": "1208"
              },
              {
                "vill_id": "1208019",
                "vill_name": "ກະສີ",
                "vill_name_en": "",
                "dr_id": "1208"
              },
              {
                "vill_id": "1208020",
                "vill_name": "ແກ້ງແຄນ",
                "vill_name_en": "",
                "dr_id": "1208"
              },
              {
                "vill_id": "1208021",
                "vill_name": "ໜອງພັງ",
                "vill_name_en": "",
                "dr_id": "1208"
              },
              {
                "vill_id": "1208022",
                "vill_name": "ໂພນດີທົ່ງ",
                "vill_name_en": "",
                "dr_id": "1208"
              },
              {
                "vill_id": "1208023",
                "vill_name": "ສັງ",
                "vill_name_en": "",
                "dr_id": "1208"
              },
              {
                "vill_id": "1208024",
                "vill_name": "ນາບຶງ",
                "vill_name_en": "",
                "dr_id": "1208"
              },
              {
                "vill_id": "1208025",
                "vill_name": "ຫ້ວຍລ້າງມື",
                "vill_name_en": "",
                "dr_id": "1208"
              },
              {
                "vill_id": "1208026",
                "vill_name": "ນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1208"
              },
              {
                "vill_id": "1208027",
                "vill_name": "ກົກຕ້ອງ",
                "vill_name_en": "",
                "dr_id": "1208"
              },
              {
                "vill_id": "1208028",
                "vill_name": "ຖ້ຳລາຍ",
                "vill_name_en": "",
                "dr_id": "1208"
              },
              {
                "vill_id": "1208029",
                "vill_name": "ສ້ອມ",
                "vill_name_en": "",
                "dr_id": "1208"
              },
              {
                "vill_id": "1208030",
                "vill_name": "ແກ້ງແປ",
                "vill_name_en": "",
                "dr_id": "1208"
              },
              {
                "vill_id": "1208031",
                "vill_name": "ທ່າຄໍ້",
                "vill_name_en": "",
                "dr_id": "1208"
              },
              {
                "vill_id": "1208032",
                "vill_name": "ທ່າຫາດ",
                "vill_name_en": "",
                "dr_id": "1208"
              },
              {
                "vill_id": "1208033",
                "vill_name": "ສອກບໍ",
                "vill_name_en": "",
                "dr_id": "1208"
              },
              {
                "vill_id": "1208034",
                "vill_name": "ກະພະ",
                "vill_name_en": "",
                "dr_id": "1208"
              },
              {
                "vill_id": "1208035",
                "vill_name": "ໂຄກຂີ້ໝິ້ນ",
                "vill_name_en": "",
                "dr_id": "1208"
              },
              {
                "vill_id": "1208036",
                "vill_name": "ໂຄກສະຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "1208"
              },
              {
                "vill_id": "1208037",
                "vill_name": "ທັບ",
                "vill_name_en": "",
                "dr_id": "1208"
              },
              {
                "vill_id": "1208038",
                "vill_name": "ທັບ",
                "vill_name_en": "",
                "dr_id": "1208"
              },
              {
                "vill_id": "1208039",
                "vill_name": "ດອນສະອາດ",
                "vill_name_en": "",
                "dr_id": "1208"
              },
              {
                "vill_id": "1208040",
                "vill_name": "ຄຳເຕີຍ",
                "vill_name_en": "",
                "dr_id": "1208"
              },
              {
                "vill_id": "1208041",
                "vill_name": "ປົ່ງແດງ",
                "vill_name_en": "",
                "dr_id": "1208"
              },
              {
                "vill_id": "1208042",
                "vill_name": "ໂນນກະແຕ້",
                "vill_name_en": "",
                "dr_id": "1208"
              },
              {
                "vill_id": "1208043",
                "vill_name": "ວັງເດືອນຫ້າ",
                "vill_name_en": "",
                "dr_id": "1208"
              },
              {
                "vill_id": "1208044",
                "vill_name": "ເວີນຄຳຂຽວ",
                "vill_name_en": "",
                "dr_id": "1208"
              },
              {
                "vill_id": "1208045",
                "vill_name": "ບຶງຫົວນາ",
                "vill_name_en": "",
                "dr_id": "1208"
              },
              {
                "vill_id": "1208046",
                "vill_name": "ນາພອກ",
                "vill_name_en": "",
                "dr_id": "1208"
              }
            ]
          },
          {
            "dr_id": "1209",
            "dr_name": "ໄຊບົວທອງ",
            "dr_name_en": "Xaybuathong",
            "pr_id": "12",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "1209001",
                "vill_name": "ປ່າກ້ວຍດົງ",
                "vill_name_en": "",
                "dr_id": "1209"
              },
              {
                "vill_id": "1209002",
                "vill_name": "ປ່າກ້ວຍທົ່ງ",
                "vill_name_en": "",
                "dr_id": "1209"
              },
              {
                "vill_id": "1209003",
                "vill_name": "ນາກະດອນ",
                "vill_name_en": "",
                "dr_id": "1209"
              },
              {
                "vill_id": "1209004",
                "vill_name": "ນາຫລວງ",
                "vill_name_en": "",
                "dr_id": "1209"
              },
              {
                "vill_id": "1209005",
                "vill_name": "ຕໍ່ເຮືອ",
                "vill_name_en": "",
                "dr_id": "1209"
              },
              {
                "vill_id": "1209006",
                "vill_name": "ສີວິໄລ",
                "vill_name_en": "",
                "dr_id": "1209"
              },
              {
                "vill_id": "1209007",
                "vill_name": "ຜາຫອຍ",
                "vill_name_en": "",
                "dr_id": "1209"
              },
              {
                "vill_id": "1209008",
                "vill_name": "ໜອງທາດ",
                "vill_name_en": "",
                "dr_id": "1209"
              },
              {
                "vill_id": "1209009",
                "vill_name": "ໂພນສະຫວັນ",
                "vill_name_en": "",
                "dr_id": "1209"
              },
              {
                "vill_id": "1209010",
                "vill_name": "ນາຄຳພັນ",
                "vill_name_en": "",
                "dr_id": "1209"
              },
              {
                "vill_id": "1209011",
                "vill_name": "ນາປ່າຄ່າ",
                "vill_name_en": "",
                "dr_id": "1209"
              },
              {
                "vill_id": "1209012",
                "vill_name": "ແກ້ງຈອນ",
                "vill_name_en": "",
                "dr_id": "1209"
              },
              {
                "vill_id": "1209013",
                "vill_name": "ກະແສ",
                "vill_name_en": "",
                "dr_id": "1209"
              },
              {
                "vill_id": "1209014",
                "vill_name": "ນາຄອງ",
                "vill_name_en": "",
                "dr_id": "1209"
              },
              {
                "vill_id": "1209015",
                "vill_name": "ນານົກ",
                "vill_name_en": "",
                "dr_id": "1209"
              },
              {
                "vill_id": "1209016",
                "vill_name": "ດົງນາຄຳ",
                "vill_name_en": "",
                "dr_id": "1209"
              },
              {
                "vill_id": "1209017",
                "vill_name": "ຜາສະຫວາ",
                "vill_name_en": "",
                "dr_id": "1209"
              },
              {
                "vill_id": "1209018",
                "vill_name": "ໂພນນາດີ",
                "vill_name_en": "",
                "dr_id": "1209"
              },
              {
                "vill_id": "1209019",
                "vill_name": "ຜາຂາດ",
                "vill_name_en": "",
                "dr_id": "1209"
              },
              {
                "vill_id": "1209020",
                "vill_name": "ໜອງຜື",
                "vill_name_en": "",
                "dr_id": "1209"
              },
              {
                "vill_id": "1209021",
                "vill_name": "ກຸດນ້ຳໃສ",
                "vill_name_en": "",
                "dr_id": "1209"
              },
              {
                "vill_id": "1209022",
                "vill_name": "ນາພ້າວ",
                "vill_name_en": "",
                "dr_id": "1209"
              },
              {
                "vill_id": "1209023",
                "vill_name": "ນາກະທິງ",
                "vill_name_en": "",
                "dr_id": "1209"
              },
              {
                "vill_id": "1209024",
                "vill_name": "ນາຊຽງຄວນ",
                "vill_name_en": "",
                "dr_id": "1209"
              },
              {
                "vill_id": "1209025",
                "vill_name": "ທ່າກົກແຄນ",
                "vill_name_en": "",
                "dr_id": "1209"
              },
              {
                "vill_id": "1209026",
                "vill_name": "ນ້ຳກະປໍ",
                "vill_name_en": "",
                "dr_id": "1209"
              },
              {
                "vill_id": "1209027",
                "vill_name": "ໂພນຄຳ",
                "vill_name_en": "",
                "dr_id": "1209"
              },
              {
                "vill_id": "1209028",
                "vill_name": "ທົ່ງຕຽມ",
                "vill_name_en": "",
                "dr_id": "1209"
              },
              {
                "vill_id": "1209029",
                "vill_name": "ແກ້ງໝື່ນ",
                "vill_name_en": "",
                "dr_id": "1209"
              },
              {
                "vill_id": "1209030",
                "vill_name": "ປ່າກ້ວຍໜອງບອນ",
                "vill_name_en": "",
                "dr_id": "1209"
              },
              {
                "vill_id": "1209031",
                "vill_name": "ປ່າກະຊະ",
                "vill_name_en": "",
                "dr_id": "1209"
              },
              {
                "vill_id": "1209032",
                "vill_name": "ໂຄກສະຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "1209"
              },
              {
                "vill_id": "1209033",
                "vill_name": "ທ່າແຮ່",
                "vill_name_en": "",
                "dr_id": "1209"
              },
              {
                "vill_id": "1209034",
                "vill_name": "ຜາໂຄ້ງ",
                "vill_name_en": "",
                "dr_id": "1209"
              },
              {
                "vill_id": "1209035",
                "vill_name": "ນາຂາມ",
                "vill_name_en": "",
                "dr_id": "1209"
              },
              {
                "vill_id": "1209036",
                "vill_name": "ນາຫມາກມີ້",
                "vill_name_en": "",
                "dr_id": "1209"
              },
              {
                "vill_id": "1209037",
                "vill_name": "ນານ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1209"
              },
              {
                "vill_id": "1209038",
                "vill_name": "ຊອກທາງ",
                "vill_name_en": "",
                "dr_id": "1209"
              },
              {
                "vill_id": "1209039",
                "vill_name": "ນາຄຳຈວງ",
                "vill_name_en": "",
                "dr_id": "1209"
              },
              {
                "vill_id": "1209040",
                "vill_name": "ນາໂມ້",
                "vill_name_en": "",
                "dr_id": "1209"
              }
            ]
          },
          {
            "dr_id": "1210",
            "dr_name": "ຄູນຄຳ",
            "dr_name_en": "Khounkham",
            "pr_id": "12",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "1210001",
                "vill_name": "ຄູນຄຳ",
                "vill_name_en": "",
                "dr_id": "1210"
              },
              {
                "vill_id": "1210002",
                "vill_name": "ຄູນເງິນ",
                "vill_name_en": "",
                "dr_id": "1210"
              },
              {
                "vill_id": "1210003",
                "vill_name": "ຄູນແກ້ວ",
                "vill_name_en": "",
                "dr_id": "1210"
              },
              {
                "vill_id": "1210004",
                "vill_name": "ນາຂາມ",
                "vill_name_en": "",
                "dr_id": "1210"
              },
              {
                "vill_id": "1210005",
                "vill_name": "ກອງລໍ",
                "vill_name_en": "",
                "dr_id": "1210"
              },
              {
                "vill_id": "1210006",
                "vill_name": "ນ້ຳສະນາມ",
                "vill_name_en": "",
                "dr_id": "1210"
              },
              {
                "vill_id": "1210007",
                "vill_name": "ພູຊາດ",
                "vill_name_en": "",
                "dr_id": "1210"
              },
              {
                "vill_id": "1210008",
                "vill_name": "ທ່າຫົວຊ້າງ",
                "vill_name_en": "",
                "dr_id": "1210"
              },
              {
                "vill_id": "1210009",
                "vill_name": "ນ້ຳບໍ່",
                "vill_name_en": "",
                "dr_id": "1210"
              },
              {
                "vill_id": "1210010",
                "vill_name": "ນາກາງ",
                "vill_name_en": "Kang",
                "dr_id": "1210"
              },
              {
                "vill_id": "1210011",
                "vill_name": "ນ້ຳມະຮາງ",
                "vill_name_en": "Nammahang",
                "dr_id": "1210"
              }
            ]
          }
        ]
      },
      {
        "pr_id": "13",
        "pr_name": "ສະຫວັນນະເຂດ",
        "pr_name_en": "Savannakhet",
        "districts": [
          {"dr_name": "ເລືອກເມືອງປະຈຸບັນ", "villages": []},
          {
            "dr_id": "1301",
            "dr_name": "ໄກສອນ ພົມວິຫານ",
            "dr_name_en": "Kaisone Phomvihane",
            "pr_id": "13",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "11301001",
                "vill_name": "ທ່າຫົວຊ້າງ",
                "vill_name_en": "",
                "dr_id": "1301"
              },
              {
                "vill_id": "11301002",
                "vill_name": "ນ້ຳບໍ່",
                "vill_name_en": "",
                "dr_id": "1301"
              },
              {
                "vill_id": "11301003",
                "vill_name": "ບຶງທະເລ",
                "vill_name_en": "",
                "dr_id": "1301"
              },
              {
                "vill_id": "11301004",
                "vill_name": "ທ່າຊະໂນ",
                "vill_name_en": "",
                "dr_id": "1301"
              },
              {
                "vill_id": "11301005",
                "vill_name": "ປາກບໍ່",
                "vill_name_en": "",
                "dr_id": "1301"
              },
              {
                "vill_id": "11301006",
                "vill_name": "ຕົ້ນເຜິ້ງ",
                "vill_name_en": "",
                "dr_id": "1301"
              },
              {
                "vill_id": "11301007",
                "vill_name": "ເລົ່າງາມ",
                "vill_name_en": "",
                "dr_id": "1301"
              },
              {
                "vill_id": "11301008",
                "vill_name": "ຂອນແກ່ນ",
                "vill_name_en": "",
                "dr_id": "1301"
              },
              {
                "vill_id": "11301009",
                "vill_name": "ນາຈະຫລິດ",
                "vill_name_en": "",
                "dr_id": "1301"
              },
              {
                "vill_id": "11301010",
                "vill_name": "ດົງດຳດວນ",
                "vill_name_en": "",
                "dr_id": "1301"
              },
              {
                "vill_id": "11301011",
                "vill_name": "ຍາງໂພ​ສີ",
                "vill_name_en": "",
                "dr_id": "1301"
              },
              {
                "vill_id": "11301012",
                "vill_name": "ເຄືອເຂົາກາດ",
                "vill_name_en": "",
                "dr_id": "1301"
              },
              {
                "vill_id": "11301013",
                "vill_name": "ຫນອງໂກມ",
                "vill_name_en": "",
                "dr_id": "1301"
              },
              {
                "vill_id": "11301014",
                "vill_name": "ດົງບັງ",
                "vill_name_en": "",
                "dr_id": "1301"
              },
              {
                "vill_id": "11301015",
                "vill_name": "ນາເຕີຍ",
                "vill_name_en": "",
                "dr_id": "1301"
              },
              {
                "vill_id": "11301016",
                "vill_name": "ຫມໍເມືອງ",
                "vill_name_en": "",
                "dr_id": "1301"
              },
              {
                "vill_id": "11301017",
                "vill_name": "ນາແກ",
                "vill_name_en": "",
                "dr_id": "1301"
              },
              {
                "vill_id": "11301018",
                "vill_name": "ຫົວເມືອງເຫນືອ",
                "vill_name_en": "",
                "dr_id": "1301"
              },
              {
                "vill_id": "11301019",
                "vill_name": "ຫົວເມືອງໃຕ້",
                "vill_name_en": "",
                "dr_id": "1301"
              },
              {
                "vill_id": "11301020",
                "vill_name": "ໂພໄຊ",
                "vill_name_en": "",
                "dr_id": "1301"
              },
              {
                "vill_id": "11301021",
                "vill_name": "ຈອມແກ້ວ",
                "vill_name_en": "",
                "dr_id": "1301"
              },
              {
                "vill_id": "11301022",
                "vill_name": "ວຽງສະຫວັນ",
                "vill_name_en": "",
                "dr_id": "1301"
              },
              {
                "vill_id": "11301023",
                "vill_name": "ຫນອງເດີ່ນ",
                "vill_name_en": "",
                "dr_id": "1301"
              },
              {
                "vill_id": "11301024",
                "vill_name": "ໂພນສະຫວ່າງເຫນືອ",
                "vill_name_en": "",
                "dr_id": "1301"
              },
              {
                "vill_id": "11301025",
                "vill_name": "ຫນອງຜື",
                "vill_name_en": "",
                "dr_id": "1301"
              },
              {
                "vill_id": "11301026",
                "vill_name": "ໂພນສະຫວ່າງໃຕ້",
                "vill_name_en": "",
                "dr_id": "1301"
              },
              {
                "vill_id": "11301027",
                "vill_name": "ນາເລົ່າ",
                "vill_name_en": "",
                "dr_id": "1301"
              },
              {
                "vill_id": "11301028",
                "vill_name": "ສະຫນາມໄຊ",
                "vill_name_en": "",
                "dr_id": "1301"
              },
              {
                "vill_id": "11301029",
                "vill_name": "ສຸນັນທາ",
                "vill_name_en": "",
                "dr_id": "1301"
              },
              {
                "vill_id": "11301030",
                "vill_name": "ທ່າເມືອງ",
                "vill_name_en": "",
                "dr_id": "1301"
              },
              {
                "vill_id": "11301031",
                "vill_name": "ໄຊຍະພູມ",
                "vill_name_en": "",
                "dr_id": "1301"
              },
              {
                "vill_id": "11301032",
                "vill_name": "ລັດຕະນະລັງສີເຫນືອ",
                "vill_name_en": "",
                "dr_id": "1301"
              },
              {
                "vill_id": "11301033",
                "vill_name": "ລັດຕະນະລັງໃຕ້",
                "vill_name_en": "",
                "dr_id": "1301"
              },
              {
                "vill_id": "11301034",
                "vill_name": "ລາດຊະວົງໄຊ",
                "vill_name_en": "",
                "dr_id": "1301"
              },
              {
                "vill_id": "11301035",
                "vill_name": "ໂນນສະຫວາດ",
                "vill_name_en": "",
                "dr_id": "1301"
              },
              {
                "vill_id": "11301036",
                "vill_name": "ໄຊຍະມຸງຄຸນ",
                "vill_name_en": "",
                "dr_id": "1301"
              },
              {
                "vill_id": "11301037",
                "vill_name": "ທ່າແຮ່",
                "vill_name_en": "",
                "dr_id": "1301"
              },
              {
                "vill_id": "11301038",
                "vill_name": "ສະພານເໜືອ",
                "vill_name_en": "",
                "dr_id": "1301"
              },
              {
                "vill_id": "11301039",
                "vill_name": "ສະພານໃຕ້",
                "vill_name_en": "",
                "dr_id": "1301"
              },
              {
                "vill_id": "11301040",
                "vill_name": "ໂພນສະຫວັນ",
                "vill_name_en": "",
                "dr_id": "1301"
              },
              {
                "vill_id": "11301041",
                "vill_name": "ດົງດຳດວນ",
                "vill_name_en": "",
                "dr_id": "1301"
              },
              {
                "vill_id": "11301042",
                "vill_name": "ໂພນສະອາດ",
                "vill_name_en": "",
                "dr_id": "1301"
              },
              {
                "vill_id": "11301043",
                "vill_name": "ສອນໄຊ",
                "vill_name_en": "",
                "dr_id": "1301"
              },
              {
                "vill_id": "11301044",
                "vill_name": "ໂພນຊາຍ",
                "vill_name_en": "",
                "dr_id": "1301"
              },
              {
                "vill_id": "11301045",
                "vill_name": "ນາເຊັງ",
                "vill_name_en": "",
                "dr_id": "1301"
              },
              {
                "vill_id": "11301046",
                "vill_name": "ດອນເຊັງ",
                "vill_name_en": "",
                "dr_id": "1301"
              },
              {
                "vill_id": "11301047",
                "vill_name": "ດົງນາຄຳ",
                "vill_name_en": "",
                "dr_id": "1301"
              },
              {
                "vill_id": "11301048",
                "vill_name": "ທາດອີງຮັງ",
                "vill_name_en": "",
                "dr_id": "1301"
              },
              {
                "vill_id": "11301049",
                "vill_name": "ໂພນສີມ",
                "vill_name_en": "",
                "dr_id": "1301"
              },
              {
                "vill_id": "11301050",
                "vill_name": "ສົ້ງ",
                "vill_name_en": "",
                "dr_id": "1301"
              },
              {
                "vill_id": "11301051",
                "vill_name": "ສົມສະອາດ",
                "vill_name_en": "",
                "dr_id": "1301"
              },
              {
                "vill_id": "11301052",
                "vill_name": "ນາກອຍ",
                "vill_name_en": "",
                "dr_id": "1301"
              },
              {
                "vill_id": "11301053",
                "vill_name": "ບຶງວະ",
                "vill_name_en": "",
                "dr_id": "1301"
              },
              {
                "vill_id": "11301054",
                "vill_name": "ດົງໝາກຢາງ",
                "vill_name_en": "",
                "dr_id": "1301"
              },
              {
                "vill_id": "11301055",
                "vill_name": "ໂພນສູງ",
                "vill_name_en": "",
                "dr_id": "1301"
              },
              {
                "vill_id": "11301056",
                "vill_name": "ຊອກ",
                "vill_name_en": "",
                "dr_id": "1301"
              },
              {
                "vill_id": "11301057",
                "vill_name": "ຍາງສູງ",
                "vill_name_en": "",
                "dr_id": "1301"
              },
              {
                "vill_id": "11301058",
                "vill_name": "ຫ້ວຍ",
                "vill_name_en": "",
                "dr_id": "1301"
              },
              {
                "vill_id": "11301059",
                "vill_name": "ແກ້ງຫີນສູງ",
                "vill_name_en": "",
                "dr_id": "1301"
              },
              {
                "vill_id": "11301060",
                "vill_name": "ດົງຄຳຫລວງ",
                "vill_name_en": "",
                "dr_id": "1301"
              },
              {
                "vill_id": "11301061",
                "vill_name": "ຊອກວາງ",
                "vill_name_en": "",
                "dr_id": "1301"
              },
              {
                "vill_id": "11301062",
                "vill_name": "ສົ້ມປ່ອຍ",
                "vill_name_en": "",
                "dr_id": "1301"
              },
              {
                "vill_id": "11301063",
                "vill_name": "ນາໂພ",
                "vill_name_en": "",
                "dr_id": "1301"
              },
              {
                "vill_id": "11301064",
                "vill_name": "ຜັກຂະ",
                "vill_name_en": "",
                "dr_id": "1301"
              },
              {
                "vill_id": "11301065",
                "vill_name": "ໂນນອຸດົມ",
                "vill_name_en": "",
                "dr_id": "1301"
              },
              {
                "vill_id": "11301066",
                "vill_name": "ຫ້ວຍໄຜ່",
                "vill_name_en": "",
                "dr_id": "1301"
              },
              {
                "vill_id": "11301067",
                "vill_name": "ອຸດົມວິໄລ",
                "vill_name_en": "",
                "dr_id": "1301"
              },
              {
                "vill_id": "11301068",
                "vill_name": "ທົ່ງສາມັກຄີ",
                "vill_name_en": "Thongsamaki",
                "dr_id": "1301"
              }
            ]
          },
          {
            "dr_id": "1302",
            "dr_name": "ອຸທຸມພອນ",
            "dr_name_en": "Outhoumphone",
            "pr_id": "13",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "1302001",
                "vill_name": "ມະນີວົງໄຊ",
                "vill_name_en": "",
                "dr_id": "1302"
              },
              {
                "vill_id": "1302002",
                "vill_name": "ເຊໂນ",
                "vill_name_en": "",
                "dr_id": "1302"
              },
              {
                "vill_id": "1302003",
                "vill_name": "ຈັນທະລັງສີ",
                "vill_name_en": "",
                "dr_id": "1302"
              },
              {
                "vill_id": "1302004",
                "vill_name": "ວົງພັນສີ",
                "vill_name_en": "",
                "dr_id": "1302"
              },
              {
                "vill_id": "1302005",
                "vill_name": "ໄຊສົມບູນ",
                "vill_name_en": "",
                "dr_id": "1302"
              },
              {
                "vill_id": "1302006",
                "vill_name": "ອຸດົມມີໄຊ",
                "vill_name_en": "",
                "dr_id": "1302"
              },
              {
                "vill_id": "1302007",
                "vill_name": "ຈອມເພັດ",
                "vill_name_en": "",
                "dr_id": "1302"
              },
              {
                "vill_id": "1302008",
                "vill_name": "ໄຊຍະມຸງຄຸນ",
                "vill_name_en": "",
                "dr_id": "1302"
              },
              {
                "vill_id": "1302009",
                "vill_name": "ນາທາດ",
                "vill_name_en": "",
                "dr_id": "1302"
              },
              {
                "vill_id": "1302010",
                "vill_name": "ໄຊອຸດົມ",
                "vill_name_en": "",
                "dr_id": "1302"
              },
              {
                "vill_id": "1302011",
                "vill_name": "ໂນນດອກໄມ້",
                "vill_name_en": "",
                "dr_id": "1302"
              },
              {
                "vill_id": "1302012",
                "vill_name": "ຫນອງອາຮົງນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1302"
              },
              {
                "vill_id": "1302013",
                "vill_name": "ໄຊສະອາດ",
                "vill_name_en": "",
                "dr_id": "1302"
              },
              {
                "vill_id": "1302014",
                "vill_name": "ສະຫນາມໄຊ",
                "vill_name_en": "",
                "dr_id": "1302"
              },
              {
                "vill_id": "1302015",
                "vill_name": "ຫນອງຄຳແຮດ",
                "vill_name_en": "",
                "dr_id": "1302"
              },
              {
                "vill_id": "1302016",
                "vill_name": "ຫ້ວຍເຫມົ້າ",
                "vill_name_en": "",
                "dr_id": "1302"
              },
              {
                "vill_id": "1302017",
                "vill_name": "ກາງໂພສີ",
                "vill_name_en": "",
                "dr_id": "1302"
              },
              {
                "vill_id": "1302018",
                "vill_name": "ນາຊາຍ",
                "vill_name_en": "",
                "dr_id": "1302"
              },
              {
                "vill_id": "1302019",
                "vill_name": "ສົມພັດວິໄລ",
                "vill_name_en": "",
                "dr_id": "1302"
              },
              {
                "vill_id": "1302020",
                "vill_name": "ດົງຫມາກແງວ",
                "vill_name_en": "",
                "dr_id": "1302"
              },
              {
                "vill_id": "1302021",
                "vill_name": "ຜັກຂະຫຍ່າ",
                "vill_name_en": "",
                "dr_id": "1302"
              },
              {
                "vill_id": "1302023",
                "vill_name": "ນາສະໂນດ",
                "vill_name_en": "",
                "dr_id": "1302"
              },
              {
                "vill_id": "1302024",
                "vill_name": "ນາຄູ",
                "vill_name_en": "",
                "dr_id": "1302"
              },
              {
                "vill_id": "1302025",
                "vill_name": "ໂນນນາຄູນ",
                "vill_name_en": "",
                "dr_id": "1302"
              },
              {
                "vill_id": "1302026",
                "vill_name": "ນາຫົວຂົວ",
                "vill_name_en": "",
                "dr_id": "1302"
              },
              {
                "vill_id": "1302027",
                "vill_name": "ອາຮົງໃຫຍ່",
                "vill_name_en": "",
                "dr_id": "1302"
              },
              {
                "vill_id": "1302028",
                "vill_name": "ໂພນນາຍາງ",
                "vill_name_en": "",
                "dr_id": "1302"
              },
              {
                "vill_id": "1302029",
                "vill_name": "ໂພນຕູມ",
                "vill_name_en": "",
                "dr_id": "1302"
              },
              {
                "vill_id": "1302030",
                "vill_name": "ໂພນສະຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "1302"
              },
              {
                "vill_id": "1302031",
                "vill_name": "ໂພນເດື່ອ",
                "vill_name_en": "",
                "dr_id": "1302"
              },
              {
                "vill_id": "1302032",
                "vill_name": "ໂພນທັນ",
                "vill_name_en": "",
                "dr_id": "1302"
              },
              {
                "vill_id": "1302033",
                "vill_name": "ໂນນອຸດົມ",
                "vill_name_en": "",
                "dr_id": "1302"
              },
              {
                "vill_id": "1302034",
                "vill_name": "ນາໂພ",
                "vill_name_en": "",
                "dr_id": "1302"
              },
              {
                "vill_id": "1302035",
                "vill_name": "ເອ້",
                "vill_name_en": "",
                "dr_id": "1302"
              },
              {
                "vill_id": "1302036",
                "vill_name": "ນາເກັງ",
                "vill_name_en": "",
                "dr_id": "1302"
              },
              {
                "vill_id": "1302037",
                "vill_name": "ປ່າຫນາມ",
                "vill_name_en": "",
                "dr_id": "1302"
              },
              {
                "vill_id": "1302038",
                "vill_name": "ນາປົ່ງ",
                "vill_name_en": "",
                "dr_id": "1302"
              },
              {
                "vill_id": "1302039",
                "vill_name": "ໂນນປ່າໄລ່",
                "vill_name_en": "",
                "dr_id": "1302"
              },
              {
                "vill_id": "1302040",
                "vill_name": "ດົງທ່າ",
                "vill_name_en": "",
                "dr_id": "1302"
              },
              {
                "vill_id": "1302041",
                "vill_name": "ດົງໃນ",
                "vill_name_en": "",
                "dr_id": "1302"
              },
              {
                "vill_id": "1302042",
                "vill_name": "ຫນອງແປນ",
                "vill_name_en": "",
                "dr_id": "1302"
              },
              {
                "vill_id": "1302043",
                "vill_name": "ນາຄຳ",
                "vill_name_en": "",
                "dr_id": "1302"
              },
              {
                "vill_id": "1302044",
                "vill_name": "ຕາດຂາແດງ",
                "vill_name_en": "",
                "dr_id": "1302"
              },
              {
                "vill_id": "1302045",
                "vill_name": "ນາ",
                "vill_name_en": "",
                "dr_id": "1302"
              },
              {
                "vill_id": "1302047",
                "vill_name": "ສະກຸດ",
                "vill_name_en": "",
                "dr_id": "1302"
              },
              {
                "vill_id": "1302048",
                "vill_name": "ໂພນຕາດ",
                "vill_name_en": "",
                "dr_id": "1302"
              },
              {
                "vill_id": "1302049",
                "vill_name": "ນາຂີ້ເຫລັກ",
                "vill_name_en": "",
                "dr_id": "1302"
              },
              {
                "vill_id": "1302050",
                "vill_name": "ທົ່ງ",
                "vill_name_en": "",
                "dr_id": "1302"
              },
              {
                "vill_id": "1302051",
                "vill_name": "ໂນນວິໄລ",
                "vill_name_en": "",
                "dr_id": "1302"
              },
              {
                "vill_id": "1302052",
                "vill_name": "ກີບມ້າ",
                "vill_name_en": "",
                "dr_id": "1302"
              },
              {
                "vill_id": "1302053",
                "vill_name": "ໂພນຫມາກຫຍ້າ",
                "vill_name_en": "",
                "dr_id": "1302"
              },
              {
                "vill_id": "1302054",
                "vill_name": "ເຫລົາໃຫຍ່",
                "vill_name_en": "",
                "dr_id": "1302"
              },
              {
                "vill_id": "1302055",
                "vill_name": "ແມດ",
                "vill_name_en": "",
                "dr_id": "1302"
              },
              {
                "vill_id": "1302056",
                "vill_name": "ຖ້ຳພະ",
                "vill_name_en": "",
                "dr_id": "1302"
              },
              {
                "vill_id": "1302057",
                "vill_name": "ນາກະເຊາະ",
                "vill_name_en": "",
                "dr_id": "1302"
              },
              {
                "vill_id": "1302058",
                "vill_name": "ດົງນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1302"
              },
              {
                "vill_id": "1302059",
                "vill_name": "ກຸດແຂ້",
                "vill_name_en": "",
                "dr_id": "1302"
              },
              {
                "vill_id": "1302060",
                "vill_name": "ຫົວຄຳ",
                "vill_name_en": "",
                "dr_id": "1302"
              },
              {
                "vill_id": "1302061",
                "vill_name": "ໂຄກ",
                "vill_name_en": "",
                "dr_id": "1302"
              },
              {
                "vill_id": "1302062",
                "vill_name": "ນາຈານ",
                "vill_name_en": "",
                "dr_id": "1302"
              },
              {
                "vill_id": "1302063",
                "vill_name": "ໂນນສະຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "1302"
              },
              {
                "vill_id": "1302064",
                "vill_name": "ພີນໃຕ້",
                "vill_name_en": "",
                "dr_id": "1302"
              },
              {
                "vill_id": "1302065",
                "vill_name": "ພີນເຫນືອ",
                "vill_name_en": "",
                "dr_id": "1302"
              },
              {
                "vill_id": "1302066",
                "vill_name": "ສີບຸນເຮືອງ",
                "vill_name_en": "",
                "dr_id": "1302"
              },
              {
                "vill_id": "1302067",
                "vill_name": "ໄຊຍະເພັດ",
                "vill_name_en": "",
                "dr_id": "1302"
              },
              {
                "vill_id": "1302068",
                "vill_name": "ຈະເລີນສຸກ",
                "vill_name_en": "",
                "dr_id": "1302"
              },
              {
                "vill_id": "1302069",
                "vill_name": "ຫ້ວຍສ້າງທອງ",
                "vill_name_en": "",
                "dr_id": "1302"
              },
              {
                "vill_id": "1302070",
                "vill_name": "ຫນອງບົວທອງ",
                "vill_name_en": "",
                "dr_id": "1302"
              },
              {
                "vill_id": "1302071",
                "vill_name": "ໂພນງີ້ວ",
                "vill_name_en": "phonngew",
                "dr_id": "1302"
              }
            ]
          },
          {
            "dr_id": "1303",
            "dr_name": "ອາດສະພັງທອງ",
            "dr_name_en": "Atsaphangthong",
            "pr_id": "13",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "1303001",
                "vill_name": "ນາແສນແກ້ວ",
                "vill_name_en": "",
                "dr_id": "1303"
              },
              {
                "vill_id": "1303002",
                "vill_name": "ດົງຫມາກກໍ່",
                "vill_name_en": "",
                "dr_id": "1303"
              },
              {
                "vill_id": "1303003",
                "vill_name": "ໂພສີແກ້ວ",
                "vill_name_en": "",
                "dr_id": "1303"
              },
              {
                "vill_id": "1303004",
                "vill_name": "ນາແຜກ",
                "vill_name_en": "",
                "dr_id": "1303"
              },
              {
                "vill_id": "1303005",
                "vill_name": "ນາຈານ",
                "vill_name_en": "",
                "dr_id": "1303"
              },
              {
                "vill_id": "1303006",
                "vill_name": "ນາຫລັກ",
                "vill_name_en": "",
                "dr_id": "1303"
              },
              {
                "vill_id": "1303007",
                "vill_name": "ດົງນາຂາມ",
                "vill_name_en": "",
                "dr_id": "1303"
              },
              {
                "vill_id": "1303008",
                "vill_name": "ດົງກຶມ",
                "vill_name_en": "",
                "dr_id": "1303"
              },
              {
                "vill_id": "1303009",
                "vill_name": "ນາໄລ່ໂຄກ",
                "vill_name_en": "",
                "dr_id": "1303"
              },
              {
                "vill_id": "1303010",
                "vill_name": "ນາໄລ່ດົງ",
                "vill_name_en": "",
                "dr_id": "1303"
              },
              {
                "vill_id": "1303011",
                "vill_name": "ໂນນຮັງ",
                "vill_name_en": "",
                "dr_id": "1303"
              },
              {
                "vill_id": "1303012",
                "vill_name": "ໂຄກສະຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "1303"
              },
              {
                "vill_id": "1303013",
                "vill_name": "ຫນອງປາກຊ່ອງ",
                "vill_name_en": "",
                "dr_id": "1303"
              },
              {
                "vill_id": "1303014",
                "vill_name": "ສະພັງແກ້ວ",
                "vill_name_en": "",
                "dr_id": "1303"
              },
              {
                "vill_id": "1303015",
                "vill_name": "ດອນປ່າໄລ່",
                "vill_name_en": "",
                "dr_id": "1303"
              },
              {
                "vill_id": "1303016",
                "vill_name": "ຕະບອງເພັດ",
                "vill_name_en": "",
                "dr_id": "1303"
              },
              {
                "vill_id": "1303017",
                "vill_name": "ດົງເຫັນ",
                "vill_name_en": "",
                "dr_id": "1303"
              },
              {
                "vill_id": "1303018",
                "vill_name": "ດົງກິໂລ",
                "vill_name_en": "",
                "dr_id": "1303"
              },
              {
                "vill_id": "1303019",
                "vill_name": "ນານ້ຳທ່ຽງ",
                "vill_name_en": "",
                "dr_id": "1303"
              },
              {
                "vill_id": "1303020",
                "vill_name": "ພັ່ງໂຮ",
                "vill_name_en": "",
                "dr_id": "1303"
              },
              {
                "vill_id": "1303021",
                "vill_name": "ສີວິໄລ",
                "vill_name_en": "",
                "dr_id": "1303"
              },
              {
                "vill_id": "1303022",
                "vill_name": "ຫົວເມືອງ",
                "vill_name_en": "",
                "dr_id": "1303"
              },
              {
                "vill_id": "1303023",
                "vill_name": "ດົງຂວາງ",
                "vill_name_en": "",
                "dr_id": "1303"
              },
              {
                "vill_id": "1303024",
                "vill_name": "ປົ່ງດົງ",
                "vill_name_en": "",
                "dr_id": "1303"
              },
              {
                "vill_id": "1303025",
                "vill_name": "ປົ່ງນາ",
                "vill_name_en": "",
                "dr_id": "1303"
              },
              {
                "vill_id": "1303026",
                "vill_name": "ໄລກະທະ",
                "vill_name_en": "",
                "dr_id": "1303"
              },
              {
                "vill_id": "1303027",
                "vill_name": "ນາ​ສະ​ຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "1303"
              },
              {
                "vill_id": "1303028",
                "vill_name": "ແຈລະມ໋ອງ",
                "vill_name_en": "",
                "dr_id": "1303"
              },
              {
                "vill_id": "1303029",
                "vill_name": "ໂພນບົກ",
                "vill_name_en": "",
                "dr_id": "1303"
              },
              {
                "vill_id": "1303030",
                "vill_name": "ນານົກຂຽນ",
                "vill_name_en": "",
                "dr_id": "1303"
              },
              {
                "vill_id": "1303031",
                "vill_name": "ຫລຽນໄຊ",
                "vill_name_en": "",
                "dr_id": "1303"
              },
              {
                "vill_id": "1303032",
                "vill_name": "ວັດທະນະສົມບູນ",
                "vill_name_en": "",
                "dr_id": "1303"
              },
              {
                "vill_id": "1303033",
                "vill_name": "ໂພນງອຍ",
                "vill_name_en": "",
                "dr_id": "1303"
              },
              {
                "vill_id": "1303034",
                "vill_name": "ນາພັງໂຄກ",
                "vill_name_en": "",
                "dr_id": "1303"
              },
              {
                "vill_id": "1303035",
                "vill_name": "ຫ້ວຍ",
                "vill_name_en": "",
                "dr_id": "1303"
              },
              {
                "vill_id": "1303036",
                "vill_name": "ຫນອງໃສ",
                "vill_name_en": "",
                "dr_id": "1303"
              },
              {
                "vill_id": "1303037",
                "vill_name": "ສາມະຄີໄຊ",
                "vill_name_en": "",
                "dr_id": "1303"
              },
              {
                "vill_id": "1303038",
                "vill_name": "ໂພນຜາງ",
                "vill_name_en": "",
                "dr_id": "1303"
              },
              {
                "vill_id": "1303039",
                "vill_name": "ປ່າໄລ່",
                "vill_name_en": "",
                "dr_id": "1303"
              },
              {
                "vill_id": "1303040",
                "vill_name": "ໜອງຫ້ອຍ",
                "vill_name_en": "Nonghoy",
                "dr_id": "1303"
              }
            ]
          },
          {
            "dr_id": "1304",
            "dr_name": "ພີນ",
            "dr_name_en": "Phine",
            "pr_id": "13",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "1304001",
                "vill_name": "ເວີນຫົງຄຳ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304002",
                "vill_name": "ບ້ານປະສົມໄຊ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304003",
                "vill_name": "ພີນ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304004",
                "vill_name": "ນາໂພ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304005",
                "vill_name": "ນາຈານ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304006",
                "vill_name": "ພອນ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304007",
                "vill_name": "ນາເປາະ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304008",
                "vill_name": "ນາຂະເນາະ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304009",
                "vill_name": "ໂນນສະອາດ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304010",
                "vill_name": "ໂຄກປູນ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304011",
                "vill_name": "ເທີມໃຫມ່",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304012",
                "vill_name": "ເທີມເກົ່າ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304013",
                "vill_name": "ສະຄວງ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304014",
                "vill_name": "ຂະຫນິນ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304015",
                "vill_name": "ອາປອກ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304016",
                "vill_name": "ມະລົວ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304017",
                "vill_name": "ຫີນສະໂງມ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304018",
                "vill_name": "ວັງບວງ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304019",
                "vill_name": "ສະລອຍ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304020",
                "vill_name": "ໂນນຍາງ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304021",
                "vill_name": "ນາທະລັງ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304022",
                "vill_name": "ນາກະຫານ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304023",
                "vill_name": "ລະຕຶບ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304024",
                "vill_name": "ແກ້ງລະແຫ້ງ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304025",
                "vill_name": "ກາງເກົ່າ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304026",
                "vill_name": "ຊົງເປືອຍ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304027",
                "vill_name": "ປາເຫລັກ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304028",
                "vill_name": "ກາງໃຫມ່",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304029",
                "vill_name": "ກະຢ໋ອງ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304030",
                "vill_name": "ໃຫມ່ເລກ9",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304031",
                "vill_name": "ອຸດົມໄຊ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304032",
                "vill_name": "ເຊສະຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304033",
                "vill_name": "ຊະນະມີໄຊ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304034",
                "vill_name": "ໄຊສົມບູນ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304035",
                "vill_name": "ສີບຸນເຮືອງ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304036",
                "vill_name": "ໂນນໄຊ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304037",
                "vill_name": "ດົງພູເງິນ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304038",
                "vill_name": "ກອຍ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304039",
                "vill_name": "ຕະໝູມ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304040",
                "vill_name": "ນາແຊງເກົ່າ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304041",
                "vill_name": "ນາຂະນົງ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304042",
                "vill_name": "ຄຳສະອີ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304043",
                "vill_name": "ນາໂພຄຳ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304044",
                "vill_name": "ນາທົ່ມເກົ່າ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304045",
                "vill_name": "ພະລູ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304046",
                "vill_name": "ຫນອງຫລວງ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304047",
                "vill_name": "ນາທູ້",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304048",
                "vill_name": "ຫ້ວຍຊາຍ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304049",
                "vill_name": "ສົມສະອາດ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304050",
                "vill_name": "ພະລອງ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304051",
                "vill_name": "ນາທອງ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304052",
                "vill_name": "ອາລວຍໃຫມ່",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304053",
                "vill_name": "ຄຳນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304054",
                "vill_name": "ຂອນແກ່ນ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304055",
                "vill_name": "ໂພນໄຊ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304056",
                "vill_name": "ອານຸສັນຍາ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304057",
                "vill_name": "ນາທົ່ມໂຄກ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304058",
                "vill_name": "ຕາດໄຮເຊ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304059",
                "vill_name": "ຕາດໄຮໂຄກ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304060",
                "vill_name": "ໂນນໄຊ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304061",
                "vill_name": "ໂນນໄຊ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304063",
                "vill_name": "ແກ້ງກົວະ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304064",
                "vill_name": "ກະແມບເຊ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304065",
                "vill_name": "ແກະ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304066",
                "vill_name": "ໂນນວິໄລ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304067",
                "vill_name": "ທົ່ງລະວຽງ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304068",
                "vill_name": "ຕັງອາໄລ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304069",
                "vill_name": "ນາເພັກ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304070",
                "vill_name": "ບົກ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304072",
                "vill_name": "ຫນອງບົວ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304073",
                "vill_name": "ວົງສີແກ້ວ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304074",
                "vill_name": "ຍາງ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304075",
                "vill_name": "ປະສິດ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304076",
                "vill_name": "ໂພນເຊ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304077",
                "vill_name": "ແກ້ງໄຊ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304078",
                "vill_name": "ອາເລົ່າໃຫມ່",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304079",
                "vill_name": "ອາເລົ່າເກົ່າ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304080",
                "vill_name": "ອາເປັຍ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304081",
                "vill_name": "ໂນນສູງ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304082",
                "vill_name": "ຊ່າງແທ່ງ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304083",
                "vill_name": "ຊ່າງແທ່ງ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304084",
                "vill_name": "ປະໂທງ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304085",
                "vill_name": "ສົບບອນ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304086",
                "vill_name": "ພະລອງ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304087",
                "vill_name": "ສະວື",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304088",
                "vill_name": "ແກ້ງອາເລີງ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304089",
                "vill_name": "ຫ້ວຍຫອຍເຫນືອ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304090",
                "vill_name": "ຫ້ວຍຫອຍກາງ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304091",
                "vill_name": "ຫ້ວຍຫອຍໄຕ້",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304092",
                "vill_name": "ອາລານ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304093",
                "vill_name": "ຫ້ວຍສິງ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304094",
                "vill_name": "ທູນຂາມ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304095",
                "vill_name": "ລະກາຍ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304096",
                "vill_name": "ປະຕອງ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304097",
                "vill_name": "ສະລູນ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304098",
                "vill_name": "ປະເຊັຍ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304099",
                "vill_name": "ປະອົກ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304100",
                "vill_name": "ແກ້ງສະລັງ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304101",
                "vill_name": "ກະປັງ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304102",
                "vill_name": "ນາປາງ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304103",
                "vill_name": "ບອຍ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304104",
                "vill_name": "ໄຜ່",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304105",
                "vill_name": "ສະແລເໜືອ",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304106",
                "vill_name": "ສະແລໃຕ້",
                "vill_name_en": "",
                "dr_id": "1304"
              },
              {
                "vill_id": "1304107",
                "vill_name": "ເຊທ່າມວກ",
                "vill_name_en": "",
                "dr_id": "1304"
              }
            ]
          },
          {
            "dr_id": "1305",
            "dr_name": "ເຊໂປນ",
            "dr_name_en": "Xepon",
            "pr_id": "13",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "1305001",
                "vill_name": "ລູສະເລັຍ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305002",
                "vill_name": "ຕະປ໋ອງ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305003",
                "vill_name": "ສົບສະລູ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305004",
                "vill_name": "ກອ໋ກ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305005",
                "vill_name": "ທ່າຜີງ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305006",
                "vill_name": "ນາ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305007",
                "vill_name": "ຜາບັງ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305008",
                "vill_name": "ໃຫມ່",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305010",
                "vill_name": "ນ້ຳໂທນ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305011",
                "vill_name": "ຕະເປິອ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305012",
                "vill_name": "ກົກຫມາກ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305013",
                "vill_name": "ລະອານ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305014",
                "vill_name": "ກະລາກ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305015",
                "vill_name": "ອາວຽນ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305016",
                "vill_name": "ນາໂທນ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305017",
                "vill_name": "ວັງໄຮ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305018",
                "vill_name": "ລາດທໍ້",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305019",
                "vill_name": "ປະງາ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305020",
                "vill_name": "ສະເວດ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305021",
                "vill_name": "ລະໂກ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305022",
                "vill_name": "ສະດູນ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305023",
                "vill_name": "ປະຍຸຍ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305024",
                "vill_name": "ຫົວນາ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305025",
                "vill_name": "ຕ້າຍ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305026",
                "vill_name": "ຊຽງຮົ່ມ+ປຸງດິນໝໍ້",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305027",
                "vill_name": "ລະເຕື່ອງ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305028",
                "vill_name": "ຄູນ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305029",
                "vill_name": "ວັງຫມໍ້ທູມ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305030",
                "vill_name": "ສົບມີ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305031",
                "vill_name": "ແກ້ງຄືບ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305032",
                "vill_name": "ວັງເລິກ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305033",
                "vill_name": "ຕໍ່ເຮືອ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305034",
                "vill_name": "ສະແລນ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305035",
                "vill_name": "ວັງແຍງ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305036",
                "vill_name": "ພະໂລະ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305037",
                "vill_name": "ແຄວິງ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305038",
                "vill_name": "ຫ້ວຍຢຶ່ງ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305039",
                "vill_name": "ສະແກງ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305040",
                "vill_name": "ຫ້ວຍຕ້ອນ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305041",
                "vill_name": "ໂພນໄຮ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305042",
                "vill_name": "ນາບໍ່",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305043",
                "vill_name": "ໂພນມ່ວງ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305044",
                "vill_name": "ຫ້ວຍແຈງ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305045",
                "vill_name": "ເມືອງແສນ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305046",
                "vill_name": "ອາໄລ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305047",
                "vill_name": "ເມືອງຈັນ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305048",
                "vill_name": "ເຊໂປນ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305049",
                "vill_name": "ທ່າໂຂ່ງ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305050",
                "vill_name": "ແກ້ງຫົວປາ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305051",
                "vill_name": "ກະແຫລງກາງ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305052",
                "vill_name": "ແກ້ງ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305053",
                "vill_name": "ອາລາງ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305054",
                "vill_name": "ແກ້ງຫລວງ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305055",
                "vill_name": "ລະຄຶມ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305056",
                "vill_name": "ປ່າໄລ່",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305057",
                "vill_name": "ອະຫໍ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305058",
                "vill_name": "ວັງບິງ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305059",
                "vill_name": "ຫ້ວຍລົວ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305060",
                "vill_name": "ສະຫມູນ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305061",
                "vill_name": "ດົງສະຫວັນ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305062",
                "vill_name": "ສະກີພິນ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305063",
                "vill_name": "ດົງໃຫຍ່",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305064",
                "vill_name": "ມະ​ຫາດ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305065",
                "vill_name": "ຈຽງ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305066",
                "vill_name": "ກະແຫລງ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305067",
                "vill_name": "ກະຕູບ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305068",
                "vill_name": "ອາສີງ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305069",
                "vill_name": "ເຟືອງ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305070",
                "vill_name": "ຫ້ວຍສານ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305071",
                "vill_name": "ແກ້ງກົກ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305072",
                "vill_name": "ກາດັບ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305073",
                "vill_name": "ລະໂບກາງ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305074",
                "vill_name": "ໂພນໄຊ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305075",
                "vill_name": "ວັງກງຸ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305076",
                "vill_name": "ນາຫລວງ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305077",
                "vill_name": "ມັນຈີ່",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305078",
                "vill_name": "ທ່າເມະ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305079",
                "vill_name": "ແກ້ງກິວ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305080",
                "vill_name": "ນາຫົວນ້ຳ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305081",
                "vill_name": "ລະອໍ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305082",
                "vill_name": "ຫ້ວຍຈັງ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305083",
                "vill_name": "ສອນມີໄຊ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305084",
                "vill_name": "ອຸດົມສຸກ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305085",
                "vill_name": "ວົງວິໄລ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305086",
                "vill_name": "ແດນສະຫວັນ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305087",
                "vill_name": "ລະເວິນ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305088",
                "vill_name": "ລະງີ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305089",
                "vill_name": "ລະງີ",
                "vill_name_en": "",
                "dr_id": "1305"
              },
              {
                "vill_id": "1305090",
                "vill_name": "ລາດ",
                "vill_name_en": "",
                "dr_id": "1305"
              }
            ]
          },
          {
            "dr_id": "1306",
            "dr_name": "ນອງ",
            "dr_name_en": "Nong",
            "pr_id": "13",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "1306001",
                "vill_name": "ປະເລາະ",
                "vill_name_en": "",
                "dr_id": "1306"
              },
              {
                "vill_id": "1306002",
                "vill_name": "ໂອ້ຍ",
                "vill_name_en": "",
                "dr_id": "1306"
              },
              {
                "vill_id": "1306003",
                "vill_name": "ລະອູ",
                "vill_name_en": "",
                "dr_id": "1306"
              },
              {
                "vill_id": "1306004",
                "vill_name": "ສາງພູ",
                "vill_name_en": "",
                "dr_id": "1306"
              },
              {
                "vill_id": "1306005",
                "vill_name": "ສະລອຍໃຫມ່",
                "vill_name_en": "",
                "dr_id": "1306"
              },
              {
                "vill_id": "1306006",
                "vill_name": "ສະລອຍເກົ່າ",
                "vill_name_en": "",
                "dr_id": "1306"
              },
              {
                "vill_id": "1306007",
                "vill_name": "ຕຳລວງ",
                "vill_name_en": "",
                "dr_id": "1306"
              },
              {
                "vill_id": "1306008",
                "vill_name": "ນາກອງ",
                "vill_name_en": "",
                "dr_id": "1306"
              },
              {
                "vill_id": "1306009",
                "vill_name": "ຫູບ",
                "vill_name_en": "",
                "dr_id": "1306"
              },
              {
                "vill_id": "1306010",
                "vill_name": "ລະລົງເກົ່າ",
                "vill_name_en": "",
                "dr_id": "1306"
              },
              {
                "vill_id": "1306011",
                "vill_name": "ລະເດີ",
                "vill_name_en": "",
                "dr_id": "1306"
              },
              {
                "vill_id": "1306012",
                "vill_name": "ອາປອກ",
                "vill_name_en": "",
                "dr_id": "1306"
              },
              {
                "vill_id": "1306013",
                "vill_name": "ແສນ",
                "vill_name_en": "",
                "dr_id": "1306"
              },
              {
                "vill_id": "1306014",
                "vill_name": "ອາສິງສາລີ",
                "vill_name_en": "",
                "dr_id": "1306"
              },
              {
                "vill_id": "1306015",
                "vill_name": "ອາສີງນາ",
                "vill_name_en": "",
                "dr_id": "1306"
              },
              {
                "vill_id": "1306016",
                "vill_name": "ອາສີງສະແນະ",
                "vill_name_en": "",
                "dr_id": "1306"
              },
              {
                "vill_id": "1306017",
                "vill_name": "ປະລຽງເກົ່າ",
                "vill_name_en": "",
                "dr_id": "1306"
              },
              {
                "vill_id": "1306018",
                "vill_name": "ປະລຽງລາວ",
                "vill_name_en": "",
                "dr_id": "1306"
              },
              {
                "vill_id": "1306019",
                "vill_name": "ປະລຽງນາຍ",
                "vill_name_en": "",
                "dr_id": "1306"
              },
              {
                "vill_id": "1306020",
                "vill_name": "ປະລຽງໃຫຍ່",
                "vill_name_en": "",
                "dr_id": "1306"
              },
              {
                "vill_id": "1306021",
                "vill_name": "ລະລົງໃຫມ່",
                "vill_name_en": "",
                "dr_id": "1306"
              },
              {
                "vill_id": "1306022",
                "vill_name": "ສະດິນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1306"
              },
              {
                "vill_id": "1306023",
                "vill_name": "ສະດີໃຫຍ່",
                "vill_name_en": "",
                "dr_id": "1306"
              },
              {
                "vill_id": "1306024",
                "vill_name": "ປູນຫີນຮ່ອງ",
                "vill_name_en": "",
                "dr_id": "1306"
              },
              {
                "vill_id": "1306025",
                "vill_name": "ປູນຊ່ວຍເຍີງ",
                "vill_name_en": "",
                "dr_id": "1306"
              },
              {
                "vill_id": "1306026",
                "vill_name": "ປູນຕົງ",
                "vill_name_en": "",
                "dr_id": "1306"
              },
              {
                "vill_id": "1306027",
                "vill_name": "ຍາງ",
                "vill_name_en": "",
                "dr_id": "1306"
              },
              {
                "vill_id": "1306028",
                "vill_name": "ຕັງອາໄລເກົ່າ",
                "vill_name_en": "",
                "dr_id": "1306"
              },
              {
                "vill_id": "1306029",
                "vill_name": "ຕັງອາໄລນ້ຳ",
                "vill_name_en": "",
                "dr_id": "1306"
              },
              {
                "vill_id": "1306030",
                "vill_name": "ຕັງອາໄລເຫນືອ",
                "vill_name_en": "",
                "dr_id": "1306"
              },
              {
                "vill_id": "1306031",
                "vill_name": "ແສນໃຫມ່",
                "vill_name_en": "",
                "dr_id": "1306"
              },
              {
                "vill_id": "1306032",
                "vill_name": "ອາຕານ",
                "vill_name_en": "",
                "dr_id": "1306"
              },
              {
                "vill_id": "1306033",
                "vill_name": "ປະໂຕ້ຍນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1306"
              },
              {
                "vill_id": "1306034",
                "vill_name": "ຕະຫລຸງ",
                "vill_name_en": "",
                "dr_id": "1306"
              },
              {
                "vill_id": "1306035",
                "vill_name": "ປະໂຕຍໃຫຍ່",
                "vill_name_en": "",
                "dr_id": "1306"
              },
              {
                "vill_id": "1306036",
                "vill_name": "ລະໄກ້",
                "vill_name_en": "",
                "dr_id": "1306"
              },
              {
                "vill_id": "1306037",
                "vill_name": "ລະແບງໂຄກ",
                "vill_name_en": "",
                "dr_id": "1306"
              },
              {
                "vill_id": "1306038",
                "vill_name": "ລະແບງນ້ຳ",
                "vill_name_en": "",
                "dr_id": "1306"
              },
              {
                "vill_id": "1306039",
                "vill_name": "ຕະໂຍງ",
                "vill_name_en": "",
                "dr_id": "1306"
              },
              {
                "vill_id": "1306040",
                "vill_name": "ປະແນະ",
                "vill_name_en": "",
                "dr_id": "1306"
              },
              {
                "vill_id": "1306041",
                "vill_name": "ຊວງຄັນຄາກ",
                "vill_name_en": "",
                "dr_id": "1306"
              },
              {
                "vill_id": "1306042",
                "vill_name": "ປະລ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1306"
              },
              {
                "vill_id": "1306043",
                "vill_name": "ສະຫລາງ",
                "vill_name_en": "",
                "dr_id": "1306"
              },
              {
                "vill_id": "1306044",
                "vill_name": "ຊວງໃຫຍ່",
                "vill_name_en": "",
                "dr_id": "1306"
              },
              {
                "vill_id": "1306045",
                "vill_name": "ປະເຊັຍ",
                "vill_name_en": "",
                "dr_id": "1306"
              },
              {
                "vill_id": "1306046",
                "vill_name": "ຊວງໃຕ້",
                "vill_name_en": "",
                "dr_id": "1306"
              },
              {
                "vill_id": "1306047",
                "vill_name": "ອາລວຍ",
                "vill_name_en": "",
                "dr_id": "1306"
              },
              {
                "vill_id": "1306048",
                "vill_name": "ອາເລົາໃຫຍ່",
                "vill_name_en": "",
                "dr_id": "1306"
              },
              {
                "vill_id": "1306049",
                "vill_name": "ລະປິດ",
                "vill_name_en": "",
                "dr_id": "1306"
              },
              {
                "vill_id": "1306050",
                "vill_name": "ບາງຢາຫລາງ",
                "vill_name_en": "",
                "dr_id": "1306"
              },
              {
                "vill_id": "1306051",
                "vill_name": "ພູນຫມາກມີ້",
                "vill_name_en": "",
                "dr_id": "1306"
              },
              {
                "vill_id": "1306052",
                "vill_name": "ອາເລົາເພາະ",
                "vill_name_en": "",
                "dr_id": "1306"
              },
              {
                "vill_id": "1306053",
                "vill_name": "ປໍນ້ຳ",
                "vill_name_en": "",
                "dr_id": "1306"
              },
              {
                "vill_id": "1306054",
                "vill_name": "ປໍໂຄກ",
                "vill_name_en": "",
                "dr_id": "1306"
              },
              {
                "vill_id": "1306055",
                "vill_name": "ບ້ານລະເບົາໂຄກ",
                "vill_name_en": "",
                "dr_id": "1306"
              },
              {
                "vill_id": "1306056",
                "vill_name": "ລະເບົານ້ຳ",
                "vill_name_en": "",
                "dr_id": "1306"
              },
              {
                "vill_id": "1306057",
                "vill_name": "ໄກສອນ",
                "vill_name_en": "",
                "dr_id": "1306"
              },
              {
                "vill_id": "1306058",
                "vill_name": "ຜູ້ໄທ",
                "vill_name_en": "",
                "dr_id": "1306"
              },
              {
                "vill_id": "1306059",
                "vill_name": "ປະຈະຕຽນ",
                "vill_name_en": "",
                "dr_id": "1306"
              },
              {
                "vill_id": "1306060",
                "vill_name": "ແກ້ງລິ້ນ",
                "vill_name_en": "",
                "dr_id": "1306"
              },
              {
                "vill_id": "1306061",
                "vill_name": "ທ່າເທ",
                "vill_name_en": "",
                "dr_id": "1306"
              },
              {
                "vill_id": "1306062",
                "vill_name": "ຕະກໍ",
                "vill_name_en": "",
                "dr_id": "1306"
              },
              {
                "vill_id": "1306063",
                "vill_name": "ໂລະ",
                "vill_name_en": "",
                "dr_id": "1306"
              },
              {
                "vill_id": "1306064",
                "vill_name": "ຄູນສີ",
                "vill_name_en": "",
                "dr_id": "1306"
              },
              {
                "vill_id": "1306065",
                "vill_name": "ຕະລຶງ",
                "vill_name_en": "",
                "dr_id": "1306"
              },
              {
                "vill_id": "1306066",
                "vill_name": "ສະວຶ",
                "vill_name_en": "",
                "dr_id": "1306"
              },
              {
                "vill_id": "1306067",
                "vill_name": "ອາລອງ",
                "vill_name_en": "",
                "dr_id": "1306"
              },
              {
                "vill_id": "1306068",
                "vill_name": "ປໍ",
                "vill_name_en": "",
                "dr_id": "1306"
              },
              {
                "vill_id": "1306069",
                "vill_name": "ນອງວິໄລ",
                "vill_name_en": "",
                "dr_id": "1306"
              },
              {
                "vill_id": "1306070",
                "vill_name": "ແດນວິໄລ",
                "vill_name_en": "",
                "dr_id": "1306"
              },
              {
                "vill_id": "1306071",
                "vill_name": "ໂນນສະຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "1306"
              },
              {
                "vill_id": "1306072",
                "vill_name": "ດົງນາສານ",
                "vill_name_en": "",
                "dr_id": "1306"
              },
              {
                "vill_id": "1306073",
                "vill_name": "ກະແຫລງພູໃຫຍ່",
                "vill_name_en": "",
                "dr_id": "1306"
              }
            ]
          },
          {
            "dr_id": "1307",
            "dr_name": "ທ່າປາງທອງ",
            "dr_name_en": "Thapangthong",
            "pr_id": "13",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "1307001",
                "vill_name": "ແດນສະຕຶງ",
                "vill_name_en": "",
                "dr_id": "1307"
              },
              {
                "vill_id": "1307002",
                "vill_name": "ໄຊ​ສົມ​ບູນ",
                "vill_name_en": "",
                "dr_id": "1307"
              },
              {
                "vill_id": "1307003",
                "vill_name": "ເຊກື​",
                "vill_name_en": "",
                "dr_id": "1307"
              },
              {
                "vill_id": "1307004",
                "vill_name": "ອາຫໍ​",
                "vill_name_en": "",
                "dr_id": "1307"
              },
              {
                "vill_id": "1307005",
                "vill_name": "ນາເລ່ົາ​",
                "vill_name_en": "",
                "dr_id": "1307"
              },
              {
                "vill_id": "1307006",
                "vill_name": "ແກ້ງພູມມະລີາ",
                "vill_name_en": "",
                "dr_id": "1307"
              },
              {
                "vill_id": "1307007",
                "vill_name": "ນາປ່າຊາດ",
                "vill_name_en": "",
                "dr_id": "1307"
              },
              {
                "vill_id": "1307008",
                "vill_name": "ພູຜາ​ນາງ",
                "vill_name_en": "",
                "dr_id": "1307"
              },
              {
                "vill_id": "1307009",
                "vill_name": "ບຸດຕະພານ",
                "vill_name_en": "",
                "dr_id": "1307"
              },
              {
                "vill_id": "1307010",
                "vill_name": "ຫ້ວຍໝື່ນ",
                "vill_name_en": "",
                "dr_id": "1307"
              },
              {
                "vill_id": "1307011",
                "vill_name": "ໃຫມ່ໂພ​ສີ",
                "vill_name_en": "",
                "dr_id": "1307"
              },
              {
                "vill_id": "1307012",
                "vill_name": "ກຸດຮີ",
                "vill_name_en": "",
                "dr_id": "1307"
              },
              {
                "vill_id": "1307013",
                "vill_name": "ນາຫົວຄວາຍ",
                "vill_name_en": "",
                "dr_id": "1307"
              },
              {
                "vill_id": "1307014",
                "vill_name": "ຫນອງກັນ",
                "vill_name_en": "",
                "dr_id": "1307"
              },
              {
                "vill_id": "1307015",
                "vill_name": "ແກ້ງແກບ",
                "vill_name_en": "",
                "dr_id": "1307"
              },
              {
                "vill_id": "1307016",
                "vill_name": "ທ່າສາລາຄຳ",
                "vill_name_en": "",
                "dr_id": "1307"
              },
              {
                "vill_id": "1307017",
                "vill_name": "ໂນນຈານ",
                "vill_name_en": "",
                "dr_id": "1307"
              },
              {
                "vill_id": "1307018",
                "vill_name": "ດົງນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1307"
              },
              {
                "vill_id": "1307019",
                "vill_name": "ແກ້ງປີງ",
                "vill_name_en": "",
                "dr_id": "1307"
              },
              {
                "vill_id": "1307020",
                "vill_name": "ນາເຊືອກ",
                "vill_name_en": "",
                "dr_id": "1307"
              },
              {
                "vill_id": "1307021",
                "vill_name": "ປ່ຽນ​ຊື່​ພູແສງຄຳ",
                "vill_name_en": "",
                "dr_id": "1307"
              },
              {
                "vill_id": "1307022",
                "vill_name": "ປ່າດ໋ອງ",
                "vill_name_en": "",
                "dr_id": "1307"
              },
              {
                "vill_id": "1307023",
                "vill_name": "ຫ້ວຍໄລ່+ນາພວງ",
                "vill_name_en": "",
                "dr_id": "1307"
              },
              {
                "vill_id": "1307024",
                "vill_name": "ລະວາງໃຫຍ່",
                "vill_name_en": "",
                "dr_id": "1307"
              },
              {
                "vill_id": "1307025",
                "vill_name": "ເຊປ໋ອງ",
                "vill_name_en": "",
                "dr_id": "1307"
              },
              {
                "vill_id": "1307026",
                "vill_name": "ນາຈັນທ້າງ",
                "vill_name_en": "",
                "dr_id": "1307"
              },
              {
                "vill_id": "1307027",
                "vill_name": "ຜືວຽງຄຳ",
                "vill_name_en": "",
                "dr_id": "1307"
              },
              {
                "vill_id": "1307028",
                "vill_name": "ຫີນຕັ້ງໂຄກ",
                "vill_name_en": "",
                "dr_id": "1307"
              },
              {
                "vill_id": "1307029",
                "vill_name": "ຫີນຕັ້ງກາງ",
                "vill_name_en": "",
                "dr_id": "1307"
              },
              {
                "vill_id": "1307030",
                "vill_name": "ໂນນ​ສີວິ​ໄລ​ວັນ",
                "vill_name_en": "",
                "dr_id": "1307"
              },
              {
                "vill_id": "1307031",
                "vill_name": "ໂນນສະຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "1307"
              },
              {
                "vill_id": "1307032",
                "vill_name": "ບາກຂຸມຄຳ",
                "vill_name_en": "",
                "dr_id": "1307"
              },
              {
                "vill_id": "1307033",
                "vill_name": "ນາພະນຽດ",
                "vill_name_en": "",
                "dr_id": "1307"
              },
              {
                "vill_id": "1307034",
                "vill_name": "ແກ້ງສັງກູ",
                "vill_name_en": "",
                "dr_id": "1307"
              },
              {
                "vill_id": "1307035",
                "vill_name": "ນາທະມານ",
                "vill_name_en": "",
                "dr_id": "1307"
              },
              {
                "vill_id": "1307036",
                "vill_name": "ທ່າພີ່",
                "vill_name_en": "",
                "dr_id": "1307"
              },
              {
                "vill_id": "1307037",
                "vill_name": "ດອນຂາວ",
                "vill_name_en": "",
                "dr_id": "1307"
              },
              {
                "vill_id": "1307038",
                "vill_name": "ນາຕັງ",
                "vill_name_en": "",
                "dr_id": "1307"
              },
              {
                "vill_id": "1307039",
                "vill_name": "ປ່ຽນ​ເປັນທົ່ງປາງ",
                "vill_name_en": "",
                "dr_id": "1307"
              },
              {
                "vill_id": "1307040",
                "vill_name": "ນາຖ້ຳໝູ",
                "vill_name_en": "",
                "dr_id": "1307"
              },
              {
                "vill_id": "1307041",
                "vill_name": "ນາຫ້ວຍໄລ່",
                "vill_name_en": "",
                "dr_id": "1307"
              },
              {
                "vill_id": "1307042",
                "vill_name": "ນາສະໂນ",
                "vill_name_en": "",
                "dr_id": "1307"
              }
            ]
          },
          {
            "dr_id": "1308",
            "dr_name": "ສອງຄອນ",
            "dr_name_en": "Songkhone",
            "pr_id": "13",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "1308001",
                "vill_name": "ນວນວິໄລ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308002",
                "vill_name": "ປາກຊ່ອງ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308003",
                "vill_name": "ສາລາຄຳ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308004",
                "vill_name": "ນາຄຳນົນ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308005",
                "vill_name": "ມ່ວງໄຂ່",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308006",
                "vill_name": "ກຸດຮີ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308007",
                "vill_name": "ລະຫາໂຄກ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308008",
                "vill_name": "ຫລັກເມືອງ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308009",
                "vill_name": "ທົ່ງສີເມືອງ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308010",
                "vill_name": "ລັດຕະນະລາສີ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308011",
                "vill_name": "ໂນນຝາຍ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308012",
                "vill_name": "ມະໄລທອງ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308013",
                "vill_name": "ຄືມມ່ວນ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308014",
                "vill_name": "ຫີນແຕກ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308015",
                "vill_name": "ໂຄກສະອາດ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308016",
                "vill_name": "ຫນອງຍໍ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308017",
                "vill_name": "ນາກາລາ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308018",
                "vill_name": "ຫນອງໄຮ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308019",
                "vill_name": "ໂນນສົມບູນ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308020",
                "vill_name": "ໂນນຊົມພູ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308021",
                "vill_name": "ຫນອງນົກຂຽນ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308022",
                "vill_name": "ອຸມຫນາມຄອມ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308023",
                "vill_name": "ລະຫານ້ຳ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308024",
                "vill_name": "ທ່າຂາມລຽນ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308025",
                "vill_name": "ແບ່ງຄຳໄຫລ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308026",
                "vill_name": "ສອງຄອນ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308027",
                "vill_name": "ດົງສະຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308028",
                "vill_name": "ສີງທ່າ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308029",
                "vill_name": "ຫນອງເດີ່ນ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308030",
                "vill_name": "ວິໄຊສົງ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308031",
                "vill_name": "ນາທັງ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308032",
                "vill_name": "ພະແລງ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308033",
                "vill_name": "ນາຊະໂນ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308034",
                "vill_name": "ດອນມ່ວງ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308035",
                "vill_name": "ດອນຂຽວ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308036",
                "vill_name": "ຫ້ວຍຄໍ້",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308037",
                "vill_name": "ຫ້ວຍໄຂ່",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308038",
                "vill_name": "ນາກະຫລິງ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308039",
                "vill_name": "ຊະແຍກ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308040",
                "vill_name": "ນາພອງທ່າ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308041",
                "vill_name": "ນາຫວ້າ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308042",
                "vill_name": "ນາພອງຕິ້ວ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308043",
                "vill_name": "ນາຫອຍປັງ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308044",
                "vill_name": "ນາພວກ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308045",
                "vill_name": "ນາຫນອງຜື",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308046",
                "vill_name": "ຫນອງບົວທ່າ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308047",
                "vill_name": "ນາຍູງ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308048",
                "vill_name": "ຫນອງວັດ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308049",
                "vill_name": "ນາປົ່ງ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308050",
                "vill_name": "ຫນອງອີ່ເຫລືອງ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308051",
                "vill_name": "ຫນອງແດງ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308052",
                "vill_name": "ຄືມສະຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308053",
                "vill_name": "ນາຄູ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308054",
                "vill_name": "ໃຫມ່ຫນອງຫວ້າ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308055",
                "vill_name": "ແກ້ງຫີນແກ້ວ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308056",
                "vill_name": "ຫນອງເມັກ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308057",
                "vill_name": "ນາໂພ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308058",
                "vill_name": "ສະບູໄຊ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308059",
                "vill_name": "ທ່າເດື່ອ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308060",
                "vill_name": "ຫນອງເດີ່ນທ່າ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308061",
                "vill_name": "ທ່າປະຊຸມ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308062",
                "vill_name": "ລາດນາ​ດີ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308063",
                "vill_name": "ເປືອຍຂາວ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308064",
                "vill_name": "ນາດົງ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308065",
                "vill_name": "ນາໂຄ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308066",
                "vill_name": "ທ່າແພ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308067",
                "vill_name": "ນາແສນເທບ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308068",
                "vill_name": "ຜັກຂະ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308069",
                "vill_name": "ຫີນກອງ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308070",
                "vill_name": "ນາລາມ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308071",
                "vill_name": "ໂນນສຳລານ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308072",
                "vill_name": "ຫົວຫາດ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308073",
                "vill_name": "ແກ້ງໂດນ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308074",
                "vill_name": "ໂນນສະຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308075",
                "vill_name": "ສາວາລ້ຽວ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308076",
                "vill_name": "ຫນອງຄັນຍູ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308077",
                "vill_name": "ໂນນຄຳສູງ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308078",
                "vill_name": "ໂນນເຕົາຖ່ານ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308079",
                "vill_name": "ຫມີຢ້ຽມ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308080",
                "vill_name": "ແກ້ງຕະງານ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308081",
                "vill_name": "ເຊບັ້ງຫຽງ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308082",
                "vill_name": "ນາອຸດົມ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308083",
                "vill_name": "ຫນອງຂອນ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308084",
                "vill_name": "ໂຄກກ່ອງ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308085",
                "vill_name": "ຫີນຄອກ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308086",
                "vill_name": "ດອນຫວາຍ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308087",
                "vill_name": "ນາຄະຍອມ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308088",
                "vill_name": "ດອນຊາດ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308089",
                "vill_name": "ນາຮັງແຂ້",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308090",
                "vill_name": "ຫລັກ11",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308091",
                "vill_name": "ດອນໃຫຍ່",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308092",
                "vill_name": "ຄຳສະ​ຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308093",
                "vill_name": "ດ່ານແຕ້ໆ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308094",
                "vill_name": "ເຊບັ້ງນວນ",
                "vill_name_en": "",
                "dr_id": "1308"
              },
              {
                "vill_id": "1308095",
                "vill_name": "ໂຊກໄຊ",
                "vill_name_en": "",
                "dr_id": "1308"
              }
            ]
          },
          {
            "dr_id": "1309",
            "dr_name": "ຈຳພອນ",
            "dr_name_en": "Champhone",
            "pr_id": "13",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "1309001",
                "vill_name": "ໂນນສູງ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309002",
                "vill_name": "ໂພນຕາດ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309003",
                "vill_name": "ພັງເຮງ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309004",
                "vill_name": "ຫນອງໄຮ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309005",
                "vill_name": "ກຸດບອນ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309006",
                "vill_name": "ນາເຊືອກ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309007",
                "vill_name": "ສີບຸນເຮືອງ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309008",
                "vill_name": "ເລົ່າສຸລິຍາ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309009",
                "vill_name": "ໂນນຮັງ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309010",
                "vill_name": "ບຶງຕະຫລຸງ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309011",
                "vill_name": "ດົງຫມາກກໍ່",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309012",
                "vill_name": "ໜອງຫຍ້າມ້າ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309013",
                "vill_name": "ໂນນຄູນ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309014",
                "vill_name": "ໂພນສະອາດ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309015",
                "vill_name": "ພາຍຂອງ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309016",
                "vill_name": "ໂພນໂດກ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309017",
                "vill_name": "ຫນອງເປັດ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309018",
                "vill_name": "ຍາງສູງ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309019",
                "vill_name": "ຄຳແຄນ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309020",
                "vill_name": "ວັດຕະນາ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309021",
                "vill_name": "ໂພນມ່ວງ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309022",
                "vill_name": "ແສນ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309023",
                "vill_name": "ລຳຈັນ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309024",
                "vill_name": "ເຊ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309025",
                "vill_name": "ທ່າມ່ວງ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309026",
                "vill_name": "ດົງເມືອງ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309028",
                "vill_name": "ໂຄກແລ້ງ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309029",
                "vill_name": "ໄຜ່ລ້ອມ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309030",
                "vill_name": "ຫນອງກະລ໋ອງ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309031",
                "vill_name": "ນານົກຂຽນ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309032",
                "vill_name": "ຂອນແຕ້",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309033",
                "vill_name": "ຫ້ວຍຍາງ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309034",
                "vill_name": "ດົງຄັນຄູ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309035",
                "vill_name": "ດົງດອກໄມ້",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309036",
                "vill_name": "ບຶກທົ່ງ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309037",
                "vill_name": "ໂນນສົມບັດ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309038",
                "vill_name": "ດອນແຍງ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309039",
                "vill_name": "ຫ້ວຍຫມາກເຍົາ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309040",
                "vill_name": "ທ່າເມືອງ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309041",
                "vill_name": "ຊະຄຶນເຫນືອ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309042",
                "vill_name": "ຊະຄຶນໃຕ້",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309043",
                "vill_name": "ສີວິໄລ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309044",
                "vill_name": "ຫນອງຫົງ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309045",
                "vill_name": "ຄໍ້",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309046",
                "vill_name": "ຂາມເຖົ້າ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309047",
                "vill_name": "ສີທອງ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309048",
                "vill_name": "ດົງມອນ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309049",
                "vill_name": "ໂພນທອງ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309050",
                "vill_name": "ລຳເທນ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309051",
                "vill_name": "ຫນອງກະໂດກ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309052",
                "vill_name": "ທວດໃຕ້",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309053",
                "vill_name": "ນາຂາມ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309054",
                "vill_name": "ລຳບອງ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309055",
                "vill_name": "ເພຍກ່າ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309056",
                "vill_name": "ແກ້ງປູນ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309057",
                "vill_name": "ໂນນສີທັນ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309058",
                "vill_name": "ພະແລງ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309059",
                "vill_name": "ຫນອງແວງ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309060",
                "vill_name": "ຄຳນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309061",
                "vill_name": "ຫລັກ35",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309062",
                "vill_name": "ນາ​ເຕີຍ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309063",
                "vill_name": "ໄຜ່",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309064",
                "vill_name": "ເຂົາກາດ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309065",
                "vill_name": "ບາກ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309066",
                "vill_name": "ດົງຄຳຫມື່ນ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309067",
                "vill_name": "ນາຄູ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309068",
                "vill_name": "ດົງນາຄອຍ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309069",
                "vill_name": "ດົງຫນອງຂູ່ນ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309070",
                "vill_name": "ແກ້ງກອກທົ່ງ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309071",
                "vill_name": "ຫົວເມືອງ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309072",
                "vill_name": "ສະພານຫຼວງ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309073",
                "vill_name": "ແກ້ງກອກເຫນືອ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309074",
                "vill_name": "ແກ້ງກອກກາງ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309075",
                "vill_name": "ແກ້ງກອກດົງ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309076",
                "vill_name": "ໂນນວິໄລວັນ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309077",
                "vill_name": "ນົກກົກ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309078",
                "vill_name": "ດົງຕະແບງ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309079",
                "vill_name": "ກະຫລາງເຫນືອ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309080",
                "vill_name": "ກະຫລາງໃຕ້",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309081",
                "vill_name": "ສະລິປ່າກວ້ຍ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309082",
                "vill_name": "ຄຳປາແໜ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309083",
                "vill_name": "ນາກະທ້າງ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309084",
                "vill_name": "ຕາແຫຼວ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309085",
                "vill_name": "ຕາແຫຼວນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309086",
                "vill_name": "ນາສານ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309087",
                "vill_name": "ກະດານ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309088",
                "vill_name": "ຄຳສີດາ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309089",
                "vill_name": "ດອນແດງ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309090",
                "vill_name": "ນາຕ້າຍ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309091",
                "vill_name": "ເລົ່າຫມາກຄ່າ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309092",
                "vill_name": "ຫນອງອີງ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309093",
                "vill_name": "ເລົ່າໜາດ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309094",
                "vill_name": "ຫົວນ້ຳສ້າງ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309095",
                "vill_name": "ບົກ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309096",
                "vill_name": "ຫ້ວຍຊາຍ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309097",
                "vill_name": "ຕານສຸມ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309098",
                "vill_name": "ດອນເຄັງ",
                "vill_name_en": "",
                "dr_id": "1309"
              },
              {
                "vill_id": "1309099",
                "vill_name": "ເລົ່າຫົວຄຳ",
                "vill_name_en": "",
                "dr_id": "1309"
              }
            ]
          },
          {
            "dr_id": "1310",
            "dr_name": "ຊົນບູລີ",
            "dr_name_en": "Xonbuly",
            "pr_id": "13",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "1013001",
                "vill_name": "ຫນອງຜຳ",
                "vill_name_en": "",
                "dr_id": "1310"
              },
              {
                "vill_id": "1013002",
                "vill_name": "ນາຫໍຫລວງ",
                "vill_name_en": "",
                "dr_id": "1310"
              },
              {
                "vill_id": "1013003",
                "vill_name": "ຕຸ່ມແຍ",
                "vill_name_en": "",
                "dr_id": "1310"
              },
              {
                "vill_id": "1013004",
                "vill_name": "ດົງບູນ",
                "vill_name_en": "",
                "dr_id": "1310"
              },
              {
                "vill_id": "1013005",
                "vill_name": "ໂນນຄູ້ເຫນືອ",
                "vill_name_en": "",
                "dr_id": "1310"
              },
              {
                "vill_id": "1013006",
                "vill_name": "ນາຫວ້າສົມໄຊ",
                "vill_name_en": "",
                "dr_id": "1310"
              },
              {
                "vill_id": "1013007",
                "vill_name": "ໂນນຮັັ່ງມີ",
                "vill_name_en": "",
                "dr_id": "1310"
              },
              {
                "vill_id": "1013008",
                "vill_name": "ວິໄຊ",
                "vill_name_en": "",
                "dr_id": "1310"
              },
              {
                "vill_id": "1013009",
                "vill_name": "ອະຫໍ",
                "vill_name_en": "",
                "dr_id": "1310"
              },
              {
                "vill_id": "1013010",
                "vill_name": "ອຳພາວັງ",
                "vill_name_en": "",
                "dr_id": "1310"
              },
              {
                "vill_id": "1013011",
                "vill_name": "ນາແພງ",
                "vill_name_en": "",
                "dr_id": "1310"
              },
              {
                "vill_id": "1013012",
                "vill_name": "ນາບົງ",
                "vill_name_en": "",
                "dr_id": "1310"
              },
              {
                "vill_id": "1013013",
                "vill_name": "ຫນອງເບັນ",
                "vill_name_en": "",
                "dr_id": "1310"
              },
              {
                "vill_id": "1013014",
                "vill_name": "ກະເບົາວັດ",
                "vill_name_en": "",
                "dr_id": "1310"
              },
              {
                "vill_id": "1013015",
                "vill_name": "ກະເບົາ",
                "vill_name_en": "",
                "dr_id": "1310"
              },
              {
                "vill_id": "1013016",
                "vill_name": "ວັງໂຄນຄຳ",
                "vill_name_en": "",
                "dr_id": "1310"
              },
              {
                "vill_id": "1013017",
                "vill_name": "ໂນນສະຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "1310"
              },
              {
                "vill_id": "1013018",
                "vill_name": "ກອງປະທຸມວັນ",
                "vill_name_en": "",
                "dr_id": "1310"
              },
              {
                "vill_id": "1013019",
                "vill_name": "ທ່າຄຳເຫລື້ອມ",
                "vill_name_en": "",
                "dr_id": "1310"
              },
              {
                "vill_id": "1013020",
                "vill_name": "ປະຕົງ",
                "vill_name_en": "",
                "dr_id": "1310"
              },
              {
                "vill_id": "1013021",
                "vill_name": "ເຊວັນ",
                "vill_name_en": "",
                "dr_id": "1310"
              },
              {
                "vill_id": "1013022",
                "vill_name": "ຕຳປອງ",
                "vill_name_en": "",
                "dr_id": "1310"
              },
              {
                "vill_id": "1013023",
                "vill_name": "ຫນອງບົວລາວ",
                "vill_name_en": "",
                "dr_id": "1310"
              },
              {
                "vill_id": "1013024",
                "vill_name": "ດົງມົດ",
                "vill_name_en": "",
                "dr_id": "1310"
              },
              {
                "vill_id": "1013025",
                "vill_name": "ນາທອງ",
                "vill_name_en": "",
                "dr_id": "1310"
              },
              {
                "vill_id": "1013026",
                "vill_name": "ປະລຸງ",
                "vill_name_en": "",
                "dr_id": "1310"
              },
              {
                "vill_id": "1013027",
                "vill_name": "ປົ່ງເຊທ່າມວກ",
                "vill_name_en": "",
                "dr_id": "1310"
              },
              {
                "vill_id": "1013028",
                "vill_name": "ນາສະໂນ",
                "vill_name_en": "",
                "dr_id": "1310"
              },
              {
                "vill_id": "1013029",
                "vill_name": "ນາໄຊ",
                "vill_name_en": "",
                "dr_id": "1310"
              },
              {
                "vill_id": "1013030",
                "vill_name": "ດົງອາລາງ",
                "vill_name_en": "",
                "dr_id": "1310"
              },
              {
                "vill_id": "1013031",
                "vill_name": "ອາລາງ",
                "vill_name_en": "",
                "dr_id": "1310"
              },
              {
                "vill_id": "1013032",
                "vill_name": "ປົ່ງ",
                "vill_name_en": "",
                "dr_id": "1310"
              },
              {
                "vill_id": "1013033",
                "vill_name": "ແກງຫລີ່",
                "vill_name_en": "",
                "dr_id": "1310"
              },
              {
                "vill_id": "1013034",
                "vill_name": "ລຳທວາຍ",
                "vill_name_en": "",
                "dr_id": "1310"
              },
              {
                "vill_id": "1013035",
                "vill_name": "ນາລະແອນ",
                "vill_name_en": "",
                "dr_id": "1310"
              },
              {
                "vill_id": "1013036",
                "vill_name": "ຕັ່ງຫວາຍໂຄກ",
                "vill_name_en": "",
                "dr_id": "1310"
              },
              {
                "vill_id": "1013037",
                "vill_name": "ລຳບຽດ",
                "vill_name_en": "",
                "dr_id": "1310"
              },
              {
                "vill_id": "1013038",
                "vill_name": "ກະລຶມຫນອງແຂ້",
                "vill_name_en": "",
                "dr_id": "1310"
              },
              {
                "vill_id": "1013039",
                "vill_name": "ກະທອງເຊ",
                "vill_name_en": "",
                "dr_id": "1310"
              },
              {
                "vill_id": "1013040",
                "vill_name": "ນາຈານໃຫຍ່",
                "vill_name_en": "",
                "dr_id": "1310"
              },
              {
                "vill_id": "1013041",
                "vill_name": "ຕັ່ງຫວາຍນ້ຳ",
                "vill_name_en": "",
                "dr_id": "1310"
              },
              {
                "vill_id": "1013042",
                "vill_name": "ຫນອງສີມ",
                "vill_name_en": "",
                "dr_id": "1310"
              },
              {
                "vill_id": "1013043",
                "vill_name": "ດົງຫນອງແຄນ",
                "vill_name_en": "",
                "dr_id": "1310"
              },
              {
                "vill_id": "1013044",
                "vill_name": "ເມືອງໂຮງ",
                "vill_name_en": "",
                "dr_id": "1310"
              },
              {
                "vill_id": "1013045",
                "vill_name": "ໃຫມ່ສະໜາມໄຊ",
                "vill_name_en": "",
                "dr_id": "1310"
              },
              {
                "vill_id": "1013046",
                "vill_name": "ແກ້ວຄຳດີ",
                "vill_name_en": "",
                "dr_id": "1310"
              },
              {
                "vill_id": "1013047",
                "vill_name": "ດວງມະລາ",
                "vill_name_en": "",
                "dr_id": "1310"
              },
              {
                "vill_id": "1013048",
                "vill_name": "ໄຊສົມບູນ",
                "vill_name_en": "",
                "dr_id": "1310"
              },
              {
                "vill_id": "1013049",
                "vill_name": "ດົງນາງາມ",
                "vill_name_en": "",
                "dr_id": "1310"
              },
              {
                "vill_id": "1013050",
                "vill_name": "ສອງຫ້ອງ",
                "vill_name_en": "",
                "dr_id": "1310"
              },
              {
                "vill_id": "1013052",
                "vill_name": "ໂພນໄຊ​ຄູູນ",
                "vill_name_en": "",
                "dr_id": "1310"
              },
              {
                "vill_id": "1013053",
                "vill_name": "ແສງຈະເລີນ",
                "vill_name_en": "",
                "dr_id": "1310"
              },
              {
                "vill_id": "1013054",
                "vill_name": "ຫນອງຜື",
                "vill_name_en": "",
                "dr_id": "1310"
              },
              {
                "vill_id": "1013055",
                "vill_name": "ດົງຫວາຍ",
                "vill_name_en": "",
                "dr_id": "1310"
              },
              {
                "vill_id": "1013056",
                "vill_name": "ດົງຫົງຄຳ",
                "vill_name_en": "",
                "dr_id": "1310"
              },
              {
                "vill_id": "1013057",
                "vill_name": "ບຶງຊ້າງ",
                "vill_name_en": "",
                "dr_id": "1310"
              },
              {
                "vill_id": "1013058",
                "vill_name": "ຊຽງຮົ່ມ",
                "vill_name_en": "",
                "dr_id": "1310"
              },
              {
                "vill_id": "1013059",
                "vill_name": "ໂຄກຫົວຊ້າງ",
                "vill_name_en": "",
                "dr_id": "1310"
              },
              {
                "vill_id": "1013060",
                "vill_name": "ໄຊວົງທິລາດ",
                "vill_name_en": "",
                "dr_id": "1310"
              },
              {
                "vill_id": "1013061",
                "vill_name": "ໂນນຍາງ",
                "vill_name_en": "",
                "dr_id": "1310"
              },
              {
                "vill_id": "1013062",
                "vill_name": "ນາໂພນທອງ",
                "vill_name_en": "",
                "dr_id": "1310"
              },
              {
                "vill_id": "1013063",
                "vill_name": "ໂພນຕານ",
                "vill_name_en": "",
                "dr_id": "1310"
              },
              {
                "vill_id": "1013064",
                "vill_name": "ດອນຍະໂນງ",
                "vill_name_en": "",
                "dr_id": "1310"
              },
              {
                "vill_id": "1013065",
                "vill_name": "ຫນອງບົວຫລວງ",
                "vill_name_en": "",
                "dr_id": "1310"
              },
              {
                "vill_id": "1013066",
                "vill_name": "ນາຫ່າງ",
                "vill_name_en": "",
                "dr_id": "1310"
              }
            ]
          },
          {
            "dr_id": "1311",
            "dr_name": "ໄຊບູລີ",
            "dr_name_en": "Xaybouly",
            "pr_id": "13",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "1311001",
                "vill_name": "ແກ້ງກະເບົາເຫນືອ",
                "vill_name_en": "",
                "dr_id": "1311"
              },
              {
                "vill_id": "1311002",
                "vill_name": "ແກ້ງກະເບົາໃຕ້",
                "vill_name_en": "",
                "dr_id": "1311"
              },
              {
                "vill_id": "1311003",
                "vill_name": "ຫນອງແວງ",
                "vill_name_en": "",
                "dr_id": "1311"
              },
              {
                "vill_id": "1311004",
                "vill_name": "ພູໂກະ",
                "vill_name_en": "",
                "dr_id": "1311"
              },
              {
                "vill_id": "1311005",
                "vill_name": "ຊຽງໄຄ",
                "vill_name_en": "",
                "dr_id": "1311"
              },
              {
                "vill_id": "1311006",
                "vill_name": "ແກ້ງແວງ",
                "vill_name_en": "",
                "dr_id": "1311"
              },
              {
                "vill_id": "1311007",
                "vill_name": "ດົງຫມາກໄຟ",
                "vill_name_en": "",
                "dr_id": "1311"
              },
              {
                "vill_id": "1311008",
                "vill_name": "ປ່າໄລ່",
                "vill_name_en": "",
                "dr_id": "1311"
              },
              {
                "vill_id": "1311009",
                "vill_name": "ດ້າງສະຫວັນ",
                "vill_name_en": "",
                "dr_id": "1311"
              },
              {
                "vill_id": "1311010",
                "vill_name": "ສີໄຄ",
                "vill_name_en": "",
                "dr_id": "1311"
              },
              {
                "vill_id": "1311011",
                "vill_name": "ຫາດຊາຍສູງ",
                "vill_name_en": "",
                "dr_id": "1311"
              },
              {
                "vill_id": "1311012",
                "vill_name": "ບຶງເຊ",
                "vill_name_en": "",
                "dr_id": "1311"
              },
              {
                "vill_id": "1311013",
                "vill_name": "ຕົ້ນແຫນ",
                "vill_name_en": "",
                "dr_id": "1311"
              },
              {
                "vill_id": "1311014",
                "vill_name": "ເວີນເຫນືອ",
                "vill_name_en": "",
                "dr_id": "1311"
              },
              {
                "vill_id": "1311015",
                "vill_name": "ເວີນໃຕ້",
                "vill_name_en": "",
                "dr_id": "1311"
              },
              {
                "vill_id": "1311016",
                "vill_name": "ໂພນທັນ",
                "vill_name_en": "",
                "dr_id": "1311"
              },
              {
                "vill_id": "1311017",
                "vill_name": "ກ້າງປ່າທ່າບໍ່",
                "vill_name_en": "",
                "dr_id": "1311"
              },
              {
                "vill_id": "1311018",
                "vill_name": "ແກ້ງໂພສີ",
                "vill_name_en": "",
                "dr_id": "1311"
              },
              {
                "vill_id": "1311019",
                "vill_name": "ສະກອງ",
                "vill_name_en": "",
                "dr_id": "1311"
              },
              {
                "vill_id": "1311020",
                "vill_name": "ຜັກເພື້ອ",
                "vill_name_en": "",
                "dr_id": "1311"
              },
              {
                "vill_id": "1311021",
                "vill_name": "ມະນີລາດ",
                "vill_name_en": "",
                "dr_id": "1311"
              },
              {
                "vill_id": "1311022",
                "vill_name": "ກາງ",
                "vill_name_en": "",
                "dr_id": "1311"
              },
              {
                "vill_id": "1311023",
                "vill_name": "ນາແດງ",
                "vill_name_en": "",
                "dr_id": "1311"
              },
              {
                "vill_id": "1311024",
                "vill_name": "ດົງໄພວັນ",
                "vill_name_en": "",
                "dr_id": "1311"
              },
              {
                "vill_id": "1311025",
                "vill_name": "ນາເຮືອງ",
                "vill_name_en": "",
                "dr_id": "1311"
              },
              {
                "vill_id": "1311026",
                "vill_name": "ຫນອງຂຽດເຫລືອງ",
                "vill_name_en": "",
                "dr_id": "1311"
              },
              {
                "vill_id": "1311027",
                "vill_name": "ນາມ່ວງ",
                "vill_name_en": "",
                "dr_id": "1311"
              },
              {
                "vill_id": "1311028",
                "vill_name": "ກະຈຸ",
                "vill_name_en": "",
                "dr_id": "1311"
              },
              {
                "vill_id": "1311029",
                "vill_name": "ດົງຍາງ",
                "vill_name_en": "",
                "dr_id": "1311"
              },
              {
                "vill_id": "1311030",
                "vill_name": "ດົງພູ້",
                "vill_name_en": "",
                "dr_id": "1311"
              },
              {
                "vill_id": "1311031",
                "vill_name": "ໝາກນາວ",
                "vill_name_en": "",
                "dr_id": "1311"
              },
              {
                "vill_id": "1311032",
                "vill_name": "ເຄືອເຂົາກາດ",
                "vill_name_en": "",
                "dr_id": "1311"
              },
              {
                "vill_id": "1311033",
                "vill_name": "ສົມສະອາດ",
                "vill_name_en": "",
                "dr_id": "1311"
              },
              {
                "vill_id": "1311034",
                "vill_name": "ດົງພ້າວ",
                "vill_name_en": "",
                "dr_id": "1311"
              },
              {
                "vill_id": "1311035",
                "vill_name": "ນາວໃຕ້",
                "vill_name_en": "",
                "dr_id": "1311"
              },
              {
                "vill_id": "1311036",
                "vill_name": "ຫ້ວຍແຮ່",
                "vill_name_en": "",
                "dr_id": "1311"
              },
              {
                "vill_id": "1311037",
                "vill_name": "ຫນອງເຮືອທອງ",
                "vill_name_en": "",
                "dr_id": "1311"
              },
              {
                "vill_id": "1311038",
                "vill_name": "ນາສອບ",
                "vill_name_en": "",
                "dr_id": "1311"
              },
              {
                "vill_id": "1311039",
                "vill_name": "ຍາງຄ້ຳ",
                "vill_name_en": "",
                "dr_id": "1311"
              },
              {
                "vill_id": "1311040",
                "vill_name": "ເລົ່າຝ້າຍ",
                "vill_name_en": "",
                "dr_id": "1311"
              },
              {
                "vill_id": "1311041",
                "vill_name": "ແກ້ງແຮດ",
                "vill_name_en": "",
                "dr_id": "1311"
              },
              {
                "vill_id": "1311042",
                "vill_name": "ປົ່ງປາກເຊ",
                "vill_name_en": "",
                "dr_id": "1311"
              },
              {
                "vill_id": "1311043",
                "vill_name": "ບົວໄຂ",
                "vill_name_en": "",
                "dr_id": "1311"
              },
              {
                "vill_id": "1311044",
                "vill_name": "ນາສັງ",
                "vill_name_en": "",
                "dr_id": "1311"
              },
              {
                "vill_id": "1311045",
                "vill_name": "ທ່າຂາມ",
                "vill_name_en": "",
                "dr_id": "1311"
              },
              {
                "vill_id": "1311046",
                "vill_name": "ທ່າຄໍ້",
                "vill_name_en": "",
                "dr_id": "1311"
              },
              {
                "vill_id": "1311047",
                "vill_name": "ສີວິໄລ",
                "vill_name_en": "",
                "dr_id": "1311"
              },
              {
                "vill_id": "1311048",
                "vill_name": "ຄຳໂນນສູງ",
                "vill_name_en": "",
                "dr_id": "1311"
              },
              {
                "vill_id": "1311049",
                "vill_name": "ດົງພຸງ",
                "vill_name_en": "",
                "dr_id": "1311"
              },
              {
                "vill_id": "1311050",
                "vill_name": "ຫນອງສະພັງ",
                "vill_name_en": "",
                "dr_id": "1311"
              },
              {
                "vill_id": "1311051",
                "vill_name": "ສະຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "1311"
              },
              {
                "vill_id": "1311052",
                "vill_name": "ນາເທິງ",
                "vill_name_en": "",
                "dr_id": "1311"
              },
              {
                "vill_id": "1311053",
                "vill_name": "ຜັກຂະຫຍ່າ",
                "vill_name_en": "",
                "dr_id": "1311"
              },
              {
                "vill_id": "1311054",
                "vill_name": "ກາງ",
                "vill_name_en": "",
                "dr_id": "1311"
              }
            ]
          },
          {
            "dr_id": "1312",
            "dr_name": "ວິລະບູລີ",
            "dr_name_en": "Vilabuly",
            "pr_id": "13",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "1312001",
                "vill_name": "ກະຫລາຍ",
                "vill_name_en": "",
                "dr_id": "1312"
              },
              {
                "vill_id": "1312002",
                "vill_name": "ອາປອນ",
                "vill_name_en": "",
                "dr_id": "1312"
              },
              {
                "vill_id": "1312003",
                "vill_name": "ປະກອຍ",
                "vill_name_en": "",
                "dr_id": "1312"
              },
              {
                "vill_id": "1312004",
                "vill_name": "ຫ້ວຍຮ່ອງ",
                "vill_name_en": "",
                "dr_id": "1312"
              },
              {
                "vill_id": "1312005",
                "vill_name": "ແກ້ງເຫລັກ",
                "vill_name_en": "",
                "dr_id": "1312"
              },
              {
                "vill_id": "1312006",
                "vill_name": "ທົ່ງຫລວງ",
                "vill_name_en": "",
                "dr_id": "1312"
              },
              {
                "vill_id": "1312007",
                "vill_name": "ສົບສະລຸງ",
                "vill_name_en": "",
                "dr_id": "1312"
              },
              {
                "vill_id": "1312008",
                "vill_name": "ອ່າງຄຳ",
                "vill_name_en": "",
                "dr_id": "1312"
              },
              {
                "vill_id": "1312009",
                "vill_name": "ວັງມະຮັງ",
                "vill_name_en": "",
                "dr_id": "1312"
              },
              {
                "vill_id": "1312010",
                "vill_name": "ນ້ຳມະຮີ",
                "vill_name_en": "",
                "dr_id": "1312"
              },
              {
                "vill_id": "1312011",
                "vill_name": "ນ້ຳປະເຄນ",
                "vill_name_en": "",
                "dr_id": "1312"
              },
              {
                "vill_id": "1312012",
                "vill_name": "ວັງທ່າຄວາຍ",
                "vill_name_en": "",
                "dr_id": "1312"
              },
              {
                "vill_id": "1312013",
                "vill_name": "ນາຫອຍ",
                "vill_name_en": "",
                "dr_id": "1312"
              },
              {
                "vill_id": "1312014",
                "vill_name": "ແກ້ງເກິກ",
                "vill_name_en": "",
                "dr_id": "1312"
              },
              {
                "vill_id": "1312015",
                "vill_name": "ນາກະຊີນ",
                "vill_name_en": "",
                "dr_id": "1312"
              },
              {
                "vill_id": "1312016",
                "vill_name": "ເມືອງຫລວງ",
                "vill_name_en": "",
                "dr_id": "1312"
              },
              {
                "vill_id": "1312017",
                "vill_name": "ນ້ຳຈະໂລະ",
                "vill_name_en": "",
                "dr_id": "1312"
              },
              {
                "vill_id": "1312018",
                "vill_name": "ນ້ຳປາ",
                "vill_name_en": "",
                "dr_id": "1312"
              },
              {
                "vill_id": "1312019",
                "vill_name": "ສົບປາ",
                "vill_name_en": "",
                "dr_id": "1312"
              },
              {
                "vill_id": "1312020",
                "vill_name": "ວັງຍາງ",
                "vill_name_en": "",
                "dr_id": "1312"
              },
              {
                "vill_id": "1312021",
                "vill_name": "ສົບກົງ",
                "vill_name_en": "",
                "dr_id": "1312"
              },
              {
                "vill_id": "1312022",
                "vill_name": "ໜອງກະດ້າງ",
                "vill_name_en": "",
                "dr_id": "1312"
              },
              {
                "vill_id": "1312023",
                "vill_name": "ໂພນສະອາດ",
                "vill_name_en": "",
                "dr_id": "1312"
              },
              {
                "vill_id": "1312024",
                "vill_name": "ຜາຂະເຍາະ",
                "vill_name_en": "",
                "dr_id": "1312"
              },
              {
                "vill_id": "1312025",
                "vill_name": "ທາງແບງແກ້ງຫລວງ",
                "vill_name_en": "",
                "dr_id": "1312"
              },
              {
                "vill_id": "1312026",
                "vill_name": "ແກ້ງເຫລັກໃຕ້",
                "vill_name_en": "",
                "dr_id": "1312"
              },
              {
                "vill_id": "1312027",
                "vill_name": "ກົກໝາກ",
                "vill_name_en": "",
                "dr_id": "1312"
              },
              {
                "vill_id": "1312028",
                "vill_name": "ຕໍເຮືອ",
                "vill_name_en": "",
                "dr_id": "1312"
              },
              {
                "vill_id": "1312029",
                "vill_name": "ຫ້ວຍດ່າງ",
                "vill_name_en": "",
                "dr_id": "1312"
              },
              {
                "vill_id": "1312030",
                "vill_name": "ນ້ຳຄິບ",
                "vill_name_en": "",
                "dr_id": "1312"
              },
              {
                "vill_id": "1312031",
                "vill_name": "ໂນນສົມບູນ",
                "vill_name_en": "",
                "dr_id": "1312"
              },
              {
                "vill_id": "1312032",
                "vill_name": "ປະດົງ",
                "vill_name_en": "",
                "dr_id": "1312"
              },
              {
                "vill_id": "1312033",
                "vill_name": "ບຸ່ງຄຳ",
                "vill_name_en": "",
                "dr_id": "1312"
              },
              {
                "vill_id": "1312034",
                "vill_name": "ຫ້ວຍຊ້ວນ",
                "vill_name_en": "",
                "dr_id": "1312"
              },
              {
                "vill_id": "1312035",
                "vill_name": "ຜາພີລາງ",
                "vill_name_en": "",
                "dr_id": "1312"
              },
              {
                "vill_id": "1312036",
                "vill_name": "ທາດ",
                "vill_name_en": "",
                "dr_id": "1312"
              },
              {
                "vill_id": "1312037",
                "vill_name": "ນາເຕີ້",
                "vill_name_en": "",
                "dr_id": "1312"
              },
              {
                "vill_id": "1312038",
                "vill_name": "ໄຮ່ດົງບັງ",
                "vill_name_en": "",
                "dr_id": "1312"
              },
              {
                "vill_id": "1312039",
                "vill_name": "ນາຍົມ",
                "vill_name_en": "",
                "dr_id": "1312"
              },
              {
                "vill_id": "1312040",
                "vill_name": "ນາແອະ",
                "vill_name_en": "",
                "dr_id": "1312"
              },
              {
                "vill_id": "1312041",
                "vill_name": "ໂຄກ",
                "vill_name_en": "",
                "dr_id": "1312"
              },
              {
                "vill_id": "1312042",
                "vill_name": "ນາຊູ",
                "vill_name_en": "",
                "dr_id": "1312"
              },
              {
                "vill_id": "1312043",
                "vill_name": "ເລ່ົາຫລວງ",
                "vill_name_en": "",
                "dr_id": "1312"
              },
              {
                "vill_id": "1312044",
                "vill_name": "ຂີ້ຄັ່ງ",
                "vill_name_en": "",
                "dr_id": "1312"
              },
              {
                "vill_id": "1312045",
                "vill_name": "ນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1312"
              },
              {
                "vill_id": "1312046",
                "vill_name": "ປ່າຜັກເໜົ່າ",
                "vill_name_en": "",
                "dr_id": "1312"
              },
              {
                "vill_id": "1312047",
                "vill_name": "ນາສະເລາະ",
                "vill_name_en": "",
                "dr_id": "1312"
              },
              {
                "vill_id": "1312048",
                "vill_name": "ຫນອງກະປາງ",
                "vill_name_en": "",
                "dr_id": "1312"
              },
              {
                "vill_id": "1312049",
                "vill_name": "ດົງຕ້ອງ",
                "vill_name_en": "",
                "dr_id": "1312"
              },
              {
                "vill_id": "1312050",
                "vill_name": "ນາກະຈັນ",
                "vill_name_en": "",
                "dr_id": "1312"
              },
              {
                "vill_id": "1312051",
                "vill_name": "ຂົວງະ",
                "vill_name_en": "",
                "dr_id": "1312"
              },
              {
                "vill_id": "1312052",
                "vill_name": "ນານ້ຳສ້າງ",
                "vill_name_en": "",
                "dr_id": "1312"
              },
              {
                "vill_id": "1312053",
                "vill_name": "ກົກຕ້ອງ",
                "vill_name_en": "",
                "dr_id": "1312"
              },
              {
                "vill_id": "1312054",
                "vill_name": "ນາແກ",
                "vill_name_en": "",
                "dr_id": "1312"
              },
              {
                "vill_id": "1312055",
                "vill_name": "ເລົົ່າ",
                "vill_name_en": "",
                "dr_id": "1312"
              },
              {
                "vill_id": "1312056",
                "vill_name": "ໃຫມ່ນ້ຳແມ້ງ",
                "vill_name_en": "",
                "dr_id": "1312"
              },
              {
                "vill_id": "1312057",
                "vill_name": "ນ້ຳຄື້ນ",
                "vill_name_en": "",
                "dr_id": "1312"
              },
              {
                "vill_id": "1312058",
                "vill_name": "ນາໂພ",
                "vill_name_en": "",
                "dr_id": "1312"
              },
              {
                "vill_id": "1312059",
                "vill_name": "ນາຊຽງແລ",
                "vill_name_en": "",
                "dr_id": "1312"
              },
              {
                "vill_id": "1312060",
                "vill_name": "ນາເມືອງແສນ",
                "vill_name_en": "",
                "dr_id": "1312"
              },
              {
                "vill_id": "1312061",
                "vill_name": "ປຸ່ງປໍ",
                "vill_name_en": "",
                "dr_id": "1312"
              },
              {
                "vill_id": "1312062",
                "vill_name": "ນ້ຳແລບ",
                "vill_name_en": "",
                "dr_id": "1312"
              },
              {
                "vill_id": "1312063",
                "vill_name": "ນາມະລຸ",
                "vill_name_en": "",
                "dr_id": "1312"
              },
              {
                "vill_id": "1312064",
                "vill_name": "ດົງຍາງ",
                "vill_name_en": "",
                "dr_id": "1312"
              },
              {
                "vill_id": "1312065",
                "vill_name": "ໂພນສະຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "1312"
              },
              {
                "vill_id": "1312066",
                "vill_name": "ສົມນາງາມ",
                "vill_name_en": "",
                "dr_id": "1312"
              },
              {
                "vill_id": "1312067",
                "vill_name": "ໂນນສຳລານ",
                "vill_name_en": "",
                "dr_id": "1312"
              },
              {
                "vill_id": "1312068",
                "vill_name": "ກະແຕບ",
                "vill_name_en": "",
                "dr_id": "1312"
              },
              {
                "vill_id": "1312069",
                "vill_name": "ໂນນສົມພູ",
                "vill_name_en": "",
                "dr_id": "1312"
              }
            ]
          },
          {
            "dr_id": "1313",
            "dr_name": "ອາດສະພອນ",
            "dr_name_en": "Atsaphone",
            "pr_id": "13",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "1313001",
                "vill_name": "ຜັກຂະຫຍ່າ",
                "vill_name_en": "",
                "dr_id": "1313"
              },
              {
                "vill_id": "1313002",
                "vill_name": "ຫາດດອກແກ້ວ",
                "vill_name_en": "",
                "dr_id": "1313"
              },
              {
                "vill_id": "1313003",
                "vill_name": "ນາກຸດຈານ",
                "vill_name_en": "",
                "dr_id": "1313"
              },
              {
                "vill_id": "1313004",
                "vill_name": "ດົງທະດາ",
                "vill_name_en": "",
                "dr_id": "1313"
              },
              {
                "vill_id": "1313005",
                "vill_name": "ດົງຫວາຍດິນ",
                "vill_name_en": "",
                "dr_id": "1313"
              },
              {
                "vill_id": "1313006",
                "vill_name": "ນາວັງມົນ",
                "vill_name_en": "",
                "dr_id": "1313"
              },
              {
                "vill_id": "1313007",
                "vill_name": "ນາຮັງນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1313"
              },
              {
                "vill_id": "1313008",
                "vill_name": "ຫນອງເດີນ",
                "vill_name_en": "",
                "dr_id": "1313"
              },
              {
                "vill_id": "1313009",
                "vill_name": "ກຸດແຄນ",
                "vill_name_en": "",
                "dr_id": "1313"
              },
              {
                "vill_id": "1313010",
                "vill_name": "ສອງຫ້ອງ",
                "vill_name_en": "",
                "dr_id": "1313"
              },
              {
                "vill_id": "1313011",
                "vill_name": "ສົ້ມໂຮງ",
                "vill_name_en": "",
                "dr_id": "1313"
              },
              {
                "vill_id": "1313012",
                "vill_name": "ກຸດຊຸງ",
                "vill_name_en": "",
                "dr_id": "1313"
              },
              {
                "vill_id": "1313013",
                "vill_name": "ນາເດື່ອ",
                "vill_name_en": "",
                "dr_id": "1313"
              },
              {
                "vill_id": "1313014",
                "vill_name": "ນາເຂົ້າຫມື່ນ",
                "vill_name_en": "",
                "dr_id": "1313"
              },
              {
                "vill_id": "1313015",
                "vill_name": "ຫີນສິ້ວ",
                "vill_name_en": "",
                "dr_id": "1313"
              },
              {
                "vill_id": "1313016",
                "vill_name": "ນາຫມາກເກືອ",
                "vill_name_en": "",
                "dr_id": "1313"
              },
              {
                "vill_id": "1313017",
                "vill_name": "ດອນກອງ",
                "vill_name_en": "",
                "dr_id": "1313"
              },
              {
                "vill_id": "1313018",
                "vill_name": "ກົກແດງ",
                "vill_name_en": "",
                "dr_id": "1313"
              },
              {
                "vill_id": "1313019",
                "vill_name": "ໂພນຕຸມ",
                "vill_name_en": "",
                "dr_id": "1313"
              },
              {
                "vill_id": "1313020",
                "vill_name": "ໂພໄຊ",
                "vill_name_en": "",
                "dr_id": "1313"
              },
              {
                "vill_id": "1313021",
                "vill_name": "ໂພນນາດີ",
                "vill_name_en": "",
                "dr_id": "1313"
              },
              {
                "vill_id": "1313022",
                "vill_name": "ປືງ",
                "vill_name_en": "",
                "dr_id": "1313"
              },
              {
                "vill_id": "1313023",
                "vill_name": "ໂພນແພງ",
                "vill_name_en": "",
                "dr_id": "1313"
              },
              {
                "vill_id": "1313024",
                "vill_name": "ກະທິງໃຫຍ່",
                "vill_name_en": "",
                "dr_id": "1313"
              },
              {
                "vill_id": "1313025",
                "vill_name": "ໂພນງາມ",
                "vill_name_en": "",
                "dr_id": "1313"
              },
              {
                "vill_id": "1313026",
                "vill_name": "ຜັກກະດາ",
                "vill_name_en": "",
                "dr_id": "1313"
              },
              {
                "vill_id": "1313027",
                "vill_name": "ຕາດຂາແດງ",
                "vill_name_en": "",
                "dr_id": "1313"
              },
              {
                "vill_id": "1313028",
                "vill_name": "ນານ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1313"
              },
              {
                "vill_id": "1313029",
                "vill_name": "ນາແຄ",
                "vill_name_en": "",
                "dr_id": "1313"
              },
              {
                "vill_id": "1313030",
                "vill_name": "ໂນນຍາງ",
                "vill_name_en": "",
                "dr_id": "1313"
              },
              {
                "vill_id": "1313031",
                "vill_name": "ບັງແບດເຊ",
                "vill_name_en": "",
                "dr_id": "1313"
              },
              {
                "vill_id": "1313032",
                "vill_name": "ຄຳພູ",
                "vill_name_en": "",
                "dr_id": "1313"
              },
              {
                "vill_id": "1313033",
                "vill_name": "ກາງທ່າ",
                "vill_name_en": "",
                "dr_id": "1313"
              },
              {
                "vill_id": "1313034",
                "vill_name": "ດົງຄຳກວນ",
                "vill_name_en": "",
                "dr_id": "1313"
              },
              {
                "vill_id": "1313035",
                "vill_name": "ໂຄກກະຕ່າຍ",
                "vill_name_en": "",
                "dr_id": "1313"
              },
              {
                "vill_id": "1313036",
                "vill_name": "ແກ້ງແດງ",
                "vill_name_en": "",
                "dr_id": "1313"
              },
              {
                "vill_id": "1313037",
                "vill_name": "ຫ້ວຍຍາງ",
                "vill_name_en": "",
                "dr_id": "1313"
              },
              {
                "vill_id": "1313038",
                "vill_name": "ແກ້ງແມວ",
                "vill_name_en": "",
                "dr_id": "1313"
              },
              {
                "vill_id": "1313039",
                "vill_name": "ແກ້ງມູມ",
                "vill_name_en": "",
                "dr_id": "1313"
              },
              {
                "vill_id": "1313040",
                "vill_name": "ໂພນບົກ",
                "vill_name_en": "",
                "dr_id": "1313"
              },
              {
                "vill_id": "1313041",
                "vill_name": "ສົບເຊ",
                "vill_name_en": "",
                "dr_id": "1313"
              },
              {
                "vill_id": "1313042",
                "vill_name": "ຫ້ວຍງົວຄຳ",
                "vill_name_en": "",
                "dr_id": "1313"
              },
              {
                "vill_id": "1313043",
                "vill_name": "ດົງຂັນຄຳ",
                "vill_name_en": "",
                "dr_id": "1313"
              },
              {
                "vill_id": "1313044",
                "vill_name": "ນາຍອນ",
                "vill_name_en": "",
                "dr_id": "1313"
              },
              {
                "vill_id": "1313045",
                "vill_name": "ຈອມຮາດ",
                "vill_name_en": "",
                "dr_id": "1313"
              },
              {
                "vill_id": "1313046",
                "vill_name": "ບຶງພະເນາະ",
                "vill_name_en": "",
                "dr_id": "1313"
              },
              {
                "vill_id": "1313047",
                "vill_name": "ນາກະບົງ",
                "vill_name_en": "",
                "dr_id": "1313"
              },
              {
                "vill_id": "1313048",
                "vill_name": "ນາຂາມ",
                "vill_name_en": "",
                "dr_id": "1313"
              },
              {
                "vill_id": "1313049",
                "vill_name": "ນາເລົ່າ",
                "vill_name_en": "",
                "dr_id": "1313"
              },
              {
                "vill_id": "1313050",
                "vill_name": "ຫ້ວຍເດື່ອ",
                "vill_name_en": "",
                "dr_id": "1313"
              },
              {
                "vill_id": "1313051",
                "vill_name": "ສິງສະຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "1313"
              },
              {
                "vill_id": "1313052",
                "vill_name": "ນາທົົ່ມ",
                "vill_name_en": "",
                "dr_id": "1313"
              },
              {
                "vill_id": "1313053",
                "vill_name": "ກຸດຫີນ",
                "vill_name_en": "",
                "dr_id": "1313"
              },
              {
                "vill_id": "1313054",
                "vill_name": "ຕົ້ນໂພສີ",
                "vill_name_en": "",
                "dr_id": "1313"
              },
              {
                "vill_id": "1313055",
                "vill_name": "ໄຊໂພນສິມ",
                "vill_name_en": "",
                "dr_id": "1313"
              },
              {
                "vill_id": "1313056",
                "vill_name": "ນາປອດ",
                "vill_name_en": "",
                "dr_id": "1313"
              },
              {
                "vill_id": "1313057",
                "vill_name": "ໂນໄຊ",
                "vill_name_en": "Nosai",
                "dr_id": "1313"
              }
            ]
          },
          {
            "dr_id": "1314",
            "dr_name": "ໄຊພູທອງ",
            "dr_name_en": "Xayphoothong",
            "pr_id": "13",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "1314001",
                "vill_name": "ຄຳຊັນ",
                "vill_name_en": "",
                "dr_id": "1314"
              },
              {
                "vill_id": "1314002",
                "vill_name": "ຂົວດຳ",
                "vill_name_en": "",
                "dr_id": "1314"
              },
              {
                "vill_id": "1314003",
                "vill_name": "ຄຳແຮັງ",
                "vill_name_en": "",
                "dr_id": "1314"
              },
              {
                "vill_id": "1314004",
                "vill_name": "ດົງໂພສີ",
                "vill_name_en": "",
                "dr_id": "1314"
              },
              {
                "vill_id": "1314005",
                "vill_name": "ນ້ຳ​ພຸ",
                "vill_name_en": "",
                "dr_id": "1314"
              },
              {
                "vill_id": "1314006",
                "vill_name": "ນາໂດນ",
                "vill_name_en": "",
                "dr_id": "1314"
              },
              {
                "vill_id": "1314007",
                "vill_name": "ມ່ວງໄຂ່",
                "vill_name_en": "",
                "dr_id": "1314"
              },
              {
                "vill_id": "1314008",
                "vill_name": "ດອນຕູມ",
                "vill_name_en": "",
                "dr_id": "1314"
              },
              {
                "vill_id": "1314009",
                "vill_name": "ດົງຫມາກໄຟ",
                "vill_name_en": "",
                "dr_id": "1314"
              },
              {
                "vill_id": "1314010",
                "vill_name": "ສົມສະອາດ",
                "vill_name_en": "",
                "dr_id": "1314"
              },
              {
                "vill_id": "1314011",
                "vill_name": "ທ່າໂພ",
                "vill_name_en": "",
                "dr_id": "1314"
              },
              {
                "vill_id": "1314012",
                "vill_name": "ຫົວຫາດ",
                "vill_name_en": "",
                "dr_id": "1314"
              },
              {
                "vill_id": "1314013",
                "vill_name": "ບູ່ງນາດີ",
                "vill_name_en": "",
                "dr_id": "1314"
              },
              {
                "vill_id": "1314014",
                "vill_name": "ນາພານ",
                "vill_name_en": "",
                "dr_id": "1314"
              },
              {
                "vill_id": "1314015",
                "vill_name": "ທ່າດ່ານ",
                "vill_name_en": "",
                "dr_id": "1314"
              },
              {
                "vill_id": "1314016",
                "vill_name": "ຄັນທ່າຈານ",
                "vill_name_en": "",
                "dr_id": "1314"
              },
              {
                "vill_id": "1314018",
                "vill_name": "ດອນຫວາຍ",
                "vill_name_en": "",
                "dr_id": "1314"
              },
              {
                "vill_id": "1314019",
                "vill_name": "ພູມມະເຈດີ",
                "vill_name_en": "",
                "dr_id": "1314"
              },
              {
                "vill_id": "1314020",
                "vill_name": "ນາຫມາກເກືອ",
                "vill_name_en": "",
                "dr_id": "1314"
              },
              {
                "vill_id": "1314021",
                "vill_name": "ໂພນສົ້ມໂຮງ",
                "vill_name_en": "",
                "dr_id": "1314"
              },
              {
                "vill_id": "1314022",
                "vill_name": "ຕາກແດດ",
                "vill_name_en": "",
                "dr_id": "1314"
              },
              {
                "vill_id": "1314023",
                "vill_name": "ໂພນທາດ",
                "vill_name_en": "",
                "dr_id": "1314"
              },
              {
                "vill_id": "1314024",
                "vill_name": "ສີສະຫວ່າງເກົ່າ",
                "vill_name_en": "",
                "dr_id": "1314"
              },
              {
                "vill_id": "1314025",
                "vill_name": "ໂພສີແກ້ວ",
                "vill_name_en": "",
                "dr_id": "1314"
              },
              {
                "vill_id": "1314026",
                "vill_name": "ນາ​ຄຳ",
                "vill_name_en": "",
                "dr_id": "1314"
              },
              {
                "vill_id": "1314027",
                "vill_name": "ນາລະໂອງ",
                "vill_name_en": "",
                "dr_id": "1314"
              },
              {
                "vill_id": "1314028",
                "vill_name": "ຄຳແສນ​ໄຊ",
                "vill_name_en": "",
                "dr_id": "1314"
              },
              {
                "vill_id": "1314029",
                "vill_name": "ໂພນທັນ",
                "vill_name_en": "",
                "dr_id": "1314"
              },
              {
                "vill_id": "1314030",
                "vill_name": "ຫ້ວຍຂະຍອງ",
                "vill_name_en": "",
                "dr_id": "1314"
              },
              {
                "vill_id": "1314031",
                "vill_name": "ເວີນຄູນ",
                "vill_name_en": "",
                "dr_id": "1314"
              },
              {
                "vill_id": "1314032",
                "vill_name": "ເຫລົ່າຫມາກຫູດ",
                "vill_name_en": "",
                "dr_id": "1314"
              },
              {
                "vill_id": "1314033",
                "vill_name": "ນາໂມງ",
                "vill_name_en": "",
                "dr_id": "1314"
              },
              {
                "vill_id": "1314034",
                "vill_name": "ສີສະຫວ່າງໃຕ້",
                "vill_name_en": "",
                "dr_id": "1314"
              },
              {
                "vill_id": "1314035",
                "vill_name": "ດອນສະໂນດ",
                "vill_name_en": "",
                "dr_id": "1314"
              },
              {
                "vill_id": "1314036",
                "vill_name": "ດົງດອກໄມ້",
                "vill_name_en": "",
                "dr_id": "1314"
              },
              {
                "vill_id": "1314037",
                "vill_name": "ເຮືອນຫີນ",
                "vill_name_en": "",
                "dr_id": "1314"
              },
              {
                "vill_id": "1314038",
                "vill_name": "ຫ້ວຍມ່ວງ",
                "vill_name_en": "",
                "dr_id": "1314"
              },
              {
                "vill_id": "1314039",
                "vill_name": "ນາດູ່",
                "vill_name_en": "",
                "dr_id": "1314"
              },
              {
                "vill_id": "1314040",
                "vill_name": "ນາບໍ່",
                "vill_name_en": "",
                "dr_id": "1314"
              },
              {
                "vill_id": "1314041",
                "vill_name": "ນາຈານ",
                "vill_name_en": "",
                "dr_id": "1314"
              }
            ]
          },
          {
            "dr_id": "1315",
            "dr_name": "ພະລານໄຊ",
            "dr_name_en": "Phalanxay",
            "pr_id": "13",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "1315001",
                "vill_name": "ຊ່າງແສງ",
                "vill_name_en": "",
                "dr_id": "1315"
              },
              {
                "vill_id": "1315002",
                "vill_name": "ນາຮູ",
                "vill_name_en": "",
                "dr_id": "1315"
              },
              {
                "vill_id": "1315003",
                "vill_name": "ນາທູ້ໃຫມ່",
                "vill_name_en": "",
                "dr_id": "1315"
              },
              {
                "vill_id": "1315004",
                "vill_name": "ນາທູ້ໃຫຍ່",
                "vill_name_en": "",
                "dr_id": "1315"
              },
              {
                "vill_id": "1315005",
                "vill_name": "ນາທ່ອນ",
                "vill_name_en": "",
                "dr_id": "1315"
              },
              {
                "vill_id": "1315006",
                "vill_name": "ແກ້ງຫລວງ",
                "vill_name_en": "",
                "dr_id": "1315"
              },
              {
                "vill_id": "1315007",
                "vill_name": "ສົບອູ່",
                "vill_name_en": "",
                "dr_id": "1315"
              },
              {
                "vill_id": "1315008",
                "vill_name": "ໂພນ",
                "vill_name_en": "",
                "dr_id": "1315"
              },
              {
                "vill_id": "1315009",
                "vill_name": "ນາຂັ້ນໃດ",
                "vill_name_en": "",
                "dr_id": "1315"
              },
              {
                "vill_id": "1315010",
                "vill_name": "ນາຈັນໄຕ",
                "vill_name_en": "",
                "dr_id": "1315"
              },
              {
                "vill_id": "1315011",
                "vill_name": "ນາຊະຄຸ",
                "vill_name_en": "",
                "dr_id": "1315"
              },
              {
                "vill_id": "1315012",
                "vill_name": "ຫີນແຕກ",
                "vill_name_en": "",
                "dr_id": "1315"
              },
              {
                "vill_id": "1315013",
                "vill_name": "ກະສົກເຫນືອ",
                "vill_name_en": "",
                "dr_id": "1315"
              },
              {
                "vill_id": "1315014",
                "vill_name": "ໂພນບົກ",
                "vill_name_en": "",
                "dr_id": "1315"
              },
              {
                "vill_id": "1315015",
                "vill_name": "ກະແສນ",
                "vill_name_en": "",
                "dr_id": "1315"
              },
              {
                "vill_id": "1315016",
                "vill_name": "ພະລານໃຕ້",
                "vill_name_en": "",
                "dr_id": "1315"
              },
              {
                "vill_id": "1315017",
                "vill_name": "ກະຫລອງເຫນືອ",
                "vill_name_en": "",
                "dr_id": "1315"
              },
              {
                "vill_id": "1315018",
                "vill_name": "ກະຫລອງໃຕ້",
                "vill_name_en": "",
                "dr_id": "1315"
              },
              {
                "vill_id": "1315019",
                "vill_name": "ນາກາງເຊ",
                "vill_name_en": "",
                "dr_id": "1315"
              },
              {
                "vill_id": "1315020",
                "vill_name": "ພະລານເຫນືອ",
                "vill_name_en": "",
                "dr_id": "1315"
              },
              {
                "vill_id": "1315021",
                "vill_name": "ໂພໄຊ",
                "vill_name_en": "",
                "dr_id": "1315"
              },
              {
                "vill_id": "1315022",
                "vill_name": "ພະນົມໄຊ",
                "vill_name_en": "",
                "dr_id": "1315"
              },
              {
                "vill_id": "1315023",
                "vill_name": "ຫົວເມືອງເໜືອ",
                "vill_name_en": "",
                "dr_id": "1315"
              },
              {
                "vill_id": "1315024",
                "vill_name": "ນາກາງທົ່ງ",
                "vill_name_en": "",
                "dr_id": "1315"
              },
              {
                "vill_id": "1315025",
                "vill_name": "ນາຫມາກມີ້",
                "vill_name_en": "",
                "dr_id": "1315"
              },
              {
                "vill_id": "1315026",
                "vill_name": "ຫນອງແວງ",
                "vill_name_en": "",
                "dr_id": "1315"
              },
              {
                "vill_id": "1315027",
                "vill_name": "ໂພນຕານ",
                "vill_name_en": "",
                "dr_id": "1315"
              },
              {
                "vill_id": "1315028",
                "vill_name": "ຫນອງແວງ",
                "vill_name_en": "",
                "dr_id": "1315"
              },
              {
                "vill_id": "1315029",
                "vill_name": "ນາໂພສົມບູນ",
                "vill_name_en": "",
                "dr_id": "1315"
              },
              {
                "vill_id": "1315030",
                "vill_name": "ນາໂພວັດ",
                "vill_name_en": "",
                "dr_id": "1315"
              },
              {
                "vill_id": "1315031",
                "vill_name": "ແກ້ງຈິບ",
                "vill_name_en": "",
                "dr_id": "1315"
              },
              {
                "vill_id": "1315032",
                "vill_name": "ເຫີ້ມໃຫຍ່",
                "vill_name_en": "",
                "dr_id": "1315"
              },
              {
                "vill_id": "1315033",
                "vill_name": "ເຫີ້ມນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1315"
              },
              {
                "vill_id": "1315034",
                "vill_name": "ຊຽງແລນ້ຳ",
                "vill_name_en": "",
                "dr_id": "1315"
              },
              {
                "vill_id": "1315035",
                "vill_name": "ນາກະປົງ",
                "vill_name_en": "",
                "dr_id": "1315"
              },
              {
                "vill_id": "1315036",
                "vill_name": "ນາບໍ່ຄຳ",
                "vill_name_en": "",
                "dr_id": "1315"
              },
              {
                "vill_id": "1315037",
                "vill_name": "ຫນອງຜື",
                "vill_name_en": "",
                "dr_id": "1315"
              },
              {
                "vill_id": "1315038",
                "vill_name": "ດວນ",
                "vill_name_en": "",
                "dr_id": "1315"
              },
              {
                "vill_id": "1315039",
                "vill_name": "ນາລາຍ",
                "vill_name_en": "",
                "dr_id": "1315"
              },
              {
                "vill_id": "1315040",
                "vill_name": "ຊຽງແລໂຄກ",
                "vill_name_en": "",
                "dr_id": "1315"
              },
              {
                "vill_id": "1315041",
                "vill_name": "ນາມ່ວງ",
                "vill_name_en": "",
                "dr_id": "1315"
              },
              {
                "vill_id": "1315042",
                "vill_name": "ໂພສີ",
                "vill_name_en": "",
                "dr_id": "1315"
              },
              {
                "vill_id": "1315043",
                "vill_name": "ໂນນມີໄຊ",
                "vill_name_en": "",
                "dr_id": "1315"
              },
              {
                "vill_id": "1315044",
                "vill_name": "ດົງບັງ",
                "vill_name_en": "",
                "dr_id": "1315"
              },
              {
                "vill_id": "1315045",
                "vill_name": "ໂທະ",
                "vill_name_en": "",
                "dr_id": "1315"
              },
              {
                "vill_id": "1315046",
                "vill_name": "ສະຫມູນ",
                "vill_name_en": "",
                "dr_id": "1315"
              },
              {
                "vill_id": "1315047",
                "vill_name": "ແກ້ງພາມ",
                "vill_name_en": "",
                "dr_id": "1315"
              },
              {
                "vill_id": "1315048",
                "vill_name": "ນາຮູ",
                "vill_name_en": "",
                "dr_id": "1315"
              },
              {
                "vill_id": "1315049",
                "vill_name": "ດອນຕິ້ວ",
                "vill_name_en": "",
                "dr_id": "1315"
              },
              {
                "vill_id": "1315050",
                "vill_name": "ແກ້ງໄຊໃຫຍ່",
                "vill_name_en": "",
                "dr_id": "1315"
              },
              {
                "vill_id": "1315051",
                "vill_name": "ແກ້ງໄຊນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1315"
              },
              {
                "vill_id": "1315052",
                "vill_name": "ນາຊາຍ",
                "vill_name_en": "",
                "dr_id": "1315"
              },
              {
                "vill_id": "1315053",
                "vill_name": "ນາຂວາງ",
                "vill_name_en": "",
                "dr_id": "1315"
              },
              {
                "vill_id": "1315054",
                "vill_name": "ຫລັກໄຊ",
                "vill_name_en": "",
                "dr_id": "1315"
              },
              {
                "vill_id": "1315055",
                "vill_name": "ອຸດົມໄຊ",
                "vill_name_en": "",
                "dr_id": "1315"
              },
              {
                "vill_id": "1315056",
                "vill_name": "ບຶງທະເລ",
                "vill_name_en": "",
                "dr_id": "1315"
              },
              {
                "vill_id": "1315057",
                "vill_name": "ຜະນອນ",
                "vill_name_en": "",
                "dr_id": "1315"
              }
            ]
          }
        ]
      },
      {
        "pr_id": "14",
        "pr_name": "ສາລະວັນ",
        "pr_name_en": "Salavan",
        "districts": [
          {"dr_name": "ເລືອກເມືອງປະຈຸບັນ", "villages": []},
          {
            "dr_id": "1401",
            "dr_name": "ສາລະວັນ",
            "dr_name_en": "Saravane",
            "pr_id": "14",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "1401001",
                "vill_name": "ນາ​ເຫຼັກ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401002",
                "vill_name": "ວັດ​ກາງ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401003",
                "vill_name": "ໂພນ​ແກ້ວ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401004",
                "vill_name": "ນາ​ກົກ​ໂພ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401005",
                "vill_name": "ຫຼັກ II",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401006",
                "vill_name": "ຫາດທອງຫຼວງ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401007",
                "vill_name": "ໜອງສະເກີງ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401008",
                "vill_name": "ໝອງສະເກິງ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401009",
                "vill_name": "ຄັັນ​ທະ​ລາດ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401010",
                "vill_name": "ນາ​ໂດນ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401011",
                "vill_name": "ໂພນ​ໄຜ່",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401012",
                "vill_name": "ນາໂດນ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401013",
                "vill_name": "ໂພນ​ໄຜ່",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401014",
                "vill_name": "ນາໂດນ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401015",
                "vill_name": "ນາ​ທູນ​ໃຫຍ່",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401016",
                "vill_name": "ທາດ​ນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401017",
                "vill_name": "ນາທູນໃຫ່ຍ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401018",
                "vill_name": "ທາດນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401019",
                "vill_name": "ທາດຫ້ວງ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401020",
                "vill_name": "ນາໂຄກ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401021",
                "vill_name": "ເຕົາ​ປູນ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401022",
                "vill_name": "ໂພນບົກ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401023",
                "vill_name": "ໂພນບົກ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401024",
                "vill_name": "ເຕົາປູນ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401025",
                "vill_name": "ນາພະຍີ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401026",
                "vill_name": "ນາ​ທູນ​ນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401027",
                "vill_name": "​ນາຂ້ອຍສາວ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401028",
                "vill_name": "​ນາ​ທານ​ກ້ວຍ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401029",
                "vill_name": "​ໂກະ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401030",
                "vill_name": "ສູງ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401031",
                "vill_name": "ໂກະ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401032",
                "vill_name": "ສູງ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401033",
                "vill_name": "ພ້າວໃຫ່ຍ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401034",
                "vill_name": "ຫາງ​ພູ​ໃຫຍ່",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401035",
                "vill_name": "ຫາງ​ພູ​ນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401036",
                "vill_name": "ທົ່ງ​ປາ​ປົກ​ໃນ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401037",
                "vill_name": "ວຽງຄຳ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401038",
                "vill_name": "ຫາງຟູໃຫ່ຍ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401039",
                "vill_name": "ໂພນ​ທອງ (3)",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401040",
                "vill_name": "ນາ​ໄຊ​ນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401041",
                "vill_name": "ສະ​ໂປນ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401042",
                "vill_name": "ດອນ​ຂາວ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401043",
                "vill_name": "ນາໄຊນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401044",
                "vill_name": "ເລີນໂທນ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401045",
                "vill_name": "ສະໂປນ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401046",
                "vill_name": "ຈ່າ​ໂສດ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401047",
                "vill_name": "ປາກແລງ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401048",
                "vill_name": "ແລງເກົ່າ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401049",
                "vill_name": "ສອງ​ກະ​ລອງ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401050",
                "vill_name": "ແກ້ງ​ກະ​ເວົາ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401051",
                "vill_name": "ຈານ​ວົງ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401052",
                "vill_name": "ຈານ​ເໜືອ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401053",
                "vill_name": "ດົງ​ໂກະ​ກາງ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401054",
                "vill_name": "ດົງ​ໂກະເໜືອ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401055",
                "vill_name": "ແບ່ງ​ອຸດົມ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401056",
                "vill_name": "ສອງກະລອງ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401057",
                "vill_name": "ກະ​ດັບ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401058",
                "vill_name": "ລະ​ວາງ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401059",
                "vill_name": "ຈານເໝືອ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401060",
                "vill_name": "ແສນ​ວັງ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401061",
                "vill_name": "ແບ່ງອຸດົມ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401062",
                "vill_name": "ຫ້ວຍລະນົງ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401063",
                "vill_name": "ລະວາງ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401064",
                "vill_name": "ແສນວັງນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401065",
                "vill_name": "ສຸ​ຕະ​ວະ​ລີ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401066",
                "vill_name": "ນາ​ຕຶມ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401067",
                "vill_name": "ຜັກ​ແພວ​ເສັດ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401068",
                "vill_name": "ວັງງົວ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401069",
                "vill_name": "ໜອງ​ບູ່​ຫີນ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401070",
                "vill_name": "ໃໝ່​ສີ​ວິ​ໄລ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401071",
                "vill_name": "ສຸຕະວະລີ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401072",
                "vill_name": "ຜັກ​ແພວ​ທົ່ງ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401073",
                "vill_name": "ຕົ້ງ​ໃຫຍ່",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401074",
                "vill_name": "ແກ້ງ​ຂຸມ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401075",
                "vill_name": "ແກ້ງເປາະ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401076",
                "vill_name": "ນາ​ຫີນ​ໂລງ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401077",
                "vill_name": "ໂຄກ​ສ​ະ​ຫວາດ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401078",
                "vill_name": "ໂຄກ​ສ​ະ​ຫວາດ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401079",
                "vill_name": "ໜອງ​ໝໍ້",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401080",
                "vill_name": "ບຶງ​ໄຊ​ເໜືອ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401081",
                "vill_name": "ທົ່ງ​ນາຄຳ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401082",
                "vill_name": "ນາຫີນໂລງ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401083",
                "vill_name": "ໜອງ​ໃສ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401084",
                "vill_name": "ນາໂພເລີນ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401085",
                "vill_name": "ຈານ​ໃຕ້",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401086",
                "vill_name": "ບຶງໃຊ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401087",
                "vill_name": "ພະ​ນວນ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401088",
                "vill_name": "ທົງນາຄຳ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401089",
                "vill_name": "ນາ​ດອນ​ບົງ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401090",
                "vill_name": "ນາ​ດອນ​ຂວາງ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401091",
                "vill_name": "ນອງໃສ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401092",
                "vill_name": "ບຶງຂາມ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401093",
                "vill_name": "ເສົາ​ກະ​ດີ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401094",
                "vill_name": "ຄອນ​ລາດ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401095",
                "vill_name": "ໝອງບົວນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401096",
                "vill_name": "ນາດອນຂວາງ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401097",
                "vill_name": "ທ່າເມືອງເກົ່າ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401098",
                "vill_name": "ນາແພງ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401099",
                "vill_name": "ກະ​ຊະນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401100",
                "vill_name": "ເສົາກະດີ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401101",
                "vill_name": "ໜອງ​ໝາກ​ຢາງ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401102",
                "vill_name": "ທ່າເມມືອງເຊ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401103",
                "vill_name": "ປາກ​ປົ່ງ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401104",
                "vill_name": "ກະຊະໃຫ່ຍ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401105",
                "vill_name": "ສອງຄອນ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401106",
                "vill_name": "ກະຊະນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401107",
                "vill_name": "ນາ​ຄູ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401108",
                "vill_name": "ໜອງ​ຜັງ​ຢອງ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401109",
                "vill_name": "ແກ້ງເມືອງລາວ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401110",
                "vill_name": "ແກ້ງ ສິມ​",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401111",
                "vill_name": "ນາລາດ​",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401112",
                "vill_name": "ໜອງ​ເຜີ້ງ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401113",
                "vill_name": "ໝອງເພັກ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401114",
                "vill_name": "ນາຄູ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401115",
                "vill_name": "ໝອງຟັງຢອງ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401116",
                "vill_name": "ຄັນ​ໝາກ​ໂຍດ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401117",
                "vill_name": "ແກ້ງຊິມ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401118",
                "vill_name": "ບ້ານຄັນງົວ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401119",
                "vill_name": "ໝອງເຜິງ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401120",
                "vill_name": "ໜອງ​ຄ້າ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401121",
                "vill_name": "ນາບອນ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401122",
                "vill_name": "ໂຄກ​ເກົ່າ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401123",
                "vill_name": "ນາຫວ້າ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401124",
                "vill_name": "ປາກ​ປຸຍ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401125",
                "vill_name": "​ວັງ​ຊອນ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401126",
                "vill_name": "ດ່ານໃຫ່ຍ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401127",
                "vill_name": "ຄັນງົວ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401128",
                "vill_name": "ໝາກ​ນາວ​ນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401129",
                "vill_name": "ໝາກ​ນາວ​ໃຫຍ່",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401130",
                "vill_name": "ບຸ່ງ​ສຸ້ຍ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401131",
                "vill_name": "ໂພນ​ທັນ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401132",
                "vill_name": "ໜອງ​ບົວໃຫຍ່",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401133",
                "vill_name": "ແບ່ງລະກຸບວັງຈັນ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401134",
                "vill_name": "ກະ​ທ້າງ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401135",
                "vill_name": "ຜັກຂະໃຫ່ຍ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401136",
                "vill_name": "ຫາດ​ເກາະນ້ຳ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401137",
                "vill_name": "ນາ​ໂພ​ນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401138",
                "vill_name": "ນາ​ໂພກາງ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401139",
                "vill_name": "ໂພນທັນ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401140",
                "vill_name": "ດົງ​ສະ​ວັງ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401141",
                "vill_name": "ຫ້ວຍ​ຫຼາດ",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401142",
                "vill_name": "ແລງໃໝ່",
                "vill_name_en": "",
                "dr_id": "1401"
              },
              {
                "vill_id": "1401143",
                "vill_name": "ເທດສະບານເມືອງ",
                "vill_name_en": "",
                "dr_id": "1401"
              }
            ]
          },
          {
            "dr_id": "1402",
            "dr_name": "ຕາໂອ້ຍ",
            "dr_name_en": "Ta oi",
            "pr_id": "14",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "1402001",
                "vill_name": "ເທດສະບານເມືອງ",
                "vill_name_en": "",
                "dr_id": "1402"
              },
              {
                "vill_id": "1402002",
                "vill_name": "ຕະລຸງລະລາວ",
                "vill_name_en": "",
                "dr_id": "1402"
              },
              {
                "vill_id": "1402003",
                "vill_name": "ຮູກະໂຍ(ຕະຫລຸງຫູ)",
                "vill_name_en": "",
                "dr_id": "1402"
              },
              {
                "vill_id": "1402004",
                "vill_name": "ລະແຊງ",
                "vill_name_en": "",
                "dr_id": "1402"
              },
              {
                "vill_id": "1402005",
                "vill_name": "ລະຫາບ",
                "vill_name_en": "",
                "dr_id": "1402"
              },
              {
                "vill_id": "1402006",
                "vill_name": "ປະເສ້ຍ",
                "vill_name_en": "",
                "dr_id": "1402"
              },
              {
                "vill_id": "1402007",
                "vill_name": "ປະຈູດອນ",
                "vill_name_en": "",
                "dr_id": "1402"
              },
              {
                "vill_id": "1402008",
                "vill_name": "ປະຈູເຈີນ",
                "vill_name_en": "",
                "dr_id": "1402"
              },
              {
                "vill_id": "1402009",
                "vill_name": "ໂຈະໃຕ້",
                "vill_name_en": "",
                "dr_id": "1402"
              },
              {
                "vill_id": "1402010",
                "vill_name": "ຕະຮວກ",
                "vill_name_en": "",
                "dr_id": "1402"
              },
              {
                "vill_id": "1402011",
                "vill_name": "ໂຈະໄຮ່",
                "vill_name_en": "",
                "dr_id": "1402"
              },
              {
                "vill_id": "1402012",
                "vill_name": "ຕຸງໂກງ",
                "vill_name_en": "",
                "dr_id": "1402"
              },
              {
                "vill_id": "1402013",
                "vill_name": "ປີໂກະ",
                "vill_name_en": "",
                "dr_id": "1402"
              },
              {
                "vill_id": "1402014",
                "vill_name": "ອາດອນ",
                "vill_name_en": "",
                "dr_id": "1402"
              },
              {
                "vill_id": "1402015",
                "vill_name": "ຕຸງແຕງ",
                "vill_name_en": "",
                "dr_id": "1402"
              },
              {
                "vill_id": "1402016",
                "vill_name": "ອາງໍ",
                "vill_name_en": "",
                "dr_id": "1402"
              },
              {
                "vill_id": "1402017",
                "vill_name": "ອາດື",
                "vill_name_en": "",
                "dr_id": "1402"
              },
              {
                "vill_id": "1402018",
                "vill_name": "ສັນຢາຢອນ",
                "vill_name_en": "",
                "dr_id": "1402"
              },
              {
                "vill_id": "1402019",
                "vill_name": "ລຶກດົງ",
                "vill_name_en": "",
                "dr_id": "1402"
              },
              {
                "vill_id": "1402020",
                "vill_name": "ຕຸງກາໄຕ",
                "vill_name_en": "",
                "dr_id": "1402"
              },
              {
                "vill_id": "1402021",
                "vill_name": "ທົງກາໄຮ່",
                "vill_name_en": "",
                "dr_id": "1402"
              },
              {
                "vill_id": "1402022",
                "vill_name": "ກະມວນ",
                "vill_name_en": "",
                "dr_id": "1402"
              },
              {
                "vill_id": "1402023",
                "vill_name": "ປະໂສມ",
                "vill_name_en": "",
                "dr_id": "1402"
              },
              {
                "vill_id": "1402024",
                "vill_name": "ທົ່ງຊະ",
                "vill_name_en": "",
                "dr_id": "1402"
              },
              {
                "vill_id": "1402025",
                "vill_name": "ປີຕຽນ",
                "vill_name_en": "",
                "dr_id": "1402"
              },
              {
                "vill_id": "1402026",
                "vill_name": "ໂບງນ້ຳ",
                "vill_name_en": "",
                "dr_id": "1402"
              },
              {
                "vill_id": "1402027",
                "vill_name": "ປລ່ອຍ",
                "vill_name_en": "",
                "dr_id": "1402"
              },
              {
                "vill_id": "1402028",
                "vill_name": "ກົກບົກ",
                "vill_name_en": "",
                "dr_id": "1402"
              },
              {
                "vill_id": "1402029",
                "vill_name": "ປະຈຸກາປາ",
                "vill_name_en": "",
                "dr_id": "1402"
              },
              {
                "vill_id": "1402030",
                "vill_name": "ປະດຸ",
                "vill_name_en": "",
                "dr_id": "1402"
              },
              {
                "vill_id": "1402031",
                "vill_name": "ຫ້ວຍງົວ",
                "vill_name_en": "",
                "dr_id": "1402"
              },
              {
                "vill_id": "1402032",
                "vill_name": "ຊ້ອຍຫົງ",
                "vill_name_en": "",
                "dr_id": "1402"
              },
              {
                "vill_id": "1402033",
                "vill_name": "ກະໂອນເກົ່າ",
                "vill_name_en": "",
                "dr_id": "1402"
              },
              {
                "vill_id": "1402034",
                "vill_name": "ສ້ອຍຕາມ",
                "vill_name_en": "",
                "dr_id": "1402"
              },
              {
                "vill_id": "1402035",
                "vill_name": "ຕະປືນພູ",
                "vill_name_en": "",
                "dr_id": "1402"
              },
              {
                "vill_id": "1402036",
                "vill_name": "ຈໍລະວຽງ",
                "vill_name_en": "",
                "dr_id": "1402"
              },
              {
                "vill_id": "1402037",
                "vill_name": "ແຕ່ນ",
                "vill_name_en": "",
                "dr_id": "1402"
              },
              {
                "vill_id": "1402038",
                "vill_name": "ສິງຕະມອງ",
                "vill_name_en": "",
                "dr_id": "1402"
              },
              {
                "vill_id": "1402039",
                "vill_name": "ລະປຶງ",
                "vill_name_en": "",
                "dr_id": "1402"
              },
              {
                "vill_id": "1402040",
                "vill_name": "ກັງ",
                "vill_name_en": "",
                "dr_id": "1402"
              },
              {
                "vill_id": "1402041",
                "vill_name": "ຫຸນໃຫຍ່",
                "vill_name_en": "",
                "dr_id": "1402"
              },
              {
                "vill_id": "1402042",
                "vill_name": "ປະສິງ",
                "vill_name_en": "",
                "dr_id": "1402"
              },
              {
                "vill_id": "1402043",
                "vill_name": "ຕຸ້ມລີທົ່ງ",
                "vill_name_en": "",
                "dr_id": "1402"
              },
              {
                "vill_id": "1402044",
                "vill_name": "ກະເປ",
                "vill_name_en": "",
                "dr_id": "1402"
              },
              {
                "vill_id": "1402045",
                "vill_name": "ກະເຕັນ",
                "vill_name_en": "",
                "dr_id": "1402"
              },
              {
                "vill_id": "1402046",
                "vill_name": "ປະເຕມ",
                "vill_name_en": "",
                "dr_id": "1402"
              },
              {
                "vill_id": "1402047",
                "vill_name": "ດູບ",
                "vill_name_en": "",
                "dr_id": "1402"
              },
              {
                "vill_id": "1402048",
                "vill_name": "ພໍ່ຕັງ",
                "vill_name_en": "",
                "dr_id": "1402"
              },
              {
                "vill_id": "1402049",
                "vill_name": "ພໍ່ເຊັນ",
                "vill_name_en": "",
                "dr_id": "1402"
              },
              {
                "vill_id": "1402050",
                "vill_name": "ຕຸ້ມລີເກົ່າ",
                "vill_name_en": "",
                "dr_id": "1402"
              },
              {
                "vill_id": "1402051",
                "vill_name": "ຕຸ້ມລີຂາວ",
                "vill_name_en": "",
                "dr_id": "1402"
              },
              {
                "vill_id": "1402052",
                "vill_name": "ປະງານດາວ",
                "vill_name_en": "",
                "dr_id": "1402"
              },
              {
                "vill_id": "1402053",
                "vill_name": "ປະງານສິງ",
                "vill_name_en": "",
                "dr_id": "1402"
              },
              {
                "vill_id": "1402054",
                "vill_name": "ສະແໜງ",
                "vill_name_en": "",
                "dr_id": "1402"
              },
              {
                "vill_id": "1402055",
                "vill_name": "ພໍ່ເບີຍ",
                "vill_name_en": "",
                "dr_id": "1402"
              },
              {
                "vill_id": "1402056",
                "vill_name": "ສະບອງກົກໄຮ່",
                "vill_name_en": "",
                "dr_id": "1402"
              }
            ]
          },
          {
            "dr_id": "1403",
            "dr_name": "ຕຸ້ມລານ",
            "dr_name_en": "Toomlam",
            "pr_id": "14",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "1403001",
                "vill_name": "ກະແຫນບ",
                "vill_name_en": "",
                "dr_id": "1403"
              },
              {
                "vill_id": "1403002",
                "vill_name": "ສະໂນ",
                "vill_name_en": "",
                "dr_id": "1403"
              },
              {
                "vill_id": "1403003",
                "vill_name": "ວັງລີ",
                "vill_name_en": "",
                "dr_id": "1403"
              },
              {
                "vill_id": "1403004",
                "vill_name": "ບອຍນ້ຳ",
                "vill_name_en": "",
                "dr_id": "1403"
              },
              {
                "vill_id": "1403005",
                "vill_name": "ຕະເຢີກ",
                "vill_name_en": "",
                "dr_id": "1403"
              },
              {
                "vill_id": "1403006",
                "vill_name": "ລະມຶກ",
                "vill_name_en": "",
                "dr_id": "1403"
              },
              {
                "vill_id": "1403007",
                "vill_name": "ວັງເຮືອ",
                "vill_name_en": "",
                "dr_id": "1403"
              },
              {
                "vill_id": "1403008",
                "vill_name": "ຕຳແບງ",
                "vill_name_en": "",
                "dr_id": "1403"
              },
              {
                "vill_id": "1403009",
                "vill_name": "ຕະເວີຍ",
                "vill_name_en": "",
                "dr_id": "1403"
              },
              {
                "vill_id": "1403010",
                "vill_name": "ອາເປັຍ",
                "vill_name_en": "",
                "dr_id": "1403"
              },
              {
                "vill_id": "1403011",
                "vill_name": "ຕະນົວ",
                "vill_name_en": "",
                "dr_id": "1403"
              },
              {
                "vill_id": "1403012",
                "vill_name": "ກົກມ່ວງ",
                "vill_name_en": "",
                "dr_id": "1403"
              },
              {
                "vill_id": "1403013",
                "vill_name": "ນາຮ່ອງໃຫຍ່",
                "vill_name_en": "",
                "dr_id": "1403"
              },
              {
                "vill_id": "1403014",
                "vill_name": "ຕະແບງ",
                "vill_name_en": "",
                "dr_id": "1403"
              },
              {
                "vill_id": "1403015",
                "vill_name": "ນາຮ່ອງນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1403"
              },
              {
                "vill_id": "1403016",
                "vill_name": "ຫ້ວຍຫວ້າ",
                "vill_name_en": "",
                "dr_id": "1403"
              },
              {
                "vill_id": "1403017",
                "vill_name": "ໂພນງາມ",
                "vill_name_en": "",
                "dr_id": "1403"
              },
              {
                "vill_id": "1403018",
                "vill_name": "ສາມັກຄີໃຊ",
                "vill_name_en": "",
                "dr_id": "1403"
              },
              {
                "vill_id": "1403019",
                "vill_name": "ແກ້ງຮັງ",
                "vill_name_en": "",
                "dr_id": "1403"
              },
              {
                "vill_id": "1403020",
                "vill_name": "ດິນດາກ",
                "vill_name_en": "",
                "dr_id": "1403"
              },
              {
                "vill_id": "1403021",
                "vill_name": "ຕຸກລຸກ",
                "vill_name_en": "",
                "dr_id": "1403"
              },
              {
                "vill_id": "1403022",
                "vill_name": "ຕຸ້ມລານ",
                "vill_name_en": "",
                "dr_id": "1403"
              },
              {
                "vill_id": "1403023",
                "vill_name": "ນາກະເຈີມ",
                "vill_name_en": "",
                "dr_id": "1403"
              },
              {
                "vill_id": "1403024",
                "vill_name": "ດອນບຸ່ງ",
                "vill_name_en": "",
                "dr_id": "1403"
              },
              {
                "vill_id": "1403025",
                "vill_name": "ວັງໂກຍ",
                "vill_name_en": "",
                "dr_id": "1403"
              },
              {
                "vill_id": "1403026",
                "vill_name": "ດອນຊາດ",
                "vill_name_en": "",
                "dr_id": "1403"
              },
              {
                "vill_id": "1403027",
                "vill_name": "ນາໂພໃຫຍ່",
                "vill_name_en": "",
                "dr_id": "1403"
              },
              {
                "vill_id": "1403028",
                "vill_name": "ນາມະຕອງ",
                "vill_name_en": "",
                "dr_id": "1403"
              },
              {
                "vill_id": "1403029",
                "vill_name": "ນາດູ່",
                "vill_name_en": "",
                "dr_id": "1403"
              },
              {
                "vill_id": "1403030",
                "vill_name": "ນາວຽງຮ່ອງ",
                "vill_name_en": "",
                "dr_id": "1403"
              },
              {
                "vill_id": "1403031",
                "vill_name": "ດອນມ່ວງ",
                "vill_name_en": "",
                "dr_id": "1403"
              },
              {
                "vill_id": "1403032",
                "vill_name": "ດອນຄະຍຸງ",
                "vill_name_en": "",
                "dr_id": "1403"
              },
              {
                "vill_id": "1403033",
                "vill_name": "ທົ່ງໄກ່ຂວາ",
                "vill_name_en": "",
                "dr_id": "1403"
              },
              {
                "vill_id": "1403034",
                "vill_name": "ຖ້ຳມະລີ",
                "vill_name_en": "",
                "dr_id": "1403"
              },
              {
                "vill_id": "1403035",
                "vill_name": "ຫ້ວຍໄຫ່ລ",
                "vill_name_en": "",
                "dr_id": "1403"
              },
              {
                "vill_id": "1403036",
                "vill_name": "ນາລະຈາງ",
                "vill_name_en": "",
                "dr_id": "1403"
              },
              {
                "vill_id": "1403037",
                "vill_name": "ກະຕາວ",
                "vill_name_en": "",
                "dr_id": "1403"
              }
            ]
          },
          {
            "dr_id": "1404",
            "dr_name": "ລະຄອນເພັງ",
            "dr_name_en": "Lakhonepheng",
            "pr_id": "14",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "1404001",
                "vill_name": "ປາກຕະພານ",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404002",
                "vill_name": "ໂຮງສາຍທອງ",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404003",
                "vill_name": "ປາກສະນົມ",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404004",
                "vill_name": "ນາໜອງຍາວ",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404005",
                "vill_name": "ຫນອງຊະໂນ",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404006",
                "vill_name": "ເລິງນົກທາ",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404007",
                "vill_name": "ພວງສະຫວັນ",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404008",
                "vill_name": "ພວງມະໄລ",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404009",
                "vill_name": "ນາແລນ",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404010",
                "vill_name": "ນາທຶນເໜືອ",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404011",
                "vill_name": "ນາທືນໃຕ້",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404012",
                "vill_name": "ໂນນແສນພັນ",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404013",
                "vill_name": "ແກ້ງທັນ",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404014",
                "vill_name": "ນາໂພສີ",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404015",
                "vill_name": "ນາໂພສີ",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404016",
                "vill_name": "ນາເມືອງໃຫ່ຍ",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404017",
                "vill_name": "ໜອງແສງ",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404018",
                "vill_name": "ດອນແດງ",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404019",
                "vill_name": "ໂນນສັງ",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404020",
                "vill_name": "ດອນເຮືອ",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404021",
                "vill_name": "ນາກະລາ",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404022",
                "vill_name": "ໂພນງາມ",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404023",
                "vill_name": "ດອນໝາກເກືອ",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404024",
                "vill_name": "ໂນນສະຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404025",
                "vill_name": "ໂອກາດ",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404026",
                "vill_name": "ນາພະບາງໃຫ່ຍ",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404027",
                "vill_name": "ນາພະບາງນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404028",
                "vill_name": "ວັງຕາແຫຼວ",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404029",
                "vill_name": "ທ່າຄັນຊຸມຊົວ",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404030",
                "vill_name": "ພູກາສີ",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404031",
                "vill_name": "ແກ້ງສະພັງທອງ",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404032",
                "vill_name": "ດອນຫວາຍ",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404033",
                "vill_name": "ລະຄອນສາຍ",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404034",
                "vill_name": "ສັນຕິສຸກ",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404035",
                "vill_name": "ພູສີແກ້ວ",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404036",
                "vill_name": "ນາວາສຸກ",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404037",
                "vill_name": "ນາກະເດົາ",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404038",
                "vill_name": "ຫນອງນົກຂຽນ",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404039",
                "vill_name": "ນາແສນພັນ",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404040",
                "vill_name": "ລະຄອນເພັງນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404041",
                "vill_name": "ລະຄອນເພັງໃຫຍ່",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404042",
                "vill_name": "ລະຄອນສີກາງ",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404043",
                "vill_name": "ລະຄອນເພັງໃຫ່ຍ",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404044",
                "vill_name": "ຫ້ວຍກະໂພ",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404045",
                "vill_name": "ໂຊກແພງ",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404046",
                "vill_name": "ຫ້ວຍກະໂພ",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404047",
                "vill_name": "ໂຊກແພງ",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404048",
                "vill_name": "ຫນອງແວງ",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404049",
                "vill_name": "ນາດູເກົ່າ",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404050",
                "vill_name": "ນາາ",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404051",
                "vill_name": "ນາມ່ວງໃຫ່ຍ",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404052",
                "vill_name": "ໂນນດິນຊາຍ",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404053",
                "vill_name": "ຫຼັກ 90",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404054",
                "vill_name": "ນາສົ້ມໂຮງ",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404055",
                "vill_name": "ດົງຕະລຸງ",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404056",
                "vill_name": "ຫຼັກ 49",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404057",
                "vill_name": "ຫຼັກ 90",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404058",
                "vill_name": "ພູດາວແຈ້ງນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404059",
                "vill_name": "ໜອງສະໂນ1",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404060",
                "vill_name": "ຄຳເຕີຍ",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404061",
                "vill_name": "ດ່ານນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404062",
                "vill_name": "ດ່ານນາເລົາ",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404063",
                "vill_name": "ແບ່ງດ່ານ",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404064",
                "vill_name": "ໂພນສູງ",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404065",
                "vill_name": "ດ່ານນາເລົ່າ",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404066",
                "vill_name": "ແບ່ງດ່ານ",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404067",
                "vill_name": "ໂພນສູງ",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404068",
                "vill_name": "ຫນອງແຕ້",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404069",
                "vill_name": "ຫນອງເທົາ",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404070",
                "vill_name": "ສາວແຫວ່ນ",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404071",
                "vill_name": "ໄຊຊະນະສົມບູນ",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404072",
                "vill_name": "ຫນອງສີ່ແຈ",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404073",
                "vill_name": "ດອນໄລ່",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404074",
                "vill_name": "ນາງລາວ",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404075",
                "vill_name": "ບຸດຕະພານ",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404076",
                "vill_name": "ດອນໄລ່",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404077",
                "vill_name": "ຫນອງຂີ້ຕົມ",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404078",
                "vill_name": "ດອນຄັ່ງ",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404079",
                "vill_name": "ກຸດລຳພອງ",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404080",
                "vill_name": "ບຸດຕະພານ",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404081",
                "vill_name": "ກຸດລຳພອງ",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404082",
                "vill_name": "ໂນນສະຫວັນ",
                "vill_name_en": "",
                "dr_id": "1404"
              },
              {
                "vill_id": "1404083",
                "vill_name": "ເຕົາຖ່າຍ 1",
                "vill_name_en": "Taotan 1",
                "dr_id": "1404"
              }
            ]
          },
          {
            "dr_id": "1405",
            "dr_name": "ວາປີ",
            "dr_name_en": "Vapy",
            "pr_id": "14",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "1405001",
                "vill_name": "ໂນນສະຫວັນ",
                "vill_name_en": "",
                "dr_id": "1405"
              },
              {
                "vill_id": "1405002",
                "vill_name": "ສຸລິຍະ",
                "vill_name_en": "",
                "dr_id": "1405"
              },
              {
                "vill_id": "1405003",
                "vill_name": "ກອງລືໃຫຍ່",
                "vill_name_en": "",
                "dr_id": "1405"
              },
              {
                "vill_id": "1405004",
                "vill_name": "ນາລັນ",
                "vill_name_en": "",
                "dr_id": "1405"
              },
              {
                "vill_id": "1405005",
                "vill_name": "ນາຊັນ",
                "vill_name_en": "",
                "dr_id": "1405"
              },
              {
                "vill_id": "1405006",
                "vill_name": "ນາຮ່ອງຂາມ",
                "vill_name_en": "",
                "dr_id": "1405"
              },
              {
                "vill_id": "1405007",
                "vill_name": "ແກ້ງນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1405"
              },
              {
                "vill_id": "1405008",
                "vill_name": "ໜອງໂພ",
                "vill_name_en": "",
                "dr_id": "1405"
              },
              {
                "vill_id": "1405009",
                "vill_name": "ດົງມອນ",
                "vill_name_en": "",
                "dr_id": "1405"
              },
              {
                "vill_id": "1405010",
                "vill_name": "ສະເໝັຍ",
                "vill_name_en": "",
                "dr_id": "1405"
              },
              {
                "vill_id": "1405011",
                "vill_name": "ແກ້ງກຸ",
                "vill_name_en": "",
                "dr_id": "1405"
              },
              {
                "vill_id": "1405012",
                "vill_name": "ຈອງ",
                "vill_name_en": "",
                "dr_id": "1405"
              },
              {
                "vill_id": "1405013",
                "vill_name": "ດົງລະອຸ",
                "vill_name_en": "",
                "dr_id": "1405"
              },
              {
                "vill_id": "1405014",
                "vill_name": "ເລົ້າ",
                "vill_name_en": "",
                "dr_id": "1405"
              },
              {
                "vill_id": "1405015",
                "vill_name": "ຫາດ",
                "vill_name_en": "",
                "dr_id": "1405"
              },
              {
                "vill_id": "1405016",
                "vill_name": "ແມດ",
                "vill_name_en": "",
                "dr_id": "1405"
              },
              {
                "vill_id": "1405017",
                "vill_name": "ແກ້ງສຸທິ",
                "vill_name_en": "",
                "dr_id": "1405"
              },
              {
                "vill_id": "1405018",
                "vill_name": "ມ່ວງເວີນຄຳ",
                "vill_name_en": "",
                "dr_id": "1405"
              },
              {
                "vill_id": "1405019",
                "vill_name": "ສະພາດ",
                "vill_name_en": "",
                "dr_id": "1405"
              },
              {
                "vill_id": "1405020",
                "vill_name": "ນາໂພນຕານ",
                "vill_name_en": "",
                "dr_id": "1405"
              },
              {
                "vill_id": "1405021",
                "vill_name": "ນາຫົວແຮດ",
                "vill_name_en": "",
                "dr_id": "1405"
              },
              {
                "vill_id": "1405022",
                "vill_name": "ນາໂພ",
                "vill_name_en": "",
                "dr_id": "1405"
              },
              {
                "vill_id": "1405023",
                "vill_name": "ນາມ່ວງ",
                "vill_name_en": "",
                "dr_id": "1405"
              },
              {
                "vill_id": "1405024",
                "vill_name": "ນາຫວ້າ",
                "vill_name_en": "",
                "dr_id": "1405"
              },
              {
                "vill_id": "1405025",
                "vill_name": "ຜັກຂະ",
                "vill_name_en": "",
                "dr_id": "1405"
              },
              {
                "vill_id": "1405026",
                "vill_name": "ລານ",
                "vill_name_en": "",
                "dr_id": "1405"
              },
              {
                "vill_id": "1405027",
                "vill_name": "ນ້ອຍເຊ",
                "vill_name_en": "",
                "dr_id": "1405"
              },
              {
                "vill_id": "1405028",
                "vill_name": "ໂພນແພງ",
                "vill_name_en": "",
                "dr_id": "1405"
              },
              {
                "vill_id": "1405029",
                "vill_name": "ສີມ",
                "vill_name_en": "",
                "dr_id": "1405"
              },
              {
                "vill_id": "1405030",
                "vill_name": "ຂະເໜົາ",
                "vill_name_en": "",
                "dr_id": "1405"
              },
              {
                "vill_id": "1405031",
                "vill_name": "ນາສຽດ",
                "vill_name_en": "",
                "dr_id": "1405"
              },
              {
                "vill_id": "1405032",
                "vill_name": "ດອນຄຳ",
                "vill_name_en": "",
                "dr_id": "1405"
              },
              {
                "vill_id": "1405033",
                "vill_name": "ທົ່ງຂຽນ",
                "vill_name_en": "",
                "dr_id": "1405"
              },
              {
                "vill_id": "1405034",
                "vill_name": "ຫີນດ່ານ",
                "vill_name_en": "",
                "dr_id": "1405"
              },
              {
                "vill_id": "1405035",
                "vill_name": "ບັງຄານ້ຳ",
                "vill_name_en": "",
                "dr_id": "1405"
              },
              {
                "vill_id": "1405036",
                "vill_name": "ວາປີເໜືອ",
                "vill_name_en": "",
                "dr_id": "1405"
              },
              {
                "vill_id": "1405037",
                "vill_name": "ຫ້ວຍໂຄນ",
                "vill_name_en": "",
                "dr_id": "1405"
              },
              {
                "vill_id": "1405038",
                "vill_name": "ເກາະຊອງ",
                "vill_name_en": "",
                "dr_id": "1405"
              },
              {
                "vill_id": "1405039",
                "vill_name": "ຕານສຸມ",
                "vill_name_en": "",
                "dr_id": "1405"
              },
              {
                "vill_id": "1405040",
                "vill_name": "ນາລະໂອງ",
                "vill_name_en": "",
                "dr_id": "1405"
              },
              {
                "vill_id": "1405041",
                "vill_name": "ໝອງໂງ້ງ",
                "vill_name_en": "",
                "dr_id": "1405"
              },
              {
                "vill_id": "1405042",
                "vill_name": "ໜອງໂງ້ງ",
                "vill_name_en": "",
                "dr_id": "1405"
              },
              {
                "vill_id": "1405043",
                "vill_name": "ນາກົກຕານ",
                "vill_name_en": "",
                "dr_id": "1405"
              },
              {
                "vill_id": "1405044",
                "vill_name": "ດອນຂ່າ",
                "vill_name_en": "",
                "dr_id": "1405"
              },
              {
                "vill_id": "1405045",
                "vill_name": "ຄອນສາຍ",
                "vill_name_en": "",
                "dr_id": "1405"
              },
              {
                "vill_id": "1405046",
                "vill_name": "ນາກາງ",
                "vill_name_en": "",
                "dr_id": "1405"
              },
              {
                "vill_id": "1405047",
                "vill_name": "ບັງຄາໂຄກ",
                "vill_name_en": "",
                "dr_id": "1405"
              },
              {
                "vill_id": "1405049",
                "vill_name": "ດອນໝີ້ນ",
                "vill_name_en": "",
                "dr_id": "1405"
              },
              {
                "vill_id": "1405050",
                "vill_name": "ນາຜີມານ",
                "vill_name_en": "",
                "dr_id": "1405"
              },
              {
                "vill_id": "1405051",
                "vill_name": "ກອງລຶນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1405"
              },
              {
                "vill_id": "1405052",
                "vill_name": "ໜອງບົວ",
                "vill_name_en": "",
                "dr_id": "1405"
              },
              {
                "vill_id": "1405053",
                "vill_name": "ໜອງຈານ",
                "vill_name_en": "",
                "dr_id": "1405"
              },
              {
                "vill_id": "1405054",
                "vill_name": "ນາຊາດ",
                "vill_name_en": "",
                "dr_id": "1405"
              },
              {
                "vill_id": "1405055",
                "vill_name": "ໜອງເຜິ້ງ",
                "vill_name_en": "",
                "dr_id": "1405"
              },
              {
                "vill_id": "1405056",
                "vill_name": "ໄຜ່ລ້ອມ",
                "vill_name_en": "",
                "dr_id": "1405"
              },
              {
                "vill_id": "1405057",
                "vill_name": "ໂນນຄໍ້",
                "vill_name_en": "",
                "dr_id": "1405"
              },
              {
                "vill_id": "1405058",
                "vill_name": "ນາຕະປຽງ",
                "vill_name_en": "",
                "dr_id": "1405"
              }
            ]
          },
          {
            "dr_id": "1406",
            "dr_name": "ຄົງເຊໂດນ",
            "dr_name_en": "Kongxedone",
            "pr_id": "14",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "1406001",
                "vill_name": "ຄົງໃຫຍ່",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406002",
                "vill_name": "ບ້ານທົ່ງຈະເລີນ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406003",
                "vill_name": "ຄົງຄຸ້ມ5",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406004",
                "vill_name": "ຄົງຄຸ້ມ6",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406005",
                "vill_name": "ວັງກ້ານຮຸງ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406006",
                "vill_name": "ນາແພງໃຫຍ່",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406007",
                "vill_name": "ວັງກາງຮຸ່ງ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406008",
                "vill_name": "ນາແພງ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406009",
                "vill_name": "ບົວລະພາ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406010",
                "vill_name": "ບຶງກາງ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406011",
                "vill_name": "ຄົງນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406012",
                "vill_name": "ບຶງກາງ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406013",
                "vill_name": "ໜອງເກາະຊອງ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406014",
                "vill_name": "ໝອງສະພັງ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406015",
                "vill_name": "ໂຮງເລື່ອຍມີໄຊ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406016",
                "vill_name": "ທ່າຄໍ່",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406017",
                "vill_name": "ໜອງໂນ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406018",
                "vill_name": "ໂນນບົກ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406019",
                "vill_name": "ຫຼັກ 11",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406021",
                "vill_name": "ດິນກອກ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406022",
                "vill_name": "ກຸດມີໃຊ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406023",
                "vill_name": "ດອນໄຜ່ບ້ານ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406024",
                "vill_name": "ຈານລານເຊ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406025",
                "vill_name": "ຄຳຄໍ້",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406026",
                "vill_name": "ນາຄຳ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406027",
                "vill_name": "ຕານປ່ຽວ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406028",
                "vill_name": "ໝື່ນປູ່",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406029",
                "vill_name": "ປາກເຊືອກ ນາຮັງ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406030",
                "vill_name": "ປາກເຊືອກ ນາຮັງ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406031",
                "vill_name": "ນາຫວດ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406032",
                "vill_name": "ຕະຫຼາງ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406033",
                "vill_name": "ນາຕານດົງ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406034",
                "vill_name": "ເຫລົ່າ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406035",
                "vill_name": "ຂຸມຄຳ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406036",
                "vill_name": "ໂນນ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406037",
                "vill_name": "ນາເມັກ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406038",
                "vill_name": "ນາເຕີຍ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406039",
                "vill_name": "ເລົ່າ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406040",
                "vill_name": "ປົ່ງ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406041",
                "vill_name": "ປັດຊຸມ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406042",
                "vill_name": "ຄຳໄຮ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406043",
                "vill_name": "ໜອງແສງລາວ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406044",
                "vill_name": "ນາໂຄກ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406045",
                "vill_name": "ຝັ່ງຖານໃຫຍ່",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406046",
                "vill_name": "ປັດຊຸມ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406047",
                "vill_name": "ບຶງມານ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406048",
                "vill_name": "ອີ່ເລີງ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406049",
                "vill_name": "ຫີນສິ່ວ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406050",
                "vill_name": "ໂຄກຫົວຊ້າງ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406051",
                "vill_name": "ນາມ່ວງໃຫຍ່",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406052",
                "vill_name": "ນາມ່ວງນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406053",
                "vill_name": "ດອນກອງເຂົ້າ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406054",
                "vill_name": "ຄຳອີ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406055",
                "vill_name": "ໂຄກສະຫວາດ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406056",
                "vill_name": "ດອນເມືອງ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406057",
                "vill_name": "ໜອງບົວ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406058",
                "vill_name": "ນາມ່ວງ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406059",
                "vill_name": "ກ່ອງເຂົາ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406060",
                "vill_name": "ກຸດເຮືອ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406061",
                "vill_name": "ນາໂພໄຊ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406062",
                "vill_name": "ນາສະດອງ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406063",
                "vill_name": "ຄັນທຸງໃຊ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406064",
                "vill_name": "ຄຳທອງ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406065",
                "vill_name": "ໂນນສຳລານ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406066",
                "vill_name": "ກູດຫີນ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406067",
                "vill_name": "ໂອກາດໃຫຍ່",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406068",
                "vill_name": "ໜອງຄຸລຸ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406069",
                "vill_name": "ໂນນຫີນຕັ້ງ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406070",
                "vill_name": "ນາກອກ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406071",
                "vill_name": "ນາໂນງ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406072",
                "vill_name": "ຄຳທອງ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406073",
                "vill_name": "ຊາຍມົນ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406074",
                "vill_name": "ໜອງແຕ່ງ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406075",
                "vill_name": "ໂອກາດນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406076",
                "vill_name": "ຫ້ວຍເຊົາ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406077",
                "vill_name": "ຫ້ວຍເຊົາ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406078",
                "vill_name": "ຊາຍຄຳ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406079",
                "vill_name": "ບ້ານຄຳຕື້",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406080",
                "vill_name": "ຫາດດູ່",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406081",
                "vill_name": "ຕານດ່ຽວ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406082",
                "vill_name": "ທວງເຊ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406083",
                "vill_name": "ນາໂນງ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406084",
                "vill_name": "ໜອງຫອຍ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406085",
                "vill_name": "ໜາມແຖ່ງ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406086",
                "vill_name": "ມຸ່ງຊຸມ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406087",
                "vill_name": "ນາພູລາວ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406088",
                "vill_name": "ແກ້ງຫວດ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406089",
                "vill_name": "ຊາຍຄຳ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406090",
                "vill_name": "ຊາຍຄຳ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406091",
                "vill_name": "ຄຳຕື",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406092",
                "vill_name": "ແກ້ງໂຄນ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406093",
                "vill_name": "ຊະໂອ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406094",
                "vill_name": "ນາຕານເຊ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406095",
                "vill_name": "ຮັງແຮ້ງ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406096",
                "vill_name": "ດອນແກ້ວ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406097",
                "vill_name": "ນາພູລາວ",
                "vill_name_en": "",
                "dr_id": "1406"
              },
              {
                "vill_id": "1406098",
                "vill_name": "ບ້ານຄົງເຊໂດນ",
                "vill_name_en": "Khongsedon",
                "dr_id": "1406"
              }
            ]
          },
          {
            "dr_id": "1407",
            "dr_name": "ເລົ່າງາມ",
            "dr_name_en": "Lao ngarm",
            "pr_id": "14",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "1407001",
                "vill_name": "ເລົ່າງາມ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407002",
                "vill_name": "ໂນນຄຳ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407003",
                "vill_name": "ໂນນເດື່ອ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407004",
                "vill_name": "ຫ້ວຍນ້ຳສັນ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407005",
                "vill_name": "ນາຈານ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407006",
                "vill_name": "ນາແຊ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407007",
                "vill_name": "ນາອອມ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407008",
                "vill_name": "ວັງຄະນານ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407009",
                "vill_name": "ສອງຫ້ອງນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407010",
                "vill_name": "ສອງຫ້ອງໃຫຍ່",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407011",
                "vill_name": "ໜອງເລົາລຸ່ມ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407012",
                "vill_name": "ວັງແຂ້",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407013",
                "vill_name": "ກວງຊີນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407014",
                "vill_name": "ໜອງເລົາເທິງ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407015",
                "vill_name": "ຍານ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407016",
                "vill_name": "ຕະແບງ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407017",
                "vill_name": "ກະຊະ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407018",
                "vill_name": "ພະນາຍ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407019",
                "vill_name": "ນາໝີ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407020",
                "vill_name": "ກາງ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407021",
                "vill_name": "ເກັດເຜິ້ງ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407022",
                "vill_name": "ດົງຊວງ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407023",
                "vill_name": "ດົງຫຼວງ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407024",
                "vill_name": "ໜອງແກ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407025",
                "vill_name": "ໜອງແຄນ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407026",
                "vill_name": "ໜອງເດີນ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407027",
                "vill_name": "ນາບອນ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407028",
                "vill_name": "ຄຳທອງ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407029",
                "vill_name": "ຫ້ວຍເປົ້າ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407030",
                "vill_name": "ນາກະລອນ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407031",
                "vill_name": "ສັນທົ່ງ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407032",
                "vill_name": "ຂົວເສັດ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407033",
                "vill_name": "ຄຽງຕັງແລ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407034",
                "vill_name": "ສະນຶມນາ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407035",
                "vill_name": "ທົ່ງຂາມ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407036",
                "vill_name": "ພັງຮາມ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407037",
                "vill_name": "ລະຫວາດ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407038",
                "vill_name": "ດົງບັງ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407039",
                "vill_name": "ພໍ່ຫຼົງ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407040",
                "vill_name": "ດົງຕ້ອງນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407041",
                "vill_name": "ສະຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407042",
                "vill_name": "ໜອງຕົວະ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407043",
                "vill_name": "ດົງຕ້ອງໃຫຍ່",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407044",
                "vill_name": "ໜອງຕາໃກ້",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407045",
                "vill_name": "ເລນ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407046",
                "vill_name": "ງິ້ວ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407047",
                "vill_name": "ພໍ່ນວນ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407048",
                "vill_name": "ສັນດົງ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407049",
                "vill_name": "ສະນຶມນອກ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407050",
                "vill_name": "ຄຽງຕາດສູງ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407051",
                "vill_name": "ສະນິມໄນ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407052",
                "vill_name": "ພໍ່ຕອກ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407053",
                "vill_name": "ບາກນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407054",
                "vill_name": "ບາກໃຫຍ່",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407055",
                "vill_name": "ວັງເປືອຍ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407056",
                "vill_name": "ເຕເມສັງທອງ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407057",
                "vill_name": "ພໍ່ປູມ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407058",
                "vill_name": "ອີ່ເລີງ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407059",
                "vill_name": "ອິນແປງ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407060",
                "vill_name": "ມ່ວນແທ້",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407061",
                "vill_name": "ດະເຊຍໂພນຫີນ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407062",
                "vill_name": "ດອນດູ່",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407063",
                "vill_name": "ຕາກີດໃຫຍ່",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407064",
                "vill_name": "ຕາກີດນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407065",
                "vill_name": "ໝື່ນປາກດີ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407066",
                "vill_name": "ຄົວ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407067",
                "vill_name": "ເລົ່າຫນອງ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407068",
                "vill_name": "ທົ່ງໂກະ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407069",
                "vill_name": "ເຕນ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407070",
                "vill_name": "ເພົາ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407071",
                "vill_name": "ເພຍລາດ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407072",
                "vill_name": "ຫໍກອງແບ່ງ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407073",
                "vill_name": "ຫາຍໂສກ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407074",
                "vill_name": "ຫຼວງເສນາ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407075",
                "vill_name": "ຂະຫຍອງແຂກນອກ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407076",
                "vill_name": "ຂະຫຍອງແຂກໃນ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407077",
                "vill_name": "ມ່ວງຊຸມ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407078",
                "vill_name": "ປາກທໍ່",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407079",
                "vill_name": "ດະເຊຍນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407080",
                "vill_name": "ພໍ່ເຂັມ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407081",
                "vill_name": "ທົ່ງສຸ້ຍ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407082",
                "vill_name": "ໜອງຕົມ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407083",
                "vill_name": "ບ້ານດົງ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407084",
                "vill_name": "ພວກໃຫຍ່",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407085",
                "vill_name": "ວັງຍາວ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407086",
                "vill_name": "ຫ້ວຍແສງ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407087",
                "vill_name": "ຜັກກູດ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407088",
                "vill_name": "ໜອງຈົວ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407089",
                "vill_name": "ພວກ​​​​",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407090",
                "vill_name": "ອົ້ນນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407091",
                "vill_name": "ຫ້ວຍຫູນເໜືອ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407092",
                "vill_name": "ອົ້ນໃຫຍ່",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407093",
                "vill_name": "ອົ້ນແບ່ງ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407094",
                "vill_name": "ຫ້ວຍຫູນໃຕ້",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407095",
                "vill_name": "ຕຸກລຸກ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407096",
                "vill_name": "ນ້ຳແບ່ງ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407097",
                "vill_name": "ເລົ່າຫຍ້າ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407098",
                "vill_name": "ໂນນສູງ",
                "vill_name_en": "",
                "dr_id": "1407"
              },
              {
                "vill_id": "1407099",
                "vill_name": "ໂນນດູ່",
                "vill_name_en": "",
                "dr_id": "1407"
              }
            ]
          },
          {
            "dr_id": "1408",
            "dr_name": "ສະມ້ວຍ",
            "dr_name_en": "Samoi",
            "pr_id": "14",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "1408001",
                "vill_name": "ເທດສະບານ",
                "vill_name_en": "",
                "dr_id": "1408"
              },
              {
                "vill_id": "1408002",
                "vill_name": "ອາສົກອັນລະ",
                "vill_name_en": "",
                "dr_id": "1408"
              },
              {
                "vill_id": "1408003",
                "vill_name": "ອາໂມນໃຕ້",
                "vill_name_en": "",
                "dr_id": "1408"
              },
              {
                "vill_id": "1408004",
                "vill_name": "ຕາຫລຸງກາງ",
                "vill_name_en": "",
                "dr_id": "1408"
              },
              {
                "vill_id": "1408005",
                "vill_name": "ຕະລຽບ",
                "vill_name_en": "",
                "dr_id": "1408"
              },
              {
                "vill_id": "1408006",
                "vill_name": "ໂຫ່",
                "vill_name_en": "",
                "dr_id": "1408"
              },
              {
                "vill_id": "1408007",
                "vill_name": "ປະລູອາຕາງ",
                "vill_name_en": "",
                "dr_id": "1408"
              },
              {
                "vill_id": "1408010",
                "vill_name": "ອາເສົາ-ອາຍົງ",
                "vill_name_en": "",
                "dr_id": "1408"
              },
              {
                "vill_id": "1408011",
                "vill_name": "ອາເມັນ",
                "vill_name_en": "",
                "dr_id": "1408"
              },
              {
                "vill_id": "1408012",
                "vill_name": "ອາລາ",
                "vill_name_en": "",
                "dr_id": "1408"
              },
              {
                "vill_id": "1408013",
                "vill_name": "ລະຕົວະ",
                "vill_name_en": "",
                "dr_id": "1408"
              },
              {
                "vill_id": "1408014",
                "vill_name": "ລະເສນ",
                "vill_name_en": "",
                "dr_id": "1408"
              },
              {
                "vill_id": "1408015",
                "vill_name": "ອາຈາຮົງຕຸນ",
                "vill_name_en": "",
                "dr_id": "1408"
              },
              {
                "vill_id": "1408016",
                "vill_name": "ເຊ",
                "vill_name_en": "",
                "dr_id": "1408"
              },
              {
                "vill_id": "1408017",
                "vill_name": "ຕັນຢູ",
                "vill_name_en": "",
                "dr_id": "1408"
              },
              {
                "vill_id": "1408018",
                "vill_name": "ສະໝ້ວຍ",
                "vill_name_en": "",
                "dr_id": "1408"
              },
              {
                "vill_id": "1408019",
                "vill_name": "ກິແນະ",
                "vill_name_en": "",
                "dr_id": "1408"
              },
              {
                "vill_id": "1408020",
                "vill_name": "ອາວອງ",
                "vill_name_en": "",
                "dr_id": "1408"
              },
              {
                "vill_id": "1408021",
                "vill_name": "ຕັນດີ",
                "vill_name_en": "",
                "dr_id": "1408"
              },
              {
                "vill_id": "1408022",
                "vill_name": "ອາເສົາ",
                "vill_name_en": "",
                "dr_id": "1408"
              },
              {
                "vill_id": "1408023",
                "vill_name": "ພີນເຊ",
                "vill_name_en": "",
                "dr_id": "1408"
              },
              {
                "vill_id": "1408024",
                "vill_name": "ຕັງກວານ",
                "vill_name_en": "",
                "dr_id": "1408"
              },
              {
                "vill_id": "1408025",
                "vill_name": "ຕຼຶຶບເໜືອ",
                "vill_name_en": "",
                "dr_id": "1408"
              },
              {
                "vill_id": "1408026",
                "vill_name": "ຕາໂລຍ",
                "vill_name_en": "",
                "dr_id": "1408"
              },
              {
                "vill_id": "1408027",
                "vill_name": "ກິລິໃຫຍ່",
                "vill_name_en": "",
                "dr_id": "1408"
              },
              {
                "vill_id": "1408028",
                "vill_name": "ອາໄມ້",
                "vill_name_en": "",
                "dr_id": "1408"
              },
              {
                "vill_id": "1408029",
                "vill_name": "ອາຈຶງດີແລ",
                "vill_name_en": "",
                "dr_id": "1408"
              },
              {
                "vill_id": "1408030",
                "vill_name": "ອາໂມນເໜືອ",
                "vill_name_en": "",
                "dr_id": "1408"
              },
              {
                "vill_id": "1408031",
                "vill_name": "ອາຊິງໃຕ້",
                "vill_name_en": "",
                "dr_id": "1408"
              },
              {
                "vill_id": "1408032",
                "vill_name": "ລະວາໃຕ້",
                "vill_name_en": "",
                "dr_id": "1408"
              },
              {
                "vill_id": "1408033",
                "vill_name": "ລະວາເໜືອ",
                "vill_name_en": "",
                "dr_id": "1408"
              },
              {
                "vill_id": "1408034",
                "vill_name": "ພີນເບ",
                "vill_name_en": "",
                "dr_id": "1408"
              },
              {
                "vill_id": "1408035",
                "vill_name": "ປັນໄຕ",
                "vill_name_en": "",
                "dr_id": "1408"
              },
              {
                "vill_id": "1408036",
                "vill_name": "ອາວາວ",
                "vill_name_en": "",
                "dr_id": "1408"
              },
              {
                "vill_id": "1408037",
                "vill_name": "ລາເລາະ",
                "vill_name_en": "",
                "dr_id": "1408"
              },
              {
                "vill_id": "1408038",
                "vill_name": "ອັງກົງ",
                "vill_name_en": "",
                "dr_id": "1408"
              },
              {
                "vill_id": "1408039",
                "vill_name": "ລະໄລສ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1408"
              },
              {
                "vill_id": "1408040",
                "vill_name": "ລະຮາງ",
                "vill_name_en": "",
                "dr_id": "1408"
              },
              {
                "vill_id": "1408041",
                "vill_name": "ຕັງໂກ",
                "vill_name_en": "",
                "dr_id": "1408"
              },
              {
                "vill_id": "1408042",
                "vill_name": "ພີນອາ",
                "vill_name_en": "",
                "dr_id": "1408"
              },
              {
                "vill_id": "1408043",
                "vill_name": "ລະໄລກົງ",
                "vill_name_en": "",
                "dr_id": "1408"
              },
              {
                "vill_id": "1408044",
                "vill_name": "ອາຈິງດີແລ",
                "vill_name_en": "",
                "dr_id": "1408"
              },
              {
                "vill_id": "1408045",
                "vill_name": "ອາຈຶງແລັງ",
                "vill_name_en": "",
                "dr_id": "1408"
              },
              {
                "vill_id": "1408046",
                "vill_name": "ແມວ",
                "vill_name_en": "",
                "dr_id": "1408"
              },
              {
                "vill_id": "1408047",
                "vill_name": "ອາຕຸກ",
                "vill_name_en": "",
                "dr_id": "1408"
              },
              {
                "vill_id": "1408048",
                "vill_name": "ອາແດງກູຕາຍ",
                "vill_name_en": "",
                "dr_id": "1408"
              },
              {
                "vill_id": "1408049",
                "vill_name": "ອາໂຫ້",
                "vill_name_en": "",
                "dr_id": "1408"
              },
              {
                "vill_id": "1408050",
                "vill_name": "ອາວ້າຍ",
                "vill_name_en": "",
                "dr_id": "1408"
              },
              {
                "vill_id": "1408051",
                "vill_name": "ປີໄຮ່",
                "vill_name_en": "",
                "dr_id": "1408"
              },
              {
                "vill_id": "1408052",
                "vill_name": "ກະເລງ",
                "vill_name_en": "",
                "dr_id": "1408"
              },
              {
                "vill_id": "1408053",
                "vill_name": "ຕະລໍໃຕ້",
                "vill_name_en": "",
                "dr_id": "1408"
              },
              {
                "vill_id": "1408054",
                "vill_name": "ຕະລໍເໜືອ",
                "vill_name_en": "",
                "dr_id": "1408"
              },
              {
                "vill_id": "1408055",
                "vill_name": "ກູໂບຍ",
                "vill_name_en": "",
                "dr_id": "1408"
              },
              {
                "vill_id": "1408056",
                "vill_name": "ກະວະ",
                "vill_name_en": "",
                "dr_id": "1408"
              },
              {
                "vill_id": "1408057",
                "vill_name": "ຕະຫວາຍ",
                "vill_name_en": "",
                "dr_id": "1408"
              },
              {
                "vill_id": "1408058",
                "vill_name": "ອາຊິງເໜືອ",
                "vill_name_en": "",
                "dr_id": "1408"
              }
            ]
          }
        ]
      },
      {
        "pr_id": "15",
        "pr_name": "ເຊກອງ",
        "pr_name_en": "Xekong",
        "districts": [
          {"dr_name": "ເລືອກເມືອງປະຈຸບັນ", "villages": []},
          {
            "dr_id": "1501",
            "dr_name": "ລະມາມ",
            "dr_name_en": "Lamarm",
            "pr_id": "15",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "150101",
                "vill_name": "ດອນຈັນ",
                "vill_name_en": "",
                "dr_id": "1501"
              },
              {
                "vill_id": "150102",
                "vill_name": "ດ່ານ",
                "vill_name_en": "",
                "dr_id": "1501"
              },
              {
                "vill_id": "150103",
                "vill_name": "ເຊນໍ້ານ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1501"
              },
              {
                "vill_id": "150104",
                "vill_name": "ກະໂດນໃໝ່",
                "vill_name_en": "",
                "dr_id": "1501"
              },
              {
                "vill_id": "150105",
                "vill_name": "ໂພນ",
                "vill_name_en": "",
                "dr_id": "1501"
              },
              {
                "vill_id": "150106",
                "vill_name": "ໂນ້ນມີໄຊ",
                "vill_name_en": "",
                "dr_id": "1501"
              },
              {
                "vill_id": "150107",
                "vill_name": "ວັດຫຼວງ",
                "vill_name_en": "",
                "dr_id": "1501"
              },
              {
                "vill_id": "150108",
                "vill_name": "ໂພນຄໍາ",
                "vill_name_en": "",
                "dr_id": "1501"
              },
              {
                "vill_id": "150109",
                "vill_name": "ລະວີຝັງແດງ",
                "vill_name_en": "",
                "dr_id": "1501"
              },
              {
                "vill_id": "150110",
                "vill_name": "ໂມ",
                "vill_name_en": "",
                "dr_id": "1501"
              },
              {
                "vill_id": "150111",
                "vill_name": "ປາກໂທນ",
                "vill_name_en": "",
                "dr_id": "1501"
              },
              {
                "vill_id": "150112",
                "vill_name": "ລະວີນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1501"
              },
              {
                "vill_id": "150113",
                "vill_name": "ຕຸຍ",
                "vill_name_en": "",
                "dr_id": "1501"
              },
              {
                "vill_id": "150114",
                "vill_name": "ເພຍໃໝ່",
                "vill_name_en": "",
                "dr_id": "1501"
              },
              {
                "vill_id": "1501001",
                "vill_name": "ທ່າຫຼວງ",
                "vill_name_en": "",
                "dr_id": "1501"
              },
              {
                "vill_id": "1501002",
                "vill_name": "ລະວີລຳພັນ",
                "vill_name_en": "",
                "dr_id": "1501"
              },
              {
                "vill_id": "1501003",
                "vill_name": "ກະສັງກາງ",
                "vill_name_en": "",
                "dr_id": "1501"
              },
              {
                "vill_id": "1501004",
                "vill_name": "ປະອໍ",
                "vill_name_en": "",
                "dr_id": "1501"
              },
              {
                "vill_id": "1501005",
                "vill_name": "ນາເວີ+ໄກເງງ",
                "vill_name_en": "",
                "dr_id": "1501"
              },
              {
                "vill_id": "1501006",
                "vill_name": "ຕະອູນ",
                "vill_name_en": "",
                "dr_id": "1501"
              },
              {
                "vill_id": "1501007",
                "vill_name": "ຕອກອົງແກ້ວ",
                "vill_name_en": "",
                "dr_id": "1501"
              },
              {
                "vill_id": "1501008",
                "vill_name": "ຈະວີກ-ນາລາຍ",
                "vill_name_en": "",
                "dr_id": "1501"
              },
              {
                "vill_id": "1501009",
                "vill_name": "ຈະວີກ-ນາລາຍ",
                "vill_name_en": "",
                "dr_id": "1501"
              },
              {
                "vill_id": "1501011",
                "vill_name": "ຈະວີກ-ນາລາຍ",
                "vill_name_en": "",
                "dr_id": "1501"
              },
              {
                "vill_id": "1501012",
                "vill_name": "ແກ້ງຫຼວງ",
                "vill_name_en": "",
                "dr_id": "1501"
              },
              {
                "vill_id": "1501013",
                "vill_name": "ປະຫຶງ",
                "vill_name_en": "",
                "dr_id": "1501"
              },
              {
                "vill_id": "1501014",
                "vill_name": "ຈະນຳ",
                "vill_name_en": "",
                "dr_id": "1501"
              },
              {
                "vill_id": "1501015",
                "vill_name": "ແກ້ງຫຼວງ",
                "vill_name_en": "",
                "dr_id": "1501"
              },
              {
                "vill_id": "1501016",
                "vill_name": "ຮ່ອງໄລ",
                "vill_name_en": "",
                "dr_id": "1501"
              },
              {
                "vill_id": "1501017",
                "vill_name": "ໂນນໜອງຫວ້າ",
                "vill_name_en": "",
                "dr_id": "1501"
              },
              {
                "vill_id": "1501019",
                "vill_name": "ຫ້ວຍຂຽວ+ໜອງບົງ",
                "vill_name_en": "",
                "dr_id": "1501"
              },
              {
                "vill_id": "1501020",
                "vill_name": "ຕອກສະໝີງ",
                "vill_name_en": "",
                "dr_id": "1501"
              },
              {
                "vill_id": "1501021",
                "vill_name": "ຕີ້ວ",
                "vill_name_en": "",
                "dr_id": "1501"
              },
              {
                "vill_id": "1501022",
                "vill_name": "ແບ່ງ",
                "vill_name_en": "",
                "dr_id": "1501"
              },
              {
                "vill_id": "1501023",
                "vill_name": "ແກ້ງຫຼວງ",
                "vill_name_en": "",
                "dr_id": "1501"
              },
              {
                "vill_id": "1501024",
                "vill_name": "ວັງສາງ",
                "vill_name_en": "",
                "dr_id": "1501"
              },
              {
                "vill_id": "1501025",
                "vill_name": "ນ້ຳຫ່ຽງ",
                "vill_name_en": "",
                "dr_id": "1501"
              },
              {
                "vill_id": "1501026",
                "vill_name": "ແກ້ງຫຼວງ",
                "vill_name_en": "",
                "dr_id": "1501"
              },
              {
                "vill_id": "1501027",
                "vill_name": "ໂພນໃຫມ່",
                "vill_name_en": "",
                "dr_id": "1501"
              }
            ]
          },
          {
            "dr_id": "1502",
            "dr_name": "ກະລືມ",
            "dr_name_en": "Kaleum",
            "pr_id": "15",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "1502001",
                "vill_name": "ແກ້ງກຽນ",
                "vill_name_en": "",
                "dr_id": "1502"
              },
              {
                "vill_id": "1502002",
                "vill_name": "ຢອນ+ທົງກາຍ",
                "vill_name_en": "",
                "dr_id": "1502"
              },
              {
                "vill_id": "1502003",
                "vill_name": "ປາກໄຊ",
                "vill_name_en": "",
                "dr_id": "1502"
              },
              {
                "vill_id": "1502004",
                "vill_name": "ກະລຸງ+ຕາສິງ",
                "vill_name_en": "",
                "dr_id": "1502"
              },
              {
                "vill_id": "1502005",
                "vill_name": "ບາກ",
                "vill_name_en": "",
                "dr_id": "1502"
              },
              {
                "vill_id": "1502006",
                "vill_name": "ຕະລາງໃໝ່",
                "vill_name_en": "",
                "dr_id": "1502"
              },
              {
                "vill_id": "1502007",
                "vill_name": "ກະດໍ",
                "vill_name_en": "",
                "dr_id": "1502"
              },
              {
                "vill_id": "1502008",
                "vill_name": "ຕາກ",
                "vill_name_en": "",
                "dr_id": "1502"
              },
              {
                "vill_id": "1502009",
                "vill_name": "ຫາດແປະ",
                "vill_name_en": "",
                "dr_id": "1502"
              },
              {
                "vill_id": "1502010",
                "vill_name": "ປາກໄຕ",
                "vill_name_en": "",
                "dr_id": "1502"
              },
              {
                "vill_id": "1502011",
                "vill_name": "ຫາດນອງ",
                "vill_name_en": "",
                "dr_id": "1502"
              },
              {
                "vill_id": "1502012",
                "vill_name": "ຫາດວີ",
                "vill_name_en": "",
                "dr_id": "1502"
              },
              {
                "vill_id": "1502013",
                "vill_name": "ຈິງ",
                "vill_name_en": "",
                "dr_id": "1502"
              },
              {
                "vill_id": "1502014",
                "vill_name": "ສອງຄອນ+ຕາຫີວ",
                "vill_name_en": "",
                "dr_id": "1502"
              },
              {
                "vill_id": "1502015",
                "vill_name": "ຈອກ",
                "vill_name_en": "",
                "dr_id": "1502"
              },
              {
                "vill_id": "1502016",
                "vill_name": "ໂລຍ",
                "vill_name_en": "",
                "dr_id": "1502"
              },
              {
                "vill_id": "1502017",
                "vill_name": "ແຈຼະ-ຝາກຫ້ວຍ-ກະໂລ",
                "vill_name_en": "",
                "dr_id": "1502"
              },
              {
                "vill_id": "1502018",
                "vill_name": "ຕີນ+ຕືມ",
                "vill_name_en": "",
                "dr_id": "1502"
              },
              {
                "vill_id": "1502019",
                "vill_name": "ວັກເໜືອ",
                "vill_name_en": "",
                "dr_id": "1502"
              },
              {
                "vill_id": "1502020",
                "vill_name": "ວັກໄຕ້",
                "vill_name_en": "",
                "dr_id": "1502"
              },
              {
                "vill_id": "1502021",
                "vill_name": "ຕັງປະ",
                "vill_name_en": "",
                "dr_id": "1502"
              },
              {
                "vill_id": "1502022",
                "vill_name": "ຕັງປັງ",
                "vill_name_en": "",
                "dr_id": "1502"
              },
              {
                "vill_id": "1502023",
                "vill_name": "ອາຮັງ",
                "vill_name_en": "",
                "dr_id": "1502"
              },
              {
                "vill_id": "1502024",
                "vill_name": "ປະຕຼາງ",
                "vill_name_en": "",
                "dr_id": "1502"
              },
              {
                "vill_id": "1502025",
                "vill_name": "ກັນດອນ",
                "vill_name_en": "",
                "dr_id": "1502"
              },
              {
                "vill_id": "1502026",
                "vill_name": "ຕະມໍ",
                "vill_name_en": "",
                "dr_id": "1502"
              },
              {
                "vill_id": "1502027",
                "vill_name": "ຕະນຶງ+ວາງ",
                "vill_name_en": "",
                "dr_id": "1502"
              },
              {
                "vill_id": "1502028",
                "vill_name": "ໂດນ",
                "vill_name_en": "",
                "dr_id": "1502"
              },
              {
                "vill_id": "1502029",
                "vill_name": "ໄລປໍ",
                "vill_name_en": "",
                "dr_id": "1502"
              },
              {
                "vill_id": "1502030",
                "vill_name": "ໂປມ",
                "vill_name_en": "",
                "dr_id": "1502"
              },
              {
                "vill_id": "1502031",
                "vill_name": "ໂຣະນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1502"
              },
              {
                "vill_id": "1502032",
                "vill_name": "ໂຣະໃຫ່ຍ",
                "vill_name_en": "",
                "dr_id": "1502"
              },
              {
                "vill_id": "1502033",
                "vill_name": "ຕະລູຍ+ຕາສາກ",
                "vill_name_en": "",
                "dr_id": "1502"
              },
              {
                "vill_id": "1502034",
                "vill_name": "ໂປະ+ສະອາງ+ເລມ",
                "vill_name_en": "",
                "dr_id": "1502"
              },
              {
                "vill_id": "1502035",
                "vill_name": "ອາຣອງ+ປະເເລງ+ອາເປ",
                "vill_name_en": "",
                "dr_id": "1502"
              },
              {
                "vill_id": "1502036",
                "vill_name": "ອູງ",
                "vill_name_en": "",
                "dr_id": "1502"
              },
              {
                "vill_id": "1502037",
                "vill_name": "ອາເວີນ+ອາເຍືອງ",
                "vill_name_en": "",
                "dr_id": "1502"
              },
              {
                "vill_id": "1502038",
                "vill_name": "ຕະລ້ອງ+ໂບບິງ",
                "vill_name_en": "",
                "dr_id": "1502"
              },
              {
                "vill_id": "1502039",
                "vill_name": "ປະເລ",
                "vill_name_en": "",
                "dr_id": "1502"
              },
              {
                "vill_id": "1502040",
                "vill_name": "ອາໂລດ",
                "vill_name_en": "",
                "dr_id": "1502"
              },
              {
                "vill_id": "1502041",
                "vill_name": "ໂກຼ",
                "vill_name_en": "",
                "dr_id": "1502"
              },
              {
                "vill_id": "1502042",
                "vill_name": "ກິອວງ+ອາແຕງ",
                "vill_name_en": "",
                "dr_id": "1502"
              },
              {
                "vill_id": "1502043",
                "vill_name": "ຈະເຕີ",
                "vill_name_en": "",
                "dr_id": "1502"
              },
              {
                "vill_id": "1502044",
                "vill_name": "ຕະວາງ",
                "vill_name_en": "",
                "dr_id": "1502"
              },
              {
                "vill_id": "1502045",
                "vill_name": "ແກ້ງກຸຍ",
                "vill_name_en": "",
                "dr_id": "1502"
              },
              {
                "vill_id": "1502046",
                "vill_name": "ປະນອນ",
                "vill_name_en": "",
                "dr_id": "1502"
              },
              {
                "vill_id": "1502047",
                "vill_name": "ລິງ",
                "vill_name_en": "",
                "dr_id": "1502"
              },
              {
                "vill_id": "1502048",
                "vill_name": "ຕັງກາດ",
                "vill_name_en": "",
                "dr_id": "1502"
              },
              {
                "vill_id": "1502049",
                "vill_name": "ຖ້ຳເດັ່ງ",
                "vill_name_en": "",
                "dr_id": "1502"
              },
              {
                "vill_id": "1502050",
                "vill_name": "ວັງປະງໍ(ຢຶບ-ອຸ່ນ-ປຣາຍ-ຈະນ່ອຍ)",
                "vill_name_en": "",
                "dr_id": "1502"
              },
              {
                "vill_id": "1502051",
                "vill_name": "ອາຊວງ+ຕານາງ+ຕະຫິດ",
                "vill_name_en": "",
                "dr_id": "1502"
              },
              {
                "vill_id": "1502052",
                "vill_name": "ອາງວດ",
                "vill_name_en": "",
                "dr_id": "1502"
              },
              {
                "vill_id": "1502053",
                "vill_name": "ຕັງປຼິນ",
                "vill_name_en": "",
                "dr_id": "1502"
              },
              {
                "vill_id": "1502054",
                "vill_name": "ອາຣອກ",
                "vill_name_en": "",
                "dr_id": "1502"
              },
              {
                "vill_id": "1502055",
                "vill_name": "ລະກາຍ",
                "vill_name_en": "",
                "dr_id": "1502"
              },
              {
                "vill_id": "1502056",
                "vill_name": "ອາເຣາະ",
                "vill_name_en": "",
                "dr_id": "1502"
              },
              {
                "vill_id": "1502057",
                "vill_name": "ອາບືນ",
                "vill_name_en": "",
                "dr_id": "1502"
              }
            ]
          },
          {
            "dr_id": "1503",
            "dr_name": "ດາກຈຶງ",
            "dr_name_en": "Dakcheung",
            "pr_id": "15",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "1503001",
                "vill_name": "ບຣອງໃຫ່ຍ",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503002",
                "vill_name": "ບຣອງນ້ອຍ+ດາກຢີຣ",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503003",
                "vill_name": "ດາກປະເນີ",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503004",
                "vill_name": "ດາກຕຣາງ",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503005",
                "vill_name": "ດາກມຸງ",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503006",
                "vill_name": "ດາກມິ",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503007",
                "vill_name": "ດາກເກ",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503008",
                "vill_name": "ດາກກຳ",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503009",
                "vill_name": "ເປັງຢາ",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503010",
                "vill_name": "ດາກຊຳ",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503011",
                "vill_name": "ດາກເຕຼີບ",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503012",
                "vill_name": "ດາກຣາຍ",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503013",
                "vill_name": "ດາກຢອຍ+ຢີງ",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503014",
                "vill_name": "ຊຽງຫຼວງ",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503015",
                "vill_name": "ດາກຕະອອກນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503016",
                "vill_name": "ດາກໂດມ",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503017",
                "vill_name": "ດາກມວນ",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503018",
                "vill_name": "ດາກມັນ",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503019",
                "vill_name": "ດາກຕາອອກໃຫ່ຍ",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503020",
                "vill_name": "ດາກຢຣັງ",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503021",
                "vill_name": "ດາກປຣານຫຼັກ 20",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503022",
                "vill_name": "ຕັງເຢີງ",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503023",
                "vill_name": "ຕັງໂບຣງ",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503024",
                "vill_name": "ຕັງລູ",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503025",
                "vill_name": "ປຣາວ",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503026",
                "vill_name": "ໄກໂລ",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503027",
                "vill_name": "ດາກປອງ",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503028",
                "vill_name": "ດາກຈາງ",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503029",
                "vill_name": "ຕັງມີ",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503030",
                "vill_name": "ກອນຢົງ",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503031",
                "vill_name": "ດາກດຽງ+ດາກຣຸ",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503032",
                "vill_name": "ດາກບຣາງ",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503033",
                "vill_name": "ດາກເດີນ",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503034",
                "vill_name": "ດາກຈາກ",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503035",
                "vill_name": "ດາກຕຽມ",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503036",
                "vill_name": "ດາກເຊງ",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503037",
                "vill_name": "ດາກຈຶງ",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503038",
                "vill_name": "ດາກບອງ",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503039",
                "vill_name": "ງອນດອນ",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503040",
                "vill_name": "ເກົ່າ",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503041",
                "vill_name": "ໂນນສະຫັວນ",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503042",
                "vill_name": "ຕັງຕະລັງ",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503043",
                "vill_name": "ຕັງເຢີຍ",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503044",
                "vill_name": "ຕັງໂນງ",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503045",
                "vill_name": "ສາງໃໝ່",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503046",
                "vill_name": "ດາກດໍ",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503047",
                "vill_name": "ດາກເດັນ+ດາກໄວ",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503048",
                "vill_name": "ດາກສຽງ A",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503049",
                "vill_name": "ຕົງສຽງ",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503050",
                "vill_name": "ດາກດິນ",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503051",
                "vill_name": "ດາກສາ",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503052",
                "vill_name": "ຄູນໄຊ",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503053",
                "vill_name": "ໜອງບຸ",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503054",
                "vill_name": "ດາກນ່ອຍ",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503055",
                "vill_name": "ດາກປຳ",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503056",
                "vill_name": "ດາກຊວງ",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503057",
                "vill_name": "ດາກເອີຍ",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503058",
                "vill_name": "ງອນບັນ",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503059",
                "vill_name": "ມັງຫາ",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503060",
                "vill_name": "ອາຢູນ",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503061",
                "vill_name": "ດາກວາງ",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503062",
                "vill_name": "ຕັງຢຼູຣງ",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503063",
                "vill_name": "ຫາອອດ",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503064",
                "vill_name": "ສຽງໃໝ່",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503065",
                "vill_name": "ດາກດູງ",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503066",
                "vill_name": "ດາກກັງ",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503067",
                "vill_name": "ຈະລິງ",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503068",
                "vill_name": "ດາກເລ",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503069",
                "vill_name": "ດາກຕີຣງ",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503070",
                "vill_name": "ດາກມອງໃຫຍ່+ມອງນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503071",
                "vill_name": "ດາກເດີຍ",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503072",
                "vill_name": "ດາກຢາງ",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503073",
                "vill_name": "ຕັງເປືອງ",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503074",
                "vill_name": "ດາກເຣີນ",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503075",
                "vill_name": "ໜອງຍືນ",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503076",
                "vill_name": "ດາກວໍ",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503077",
                "vill_name": "ດາກສຽງ B",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503078",
                "vill_name": "ດາກວາຍ",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503079",
                "vill_name": "ດາກຣັນ",
                "vill_name_en": "",
                "dr_id": "1503"
              },
              {
                "vill_id": "1503080",
                "vill_name": "ດາກປາ+ດາກລານ",
                "vill_name_en": "",
                "dr_id": "1503"
              }
            ]
          },
          {
            "dr_id": "1504",
            "dr_name": "ທ່າແຕງ",
            "dr_name_en": "Thateng",
            "pr_id": "15",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "150401",
                "vill_name": "ຫົວເຊ",
                "vill_name_en": "",
                "dr_id": "1504"
              },
              {
                "vill_id": "150402",
                "vill_name": "ກະປື",
                "vill_name_en": "",
                "dr_id": "1504"
              },
              {
                "vill_id": "150403",
                "vill_name": "ຫ້ວຍດຳບຶກ",
                "vill_name_en": "",
                "dr_id": "1504"
              },
              {
                "vill_id": "150404",
                "vill_name": "ກາເຟ",
                "vill_name_en": "",
                "dr_id": "1504"
              },
              {
                "vill_id": "1504001",
                "vill_name": "ທ່າແຕງເໜືອ",
                "vill_name_en": "",
                "dr_id": "1504"
              },
              {
                "vill_id": "1504002",
                "vill_name": "ແສນເໜືອ",
                "vill_name_en": "",
                "dr_id": "1504"
              },
              {
                "vill_id": "1504003",
                "vill_name": "ຫ້ວຍຊາຍ",
                "vill_name_en": "",
                "dr_id": "1504"
              },
              {
                "vill_id": "1504004",
                "vill_name": "ໜອງເລົ່າ",
                "vill_name_en": "",
                "dr_id": "1504"
              },
              {
                "vill_id": "1504005",
                "vill_name": "ໜອງນົກ",
                "vill_name_en": "",
                "dr_id": "1504"
              },
              {
                "vill_id": "1504006",
                "vill_name": "ແສນໃຕ້",
                "vill_name_en": "",
                "dr_id": "1504"
              },
              {
                "vill_id": "1504007",
                "vill_name": "ເກາະລະກາງ",
                "vill_name_en": "",
                "dr_id": "1504"
              },
              {
                "vill_id": "1504008",
                "vill_name": "ເກາະຫົວພູ",
                "vill_name_en": "",
                "dr_id": "1504"
              },
              {
                "vill_id": "1504009",
                "vill_name": "ລີກ",
                "vill_name_en": "",
                "dr_id": "1504"
              },
              {
                "vill_id": "1504010",
                "vill_name": "ຈະຫຼາຍເກົ່າ",
                "vill_name_en": "",
                "dr_id": "1504"
              },
              {
                "vill_id": "1504011",
                "vill_name": "ກຳກອກ",
                "vill_name_en": "",
                "dr_id": "1504"
              },
              {
                "vill_id": "1504012",
                "vill_name": "ຈະກຳໃໝ່",
                "vill_name_en": "",
                "dr_id": "1504"
              },
              {
                "vill_id": "1504013",
                "vill_name": "ຈະກຳໃຫ່ຍ",
                "vill_name_en": "",
                "dr_id": "1504"
              },
              {
                "vill_id": "1504014",
                "vill_name": "ຈຸນລາ",
                "vill_name_en": "",
                "dr_id": "1504"
              },
              {
                "vill_id": "1504015",
                "vill_name": "ປະໄລ",
                "vill_name_en": "",
                "dr_id": "1504"
              },
              {
                "vill_id": "1504016",
                "vill_name": "ຍົກທົ່ງ",
                "vill_name_en": "",
                "dr_id": "1504"
              },
              {
                "vill_id": "1504017",
                "vill_name": "ຈະກຸດ",
                "vill_name_en": "",
                "dr_id": "1504"
              },
              {
                "vill_id": "1504018",
                "vill_name": "ກາເຟ",
                "vill_name_en": "",
                "dr_id": "1504"
              },
              {
                "vill_id": "1504019",
                "vill_name": "ກົກໄຮ",
                "vill_name_en": "",
                "dr_id": "1504"
              },
              {
                "vill_id": "1504020",
                "vill_name": "ໂທນນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1504"
              },
              {
                "vill_id": "1504021",
                "vill_name": "ໂນນສຳພັນ",
                "vill_name_en": "",
                "dr_id": "1504"
              },
              {
                "vill_id": "1504022",
                "vill_name": "ສະທົວເໜືອ",
                "vill_name_en": "",
                "dr_id": "1504"
              },
              {
                "vill_id": "1504023",
                "vill_name": "ສະທົວໃຕ້",
                "vill_name_en": "",
                "dr_id": "1504"
              },
              {
                "vill_id": "1504024",
                "vill_name": "ໂທນໃຫ່ຍ",
                "vill_name_en": "",
                "dr_id": "1504"
              },
              {
                "vill_id": "1504025",
                "vill_name": "ຈຸງຮຸງເໜືອ",
                "vill_name_en": "",
                "dr_id": "1504"
              },
              {
                "vill_id": "1504026",
                "vill_name": "ຈຸງຮຸງໃຕ້",
                "vill_name_en": "",
                "dr_id": "1504"
              },
              {
                "vill_id": "1504027",
                "vill_name": "ທົ່ງຍາວ",
                "vill_name_en": "",
                "dr_id": "1504"
              },
              {
                "vill_id": "1504028",
                "vill_name": "ທົ່ງໂກງ",
                "vill_name_en": "",
                "dr_id": "1504"
              },
              {
                "vill_id": "1504029",
                "vill_name": "ທົ່ງຫວາຍ",
                "vill_name_en": "",
                "dr_id": "1504"
              },
              {
                "vill_id": "1504030",
                "vill_name": "ດອນຊາ",
                "vill_name_en": "",
                "dr_id": "1504"
              },
              {
                "vill_id": "1504031",
                "vill_name": "ປະແຫຼງເໜືອ",
                "vill_name_en": "",
                "dr_id": "1504"
              },
              {
                "vill_id": "1504032",
                "vill_name": "ປະແຫຼງໃຕ້",
                "vill_name_en": "",
                "dr_id": "1504"
              },
              {
                "vill_id": "1504033",
                "vill_name": "ປົ່ງໃຕ້",
                "vill_name_en": "",
                "dr_id": "1504"
              },
              {
                "vill_id": "1504034",
                "vill_name": "ປົ່ງເໜຶອ",
                "vill_name_en": "",
                "dr_id": "1504"
              },
              {
                "vill_id": "1504035",
                "vill_name": "ກົກພຸງເໜືອ",
                "vill_name_en": "",
                "dr_id": "1504"
              },
              {
                "vill_id": "1504036",
                "vill_name": "ກົກພຸງເກົ່າ",
                "vill_name_en": "",
                "dr_id": "1504"
              },
              {
                "vill_id": "1504037",
                "vill_name": "ກົກພຸງໃໝ່",
                "vill_name_en": "",
                "dr_id": "1504"
              },
              {
                "vill_id": "1504038",
                "vill_name": "ຕຸ້ມຍໍ",
                "vill_name_en": "",
                "dr_id": "1504"
              },
              {
                "vill_id": "1504039",
                "vill_name": "ຕະກຽວ",
                "vill_name_en": "",
                "dr_id": "1504"
              },
              {
                "vill_id": "1504040",
                "vill_name": "ໝື່ນກາງ",
                "vill_name_en": "",
                "dr_id": "1504"
              },
              {
                "vill_id": "1504041",
                "vill_name": "ໝື່ນໃໝ່",
                "vill_name_en": "",
                "dr_id": "1504"
              },
              {
                "vill_id": "1504042",
                "vill_name": "ໜອງບົວໃສ",
                "vill_name_en": "",
                "dr_id": "1504"
              },
              {
                "vill_id": "1504043",
                "vill_name": "ປະຫຼາຍ",
                "vill_name_en": "",
                "dr_id": "1504"
              },
              {
                "vill_id": "1504044",
                "vill_name": "ກັນດອນ",
                "vill_name_en": "",
                "dr_id": "1504"
              },
              {
                "vill_id": "1504045",
                "vill_name": "ຈະກຳລິດ",
                "vill_name_en": "",
                "dr_id": "1504"
              },
              {
                "vill_id": "1504046",
                "vill_name": "ທ່າແຕງໃຕ້",
                "vill_name_en": "",
                "dr_id": "1504"
              },
              {
                "vill_id": "1504047",
                "vill_name": "ວາງໃໝ່",
                "vill_name_en": "",
                "dr_id": "1504"
              },
              {
                "vill_id": "1504048",
                "vill_name": "ສະທື",
                "vill_name_en": "",
                "dr_id": "1504"
              },
              {
                "vill_id": "1504049",
                "vill_name": "ຢຶບໃໝ່",
                "vill_name_en": "",
                "dr_id": "1504"
              },
              {
                "vill_id": "1504050",
                "vill_name": "ກົງຕາຢູນ",
                "vill_name_en": "",
                "dr_id": "1504"
              },
              {
                "vill_id": "1504051",
                "vill_name": "ຫຼັກຂາວ",
                "vill_name_en": "",
                "dr_id": "1504"
              },
              {
                "vill_id": "1504052",
                "vill_name": "ໝອງກັນ",
                "vill_name_en": "",
                "dr_id": "1504"
              }
            ]
          }
        ]
      },
      {
        "pr_id": "16",
        "pr_name": "ຈຳປາສັກ",
        "pr_name_en": "Champasak",
        "districts": [
          {"dr_name": "ເລືອກເມືອງປະຈຸບັນ", "villages": []},
          {
            "dr_id": "1601",
            "dr_name": "ປາກເຊ",
            "dr_name_en": "Pakse",
            "pr_id": "16",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "1601001",
                "vill_name": "ທ່າ​ຫຼວງ",
                "vill_name_en": "",
                "dr_id": "1601"
              },
              {
                "vill_id": "1601003",
                "vill_name": " ທ່າ​ຫຼວງ",
                "vill_name_en": "",
                "dr_id": "1601"
              },
              {
                "vill_id": "1601004",
                "vill_name": "ພະ​ບາດ ",
                "vill_name_en": "",
                "dr_id": "1601"
              },
              {
                "vill_id": "1601005",
                "vill_name": "​ໂພນ​ໄຊ ",
                "vill_name_en": "",
                "dr_id": "1601"
              },
              {
                "vill_id": "1601006",
                "vill_name": "ຫຼັກ​ເມືອງ",
                "vill_name_en": "",
                "dr_id": "1601"
              },
              {
                "vill_id": "1601007",
                "vill_name": "ປາກ​ເຊ",
                "vill_name_en": "",
                "dr_id": "1601"
              },
              {
                "vill_id": "1601008",
                "vill_name": "ທົ່ງ",
                "vill_name_en": "",
                "dr_id": "1601"
              },
              {
                "vill_id": "1601009",
                "vill_name": "ບ້ານຮ່ອງ​ຄະ​ຍອມ",
                "vill_name_en": "",
                "dr_id": "1601"
              },
              {
                "vill_id": "1601010",
                "vill_name": "ສາງ​ນ້ຳມັນ",
                "vill_name_en": "",
                "dr_id": "1601"
              },
              {
                "vill_id": "1601011",
                "vill_name": "​ໂພນ​ກຸງ",
                "vill_name_en": "",
                "dr_id": "1601"
              },
              {
                "vill_id": "1601012",
                "vill_name": "​ໂພນ​ສະຫວັນ",
                "vill_name_en": "",
                "dr_id": "1601"
              },
              {
                "vill_id": "1601013",
                "vill_name": "ທ່າ​ຫີນ​ເໜືອ",
                "vill_name_en": "",
                "dr_id": "1601"
              },
              {
                "vill_id": "1601014",
                "vill_name": "ຫ້ວຍ​ປູນ",
                "vill_name_en": "",
                "dr_id": "1601"
              },
              {
                "vill_id": "1601015",
                "vill_name": "ກົກ​ເດື່ອ",
                "vill_name_en": "",
                "dr_id": "1601"
              },
              {
                "vill_id": "1601016",
                "vill_name": "​ໂພນ​ງາມ",
                "vill_name_en": "",
                "dr_id": "1601"
              },
              {
                "vill_id": "1601017",
                "vill_name": "ທ່າ​ໄຮ່",
                "vill_name_en": "",
                "dr_id": "1601"
              },
              {
                "vill_id": "1601018",
                "vill_name": "ຄັນ​ເກີງ",
                "vill_name_en": "",
                "dr_id": "1601"
              },
              {
                "vill_id": "1601019",
                "vill_name": "​ແກ້ງ​ເກີ​ງ",
                "vill_name_en": "",
                "dr_id": "1601"
              },
              {
                "vill_id": "1601020",
                "vill_name": "ຫ້ວຍ​ເລົາ",
                "vill_name_en": "",
                "dr_id": "1601"
              },
              {
                "vill_id": "1601021",
                "vill_name": "​ໂພນ​ສີ​ໄຄ",
                "vill_name_en": "",
                "dr_id": "1601"
              },
              {
                "vill_id": "1601022",
                "vill_name": "ດົງ​​ເກາະ​ໂລງ",
                "vill_name_en": "",
                "dr_id": "1601"
              },
              {
                "vill_id": "1601023",
                "vill_name": "ບ້ານດອນ​ເຂາະ",
                "vill_name_en": "",
                "dr_id": "1601"
              },
              {
                "vill_id": "1601024",
                "vill_name": "ແກ້ງຊັນ",
                "vill_name_en": "",
                "dr_id": "1601"
              },
              {
                "vill_id": "1601025",
                "vill_name": "ນາ​ແຫກ",
                "vill_name_en": "",
                "dr_id": "1601"
              },
              {
                "vill_id": "1601026",
                "vill_name": "​ໂຊກ​ອຳນວຍ",
                "vill_name_en": "",
                "dr_id": "1601"
              },
              {
                "vill_id": "1601027",
                "vill_name": "ຫ້ວຍ​ຍາງ​ຄຳ",
                "vill_name_en": "",
                "dr_id": "1601"
              },
              {
                "vill_id": "1601028",
                "vill_name": "ບ້ານກາງ",
                "vill_name_en": "",
                "dr_id": "1601"
              },
              {
                "vill_id": "1601029",
                "vill_name": "ພູມ່ວງ",
                "vill_name_en": "",
                "dr_id": "1601"
              },
              {
                "vill_id": "1601030",
                "vill_name": "ສວນສະຫວັນ",
                "vill_name_en": "",
                "dr_id": "1601"
              },
              {
                "vill_id": "1601031",
                "vill_name": "ສະໜາມໄຊ",
                "vill_name_en": "",
                "dr_id": "1601"
              },
              {
                "vill_id": "1601032",
                "vill_name": "ໂນນສະຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "1601"
              },
              {
                "vill_id": "1601033",
                "vill_name": "ຄອນໄຫຼ",
                "vill_name_en": "",
                "dr_id": "1601"
              },
              {
                "vill_id": "1601034",
                "vill_name": "ໂພນສະອາດ",
                "vill_name_en": "",
                "dr_id": "1601"
              },
              {
                "vill_id": "1601035",
                "vill_name": "ແກ",
                "vill_name_en": "",
                "dr_id": "1601"
              },
              {
                "vill_id": "1601036",
                "vill_name": "ໂພຕາກ",
                "vill_name_en": "",
                "dr_id": "1601"
              },
              {
                "vill_id": "1601037",
                "vill_name": "ແຫ່",
                "vill_name_en": "",
                "dr_id": "1601"
              },
              {
                "vill_id": "1601038",
                "vill_name": "ຊົງເຊ",
                "vill_name_en": "",
                "dr_id": "1601"
              },
              {
                "vill_id": "1601039",
                "vill_name": "ນາຈຽງ",
                "vill_name_en": "",
                "dr_id": "1601"
              },
              {
                "vill_id": "1601040",
                "vill_name": "ສະພານໄຊ",
                "vill_name_en": "",
                "dr_id": "1601"
              },
              {
                "vill_id": "1601041",
                "vill_name": "ພັດທະນາ",
                "vill_name_en": "",
                "dr_id": "1601"
              },
              {
                "vill_id": "1601042",
                "vill_name": "ແກ້ວສຳພັນ",
                "vill_name_en": "",
                "dr_id": "1601"
              },
              {
                "vill_id": "1601043",
                "vill_name": "​ໂນນ​ດູ່",
                "vill_name_en": "",
                "dr_id": "1601"
              },
              {
                "vill_id": "1601044",
                "vill_name": "ຈັດສັນ",
                "vill_name_en": "",
                "dr_id": "1601"
              },
              {
                "vill_id": "1601045",
                "vill_name": "ນາດ່ານ",
                "vill_name_en": "",
                "dr_id": "1601"
              },
              {
                "vill_id": "1601046",
                "vill_name": "ໂນນສະຫວັນ",
                "vill_name_en": "",
                "dr_id": "1601"
              },
              {
                "vill_id": "1601047",
                "vill_name": "ນາຂາມ",
                "vill_name_en": "",
                "dr_id": "1601"
              },
              {
                "vill_id": "1601048",
                "vill_name": "ຄຳບອນ",
                "vill_name_en": "",
                "dr_id": "1601"
              },
              {
                "vill_id": "1601049",
                "vill_name": "ບ້ານນາ",
                "vill_name_en": "",
                "dr_id": "1601"
              },
              {
                "vill_id": "1601050",
                "vill_name": "ບ້ານນາ",
                "vill_name_en": "",
                "dr_id": "1601"
              },
              {
                "vill_id": "1601051",
                "vill_name": "ຄີລີ",
                "vill_name_en": "",
                "dr_id": "1601"
              },
              {
                "vill_id": "1601052",
                "vill_name": "ວັດຫຼວງ",
                "vill_name_en": "Vatluang",
                "dr_id": "1601"
              }
            ]
          },
          {
            "dr_id": "1602",
            "dr_name": "ຊະນະສົມບູນ",
            "dr_name_en": "Sanasomboon",
            "pr_id": "16",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "1602001",
                "vill_name": "ນາດ່ານ",
                "vill_name_en": "",
                "dr_id": "1602"
              },
              {
                "vill_id": "1602002",
                "vill_name": "ໂນນສະຫວັນນາດ່ານ",
                "vill_name_en": "",
                "dr_id": "1602"
              },
              {
                "vill_id": "1602003",
                "vill_name": "ນາຂາມ",
                "vill_name_en": "",
                "dr_id": "1602"
              },
              {
                "vill_id": "1602004",
                "vill_name": "ຄຳບອນ",
                "vill_name_en": "",
                "dr_id": "1602"
              },
              {
                "vill_id": "1602005",
                "vill_name": "ບ້ານນາ",
                "vill_name_en": "",
                "dr_id": "1602"
              },
              {
                "vill_id": "1602006",
                "vill_name": "ຄີລີ",
                "vill_name_en": "",
                "dr_id": "1602"
              },
              {
                "vill_id": "1602007",
                "vill_name": "ແກ້ງໄມ້ເຮັ້ຍ",
                "vill_name_en": "",
                "dr_id": "1602"
              },
              {
                "vill_id": "1602008",
                "vill_name": "ເລົ່າ",
                "vill_name_en": "",
                "dr_id": "1602"
              },
              {
                "vill_id": "1602009",
                "vill_name": "ທົ່ງຮີ",
                "vill_name_en": "",
                "dr_id": "1602"
              },
              {
                "vill_id": "1602010",
                "vill_name": "ແຊລະບຳ",
                "vill_name_en": "",
                "dr_id": "1602"
              },
              {
                "vill_id": "1602011",
                "vill_name": "ໜອງດູ່",
                "vill_name_en": "",
                "dr_id": "1602"
              },
              {
                "vill_id": "1602012",
                "vill_name": "ປົ່ງເກີມ",
                "vill_name_en": "",
                "dr_id": "1602"
              },
              {
                "vill_id": "1602013",
                "vill_name": "ຫີນລາດ",
                "vill_name_en": "",
                "dr_id": "1602"
              },
              {
                "vill_id": "1602014",
                "vill_name": "ປາກປາກັ້ງ",
                "vill_name_en": "",
                "dr_id": "1602"
              },
              {
                "vill_id": "1602015",
                "vill_name": "ສະລິ",
                "vill_name_en": "",
                "dr_id": "1602"
              },
              {
                "vill_id": "1602016",
                "vill_name": "ວັງເວີນ",
                "vill_name_en": "",
                "dr_id": "1602"
              },
              {
                "vill_id": "1602017",
                "vill_name": "ໜອງໂນ",
                "vill_name_en": "",
                "dr_id": "1602"
              },
              {
                "vill_id": "1602018",
                "vill_name": "ດອນແຊ",
                "vill_name_en": "",
                "dr_id": "1602"
              },
              {
                "vill_id": "1602019",
                "vill_name": "ຄຳແປງ",
                "vill_name_en": "",
                "dr_id": "1602"
              },
              {
                "vill_id": "1602020",
                "vill_name": "ໜອງແຄນ",
                "vill_name_en": "",
                "dr_id": "1602"
              },
              {
                "vill_id": "1602021",
                "vill_name": "ຄຳຫຼວງ",
                "vill_name_en": "",
                "dr_id": "1602"
              },
              {
                "vill_id": "1602022",
                "vill_name": "ຊອນພາກ",
                "vill_name_en": "",
                "dr_id": "1602"
              },
              {
                "vill_id": "1602023",
                "vill_name": "ສີທວນ",
                "vill_name_en": "",
                "dr_id": "1602"
              },
              {
                "vill_id": "1602024",
                "vill_name": "ດົງລານຄຳ",
                "vill_name_en": "",
                "dr_id": "1602"
              },
              {
                "vill_id": "1602025",
                "vill_name": "ສຸລະ",
                "vill_name_en": "",
                "dr_id": "1602"
              },
              {
                "vill_id": "1602026",
                "vill_name": "ໃໝ່ສິງສຳພັນ",
                "vill_name_en": "",
                "dr_id": "1602"
              },
              {
                "vill_id": "1602027",
                "vill_name": "ໜອງເມັກ",
                "vill_name_en": "",
                "dr_id": "1602"
              },
              {
                "vill_id": "1602028",
                "vill_name": "ບຸ່ງຄ້າ",
                "vill_name_en": "",
                "dr_id": "1602"
              },
              {
                "vill_id": "1602029",
                "vill_name": "ນາແກວ",
                "vill_name_en": "",
                "dr_id": "1602"
              },
              {
                "vill_id": "1602030",
                "vill_name": "ຫ້ວຍຍາງ",
                "vill_name_en": "",
                "dr_id": "1602"
              },
              {
                "vill_id": "1602031",
                "vill_name": "ບ້ານງີ້ວ",
                "vill_name_en": "",
                "dr_id": "1602"
              },
              {
                "vill_id": "1602032",
                "vill_name": "ແກ້ງກອກ",
                "vill_name_en": "",
                "dr_id": "1602"
              },
              {
                "vill_id": "1602033",
                "vill_name": "ບ ໂສໂລ",
                "vill_name_en": "",
                "dr_id": "1602"
              },
              {
                "vill_id": "1602034",
                "vill_name": "ບ ໂສໂລນອ້ຍ",
                "vill_name_en": "",
                "dr_id": "1602"
              },
              {
                "vill_id": "1602035",
                "vill_name": "ຈຳປີ",
                "vill_name_en": "",
                "dr_id": "1602"
              },
              {
                "vill_id": "1602036",
                "vill_name": "ພູທອງລຸ່ມ",
                "vill_name_en": "",
                "dr_id": "1602"
              },
              {
                "vill_id": "1602037",
                "vill_name": "ບ ເດື່ອ",
                "vill_name_en": "",
                "dr_id": "1602"
              },
              {
                "vill_id": "1602038",
                "vill_name": "ນາຫຼົ້ງ",
                "vill_name_en": "",
                "dr_id": "1602"
              },
              {
                "vill_id": "1602039",
                "vill_name": "ງົວແດງ",
                "vill_name_en": "",
                "dr_id": "1602"
              },
              {
                "vill_id": "1602040",
                "vill_name": "ນາຊອນ",
                "vill_name_en": "",
                "dr_id": "1602"
              },
              {
                "vill_id": "1602041",
                "vill_name": "ປາກຊອນ",
                "vill_name_en": "",
                "dr_id": "1602"
              },
              {
                "vill_id": "1602042",
                "vill_name": "ໂຢງ",
                "vill_name_en": "",
                "dr_id": "1602"
              },
              {
                "vill_id": "1602043",
                "vill_name": "ສະຜ່າຍ",
                "vill_name_en": "",
                "dr_id": "1602"
              },
              {
                "vill_id": "1602044",
                "vill_name": "ທາງແບ່ງສີວິໄລ",
                "vill_name_en": "",
                "dr_id": "1602"
              },
              {
                "vill_id": "1602045",
                "vill_name": "ດອນໂຄ",
                "vill_name_en": "",
                "dr_id": "1602"
              },
              {
                "vill_id": "1602046",
                "vill_name": "ນາຂວ້າງ",
                "vill_name_en": "",
                "dr_id": "1602"
              },
              {
                "vill_id": "1602047",
                "vill_name": "ໜອງຫອຍ",
                "vill_name_en": "",
                "dr_id": "1602"
              }
            ]
          },
          {
            "dr_id": "1603",
            "dr_name": "ບາຈຽງຈະເລີນສຸກ",
            "dr_name_en": "Bachiangchaleunsook",
            "pr_id": "16",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "1603001",
                "vill_name": "ທົ່ງເພົາ",
                "vill_name_en": "",
                "dr_id": "1603"
              },
              {
                "vill_id": "1603002",
                "vill_name": "ກະຈີດ",
                "vill_name_en": "",
                "dr_id": "1603"
              },
              {
                "vill_id": "1603003",
                "vill_name": "ໝອງກຸ້ງ",
                "vill_name_en": "",
                "dr_id": "1603"
              },
              {
                "vill_id": "1603004",
                "vill_name": "ໜອງບົວ",
                "vill_name_en": "",
                "dr_id": "1603"
              },
              {
                "vill_id": "1603005",
                "vill_name": "ແກ້ງເກ່ຍ",
                "vill_name_en": "",
                "dr_id": "1603"
              },
              {
                "vill_id": "1603006",
                "vill_name": "ບ້ານເມີ້ງ",
                "vill_name_en": "",
                "dr_id": "1603"
              },
              {
                "vill_id": "1603007",
                "vill_name": "ໂນນອຸດົມ",
                "vill_name_en": "",
                "dr_id": "1603"
              },
              {
                "vill_id": "1603008",
                "vill_name": "ບ້ານຂັນລາຍ",
                "vill_name_en": "",
                "dr_id": "1603"
              },
              {
                "vill_id": "1603009",
                "vill_name": "ບັກແກງ",
                "vill_name_en": "",
                "dr_id": "1603"
              },
              {
                "vill_id": "1603010",
                "vill_name": "ລາດບົກ",
                "vill_name_en": "",
                "dr_id": "1603"
              },
              {
                "vill_id": "1603011",
                "vill_name": "ໂພນໃຜ່",
                "vill_name_en": "",
                "dr_id": "1603"
              },
              {
                "vill_id": "1603013",
                "vill_name": "ໂພນທອງ",
                "vill_name_en": "",
                "dr_id": "1603"
              },
              {
                "vill_id": "1603014",
                "vill_name": "ແກ້ງຍາວ",
                "vill_name_en": "",
                "dr_id": "1603"
              },
              {
                "vill_id": "1603015",
                "vill_name": "ໜອງບົກໃຫຍ່",
                "vill_name_en": "",
                "dr_id": "1603"
              },
              {
                "vill_id": "1603016",
                "vill_name": "ສອງຄອນ",
                "vill_name_en": "",
                "dr_id": "1603"
              },
              {
                "vill_id": "1603017",
                "vill_name": "ທົ່ງທິງ",
                "vill_name_en": "",
                "dr_id": "1603"
              },
              {
                "vill_id": "1603018",
                "vill_name": "ໝາກແງວ",
                "vill_name_en": "",
                "dr_id": "1603"
              },
              {
                "vill_id": "1603019",
                "vill_name": "ແກ້ງຕື້ນ",
                "vill_name_en": "",
                "dr_id": "1603"
              },
              {
                "vill_id": "1603020",
                "vill_name": "ໜອງກັ້ງ",
                "vill_name_en": "",
                "dr_id": "1603"
              },
              {
                "vill_id": "1603021",
                "vill_name": "ໜອງຫົວຄວາຍ",
                "vill_name_en": "",
                "dr_id": "1603"
              },
              {
                "vill_id": "1603022",
                "vill_name": "ໜອງຫົວຄວາຍ",
                "vill_name_en": "",
                "dr_id": "1603"
              },
              {
                "vill_id": "1603023",
                "vill_name": "ໜອງຫົວຄວາຍ",
                "vill_name_en": "",
                "dr_id": "1603"
              },
              {
                "vill_id": "1603024",
                "vill_name": "ໜອງກອກ",
                "vill_name_en": "",
                "dr_id": "1603"
              },
              {
                "vill_id": "1603025",
                "vill_name": "ໝອງໃສ",
                "vill_name_en": "",
                "dr_id": "1603"
              },
              {
                "vill_id": "1603026",
                "vill_name": "ໂນນສອນ",
                "vill_name_en": "",
                "dr_id": "1603"
              },
              {
                "vill_id": "1603027",
                "vill_name": "ພູທອງເທິງ",
                "vill_name_en": "",
                "dr_id": "1603"
              },
              {
                "vill_id": "1603028",
                "vill_name": "ນາແກ",
                "vill_name_en": "",
                "dr_id": "1603"
              },
              {
                "vill_id": "1603029",
                "vill_name": "ປາຫຼາຍ",
                "vill_name_en": "",
                "dr_id": "1603"
              },
              {
                "vill_id": "1603030",
                "vill_name": "ໂນນໃຊ",
                "vill_name_en": "",
                "dr_id": "1603"
              },
              {
                "vill_id": "1603031",
                "vill_name": "ບ້ານທີ 10",
                "vill_name_en": "",
                "dr_id": "1603"
              },
              {
                "vill_id": "1603032",
                "vill_name": "ບ້ານທີ4(ສາມັກຄີໄຊ )",
                "vill_name_en": "",
                "dr_id": "1603"
              },
              {
                "vill_id": "1603033",
                "vill_name": "ສາມັກຄີໄຊ",
                "vill_name_en": "",
                "dr_id": "1603"
              },
              {
                "vill_id": "1603034",
                "vill_name": "ທີ1(ຫຼັກ16 )ປາກຊ່ອງ",
                "vill_name_en": "",
                "dr_id": "1603"
              },
              {
                "vill_id": "1603035",
                "vill_name": "ທີ1/16+ທີ່1ປາກຊອງ",
                "vill_name_en": "",
                "dr_id": "1603"
              },
              {
                "vill_id": "1603036",
                "vill_name": "ທົ່ງອຸດົມ",
                "vill_name_en": "",
                "dr_id": "1603"
              },
              {
                "vill_id": "1603037",
                "vill_name": "ຫ້ວຍພືນ",
                "vill_name_en": "",
                "dr_id": "1603"
              },
              {
                "vill_id": "1603038",
                "vill_name": "ອຸດົມສຸກ",
                "vill_name_en": "",
                "dr_id": "1603"
              },
              {
                "vill_id": "1603039",
                "vill_name": "ພີີນທອງ",
                "vill_name_en": "",
                "dr_id": "1603"
              },
              {
                "vill_id": "1603040",
                "vill_name": "ຫ້ວຍແຮ່(21)",
                "vill_name_en": "",
                "dr_id": "1603"
              },
              {
                "vill_id": "1603041",
                "vill_name": "ຫລັກ23",
                "vill_name_en": "",
                "dr_id": "1603"
              },
              {
                "vill_id": "1603042",
                "vill_name": "ຫລັກ 25( ຕາກີດ)",
                "vill_name_en": "",
                "dr_id": "1603"
              },
              {
                "vill_id": "1603043",
                "vill_name": "ຫລັກ 19(ຫ້ວຍເຕັນ)",
                "vill_name_en": "",
                "dr_id": "1603"
              },
              {
                "vill_id": "1603044",
                "vill_name": "ຫຼັກ 16 ( ສີຊຽງໃໝ່)",
                "vill_name_en": "",
                "dr_id": "1603"
              },
              {
                "vill_id": "1603045",
                "vill_name": "ໂພນໃຊ",
                "vill_name_en": "",
                "dr_id": "1603"
              },
              {
                "vill_id": "1603046",
                "vill_name": "ໝື່ນຫລວງ",
                "vill_name_en": "",
                "dr_id": "1603"
              },
              {
                "vill_id": "1603047",
                "vill_name": "ໜອງນ້ຳຂາວໃຫຍ່",
                "vill_name_en": "",
                "dr_id": "1603"
              },
              {
                "vill_id": "1603048",
                "vill_name": "ຫົວແຊ",
                "vill_name_en": "",
                "dr_id": "1603"
              },
              {
                "vill_id": "1603049",
                "vill_name": "ໃຫ່ຍຫົວແຊ",
                "vill_name_en": "",
                "dr_id": "1603"
              },
              {
                "vill_id": "1603050",
                "vill_name": "ລົ່ມສັກເໜືອ",
                "vill_name_en": "",
                "dr_id": "1603"
              },
              {
                "vill_id": "1603051",
                "vill_name": "ຫ້ວຍລືສີ",
                "vill_name_en": "",
                "dr_id": "1603"
              },
              {
                "vill_id": "1603052",
                "vill_name": "ລົົ່ມສັກໃຕ້",
                "vill_name_en": "",
                "dr_id": "1603"
              },
              {
                "vill_id": "1603053",
                "vill_name": "ປາກຫ້ວຍເດື່ອ",
                "vill_name_en": "",
                "dr_id": "1603"
              },
              {
                "vill_id": "1603054",
                "vill_name": "ໃຊສະຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "1603"
              }
            ]
          },
          {
            "dr_id": "1604",
            "dr_name": "ປາກຊ່ອງ",
            "dr_name_en": "Pakxong",
            "pr_id": "16",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "1604001",
                "vill_name": "ວັດຫຼວງ",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604002",
                "vill_name": "ປາກຊ່ອງ",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604003",
                "vill_name": "ພູ​ໂອຍ",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604004",
                "vill_name": "ພູໝາກກໍ່",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604005",
                "vill_name": "ໜອງຫຼວງ",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604006",
                "vill_name": "ໜອງ​ແລະ",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604007",
                "vill_name": "ໜອງກະລິ",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604008",
                "vill_name": "ພູດິນ​ແດງ",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604009",
                "vill_name": "ຫຼັກ 48",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604010",
                "vill_name": "ບັງລຽງ​",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604011",
                "vill_name": "ຫຼັກ 28",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604012",
                "vill_name": "ຫຼັກ 30",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604013",
                "vill_name": "ຫຼັກ 33",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604014",
                "vill_name": "ຫຼັກ 35",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604015",
                "vill_name": "ຫຼັກ 36",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604016",
                "vill_name": "ຫຼັກ 38",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604017",
                "vill_name": "ຫຼັກ 40",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604018",
                "vill_name": "ຫຼັກ 42",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604019",
                "vill_name": "ຫຼັກ 43",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604020",
                "vill_name": "ຫຼັກ 45",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604021",
                "vill_name": "ຫ້ວຍຈຽດ",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604022",
                "vill_name": "ຄົດນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604023",
                "vill_name": "ພູຫຼັງ​ແກ່ວ",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604024",
                "vill_name": "ຫ້ວຍຊັນ",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604025",
                "vill_name": "​ເຊຕະປຸງ",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604026",
                "vill_name": "​ໜອງຢາ​ເທິງ",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604027",
                "vill_name": "​ໜອງຢາ​ລຸ່ມ",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604028",
                "vill_name": "​ພູດ້າມ​ຂວັນ",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604029",
                "vill_name": "​ພູມອນ",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604030",
                "vill_name": "​​ແດນສະຫວາງ",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604031",
                "vill_name": "​​ໜອງສຳ​ພັນ",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604032",
                "vill_name": "ຫ້ວຍຫວາຍ (ກຸ່ມ 5)",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604033",
                "vill_name": "​ເສັດ​ຄົດ",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604034",
                "vill_name": "​ໜອງຫີນຂາວ",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604035",
                "vill_name": "​ກະ​ເພີ",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604036",
                "vill_name": "​ຜັກກູດ​ນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604037",
                "vill_name": "​ພູດິນ​ອ່ອນ",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604038",
                "vill_name": "​​ໃໝ່​ໄຊ​ສົມບູນ",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604039",
                "vill_name": "​​ໜອງກາ",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604040",
                "vill_name": "​​ໜອງບອນ",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604041",
                "vill_name": "​​ຫ້ວຍ​ເຕົ່າ",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604042",
                "vill_name": "​​ຄົດ​ໃຫຍ່",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604043",
                "vill_name": "​​ສົມສະໜຸກໃໝ່",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604044",
                "vill_name": "​​ຫ້ວຍ​ເສັດ",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604045",
                "vill_name": "​​ກະຕວດ",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604046",
                "vill_name": "​​ກອງຕູ່​ນ",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604047",
                "vill_name": "​​ໜອງສູງ",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604048",
                "vill_name": "​​ລີ່​ເຈືອງ",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604049",
                "vill_name": "ທົ່ງກະຕ່າຍ",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604050",
                "vill_name": "ຫ້ວຍ​ເຕີຍ",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604051",
                "vill_name": "ຫີນລັບ",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604052",
                "vill_name": "ຫ້ວຍຊອຍ ",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604053",
                "vill_name": "ທົງຫວ້າ",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604054",
                "vill_name": "​ເຊປຽນ",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604055",
                "vill_name": "​ຫົວຊ້າງ",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604056",
                "vill_name": "​ພະໜວນດົງ",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604057",
                "vill_name": "​ປາກບ້ອງ",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604058",
                "vill_name": "​​ໂນນຈັນ",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604059",
                "vill_name": "ທົ່ງກາຫຼົງ",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604060",
                "vill_name": "ຫຼັກ 11",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604061",
                "vill_name": "ຫຼັກ 12",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604062",
                "vill_name": "ໜອງ​ເຂືອງ​ໃຫຍ່",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604063",
                "vill_name": "ນ້ຳປົດ",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604064",
                "vill_name": "ຫຼັກ 15",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604065",
                "vill_name": "ຈັນສະຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604066",
                "vill_name": "ໜອງ​ເຂືອງ​ນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604067",
                "vill_name": "ໜອງກີ​ນ",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604068",
                "vill_name": "ຫ້ວຍສາດ",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604069",
                "vill_name": "ໜອງ​ເມັກ",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604070",
                "vill_name": "ນ້ຳຮຸ່ງ",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604071",
                "vill_name": "ໜອງຫີນ",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604072",
                "vill_name": "ໜອງ​ເທືອ​ມ",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604073",
                "vill_name": "ໜອງຕວງ",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604074",
                "vill_name": "ນ້ຳຕວດ",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604075",
                "vill_name": "ຫ້ວຍຫວາຍ ",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604076",
                "vill_name": "​ໄຮ່ທົ່ງ​ຫວາຍ",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604077",
                "vill_name": "​ຫ້ວຍກອງ",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604078",
                "vill_name": "​ນ້ຳຕາງ",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604079",
                "vill_name": "​ໜອງອີ​ໂອຍ",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604080",
                "vill_name": "​ອຸປະຊາ",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604081",
                "vill_name": "​ຕາ​ໂອດ",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604082",
                "vill_name": "​ຕາ​ເຢິກ​ເສືອ",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604083",
                "vill_name": "​ລາຊະ​ສິນ",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604084",
                "vill_name": "​ນ້ຳ​ໂກງ",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604085",
                "vill_name": "ຫ້ວຍຈອດ",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604086",
                "vill_name": "ຫ້ວຍຊອຍ ",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604087",
                "vill_name": "​ເຊນ້ຳ​ນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604088",
                "vill_name": "​ໜອງພະໜວນ",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604089",
                "vill_name": "​​ໂນນສູງ",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604090",
                "vill_name": "​​ສວນສະຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "1604"
              },
              {
                "vill_id": "1604091",
                "vill_name": "​​ອີ່ຕູ້",
                "vill_name_en": "",
                "dr_id": "1604"
              }
            ]
          },
          {
            "dr_id": "1605",
            "dr_name": "ປະທຸມພອນ",
            "dr_name_en": "Pathoumphone",
            "pr_id": "16",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "1605001",
                "vill_name": "​​ໜອງ​ແວງ",
                "vill_name_en": "",
                "dr_id": "1605"
              },
              {
                "vill_id": "1605002",
                "vill_name": "​​ນາສາ​ຍຄຳ",
                "vill_name_en": "",
                "dr_id": "1605"
              },
              {
                "vill_id": "1605003",
                "vill_name": "​​ເວີນ",
                "vill_name_en": "",
                "dr_id": "1605"
              },
              {
                "vill_id": "1605004",
                "vill_name": "ຜັກ​​ແພວ",
                "vill_name_en": "",
                "dr_id": "1605"
              },
              {
                "vill_id": "1605005",
                "vill_name": "ໝໍ້ພຸ",
                "vill_name_en": "",
                "dr_id": "1605"
              },
              {
                "vill_id": "1605006",
                "vill_name": "ບັງ​ລຽງ​ໃນ",
                "vill_name_en": "",
                "dr_id": "1605"
              },
              {
                "vill_id": "1605007",
                "vill_name": "ມ່ວງ",
                "vill_name_en": "",
                "dr_id": "1605"
              },
              {
                "vill_id": "1605008",
                "vill_name": "ນ້ຳ​ໃສ​ທ່າ",
                "vill_name_en": "",
                "dr_id": "1605"
              },
              {
                "vill_id": "1605009",
                "vill_name": "ປາກຫ້ວຍ​ບັງ​ລຽງ",
                "vill_name_en": "",
                "dr_id": "1605"
              },
              {
                "vill_id": "1605010",
                "vill_name": "ນ້ຳພາກ",
                "vill_name_en": "",
                "dr_id": "1605"
              },
              {
                "vill_id": "1605011",
                "vill_name": "ໜອງ​ປັກ​ແຮດ",
                "vill_name_en": "",
                "dr_id": "1605"
              },
              {
                "vill_id": "1605012",
                "vill_name": "ນ້ຳໃສເທິງ",
                "vill_name_en": "",
                "dr_id": "1605"
              },
              {
                "vill_id": "1605013",
                "vill_name": "ຫຼັກ 30",
                "vill_name_en": "",
                "dr_id": "1605"
              },
              {
                "vill_id": "1605014",
                "vill_name": "ຫຼັກ 34",
                "vill_name_en": "",
                "dr_id": "1605"
              },
              {
                "vill_id": "1605015",
                "vill_name": "​ເຜົ່າສຳ​ພັນ​",
                "vill_name_en": "",
                "dr_id": "1605"
              },
              {
                "vill_id": "1605016",
                "vill_name": "ຫົວດອນ​ແດງ",
                "vill_name_en": "",
                "dr_id": "1605"
              },
              {
                "vill_id": "1605017",
                "vill_name": "ສີມຸງຄຸນ",
                "vill_name_en": "",
                "dr_id": "1605"
              },
              {
                "vill_id": "1605018",
                "vill_name": "ບຸ່ງຄຳ",
                "vill_name_en": "",
                "dr_id": "1605"
              },
              {
                "vill_id": "1605019",
                "vill_name": "​ເປືອຍດ້າວ",
                "vill_name_en": "",
                "dr_id": "1605"
              },
              {
                "vill_id": "1605020",
                "vill_name": "​ສີຈັນ​ໂທ",
                "vill_name_en": "",
                "dr_id": "1605"
              },
              {
                "vill_id": "1605021",
                "vill_name": "​ຫຼັກ 40",
                "vill_name_en": "",
                "dr_id": "1605"
              },
              {
                "vill_id": "1605022",
                "vill_name": "​ຄອນນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1605"
              },
              {
                "vill_id": "1605023",
                "vill_name": "​ປາກຕວຍ",
                "vill_name_en": "",
                "dr_id": "1605"
              },
              {
                "vill_id": "1605024",
                "vill_name": "​ນາຄຳ",
                "vill_name_en": "",
                "dr_id": "1605"
              },
              {
                "vill_id": "1605025",
                "vill_name": "​ນາຂາມ",
                "vill_name_en": "",
                "dr_id": "1605"
              },
              {
                "vill_id": "1605026",
                "vill_name": "​ນາລານ",
                "vill_name_en": "",
                "dr_id": "1605"
              },
              {
                "vill_id": "1605027",
                "vill_name": "​ໜອງບຶງ",
                "vill_name_en": "",
                "dr_id": "1605"
              },
              {
                "vill_id": "1605028",
                "vill_name": "​ນາກອກ",
                "vill_name_en": "",
                "dr_id": "1605"
              },
              {
                "vill_id": "1605029",
                "vill_name": "​ທາງ​ແບ່ງ 48",
                "vill_name_en": "",
                "dr_id": "1605"
              },
              {
                "vill_id": "1605030",
                "vill_name": "​ໜອງ​ແຂ້",
                "vill_name_en": "",
                "dr_id": "1605"
              },
              {
                "vill_id": "1605031",
                "vill_name": "​ປະທຸມ​ພອນ",
                "vill_name_en": "",
                "dr_id": "1605"
              },
              {
                "vill_id": "1605032",
                "vill_name": "​​ໂຕະ​ໂມະ​ທ່າ",
                "vill_name_en": "",
                "dr_id": "1605"
              },
              {
                "vill_id": "1605033",
                "vill_name": "​​ທ່າຮູ້",
                "vill_name_en": "",
                "dr_id": "1605"
              },
              {
                "vill_id": "1605034",
                "vill_name": "​​​ແຊງ",
                "vill_name_en": "",
                "dr_id": "1605"
              },
              {
                "vill_id": "1605035",
                "vill_name": "ຫ້ວຍ​ໃຜ່",
                "vill_name_en": "",
                "dr_id": "1605"
              },
              {
                "vill_id": "1605036",
                "vill_name": "ບຸ່ນ​ໃຫຍ່",
                "vill_name_en": "",
                "dr_id": "1605"
              },
              {
                "vill_id": "1605037",
                "vill_name": "ພອງ​ເພາະ",
                "vill_name_en": "",
                "dr_id": "1605"
              },
              {
                "vill_id": "1605038",
                "vill_name": "ທ່າກວ້າງ",
                "vill_name_en": "",
                "dr_id": "1605"
              },
              {
                "vill_id": "1605039",
                "vill_name": "ປ່າອາວ",
                "vill_name_en": "",
                "dr_id": "1605"
              },
              {
                "vill_id": "1605040",
                "vill_name": "ຫ້ວຍຍາງ",
                "vill_name_en": "",
                "dr_id": "1605"
              },
              {
                "vill_id": "1605041",
                "vill_name": "ໜອງບົວ​ນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1605"
              },
              {
                "vill_id": "1605042",
                "vill_name": "ໜອງບົວ​ໃຫຍ່",
                "vill_name_en": "",
                "dr_id": "1605"
              },
              {
                "vill_id": "1605043",
                "vill_name": "ຫ້ວຍ​ແມ່​ສັງ",
                "vill_name_en": "",
                "dr_id": "1605"
              },
              {
                "vill_id": "1605044",
                "vill_name": "ສະ​ໂນ​ດ",
                "vill_name_en": "",
                "dr_id": "1605"
              },
              {
                "vill_id": "1605045",
                "vill_name": "ທົບສອກ",
                "vill_name_en": "",
                "dr_id": "1605"
              },
              {
                "vill_id": "1605046",
                "vill_name": "ຫ້ວຍ​ໂກະ",
                "vill_name_en": "",
                "dr_id": "1605"
              },
              {
                "vill_id": "1605047",
                "vill_name": "ກຽດ​ໂງ້ງ",
                "vill_name_en": "",
                "dr_id": "1605"
              },
              {
                "vill_id": "1605048",
                "vill_name": "ພົມມະລຶ",
                "vill_name_en": "",
                "dr_id": "1605"
              },
              {
                "vill_id": "1605049",
                "vill_name": "ນາທົ່ງ",
                "vill_name_en": "",
                "dr_id": "1605"
              },
              {
                "vill_id": "1605050",
                "vill_name": "ນ້ຳອ້ອມ",
                "vill_name_en": "",
                "dr_id": "1605"
              },
              {
                "vill_id": "1605051",
                "vill_name": "ທົົ່ງພະ",
                "vill_name_en": "",
                "dr_id": "1605"
              },
              {
                "vill_id": "1605052",
                "vill_name": "ນາບອນ",
                "vill_name_en": "",
                "dr_id": "1605"
              },
              {
                "vill_id": "1605053",
                "vill_name": "​ເຫຼົ່າຫຍ້າ",
                "vill_name_en": "",
                "dr_id": "1605"
              },
              {
                "vill_id": "1605054",
                "vill_name": "​ສົມສຸກ",
                "vill_name_en": "",
                "dr_id": "1605"
              },
              {
                "vill_id": "1605055",
                "vill_name": "​ໜອງ​ເອກ",
                "vill_name_en": "",
                "dr_id": "1605"
              },
              {
                "vill_id": "1605056",
                "vill_name": "​ຫ້ວຍຕ້ອນ",
                "vill_name_en": "",
                "dr_id": "1605"
              },
              {
                "vill_id": "1605057",
                "vill_name": "​ສະໝິງ",
                "vill_name_en": "",
                "dr_id": "1605"
              },
              {
                "vill_id": "1605058",
                "vill_name": "​ກະລະ",
                "vill_name_en": "",
                "dr_id": "1605"
              },
              {
                "vill_id": "1605059",
                "vill_name": "​ທົ່ງ​ໄຊ",
                "vill_name_en": "",
                "dr_id": "1605"
              },
              {
                "vill_id": "1605060",
                "vill_name": "​​ແກ​ແລ​ໃຫຍ່",
                "vill_name_en": "",
                "dr_id": "1605"
              },
              {
                "vill_id": "1605061",
                "vill_name": "​​​ຫວ້ຍໝາກ",
                "vill_name_en": "",
                "dr_id": "1605"
              },
              {
                "vill_id": "1605062",
                "vill_name": "ຄອນທູດ",
                "vill_name_en": "",
                "dr_id": "1605"
              },
              {
                "vill_id": "1605063",
                "vill_name": "ພະ​ໂພ",
                "vill_name_en": "",
                "dr_id": "1605"
              },
              {
                "vill_id": "1605064",
                "vill_name": "ຜັກຂະ",
                "vill_name_en": "",
                "dr_id": "1605"
              },
              {
                "vill_id": "1605065",
                "vill_name": "ໜອງມະ​ເອກ",
                "vill_name_en": "",
                "dr_id": "1605"
              },
              {
                "vill_id": "1605066",
                "vill_name": "ພະລາຍ",
                "vill_name_en": "",
                "dr_id": "1605"
              },
              {
                "vill_id": "1605067",
                "vill_name": "ຕະຫວາງ",
                "vill_name_en": "",
                "dr_id": "1605"
              },
              {
                "vill_id": "1605068",
                "vill_name": "ໜອງປີງ",
                "vill_name_en": "",
                "dr_id": "1605"
              },
              {
                "vill_id": "1605069",
                "vill_name": "ຕາອອງ",
                "vill_name_en": "",
                "dr_id": "1605"
              },
              {
                "vill_id": "1605070",
                "vill_name": "ສະຫວ່າງ",
                "vill_name_en": "Saovang",
                "dr_id": "1605"
              }
            ]
          },
          {
            "dr_id": "1606",
            "dr_name": "ໂພນທອງ",
            "dr_name_en": "Phonthong",
            "pr_id": "16",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "1606001",
                "vill_name": "​ໂນນສະຫວັນ",
                "vill_name_en": "",
                "dr_id": "1606"
              },
              {
                "vill_id": "1606002",
                "vill_name": "​ຫ້ວຍ​ໂດນ",
                "vill_name_en": "",
                "dr_id": "1606"
              },
              {
                "vill_id": "1606003",
                "vill_name": "​ດອນ​ໄຫລ່",
                "vill_name_en": "",
                "dr_id": "1606"
              },
              {
                "vill_id": "1606004",
                "vill_name": "​ໜອງບົວ",
                "vill_name_en": "",
                "dr_id": "1606"
              },
              {
                "vill_id": "1606005",
                "vill_name": "​ທ່າຫລວງ",
                "vill_name_en": "",
                "dr_id": "1606"
              },
              {
                "vill_id": "1606006",
                "vill_name": "​ຫໍພະ​ແກ້ວ",
                "vill_name_en": "",
                "dr_id": "1606"
              },
              {
                "vill_id": "1606007",
                "vill_name": "​​ໂພ​ໄຊ",
                "vill_name_en": "",
                "dr_id": "1606"
              },
              {
                "vill_id": "1606008",
                "vill_name": "ຫ້ວຍ​ເພັກ",
                "vill_name_en": "",
                "dr_id": "1606"
              },
              {
                "vill_id": "1606009",
                "vill_name": "ຄັນ​ແຍງ",
                "vill_name_en": "",
                "dr_id": "1606"
              },
              {
                "vill_id": "1606010",
                "vill_name": "ນາຫວ້າ",
                "vill_name_en": "",
                "dr_id": "1606"
              },
              {
                "vill_id": "1606011",
                "vill_name": "ສ່າ​ເຫຼົ້າ",
                "vill_name_en": "",
                "dr_id": "1606"
              },
              {
                "vill_id": "1606012",
                "vill_name": "ໜອງຫອຍ",
                "vill_name_en": "",
                "dr_id": "1606"
              },
              {
                "vill_id": "1606013",
                "vill_name": "ກ້ວຍ",
                "vill_name_en": "",
                "dr_id": "1606"
              },
              {
                "vill_id": "1606014",
                "vill_name": "ໜອງ​ແຕ້​ນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1606"
              },
              {
                "vill_id": "1606015",
                "vill_name": "​ໄຜ່ລ້ອມ",
                "vill_name_en": "",
                "dr_id": "1606"
              },
              {
                "vill_id": "1606016",
                "vill_name": "​​ໂພນ​ໃຫ່ຍ",
                "vill_name_en": "",
                "dr_id": "1606"
              },
              {
                "vill_id": "1606017",
                "vill_name": "​​ນາສະຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "1606"
              },
              {
                "vill_id": "1606018",
                "vill_name": "​​ໜອງ​ເມັກ",
                "vill_name_en": "",
                "dr_id": "1606"
              },
              {
                "vill_id": "1606019",
                "vill_name": "​​ນາ​ຈານ",
                "vill_name_en": "",
                "dr_id": "1606"
              },
              {
                "vill_id": "1606020",
                "vill_name": "​​ດອນຊາດ",
                "vill_name_en": "",
                "dr_id": "1606"
              },
              {
                "vill_id": "1606021",
                "vill_name": "​​ສອງຄອນ",
                "vill_name_en": "",
                "dr_id": "1606"
              },
              {
                "vill_id": "1606022",
                "vill_name": "​​​ໂນນພະ​ເຈົ້າ",
                "vill_name_en": "",
                "dr_id": "1606"
              },
              {
                "vill_id": "1606023",
                "vill_name": "​​​ໜອງຜື",
                "vill_name_en": "",
                "dr_id": "1606"
              },
              {
                "vill_id": "1606024",
                "vill_name": "​​​​ແກ້ງຍາວ",
                "vill_name_en": "",
                "dr_id": "1606"
              },
              {
                "vill_id": "1606025",
                "vill_name": "ບຸ່ງ​ແກ",
                "vill_name_en": "",
                "dr_id": "1606"
              },
              {
                "vill_id": "1606026",
                "vill_name": "ທ່າ​ໃຫ່ຍ",
                "vill_name_en": "",
                "dr_id": "1606"
              },
              {
                "vill_id": "1606027",
                "vill_name": "​ໂນນກຸງ",
                "vill_name_en": "",
                "dr_id": "1606"
              },
              {
                "vill_id": "1606028",
                "vill_name": "​​ໂນນສະອາດ",
                "vill_name_en": "",
                "dr_id": "1606"
              },
              {
                "vill_id": "1606029",
                "vill_name": "​​ອ່າງຄຳ",
                "vill_name_en": "",
                "dr_id": "1606"
              },
              {
                "vill_id": "1606030",
                "vill_name": "​​ສະ​ເໝາະລຽບ",
                "vill_name_en": "",
                "dr_id": "1606"
              },
              {
                "vill_id": "1606031",
                "vill_name": "​​ພະລິງ",
                "vill_name_en": "",
                "dr_id": "1606"
              },
              {
                "vill_id": "1606032",
                "vill_name": "​​ດອນສະ​ແບ​ງ",
                "vill_name_en": "",
                "dr_id": "1606"
              },
              {
                "vill_id": "1606033",
                "vill_name": "​​​ໂນນຫີນ",
                "vill_name_en": "",
                "dr_id": "1606"
              },
              {
                "vill_id": "1606034",
                "vill_name": "ອຸປະຫລາ​ດ",
                "vill_name_en": "",
                "dr_id": "1606"
              },
              {
                "vill_id": "1606035",
                "vill_name": "ຝັ່ງ​ແດງ",
                "vill_name_en": "",
                "dr_id": "1606"
              },
              {
                "vill_id": "1606036",
                "vill_name": "​ໃໝ່ສີວິ​ໄລ",
                "vill_name_en": "",
                "dr_id": "1606"
              },
              {
                "vill_id": "1606037",
                "vill_name": "​​ໂພນທອງ",
                "vill_name_en": "",
                "dr_id": "1606"
              },
              {
                "vill_id": "1606038",
                "vill_name": "​​​ແກ",
                "vill_name_en": "",
                "dr_id": "1606"
              },
              {
                "vill_id": "1606039",
                "vill_name": "​​​ກຸງ",
                "vill_name_en": "",
                "dr_id": "1606"
              },
              {
                "vill_id": "1606040",
                "vill_name": "​​​ຈິກ",
                "vill_name_en": "",
                "dr_id": "1606"
              },
              {
                "vill_id": "1606041",
                "vill_name": "​​​ໜອງ​ໄຮ​ໂຄກ",
                "vill_name_en": "",
                "dr_id": "1606"
              },
              {
                "vill_id": "1606042",
                "vill_name": "​​​ດູ່",
                "vill_name_en": "",
                "dr_id": "1606"
              },
              {
                "vill_id": "1606043",
                "vill_name": "​​​ບົກ",
                "vill_name_en": "",
                "dr_id": "1606"
              },
              {
                "vill_id": "1606044",
                "vill_name": "​ໂຄກກຸງ",
                "vill_name_en": "",
                "dr_id": "1606"
              },
              {
                "vill_id": "1606045",
                "vill_name": "​ດອນຈິກ",
                "vill_name_en": "",
                "dr_id": "1606"
              },
              {
                "vill_id": "1606046",
                "vill_name": "​​​ໃໝ່",
                "vill_name_en": "",
                "dr_id": "1606"
              },
              {
                "vill_id": "1606047",
                "vill_name": "​​​ໝໍ້",
                "vill_name_en": "",
                "dr_id": "1606"
              },
              {
                "vill_id": "1606048",
                "vill_name": "​​​ທົ່ງກ້ວາງ",
                "vill_name_en": "",
                "dr_id": "1606"
              },
              {
                "vill_id": "1606049",
                "vill_name": "​​​ນາອຸດົມ",
                "vill_name_en": "",
                "dr_id": "1606"
              },
              {
                "vill_id": "1606050",
                "vill_name": "ວັງ​ເຕົ່າ",
                "vill_name_en": "",
                "dr_id": "1606"
              },
              {
                "vill_id": "1606051",
                "vill_name": "ດອນໂຈດ",
                "vill_name_en": "",
                "dr_id": "1606"
              },
              {
                "vill_id": "1606052",
                "vill_name": "​ແກ້ງກາງ",
                "vill_name_en": "",
                "dr_id": "1606"
              },
              {
                "vill_id": "1606053",
                "vill_name": "ລາດວາລີ",
                "vill_name_en": "",
                "dr_id": "1606"
              },
              {
                "vill_id": "1606054",
                "vill_name": "ດົງຍາງ",
                "vill_name_en": "",
                "dr_id": "1606"
              },
              {
                "vill_id": "1606055",
                "vill_name": "ໜອງສາລາ",
                "vill_name_en": "",
                "dr_id": "1606"
              },
              {
                "vill_id": "1606056",
                "vill_name": "ໜອງ​ດົງ",
                "vill_name_en": "",
                "dr_id": "1606"
              },
              {
                "vill_id": "1606057",
                "vill_name": "ອຸສຸ",
                "vill_name_en": "",
                "dr_id": "1606"
              },
              {
                "vill_id": "1606058",
                "vill_name": "ຄັນຍາງ",
                "vill_name_en": "",
                "dr_id": "1606"
              },
              {
                "vill_id": "1606059",
                "vill_name": "ໜອງ​ໄຮ​ທ່າ",
                "vill_name_en": "",
                "dr_id": "1606"
              },
              {
                "vill_id": "1606060",
                "vill_name": "​ເວີນ​ໄຊ",
                "vill_name_en": "",
                "dr_id": "1606"
              },
              {
                "vill_id": "1606061",
                "vill_name": "ຂະໜາດ",
                "vill_name_en": "",
                "dr_id": "1606"
              },
              {
                "vill_id": "1606063",
                "vill_name": "ສະໝັນ",
                "vill_name_en": "",
                "dr_id": "1606"
              },
              {
                "vill_id": "1606064",
                "vill_name": "ຊົ້ງຊາຍ",
                "vill_name_en": "",
                "dr_id": "1606"
              },
              {
                "vill_id": "1606065",
                "vill_name": "​ໂນນຄູນ",
                "vill_name_en": "",
                "dr_id": "1606"
              },
              {
                "vill_id": "1606066",
                "vill_name": "​ກຸດນ້ຳ​ຂຸ້ນ",
                "vill_name_en": "",
                "dr_id": "1606"
              },
              {
                "vill_id": "1606067",
                "vill_name": "​​ໂຄກກ່ອງ",
                "vill_name_en": "",
                "dr_id": "1606"
              },
              {
                "vill_id": "1606068",
                "vill_name": "​​ກະຕິນ",
                "vill_name_en": "",
                "dr_id": "1606"
              },
              {
                "vill_id": "1606069",
                "vill_name": "ຂຸມ​ທອງ",
                "vill_name_en": "",
                "dr_id": "1606"
              },
              {
                "vill_id": "1606070",
                "vill_name": "ປ້ອງ",
                "vill_name_en": "",
                "dr_id": "1606"
              },
              {
                "vill_id": "1606071",
                "vill_name": "​ໂນນຊັບ",
                "vill_name_en": "",
                "dr_id": "1606"
              },
              {
                "vill_id": "1606072",
                "vill_name": "​ເກົ່າເກິງ",
                "vill_name_en": "",
                "dr_id": "1606"
              },
              {
                "vill_id": "1606073",
                "vill_name": "ຈິກ",
                "vill_name_en": "Jik",
                "dr_id": "1606"
              },
              {
                "vill_id": "1606074",
                "vill_name": "ເວີນໄຊ",
                "vill_name_en": "Veunsai",
                "dr_id": "1606"
              }
            ]
          },
          {
            "dr_id": "1607",
            "dr_name": "ຈຳປາສັກ",
            "dr_name_en": "Champasak",
            "pr_id": "16",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "1607001",
                "vill_name": "​ຫົວປາຂໍ້",
                "vill_name_en": "",
                "dr_id": "1607"
              },
              {
                "vill_id": "1607002",
                "vill_name": "​ຂອນແກ່ນ",
                "vill_name_en": "",
                "dr_id": "1607"
              },
              {
                "vill_id": "1607003",
                "vill_name": "​ຄັນແຍງ",
                "vill_name_en": "",
                "dr_id": "1607"
              },
              {
                "vill_id": "1607004",
                "vill_name": "​ວັດໄຊ",
                "vill_name_en": "",
                "dr_id": "1607"
              },
              {
                "vill_id": "1607005",
                "vill_name": "​ພັນທະຄາມ",
                "vill_name_en": "",
                "dr_id": "1607"
              },
              {
                "vill_id": "1607006",
                "vill_name": "ພະພິນ",
                "vill_name_en": "",
                "dr_id": "1607"
              },
              {
                "vill_id": "1607007",
                "vill_name": "ໂພໄຊ",
                "vill_name_en": "",
                "dr_id": "1607"
              },
              {
                "vill_id": "1607008",
                "vill_name": "ເມືອງແສນ",
                "vill_name_en": "",
                "dr_id": "1607"
              },
              {
                "vill_id": "1607009",
                "vill_name": "ວັດທົ່ງ",
                "vill_name_en": "",
                "dr_id": "1607"
              },
              {
                "vill_id": "1607010",
                "vill_name": "ວັດອຳມາດ",
                "vill_name_en": "",
                "dr_id": "1607"
              },
              {
                "vill_id": "1607011",
                "vill_name": "ວັດລະຄອນ",
                "vill_name_en": "",
                "dr_id": "1607"
              },
              {
                "vill_id": "1607012",
                "vill_name": "ໂພນແພງ",
                "vill_name_en": "",
                "dr_id": "1607"
              },
              {
                "vill_id": "1607013",
                "vill_name": "ບ້ານໄຮ່",
                "vill_name_en": "",
                "dr_id": "1607"
              },
              {
                "vill_id": "1607014",
                "vill_name": "ວັດທາດ",
                "vill_name_en": "",
                "dr_id": "1607"
              },
              {
                "vill_id": "1607015",
                "vill_name": "ວັດຫຼວງເກົ່າ",
                "vill_name_en": "",
                "dr_id": "1607"
              },
              {
                "vill_id": "1607016",
                "vill_name": "ພະນອນເໜືອ",
                "vill_name_en": "",
                "dr_id": "1607"
              },
              {
                "vill_id": "1607017",
                "vill_name": "ພະນອນໃຕ້",
                "vill_name_en": "",
                "dr_id": "1607"
              },
              {
                "vill_id": "1607018",
                "vill_name": "ເມືອງກາງ",
                "vill_name_en": "",
                "dr_id": "1607"
              },
              {
                "vill_id": "1607019",
                "vill_name": "ສີສະຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "1607"
              },
              {
                "vill_id": "1607020",
                "vill_name": "ກະຕຶບ",
                "vill_name_en": "",
                "dr_id": "1607"
              },
              {
                "vill_id": "1607021",
                "vill_name": "ທ່າເດື່ອ",
                "vill_name_en": "",
                "dr_id": "1607"
              },
              {
                "vill_id": "1607022",
                "vill_name": "ໜອງວຽນ",
                "vill_name_en": "",
                "dr_id": "1607"
              },
              {
                "vill_id": "1607023",
                "vill_name": "ທາງຄົບ",
                "vill_name_en": "",
                "dr_id": "1607"
              },
              {
                "vill_id": "1607024",
                "vill_name": "ໜອງສາ",
                "vill_name_en": "",
                "dr_id": "1607"
              },
              {
                "vill_id": "1607025",
                "vill_name": "ໜອງເດີ່ນ",
                "vill_name_en": "",
                "dr_id": "1607"
              },
              {
                "vill_id": "1607026",
                "vill_name": "ໜອງຫໍ",
                "vill_name_en": "",
                "dr_id": "1607"
              },
              {
                "vill_id": "1607027",
                "vill_name": "ຫ້ວຍສາຫົວ",
                "vill_name_en": "",
                "dr_id": "1607"
              },
              {
                "vill_id": "1607028",
                "vill_name": "ດອນທາງຄວາຍ",
                "vill_name_en": "",
                "dr_id": "1607"
              },
              {
                "vill_id": "1607029",
                "vill_name": "ໜອງນົກຂຽນ",
                "vill_name_en": "",
                "dr_id": "1607"
              },
              {
                "vill_id": "1607030",
                "vill_name": "ສ້າງແຄນ",
                "vill_name_en": "",
                "dr_id": "1607"
              },
              {
                "vill_id": "1607031",
                "vill_name": "ທາງແບ່ງ",
                "vill_name_en": "",
                "dr_id": "1607"
              },
              {
                "vill_id": "1607032",
                "vill_name": "ໂຄກກ່ອງ",
                "vill_name_en": "",
                "dr_id": "1607"
              },
              {
                "vill_id": "1607033",
                "vill_name": "ໜອງຖ່ອນ",
                "vill_name_en": "",
                "dr_id": "1607"
              },
              {
                "vill_id": "1607034",
                "vill_name": "ດອນຕະລາດ",
                "vill_name_en": "",
                "dr_id": "1607"
              },
              {
                "vill_id": "1607035",
                "vill_name": "ດອນກອງ",
                "vill_name_en": "",
                "dr_id": "1607"
              },
              {
                "vill_id": "1607036",
                "vill_name": "ນາສະຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "1607"
              },
              {
                "vill_id": "1607037",
                "vill_name": "ຫ້ວຍ",
                "vill_name_en": "",
                "dr_id": "1607"
              },
              {
                "vill_id": "1607038",
                "vill_name": "ໜອງຫີນ",
                "vill_name_en": "",
                "dr_id": "1607"
              },
              {
                "vill_id": "1607039",
                "vill_name": "ໜອງຂາມ",
                "vill_name_en": "",
                "dr_id": "1607"
              },
              {
                "vill_id": "1607040",
                "vill_name": "ໜອງສະໂນ",
                "vill_name_en": "",
                "dr_id": "1607"
              },
              {
                "vill_id": "1607041",
                "vill_name": "ທ່າແຕງເທິງ",
                "vill_name_en": "",
                "dr_id": "1607"
              },
              {
                "vill_id": "1607043",
                "vill_name": "ໜອງໝາກແກ້ວ",
                "vill_name_en": "",
                "dr_id": "1607"
              },
              {
                "vill_id": "1607044",
                "vill_name": "ຄຳຈະເລີນ",
                "vill_name_en": "",
                "dr_id": "1607"
              },
              {
                "vill_id": "1607045",
                "vill_name": "ໂນນທາຍ",
                "vill_name_en": "",
                "dr_id": "1607"
              },
              {
                "vill_id": "1607046",
                "vill_name": "ທາງໂງ",
                "vill_name_en": "",
                "dr_id": "1607"
              },
              {
                "vill_id": "1607047",
                "vill_name": "ໜອງບົວຂາວ",
                "vill_name_en": "",
                "dr_id": "1607"
              },
              {
                "vill_id": "1607048",
                "vill_name": "ທົມຈານ",
                "vill_name_en": "",
                "dr_id": "1607"
              },
              {
                "vill_id": "1607049",
                "vill_name": "ເປືອຍ",
                "vill_name_en": "",
                "dr_id": "1607"
              },
              {
                "vill_id": "1607050",
                "vill_name": "ຫລັກແບ່ນ",
                "vill_name_en": "",
                "dr_id": "1607"
              },
              {
                "vill_id": "1607051",
                "vill_name": "ໜອງບົວ",
                "vill_name_en": "",
                "dr_id": "1607"
              },
              {
                "vill_id": "1607052",
                "vill_name": "ເມີຍ",
                "vill_name_en": "",
                "dr_id": "1607"
              },
              {
                "vill_id": "1607053",
                "vill_name": "ດອນໂດ່",
                "vill_name_en": "",
                "dr_id": "1607"
              },
              {
                "vill_id": "1607054",
                "vill_name": "ໜອງຫໍ",
                "vill_name_en": "",
                "dr_id": "1607"
              },
              {
                "vill_id": "1607055",
                "vill_name": "ງົວບາ",
                "vill_name_en": "",
                "dr_id": "1607"
              },
              {
                "vill_id": "1607056",
                "vill_name": "ໜອງແຕ້",
                "vill_name_en": "",
                "dr_id": "1607"
              },
              {
                "vill_id": "1607057",
                "vill_name": "ຫ້ວຍນາ",
                "vill_name_en": "",
                "dr_id": "1607"
              },
              {
                "vill_id": "1607058",
                "vill_name": "ພົງໂພດ",
                "vill_name_en": "",
                "dr_id": "1607"
              },
              {
                "vill_id": "1607059",
                "vill_name": "ໂນນດິນດຳ",
                "vill_name_en": "",
                "dr_id": "1607"
              },
              {
                "vill_id": "1607060",
                "vill_name": "ໂນນດີ",
                "vill_name_en": "",
                "dr_id": "1607"
              },
              {
                "vill_id": "1607061",
                "vill_name": "ສີສຸລາດ",
                "vill_name_en": "",
                "dr_id": "1607"
              },
              {
                "vill_id": "1607062",
                "vill_name": "ທ່າແຕງທ່າ",
                "vill_name_en": "",
                "dr_id": "1607"
              },
              {
                "vill_id": "1607063",
                "vill_name": "ຫ້ວຍແຫ້",
                "vill_name_en": "",
                "dr_id": "1607"
              },
              {
                "vill_id": "1607064",
                "vill_name": "ສົ້ມໂຮງ",
                "vill_name_en": "",
                "dr_id": "1607"
              },
              {
                "vill_id": "1607065",
                "vill_name": "ທ່າຕານ",
                "vill_name_en": "",
                "dr_id": "1607"
              },
              {
                "vill_id": "1607066",
                "vill_name": "ໜອງຜຳ",
                "vill_name_en": "",
                "dr_id": "1607"
              },
              {
                "vill_id": "1607067",
                "vill_name": "ໃໝ່ທ່າ",
                "vill_name_en": "",
                "dr_id": "1607"
              },
              {
                "vill_id": "1607068",
                "vill_name": "ດອນຊຽງຫລ້າ",
                "vill_name_en": "",
                "dr_id": "1607"
              },
              {
                "vill_id": "1607069",
                "vill_name": "ໃໝ່ກາງ",
                "vill_name_en": "",
                "dr_id": "1607"
              },
              {
                "vill_id": "1607070",
                "vill_name": "ຫ້ວຍມັນປາ",
                "vill_name_en": "",
                "dr_id": "1607"
              },
              {
                "vill_id": "1607071",
                "vill_name": "ຫ້ວຍເງິນ",
                "vill_name_en": "",
                "dr_id": "1607"
              },
              {
                "vill_id": "1607072",
                "vill_name": "ນາດົງ",
                "vill_name_en": "",
                "dr_id": "1607"
              },
              {
                "vill_id": "1607073",
                "vill_name": "ດອນກອຍ",
                "vill_name_en": "",
                "dr_id": "1607"
              },
              {
                "vill_id": "1607074",
                "vill_name": "ດອນຮີ",
                "vill_name_en": "",
                "dr_id": "1607"
              },
              {
                "vill_id": "1607075",
                "vill_name": "ໂນນສະຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "1607"
              },
              {
                "vill_id": "1607076",
                "vill_name": "ນາຄວາຍ",
                "vill_name_en": "",
                "dr_id": "1607"
              }
            ]
          },
          {
            "dr_id": "1608",
            "dr_name": "ສຸຂຸມາ",
            "dr_name_en": "Sukhuma",
            "pr_id": "16",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "1608001",
                "vill_name": "ສຸຂຸມາ",
                "vill_name_en": "",
                "dr_id": "1608"
              },
              {
                "vill_id": "1608002",
                "vill_name": "ທັບຈາມ",
                "vill_name_en": "",
                "dr_id": "1608"
              },
              {
                "vill_id": "1608003",
                "vill_name": "ຂຸດຕະບູນ",
                "vill_name_en": "",
                "dr_id": "1608"
              },
              {
                "vill_id": "1608005",
                "vill_name": "ລາດ",
                "vill_name_en": "",
                "dr_id": "1608"
              },
              {
                "vill_id": "1608006",
                "vill_name": "ໂພນເຜີ້ງ",
                "vill_name_en": "",
                "dr_id": "1608"
              },
              {
                "vill_id": "1608007",
                "vill_name": "ບົກ",
                "vill_name_en": "",
                "dr_id": "1608"
              },
              {
                "vill_id": "1608008",
                "vill_name": "ມ່ວງ",
                "vill_name_en": "",
                "dr_id": "1608"
              },
              {
                "vill_id": "1608009",
                "vill_name": "ໂຄກສະຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "1608"
              },
              {
                "vill_id": "1608010",
                "vill_name": "ປາກໍ່",
                "vill_name_en": "",
                "dr_id": "1608"
              },
              {
                "vill_id": "1608011",
                "vill_name": "ໂພນ",
                "vill_name_en": "",
                "dr_id": "1608"
              },
              {
                "vill_id": "1608012",
                "vill_name": "ບາກ",
                "vill_name_en": "",
                "dr_id": "1608"
              },
              {
                "vill_id": "1608013",
                "vill_name": "ສາມຂາ",
                "vill_name_en": "",
                "dr_id": "1608"
              },
              {
                "vill_id": "1608014",
                "vill_name": "ໂຄກໜອງບົວ",
                "vill_name_en": "",
                "dr_id": "1608"
              },
              {
                "vill_id": "1608015",
                "vill_name": "ນາມະໂງະ",
                "vill_name_en": "",
                "dr_id": "1608"
              },
              {
                "vill_id": "1608016",
                "vill_name": "ຫ້ວຍແຫ່",
                "vill_name_en": "",
                "dr_id": "1608"
              },
              {
                "vill_id": "1608017",
                "vill_name": "ບຸ່ງແກ້ວ",
                "vill_name_en": "",
                "dr_id": "1608"
              },
              {
                "vill_id": "1608018",
                "vill_name": "ດົງຫົວບ້ານ",
                "vill_name_en": "",
                "dr_id": "1608"
              },
              {
                "vill_id": "1608019",
                "vill_name": "ທ່າແສງ",
                "vill_name_en": "",
                "dr_id": "1608"
              },
              {
                "vill_id": "1608020",
                "vill_name": "ທ່າໂພສີ",
                "vill_name_en": "",
                "dr_id": "1608"
              },
              {
                "vill_id": "1608021",
                "vill_name": "ປ່າກ້ວຍ",
                "vill_name_en": "",
                "dr_id": "1608"
              },
              {
                "vill_id": "1608022",
                "vill_name": "ກົງກວຽນ",
                "vill_name_en": "",
                "dr_id": "1608"
              },
              {
                "vill_id": "1608023",
                "vill_name": "ທ່າດ່ານ",
                "vill_name_en": "",
                "dr_id": "1608"
              },
              {
                "vill_id": "1608024",
                "vill_name": "ດົງຍາງ",
                "vill_name_en": "",
                "dr_id": "1608"
              },
              {
                "vill_id": "1608025",
                "vill_name": "ໃໝ່ວັງໝາກແຊວ",
                "vill_name_en": "",
                "dr_id": "1608"
              },
              {
                "vill_id": "1608026",
                "vill_name": "ອຸທຸມໃໝ່",
                "vill_name_en": "",
                "dr_id": "1608"
              },
              {
                "vill_id": "1608027",
                "vill_name": "ຫົວດອນໄຊ",
                "vill_name_en": "",
                "dr_id": "1608"
              },
              {
                "vill_id": "1608028",
                "vill_name": "ໝາກມີ້",
                "vill_name_en": "",
                "dr_id": "1608"
              },
              {
                "vill_id": "1608029",
                "vill_name": "ຫາດດອນໄຊ",
                "vill_name_en": "",
                "dr_id": "1608"
              },
              {
                "vill_id": "1608030",
                "vill_name": "ກອກ",
                "vill_name_en": "",
                "dr_id": "1608"
              },
              {
                "vill_id": "1608031",
                "vill_name": "ຕູມນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1608"
              },
              {
                "vill_id": "1608032",
                "vill_name": "ຕູມໃຫ່ຍ",
                "vill_name_en": "",
                "dr_id": "1608"
              },
              {
                "vill_id": "1608033",
                "vill_name": "ໂນນຍາງ",
                "vill_name_en": "",
                "dr_id": "1608"
              },
              {
                "vill_id": "1608034",
                "vill_name": "ຫາດທົ່ງ (ສາມປາງ)",
                "vill_name_en": "",
                "dr_id": "1608"
              },
              {
                "vill_id": "1608035",
                "vill_name": "ດອນກອງ",
                "vill_name_en": "",
                "dr_id": "1608"
              },
              {
                "vill_id": "1608036",
                "vill_name": "ດອນຄອກງົວ",
                "vill_name_en": "",
                "dr_id": "1608"
              },
              {
                "vill_id": "1608037",
                "vill_name": "ຍາງສາວ",
                "vill_name_en": "",
                "dr_id": "1608"
              },
              {
                "vill_id": "1608038",
                "vill_name": "ແສນເມືອງ",
                "vill_name_en": "",
                "dr_id": "1608"
              },
              {
                "vill_id": "1608039",
                "vill_name": "ໂນນແດງເໜືອ",
                "vill_name_en": "",
                "dr_id": "1608"
              },
              {
                "vill_id": "1608040",
                "vill_name": "ຫຽງ",
                "vill_name_en": "",
                "dr_id": "1608"
              },
              {
                "vill_id": "1608041",
                "vill_name": "ດອນຫວາຍ",
                "vill_name_en": "",
                "dr_id": "1608"
              },
              {
                "vill_id": "1608042",
                "vill_name": "ໜອງພັນວົງ",
                "vill_name_en": "",
                "dr_id": "1608"
              },
              {
                "vill_id": "1608043",
                "vill_name": "ຫ້ວຍເຜີ້ງ",
                "vill_name_en": "",
                "dr_id": "1608"
              },
              {
                "vill_id": "1608044",
                "vill_name": "ໂນນພະເຈົ້າ",
                "vill_name_en": "",
                "dr_id": "1608"
              },
              {
                "vill_id": "1608045",
                "vill_name": "ປາກຊ້າງ",
                "vill_name_en": "",
                "dr_id": "1608"
              },
              {
                "vill_id": "1608046",
                "vill_name": "ຜັກຕົບ",
                "vill_name_en": "",
                "dr_id": "1608"
              },
              {
                "vill_id": "1608047",
                "vill_name": "ສຳຫລຽງ",
                "vill_name_en": "",
                "dr_id": "1608"
              },
              {
                "vill_id": "1608048",
                "vill_name": "ນາຈານ",
                "vill_name_en": "",
                "dr_id": "1608"
              },
              {
                "vill_id": "1608049",
                "vill_name": "ແສນສຸກ",
                "vill_name_en": "",
                "dr_id": "1608"
              },
              {
                "vill_id": "1608050",
                "vill_name": "ໂຄກກ່ອງ",
                "vill_name_en": "",
                "dr_id": "1608"
              },
              {
                "vill_id": "1608051",
                "vill_name": "ແຊ",
                "vill_name_en": "",
                "dr_id": "1608"
              },
              {
                "vill_id": "1608052",
                "vill_name": "ໂນນຄຸ້ມ",
                "vill_name_en": "",
                "dr_id": "1608"
              },
              {
                "vill_id": "1608053",
                "vill_name": "ໂນນເອື້ອງ",
                "vill_name_en": "",
                "dr_id": "1608"
              },
              {
                "vill_id": "1608054",
                "vill_name": "ໂນນແດງໄຕ້",
                "vill_name_en": "",
                "dr_id": "1608"
              },
              {
                "vill_id": "1608055",
                "vill_name": "ຫ້ວຍໄຜ່",
                "vill_name_en": "",
                "dr_id": "1608"
              },
              {
                "vill_id": "1608056",
                "vill_name": "ໂນນໂພ",
                "vill_name_en": "",
                "dr_id": "1608"
              },
              {
                "vill_id": "1608057",
                "vill_name": "ທ່າຫລວງ",
                "vill_name_en": "",
                "dr_id": "1608"
              }
            ]
          },
          {
            "dr_id": "1609",
            "dr_name": "ມຸນລະປະໂມກ",
            "dr_name_en": "Moonlapamok",
            "pr_id": "16",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "1609001",
                "vill_name": "ບ້ານເວີນແຄນ",
                "vill_name_en": "",
                "dr_id": "1609"
              },
              {
                "vill_id": "1609002",
                "vill_name": "ບ້ານທ່າຄໍ້",
                "vill_name_en": "",
                "dr_id": "1609"
              },
              {
                "vill_id": "1609003",
                "vill_name": "ບ້ານທ່ານ້ອງ",
                "vill_name_en": "",
                "dr_id": "1609"
              },
              {
                "vill_id": "1609004",
                "vill_name": "ບ້ານທ່າແຮ່",
                "vill_name_en": "",
                "dr_id": "1609"
              },
              {
                "vill_id": "1609005",
                "vill_name": "ບ້ານເວີນ",
                "vill_name_en": "",
                "dr_id": "1609"
              },
              {
                "vill_id": "1609006",
                "vill_name": "ບ້ານ​ໃໝ່",
                "vill_name_en": "",
                "dr_id": "1609"
              },
              {
                "vill_id": "1609007",
                "vill_name": "ບ້ານດອນ​ເປືອຍ",
                "vill_name_en": "",
                "dr_id": "1609"
              },
              {
                "vill_id": "1609010",
                "vill_name": "ປາ​ໂມກ",
                "vill_name_en": "",
                "dr_id": "1609"
              },
              {
                "vill_id": "1609011",
                "vill_name": "​ເດື່ອ​ເຕັ້ຍ",
                "vill_name_en": "",
                "dr_id": "1609"
              },
              {
                "vill_id": "1609012",
                "vill_name": "ສະພັງ​ເໜືອ",
                "vill_name_en": "",
                "dr_id": "1609"
              },
              {
                "vill_id": "1609013",
                "vill_name": "ບ້ານ​ ສະພັງ",
                "vill_name_en": "",
                "dr_id": "1609"
              },
              {
                "vill_id": "1609014",
                "vill_name": "ນາ​ຂາມ",
                "vill_name_en": "",
                "dr_id": "1609"
              },
              {
                "vill_id": "1609015",
                "vill_name": "ບ້ານ​ ຫ້ວຍຍາງ",
                "vill_name_en": "",
                "dr_id": "1609"
              },
              {
                "vill_id": "1609016",
                "vill_name": "ຫ້ວຍ​ແຮດ",
                "vill_name_en": "",
                "dr_id": "1609"
              },
              {
                "vill_id": "1609017",
                "vill_name": "​ຫ້ວ​ຍ​ໂກ",
                "vill_name_en": "",
                "dr_id": "1609"
              },
              {
                "vill_id": "1609018",
                "vill_name": "ນາ​ດີ",
                "vill_name_en": "",
                "dr_id": "1609"
              },
              {
                "vill_id": "1609019",
                "vill_name": "ຊານຫວ້າ",
                "vill_name_en": "",
                "dr_id": "1609"
              },
              {
                "vill_id": "1609021",
                "vill_name": "ບ້ານ​ ເວີນ​ຍາງ",
                "vill_name_en": "",
                "dr_id": "1609"
              },
              {
                "vill_id": "1609022",
                "vill_name": "​ໂພນ​ດູ່",
                "vill_name_en": "",
                "dr_id": "1609"
              },
              {
                "vill_id": "1609023",
                "vill_name": "ທ່າ​ກັງ",
                "vill_name_en": "",
                "dr_id": "1609"
              },
              {
                "vill_id": "1609024",
                "vill_name": "ກ້ານ​ເຫຼືອງ",
                "vill_name_en": "",
                "dr_id": "1609"
              },
              {
                "vill_id": "1609025",
                "vill_name": "ຫີນລາດ",
                "vill_name_en": "",
                "dr_id": "1609"
              },
              {
                "vill_id": "1609026",
                "vill_name": "ທ່າ​ຫີນ",
                "vill_name_en": "",
                "dr_id": "1609"
              },
              {
                "vill_id": "1609027",
                "vill_name": "ກະດຽນ",
                "vill_name_en": "",
                "dr_id": "1609"
              },
              {
                "vill_id": "1609028",
                "vill_name": "ກ້ວຍ",
                "vill_name_en": "",
                "dr_id": "1609"
              },
              {
                "vill_id": "1609029",
                "vill_name": "ຫ້ວຍ​ຊາຍ",
                "vill_name_en": "",
                "dr_id": "1609"
              },
              {
                "vill_id": "1609030",
                "vill_name": "ໜອງງາ",
                "vill_name_en": "",
                "dr_id": "1609"
              },
              {
                "vill_id": "1609031",
                "vill_name": "ບ້ານ​ ເປ​ວ",
                "vill_name_en": "",
                "dr_id": "1609"
              },
              {
                "vill_id": "1609032",
                "vill_name": "ປໍ",
                "vill_name_en": "",
                "dr_id": "1609"
              },
              {
                "vill_id": "1609033",
                "vill_name": "​ເຂັມ",
                "vill_name_en": "",
                "dr_id": "1609"
              },
              {
                "vill_id": "1609034",
                "vill_name": "ໜອງບົວທອງ",
                "vill_name_en": "",
                "dr_id": "1609"
              },
              {
                "vill_id": "1609035",
                "vill_name": "ກະດັນ​ກາງ",
                "vill_name_en": "",
                "dr_id": "1609"
              },
              {
                "vill_id": "1609036",
                "vill_name": "ສົ້ມ​ໂຮງ",
                "vill_name_en": "",
                "dr_id": "1609"
              },
              {
                "vill_id": "1609037",
                "vill_name": "ລວງໂຊ້",
                "vill_name_en": "louangso",
                "dr_id": "1609"
              },
              {
                "vill_id": "1609038",
                "vill_name": "ບ້ານໃໝ່",
                "vill_name_en": "Mai",
                "dr_id": "1609"
              }
            ]
          },
          {
            "dr_id": "1610",
            "dr_name": "ໂຂງ",
            "dr_name_en": "Khong",
            "pr_id": "16",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "1610001",
                "vill_name": "ນາຝັ່ງ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610002",
                "vill_name": "ດອນຂະເໝົາ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610003",
                "vill_name": "ສົງເປືອຍ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610004",
                "vill_name": "ແກ້ງ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610005",
                "vill_name": "ບຸ່ນນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610006",
                "vill_name": "ຫໍ່ເຂົ້າ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610007",
                "vill_name": "ທ່າໂພເໜືອ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610008",
                "vill_name": "ຈານ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610009",
                "vill_name": "ກົກປາແດກ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610010",
                "vill_name": "ເຕົ້າປຸງ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610011",
                "vill_name": "ລົມພັດ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610012",
                "vill_name": "ຫ້ວຍໄຮ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610013",
                "vill_name": "ໂພນສະອາດ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610014",
                "vill_name": "ນາແວງ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610015",
                "vill_name": "ນາລານ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610016",
                "vill_name": "ສົງເປືອຍ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610017",
                "vill_name": "ຫ້ວຍໃຜ່",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610018",
                "vill_name": "ບຸ່ງ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610019",
                "vill_name": "ສາມຄ້າງ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610020",
                "vill_name": "ດອນຫວດ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610021",
                "vill_name": "ນາສົ້ມໂຮງ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610022",
                "vill_name": "ນາເຊືອກ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610023",
                "vill_name": "ນາແສນພັນ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610024",
                "vill_name": "ໂພນວິໄຊ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610025",
                "vill_name": "ຫາດຊາຍຄູນ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610026",
                "vill_name": "ເວີນຂາວ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610027",
                "vill_name": "ຫາດຫົວເບັງ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610028",
                "vill_name": "ໂພນ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610029",
                "vill_name": "ກະດັນ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610030",
                "vill_name": "ດອນແຄ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610031",
                "vill_name": "ຂີ້ນາກ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610032",
                "vill_name": "ໂພນສະຫັວນ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610033",
                "vill_name": "ຊົນລະວຽງ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610034",
                "vill_name": "ນາສົງເປືອຍ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610035",
                "vill_name": "ໂສດ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610036",
                "vill_name": "ນາປາກຽບ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610037",
                "vill_name": "ເສັດນ້ຳອ້ອມ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610038",
                "vill_name": "ພຽງດີ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610039",
                "vill_name": "ນາກະສັງ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610040",
                "vill_name": "ທ່າມ່ວງ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610041",
                "vill_name": "ແສນໂຮມ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610042",
                "vill_name": "ເສັດເຕົາເຫຼັກ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610043",
                "vill_name": "ເສັດສະຫວັນ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610044",
                "vill_name": "ທ່າຄໍ້",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610045",
                "vill_name": "ບຶງງາມ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610046",
                "vill_name": "ດອນພະເພັງ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610047",
                "vill_name": "ດອນຕານ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610048",
                "vill_name": "ດອນແອນ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610049",
                "vill_name": "ດອນຊົມ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610050",
                "vill_name": "ດອນສະໂຮງ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610051",
                "vill_name": "ຫົວສະດຳ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610052",
                "vill_name": "ຫາງສະດຳ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610053",
                "vill_name": "ຫາງຄອນ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610054",
                "vill_name": "ຄອນ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610055",
                "vill_name": "ດອນເດດ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610056",
                "vill_name": "ທ່າໂພໃຕ້",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610057",
                "vill_name": "ທ່າພ້າວ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610058",
                "vill_name": "ແກ້ງກຸ່ມ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610059",
                "vill_name": "ສົມເວັນອອກ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610060",
                "vill_name": "ເວີນສົມ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610061",
                "vill_name": "ທ່າໝາກເຫັບ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610062",
                "vill_name": "ດອນນາງຄວັດ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610063",
                "vill_name": "ດອນຊ້າງ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610064",
                "vill_name": "ທໍລະທີ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610065",
                "vill_name": "ດອນເປືອຍ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610066",
                "vill_name": "ຫົວລົບປາກດີ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610067",
                "vill_name": "ລົບປາດີກາງ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610068",
                "vill_name": "ລົບປາກດີຈົກ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610069",
                "vill_name": "ຄອນນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610070",
                "vill_name": "ໂພຕາກ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610071",
                "vill_name": "ດອນໂຈນ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610072",
                "vill_name": "ດອນສົ້ມໂຮງ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610073",
                "vill_name": "ຫົວໂລງ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610074",
                "vill_name": "ໂລງຊົ້ງ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610075",
                "vill_name": "ໂລງກາງ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610076",
                "vill_name": "ຫາງໂລງ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610077",
                "vill_name": "ດອນຖານ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610078",
                "vill_name": "ຫາງຮີນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610079",
                "vill_name": "ດອນກະເດັນ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610080",
                "vill_name": "ດອນລຽງ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610081",
                "vill_name": "ຫ້ວຍ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610082",
                "vill_name": "ຫົວສ້າງໄພ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610083",
                "vill_name": "ເດື່ອ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610084",
                "vill_name": "ມ່ວງ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610085",
                "vill_name": "ສາລາ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610086",
                "vill_name": "ພິມານໂພນ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610087",
                "vill_name": "ຫີນສີ່ວ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610088",
                "vill_name": "ຫົວພິມານ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610089",
                "vill_name": "ເມືອງແສນ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610090",
                "vill_name": "ແສນຮາດນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610091",
                "vill_name": "ແສນຮາດໃຫຍ່",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610092",
                "vill_name": "ດອນເຫຼັກໄຟ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610093",
                "vill_name": "ໂຂງແຫຼມ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610094",
                "vill_name": "ຫົວໂຂງ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610095",
                "vill_name": "ໂຂງຫາດງີ້ວ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610096",
                "vill_name": "ດອນສັນ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610097",
                "vill_name": "ຫາງສັນ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610098",
                "vill_name": "ກາງໂຂງ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610099",
                "vill_name": "ນາ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610100",
                "vill_name": "ເວີນທອງ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610101",
                "vill_name": "ດົງ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610102",
                "vill_name": "ແສນລ່າມ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610103",
                "vill_name": "ດອນຈອມ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610104",
                "vill_name": "ໜອງເຫັ້ຍ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610105",
                "vill_name": "ໃໝ່ສີວິໄລ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610106",
                "vill_name": "ດອນແຮດ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610108",
                "vill_name": "ດອນແຮດ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610109",
                "vill_name": "ຫົວຮີເວີນ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610110",
                "vill_name": "ຮີທາດ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610111",
                "vill_name": "ດອນກອຍ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610113",
                "vill_name": "ໂພນຈຳປາ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610114",
                "vill_name": "ຂະເໝົາພູແມວ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610115",
                "vill_name": "ຂະເໝົາປາກເຊ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610116",
                "vill_name": "ດອນເຄົາ",
                "vill_name_en": "",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610117",
                "vill_name": "ດອນໂລງ",
                "vill_name_en": "Donlong",
                "dr_id": "1610"
              },
              {
                "vill_id": "1610118",
                "vill_name": "ສີວິໄລ",
                "vill_name_en": "sivilai",
                "dr_id": "1610"
              }
            ]
          }
        ]
      },
      {
        "pr_id": "17",
        "pr_name": "ອັດຕະປື",
        "pr_name_en": "Attapu",
        "districts": [
          {"dr_name": "ເລືອກເມືອງປະຈຸບັນ", "villages": []},
          {
            "dr_id": "1701",
            "dr_name": "ໄຊເສດຖາ",
            "dr_name_en": "Xaysettha",
            "pr_id": "17",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "1701001",
                "vill_name": "ໄຊສີ",
                "vill_name_en": "",
                "dr_id": "1701"
              },
              {
                "vill_id": "1701002",
                "vill_name": "ຄັນມະກ່ອງ",
                "vill_name_en": "",
                "dr_id": "1701"
              },
              {
                "vill_id": "1701003",
                "vill_name": "ພັ່ງແດງ",
                "vill_name_en": "",
                "dr_id": "1701"
              },
              {
                "vill_id": "1701004",
                "vill_name": "ໂພໄຊ",
                "vill_name_en": "",
                "dr_id": "1701"
              },
              {
                "vill_id": "1701005",
                "vill_name": " ຊ້າຍ",
                "vill_name_en": "",
                "dr_id": "1701"
              },
              {
                "vill_id": "1701006",
                "vill_name": "ບ້ານຕວຍ",
                "vill_name_en": "",
                "dr_id": "1701"
              },
              {
                "vill_id": "1701007",
                "vill_name": "ແກ້ງໃຫ່ຍ",
                "vill_name_en": "",
                "dr_id": "1701"
              },
              {
                "vill_id": "1701008",
                "vill_name": "ວັດເໜືອ",
                "vill_name_en": "",
                "dr_id": "1701"
              },
              {
                "vill_id": "1701009",
                "vill_name": "ວັດທາດ",
                "vill_name_en": "",
                "dr_id": "1701"
              },
              {
                "vill_id": "1701010",
                "vill_name": "ບ້ານວັດຫຼວງ",
                "vill_name_en": "",
                "dr_id": "1701"
              },
              {
                "vill_id": "1701011",
                "vill_name": "ສົມໂຄດ",
                "vill_name_en": "",
                "dr_id": "1701"
              },
              {
                "vill_id": "1701012",
                "vill_name": "ດອນສິມ",
                "vill_name_en": "",
                "dr_id": "1701"
              },
              {
                "vill_id": "1701013",
                "vill_name": " ພອກ",
                "vill_name_en": "",
                "dr_id": "1701"
              },
              {
                "vill_id": "1701014",
                "vill_name": "ທ່າລານ",
                "vill_name_en": "",
                "dr_id": "1701"
              },
              {
                "vill_id": "1701015",
                "vill_name": " ຫາດສະຕີ",
                "vill_name_en": "",
                "dr_id": "1701"
              },
              {
                "vill_id": "1701016",
                "vill_name": "ແກ້ງໄຊ",
                "vill_name_en": "",
                "dr_id": "1701"
              },
              {
                "vill_id": "1701017",
                "vill_name": " ສະປວນ",
                "vill_name_en": "",
                "dr_id": "1701"
              },
              {
                "vill_id": "1701018",
                "vill_name": " ນ້ຳຫຽງ",
                "vill_name_en": "",
                "dr_id": "1701"
              },
              {
                "vill_id": "1701019",
                "vill_name": " ນ້ຳຫຽງ",
                "vill_name_en": "",
                "dr_id": "1701"
              },
              {
                "vill_id": "1701020",
                "vill_name": " ສະແຄະ",
                "vill_name_en": "",
                "dr_id": "1701"
              },
              {
                "vill_id": "1701021",
                "vill_name": "  ດາກຢຽງ",
                "vill_name_en": "",
                "dr_id": "1701"
              },
              {
                "vill_id": "1701022",
                "vill_name": "ໄໜ່ອຸດົມ",
                "vill_name_en": "",
                "dr_id": "1701"
              },
              {
                "vill_id": "1701023",
                "vill_name": "ຍາວເໜືອເກົ່າ",
                "vill_name_en": "",
                "dr_id": "1701"
              },
              {
                "vill_id": "1701024",
                "vill_name": "ຂຸມຄຳ",
                "vill_name_en": "",
                "dr_id": "1701"
              }
            ]
          },
          {
            "dr_id": "1702",
            "dr_name": "ສາມະຄີໄຊ",
            "dr_name_en": "Samakkixay",
            "pr_id": "17",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "1702001",
                "vill_name": "  ເມືອງ​ໃຫ່​ມ",
                "vill_name_en": "",
                "dr_id": "1702"
              },
              {
                "vill_id": "1702003",
                "vill_name": " ເ​ຊ​ໃຫ່​ຍ",
                "vill_name_en": "",
                "dr_id": "1702"
              },
              {
                "vill_id": "1702004",
                "vill_name": " ສາ​ມັກ​ຄີ",
                "vill_name_en": "",
                "dr_id": "1702"
              },
              {
                "vill_id": "1702005",
                "vill_name": "ສີ​ວິ​ໄລ",
                "vill_name_en": "",
                "dr_id": "1702"
              },
              {
                "vill_id": "1702006",
                "vill_name": "ເຊ​ກະ​ຫມານ​ເຫນືອ",
                "vill_name_en": "",
                "dr_id": "1702"
              },
              {
                "vill_id": "1702007",
                "vill_name": " ຫລັກ 3",
                "vill_name_en": "",
                "dr_id": "1702"
              },
              {
                "vill_id": "1702008",
                "vill_name": " ສະ​ເພົາ",
                "vill_name_en": "",
                "dr_id": "1702"
              },
              {
                "vill_id": "1702009",
                "vill_name": " ທ່າ​ຫີນ",
                "vill_name_en": "",
                "dr_id": "1702"
              },
              {
                "vill_id": "1702010",
                "vill_name": "ກອງ​ຮັງ",
                "vill_name_en": "",
                "dr_id": "1702"
              },
              {
                "vill_id": "1702011",
                "vill_name": " ກະ​ຍື",
                "vill_name_en": "",
                "dr_id": "1702"
              },
              {
                "vill_id": "1702012",
                "vill_name": " ລະຍາວເຫນືອໃຫມ່",
                "vill_name_en": "",
                "dr_id": "1702"
              },
              {
                "vill_id": "1702013",
                "vill_name": "  ລະ​ຍາວ​ເໜືອເກົ່າ",
                "vill_name_en": "",
                "dr_id": "1702"
              },
              {
                "vill_id": "1702014",
                "vill_name": "   ລະ​ຍາວ​ກາງ",
                "vill_name_en": "",
                "dr_id": "1702"
              },
              {
                "vill_id": "1702015",
                "vill_name": "   ລະ​ຍາວ​ກາງ",
                "vill_name_en": "",
                "dr_id": "1702"
              },
              {
                "vill_id": "1702016",
                "vill_name": " ລະ​ຍາວ​ໄຕ້ ",
                "vill_name_en": "",
                "dr_id": "1702"
              },
              {
                "vill_id": "1702017",
                "vill_name": " ຈຳ​ປາວ",
                "vill_name_en": "",
                "dr_id": "1702"
              },
              {
                "vill_id": "1702018",
                "vill_name": " ແຄມ​ຊັງ",
                "vill_name_en": "",
                "dr_id": "1702"
              },
              {
                "vill_id": "1702019",
                "vill_name": " ຕຳມະເລີຍ",
                "vill_name_en": "",
                "dr_id": "1702"
              },
              {
                "vill_id": "1702020",
                "vill_name": " ຮົມ",
                "vill_name_en": "",
                "dr_id": "1702"
              },
              {
                "vill_id": "1702021",
                "vill_name": "ວັດ​ຫລວງ",
                "vill_name_en": "",
                "dr_id": "1702"
              },
              {
                "vill_id": "1702022",
                "vill_name": " ໂພນ​ສະ​ຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "1702"
              },
              {
                "vill_id": "1702023",
                "vill_name": "ຕານຊຸມ",
                "vill_name_en": "",
                "dr_id": "1702"
              },
              {
                "vill_id": "1702024",
                "vill_name": "ໄຊ​ສະ​ອາດ",
                "vill_name_en": "",
                "dr_id": "1702"
              },
              {
                "vill_id": "1702025",
                "vill_name": " ໄຊສົມບູນ",
                "vill_name_en": "",
                "dr_id": "1702"
              },
              {
                "vill_id": "1702026",
                "vill_name": "ເວີນ​ແຄນ",
                "vill_name_en": "",
                "dr_id": "1702"
              },
              {
                "vill_id": "1702027",
                "vill_name": "ນາໄຊທອງ",
                "vill_name_en": "",
                "dr_id": "1702"
              },
              {
                "vill_id": "1702028",
                "vill_name": "ດອນຕອມ",
                "vill_name_en": "",
                "dr_id": "1702"
              },
              {
                "vill_id": "1702029",
                "vill_name": "ຫ້ວຍແກ້ວ",
                "vill_name_en": "",
                "dr_id": "1702"
              },
              {
                "vill_id": "1702030",
                "vill_name": "ໄຊ​ໂພ​ສີ",
                "vill_name_en": "",
                "dr_id": "1702"
              },
              {
                "vill_id": "1702031",
                "vill_name": " ຮະ​ລາງໃຫຍ່​",
                "vill_name_en": "",
                "dr_id": "1702"
              },
              {
                "vill_id": "1702032",
                "vill_name": "ກະ​ໂສມ",
                "vill_name_en": "",
                "dr_id": "1702"
              },
              {
                "vill_id": "1702033",
                "vill_name": "ສອກ",
                "vill_name_en": "",
                "dr_id": "1702"
              },
              {
                "vill_id": "1702034",
                "vill_name": "ໂສກຄຳ",
                "vill_name_en": "",
                "dr_id": "1702"
              },
              {
                "vill_id": "1702035",
                "vill_name": "ມີໄຊ",
                "vill_name_en": "",
                "dr_id": "1702"
              },
              {
                "vill_id": "1702036",
                "vill_name": " ແບ່ງພູຄຳ",
                "vill_name_en": "",
                "dr_id": "1702"
              },
              {
                "vill_id": "1702037",
                "vill_name": " ຫມື່ນ​ຫົວ​ເມືອງ",
                "vill_name_en": "",
                "dr_id": "1702"
              },
              {
                "vill_id": "1702038",
                "vill_name": "ໂພນງາມ",
                "vill_name_en": "",
                "dr_id": "1702"
              },
              {
                "vill_id": "1702039",
                "vill_name": "ແກ້ງຫມາກເຂືອ",
                "vill_name_en": "",
                "dr_id": "1702"
              },
              {
                "vill_id": "1702040",
                "vill_name": "ເຊກອງ",
                "vill_name_en": "Sekong",
                "dr_id": "1702"
              }
            ]
          },
          {
            "dr_id": "1703",
            "dr_name": "ສະໜາມໄຊ",
            "dr_name_en": "Sanamxay",
            "pr_id": "17",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "1703001",
                "vill_name": "ຄັງ",
                "vill_name_en": "",
                "dr_id": "1703"
              },
              {
                "vill_id": "1703002",
                "vill_name": "ປຸຍ",
                "vill_name_en": "",
                "dr_id": "1703"
              },
              {
                "vill_id": "1703003",
                "vill_name": "ປຸຍ",
                "vill_name_en": "",
                "dr_id": "1703"
              },
              {
                "vill_id": "1703004",
                "vill_name": "ໂຈມໂພຍ",
                "vill_name_en": "",
                "dr_id": "1703"
              },
              {
                "vill_id": "1703005",
                "vill_name": "ສົມສຸກ",
                "vill_name_en": "",
                "dr_id": "1703"
              },
              {
                "vill_id": "1703006",
                "vill_name": "ດອນເສືອ",
                "vill_name_en": "",
                "dr_id": "1703"
              },
              {
                "vill_id": "1703007",
                "vill_name": "ດອນເສືອ",
                "vill_name_en": "",
                "dr_id": "1703"
              },
              {
                "vill_id": "1703008",
                "vill_name": "ດອນໄຜ່",
                "vill_name_en": "",
                "dr_id": "1703"
              },
              {
                "vill_id": "1703009",
                "vill_name": "ດອນສູງ",
                "vill_name_en": "",
                "dr_id": "1703"
              },
              {
                "vill_id": "1703010",
                "vill_name": "ຫາດຊາຍຄຳ",
                "vill_name_en": "",
                "dr_id": "1703"
              },
              {
                "vill_id": "1703011",
                "vill_name": "ອຸດົມສຸກ",
                "vill_name_en": "",
                "dr_id": "1703"
              },
              {
                "vill_id": "1703012",
                "vill_name": "ຫາດພິລາ",
                "vill_name_en": "",
                "dr_id": "1703"
              },
              {
                "vill_id": "1703013",
                "vill_name": "ມິດສຳພັນ",
                "vill_name_en": "",
                "dr_id": "1703"
              },
              {
                "vill_id": "1703014",
                "vill_name": "ຫາດຍາວ",
                "vill_name_en": "",
                "dr_id": "1703"
              },
              {
                "vill_id": "1703015",
                "vill_name": "ອຸດົມໄຊ",
                "vill_name_en": "",
                "dr_id": "1703"
              },
              {
                "vill_id": "1703016",
                "vill_name": "ຫີນສົມບັດ",
                "vill_name_en": "",
                "dr_id": "1703"
              },
              {
                "vill_id": "1703017",
                "vill_name": "ນ້ຳກົງບ້ານ",
                "vill_name_en": "",
                "dr_id": "1703"
              },
              {
                "vill_id": "1703018",
                "vill_name": "ຫາດຊາຍສູງ",
                "vill_name_en": "",
                "dr_id": "1703"
              },
              {
                "vill_id": "1703019",
                "vill_name": "ຄັນໝາກນາວ",
                "vill_name_en": "",
                "dr_id": "1703"
              },
              {
                "vill_id": "1703020",
                "vill_name": "ບຸ່ງແກ້ວ",
                "vill_name_en": "",
                "dr_id": "1703"
              },
              {
                "vill_id": "1703021",
                "vill_name": "ຕະເງົາ",
                "vill_name_en": "",
                "dr_id": "1703"
              },
              {
                "vill_id": "1703022",
                "vill_name": "ສົ້ມປ່ອຍ",
                "vill_name_en": "",
                "dr_id": "1703"
              },
              {
                "vill_id": "1703023",
                "vill_name": "ຫາດອຸດົມໄຊ",
                "vill_name_en": "",
                "dr_id": "1703"
              },
              {
                "vill_id": "1703024",
                "vill_name": "ທ່າອວນ",
                "vill_name_en": "",
                "dr_id": "1703"
              },
              {
                "vill_id": "1703025",
                "vill_name": "ແທ",
                "vill_name_en": "",
                "dr_id": "1703"
              },
              {
                "vill_id": "1703026",
                "vill_name": "ດອນມ່ວງ",
                "vill_name_en": "",
                "dr_id": "1703"
              },
              {
                "vill_id": "1703027",
                "vill_name": "ຕະປາກ",
                "vill_name_en": "",
                "dr_id": "1703"
              },
              {
                "vill_id": "1703028",
                "vill_name": "ໂຄກກ່ອງ",
                "vill_name_en": "",
                "dr_id": "1703"
              },
              {
                "vill_id": "1703029",
                "vill_name": "ໄໝ່",
                "vill_name_en": "",
                "dr_id": "1703"
              },
              {
                "vill_id": "1703030",
                "vill_name": "ທ່າຫີນໄຕ້",
                "vill_name_en": "",
                "dr_id": "1703"
              },
              {
                "vill_id": "1703031",
                "vill_name": "ຕະມໍຢອດ",
                "vill_name_en": "",
                "dr_id": "1703"
              },
              {
                "vill_id": "1703032",
                "vill_name": "ຫີນລາດ",
                "vill_name_en": "",
                "dr_id": "1703"
              },
              {
                "vill_id": "1703033",
                "vill_name": "ສະໝອງໄຕ້",
                "vill_name_en": "",
                "dr_id": "1703"
              },
              {
                "vill_id": "1703034",
                "vill_name": "ປີນດົງ",
                "vill_name_en": "",
                "dr_id": "1703"
              },
              {
                "vill_id": "1703035",
                "vill_name": "ກະແຊະ",
                "vill_name_en": "",
                "dr_id": "1703"
              },
              {
                "vill_id": "1703036",
                "vill_name": "ຈັນຕໍ",
                "vill_name_en": "",
                "dr_id": "1703"
              },
              {
                "vill_id": "1703037",
                "vill_name": "ແບ່ງວິໄລ",
                "vill_name_en": "",
                "dr_id": "1703"
              },
              {
                "vill_id": "1703038",
                "vill_name": "ພອນມະນີ",
                "vill_name_en": "",
                "dr_id": "1703"
              },
              {
                "vill_id": "1703039",
                "vill_name": "ໂພນສະອາດ",
                "vill_name_en": "",
                "dr_id": "1703"
              },
              {
                "vill_id": "1703040",
                "vill_name": "ສີວິໄລ(ໜອງແຂ້)",
                "vill_name_en": "",
                "dr_id": "1703"
              },
              {
                "vill_id": "1703041",
                "vill_name": "ປາກບໍ່",
                "vill_name_en": "",
                "dr_id": "1703"
              },
              {
                "vill_id": "1703042",
                "vill_name": "ດອນ",
                "vill_name_en": "",
                "dr_id": "1703"
              },
              {
                "vill_id": "1703043",
                "vill_name": "ໜອງມ່ວງ",
                "vill_name_en": "",
                "dr_id": "1703"
              },
              {
                "vill_id": "1703044",
                "vill_name": "ມັດກາ",
                "vill_name_en": "",
                "dr_id": "1703"
              },
              {
                "vill_id": "1703045",
                "vill_name": "ໜອງຫີນ",
                "vill_name_en": "",
                "dr_id": "1703"
              },
              {
                "vill_id": "1703046",
                "vill_name": "ໄຊດອນໂຂງ",
                "vill_name_en": "",
                "dr_id": "1703"
              },
              {
                "vill_id": "1703047",
                "vill_name": "ທ່າແສງຈັນ",
                "vill_name_en": "",
                "dr_id": "1703"
              }
            ]
          },
          {
            "dr_id": "1704",
            "dr_name": "ສານໄຊ",
            "dr_name_en": "Sanxay",
            "pr_id": "17",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "1704001",
                "vill_name": "ຈະ​ເລີນ​ໄຊ",
                "vill_name_en": "",
                "dr_id": "1704"
              },
              {
                "vill_id": "1704002",
                "vill_name": "ດາກ​ກຽດ",
                "vill_name_en": "",
                "dr_id": "1704"
              },
              {
                "vill_id": "1704003",
                "vill_name": "ດາກ​ກະ​ນາດ",
                "vill_name_en": "",
                "dr_id": "1704"
              },
              {
                "vill_id": "1704004",
                "vill_name": "ດາກ​ຮຽດ",
                "vill_name_en": "",
                "dr_id": "1704"
              },
              {
                "vill_id": "1704005",
                "vill_name": "ຫີນ​ດຳ",
                "vill_name_en": "",
                "dr_id": "1704"
              },
              {
                "vill_id": "1704006",
                "vill_name": "ດອນ​ແຄນ",
                "vill_name_en": "",
                "dr_id": "1704"
              },
              {
                "vill_id": "1704007",
                "vill_name": "ຢຽງ​ດາກ",
                "vill_name_en": "",
                "dr_id": "1704"
              },
              {
                "vill_id": "1704008",
                "vill_name": "ກະ​ຈູງ",
                "vill_name_en": "",
                "dr_id": "1704"
              },
              {
                "vill_id": "1704009",
                "vill_name": "ດາກ​ບ໊ອກ",
                "vill_name_en": "",
                "dr_id": "1704"
              },
              {
                "vill_id": "1704010",
                "vill_name": "ຫນອງ​ຟ້າ",
                "vill_name_en": "",
                "dr_id": "1704"
              },
              {
                "vill_id": "1704011",
                "vill_name": "ວັງ​ຕັດ​ໄຫ່​ຍ",
                "vill_name_en": "",
                "dr_id": "1704"
              },
              {
                "vill_id": "1704012",
                "vill_name": "ວັັງ​ຕັດ​ນ້ອຍ",
                "vill_name_en": "",
                "dr_id": "1704"
              },
              {
                "vill_id": "1704013",
                "vill_name": "ມີ​ໄຊ",
                "vill_name_en": "",
                "dr_id": "1704"
              },
              {
                "vill_id": "1704014",
                "vill_name": "ຕາດ​ແສງ",
                "vill_name_en": "",
                "dr_id": "1704"
              },
              {
                "vill_id": "1704015",
                "vill_name": "ມູນ",
                "vill_name_en": "",
                "dr_id": "1704"
              },
              {
                "vill_id": "1704016",
                "vill_name": "ໄຫ່ມຖາ​ວອນ",
                "vill_name_en": "",
                "dr_id": "1704"
              },
              {
                "vill_id": "1704017",
                "vill_name": "ດາກ​ເສັງນ",
                "vill_name_en": "",
                "dr_id": "1704"
              },
              {
                "vill_id": "1704018",
                "vill_name": "ດາກ​ປະ​ດຸ",
                "vill_name_en": "",
                "dr_id": "1704"
              },
              {
                "vill_id": "1704019",
                "vill_name": "ດາກ​ຊາວ",
                "vill_name_en": "",
                "dr_id": "1704"
              },
              {
                "vill_id": "1704020",
                "vill_name": "ສຸກ​ສະ​ຫວ່າງ",
                "vill_name_en": "",
                "dr_id": "1704"
              },
              {
                "vill_id": "1704021",
                "vill_name": "ດາກ​ບູ",
                "vill_name_en": "",
                "dr_id": "1704"
              },
              {
                "vill_id": "1704022",
                "vill_name": "ດາກ​ດາວ",
                "vill_name_en": "",
                "dr_id": "1704"
              },
              {
                "vill_id": "1704023",
                "vill_name": "ດາກ​ເບນ",
                "vill_name_en": "",
                "dr_id": "1704"
              },
              {
                "vill_id": "1704024",
                "vill_name": "ວັງໄຊ",
                "vill_name_en": "",
                "dr_id": "1704"
              },
              {
                "vill_id": "1704025",
                "vill_name": "ກອງ​ນາ​",
                "vill_name_en": "",
                "dr_id": "1704"
              },
              {
                "vill_id": "1704026",
                "vill_name": "ດອນ​ຈັນ",
                "vill_name_en": "",
                "dr_id": "1704"
              },
              {
                "vill_id": "1704027",
                "vill_name": "ດາກ​ແຢະ ",
                "vill_name_en": "",
                "dr_id": "1704"
              },
              {
                "vill_id": "1704028",
                "vill_name": "ດາກ​ລະ​ ",
                "vill_name_en": "",
                "dr_id": "1704"
              },
              {
                "vill_id": "1704029",
                "vill_name": "ດາກ​ລະ​ເກົ່າ​ ",
                "vill_name_en": "",
                "dr_id": "1704"
              },
              {
                "vill_id": "1704030",
                "vill_name": "ດາກ​ລຽມ",
                "vill_name_en": "",
                "dr_id": "1704"
              },
              {
                "vill_id": "1704031",
                "vill_name": "ດາກ​ເກີ",
                "vill_name_en": "",
                "dr_id": "1704"
              },
              {
                "vill_id": "1704032",
                "vill_name": "ດາກ​ບັງ",
                "vill_name_en": "",
                "dr_id": "1704"
              },
              {
                "vill_id": "1704033",
                "vill_name": "ດາກນອງ",
                "vill_name_en": "",
                "dr_id": "1704"
              },
              {
                "vill_id": "1704034",
                "vill_name": "ດາກ​ນົງ",
                "vill_name_en": "",
                "dr_id": "1704"
              },
              {
                "vill_id": "1704035",
                "vill_name": "ດາກ​ປອກ​ໄຫ່​ມ",
                "vill_name_en": "",
                "dr_id": "1704"
              },
              {
                "vill_id": "1704036",
                "vill_name": "ດາກ​ດໍ",
                "vill_name_en": "",
                "dr_id": "1704"
              },
              {
                "vill_id": "1704037",
                "vill_name": "ດາກ​ຍົກ",
                "vill_name_en": "",
                "dr_id": "1704"
              },
              {
                "vill_id": "1704038",
                "vill_name": "ດາກ​ສຽດ",
                "vill_name_en": "",
                "dr_id": "1704"
              },
              {
                "vill_id": "1704039",
                "vill_name": "ດາກ​ຊືມ",
                "vill_name_en": "",
                "dr_id": "1704"
              },
              {
                "vill_id": "1704040",
                "vill_name": "ດາກ​ສະ​ມໍ",
                "vill_name_en": "",
                "dr_id": "1704"
              },
              {
                "vill_id": "1704041",
                "vill_name": "ສົມ​ບູນ",
                "vill_name_en": "",
                "dr_id": "1704"
              },
              {
                "vill_id": "1704042",
                "vill_name": "ໃຫ່ມນາກອກ",
                "vill_name_en": "",
                "dr_id": "1704"
              },
              {
                "vill_id": "1704043",
                "vill_name": "ຕາດ​ກຸ່ມ",
                "vill_name_en": "",
                "dr_id": "1704"
              },
              {
                "vill_id": "1704044",
                "vill_name": "ເພຍ​ແກ້ວ",
                "vill_name_en": "",
                "dr_id": "1704"
              },
              {
                "vill_id": "1704045",
                "vill_name": "ພູໄຊ",
                "vill_name_en": "",
                "dr_id": "1704"
              },
              {
                "vill_id": "1704046",
                "vill_name": "ໜອງໄກ່ໂອກ",
                "vill_name_en": "",
                "dr_id": "1704"
              }
            ]
          },
          {
            "dr_id": "1705",
            "dr_name": "ພູວົງ",
            "dr_name_en": "Phouvong",
            "pr_id": "17",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "1705001",
                "vill_name": "ວົງ​ສຳ​ພັນ",
                "vill_name_en": "",
                "dr_id": "1705"
              },
              {
                "vill_id": "1705002",
                "vill_name": "ນ້ຳ​ຊວນ",
                "vill_name_en": "",
                "dr_id": "1705"
              },
              {
                "vill_id": "1705003",
                "vill_name": "ພູເກືອ",
                "vill_name_en": "",
                "dr_id": "1705"
              },
              {
                "vill_id": "1705004",
                "vill_name": "ຕະອູມ",
                "vill_name_en": "",
                "dr_id": "1705"
              },
              {
                "vill_id": "1705005",
                "vill_name": "ລະມອງ",
                "vill_name_en": "",
                "dr_id": "1705"
              },
              {
                "vill_id": "1705006",
                "vill_name": "ວົງ​ລະ​ຄອນ",
                "vill_name_en": "",
                "dr_id": "1705"
              },
              {
                "vill_id": "1705007",
                "vill_name": "ນ້ຳກົງ(ວົງໄຊ)",
                "vill_name_en": "",
                "dr_id": "1705"
              },
              {
                "vill_id": "1705008",
                "vill_name": "ວຽງ​ໄຊ",
                "vill_name_en": "",
                "dr_id": "1705"
              },
              {
                "vill_id": "1705009",
                "vill_name": "ວັງຍາງ",
                "vill_name_en": "",
                "dr_id": "1705"
              },
              {
                "vill_id": "1705010",
                "vill_name": "ວົງວິໄລໃຕ້",
                "vill_name_en": "",
                "dr_id": "1705"
              },
              {
                "vill_id": "1705011",
                "vill_name": "ວົງວິໄລເຫນືອ",
                "vill_name_en": "",
                "dr_id": "1705"
              },
              {
                "vill_id": "1705012",
                "vill_name": "ພູ​ໂຮມ",
                "vill_name_en": "",
                "dr_id": "1705"
              },
              {
                "vill_id": "1705013",
                "vill_name": "ພູໄຊ",
                "vill_name_en": "",
                "dr_id": "1705"
              },
              {
                "vill_id": "1705014",
                "vill_name": "ພູ​ຍາງ",
                "vill_name_en": "",
                "dr_id": "1705"
              },
              {
                "vill_id": "1705015",
                "vill_name": "ວັງແຄນ",
                "vill_name_en": "",
                "dr_id": "1705"
              },
              {
                "vill_id": "1705016",
                "vill_name": "ຄຳວົງສາ",
                "vill_name_en": "",
                "dr_id": "1705"
              },
              {
                "vill_id": "1705017",
                "vill_name": "ສົມບູນ",
                "vill_name_en": "",
                "dr_id": "1705"
              },
              {
                "vill_id": "1705018",
                "vill_name": "ອີ່ຕຸ້ມ",
                "vill_name_en": "Etoum",
                "dr_id": "1705"
              },
              {
                "vill_id": "1705019",
                "vill_name": "ໝາກກ້ຽງ",
                "vill_name_en": "Markkieng",
                "dr_id": "1705"
              }
            ]
          }
        ]
      },
      {
        "pr_id": "18",
        "pr_name": "ໄຊສົມບູນ",
        "pr_name_en": "Sisomboun",
        "districts": [
          {"dr_name": "ເລືອກເມືອງປະຈຸບັນ", "villages": []},
          {
            "dr_id": "1801",
            "dr_name": "ອານຸວົງ",
            "dr_name_en": "Anouvong",
            "pr_id": "18",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "180122",
                "vill_name": "ຂີ້ຊ້າງ",
                "vill_name_en": "kHEESSNG",
                "dr_id": "1801"
              },
              {
                "vill_id": "1801001",
                "vill_name": "ພູຫົວຊ້າງ",
                "vill_name_en": "PHOUHEUSANG",
                "dr_id": "1801"
              },
              {
                "vill_id": "1801002",
                "vill_name": "ຜາສະຫົງບ",
                "vill_name_en": "PHASANGOUP",
                "dr_id": "1801"
              },
              {
                "vill_id": "1801003",
                "vill_name": "ທົ່ງຄູນ",
                "vill_name_en": "TOUNGKOUN",
                "dr_id": "1801"
              },
              {
                "vill_id": "1801004",
                "vill_name": "ທົ່ງຮັກ",
                "vill_name_en": "TOUNGTHUk",
                "dr_id": "1801"
              },
              {
                "vill_id": "1801005",
                "vill_name": "ກໍ່ໄຮ່",
                "vill_name_en": "KORHAI",
                "dr_id": "1801"
              },
              {
                "vill_id": "1801006",
                "vill_name": "ອອມ",
                "vill_name_en": "ORM",
                "dr_id": "1801"
              },
              {
                "vill_id": "1801007",
                "vill_name": "ຖໍ້າໂລ",
                "vill_name_en": "THUMLO",
                "dr_id": "1801"
              },
              {
                "vill_id": "1801008",
                "vill_name": "ອ່າວເໝຶອ",
                "vill_name_en": "AONEU",
                "dr_id": "1801"
              },
              {
                "vill_id": "1801009",
                "vill_name": "ອ່າວກາງ",
                "vill_name_en": "AOKANG",
                "dr_id": "1801"
              },
              {
                "vill_id": "1801010",
                "vill_name": "ອ່າວໄຕ້",
                "vill_name_en": "AOTAI",
                "dr_id": "1801"
              },
              {
                "vill_id": "1801011",
                "vill_name": "ນໍ້າຈັງ",
                "vill_name_en": "NUMJANG",
                "dr_id": "1801"
              },
              {
                "vill_id": "1801012",
                "vill_name": "ນໍ້າໂມ",
                "vill_name_en": "NUMMO",
                "dr_id": "1801"
              },
              {
                "vill_id": "1801013",
                "vill_name": "ນໍ້າຍອນ",
                "vill_name_en": "NUMYON",
                "dr_id": "1801"
              },
              {
                "vill_id": "1801014",
                "vill_name": "ຫຼັກ24",
                "vill_name_en": "LAK24",
                "dr_id": "1801"
              },
              {
                "vill_id": "1801015",
                "vill_name": "ຫຼັກ18",
                "vill_name_en": "LAK18",
                "dr_id": "1801"
              },
              {
                "vill_id": "1801016",
                "vill_name": "ຫີນຫົວເສຶອ",
                "vill_name_en": "HINHEUSEU",
                "dr_id": "1801"
              },
              {
                "vill_id": "1801017",
                "vill_name": "ໂພນພະ",
                "vill_name_en": "PHONEPHA",
                "dr_id": "1801"
              },
              {
                "vill_id": "1801018",
                "vill_name": "ມ່ວງສຸ່ມ",
                "vill_name_en": "MEUNGSOM",
                "dr_id": "1801"
              },
              {
                "vill_id": "1801019",
                "vill_name": "ໄຊຈະເຈີນ",
                "vill_name_en": "SAIJALERN",
                "dr_id": "1801"
              },
              {
                "vill_id": "1801020",
                "vill_name": "ນ້ຳເຈັ້ຍ",
                "vill_name_en": "Namjair",
                "dr_id": "1801"
              },
              {
                "vill_id": "1801021",
                "vill_name": "ນ້ຳຫຼ້າ",
                "vill_name_en": "Namla",
                "dr_id": "1801"
              },
              {
                "vill_id": "1801022",
                "vill_name": "ນາຕູ້",
                "vill_name_en": "Natou",
                "dr_id": "1801"
              },
              {
                "vill_id": "1801023",
                "vill_name": "ເຕ່ຍເຍຼ",
                "vill_name_en": "Tiabare",
                "dr_id": "1801"
              },
              {
                "vill_id": "1801024",
                "vill_name": "ນາຫຼວງ",
                "vill_name_en": "Naluang",
                "dr_id": "1801"
              }
            ]
          },
          {
            "dr_id": "1802",
            "dr_name": "ທ່າໂທມ",
            "dr_name_en": "Thathom",
            "pr_id": "18",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "1802001",
                "vill_name": "ສີບຸນເຮຶອງ",
                "vill_name_en": "SIBOUNHEUNG",
                "dr_id": "1802"
              },
              {
                "vill_id": "1802002",
                "vill_name": "ຊຽງຕັ້ງ",
                "vill_name_en": "SEANGTANG",
                "dr_id": "1802"
              },
              {
                "vill_id": "1802003",
                "vill_name": "ໂພນຈະເລີນ",
                "vill_name_en": "PHONEJALERN",
                "dr_id": "1802"
              },
              {
                "vill_id": "1802004",
                "vill_name": "ໂພນຮົ່ມ",
                "vill_name_en": "PHONEHEUM",
                "dr_id": "1802"
              },
              {
                "vill_id": "1802005",
                "vill_name": "ນໍ້າຝາງ",
                "vill_name_en": "NUMPHANG",
                "dr_id": "1802"
              },
              {
                "vill_id": "1802006",
                "vill_name": "ຄອນຊະນະ",
                "vill_name_en": "KONESANA",
                "dr_id": "1802"
              },
              {
                "vill_id": "1802007",
                "vill_name": "ຊຳກໍທອງ",
                "vill_name_en": "SAMKORTHONG",
                "dr_id": "1802"
              },
              {
                "vill_id": "1802008",
                "vill_name": "ແດນມີໄຊ",
                "vill_name_en": "DAANMISAI",
                "dr_id": "1802"
              },
              {
                "vill_id": "1802009",
                "vill_name": "ນາຫີນບູນ",
                "vill_name_en": "NAHINBOUN",
                "dr_id": "1802"
              },
              {
                "vill_id": "1802010",
                "vill_name": "ຊຽງຄົງ",
                "vill_name_en": "SANGKONG",
                "dr_id": "1802"
              },
              {
                "vill_id": "1802011",
                "vill_name": "ນາຊົງ",
                "vill_name_en": "NASONG",
                "dr_id": "1802"
              },
              {
                "vill_id": "1802012",
                "vill_name": "ທ່າວຽງໄຊ",
                "vill_name_en": "TAVEAGSAI",
                "dr_id": "1802"
              },
              {
                "vill_id": "1802013",
                "vill_name": "ໂພນໂຮມ",
                "vill_name_en": "PHONEHOME",
                "dr_id": "1802"
              },
              {
                "vill_id": "1802014",
                "vill_name": "ນາຫ້ອງ",
                "vill_name_en": "NAHONG",
                "dr_id": "1802"
              },
              {
                "vill_id": "1802015",
                "vill_name": "ຫາດສາຄອນ",
                "vill_name_en": "HARDSAKONE",
                "dr_id": "1802"
              },
              {
                "vill_id": "1802016",
                "vill_name": "ປູ້",
                "vill_name_en": "POU",
                "dr_id": "1802"
              },
              {
                "vill_id": "1802017",
                "vill_name": "ພຽງຕາ",
                "vill_name_en": "PHANGTA",
                "dr_id": "1802"
              },
              {
                "vill_id": "1802018",
                "vill_name": "ນໍ້າລ້ອງ",
                "vill_name_en": "NUMLONG",
                "dr_id": "1802"
              },
              {
                "vill_id": "1802019",
                "vill_name": "ວັງໄຮ່",
                "vill_name_en": "VANGHAI",
                "dr_id": "1802"
              },
              {
                "vill_id": "1802020",
                "vill_name": "ທິດນູນ",
                "vill_name_en": "THIDNOUN",
                "dr_id": "1802"
              },
              {
                "vill_id": "1802021",
                "vill_name": "ປາກຢອງ",
                "vill_name_en": "PARKYONG",
                "dr_id": "1802"
              },
              {
                "vill_id": "1802022",
                "vill_name": "ຍາມຈະເລີນ",
                "vill_name_en": "YAMJALERN",
                "dr_id": "1802"
              },
              {
                "vill_id": "1802023",
                "vill_name": "ວຽງສະຫວາດ",
                "vill_name_en": "VIENGSAVARD",
                "dr_id": "1802"
              },
              {
                "vill_id": "1802024",
                "vill_name": "ສັນພູໄຊ",
                "vill_name_en": "SANPHOUSAI",
                "dr_id": "1802"
              },
              {
                "vill_id": "1802025",
                "vill_name": "ໄຊຈະເລີນ",
                "vill_name_en": "SAIJALERN",
                "dr_id": "1802"
              },
              {
                "vill_id": "1802026",
                "vill_name": "ຫາດສາມຄອນ",
                "vill_name_en": "Hardsamkhone",
                "dr_id": "1802"
              },
              {
                "vill_id": "1802027",
                "vill_name": "ວຽງທອງ",
                "vill_name_en": "viengthon",
                "dr_id": "1802"
              }
            ]
          },
          {
            "dr_id": "1803",
            "dr_name": "ລ້ອງແຈ້ງ",
            "dr_name_en": "Longcheng",
            "pr_id": "18",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "1803001",
                "vill_name": "ລ້ອງແຈ້ງ",
                "vill_name_en": "LONGJANG",
                "dr_id": "1803"
              },
              {
                "vill_id": "1803002",
                "vill_name": "ຊຳທອງ",
                "vill_name_en": "SAMTHONG",
                "dr_id": "1803"
              },
              {
                "vill_id": "1803003",
                "vill_name": "ຫຼວງພັນໄຊ",
                "vill_name_en": "LEUNGPHANSAI",
                "dr_id": "1803"
              },
              {
                "vill_id": "1803004",
                "vill_name": "ພັນໄຊ",
                "vill_name_en": "PHANSAI",
                "dr_id": "1803"
              },
              {
                "vill_id": "1803005",
                "vill_name": "ນໍ້າຜາ",
                "vill_name_en": "NUMPHA",
                "dr_id": "1803"
              },
              {
                "vill_id": "1803006",
                "vill_name": "ນໍ້າຊານ",
                "vill_name_en": "NUMSAN",
                "dr_id": "1803"
              },
              {
                "vill_id": "1803007",
                "vill_name": "ນາໄຊສະຫວ່າງ",
                "vill_name_en": "NASAISAVANG",
                "dr_id": "1803"
              },
              {
                "vill_id": "1803008",
                "vill_name": "ນໍ້າຊາວ",
                "vill_name_en": "Numsao",
                "dr_id": "1803"
              },
              {
                "vill_id": "1803009",
                "vill_name": "ນາຕີ",
                "vill_name_en": "Naty",
                "dr_id": "1803"
              },
              {
                "vill_id": "1803010",
                "vill_name": "ໝອງພູໄຊ",
                "vill_name_en": "Nongpousai",
                "dr_id": "1803"
              }
            ]
          },
          {
            "dr_id": "1804",
            "dr_name": "ຮົ່ມ",
            "dr_name_en": "Hom",
            "pr_id": "18",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "184017",
                "vill_name": "ໂພນສະຫວາດ",
                "vill_name_en": "Phonsavard",
                "dr_id": "1804"
              },
              {
                "vill_id": "1804001",
                "vill_name": "ໜອງ",
                "vill_name_en": "",
                "dr_id": "1804"
              },
              {
                "vill_id": "1804002",
                "vill_name": "ຮົ່ມທາດ",
                "vill_name_en": "",
                "dr_id": "1804"
              },
              {
                "vill_id": "1804003",
                "vill_name": "ສາມເສົ້າ",
                "vill_name_en": "Samsao",
                "dr_id": "1804"
              },
              {
                "vill_id": "1804004",
                "vill_name": "ພູງູ",
                "vill_name_en": "Phoungou",
                "dr_id": "1804"
              },
              {
                "vill_id": "1804005",
                "vill_name": "ດອນໂຮມ",
                "vill_name_en": "donehome",
                "dr_id": "1804"
              },
              {
                "vill_id": "1804006",
                "vill_name": "ສົມພອນ",
                "vill_name_en": "Somephone",
                "dr_id": "1804"
              },
              {
                "vill_id": "1804007",
                "vill_name": "ຜາລະແວກ",
                "vill_name_en": "Phalavaek",
                "dr_id": "1804"
              },
              {
                "vill_id": "1804008",
                "vill_name": "ນ້ຳຄຽນ",
                "vill_name_en": "Namkhien",
                "dr_id": "1804"
              },
              {
                "vill_id": "1804009",
                "vill_name": "ນ້ຳທວາຍ",
                "vill_name_en": "Namthuay",
                "dr_id": "1804"
              },
              {
                "vill_id": "1804010",
                "vill_name": "ສົບພວນ",
                "vill_name_en": "Sopphoun",
                "dr_id": "1804"
              },
              {
                "vill_id": "1804011",
                "vill_name": "ບ້ານໂພນມ່ວງ",
                "vill_name_en": "Phonmong",
                "dr_id": "1804"
              },
              {
                "vill_id": "1804012",
                "vill_name": "ນໍ້າຢວກ",
                "vill_name_en": "Numyeuk",
                "dr_id": "1804"
              },
              {
                "vill_id": "1804013",
                "vill_name": "ບ້ານເມືອງຫລົງ",
                "vill_name_en": "meaunglong",
                "dr_id": "1804"
              },
              {
                "vill_id": "1804014",
                "vill_name": "ນ້ຳປຸງນ້ອຍ",
                "vill_name_en": "Nampoungnoy",
                "dr_id": "1804"
              },
              {
                "vill_id": "1804015",
                "vill_name": "516",
                "vill_name_en": "516",
                "dr_id": "1804"
              },
              {
                "vill_id": "1804016",
                "vill_name": "ປາກຕູ້",
                "vill_name_en": "Parktou",
                "dr_id": "1804"
              },
              {
                "vill_id": "1804017",
                "vill_name": "ພູກະທະ",
                "vill_name_en": "Phoukatha",
                "dr_id": "1804"
              },
              {
                "vill_id": "1804018",
                "vill_name": "ຄອນວັດ",
                "vill_name_en": "Konvath",
                "dr_id": "1804"
              }
            ]
          },
          {
            "dr_id": "1805",
            "dr_name": "ລ້ອງຊານ",
            "dr_name_en": "Longsan",
            "pr_id": "18",
            "villages": [
              {"vill_name": "ເລືອກບ້ານປະຈຸບັນ"},
              {
                "vill_id": "180505",
                "vill_name": "ນ້ຳມຸ້ງ",
                "vill_name_en": "Nammoung",
                "dr_id": "1805"
              },
              {
                "vill_id": "1805002",
                "vill_name": "ດອນສຳພັນ",
                "vill_name_en": "Donesamphan",
                "dr_id": "1805"
              },
              {
                "vill_id": "1805003",
                "vill_name": "ໂພນເລົ່າ",
                "vill_name_en": "Phonlao",
                "dr_id": "1805"
              },
              {
                "vill_id": "1805004",
                "vill_name": "ສັນປາຕອງ",
                "vill_name_en": "sanpatong",
                "dr_id": "1805"
              },
              {
                "vill_id": "1805005",
                "vill_name": "ໂພນສະຫວາດ",
                "vill_name_en": "Phonsavard",
                "dr_id": "1805"
              },
              {
                "vill_id": "1805006",
                "vill_name": "ຫວ້ຍນ້ຳເຢັນ",
                "vill_name_en": "Huaynamyen",
                "dr_id": "1805"
              },
              {
                "vill_id": "1805007",
                "vill_name": "ທ່າເຮືອ",
                "vill_name_en": "Thaheau",
                "dr_id": "1805"
              },
              {
                "vill_id": "1805008",
                "vill_name": "ຄອນວັດ",
                "vill_name_en": "Khonevat",
                "dr_id": "1805"
              },
              {
                "vill_id": "1805009",
                "vill_name": "ຫ້ວຍສ້ຽວ",
                "vill_name_en": "Honysiwy",
                "dr_id": "1805"
              },
              {
                "vill_id": "1805010",
                "vill_name": "ວັງຫຼວງ",
                "vill_name_en": "vangluang",
                "dr_id": "1805"
              },
              {
                "vill_id": "1805011",
                "vill_name": "ຖ້ຳດິນ",
                "vill_name_en": "Thamdin",
                "dr_id": "1805"
              },
              {
                "vill_id": "1805012",
                "vill_name": "ພູປ່າມັນ",
                "vill_name_en": "Phoupaman",
                "dr_id": "1805"
              },
              {
                "vill_id": "1805013",
                "vill_name": "ນໍ້າຍີ້ງ",
                "vill_name_en": "Namying",
                "dr_id": "1805"
              },
              {
                "vill_id": "1805014",
                "vill_name": "ບ້ານຊຽງມີ",
                "vill_name_en": "Xiengmy",
                "dr_id": "1805"
              },
              {
                "vill_id": "1805015",
                "vill_name": "ນໍ້າຄຸຍ",
                "vill_name_en": "Numkeu",
                "dr_id": "1805"
              },
              {
                "vill_id": "1805016",
                "vill_name": "ຜາດ່າງ",
                "vill_name_en": "Phadang",
                "dr_id": "1805"
              },
              {
                "vill_id": "1805017",
                "vill_name": "ດອນໂຮມ",
                "vill_name_en": "DoneHorm",
                "dr_id": "1805"
              },
              {
                "vill_id": "1805018",
                "vill_name": "ແກ່ວສາມີ",
                "vill_name_en": "Keosamy",
                "dr_id": "1805"
              },
              {
                "vill_id": "1805019",
                "vill_name": "ຊຽງແງ້",
                "vill_name_en": "Xiengbang",
                "dr_id": "1805"
              },
              {
                "vill_id": "1805020",
                "vill_name": "ຫີນບໍ",
                "vill_name_en": "Hinbor",
                "dr_id": "1805"
              },
              {
                "vill_id": "1805021",
                "vill_name": "ນາມອນ",
                "vill_name_en": "Namone",
                "dr_id": "1805"
              },
              {
                "vill_id": "1805022",
                "vill_name": "ບ້ານຊຳຄອນ",
                "vill_name_en": "Samkhone",
                "dr_id": "1805"
              }
            ]
          }
        ]
      }
    ]
  };
  