import React, { useState, useEffect } from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Form from 'react-bootstrap/Form'
import Image from 'react-bootstrap/Image'
import Dropdown from 'react-bootstrap/Dropdown'
import NavDropdown from 'react-bootstrap/NavDropdown'
import { USER_KEY } from '../consts'
import useReactRouter from 'use-react-router'
// import ImageProfile from '../image/profile.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt, faUser } from '@fortawesome/free-solid-svg-icons'
// import Constans from '../consts'
// import Route from '../consts/router'
export default function NavbarAdmin() {
    const { history } = useReactRouter()
      const [userData, setUserData] = useState({})
      useEffect(() => {
        const _resData = localStorage?.getItem(USER_KEY)
        const _localJson = JSON?.parse(_resData)
          if (!_localJson?.accessToken) {
          history.push(`/`)
        } else {
              setUserData(_localJson)
        }
      }, [])
      const _onLogout = () => {
        localStorage.clear()
        sessionStorage.clear()
        history.push(`/`)
        window.location.reload();
      }

    //   const _onDetailProfile = () => {
    //     if (userData?.loginAdmin?.data?.role === "POPULATION") history.push(Route?.POPULATION_DETAIL + "/id/" + userData?.loginAdmin?.data?.id)
    //     history.push(Route?.USERS_DETAIL +"/"+ userData?.loginAdmin?.data?.id)
    //   }
    return (
        <div className='theme-red'>
            <Navbar
                style={{
                    backgroundColor: '#ffffff',
                    boxShadow: '0px 0px 2px rgba(37, 42, 49, 0.16), 0px 1px 4px rgba(37, 42, 49, 0.12)',
                    color: '#00072B!important',
                    width: '100%',
                    height: 64,
                    position: 'fixed',
                    marginLeft: 65,
                    paddingRight: 80,
                    zIndex: 1001,
                }}
                variant='dark'
            >
                <div className='CompanyName' style={{ color: "#00072B" }}>ZERO WASTE LAOS-ADMIN</div>


                <div style={{ color: '#00072B' }}>(V 0.0.2)</div>
                <Navbar.Brand style={{ color: '#909090' }} href='#'></Navbar.Brand>
                <Navbar.Toggle aria-controls='basic-navbar-nav' />
                <Navbar.Collapse id='basic-navbar-nav'>
                    <Nav className='mr-auto' />
                    <Form inline>
                        <Dropdown>
                            <Dropdown.Toggle
                                style={{ color: '#00072B',fontSize:14 }}
                                variant=''
                                // id='dropdown-button-drop-start'
                                drop='start'
                            >
                                {/* {userData
                  ? (userData?.loginAdmin?.data?.firstName
                      ? userData?.loginAdmin?.data?.firstName
                      : '') +
                    ' ' +
                    (userData?.loginAdmin?.data?.lastName
                      ? userData?.loginAdmin?.data?.lastName
                      : '')
                  : ''} */}
                                <Image
                                    src={
                                        "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Circle-icons-profile.svg/1024px-Circle-icons-profile.svg.png"
                                    }
                                    width={50}
                                    height={50}
                                    roundedCircle
                                />
                                &nbsp;&nbsp;&nbsp;
                                {userData?.data?.firstName}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                 {/* <Dropdown.Item
                                    style={{ color: '#909090',fontSize:15 }}
                                  onClick={() => _onDetailProfile()}
                                >
                                    <FontAwesomeIcon icon={faUser} style={{ color: 'red' }} />
                                    &nbsp;
                                    Profile
                                </Dropdown.Item>
                                <NavDropdown.Divider />  */}
                                <Dropdown.Item
                                   style={{ color: '#909090',fontSize:15  }}
                                  onClick={() => _onLogout()}
                                >
                                    <FontAwesomeIcon
                                        icon={faSignOutAlt}
                                        style={{ color: 'red' }}
                                    />
                                    &nbsp;
                                    log out
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>

                    </Form>
                </Navbar.Collapse>
            </Navbar>
        </div>
    )
}
