import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import useReactRouter from "use-react-router";


// import Consts from "../../../consts"
import Clubhouse from "../../../image/contact/clubhouse.png"


import { FaAngleRight } from "react-icons/fa";
import { Carousel, Container, Row, Col, Button, ListGroup } from 'react-bootstrap'
import { FaFacebook, FaSkype, FaYoutubeSquare, FaTiktok, FaMapMarkerAlt, FaMailBulk,FaEnvelope,FaPhoneAlt } from "react-icons/fa";

import Footer from "../../../components/footer"

import './Index.css'


function Contact() {
    const { history } = useReactRouter();


    return (
        <div style={{ zIndex: 0 }}>

            <Container fluid >
                <Row>

                    <Col md={6} className="mt-5 contactMidia" style={{ marginTop: 170 }}>

                        <p className="contactMidia-Title">Contact Us</p>

                        <ul className='contactSocial'>
                            <li><a href="https://www.facebook.com/Zerowastelaos.Org/"><FaFacebook /> Facebook: Zero Waste Laos</a></li>
                            <li><a href="https://www.clubhouse.com/@zwl_laos"><img src={Clubhouse} style={{ width: 30, height: 30 }} /> Clubhouse: Zero waste Laos</a></li>
                            <li><a href="https://www.youtube.com/channel/UCALxH4u0bT9JZ64Lk2UaKgQ"><FaYoutubeSquare /> Youtube: Zero Waste Laos Official</a></li>
                            <li><a href="#"><FaEnvelope /> Email: souksaveuy@zerowastelaos.org</a></li>
                            <li><a href="#"><FaPhoneAlt /> Tel: +8562058734549</a></li>
                        </ul>

                        


                        <ul className="contactAddress">
                            <li><FaMapMarkerAlt /> Vientiane Capital, Lao PDR</li>


                        </ul>

                    </Col>
                    <Col md={6} className='p-0 mapAddress'>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3653.496486265622!2d102.62445157459956!3d17.938851333050717!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3124670030747f29%3A0x8504c2842ea2e8bf!2sZero%20Waste%20Laos!5e0!3m2!1sth!2sla!4v1717753725854!5m2!1sth!2sla" className='contactMap' allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </Col>
                </Row>
            </Container>


            <Footer />

        </div>
    )
}
export default Contact